import * as React from 'react';
import {
  SchedulerEditItem
} from '@progress/kendo-react-scheduler';

export const EditItemWithDynamicTitle = (props) => {
  return (
    <SchedulerEditItem
      { ...props }
      className={setClass(props.dataItem)}
      title={generateTitle(props.dataItem)}
    />
  );
};

function nameToClass(name) {
  var className = ['assigned1', 'assigned2', 'assigned3', 'assigned4', 'assigned5', 'assigned6', 'assigned7', 'assigned8', 'assigned9', 'assigned10', 'assigned11', 'assigned12'];
  var hash = hashStr(name);
  var index = hash % className.length;
  return className[index];
}

function hashStr(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    var charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
}

const generateTitle = (dataItem) => {
  let title = `${dataItem.workOrderNumber}`;
  if (dataItem.userIds.length > 0) {
    title = title + ` (${dataItem.userIds.map(u => u.fullName).join(' | ')})`;
  }
  if (dataItem.appointmentNotes) {
    title = title + ` - ${dataItem.appointmentNotes}`;
  }
  return title;
};

const setClass = (dataItem) => {
  const { workOrderStatus, appointmentEndDate } = dataItem
  const pastAppointment = new Date(appointmentEndDate) < new Date();
  console.log("setClass -> pastAppointment", pastAppointment);
  if (pastAppointment && (workOrderStatus === 'Open' || workOrderStatus === 'Assigned' || workOrderStatus === 'InProgress')) {
    return 'needs-attention'
  } else if(workOrderStatus === 'InReview'){
    return 'inReview'
  } else if(workOrderStatus === 'Closed'){
    return 'closed'
  } else if(dataItem.userIds.length < 1){
    return 'unAssigned';
  } else {
    var ids = JSON.stringify(dataItem.userIds.map(u => u.fullName).join(''));
    return nameToClass(ids);
  }
};