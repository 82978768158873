import React, { useState, useEffect, useContext } from 'react';
import { Button, Checkbox, Loader, Table } from 'semantic-ui-react';
import * as QuickSightService from '../../../../core/services/quicksight.service';
import AppContext from '../../../../core/context/app.context';
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
} from '@progress/kendo-react-listbox';
import roles from '../../../../constants/roles';
import { isEnabled } from '../../../../core/services/auth.service';
import CustomLoader from '../../../../shared/components/loader';

const SELECTED_FIELD = 'selected';

const formatData = (data) => {
  return data.map(item => ({ id: item.id, name: item.fullName, selected: false }))
}

export const UserListBox = ({ users, members, loading, onSave }) => {
  const [state, setState] = useState({
    users: [...users],
    members: [...members],
    draggedItem: {},
  });

  useEffect(() => {
    setState({ ...state, users: formatData(users) });
  }, [users]);

  useEffect(() => {
    setState({ users: formatData(users), members: formatData(members), draggedItem: {} });
  }, [members]);

  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.name === event.dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e) => {
    let toolName = e.toolName || '';
    let result = processListBoxData(
      state.users,
      state.members,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      users: result.listBoxOneData,
      members: result.listBoxTwoData,
    });
  };

  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e) => {
    let result = processListBoxDragAndDrop(
      state.users,
      state.members,
      state.draggedItem,
      e.dataItem,
      'name'
    );
    setState({
      ...state,
      users: result.listBoxOneData,
      members: result.listBoxTwoData,
    });
  };

  const handleSave = () => {
    const userIds = state.members.map(member => member.id);
    onSave(userIds);
  }

  return (
    <>
      <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
        <div style={{ display: 'flex', flex: 1, gap: 10 }}>
          <div style={{ flex: 1 }}>
            <h3>Users</h3>
            <ListBox
              style={{
                height: 400,
                width: '100%',
              }}
              data={state.users}
              textField='name'
              selectedField={SELECTED_FIELD}
              onItemClick={(e) => handleItemClick(e, 'users', 'members')}
              onDragStart={handleDragStart}
              onDrop={handleDrop}
              toolbar={() => {
                return (
                  <ListBoxToolbar
                    tools={[
                      'transferTo',
                      'transferFrom',
                      'transferAllTo',
                      'transferAllFrom',
                    ]}
                    data={state.users}
                    dataConnected={state.members}
                    onToolClick={handleToolBarClick}
                  />
                );
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <h3>Members</h3>
            <ListBox
              style={{
                height: 400,
                width: '100%',
              }}
              data={state.members}
              textField='name'
              selectedField={SELECTED_FIELD}
              onItemClick={(e) => handleItemClick(e, 'members', 'users')}
              onDragStart={handleDragStart}
              onDrop={handleDrop}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button positive onClick={handleSave} disabled={loading} loading={loading}>Save</Button>
      </div>
    </>
  );
};

const ReportingStep = ({ project }) => {
  const context = useContext(AppContext);

  const [group, setGroup] = useState(null);
  const [loadingGroup, setLoadingGroup] = useState(true);
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchQuickSightGroupByProject(project.id);
  }, []);

  const fetchQuickSightGroupByProject = async () => {
    const result = await QuickSightService.getGroup(project.id);
    if (result) {
      await Promise.all([fetchQuickSightGroupUsersByProject(), fetchQuickSightGroupMembersByProject()]);
      setGroup(result);
    }
    setLoadingGroup(false);
  }

  const fetchQuickSightGroupUsersByProject = async () => {
    const users = await QuickSightService.listGroupUsers(project.id);
    setUsers(users);
  }

  const fetchQuickSightGroupMembersByProject = async () => {
    const members = await QuickSightService.listGroupMembers(project.id);
    setMembers(members);
  }

  const createGroup = async () => {
    setCreatingGroup(true);
    setGroup({});
    await QuickSightService.createGroup(project.id);
    await fetchQuickSightGroupByProject();
    setCreatingGroup(false);
  }

  const deleteGroup = async () => {
    setDeletingGroup(true);
    setGroup(null);
    await QuickSightService.deleteGroup(project.id);
    setDeletingGroup(false);
  }

  const onSave = async (userIds) => {
    setSaving(true);
    await QuickSightService.manageUserGroup(project.id, userIds);
    await Promise.all([fetchQuickSightGroupUsersByProject(), fetchQuickSightGroupMembersByProject()]);
    setSaving(false);
  }

  return (
    <>
      {saving && <CustomLoader loading={saving} />}
      {isEnabled(context.user.userRoles, [roles.SystemAdmin]) && <div style={{ marginTop: '10px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Table celled definition className='overview-step-table'>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Group</Table.Cell>
                <Table.Cell>
                  {loadingGroup ? <Loader active inline size='small' /> : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <Checkbox
                        checked={!!group}
                        disabled={creatingGroup || deletingGroup}
                        onChange={!!group ? deleteGroup : createGroup}
                        toggle
                      />
                    </div>
                  </div>}
                </Table.Cell>
              </Table.Row>
              {(deletingGroup || creatingGroup || group) && <Table.Row>
                <Table.Cell width={4}>Group Name</Table.Cell>
                <Table.Cell>{group && group.name ? group.name : <Loader active inline size='small' />}</Table.Cell>
              </Table.Row>}
            </Table.Body>
          </Table>
        </div>
        <div style={{ flex: 0.5 }}></div>
      </div>}
      {group && group.name && <UserListBox loading={saving} users={users} members={members} onSave={onSave} />}
    </>
  )
}

export default ReportingStep;