import { getToken } from './auth.service';
import config from '../../config';

export const invokeGeoJsonFileCreation = async (projectId, workOrderType) => {
  try {
    const token = await getToken();
    const body = { projectId, workOrderType };
    const response = await fetch(`${config.invokeGeoJsonFileCreationApi}`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': token
      }),
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      throw new Error('GeoJson creation failed');
    }
    const data = await response.json();
    return data.id;
  } catch (error) {
    throw error;
  }
}

export const getMapDataUrl = async (projectId) => {
  try {
    const token = await getToken();
    const response = await fetch(`${config.mapDataApi}/${projectId}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': token
      })
    });
    const mapDataUrl = await response.json();
    return mapDataUrl;
  } catch (error) {
    throw error;
  }
}