const MODAL_TYPES = {
  IN_REVIEW: 'IN_REVIEW',
  ESCALATED: 'ESCALATED',
  COMPLETED: 'COMPLETED',
  REJECT: 'REJECT'
}

export const MODAL_TYPES_LABELS = {
  [MODAL_TYPES.IN_REVIEW]: 'In Review',
  [MODAL_TYPES.ESCALATED]: 'Escalated',
  [MODAL_TYPES.COMPLETED]: 'Completed'
}

export default MODAL_TYPES;