import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';

const QualificationWarningModal = ({ showModal, closeModal, message }) => (
  <Modal dimmer='blurring' size='tiny' open={showModal}>
    <Modal.Header><Icon name='warning sign' />Warning</Modal.Header>
    <Modal.Content>
      {message}
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={closeModal} primary content='Confirm' />
    </Modal.Actions>
  </Modal>
)

export default QualificationWarningModal;