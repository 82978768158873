import gql from 'graphql-tag';

export const GET_BLACKOUTS_QUERY = gql`
  query listBlackOuts($projectId: ID!, $limit: Int) {
    listBlackOuts(project: $projectId, limit: $limit) {
      items {
        id
        blackOutCriteriaValue
        blackOutStart
        blackOutEnd
      }
    }
  }
`;

export const IS_IN_BLACKOUT_RANGE_QUERY = gql`
  query IsInBlackOutRange($project: ID!, $startDates: [AWSDate]!, $endDates: [AWSDate]!, $billingCycleCodes: [String]!, $routes:[String]!) {
    isInBlackOutRange(
      billingCycleCodes: $billingCycleCodes, 
      endDates: $endDates, 
      project: $project, 
      routes: $routes, 
      startDates: $startDates
    ) {
      isBlackout
      routes
    }
  }
`;