import { SET_POPUP, RESET_ADMIN_OVERVIEW_STATE } from '../types/administrationOverview.types';

export const setPopup = (popup) => {
  return {
    type: SET_POPUP,
    payload: popup
  }
}

export const resetAdminOverviewState = () => {
  return {
    type: RESET_ADMIN_OVERVIEW_STATE
  }
}