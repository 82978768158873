import React from 'react';
import { Button, Icon, Modal, Form } from 'semantic-ui-react';
import CustomErrorMessage from '../../../../shared/components/messages/error';
import AppContext from '../../../../core/context/app.context';
import * as workOrderService from '../../../../core/services/workOrder.service';

class SaveWorkOrdersGroup extends React.Component {
  static contextType = AppContext;
  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;
    const { currentProject } = this.context;
    const groups = await workOrderService.listWorkOrderGroups(currentProject.id);
    this.setState({ groups });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  state = {
    error: null,
    loading: false,
    groupName: '',
    groups: []
  }

  onModalConfirm = async () => {
    try {
      this.setState({ loading: true, error: false });
      const { getWorkOrders, getFilterTypes, searchBy, filterBy, selectedWorkOrders, closeModal, unselectWorkOrders } = this.props;
      const { currentProject } = this.context;
      const groupName = this.state.groupName;
      if (groupName && groupName.trim().length > 0) {
        await workOrderService.saveWorkOrderGroups(currentProject, groupName, selectedWorkOrders);
        getWorkOrders(null, searchBy, filterBy);
        getFilterTypes();
        unselectWorkOrders();
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  }

  onGroupNameChange = (event, { value }) => {
    this.setState({ groupName: value });
  }

  handleKeyPress = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      this.onModalConfirm();
    }
  }

  render() {
    const { loading, error, groups } = this.state;
    const { showModal, closeModal, selectedWorkOrders } = this.props;
    return (
      <div onKeyPress={this.handleKeyPress}>
        <Modal as={Form} dimmer='blurring' size='tiny' onClose={closeModal} open={showModal} onSubmit={this.onModalConfirm}>
          <Modal.Header>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label><Icon name='list' />Save Group</label>
              <label style={{ fontWeight: 'normal', fontSize: '18px' }}>Selected: {selectedWorkOrders.length}</label>
            </div>
          </Modal.Header>
          <Modal.Content>
            <Form.Field>
              <Form.Input required list='groupNames' autoFocus placeholder='Group Name' onChange={this.onGroupNameChange} />
              <datalist id='groupNames'>
                {groups.map((group, key) => <option key={key} value={group} />)}
              </datalist>
            </Form.Field>
            {error && <CustomErrorMessage message='Error. Unable to save work orders group' />}
          </Modal.Content>
          <Modal.Actions style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!loading && <Button onClick={closeModal} negative>Cancel</Button>}
            <Form.Button loading={loading} positive icon='checkmark' labelPosition='right' content='Save' />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default SaveWorkOrdersGroup;