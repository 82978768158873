import WORKORDER_TYPES from './workOrderTypes';

// TODO: map each column name with proper column type
export const REPORT_TABLE_COLUMN_TYPES = {
  boolean: 'boolean',
  string: 'string',
  json: 'json',
  number: 'number',
  none: ''
}

export default {
  [WORKORDER_TYPES.WaterSiteSurvey]: [
    'street',
    'street2',
    'workOrderNumber',
    'city',
    'stateCode',
    'zipCode',
    'districtCode',
    'accountNumber',
    'routeCode',
    'customerName',
    'customDataFields',
    'workOrderStatus',
    'longitude',
    'latitude',
    'workOrderNeedsAppointment',
    'capturedLongitude',
    'capturedLatitude',
    'locationId',
    'mapNumber',
    'billingCycleCode',
    'workOrderReasonCodes',
    'workOrderLastVisitDate',
    'workOrderSiteConditions',
    'workOrderFlaggedAs3strike',
    'workOrderFlaggedAsPlated',
    'workOrderFlaggedAsDamaged',
    'workOrderFlaggedAsAdHoc',
    'workOrderFlaggedAsCNC',
    'ReadSequence',
    'meterSize',
    'oldMeterReading',
    'newMeterReading',
    'newMeterReadingDate',
    'newLidSize',
    'oldMeterNumber',
    'meterReadingDate',
    'newMeterNumber',
    'newRadioNumber',
    'serviceType',
    'newMeterDial',
    'meterReadNotes',
    'oldGenericNumber',
    'newRegisterNumber',
    'oldRegisterNumber',
    'oldRadioNumber',
    'meterType',
    'userName',
    'assetNumber',
    'assetLocation',
    'partNumber',
    'form',
    'manufacturer',
    'manufactureDate',
    'amrSerialNumber',
    'content',
    'emptyColumn1',
    'emptyColumn2',
    'emptyColumn3',
    'emptyColumn4',
    'emptyColumn5',
    'emptyColumn6',
    'emptyColumn7',
    'emptyColumn8',
    'emptyColumn9',
    'emptyColumn10',
    'emptyColumn11',
    'emptyColumn12',
    'emptyColumn13',
    'emptyColumn14',
    'emptyColumn15',
    'emptyColumn16',
    'emptyColumn17',
    'emptyColumn18',
    'emptyColumn19',
    'emptyColumn20',
    'emptyColumn21',
    'emptyColumn22',
    'emptyColumn23',
    'emptyColumn24',
    'emptyColumn25',
    'emptyColumn26',
    'emptyColumn27',
    'emptyColumn28',
    'emptyColumn29',
    'emptyColumn30',
  ],
  [WORKORDER_TYPES.WaterDeploy]: [
    'street',
    'street2',
    'workOrderNumber',
    'city',
    'stateCode',
    'zipCode',
    'districtCode',
    'accountNumber',
    'routeCode',
    'customerName',
    'customDataFields',
    'workOrderStatus',
    'longitude',
    'latitude',
    'workOrderNeedsAppointment',
    'capturedLongitude',
    'capturedLatitude',
    'locationId',
    'mapNumber',
    'billingCycleCode',
    'workOrderReasonCodes',
    'workOrderLastVisitDate',
    'workOrderSiteConditions',
    'workOrderFlaggedAs3strike',
    'workOrderFlaggedAsPlated',
    'workOrderFlaggedAsDamaged',
    'workOrderFlaggedAsAdHoc',
    'workOrderFlaggedAsCNC',
    'ReadSequence',
    'meterSize',
    'oldMeterReading',
    'newMeterReading',
    'newMeterReadingDate',
    'newLidSize',
    'oldMeterNumber',
    'meterReadingDate',
    'newMeterNumber',
    'newRadioNumber',
    'serviceType',
    'newMeterDial',
    'meterReadNotes',
    'oldGenericNumber',
    'newRegisterNumber',
    'oldRegisterNumber',
    'oldRadioNumber',
    'meterType',
    'userName',
    'assetNumber',
    'assetLocation',
    'partNumber',
    'form',
    'manufacturer',
    'manufactureDate',
    'amrSerialNumber',
    'content',
    'emptyColumn1',
    'emptyColumn2',
    'emptyColumn3',
    'emptyColumn4',
    'emptyColumn5',
    'emptyColumn6',
    'emptyColumn7',
    'emptyColumn8',
    'emptyColumn9',
    'emptyColumn10',
    'emptyColumn11',
    'emptyColumn12',
    'emptyColumn13',
    'emptyColumn14',
    'emptyColumn15',
    'emptyColumn16',
    'emptyColumn17',
    'emptyColumn18',
    'emptyColumn19',
    'emptyColumn20',
    'emptyColumn21',
    'emptyColumn22',
    'emptyColumn23',
    'emptyColumn24',
    'emptyColumn25',
    'emptyColumn26',
    'emptyColumn27',
    'emptyColumn28',
    'emptyColumn29',
    'emptyColumn30',
  ],
  [WORKORDER_TYPES.MeterDeploy]: [
    'accountNumber',
    'customerName',
    'workOrderNumber',
    'workOrderStatus',
    'customDataFields',
    'routeCode',
    'street',
    'longitude',
    'latitude',
    'capturedLongitude',
    'capturedLatitude',
    'locationId',
    'mapNumber',
    'billingCycleCode',
    'workOrderNeedsAppointment',
    'workOrderLastVisitDate',
    'workOrderSiteConditions',
    'workOrderFlaggedAs3strike',
    'workOrderFlaggedAsPlated',
    'workOrderFlaggedAsDamaged',
    'workOrderFlaggedAsAdHoc',
    'workOrderFlaggedAsCNC',
    'workOrderReasonCodes',
    'oldMeterNumber',
    'oldMeterKwhReading',
    'oldMeterKwReading',
    'meterReadingDate',
    'newMeterNumber',
    'newMeterKwhReading',
    'newMeterKwReading',
    'newMeterReadingDate',
    'demandCode',
    'hasDisconnectedSwitch',
    'billingMultiplier',
    'serviceDescription',
    'serviceType',
    'newRadioNumber',
    'oldMeterKvarLastReading',
    'meterStatus',
    'oldMeterKwhLastReading',
    'newMeterDial',
    'meterReadNotes',
    'newRegisterNumber',
    'meterType',
    'userName',
    'assetNumber',
    'assetLocation',
    'partNumber',
    'form',
    'manufacturer',
    'amrSerialNumber',
    'cononicalAmrSerialNumber',
    'Installer Comments',
    'emptyColumn1',
    'emptyColumn2',
    'emptyColumn3',
    'emptyColumn4',
    'emptyColumn5',
    'emptyColumn6',
    'emptyColumn7',
    'emptyColumn8',
    'emptyColumn9',
    'emptyColumn10',
    'emptyColumn11',
    'emptyColumn12',
    'emptyColumn13',
    'emptyColumn14',
    'emptyColumn15',
    'emptyColumn16',
    'emptyColumn17',
    'emptyColumn18',
    'emptyColumn19',
    'emptyColumn20',
    'emptyColumn21',
    'emptyColumn22',
    'emptyColumn23',
    'emptyColumn24',
    'emptyColumn25',
    'emptyColumn26',
    'emptyColumn27',
    'emptyColumn28',
    'emptyColumn29',
    'emptyColumn30',
  ]
}