import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const DuplicateWorkOrdeerConfirmModal = ({
  loading,
  modalRef,
  showModal,
  closeModal,
  onSubmit,
}) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmit();
    }
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>Duplicate Work Order</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to duplicate the selected work order?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={loading} onClick={closeModal} negative>No</Button>
          <Button loading={loading} disabled={loading} onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Yes' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default DuplicateWorkOrdeerConfirmModal;