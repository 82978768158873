import React from 'react';
import { Field, FormElement } from '@progress/kendo-react-form';
import { DatePicker, DateTimePicker } from '@progress/kendo-react-dateinputs';
import { AssignmentsEditor } from './editors';
import { Label, Error } from '@progress/kendo-react-labels';
import { Input, TextArea } from '@progress/kendo-react-inputs'; // Import the TextArea component
import { connect } from 'react-redux';
import { Loader } from '@progress/kendo-react-indicators';
import ErrorMessage from '../../../shared/components/messages/error';
import { Icon, Message } from 'semantic-ui-react';
import './styles.css';

const CustomFormEditor = (props) => {
  const startDateWarning = props.valueGetter('appointmentStartDate') < new Date();
  // const appointmentSequence = props.appointmentSequence;

  const getAppointmentEndDate = () => {
    if (props.valueGetter('appointmentStartDate')) {
      return new Date(props.valueGetter('appointmentStartDate').getTime() + 30 * 60000);
    } else {
      return null;
    }
  }
  return (
    <FormElement horizontal={true}>
      <div className='k-form-field'>
        <Label>
          Start
        </Label>
        <div className='k-form-field-wrap'>
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center'
          }}>
            <Field onChange={() => props.onChange('appointmentEndDate', { value: getAppointmentEndDate() })} name={'appointmentStartDate'} component={props.startEditor || DatePicker} as={DateTimePicker} rows={1} size='tiny' format='MM/dd/yy hh:mm a' />
              &nbsp;
              <Label>
              End
              </Label>
              &nbsp;
              <Field name={'appointmentEndDate'} component={props.endEditor || DatePicker} as={DateTimePicker} rows={1} format='MM/dd/yy hh:mm a' size='tiny' />
          </div>
        </div>
      </div>
      {startDateWarning && <Message warning><Icon name='warning' /> Warning! Appointment will be created in the past.</Message>}
      <div className='k-form-field'>
        <Label>
          Work Order Number
        </Label>
        {props.appointmentId || props.selectedWorkOrder ? (
          <Label style={{ flexDirection: 'initial', fontWeight: 'bold' }}>
            {props.appointmentId ? props.valueGetter('workOrderNumber') : props.selectedWorkOrder.workOrderNumber}
          </Label>
        ) : (<div className='k-form-field-wrap'>
          <Field name={'workOrderNumber'} component={Input} />
          {props.errors.workOrderNumber && <Error>{props.errors.workOrderNumber}</Error>}
          {props.workOrderNotFoundError && <Error>Work Order not found</Error>}
        </div>)}
      </div>
      <div className='k-form-field'>
        <Label>
          Assignments
        </Label>
        <div className='k-form-field-wrap'>
          <Field name={'userIds'} component={(rest) => <AssignmentsEditor {...rest} selectedWorkOrder={props.selectedWorkOrder} />} />
          {props.errors.userIds && <Error>{props.errors.userIds}</Error>}
        </div>
      </div>
      {/* 
      TODO: Make Sequence Number configurable by project
      */}
      {props.valueGetter('appointmentSequence') !== null && 
      <div className='k-form-field'>
        <Label>
          Sequence Number
        </Label>
        <div className='k-form-field-wrap'>
        <Field name={'appointmentSequence'} disabled={true} value={props.valueGetter('appointmentSequence')} component={Input} />
          {props.errors.appointmentSequence && <Error>{props.errors.appointmentSequence}</Error>}
        </div>
      </div>
      }
      <div className='k-form-field'>
        <Label>
          Note
        </Label>
        <div className='k-form-field-wrap' style={{ marginBottom: 10 }}>
          <Field name={'appointmentNotes'} component={TextArea} rows={3} />
          {props.errors.appointmentNotes && <Error>{props.errors.appointmentNotes}</Error>}
        </div>
      </div>
      {props.validating && <><Loader size='small' type={'infinite-spinner'} /><span style={{ marginLeft: 10 }}>Checking time slot...</span></>}
      {props.validationMessage && <ErrorMessage message={props.validationMessage} style={{ margin: '10px 0px 0px 0px' }} headerStyle={{}} />}
      <br />
    </FormElement>
  )
}

const mapStateToProps = ({ scheduler }) => {
  return {
    workOrderNotFoundError: scheduler.workOrderNotFoundError,
    validating: scheduler.validating,
    validationMessage: scheduler.validationMessage
  }
}

export default connect(mapStateToProps, null)(CustomFormEditor);