import config from '../../config';
import { getToken } from './auth.service';

export const getQuickSightDashboardEmbedUrl = async (dashboardId) => {
  try {
    const token = await getToken();
    let response = await fetch(`${config.getQuickSightDashboardEmbedUrlApi}/${dashboardId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token
      }
    });
    response = await response.json();
    return response.EmbedUrl;
  } catch (error) {
    console.log(error);
  }
}

export const getGroup = async (projectId) => {
  try {
    const token = await getToken();
    let response = await fetch(`${config.qsServiceGroupApi}/${projectId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token
      }
    });
    if (!response.ok) {
      return null;
    }
    response = await response.json();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const createGroup = async (projectId) => {
  try {
    const token = await getToken();
    await fetch(`${config.qsServiceGroupApi}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        projectId
      }),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const deleteGroup = async (projectId) => {
  try {
    const token = await getToken();
    await fetch(`${config.qsServiceGroupApi}/${projectId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Authorization: token,
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const listGroupUsers = async (projectId) => {
  try {
    const token = await getToken();
    let response = await fetch(`${config.qsServiceGroupApi}/${projectId}/users`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token,
      }
    });
    if (!response.ok) {
      return [];
    }
    response = await response.json();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const listGroupMembers = async (projectId) => {
  try {
    const token = await getToken();
    let response = await fetch(`${config.qsServiceGroupApi}/${projectId}/members`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token,
      }
    });
    if (!response.ok) {
      return [];
    }
    response = await response.json();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const manageUserGroup = async (projectId, userIds) => {
  try {
    const token = await getToken();
    await fetch(`${config.qsServiceGroupApi}/users`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        projectId,
        userIds
      }),
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    console.log(error);
  }
}