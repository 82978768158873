export default {
  login: '/',
  dashboard: '/dashboard',
  workOrders: '/workorders/all-workorders',
  qualityAssurance: '/quality-assurance/meters',
  inventory: '/inventory/all-assets',
  serializedAssets: '/inventory/serialized-assets',
  nonSerializedAssets: '/inventory/non-serialized-assets',
  technicianReport: '/reports/technician-report',
  inventoryReport: '/reports/inventory-report',
  workOrdersReport: '/reports/workoders-report',
  conditionsReport: '/reports/conditions-report',
  exceptionsReport: '/reports/exceptions-report',
  appointmentsReport: '/reports/appointments-report',
  userManagement: '/administration/people',
  qualificationManagement: '/administration/qualifications',
  importAssets: '/import-assets',
  administrationOverview: '/administration/overview',
  administrationProjectOverview: '/administration/project-overview',
  administrationProjects: '/administration/projects',
  administrationTechnicianReport: '/administration/reports/technician-report',
  administrationAssignmentsReport: '/administration/reports/assignments-report',
  administrationPayrollReport: '/administration/reports/payroll-report',
  analysisChartsView: '/analysis/chart-view',
  analysisMapView: '/analysis/map-view',
  planning: '/planning',
  administrationUpdateProject: '/administration/update-project',
  projectToolsServiceType: '/project-tools/service-type',
  dashboardExternalLink: '/dashboard/:projectId/:workOrderId',
  appointment: '/appointment',
  snapshots: '/snapshots',
  peopleEmailReporting: '/people/email-reporting',
  whatsNew: '/features',
  csrPortal: '/csr-portal'
}
