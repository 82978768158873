import React from 'react';
import { Button } from 'semantic-ui-react';

const MapSearch = ({ onSearch, placeholder, onChange, value }) => (
  <React.Fragment>
    <div className='field ui input' style={{ paddingRight: '10px' }}>
      <input onChange={onChange} value={value} type='text' placeholder={placeholder} style={{ width: '400px', paddingRight: '5px' }} />
    </div>
    <Button primary onClick={onSearch}>Search</Button>
  </React.Fragment>
)

export default MapSearch;