import React from 'react';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import './styles.css';

const InventoryStatusByMeterTypeTableView = ({ data, assetLocation }) => (
  <Grid className='projects-table inventory-levels-by-form-factor-table' data={data}>
    <GridToolbar>
      <b className='inventory-levels-by-form-factor-table-title'>{`${assetLocation ? assetLocation + ' - ' : ''}`}Register - Inventory Status</b>
    </GridToolbar>
    <GridNoRecords>
      No data
    </GridNoRecords>
    <Column field='size' title='Size' width='250px' />
    <Column field='partNumber' title='Part Number' width='200px' />
    <Column field='totalAssetsAvailable' title='Total Assets Received' width='200px' />
    <Column field='remainingAssetsAvailable' title='Total Avail Assets (In Stock)' width='240px' />
    <Column field='assetsNeededToShip' title='Assets Needed (to Ship)' width='240px' />
  </Grid>
)

export default InventoryStatusByMeterTypeTableView;