import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Segment } from 'semantic-ui-react';
import ExportReportBaseInfoModal from './exportReportBaseInfoModal';
import ReportConditionModal from './exportReportConditionModal';
import ExportReportTemplateModal from './exportReportTemplateModal';
import ExportReportTemplateTransformationModal from './exportReportTemplateTransformationModal';
import ExportReportTemplateTransformationConditionModal from './exportReportTemplateTransformationConditionModal';
import ExportReportConditionsTable from './exportReportConditionsTable';
import ExportReportTemplateTable from './exportReportTemplateTable';
import ExportReportBeforeUploadTable from './exportReportBeforeUploadTable';
import ExportReportBeforeUploadModal from './exportReportBeforeUploadModal';
import AppContext from '../../../../core/context/app.context';
import { updateProject } from '../../../../core/services/project.service';
import SuccessMessage from '../../../../shared/components/messages/message';
import ErrorMessage from '../../../../shared/components/messages/error';

const REPORT_TABS = {
  Conditions: 'Conditions',
  Template: 'Template',
  'Before Upload': 'Before Upload'
}

const ExportStep = ({ project, onUpdateProjectExportConfig }) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [workOrderType, setWorkOrderType] = useState('');
  const [reports, setReports] = useState({});
  const [currentReport, setCurrentReport] = useState();
  const [reportTab, setReportTab] = useState(REPORT_TABS.Template);
  const [showCreateExportReportBaseInfoModal, setShowCreateExportReportBaseInfoModal] = useState(false);
  const [showEditExportReportBaseInfoModal, setShowEditExportReportBaseInfoModal] = useState(false);
  const [showReportConditionModal, setShowReportConditionModal] = useState(false);
  const [showReportTemplateModal, setShowReportTemplateModal] = useState(false);
  const [showTemplateTransformationModal, setShowTemplateTransformationModal] = useState(false);
  const [showTemplateTransformationConditionModal, setShowTemplateTransformationConditionModal] = useState(false);
  const [selectedReportCondition, setSelectedReportCondition] = useState(null);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);
  const [selectedReportTemplateTransformation, setSelectedReportTemplateTransformation] = useState(null);
  const [showBeforeUploadModal, setShowBeforeUploadModal] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);

  useEffect(() => {
    let projectExportConfig = project.projectExportConfig;
    setWorkOrderType(project.projectWorkOrderType);
    if (projectExportConfig) {
      projectExportConfig = JSON.parse(projectExportConfig);
      setWorkOrderType(projectExportConfig.workOrderType);
      projectExportConfig.reports.forEach(report => reports[report.type] = report);
      setReports({ ...reports });
      setCurrentReport({ ...reports[Object.keys(reports)[0]] });
    }
  }, [project]);

  useEffect(() => {
    if (successNotification || errorNotification) {
      setTimeout(() => {
        setSuccessNotification(false);
        setErrorNotification(false);
      }, 3000);
    }
  }, [successNotification, errorNotification]);

  const onDeleteReport = () => {
    delete reports[currentReport.type];
    if (Object.keys(reports).length > 0) {
      setCurrentReport({ ...reports[Object.keys(reports)[0]] });
    }
    setReports({ ...reports });
  }

  const onCurrentReportChange = (event, { value }) => {
    const report = reports[value];
    setCurrentReport({ ...report });
  }

  const onSaveReportBaseInfo = (report) => {
    if (showEditExportReportBaseInfoModal && currentReport) {
      if (currentReport.type !== report.type) {
        delete reports[currentReport.type];
      }
    }
    reports[report.type] = report;
    setReports({ ...reports });
    setCurrentReport({ ...report });
    if (showCreateExportReportBaseInfoModal) {
      setShowCreateExportReportBaseInfoModal(false);
    } else {
      setShowEditExportReportBaseInfoModal(false);
    }
  }

  const onReportTabClick = (event, { name }) => {
    setReportTab(name);
  }

  const onSaveCondition = (condition, id) => {
    if (id !== null && id !== undefined) {
      reports[currentReport.type].conditions[id] = condition;
      reports[currentReport.type].conditions = [...reports[currentReport.type].conditions];
      setSelectedReportCondition(null);
    } else {
      reports[currentReport.type].conditions = [...reports[currentReport.type].conditions, condition];
    }
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
    setShowReportConditionModal(false);
  }

  const onCloseReportConditionModal = () => {
    setSelectedReportCondition(null);
    setShowReportConditionModal(false);
  }

  const onSaveTemplate = (template, id) => {
    if (id !== null && id !== undefined) {
      const transformations = reports[currentReport.type].template[id].transformations;
      if (transformations) {
        reports[currentReport.type].template[id] = { ...template, transformations: [...transformations] };
      } else {
        reports[currentReport.type].template[id] = { ...template };
      }
      reports[currentReport.type].template = [...reports[currentReport.type].template];
      setSelectedReportTemplate(null);
    } else {
      reports[currentReport.type].template = [...reports[currentReport.type].template, template];
    }
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
    setShowReportTemplateModal(false);
  }

  const onSaveTransformation = (transformation) => {
    const { id } = selectedReportTemplate;
    if (!reports[currentReport.type].template[id].transformations) {
      reports[currentReport.type].template[id].transformations = [transformation];
    } else {
      reports[currentReport.type].template[id].transformations = [...reports[currentReport.type].template[id].transformations, transformation];
    }
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
    setShowTemplateTransformationModal(false);
    setSelectedReportTemplate(null);
  }

  const onSaveTransformationCondition = (transformationCondition) => {
    if (!reports[currentReport.type].template[selectedReportTemplate.id].transformations[selectedReportTemplateTransformation.id].conditions) {
      reports[currentReport.type].template[selectedReportTemplate.id].transformations[selectedReportTemplateTransformation.id].conditions = [transformationCondition];
    } else {
      reports[currentReport.type].template[selectedReportTemplate.id].transformations[selectedReportTemplateTransformation.id].conditions = [
        ...reports[currentReport.type].template[selectedReportTemplate.id].transformations[selectedReportTemplateTransformation.id].conditions,
        transformationCondition
      ];
    }
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
    setShowTemplateTransformationConditionModal(false);
    setSelectedReportTemplate(null);
    setSelectedReportTemplateTransformation(null);
  }

  const onCloseTemplateTransformationModal = () => {
    setSelectedReportTemplate(null);
    setShowTemplateTransformationModal(false);
  }

  const onCloseTemplateTransformationConditionModal = () => {
    setSelectedReportTemplate(null);
    setSelectedReportTemplateTransformation(null);
    setShowTemplateTransformationConditionModal(false);
  }

  const onDeleteConditions = (id) => {
    reports[currentReport.type].conditions.splice(id, 1);
    reports[currentReport.type].conditions = [...reports[currentReport.type].conditions];
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
  }

  const onEditCondition = (id) => {
    setSelectedReportCondition({ ...currentReport.conditions[id], id });
    setShowReportConditionModal(true);
  }

  const onEditTemplate = (id) => {
    setSelectedReportTemplate({ ...currentReport.template[id], id });
    setShowReportTemplateModal(true);
  }

  const reorderTemplate = (orderBy) => {
    const sort = (a, b) => {
      return orderBy.findIndex(n => n.column_name === a.column_name) - orderBy.findIndex(n => n.column_name === b.column_name);
    }
    const template = currentReport.template.sort(sort);
    setCurrentReport({ ...reports[currentReport.type], template });
  }

  const onDeleteTemplate = (id) => {
    reports[currentReport.type].template.splice(id, 1);
    reports[currentReport.type].template = [...reports[currentReport.type].template];
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
  }

  const onCloseReportTemplateModal = () => {
    setSelectedReportTemplate(null);
    setShowReportTemplateModal(false);
  }

  const onAttachTransformation = (id) => {
    setSelectedReportTemplate({ ...currentReport.template[id], id });
    setShowTemplateTransformationModal(true);
  }

  const onDeleteTemplateTransformations = (templateId, transformationId) => {
    reports[currentReport.type].template[templateId].transformations.splice(transformationId, 1);
    reports[currentReport.type].template[templateId].transformations = [...reports[currentReport.type].template[templateId].transformations];
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
  }

  const onAttachTransformationCondition = (templateId, transformationId) => {
    setSelectedReportTemplate({ ...currentReport.template[templateId], id: templateId });
    setSelectedReportTemplateTransformation({ ...currentReport.template[templateId].transformations[transformationId], id: transformationId });
    setShowTemplateTransformationConditionModal(true);
  }

  const onDeleteTemplateTransformationConditions = (conditionId, transformationId, templateId) => {
    reports[currentReport.type].template[templateId].transformations[transformationId].conditions.splice(conditionId, 1);
    reports[currentReport.type].template[templateId].transformations[transformationId].conditions = [
      ...reports[currentReport.type].template[templateId].transformations[transformationId].conditions
    ];
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
  }

  const onDeleteBeforeUpload = (id) => {
    reports[currentReport.type].before_upload.splice(id, 1);
    reports[currentReport.type].before_upload = [...reports[currentReport.type].before_upload];
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
  }

  const onSaveBeforeUpload = (beforeUpload) => {
    if (!reports[currentReport.type].before_upload) {
      reports[currentReport.type].before_upload = [beforeUpload];
    } else {
      reports[currentReport.type].before_upload = [...reports[currentReport.type].before_upload, beforeUpload];
    }
    setReports({ ...reports });
    setCurrentReport({ ...reports[currentReport.type] });
    setShowBeforeUploadModal(false);
  }

  const onSave = async () => {
    setLoading(true);
    setSuccessNotification(false);
    setErrorNotification(false);
    const projectExportConfig = JSON.stringify({
      workOrderType,
      reports: Object.values(reports)
    }, null, 0);
    try {
      setSuccessNotification(false);
      setErrorNotification(false);
      await updateProject({ id: project.id, projectExportConfig });
      onUpdateProjectExportConfig(projectExportConfig);
      context.setTenant(context.currentTenant);
      setSuccessNotification(true);
    } catch (error) {
      setErrorNotification(true);
    }
    setLoading(false);
  }

  return (
    <>
      <Segment.Group className='segment-no-padding'>
        <Segment>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <span style={{ paddingRight: '10px' }}>Report Template:</span>
              <Dropdown
                className='right-margin'
                placeholder='Report Template'
                selection
                value={workOrderType || ''}
                options={[]}
                onChange={() => { }} />
              <Button>Load</Button>
            </div>
            {workOrderType && <div>
              <Button onClick={() => setShowCreateExportReportBaseInfoModal(true)}>Create New Report</Button>
            </div>}
          </div>
        </Segment>
      </Segment.Group>

      {showCreateExportReportBaseInfoModal && <ExportReportBaseInfoModal
        onSaveReportBaseInfo={onSaveReportBaseInfo}
        closeModal={() => setShowCreateExportReportBaseInfoModal(false)}
        showModal={showCreateExportReportBaseInfoModal} />}

      {showEditExportReportBaseInfoModal && <ExportReportBaseInfoModal
        onSaveReportBaseInfo={onSaveReportBaseInfo}
        selectedReport={currentReport}
        closeModal={() => setShowEditExportReportBaseInfoModal(false)}
        showModal={showEditExportReportBaseInfoModal} />}

      {showReportConditionModal && <ReportConditionModal
        selectedReportCondition={selectedReportCondition}
        onSaveCondition={onSaveCondition}
        workOrderType={workOrderType}
        showModal={showReportConditionModal}
        closeModal={onCloseReportConditionModal} />}

      {showReportTemplateModal && <ExportReportTemplateModal
        selectedReportTemplate={selectedReportTemplate}
        onSaveTemplate={onSaveTemplate}
        workOrderType={workOrderType}
        showModal={showReportTemplateModal}
        closeModal={onCloseReportTemplateModal} />}

      {showTemplateTransformationModal && <ExportReportTemplateTransformationModal
        workOrderType={workOrderType}
        onSaveTransformation={onSaveTransformation}
        showModal={showTemplateTransformationModal}
        closeModal={onCloseTemplateTransformationModal} />}

      {showTemplateTransformationConditionModal && <ExportReportTemplateTransformationConditionModal
        workOrderType={workOrderType}
        onSaveTransformationCondition={onSaveTransformationCondition}
        showModal={showTemplateTransformationConditionModal}
        closeModal={onCloseTemplateTransformationConditionModal} />}

      {showBeforeUploadModal && <ExportReportBeforeUploadModal
        showModal={showBeforeUploadModal}
        closeModal={() => setShowBeforeUploadModal(false)}
        onSaveBeforeUpload={onSaveBeforeUpload} />}

      {reports && Object.keys(reports).length > 0 && <Segment.Group>
        <Segment>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <span style={{ paddingRight: '10px' }}>Report:</span>
              </div>
              <Dropdown
                placeholder='Report'
                selection
                value={(currentReport && currentReport.type) || ''}
                options={Object.keys(reports).map(report => Object.assign({}, { key: report, value: report, text: report }))}
                onChange={onCurrentReportChange} />
              <div style={{ margin: 0, marginLeft: '30px', marginRight: '30px' }}>
                <Menu>
                  <Menu.Item
                    name={REPORT_TABS.Template}
                    active={reportTab === REPORT_TABS.Template}
                    content={REPORT_TABS.Template}
                    onClick={onReportTabClick}
                  />
                  <Menu.Item
                    name={REPORT_TABS.Conditions}
                    active={reportTab === REPORT_TABS.Conditions}
                    content={REPORT_TABS.Conditions}
                    onClick={onReportTabClick}
                  />
                  <Menu.Item
                    name={REPORT_TABS['Before Upload']}
                    active={reportTab === REPORT_TABS['Before Upload']}
                    content={REPORT_TABS['Before Upload']}
                    onClick={onReportTabClick}
                  />
                </Menu>
              </div>
              {reportTab === REPORT_TABS.Conditions && <Button onClick={() => setShowReportConditionModal(true)}>Add Condition</Button>}
              {reportTab === REPORT_TABS.Template && <Button onClick={() => setShowReportTemplateModal(true)}>Add Template</Button>}
              {reportTab === REPORT_TABS['Before Upload'] && <Button onClick={() => setShowBeforeUploadModal(true)}>Add "Before Upload" Logic</Button>}
            </div>
            {currentReport && <div style={{ display: 'flex' }}>
              <Button onClick={() => setShowEditExportReportBaseInfoModal(true)}>Edit</Button>
              <Button onClick={onDeleteReport}>Delete</Button>
            </div>}
          </div>
        </Segment>
      </Segment.Group>}

      {reportTab === REPORT_TABS.Conditions && <ExportReportConditionsTable
        onEditCondition={onEditCondition}
        onDeleteConditions={onDeleteConditions}
        conditions={(currentReport && currentReport.conditions) || []} />}

      {reportTab === REPORT_TABS.Template && <ExportReportTemplateTable
        reorderTemplate={reorderTemplate}
        currentReportType={(currentReport && currentReport.type) || ''}
        onDeleteTemplateTransformationConditions={onDeleteTemplateTransformationConditions}
        onAttachTransformationCondition={onAttachTransformationCondition}
        onDeleteTemplateTransformations={onDeleteTemplateTransformations}
        onAttachTransformation={onAttachTransformation}
        onEditTemplate={onEditTemplate}
        onDeleteTemplate={onDeleteTemplate}
        template={(currentReport && currentReport.template) || []} />}

      {reportTab === REPORT_TABS['Before Upload'] && <ExportReportBeforeUploadTable
        onDeleteBeforeUpload={onDeleteBeforeUpload}
        beforeUpload={(currentReport && currentReport.before_upload) || []} />}

      {successNotification && <div style={{ marginTop: 10 }}>
        <SuccessMessage header message='Your data has been saved' />
      </div>}
      {errorNotification && <div style={{ marginTop: 10 }}>
        <ErrorMessage header message='Failed to save the data' />
      </div>}

      <div style={{ textAlign: 'right', marginTop: '10px', paddingBottom: '10px' }}>
        <Button onClick={onSave} loading={loading} positive content='Save' />
      </div>
    </>
  )
}

export default ExportStep;