import React, { useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { addComment } from '../../../core/services/comment.service';
import MODAL_TYPES from '../../../constants/qaModalTypes';
import { updateWorkOrder } from '../../../core/services/workOrder.service';

const ESCALATION_CATEGORIES = {
  'Mismatch Old ID': 'Mismatch Old ID',
  'Mismatch New ID': 'Mismatch New ID',
  'Missing Photo(s)': 'Missing Photo(s)',
  'Unable to Verify Out Read': 'Unable to Verify Out Read',
  'Unable to Verify ID Number(s)': 'Unable to Verify ID Number(s)',
  'Other': 'Other'
}

const CommentModal = ({ modalType, userId, workOrderId, showModal, closeModal, description, submitWithoutComment, onCancel, ...rest }) => {

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [escalationCategory, setEscalationCategory] = useState('');

  const onCommentChange = (event, { value }) => {
    setComment(value);
  }

  const handleKeyPress = async (event) => {
    if (event.charCode === 13) {
      if ((!comment && submitWithoutComment) || (comment)) {
        event.preventDefault();
        onSubmit();
      }
    }
  }

  const onEscalationCategoryChange = (event, { value }) => {
    setEscalationCategory(value);
  }

  const onSubmit = async () => {
    if ((!comment && submitWithoutComment) || (comment)) {
      setLoading(true);
      rest.onSubmit ? await rest.onSubmit(comment) : await addComment(userId, workOrderId, comment);
      if (escalationCategory) {
        updateWorkOrder({ id: workOrderId, workOrderEscalatedCategory: escalationCategory });
      }
      setLoading(false);
    }
    closeModal();
  }

  const isSubmitDisabled = () => {
    if (submitWithoutComment) {
      return false;
    }
    if (rest.showEscalationCategories && modalType === MODAL_TYPES.ESCALATED) {
      return comment.length === 0 || !escalationCategory;
    }
    return comment.length === 0;
  }

  return (
    <div onKeyPress={handleKeyPress}>
      <Modal dimmer={{ style: {zIndex: 1005}}} size='tiny' open={showModal}>
        <Modal.Header>Add comment</Modal.Header>
        <Modal.Content>
          {description && <p>{description}</p>}
          {rest.showEscalationCategories && modalType === MODAL_TYPES.ESCALATED && (
            <div style={{ paddingBottom: 10 }}>
              <Form.Select
                fluid
                onChange={onEscalationCategoryChange}
                options={[
                  { key: ESCALATION_CATEGORIES['Mismatch Old ID'], text: ESCALATION_CATEGORIES['Mismatch Old ID'], value: ESCALATION_CATEGORIES['Mismatch Old ID'] },
                  { key: ESCALATION_CATEGORIES['Mismatch New ID'], text: ESCALATION_CATEGORIES['Mismatch New ID'], value: ESCALATION_CATEGORIES['Mismatch New ID'] },
                  { key: ESCALATION_CATEGORIES['Missing Photo(s)'], text: ESCALATION_CATEGORIES['Missing Photo(s)'], value: ESCALATION_CATEGORIES['Missing Photo(s)'] },
                  { key: ESCALATION_CATEGORIES['Unable to Verify Out Read'], text: ESCALATION_CATEGORIES['Unable to Verify Out Read'], value: ESCALATION_CATEGORIES['Unable to Verify Out Read'] },
                  { key: ESCALATION_CATEGORIES['Unable to Verify ID Number(s)'], text: ESCALATION_CATEGORIES['Unable to Verify ID Number(s)'], value: ESCALATION_CATEGORIES['Unable to Verify ID Number(s)'] },
                  { key: ESCALATION_CATEGORIES['Other'], text: ESCALATION_CATEGORIES['Other'], value: ESCALATION_CATEGORIES['Other'] },
                ]}
                placeholder='Escalation Category'
              />
            </div>)}
          <Form.Input fluid autoFocus onChange={onCommentChange} type='text' placeholder={submitWithoutComment ? 'Comment (Optional)' : 'Comment'} />
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={loading} onClick={onCancel ? onCancel : closeModal} negative>Cancel</Button>
          <Button loading={loading} onClick={onSubmit} disabled={isSubmitDisabled()} positive icon='checkmark' labelPosition='right' content='Confirm' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}
export default CommentModal;