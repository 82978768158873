import {
  SET_LAYOUT_TYPE,
  SET_MAP,
  SET_LAYOUT_DATA,
  SET_GEOJSON_FILE_URL,
  SET_GEOJSON_SIZE,
  ADD_SELECTED_WORKORDERS,
  DELETE_SELECTED_WORKORDERS,
  SET_SHOW_ASSIGNEMNTS_MODAL,
  SET_SHOW_GROUP_MODAL,
  RESET_MAP_STATE,
  SET_SHOW_MAP_TOOLBAR,
  SET_SELECTED_WORKORDERS,
  RESIZE_MAP,
  SET_POLYGONS,
  SET_POPUP,
  SET_LAYOUT_ICON_POPUP,
  SET_STYLE,
  SET_OVERLAY_GEOJSON_FILE_URL,
  SET_LOADING,
  SET_PREVIOUS_MAP,
  SET_SEARCH_BY,
  SET_FILTER_BY,
  SET_GEOJSON_DATA_COPY,
  SET_MAP_STATUS_ID,
  SET_HAS_CACHED_VERSION,
  SET_MOUNTED,
  SET_MAP_REF,
  SET_CUSTOM_DATA_URL,
  SET_DISABLE_FILTERS,
  SET_DISABLE_VIEWS,
  SET_CUSTOM_VIEW_DATA
} from '../types/map.types'
import * as mapService from '../../services/map.service';
import { listWorkOrdersMapLayoutData } from '../../services/workOrder.service';
import { MAP_LAYOUT_TYPES } from '../../../constants/mapLayout';
import { filterData, fitBounds, getFeaturePaint } from '../../../helpers/map';
import _ from 'lodash';
import MAP_SOURCES from '../../../routes/workorders/constants/mapSource';
import WORKORDER_TYPES from '../../../constants/workOrderTypes';
import { getMapStatusByIdAndStatus } from '../../services/mapStatus.service';

export const setMapRef = (map) => {
  return {
    type: SET_MAP_REF,
    payload: map
  }
}
export const setShowAssignmentsModal = (showAssignmentsModal) => {
  return {
    type: SET_SHOW_ASSIGNEMNTS_MODAL,
    payload: showAssignmentsModal
  }
}

export const setShowGroupModal = (showGroupModal) => {
  return {
    type: SET_SHOW_GROUP_MODAL,
    payload: showGroupModal
  }
}

export const setLayoutType = (layoutType) => {
  return {
    type: SET_LAYOUT_TYPE,
    payload: layoutType
  }
}

export const setLoading = (loading, loadingMessage) => {
  return {
    type: SET_LOADING,
    payload: {
      loading,
      loadingMessage
    }
  }
}

export const setMap = (map) => {
  return {
    type: SET_MAP,
    payload: map
  }
}

export const setLayoutData = (layoutData) => {
  return {
    type: SET_LAYOUT_DATA,
    payload: layoutData
  }
}

export const setGeoJsonFileUrl = (geoJsonFileUrl) => {
  return {
    type: SET_GEOJSON_FILE_URL,
    payload: geoJsonFileUrl
  }
}

export const setGeoJsonSize = (geoJsonSize) => {
  return {
    type: SET_GEOJSON_SIZE,
    payload: geoJsonSize
  }
}

export const setOverlayGeoJsonFileUrl = (overlayGeoJsonFileUrl) => {
  return {
    type: SET_OVERLAY_GEOJSON_FILE_URL,
    payload: overlayGeoJsonFileUrl
  }
}

export const setCustomDataUrl = (customDataUrl) => {
  return {
    type: SET_CUSTOM_DATA_URL,
    payload: customDataUrl
  }
}

export const setPopup = (popup) => {
  return {
    type: SET_POPUP,
    payload: popup
  }
}

export const setLayoutIconPopup = (layoutIconPopup) => {
  return {
    type: SET_LAYOUT_ICON_POPUP,
    payload: layoutIconPopup
  }
}

export const setShowMapToolbar = (showMapToolbar) => {
  return {
    type: SET_SHOW_MAP_TOOLBAR,
    payload: showMapToolbar
  }
}

export const setSelectedWorkOrders = (selectedWorkOrders) => {
  return {
    type: SET_SELECTED_WORKORDERS,
    payload: selectedWorkOrders
  }
}

export const setPolygons = (polygons) => {
  return {
    type: SET_POLYGONS,
    payload: polygons
  }
}

export const setStyle = (style) => {
  return {
    type: SET_STYLE,
    payload: style
  }
}

export const setPreviousMap = (previousMap) => {
  return {
    type: SET_PREVIOUS_MAP,
    payload: previousMap
  }
}

export const setMapStatusId = (mapStatusId) => {
  return {
    type: SET_MAP_STATUS_ID,
    payload: mapStatusId
  }
}

export const setGeoJsonDataCopy = (geoJsonDataCopy) => {
  return {
    type: SET_GEOJSON_DATA_COPY,
    payload: geoJsonDataCopy
  }
}

export const setHasCachedVersion = (hasCachedVersion) => {
  return {
    type: SET_HAS_CACHED_VERSION,
    payload: hasCachedVersion
  }
}

export const setDisableFilters = (disableFilters) => {
  return {
    type: SET_DISABLE_FILTERS,
    payload: disableFilters
  }
}

export const setDisableViews = (disableViews) => {
  return {
    type: SET_DISABLE_VIEWS,
    payload: disableViews
  }
}

export const setMounted = (mounted) => {
  return {
    type: SET_MOUNTED,
    payload: mounted
  }
}

export const setCustomViewData = (customViewData) => {
  return {
    type: SET_CUSTOM_VIEW_DATA,
    payload: customViewData
  }
}

export const searchMap = (projectWorkOrderType, zoom = true, projectTimeZone) => {
  return async (dispatch, getState) => {
    const { map, filterBy, geoJsonDataCopy, layoutType, layoutData, geoJsonFileUrl } = getState().map;
    let { searchBy } = getState().map;
    let filters;
    if (filterBy && filterBy.length > 0) {
      filters = filterBy.map(filter => filter.filterName);
    }

    const getFilterValue = (filter) => {
      return filter.split(':')[1].substring(1);
    }
    // Map will use coords (i.e X90Y-90 ) as key, and value will be true or false base on if duplicates are found
    const clusterMap = new Map();
    for (const cluster of geoJsonDataCopy.features) {
      const { geometry: { coordinates: [x, y] } } = cluster;
      const cluString = `X${x}Y${y}`;
      if (clusterMap.has(cluString)) {
        clusterMap.set(cluString, true);
      } else {
        clusterMap.set(cluString, false);
      }
    }
    if (!searchBy && !filters) {
      map.getSource(MAP_SOURCES.MAP_SOURCE_ID).setData(filterData(geoJsonDataCopy, layoutType, layoutData, map));
      if (zoom) {
        fitBounds(map, geoJsonDataCopy);
      }
      dispatch(setGeoJsonSize(geoJsonDataCopy.features.length));
    } else {
      geoJsonFileUrl.features = geoJsonDataCopy.features.filter(({ properties, geometry: { coordinates } }) => {
        const {
          num: workOrderNumber,
          omn: oldMeterNumber,
          nmn: newMeterNumber,
          str: street,
          cn: customerName,
          rl: recordLocator,
          nradn: newRadioNumber,
          nregn: newRegisterNumber,
          nman: newMeterAssetNumber,
          ws: workOrderStatus,
          dc: districtCode,
          rc: routeCode,
          appt: workOrderNeedsAppointment,
          nst: workOrderNeedsSiteTest,
          wdel: workOrderIsDeleted,
          sc: substationCode,
          cc: circuitCode,
          bcc: billingCycleCode,
          li: locationId,
          an: accountNumber,
          bc: bookCode,
          mff: meterFormFactor,
          asgn: workOrderAssignments,
          grps: workOrderGroups,
          ms: meterSize,
          reas: workOrderReasonCodes,
          asd: appointmentStartDate,
        } = properties;

        const workOrderFlaggedAs3strike = properties['3s'];

        let matchWorkOrderNumber = false;
        let matchOldMeterNumber = false;
        let matchNewMeterNumber = false;
        let matchStreet = false;
        let matchCustomerName = false;
        let matchRecordLocator = false;
        let matchNewRadioNumber = false;
        let matchNewRegisterNumber = false;
        let matchNewMeterAssetNumber = false;
        let matchAccountNumber = false;
        let matchLocationId = false;


        if (searchBy) {
          searchBy = searchBy.toLowerCase();
          if (locationId !== null && locationId !== undefined) {
            matchLocationId = locationId.toLowerCase().includes(searchBy);
          }
          if (accountNumber !== null && accountNumber !== undefined) {
            matchAccountNumber = accountNumber.toLowerCase().includes(searchBy);
          }
          if (workOrderNumber !== null && workOrderNumber !== undefined) {
            matchWorkOrderNumber = workOrderNumber.toLowerCase().includes(searchBy);
          }
          if (oldMeterNumber !== null && oldMeterNumber !== undefined) {
            matchOldMeterNumber = oldMeterNumber.toLowerCase().includes(searchBy);
          }
          if (newMeterNumber !== null && newMeterNumber !== undefined) {
            matchNewMeterNumber = newMeterNumber.toLowerCase().includes(searchBy);
          }
          if (street !== null && street !== undefined) {
            matchStreet = street.toLowerCase().includes(searchBy);
          }
          if (customerName !== null && customerName !== undefined) {
            matchCustomerName = customerName.toLowerCase().includes(searchBy);
          }
          if (recordLocator !== null && recordLocator !== undefined) {
            matchRecordLocator = recordLocator.toLowerCase().includes(searchBy);
          }

          switch (projectWorkOrderType) {
            case WORKORDER_TYPES.WaterDeploy:
            case WORKORDER_TYPES.WaterSiteSurvey: {
              if (newRadioNumber !== null && newRadioNumber !== undefined) {
                matchNewRadioNumber = newRadioNumber.toLowerCase().includes(searchBy);
              }
              if (newRegisterNumber !== null && newRegisterNumber !== undefined) {
                matchNewRegisterNumber = newRegisterNumber.toLowerCase().includes(searchBy);
              }
              break;
            }
            default: {
              if (newMeterAssetNumber !== null && newMeterAssetNumber !== undefined) {
                matchNewMeterAssetNumber = newMeterAssetNumber.toLowerCase().includes(searchBy);
              }
            }
          }
        }

        let workOrderStatusFilters = [];
        let districtCodeFilters = [];
        let routeCodeFilters = [];
        let substationCodeFilters = [];
        let circuitCodeFilters = [];
        let billingCycleCodeFilters = [];
        let bookCodeFilters = [];
        let meterFormFactorFilters = [];
        let workOrderAssignmentsFilters = [];
        let workOrderGroupsFilters = [];
        let meterSizeFilters = [];

        let matchWorkOrderStatusFilter = true;
        let matchDistrictCodeFilter = true;
        let matchRouteCodeFilter = true;
        let matchWorkOrderNotInGroupFilter = true;
        let matchWorkOrderNeedsAppointmentFilter = true;
        let matchWorkOrderNeedsSiteTestFilter = true;
        let matchWorkOrderFlaggedAs3strikeFilter = true;
        let matchWorkOrderIsDeletedFilter = true;
        let matchSubstationCodeFilter = true;
        let matchCircuitCodeFilter = true;
        let matchBillingCycleCodeFilter = true;
        let matchBookCodeFilter = true;
        let matchMeterFormFactorFilter = true;
        let matchWorkOrderAssignmentsFilter = true;
        let matchWorkOrderGroupsFilter = true;
        let matchMeterSizeFilter = true;
        let matchWorkOrderReasonCodesFilter = true;
        let matchClusterFilter = true;
        let matchAppointmentsTodayFilter = true;
        let matchAppointmentsThisWeekFilter = true;
        let matchAppointmentsMissedFilter = true;

        if (filters) {
          for (let filter of filters) {
            if (filter.startsWith('Meter Size:')) {
              meterSizeFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Group:')) {
              workOrderGroupsFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('User:')) {
              workOrderAssignmentsFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Form Factor:')) {
              meterFormFactorFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Book:')) {
              bookCodeFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Cycle:')) {
              billingCycleCodeFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Circuit:')) {
              circuitCodeFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Substation:')) {
              substationCodeFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Status:')) {
              workOrderStatusFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('District:')) {
              districtCodeFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Route:')) {
              routeCodeFilters.push(getFilterValue(filter));
            }
            if (filter.startsWith('Custom: Is Not In Group')) {
              if (workOrderGroups !== null && workOrderGroups !== undefined) {
                if (workOrderGroups.length > 0) matchWorkOrderNotInGroupFilter = false;
              }
            }
            if (filter.startsWith('Custom: Needs Appointment')) {
              if (workOrderNeedsAppointment !== null && workOrderNeedsAppointment !== undefined) {
                matchWorkOrderNeedsAppointmentFilter = workOrderNeedsAppointment;
              }
            }
            if (filter.startsWith('Custom: Flagged as Specialty')) {
              if (workOrderNeedsSiteTest !== null && workOrderNeedsSiteTest !== undefined) {
                matchWorkOrderNeedsSiteTestFilter = workOrderNeedsSiteTest;
              }
            }
            if (filter.startsWith('Custom: RTU Closed/3-strike')) {
              if (workOrderFlaggedAs3strike !== null && workOrderFlaggedAs3strike !== undefined) {
                matchWorkOrderFlaggedAs3strikeFilter = workOrderFlaggedAs3strike;
              }
            }
            if (filter.startsWith('Custom: Is Deleted')) {
              if (workOrderIsDeleted !== null && workOrderIsDeleted !== undefined) {
                matchWorkOrderIsDeletedFilter = workOrderIsDeleted;
              }
            }
            if (filter.startsWith('Custom: RTU Review')) {
              if (workOrderReasonCodes !== null && workOrderReasonCodes !== undefined && workOrderStatus !== null && workOrderStatus !== undefined) {
                matchWorkOrderReasonCodesFilter = workOrderReasonCodes.indexOf('RTU Review') > -1;
              } else {
                matchWorkOrderReasonCodesFilter = false;
              }
            }
            if (filter.startsWith('Custom: Clusters')) {
              const [x, y] = coordinates;
              const cluString = `X${x}Y${y}`;
              if (!clusterMap.get(cluString)) {
                matchClusterFilter = false;
              }
            }
            if (filter.startsWith('Custom: Appointments (Today)')) {
              if (appointmentStartDate) {
                const today = new Date(new Date().toLocaleString('en-US', { timeZone: projectTimeZone }));
                const target = new Date(new Date(appointmentStartDate).toLocaleString('en-US', { timeZone: projectTimeZone }));
                
                return target.getDate() === today.getDate() &&
                  target.getMonth() === today.getMonth() &&
                  target.getFullYear() === today.getFullYear();
              }
              return false;
            }
            if (filter.startsWith('Custom: Appointments (This Week)')) {
              if (appointmentStartDate) {
                const today = new Date(new Date().toLocaleString('en-US', { timeZone: projectTimeZone }));
                
                const startOfWeek = new Date(today);
                const endOfWeek = new Date(today);
                
                startOfWeek.setHours(0, 0, 0, 0);
                startOfWeek.setDate(today.getDate() - today.getDay() + 1);
                
                endOfWeek.setHours(23, 59, 59, 999);
                endOfWeek.setDate(today.getDate() - today.getDay() + 7);
                
                const target = new Date(new Date(appointmentStartDate).toLocaleString('en-US', { timeZone: projectTimeZone }));
                target.setHours(0, 0, 0, 0);
                
                return target >= startOfWeek && target <= endOfWeek;
              }
              return false;
            }
            if (filter.startsWith('Custom: Appointments (Missed)')) {
              if (appointmentStartDate) {
                const today = new Date(new Date().toLocaleString('en-US', { timeZone: projectTimeZone }));
                const target = new Date(new Date(appointmentStartDate).toLocaleString('en-US', { timeZone: projectTimeZone }));
                
                today.setHours(0, 0, 0, 0);
                target.setHours(0, 0, 0, 0);
                
                return target < today;
              }
              return false;
            }
          }
        }

        if (meterSizeFilters.length > 0) {
          if (meterSize === null || meterSize === undefined) {
            matchMeterSizeFilter = false;
          } else {
            matchMeterSizeFilter = meterSizeFilters.indexOf(meterSize) > -1;
          }
        }
        if (workOrderGroupsFilters.length > 0) {
          if (workOrderGroups === null || workOrderGroups === undefined) {
            matchWorkOrderGroupsFilter = false;
          } else {
            let hasGroup = false;
            for (let group of workOrderGroups.split(' | ')) {
              if (workOrderGroupsFilters.indexOf(group) > -1) {
                hasGroup = true;
                break;
              }
            }
            if (!hasGroup) {
              matchWorkOrderGroupsFilter = false;
            }
          }
        }
        if (workOrderAssignmentsFilters.length > 0) {
          if (workOrderAssignments === null || workOrderAssignments === undefined) {
            matchWorkOrderAssignmentsFilter = false;
          } else {
            for (let user of workOrderAssignmentsFilters) {
              matchWorkOrderAssignmentsFilter = workOrderAssignments.indexOf(user) > -1;
              if (!matchWorkOrderAssignmentsFilter) {
                return false;
              }
            }
          }
        }
        if (meterFormFactorFilters.length > 0) {
          if (meterFormFactor === null || meterFormFactor === undefined) {
            matchMeterFormFactorFilter = false;
          } else {
            matchMeterFormFactorFilter = meterFormFactorFilters.indexOf(meterFormFactor) > -1;
          }
        }
        if (bookCodeFilters.length > 0) {
          if (bookCode === null || bookCode === undefined) {
            matchBookCodeFilter = false;
          } else {
            matchBookCodeFilter = bookCodeFilters.indexOf(bookCode) > -1;
          }
        }
        if (billingCycleCodeFilters.length > 0) {
          if (billingCycleCode === null || billingCycleCode === undefined) {
            matchBillingCycleCodeFilter = false;
          } else {
            matchBillingCycleCodeFilter = billingCycleCodeFilters.indexOf(billingCycleCode) > -1;
          }
        }
        if (circuitCodeFilters.length > 0) {
          if (circuitCode === null || circuitCode === undefined) {
            matchCircuitCodeFilter = false;
          } else {
            matchCircuitCodeFilter = circuitCodeFilters.indexOf(circuitCode) > -1;
          }
        }
        if (substationCodeFilters.length > 0) {
          if (substationCode === null || substationCode === undefined) {
            matchSubstationCodeFilter = false;
          } else {
            matchSubstationCodeFilter = substationCodeFilters.indexOf(substationCode) > -1;
          }
        }
        if (workOrderStatusFilters.length > 0) {
          if (workOrderStatus === null || workOrderStatus === undefined) {
            matchWorkOrderStatusFilter = false;
          } else {
            matchWorkOrderStatusFilter = workOrderStatusFilters.indexOf(workOrderStatus) > -1;
          }
        }
        if (districtCodeFilters.length > 0) {
          if (districtCode === null || districtCode === undefined) {
            matchDistrictCodeFilter = false;
          } else {
            matchDistrictCodeFilter = districtCodeFilters.indexOf(districtCode) > -1;
          }
        }
        if (routeCodeFilters.length > 0) {
          if (routeCode === null || routeCode === undefined) {
            matchRouteCodeFilter = false;
          } else {
            matchRouteCodeFilter = routeCodeFilters.indexOf(routeCode) > -1;
          }
        }

        const matchSearchBy = (
          matchLocationId ||
          matchAccountNumber ||
          matchWorkOrderNumber ||
          matchOldMeterNumber ||
          matchNewMeterNumber ||
          matchStreet ||
          matchCustomerName ||
          matchRecordLocator ||
          matchNewRadioNumber ||
          matchNewRegisterNumber ||
          matchNewMeterAssetNumber
        );

        const matchFilterBy = (
          matchWorkOrderStatusFilter &&
          matchDistrictCodeFilter &&
          matchRouteCodeFilter &&
          matchWorkOrderNotInGroupFilter &&
          matchWorkOrderNeedsAppointmentFilter &&
          matchWorkOrderNeedsSiteTestFilter &&
          matchWorkOrderFlaggedAs3strikeFilter &&
          matchWorkOrderIsDeletedFilter &&
          matchSubstationCodeFilter &&
          matchCircuitCodeFilter &&
          matchBillingCycleCodeFilter &&
          matchBookCodeFilter &&
          matchMeterFormFactorFilter &&
          matchWorkOrderAssignmentsFilter &&
          matchWorkOrderGroupsFilter &&
          matchMeterSizeFilter &&
          matchWorkOrderReasonCodesFilter &&
          matchClusterFilter &&
          matchAppointmentsTodayFilter &&
          matchAppointmentsThisWeekFilter &&
          matchAppointmentsMissedFilter
        );

        if (searchBy && !filters) {
          return matchSearchBy;
        } else if (!searchBy && filters) {
          return matchFilterBy;
        }
        return matchSearchBy && matchFilterBy;
      });
      map.getSource(MAP_SOURCES.MAP_SOURCE_ID).setData(filterData(geoJsonFileUrl, layoutType, layoutData, map));
      if (geoJsonFileUrl.features.length > 0 && zoom) {
        fitBounds(map, geoJsonFileUrl);
      }
      dispatch(setGeoJsonSize(geoJsonFileUrl.features.length));
    }
  }
}

export const fetchCachedData = (geoJsonFileUrl, overlayGeoJsonFileUrl, customDataUrl) => {
  return async (dispatch, getState) => {
    const { layoutType } = getState().map;
    dispatch(setLoading(true, 'Retrieving Cached Locations Data...'));
    dispatch(setHasCachedVersion(true));
    const response = await fetch(geoJsonFileUrl, { method: 'GET' });
    const data = await response.json();
    dispatch(setGeoJsonDataCopy({ ...data, features: _.cloneDeep(data.features) }));
    dispatch(setGeoJsonSize(data && data.features ? data.features.length : 0));
    if (overlayGeoJsonFileUrl) {
      dispatch(setOverlayGeoJsonFileUrl(overlayGeoJsonFileUrl));
    }
    if (customDataUrl) {
      const response = await fetch(customDataUrl, { method: 'GET' });
      const data = await response.json();
      dispatch(setCustomViewData(data && data.features ? data.features : []));
      dispatch(setCustomDataUrl(customDataUrl));
    }
    dispatch(setGeoJsonFileUrl(filterData(data, layoutType)));
    dispatch(setLoading(false, ''));
  }
}

export const syncGeoJsonData = (url, projectId, workOrderType, projectTimeZone) => {
  return async (dispatch, getState) => {
    dispatch(setSelectedWorkOrders([]));
    dispatch(setShowAssignmentsModal(false));
    dispatch(setShowGroupModal(false));
    dispatch(setPolygons({}));
    dispatch(setPopup(null));

    const response = await fetch(url, { method: 'GET' });
    const data = await response.json();
    const updatedWorkOrders = data.features;

    let { map, geoJsonDataCopy } = getState().map;

    const mapData = map.getSource(MAP_SOURCES.MAP_SOURCE_ID)._data;
    const { features } = mapData;

    updatedWorkOrders.forEach(workOrder => {
      const feature = features.find(feature => feature.properties.id === workOrder.properties.id);
      feature.properties.ws = workOrder.properties.ws;
      feature.properties.asgn = workOrder.properties.asgn;

      const geoJsonFileFeature = geoJsonDataCopy.features.find(feature => feature.properties.id === workOrder.properties.id);

      geoJsonFileFeature.properties.ws = workOrder.properties.ws;
      geoJsonFileFeature.properties.asgn = workOrder.properties.asgn;
    });

    map.getSource(MAP_SOURCES.MAP_SOURCE_ID).setData(mapData);

    dispatch(setGeoJsonDataCopy({ ...geoJsonDataCopy }));
    dispatch(searchMap(workOrderType, false, projectTimeZone));

    await mapService.invokeGeoJsonFileCreation(projectId, workOrderType);
  }
}

export const createGeoJson = ({ projectId, workOrderType, init, usePreviousLocation, search, refresh, style }) => {
  return async (dispatch, getState) => {
    if (init) {
      dispatch(setLoading(true, 'Loading...'));
      dispatch(setHasCachedVersion(false));
    }

    const { map } = getState().map;

    if ((map && map.isStyleLoaded() && init) || style) {
      dispatch(setSearchBy(''));
      dispatch(setFilterBy([]));
      dispatch(setLayoutType(MAP_LAYOUT_TYPES.WorkOrderStatus));
    }

    if (style) {
      const { geoJsonDataCopy, layoutType } = getState().map;
      dispatch(setStyle(style));
      dispatch(setGeoJsonFileUrl(filterData(geoJsonDataCopy, layoutType)));
      dispatch(setGeoJsonSize(geoJsonDataCopy.features.length));
      return;
    }

    if (map) {
      dispatch(setPreviousMap({
        zoom: map.getZoom(),
        center: map.getCenter()
      }));
    }
    dispatch(setSelectedWorkOrders([]));
    dispatch(setShowAssignmentsModal(false));
    dispatch(setShowGroupModal(false));
    dispatch(setPolygons({}));
    dispatch(setPopup(null));

    if (init) {
      const { url: geoJsonFileUrl, overlayUrl: overlayGeoJsonFileUrl, customDataUrl } = await mapService.getMapDataUrl(projectId);
      if (geoJsonFileUrl) {
        dispatch(setDisableFilters(true));
        dispatch(setDisableViews(true));
        dispatch(fetchCachedData(geoJsonFileUrl, overlayGeoJsonFileUrl, customDataUrl));
      } else {
        dispatch(setLoading(true, 'Creating Current Locations Data...'));
      }
    }

    if (!init && !refresh) {
      dispatch(setLoading(true, 'Creating Current Locations Data...'));
    }

    let mapStatusId = await mapService.invokeGeoJsonFileCreation(projectId, workOrderType);
    dispatch(setMapStatusId(mapStatusId));

    const fetchMapData = setInterval(async () => {
      const { mapStatusId } = getState().map;
      if (mapStatusId) {
        const mapStatus = await getMapStatusByIdAndStatus(mapStatusId);
        if (mapStatus) {
          clearInterval(fetchMapData);
          const geoJsonFileUrl = mapStatus.url;
          const overlayGeoJsonFileUrl = mapStatus.overlayUrl;
          const customDataUrl = mapStatus.customDataUrl;

          const { hasCachedVersion } = getState().map;

          if (!hasCachedVersion) {
            dispatch(setLoading(true, 'Retrieving Locations Data...'));
          }

          const response = await fetch(geoJsonFileUrl, { method: 'GET' });
          const data = await response.json();

          dispatch(setGeoJsonDataCopy({ ...data, features: _.cloneDeep(data.features) }));
          dispatch(setGeoJsonSize(data && data.features ? data.features.length : 0));

          const { layoutType, layoutData } = getState().map;

          let { map } = getState().map;

          dispatch(setMapStatusId(''));

          if (init && !hasCachedVersion) {
            if (overlayGeoJsonFileUrl) {
              dispatch(setOverlayGeoJsonFileUrl(overlayGeoJsonFileUrl));
            }
            if (customDataUrl) {
              const response = await fetch(customDataUrl, { method: 'GET' });
              const data = await response.json();
              dispatch(setCustomViewData(data && data.features ? data.features : []));
              dispatch(setCustomDataUrl(customDataUrl));
            }
            dispatch(setGeoJsonFileUrl(filterData(data, layoutType)));
          }

          if (!map) {
            map = await new Promise((resolve, reject) => {
              const waitForMap = setInterval(() => {
                console.log('wait for the map to init');
                const { map, mounted } = getState().map;
                if (!mounted) {
                  clearInterval(waitForMap);
                  reject('Map is no longer active');
                }
                if (map) {
                  clearInterval(waitForMap);
                  dispatch(setMapRef(map));
                  resolve(map);
                }
              }, 250);
            });
          }

          if (map) {
            if (hasCachedVersion || !init) {
              map.getSource(MAP_SOURCES.MAP_SOURCE_ID).setData(filterData(data, layoutType, layoutData, map));
              if (overlayGeoJsonFileUrl) {
                map.getSource(MAP_SOURCES.OVERLAY_SOURCE_ID).setData(overlayGeoJsonFileUrl);
              }
              if (customDataUrl) {
                map.getSource(MAP_SOURCES.CUSTOM).setData(customDataUrl);
              }
            }
            if (search && !init) {
              dispatch(searchMap(workOrderType, usePreviousLocation ? false : true));
            }
          }

          dispatch(setLoading(false, ''));
          const { disableFilters, disableViews } = getState().map;
          if (disableFilters) {
            dispatch(setDisableFilters(false));
          }
          if (disableViews) {
            dispatch(setDisableViews(false));
          }
        }
      } else {
        clearInterval(fetchMapData);
      }
    }, 2000);
  }
}

export const getLayoutData = (projectId, workOrderType) => {
  return async (dispatch) => {
    const layoutData = await listWorkOrdersMapLayoutData(projectId, workOrderType);
    dispatch(setLayoutData(layoutData));
  }
}

export const addSelectedWorkOrders = (polygon) => {
  return {
    type: ADD_SELECTED_WORKORDERS,
    payload: polygon
  }
}

export const deleteSelectedWorkOrders = (polygonId) => {
  return {
    type: DELETE_SELECTED_WORKORDERS,
    payload: polygonId
  }
}

export const resetMapState = () => {
  return {
    type: RESET_MAP_STATE
  }
}

export const resizeMap = () => {
  return {
    type: RESIZE_MAP
  }
}

export const setSearchBy = (searchBy) => {
  return {
    type: SET_SEARCH_BY,
    payload: searchBy
  }
}

export const setFilterBy = (filterBy) => {
  return {
    type: SET_FILTER_BY,
    payload: filterBy
  }
}