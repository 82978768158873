import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Menu as SemanticMenu } from 'semantic-ui-react';
import TABS from '../../../constants/workOrderDetailsTabs';
import * as workOrderDetailsMenuActions from '../../../core/redux/actions/workOrderDetailsMenu.actions';

const Menu = ({
  info: Info,
  additional: Additional,
  audit: Audit,
  comments: Comments,
  history: History,
  showInfo,
  showAdditional,
  showAudit,
  showComments,
  showHistory,
  setShowInfo,
  setShowAdditional,
  setShowAudit,
  setShowComments,
  setShowHistory,
}) => {
  useEffect(() => {
    return () => setShowInfo();
  }, []);

  return (
    <React.Fragment>
      <div>
        <SemanticMenu secondary pointing size='large'>
          <SemanticMenu.Item
            name={TABS.INFO}
            active={showInfo}
            onClick={setShowInfo}
          />
          <SemanticMenu.Item
            name={TABS.ADDITIONAL}
            active={showAdditional}
            onClick={setShowAdditional}
          />
          <SemanticMenu.Item
            name={TABS.AUDIT}
            active={showAudit}
            onClick={setShowAudit}
          />
          <SemanticMenu.Item
            name={TABS.COMMENTS}
            active={showComments}
            onClick={setShowComments}
          />
          <SemanticMenu.Item
            name={TABS.HISTORY}
            active={showHistory}
            onClick={setShowHistory}
          />
        </SemanticMenu>
      </div>
      <div className='details-menu-content'>
        {showInfo && <Info />}
        {showAdditional && <Additional />}
        {showAudit && <Audit />}
        {showComments && <Comments />}
        {showHistory && <History />}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ workOrderDetailsMenu }) => {
  return {
    showInfo: workOrderDetailsMenu.showInfo,
    showAdditional: workOrderDetailsMenu.showAdditional,
    showAudit: workOrderDetailsMenu.showAudit,
    showComments: workOrderDetailsMenu.showComments,
    showHistory: workOrderDetailsMenu.showHistory,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowInfo: () => dispatch(workOrderDetailsMenuActions.setShowInfo()),
    setShowAdditional: () => dispatch(workOrderDetailsMenuActions.setShowAdditional()),
    setShowAudit: () => dispatch(workOrderDetailsMenuActions.setShowAudit()),
    setShowComments: () => dispatch(workOrderDetailsMenuActions.setShowComments()),
    setShowHistory: () => dispatch(workOrderDetailsMenuActions.setShowHistory())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);