import gql from 'graphql-tag';

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($userId: ID!, $reportingPreferences: String) {
    updateUser(input:{
      id: $userId
      reportingPreferences: $reportingPreferences
    }) {
      id
    }
  }
`;