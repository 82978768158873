import * as appointmentService from '../../services/appointment.service';
import { addComment } from '../../services/comment.service';
import { 
  SET_NEW_APPOINTMENT,
  SET_SHOW_APPOINTMENT_ALREADY_EXIST_MODAL,
  SET_WORKORDER_NOT_FOUND_ERROR,
  SET_DATA, SET_LOADING,
  SET_UPDATE_DETAILS_REQUIRED,
  SET_ERROR,
  SET_VALIDATING,
  SET_VALIDATION_MESSAGE,
  RESET_SCHEDULER_STATE,
} from '../types/scheduler.types';

const formatDate = (date, timeZone) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit', ...timeZone ? { timeZone } : {} })
  const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
  return `${month}/${day}/${year}`;
}

const formatTime = (date, timeZone) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit', ...timeZone ? { timeZone } : {} })
  const [{ value: hour }, , { value: minute }, , { value: dayPeriod }] = dateTimeFormat.formatToParts(date)
  return `${hour}:${minute} ${dayPeriod}`;
}

export const setWorkOrderNotFoundError = (workOrderNotFoundError) => {
  return {
    type: SET_WORKORDER_NOT_FOUND_ERROR,
    payload: workOrderNotFoundError
  }
}

export const setShowAppointmentAlreadyExistModal = (showAppointmentAlreadyExistModal) => {
  return {
    type: SET_SHOW_APPOINTMENT_ALREADY_EXIST_MODAL,
    payload: showAppointmentAlreadyExistModal
  }
}

export const setNewAppointment = (newAppointment) => {
  return {
    type: SET_NEW_APPOINTMENT,
    payload: newAppointment
  }
}

export const setData = (data) => {
  return {
    type: SET_DATA,
    payload: data
  }
}

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

export const setValidating = (validating) => {
  return {
    type: SET_VALIDATING,
    payload: validating
  }
}

export const setValidationMessage = (validationMessage) => {
  return {
    type: SET_VALIDATION_MESSAGE,
    payload: validationMessage
  }
}

export const setUpdateDetailsRequired = (updateDetailsRequired) => {
  return {
    type: SET_UPDATE_DETAILS_REQUIRED,
    payload: updateDetailsRequired
  }
}

export const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error
  }
}

export const resetSchedulerState = () => {
  return {
    type: RESET_SCHEDULER_STATE
  }
}

function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
} 

export const createAppointment = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowAppointmentAlreadyExistModal(false));
      dispatch(setLoading(true));
      dispatch(setUpdateDetailsRequired(false));
      const newAppointment = getState().scheduler.newAppointment;
      const appointmentStartDate = new Date(newAppointment.appointmentStartDate);
      const appointmentEndDate = new Date(newAppointment.appointmentEndDate);
      newAppointment.appointmentStartDate = appointmentStartDate.toISOString();
      newAppointment.appointmentEndDate = appointmentEndDate.toISOString();
      await appointmentService.createAppointment(newAppointment);
      await addComment(newAppointment.schedulerUserId, newAppointment.workOrderId, `Appointment: ${formatDate(appointmentStartDate, newAppointment.timeZone)} from ${formatTime(appointmentStartDate, newAppointment.timeZone)} to ${formatTime(appointmentEndDate, newAppointment.timeZone)} - ${newAppointment.appointmentNotes}`);
      dispatch(setNewAppointment(null));
      const appStartDate = addMonths(appointmentStartDate,-1).toISOString();
      const appEndDate = addMonths(appointmentEndDate,2).toISOString();
      dispatch(listAppointmentsByProject(newAppointment.projectId,appStartDate,appEndDate));
      dispatch(setLoading(false));
      dispatch(setUpdateDetailsRequired(true));
    } catch (error) {
      dispatch(setNewAppointment(null));
      dispatch(setLoading(false));
      dispatch(setError(error.message || 'Unexpected error'));
    }
  }
}

export const updateAppointment = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUpdateDetailsRequired(false));
      const newAppointment = getState().scheduler.newAppointment;
      const appointmentStartDate = new Date(newAppointment.appointmentStartDate);
      const appointmentEndDate = new Date(newAppointment.appointmentEndDate);
      newAppointment.appointmentStartDate = appointmentStartDate.toISOString();
      newAppointment.appointmentEndDate = appointmentEndDate.toISOString();
      await appointmentService.updateAppointment(newAppointment);
      await addComment(newAppointment.schedulerUserId, newAppointment.workOrderId, `Updated Appointment: ${formatDate(appointmentStartDate, newAppointment.timeZone)} from ${formatTime(appointmentStartDate, newAppointment.timeZone)} to ${formatTime(appointmentEndDate, newAppointment.timeZone)} - ${newAppointment.appointmentNotes}`);
      const appStartDate = addMonths(appointmentStartDate,-1).toISOString();
      const appEndDate = addMonths(appointmentEndDate,2).toISOString();
      dispatch(listAppointmentsByProject(newAppointment.projectId,appStartDate,appEndDate));
      dispatch(setNewAppointment(null));
      dispatch(setLoading(false));
      dispatch(setUpdateDetailsRequired(true));
    } catch (error) {
      console.log(JSON.stringify(error));
      dispatch(setNewAppointment(null));
      dispatch(setLoading(false));
      dispatch(setError(error.message || 'Unexpected error'));
    }
  }
}

export const deleteAppointment = (appointmentId, projectId, workOrderId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setUpdateDetailsRequired(false));
    await appointmentService.deleteAppointment(appointmentId, projectId, workOrderId);
    dispatch(listAppointmentsByProject(projectId));
    dispatch(setLoading(false));
    dispatch(setUpdateDetailsRequired(true));
  }
}

export const listAppointmentsByProject = (projectId, startDate, endDate, filterDuplicateAddress ) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const data = await appointmentService.listAppointmentsByProject(projectId, startDate, endDate, filterDuplicateAddress);
    dispatch(setData(data));
    dispatch(setLoading(false));
  }
}

export const listDuplicateLocationsForWorkOrders = (workOrderId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const data = await appointmentService.listDuplicateLocationsForWorkOrders(workOrderId);
    dispatch(setData(data));
    dispatch(setLoading(false));
  }
}