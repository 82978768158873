import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Table, Image, Popup } from 'semantic-ui-react';
import Loader from '../loader';
import { isEnabled } from '../../../core/services/auth.service';
import ROLES from '../../../constants/roles';
import StaticMap from '../static-map';
import Gallery from '../gallery';
import CommentModal from '../modal/comment';
import * as workOrderService from '../../../core/services/workOrder.service';
import { getAppointmentByWorkOrder } from '../../../core/services/appointment.service';
import TabSectionDivider from './tabSectionDivider';
import CustomDataTab from './customDataTab';
import AuditDataTab from './auditDataTab';
import HistoryTabContent from './historyTabContent';
import CommentsTabContent from './commentsTabContent';
import Splitter from './splitter';
import QualityAssuranceHeader from './qualityAssuranceHeader';
import Header from './header';
import DetailsActions from './detailsActions';
import QualityAssuranceDetailsActions from './qualityAssuranceDetailsActions';
import EDIT_ICON from '../../../assets/icons/baseline_edit_black_18dp.png';
import EditModal from '../../../routes/quality-assurance/components/modal/editModal';
import VerifyModal from '../../../routes/quality-assurance/components/modal/verifyModal';
import WarningIcon from './qualityAssuranceWarningIcon';
import { MODAL_TYPES_LABELS as TAB_TYPE } from '../../../constants/qaModalTypes';
import Menu from './menu';
import DetailsAppointmentModal from './detailsAppointmentModal';

const InfoTabContent = ({
  currentProject,
  selectedWorkOrder,
  user,
  projectHasBlackOuts,
  rtuWorkOrder,
  updateWorkOrder,
  updateWorkOrderNewMeterNumber,
  handleEscalate,
  isQualityAssurance,
  getWorkOrderDetails,
  oldMeterReadingHighThreshold,
  qualityAssuranceType,
  allowOldMeterNumberEditing,
  setDetailsOpenCount,
  detailsOpenCount,
  setShowAppointmentModal,
  appointmentManagement,
  qaDoubleEntryVerification,
  verifyWorkOrder,
  workOrdersVerified
}) => {
  const [show3StrikeCommentModal, setShow3StrikeCommentModal] = useState(false);
  const [showBlackoutCommentModal, setShowBlackoutCommentModal] = useState(false);
  const [showNeedsSiteTestCommentModal, setShowNeedsSiteTestCommentModal] = useState(false);
  const [showNeedsAppointmentCommentModal, setShowNeedsAppointmentCommentModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(true);
  
  const [showOldMeterKwReadingEditModal, setShowOldMeterKwReadingEditModal] = useState(false);
  const [showOldMeterKwhReadingEditModal, setShowOldMeterKwhReadingEditModal] = useState(false);
  const [showOldMeterKvaReadingEditModal, setShowOldMeterKvaReadingEditModal] = useState(false);
  const [showOldMeterKvarReadingEditModal, setShowOldMeterKvarReadingEditModal] = useState(false);
  const [showNewMeterNumberEditModal, setShowNewMeterNumberEditModal] = useState(false);
  const [showOldMeterNumberEditModal, setShowOldMeterNumberEditModal] = useState(false);

  const scrollRef = React.createRef();

  useEffect(() => {
    if (detailsOpenCount === 0) {
      isQualityAssurance && scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setDetailsOpenCount(1);
    }
  }, []);

  const on3StrikeChange = () => {
    setShow3StrikeCommentModal(true);
  }

  const onNeedsAppointmentChange = () => {
    if (selectedWorkOrder.workOrderNeedsAppointment) {
      updateNeedsAppointment();
    } else {
      setShowNeedsAppointmentCommentModal(true);
    }
  }

  const updateNeedsAppointment = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderNeedsAppointment: !selectedWorkOrder.workOrderNeedsAppointment });
  }

  const onCloseNeedsAppointmentCommentModal = () => {
    setShowNeedsAppointmentCommentModal(false);
    updateNeedsAppointment();
  }

  const onClose3StrikeCommentModal = async () => {
    setShow3StrikeCommentModal(false);
    await rtuWorkOrder({ id: selectedWorkOrder.id, workOrderFlaggedAs3strike: !selectedWorkOrder.workOrderFlaggedAs3strike, currentUserId: user.id });
  }

  const onFlaggedAsDamagedChange = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderFlaggedAsDamaged: !selectedWorkOrder.workOrderFlaggedAsDamaged });
  }

  const onFlaggedAsCNC = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderFlaggedAsCNC: !selectedWorkOrder.workOrderFlaggedAsCNC });
  }

  const onFlaggedAsPlatedChange = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderFlaggedAsPlated: !selectedWorkOrder.workOrderFlaggedAsPlated });
  }

  const onBlackoutChange = () => {
    setShowBlackoutCommentModal(true);
  }

  const onCloseBlackoutCommentModal = async () => {
    setShowBlackoutCommentModal(false);
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderIsInBlackOut: !selectedWorkOrder.workOrderIsInBlackOut, workOrderReviewedUserId: user.id })
  }

  const updateNeedsSiteTest = async () => {
    await updateWorkOrder({ id: selectedWorkOrder.id, workOrderNeedsSiteTest: !selectedWorkOrder.workOrderNeedsSiteTest });
  }

  const onNeedsSiteTestChange = () => {
    if (selectedWorkOrder.workOrderNeedsSiteTest) {
      updateNeedsSiteTest();
    } else {
      setShowNeedsSiteTestCommentModal(true);
    }
  }

  const onCloseNeedsSiteTestCommentModal = async () => {
    setShowNeedsSiteTestCommentModal(false);
    await updateNeedsSiteTest();
  }

  const updateOldMeterKwReading = async (id, oldMeterKwReading) => {
    await updateWorkOrder({ id, oldMeterKwReading, workOrderReviewedUserId: user.id });
  }

  const updateOldMeterKwhReading = async (id, oldMeterKwhReading) => {
    await updateWorkOrder({ id, oldMeterKwhReading, workOrderReviewedUserId: user.id });
  }

  const updateOldMeterKvaReading = async (id, oldMeterKvaReading) => {
    await updateWorkOrder({ id, oldMeterKvaReading, workOrderReviewedUserId: user.id });
  }

  const updateOldMeterKvarReading = async (id, oldMeterKvarReading) => {
    await updateWorkOrder({ id, oldMeterKvarReading, workOrderReviewedUserId: user.id });
  }

  const updateNewMeterNumber = async (id, newMeterNumber) => {
    await updateWorkOrderNewMeterNumber(id, newMeterNumber);
  }

  const updateOldMeterNumber = async (id, oldMeterNumber) => {
    await updateWorkOrder({ id, oldMeterNumber, workOrderReviewedUserId: user.id });
  }

  const shouldSetWarningSign = (primary, secondary) => {
    return oldMeterReadingHighThreshold && ((selectedWorkOrder[primary] < selectedWorkOrder[secondary]) || (selectedWorkOrder[primary] - selectedWorkOrder[secondary]) > oldMeterReadingHighThreshold);
  }

  const formatQualityAssuranceField = (primary, secondary, onClick, condition, label, warningIcon) => {
    if (isQualityAssurance) {
      if (selectedWorkOrder[condition]) {
        return <span style={{ fontWeight: 'bold' }}>{label}</span>
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {selectedWorkOrder && selectedWorkOrder[primary] !== null && selectedWorkOrder[primary] !== undefined ? <span>{selectedWorkOrder[primary]}{warningIcon && shouldSetWarningSign(primary, secondary) ? <WarningIcon /> : null}</span> : ''}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Image src={EDIT_ICON} style={{ cursor: 'pointer' }} onClick={onClick} />
          </div>
        </div>
      )
    }
    return <div>{selectedWorkOrder && selectedWorkOrder[primary] !== null && selectedWorkOrder[primary] !== undefined ? <span>{selectedWorkOrder[primary]}{warningIcon && shouldSetWarningSign(primary, secondary) ? <WarningIcon /> : null}</span> : ''}</div>
  }

  const formatOldMeterNumberField = () => {
    if (allowOldMeterNumberEditing) {
      return formatQualityAssuranceField('oldMeterNumber', '', () => setShowOldMeterNumberEditModal(true));
    }
    return <div>{selectedWorkOrder && selectedWorkOrder.oldMeterNumber !== null && selectedWorkOrder.oldMeterNumber !== undefined ? selectedWorkOrder.oldMeterNumber : ''}</div>
  }

  const hasAppointment = async (currentProject, selectedWorkOrder) => {
    if (currentProject) {
      let currentAppointment = "";
      currentAppointment = await getAppointmentByWorkOrder(currentProject.id, selectedWorkOrder.id);
        if (currentAppointment) return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      {show3StrikeCommentModal && <CommentModal
        description={selectedWorkOrder.workOrderFlaggedAs3strike ?
          'Caution: You are adding this work order to scope. Please add a comment.' :
          'Caution: You are removing this work order from scope and returning it to the utility. Please add a comment.'}
        showModal={show3StrikeCommentModal}
        closeModal={onClose3StrikeCommentModal}
        onCancel={() => setShow3StrikeCommentModal(false)}
        workOrderId={selectedWorkOrder.id}
        userId={user.id} />}
      {showBlackoutCommentModal && <CommentModal
        showModal={showBlackoutCommentModal}
        submitWithoutComment={true}
        closeModal={onCloseBlackoutCommentModal}
        onCancel={() => setShowBlackoutCommentModal(false)}
        workOrderId={selectedWorkOrder.id}
        userId={user.id} />}
      {showNeedsSiteTestCommentModal && <CommentModal
        submitWithoutComment={true}
        closeModal={onCloseNeedsSiteTestCommentModal}
        onCancel={() => setShowNeedsSiteTestCommentModal(false)}
        showModal={showNeedsSiteTestCommentModal}
        workOrderId={selectedWorkOrder.id}
        userId={user.id} />}
      {showNeedsAppointmentCommentModal && <CommentModal
        closeModal={onCloseNeedsAppointmentCommentModal}
        onCancel={() => setShowNeedsAppointmentCommentModal(false)}
        showModal={showNeedsAppointmentCommentModal}
        workOrderId={selectedWorkOrder.id}
        userId={user.id} />}

      {showOldMeterKwReadingEditModal && <EditModal
        title={'Old Meter KW Reading'}
        closeModal={() => setShowOldMeterKwReadingEditModal(false)}
        showModal={showOldMeterKwReadingEditModal}
        inputValue={selectedWorkOrder.oldMeterKwReading}
        workOrderId={selectedWorkOrder.id}
        getWorkOrderDetails={getWorkOrderDetails}
        update={updateOldMeterKwReading}
        modalType='number' />}
      {showOldMeterKwhReadingEditModal && <EditModal
        title={'Old Meter KWH Reading'}
        closeModal={() => setShowOldMeterKwhReadingEditModal(false)}
        showModal={showOldMeterKwhReadingEditModal}
        inputValue={selectedWorkOrder.oldMeterKwhReading}
        workOrderId={selectedWorkOrder.id}
        getWorkOrderDetails={getWorkOrderDetails}
        update={updateOldMeterKwhReading}
        modalType='number' />}
      {showOldMeterKvaReadingEditModal && <EditModal
        title={'Old Meter KVA Reading'}
        closeModal={() => setShowOldMeterKvaReadingEditModal(false)}
        showModal={showOldMeterKvaReadingEditModal}
        inputValue={selectedWorkOrder.oldMeterKvaReading}
        workOrderId={selectedWorkOrder.id}
        getWorkOrderDetails={getWorkOrderDetails}
        update={updateOldMeterKvaReading}
        modalType='number' />}
      {showOldMeterKvarReadingEditModal && <EditModal
        title={'Old Meter KVAR Reading'}
        closeModal={() => setShowOldMeterKvarReadingEditModal(false)}
        showModal={showOldMeterKvarReadingEditModal}
        inputValue={selectedWorkOrder.oldMeterKvarReading}
        workOrderId={selectedWorkOrder.id}
        getWorkOrderDetails={getWorkOrderDetails}
        update={updateOldMeterKvarReading}
        modalType='number' />}
      {showNewMeterNumberEditModal && <EditModal
        title={'New Meter Number'}
        closeModal={() => setShowNewMeterNumberEditModal(false)}
        showModal={showNewMeterNumberEditModal}
        inputValue={selectedWorkOrder.newMeterNumber}
        workOrderId={selectedWorkOrder.id}
        getWorkOrderDetails={getWorkOrderDetails}
        update={updateNewMeterNumber}
        modalType='text' />}
      {showOldMeterNumberEditModal && <EditModal
        title={'Old Meter Number'}
        closeModal={() => setShowOldMeterNumberEditModal(false)}
        showModal={showOldMeterNumberEditModal}
        inputValue={selectedWorkOrder.oldMeterNumber}
        workOrderId={selectedWorkOrder.id}
        getWorkOrderDetails={getWorkOrderDetails}
        update={updateOldMeterNumber}
        modalType='text' />}
      {showVerifyModal && qaDoubleEntryVerification && isQualityAssurance && selectedWorkOrder && selectedWorkOrder.id && workOrdersVerified && !workOrdersVerified.includes(selectedWorkOrder.id) && !selectedWorkOrder.workOrderFlaggedAsDamaged && !selectedWorkOrder.workOrderFlaggedAs3strike && <VerifyModal
        addComment
        escalate
        handleEscalate={handleEscalate}
        userId={user.id}
        selectedWorkOrder={selectedWorkOrder}
        photoIndex={0}
        title={'Data Verification'}
        closeModal={setShowVerifyModal}
        showModal={showVerifyModal}
        inputValue={selectedWorkOrder.oldMeterKwhReading ? selectedWorkOrder.oldMeterKwhReading : selectedWorkOrder.oldMeterReading}
        workOrderId={selectedWorkOrder.id}
        getWorkOrderDetails={getWorkOrderDetails}
        update={updateOldMeterKwhReading}
        verifyWorkOrder={verifyWorkOrder}
        modalType='text' />}

      <TabSectionDivider icon='bar chart' title='Status' />
      <Table definition compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Work Order Status</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderStatus !== null && selectedWorkOrder.workOrderStatus !== undefined ? selectedWorkOrder.workOrderStatus : ''}{selectedWorkOrder && selectedWorkOrder.workOrderEscalatedCategory !== null && selectedWorkOrder.workOrderEscalatedCategory !== undefined ? ' - Category: ' + selectedWorkOrder.workOrderEscalatedCategory : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Last Visit Date</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderLastVisitDate !== null && selectedWorkOrder.workOrderLastVisitDate !== undefined ? selectedWorkOrder.workOrderLastVisitDate : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Technician</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.userName !== null && selectedWorkOrder.userName !== undefined ? selectedWorkOrder.userName : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Assignments</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderAssignments !== null && selectedWorkOrder.workOrderAssignments !== undefined ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.workOrderAssignments}</div> : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Record Locator</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.recordLocator !== null && selectedWorkOrder.recordLocator !== undefined ? selectedWorkOrder.recordLocator : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Appointment-based</Table.Cell>
            <Table.Cell>
              {appointmentManagement ? (
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Popup
                      content='Use the Manage Appointment link to update'
                      trigger={(
                        <Checkbox
                          checked={selectedWorkOrder && selectedWorkOrder.workOrderNeedsAppointment !== null ? selectedWorkOrder.workOrderNeedsAppointment : false}
                          disabled={!hasAppointment(currentProject, selectedWorkOrder)}
                          onChange={onNeedsAppointmentChange}
                        />
                      )}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <span onClick={() => setShowAppointmentModal(true)} style={{ cursor: 'pointer', paddingLeft: 10, color: '#2285D0', 'textDecorationLine': 'underline' }}>Manage Appointment</span>
                  </div>
                </div>
              ) : (
                <Checkbox
                  checked={selectedWorkOrder && selectedWorkOrder.workOrderNeedsAppointment !== null ? selectedWorkOrder.workOrderNeedsAppointment : false}
                  onChange={onNeedsAppointmentChange}
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Flagged As Escalated</Table.Cell>
            <Table.Cell>
              <Checkbox
                disabled={true}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAsEscalated !== null ? selectedWorkOrder.workOrderFlaggedAsEscalated : false} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Flagged As CNC</Table.Cell>
            <Table.Cell>
              <Checkbox
                onChange={onFlaggedAsCNC}
                disabled={!isEnabled(user.userRoles, [ROLES.Admin, ROLES.QualityAssurance, ROLES.ClientAdmin, ROLES.SystemAdmin, ROLES.AccountAdmin]) || !isQualityAssurance}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAsCNC !== null ? selectedWorkOrder.workOrderFlaggedAsCNC : false} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Flagged As AdHoc</Table.Cell>
            <Table.Cell>
              <Checkbox
                disabled={true}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAsAdHoc !== null ? selectedWorkOrder.workOrderFlaggedAsAdHoc : false} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Flagged As RTU/3 Strike</Table.Cell>
            <Table.Cell>
              <Checkbox
                disabled={(!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]) || selectedWorkOrder.workOrderStatus === 'InReview' || selectedWorkOrder.workOrderStatus === 'Closed') || (isQualityAssurance && (qualityAssuranceType !== TAB_TYPE.ESCALATED && qualityAssuranceType !== TAB_TYPE.COMPLETED))}
                onChange={on3StrikeChange}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAs3strike !== null ? selectedWorkOrder.workOrderFlaggedAs3strike : false} />
            </Table.Cell>
          </Table.Row>
          {projectHasBlackOuts && <Table.Row>
            <Table.Cell width={6}>Is In Blackout</Table.Cell>
            <Table.Cell>
              <Checkbox
                disabled={!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.ClientAdmin])}
                onChange={onBlackoutChange}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderIsInBlackOut !== null ? selectedWorkOrder.workOrderIsInBlackOut : false} />
            </Table.Cell>
          </Table.Row>}
          <Table.Row>
            <Table.Cell width={6}>Flagged as Specialty</Table.Cell>
            <Table.Cell>
              <Checkbox
                disabled={isQualityAssurance}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderNeedsSiteTest !== null ? selectedWorkOrder.workOrderNeedsSiteTest : false}
                onChange={onNeedsSiteTestChange} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Reason Codes</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderReasonCodes !== null && selectedWorkOrder.workOrderReasonCodes !== undefined && selectedWorkOrder.workOrderReasonCodes.length > 0 ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.workOrderReasonCodes.join(', ')}</div> : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Deleted Date</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderDeletedDate !== null && selectedWorkOrder.workOrderDeletedDate !== undefined ? selectedWorkOrder.workOrderDeletedDate : ''}</Table.Cell>
          </Table.Row>
          {isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]) && <Table.Row>
            <Table.Cell width={6}>Is Geocoded</Table.Cell>
            <Table.Cell>
              <Checkbox
                disabled={true}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderIsGeocoded !== null ? selectedWorkOrder.workOrderIsGeocoded : false} />
            </Table.Cell>
          </Table.Row>}
        </Table.Body>
      </Table>

      <div ref={scrollRef}>
        <TabSectionDivider icon='sign out' title='Existing Assets' />
        <Table definition compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={6}>Old Meter Number</Table.Cell>
              <Table.Cell>{formatOldMeterNumberField()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Meter Status</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterStatus !== null && selectedWorkOrder.meterStatus !== undefined ? selectedWorkOrder.meterStatus : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Meter Type</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterType !== null && selectedWorkOrder.meterType !== undefined ? selectedWorkOrder.meterType : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Form Factor</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterFormFactor !== null && selectedWorkOrder.meterFormFactor !== undefined ? selectedWorkOrder.meterFormFactor : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Class</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterClass !== null && selectedWorkOrder.meterClass !== undefined ? selectedWorkOrder.meterClass : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Voltage</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterVoltage !== null && selectedWorkOrder.meterVoltage !== undefined ? selectedWorkOrder.meterVoltage : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter Last KWH Reading</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.oldMeterKwhLastReading !== null && selectedWorkOrder.oldMeterKwhLastReading !== undefined ? selectedWorkOrder.oldMeterKwhLastReading : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter KWH Reading</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.oldMeterKwhReading !== null && selectedWorkOrder.oldMeterKwhReading !== undefined ? selectedWorkOrder.oldMeterKwhReading : ''}</span> :
                  formatQualityAssuranceField(
                    'oldMeterKwhReading',
                    'oldMeterKwhLastReading',
                    qaDoubleEntryVerification ? () => setShowOldMeterKwhReadingEditModal(true) : () => setShowOldMeterKwhReadingEditModal(true),
                    'workOrderFlaggedAsDamaged',
                    'DAMAGED',
                    true
                  )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Flagged As Damaged</Table.Cell>
              <Table.Cell>
                <Checkbox
                  onChange={onFlaggedAsDamagedChange}
                  disabled={!isEnabled(user.userRoles, [ROLES.Admin, ROLES.QualityAssurance, ROLES.ClientAdmin, ROLES.SystemAdmin, ROLES.AccountAdmin]) || !isQualityAssurance}
                  checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAsDamaged !== null ? selectedWorkOrder.workOrderFlaggedAsDamaged : false} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter Last KW Reading</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.oldMeterKwLastReading !== null && selectedWorkOrder.oldMeterKwLastReading !== undefined ? selectedWorkOrder.oldMeterKwLastReading : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter KW Reading</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.oldMeterKwReading !== null && selectedWorkOrder.oldMeterKwReading !== undefined ? selectedWorkOrder.oldMeterKwReading : ''}</span> :
                  formatQualityAssuranceField('oldMeterKwReading', '', () => setShowOldMeterKwReadingEditModal(true))}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter Last KVAR Reading</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.oldMeterKvarLastReading !== null && selectedWorkOrder.oldMeterKvarLastReading !== undefined ? selectedWorkOrder.oldMeterKvarLastReading : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter KVAR Reading</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.oldMeterKvarReading !== null && selectedWorkOrder.oldMeterKvarReading !== undefined ? selectedWorkOrder.oldMeterKvarReading : ''}</span> :
                  formatQualityAssuranceField('oldMeterKvarReading', '', () => setShowOldMeterKvarReadingEditModal(true))}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter Last KVA Reading</Table.Cell>
              <Table.Cell>{selectedWorkOrder && selectedWorkOrder.oldMeterKvaLastReading !== null && selectedWorkOrder.oldMeterKvaLastReading !== undefined ? selectedWorkOrder.oldMeterKvaLastReading : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={6}>Old Meter KVA Reading</Table.Cell>
              <Table.Cell>
                {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                  <span>{selectedWorkOrder && selectedWorkOrder.oldMeterKvaReading !== null && selectedWorkOrder.oldMeterKvaReading !== undefined ? selectedWorkOrder.oldMeterKvaReading : ''}</span> :
                  formatQualityAssuranceField('oldMeterKvaReading', '', () => setShowOldMeterKvaReadingEditModal(true))}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <TabSectionDivider icon='sign in' title='New Assets' />
      <Table definition compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>No Meter Install</Table.Cell>
            <Table.Cell>
              <Checkbox
                disabled={!isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.Admin, ROLES.QualityAssurance])}
                onChange={onFlaggedAsPlatedChange}
                checked={selectedWorkOrder && selectedWorkOrder.workOrderFlaggedAsPlated !== null ? selectedWorkOrder.workOrderFlaggedAsPlated : false} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>New Meter Number</Table.Cell>
            <Table.Cell>
              {qualityAssuranceType === TAB_TYPE.COMPLETED ?
                <span>{selectedWorkOrder && selectedWorkOrder.newMeterNumber !== null && selectedWorkOrder.newMeterNumber !== undefined ? selectedWorkOrder.newMeterNumber : ''}</span> :
                formatQualityAssuranceField(
                  'newMeterNumber',
                  '',
                  () => setShowNewMeterNumberEditModal(true),
                  'workOrderFlaggedAsPlated',
                  'PLATED'
                )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Asset Number</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.newMeterAssetNumber !== null && selectedWorkOrder.newMeterAssetNumber !== undefined ? selectedWorkOrder.newMeterAssetNumber : ''}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <TabSectionDivider icon='address card' title='Service' />
      <Table definition compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Service Type</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.serviceType !== null && selectedWorkOrder.serviceType !== undefined ? selectedWorkOrder.serviceType : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Service Description</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.serviceDescription !== null && selectedWorkOrder.serviceDescription !== undefined ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.serviceDescription}</div> : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Location Id</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.locationId !== null && selectedWorkOrder.locationId !== undefined ? selectedWorkOrder.locationId : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Account Number</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.accountNumber !== null && selectedWorkOrder.accountNumber !== undefined ? selectedWorkOrder.accountNumber : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Customer Name</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.customerName !== null && selectedWorkOrder.customerName !== undefined ? selectedWorkOrder.customerName : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Street</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.street !== null && selectedWorkOrder.street !== undefined ? selectedWorkOrder.street : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Home Phone Number</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.homePhoneNumber !== null && selectedWorkOrder.homePhoneNumber !== undefined ? selectedWorkOrder.homePhoneNumber : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Mobile Phone Number</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.mobilePhoneNumber !== null && selectedWorkOrder.mobilePhoneNumber !== undefined ? selectedWorkOrder.mobilePhoneNumber : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Business Phone Number</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.businessPhoneNumber !== null && selectedWorkOrder.businessPhoneNumber !== undefined ? selectedWorkOrder.businessPhoneNumber : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Read Notes</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.meterReadNotes !== null && selectedWorkOrder.meterReadNotes !== undefined ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.meterReadNotes}</div> : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>District Code</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.districtCode !== null && selectedWorkOrder.districtCode !== undefined ? selectedWorkOrder.districtCode : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Billing Cycle</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.billingCycleCode !== null && selectedWorkOrder.billingCycleCode !== undefined ? selectedWorkOrder.billingCycleCode : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Route Code</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.routeCode !== null && selectedWorkOrder.routeCode !== undefined ? selectedWorkOrder.routeCode : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Book Code</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.bookCode !== null && selectedWorkOrder.bookCode !== undefined ? selectedWorkOrder.bookCode : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Substation Code</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.substationCode !== null && selectedWorkOrder.substationCode !== undefined ? selectedWorkOrder.substationCode : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Circuit Code</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.circuitCode !== null && selectedWorkOrder.circuitCode !== undefined ? selectedWorkOrder.circuitCode : ''}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={6}>Site Conditions</Table.Cell>
            <Table.Cell>{selectedWorkOrder && selectedWorkOrder.workOrderSiteConditions && selectedWorkOrder.workOrderSiteConditions.length > 0 ? selectedWorkOrder.workOrderSiteConditions.join(', ') : ''}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

const MeterDeployDetailsModal = ({
  user,
  showModal,
  closeModal,
  selectedWorkOrder,
  modalDetailsLoading,
  photoIndex,
  onGallerySlide,
  onNext,
  currentProject,
  isQualityAssurance,
  qualityAssuranceHeaderLabelColor,
  qualityAssuranceType,
  workOrders,
  handleApproveAndNext,
  handleEscalate,
  handleReject,
  handleApprove,
  fileInputRef,
  replacePhoto,
  showAddPhotoModal,
  verifyWorkOrder,
  workOrdersVerified,
  getWorkOrderDetails,
  getWorkOrdersWithStatusInReview,
  getWorkOrdersWithStatusEscalated,
  getWorkOrdersWithStatusCompleted,
  setDetailsOpenCount,
  detailsOpenCount,
  onReplacePhotoClick,
  setModalDetailsLoading,
  hideNext,
  ...rest
}) => {
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [closeWorkOrderUponApproval, setCloseWorkOrderUponApproval] = useState(false);

  useEffect(() => {
    if (selectedWorkOrder && selectedWorkOrder.customDataFields) {
      let isRework = selectedWorkOrder.customDataFields.findIndex(({ key }) => key === 'reworkReason') > -1;
      setCloseWorkOrderUponApproval(isRework);
    } else {
      setCloseWorkOrderUponApproval(false);
    }
  }, [selectedWorkOrder]);

  const onCloseWorkOrderUponApprovalChange = (e, { checked }) => {
    setCloseWorkOrderUponApproval(checked);
  }

  const rtuWorkOrder = async (workOrder) => {
    setModalDetailsLoading(true);
    if (qualityAssuranceType === TAB_TYPE.ESCALATED) {
      await workOrderService.rtuWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType });
      getWorkOrderDetails(workOrder.id);
      if (workOrder.workOrderFlaggedAs3strike) {
        getWorkOrdersWithStatusCompleted();
        closeModal();
      }
      getWorkOrdersWithStatusEscalated();
    } else if (qualityAssuranceType === TAB_TYPE.COMPLETED) {
      await workOrderService.rtuWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType });
      await getWorkOrderDetails(workOrder.id);
      getWorkOrdersWithStatusCompleted();
      if (selectedWorkOrder.workOrderFlaggedAsEscalated) {
        getWorkOrdersWithStatusEscalated();
      }
      closeModal();
    } else {
      await rest.updateWorkOrder(selectedWorkOrder.id, () => workOrderService.rtuWorkOrder(workOrder));
    }
  }

  const updateWorkOrder = async (workOrder) => {
    setModalDetailsLoading(true);
    if (isQualityAssurance) {
      await workOrderService.updateWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType });
      getWorkOrderDetails(workOrder.id);
      if (qualityAssuranceType === TAB_TYPE.IN_REVIEW) {
        getWorkOrdersWithStatusInReview();
      } else if (qualityAssuranceType === TAB_TYPE.ESCALATED) {
        getWorkOrdersWithStatusEscalated();
      }
    } else {
      rest.updateWorkOrder(selectedWorkOrder.id, () => workOrderService.updateWorkOrder({ ...workOrder, workOrderType: selectedWorkOrder.workOrderType }));
    }
  }

  const updateWorkOrderNewMeterNumber = async (id, newMeterNumber) => {
    if (isQualityAssurance) {
      await workOrderService.updateWorkOrderNewMeterNumber({
        projectId: selectedWorkOrder.project.id,
        workOrderType: selectedWorkOrder.workOrderType,
        workOrderId: id,
        newMeterNumber,
      }).catch(e => {
        throw e;
      });
      getWorkOrderDetails(selectedWorkOrder.id);
      if (qualityAssuranceType === TAB_TYPE.IN_REVIEW) {
        getWorkOrdersWithStatusInReview();
      } else if (qualityAssuranceType === TAB_TYPE.ESCALATED) {
        getWorkOrdersWithStatusEscalated();
      } else if (qualityAssuranceType === TAB_TYPE.COMPLETED) {
        getWorkOrdersWithStatusCompleted();
      }
    }
  }

  return (
    <>
      {showAppointmentModal && <DetailsAppointmentModal selectedWorkOrder={selectedWorkOrder} updateWorkOrder={rest.updateWorkOrder} onClose={() => setShowAppointmentModal(false)} showModal={showAppointmentModal} />}
      <Modal dimmer={{ style: { zIndex: 1001 } }} size='fullscreen' closeIcon open={showModal} onClose={closeModal}>
        <Loader loading={modalDetailsLoading} />
        <Modal.Header>
          {isQualityAssurance ?
            <QualityAssuranceHeader
              type={qualityAssuranceType}
              workOrders={workOrders}
              labelColor={qualityAssuranceHeaderLabelColor}
              currentProject={currentProject}
              selectedWorkOrder={selectedWorkOrder} /> :
            <Header currentProject={currentProject} selectedWorkOrder={selectedWorkOrder} />}
        </Modal.Header>
        <Modal.Content image scrolling className='modal-size'>
          <Splitter>
            <Modal.Description className='details-description'>
              <div>
                {selectedWorkOrder && <div>
                  <StaticMap workOrder={selectedWorkOrder} updateWorkOrder={updateWorkOrder} />
                </div>}
                <Menu
                  info={(props) => (
                    <InfoTabContent
                      currentProject={currentProject}
                      photoIndex={photoIndex}
                      handleEscalate={handleEscalate}
                      qaDoubleEntryVerification={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification ? currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification : false}
                      appointmentManagement={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.appointmentManagement ? currentProject.projectConfiguration.additionalFeatures.appointmentManagement : false}
                      setShowAppointmentModal={setShowAppointmentModal}
                      setDetailsOpenCount={setDetailsOpenCount}
                      detailsOpenCount={detailsOpenCount}
                      allowOldMeterNumberEditing={currentProject && currentProject.projectConfiguration ? currentProject.projectConfiguration.allowOldMeterNumberEditing : false}
                      qualityAssuranceType={qualityAssuranceType}
                      oldMeterReadingHighThreshold={currentProject && currentProject.projectConfiguration ? currentProject.projectConfiguration.oldMeterReadingHighThreshold : undefined}
                      getWorkOrderDetails={getWorkOrderDetails}
                      selectedWorkOrder={selectedWorkOrder}
                      user={user}
                      projectHasBlackOuts={currentProject && currentProject.projectHasBlackOuts ? currentProject.projectHasBlackOuts : false}
                      updateWorkOrder={updateWorkOrder}
                      updateWorkOrderNewMeterNumber={updateWorkOrderNewMeterNumber}
                      rtuWorkOrder={rtuWorkOrder}
                      workOrders={workOrders}
                      isQualityAssurance={isQualityAssurance}
                      workOrdersVerified={workOrdersVerified}
                      verifyWorkOrder={verifyWorkOrder}
                      {...props}
                    />
                  )}
                  additional={(props) => (
                    <CustomDataTab 
                      verifyWorkOrder={verifyWorkOrder}
                      qaDoubleEntryVerification={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification ? currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification : false}
                      isQualityAssurance={isQualityAssurance}
                      workOrdersVerified={workOrdersVerified}
                      getWorkOrderDetails={getWorkOrderDetails}
                      updateWorkOrder={updateWorkOrder}
                      selectedWorkOrder={selectedWorkOrder}
                      currentProject={currentProject}
                      data={selectedWorkOrder.customDataFields}
                      user={user} {...props}
                    />
                  )}
                  audit={(props) => <AuditDataTab data={selectedWorkOrder.auditDataFields} workOrderAuditDate={selectedWorkOrder.workOrderAuditDate} {...props} />}
                  comments={(props) => (
                    <CommentsTabContent
                      workOrder={selectedWorkOrder}
                      user={user}
                      updateWorkOrder={updateWorkOrder}
                      getWorkOrderDetails={getWorkOrderDetails}
                      {...props}
                    />
                  )}
                  history={(props) => <HistoryTabContent workOrder={selectedWorkOrder} {...props} />}
                />
              </div>
            </Modal.Description>
            <Gallery onSlide={onGallerySlide} photoIndex={photoIndex} workOrder={selectedWorkOrder} />
          </Splitter>
        </Modal.Content>
        <Modal.Actions>
          {isQualityAssurance ? <QualityAssuranceDetailsActions
            closeWorkOrderUponApproval={closeWorkOrderUponApproval}
            onCloseWorkOrderUponApprovalChange={onCloseWorkOrderUponApprovalChange}
            onReplacePhotoClick={onReplacePhotoClick}
            type={qualityAssuranceType}
            user={user}
            fileInputRef={fileInputRef}
            replacePhoto={replacePhoto}
            showAddPhotoModal={showAddPhotoModal}
            selectedWorkOrder={selectedWorkOrder}
            handleEscalate={handleEscalate}
            handleReject={handleReject}
            disableApprove={currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.qaDoubleEntryVerification && workOrdersVerified && !workOrdersVerified.includes(selectedWorkOrder.id) && selectedWorkOrder && !selectedWorkOrder.workOrderFlaggedAsDamaged && !selectedWorkOrder.workOrderFlaggedAs3strike }
            handleApprove={handleApprove}
            modalDetailsLoading={modalDetailsLoading}
            workOrders={workOrders}
            handleApproveAndNext={handleApproveAndNext}
            onNext={onNext}
          /> : <DetailsActions onNext={onNext} hideNext={hideNext} closeModal={closeModal} />}
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default MeterDeployDetailsModal;

