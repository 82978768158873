import gql from 'graphql-tag';

export const GET_ACTIVE_PLAN_QUERY = gql`
  query GetActivePlan($project: ID!) {
    getActivePlan(project: $project) {
      id
			startDate
      endDate
      weeklyCapacity
      isActive
      workingDays
      planWeeks {
        id
        planId
        planRate
        week
        weekStartDate
        workingDays
        actualRate
        targetRate
      }
    }
  }
`;