import React from 'react';
import { Button, Icon, Modal, Dropdown, Input, Checkbox, Message, Image } from 'semantic-ui-react';
import CustomErrorMessage from '../../../../../shared/components/messages/error';
import './styles.css';
import BADGE_PLACEHOLDER from '../../../../../assets/PWBadgePlaceholder.png';
import ROLES from '../../../../../constants/roles';

const CustomMessage = ({ message, style }) => (
  <Message positive style={style}>
    <Message.Header>{message}</Message.Header>
  </Message>
)

const UserDetailsModal = ({
  selectedUser,
  showUserDetailsModal,
  closeModal,
  roles,
  projects,
  onFullNameChange,
  onModalConfirm,
  selectedRoles,
  selectedProjects,
  onRoleChange,
  onProjectChange,
  isActive,
  onIsActiveChange,
  error,
  loading,
  fullName,
  onEmailChange,
  onPhoneChange,
  email,
  phone,
  sendInvitation,
  sendingInvitation,
  invitationSentMessage,
  userDetailsModalRef,
  fileInputRef,
  onAvatarChange,
  avatar,
  removeAvatar,
  avatarUrl,
  qualifications,
  onQualificationChange,
  selectedQualifications,
}) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showUserDetailsModal) {
      event.preventDefault();
      onModalConfirm();
    }
  }

  return (
    <div tabIndex={0} onKeyPress={handleKeyPress} ref={userDetailsModalRef}>
      <Modal size='large' onClose={closeModal} open={showUserDetailsModal}>
        <Modal.Header><Icon name='user' /> Edit User</Modal.Header>
        <Modal.Content>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingRight: '15px' }}>
              <div style={{ paddingBottom: '10px' }}>
                {(selectedUser && selectedUser.tenant && selectedUser.tenant.tenantName) || 'System'}
              </div>
              <div style={{ paddingBottom: '10px' }}>
                Username: {selectedUser && selectedUser.userName}
              </div>
              <div style={{ paddingBottom: '10px' }}>
                Full Name: <Input type='text' style={{ marginLeft: '10px', marginTop: '10px' }} defaultValue={fullName} onChange={onFullNameChange} />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                Email: <Input type='email' style={{ marginLeft: '10px', marginTop: '10px' }} defaultValue={email} onChange={onEmailChange} />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                Phone: <Input type='text' style={{ marginLeft: '10px', marginTop: '10px' }} defaultValue={phone} onChange={onPhoneChange} />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                Roles: <Dropdown
                  defaultValue={selectedRoles}
                  multiple
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  placeholder='Roles'
                  selection
                  options={
                    roles.map((role, index) => {
                      return { key: index, value: JSON.stringify({ id: role.id, name: role.role }), text: role.role };
                    })}
                  onChange={onRoleChange} />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                Projects: <Dropdown
                  defaultValue={selectedProjects}
                  multiple
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  placeholder='Projects'
                  selection
                  options={
                    projects.map((project, index) => {
                      return { key: index, value: project.id, text: project.projectName };
                    })}
                  onChange={onProjectChange} />
              </div>
              {selectedRoles.findIndex(i => i.includes(ROLES.Technician)) > -1 && <div style={{ paddingBottom: '10px' }}>
                Qualifications: <Dropdown
                  value={selectedQualifications}
                  multiple
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  placeholder='Qualifications'
                  selection
                  options={
                    qualifications.map((qualification, index) => {
                      return { key: index, value: qualification.id, text: qualification.qualificationName };
                    })}
                  onChange={onQualificationChange} />
              </div>}
              <div style={{ paddingBottom: '10px' }}>
                <Checkbox
                  checked={isActive}
                  label='Active'
                  onChange={onIsActiveChange}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: '15px' }}>
                <Button loading={sendingInvitation} onClick={sendInvitation}>Re-send Invitation</Button>
              </div>
              {invitationSentMessage && <CustomMessage style={{ margin: 0 }} message='Invitation sent successfully' />}
              {error && <CustomErrorMessage style={{ margin: 0 }} message={error.message} />}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'right' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Image size='large' floated='right' src={avatarUrl ? avatarUrl : BADGE_PLACEHOLDER} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
                <label className='badge-label'>For Technicians (PNG 432x273 @ 72ppi)</label>
                <Button onClick={() => fileInputRef.current.click()} type='file'>
                  Upload Badge
              </Button>
              </div>
              <input accept='image/png' ref={fileInputRef} type='file' hidden onChange={(event) => onAvatarChange(event)} />
              {avatar ? (<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <label>{avatar.name}</label>
                <Icon name='delete' onClick={removeAvatar} className='remove-avatar' />
              </div>
              ) : null}
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          {!loading && <Button onClick={closeModal} negative>Cancel</Button>}
          <Button loading={loading} onClick={onModalConfirm} positive icon='checkmark' labelPosition='right' content='Update' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default UserDetailsModal;