import * as React from 'react';
import { SchedulerForm } from '@progress/kendo-react-scheduler';
import { CustomDialog } from './custom-dialog';
import CustomFormEditor from './custom-form-editor';
import { connect } from 'react-redux';
import * as schedulerActions from '../../../core/redux/actions/scheduler.actions';
import { getWorkOrderByOldMeterNumber } from '../../../core/services/workOrder.service';
import AppContext from '../../../core/context/app.context';
import { getAppointmentByWorkOrder } from '../../../core/services/appointment.service';

const FormWithCustomEditor = (props) => {
  const context = React.useContext(AppContext);

  React.useEffect(() => {
    return () => {
      props.setWorkOrderNotFoundError(false);
    }
  });

  const requiredValidator = React.useCallback(value => value === undefined || value === null || value === '' || value.length === 0 ? 'Field is required.' : undefined, []);

  const formValidator = (_dataItem, formValueGetter) => {
    let result = {};
    if (!props.selectedWorkOrder) {
      result.workOrderNumber = [requiredValidator(formValueGetter('workOrderNumber'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    }
    //result.userIds = [requiredValidator(formValueGetter('userIds'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    result.appointmentNotes = [requiredValidator(formValueGetter('appointmentNotes'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    return result;
  };

  const handleSubmit = async (event) => {
    const { setWorkOrderNotFoundError, setValidating, setValidationMessage } = props;
    setValidating(true);
    setValidationMessage(null);
    setWorkOrderNotFoundError(false);

    const appointmentId = props.dataItem.id;

    const { currentProject, user } = context;
    let workOrder;
    let oldAppointment;
    if (!appointmentId) {
      // Check if workorder exist when creating a new appointment
      if (!props.selectedWorkOrder) {
        workOrder = await getWorkOrderByOldMeterNumber(currentProject.id, currentProject.projectWorkOrderType, event.value.workOrderNumber);
        if (!workOrder) {
          setWorkOrderNotFoundError(true);
          return;
        }
      } else {
        workOrder = props.selectedWorkOrder;
      }
      oldAppointment = await getAppointmentByWorkOrder(currentProject.id, workOrder.id);
    }

    const { value: formData } = event;
    const newAppointment = {
      ...formData,
      schedulerUserId: user.id,
      userIds: formData.userIds ? formData.userIds.map(a => a.id) : '',
      projectId: currentProject.id,
      timeZone: currentProject.projectTimeZone
    }
    delete newAppointment['isAllDay'];
    delete newAppointment['workOrderNumber'];

    /*const validAppointment = await validateAppointment({
      id: oldAppointment ? oldAppointment.id : null,
      ...newAppointment,
      workOrderId: workOrder ? workOrder.id : props.dataItem.workOrderId
    })
    if (!validAppointment.ok) {
      setValidationMessage(validAppointment.message);
      setValidating(false);
      return;
    }*/
    setValidating(false);

    if (oldAppointment) {
      props.setShowAppointmentAlreadyExistModal(true);
      newAppointment.workOrderId = workOrder ? workOrder.id : props.dataItem.workOrderId;
      event.value.override = true;
    } else if (workOrder) {
      newAppointment.workOrderId = workOrder.id;
    }
    props.setNewAppointment(newAppointment);
    props.onSubmit(event);
  }

  const handleCancel = (event) => {
    const { setValidating, setValidationMessage } = props;
    setValidationMessage(null);
    setValidating(false);
    props.onCancel(event);
  }

  return <SchedulerForm {...props} onCancel={handleCancel} validator={formValidator} onSubmit={handleSubmit} editor={(rest) => <CustomFormEditor {...rest} selectedWorkOrder={props.selectedWorkOrder} appointmentId={props.dataItem.id} />} dialog={CustomDialog} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWorkOrderNotFoundError: (workOrderNotFoundError) => dispatch(schedulerActions.setWorkOrderNotFoundError(workOrderNotFoundError)),
    setShowAppointmentAlreadyExistModal: (showModal) => dispatch(schedulerActions.setShowAppointmentAlreadyExistModal(showModal)),
    setNewAppointment: (newAppointment) => dispatch(schedulerActions.setNewAppointment(newAppointment)),
    setValidating: (validating) => dispatch(schedulerActions.setValidating(validating)),
    setValidationMessage: (validationMessage) => dispatch(schedulerActions.setValidationMessage(validationMessage)),
  }
}

export default connect(null, mapDispatchToProps)(FormWithCustomEditor);