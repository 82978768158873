import React, { Component } from 'react';
import { Button, Icon, Image, Dropdown, Message, Input } from 'semantic-ui-react';
import AppContext from '../../core/context/app.context';
import TopMenu from '../../shared/components/top-menu';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import BACKGROUND_IMAGE from '../../assets/ReportBackground.png';
import ErrorMessage from '../../shared/components/messages/error';
import CustomMessage from '../../shared/components/messages/message';
import { getToken } from '../../core/services/auth.service';
import RouteWrapper from '../../hoc/routeWrapper';
import routeMaps from '../../core/route-maps';
import config from '../../config';
import { validateEmail } from '../../helpers/validators';

const REPORT_TYPES = {
  Summary: 'Summary',
  Detail: 'Detail',
  FirstLastDurationView: 'FirstLastDurationView'
}

class TechnicianReportRoute extends Component {

  static contextType = AppContext;

  state = {
    fromDate: '',
    toDate: '',
    error: '',
    exporting: false,
    reportType: '',
    projectId: undefined,
    email: '',
    showEmail: false,
    showEmailSuccessMessage: false
  }

  componentDidMount = () => {
    const { history } = this.props;
    const { currentTenant, currentProject } = this.context;
    if (currentProject) {
      this.setState({ projectId: currentProject.id });
    }
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Technician Report` : 'Technician Report'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  onDateChange = (event) => {
    const { end, start } = event.target.value;
    this.setState({ fromDate: start, toDate: end });
  }

  // current format is yyyy-MM-dd
  formatDate(date) {
    if (date) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  downloadReport = async (tenantId, fromDate, toDate, reportType, projectId, email) => {
    try {
      const token = await getToken();
      let url;
      switch(reportType) {
        case REPORT_TYPES.Summary: {
          url = `${config.invokeExportTechSummaryReport}/${tenantId}/${fromDate}/${toDate}/${email}${projectId ? `/${projectId}` : ''}`;
          break;
        }
        case REPORT_TYPES.FirstLastDurationView: {
          url = `${config.invokeAdminExportDurationViewReportApi}/${tenantId}/${fromDate}/${toDate}/${email}${projectId ? `/${projectId}` : ''}`;
          break;
        }
        // Detail
        default: {
          url = `${config.invokeAdminExportDetailViewReportApi}/${tenantId}/${fromDate}/${toDate}/${email}${projectId ? `/${projectId}` : ''}`;
          break;
        }
      }
      const response = await fetch(url, {
        method: 'get',
        headers: new Headers({
          'Authorization': token
        })
      });
      if (!response.ok) {
        throw new Error('No data found');
      }
      const fileUrl = await response.json();
      if (fileUrl) {
        window.open(fileUrl);
      }
    } catch (error) {
      throw error;
    }
  }

  export = async () => {
    this.setState({ error: '', showEmailSuccessMessage: false });
    try {
      let { fromDate, toDate, reportType, projectId, email } = this.state;
      const { currentTenant } = this.context;
      if (reportType) {
        if (fromDate && toDate) {
          //if (reportType === REPORT_TYPES.FirstLastDurationView || reportType === REPORT_TYPES.Detail) {
            if (!email) {
              this.setState({ error: 'Email is required' });
              return;
            } else if (!validateEmail(email)) {
              this.setState({ error: 'Email is not valid' });
              return;
            }
          //}
          this.setState({ exporting: true });
          fromDate = this.formatDate(fromDate);
          toDate = this.formatDate(toDate);
          await this.downloadReport(currentTenant.id, fromDate, toDate, reportType, projectId, email);
          this.setState({ exporting: false, showEmailSuccessMessage: true });
        } else {
          this.setState({ error: 'From Date/To Date are required fields' });
        }
      } else {
        this.setState({ error: 'Report type is required' });
      }
    } catch (error) {
      this.setState({ error: error.message, exporting: false });
    }
  }

  onReportTypeChange = (event, { value }) => {
    const { user } = this.context;
    const reportType = value;
    //if (reportType === REPORT_TYPES.FirstLastDurationView || reportType === REPORT_TYPES.Detail) {
      this.setState({ reportType: reportType, showEmail: true, email: user.email ? user.email : '' });
    // } else {
    //   this.setState({ reportType: value, showEmail: false, email: '' });
    // }
  }

  onEmailChange = (event, { value }) => {
    this.setState({ email: value });
  }

  onProjectChange = (event, { value }) => {
    if (value === 'all') {
      this.setState({ projectId: null });
    } else {
      this.setState({ projectId: value });
    }
  }

  renderTechnicianReportRouteContent = () => {
    const { error, exporting, showEmail, email, showEmailSuccessMessage } = this.state;
    const { currentProject } = this.context;
    return (
      <React.Fragment>
      <Message color='grey' content='This report exports a Summary or Detail view of work orders across the project and technicians. What did they complete, how many RTC/CNC and RTU/3-strike over a given time period.' />
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <DateRangePicker format='yyyy-MM-dd' onChange={this.onDateChange} />
        <Dropdown
        clearable
        placeholder='Report Type ...'
        style={{ marginLeft: '10px' }}
        selection
        options={[
          { key: 0, value: REPORT_TYPES.Summary, text: 'General Summary' },
          { key: 1, value: REPORT_TYPES.Detail, text: 'Detail View' },
          { key: 2, value: REPORT_TYPES.FirstLastDurationView, text: 'First/Last Duration View' }]}
        onChange={this.onReportTypeChange} />
        {currentProject && <Dropdown
        clearable
        placeholder='Project'
        style={{ marginLeft: '10px' }}
        selection
        options={[{ key: currentProject.id, value: currentProject.id, text: currentProject.projectName }]}
        onChange={this.onProjectChange}
        defaultValue={currentProject.id} />}
        {showEmail && <Input value={email} onChange={this.onEmailChange} style={{ marginLeft: '10px' }} placeholder='Email' type='email' required />}
        <Button onClick={this.export} loading={exporting} style={{ marginLeft: '10px' }}><Icon name='download' />Export</Button>
      </div>
      {showEmailSuccessMessage && <div style={{ marginTop: '10px' }}>
        <CustomMessage message='You have requested a longer running report. When the information is ready you will recieve an email with a link to download the report.' />
      </div>}
      {error && <div style={{ marginTop: '10px' }}>
        <ErrorMessage message={error} />
      </div>}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
        <Image src={BACKGROUND_IMAGE} />
      </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <RouteWrapper>
        {this.renderTopMenu()}
        {this.renderTechnicianReportRouteContent()}
      </RouteWrapper>
    );
  }
}

export default TechnicianReportRoute;