import React, { Component } from 'react';
import AppContext from '../../../../core/context/app.context';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import moment from 'moment';
import Chart from 'react-google-charts';
import { updateProjectPlanningRouteCodeOrder } from '../../../../core/services/project.service';
import { updatePlanWeek } from '../../../../core/services/plan.service';
import { Statistic, Header, Button } from 'semantic-ui-react';
import RouteDetailsModal from '../modal/routeDetailsModal';
import ROLES from '../../../../constants/roles';
import { isEnabled } from '../../../../core/services/auth.service';

const loadCharts = (callback) => {
  const existingScript = document.getElementById('googleMaps');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://www.gstatic.com/charts/loader.js';
    script.id = 'googleCharts';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export class Renderers {
  constructor(enterEdit, exitEdit, editFieldName) {
    this.enterEdit = enterEdit;
    this.exitEdit = exitEdit;
    this.editFieldName = editFieldName;
  }

  cellRender = (tdElement, cellProps) => {
    const dataItem = cellProps.dataItem;
    const dataIndex = cellProps.dataIndex;
    const columnIndex = cellProps.columnIndex;
    const cellField = cellProps.field;
    const editor = cellProps.editor;
    const inEditField = dataItem[this.editFieldName];
    const additionalProps = cellField && cellField === inEditField ?
      {
        ref: (td) => {
          const input = td && td.querySelector('input');
          const activeElement = document.activeElement;

          if (!input ||
            !activeElement ||
            input === activeElement ||
            !activeElement.contains(input)) {
            return;
          }

          if (input.type === 'checkbox') {
            input.focus();
          } else {
            input.select();
          }
        }
      } : {
        style: {
          ...((columnIndex > 0 && editor && cellField !== 'adjustedDays' && dataIndex !== 0) || cellField === 'adjustedDays') && {
            outlineOffset: '-10px',
            outline: 'solid',
            outlineWidth: '1px',
            outlineColor: '#CCC',
            textAlign: 'center'
          },
        },
        onClick: () => { this.enterEdit(dataItem, cellField); }
      };
    return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
  }

  rowRender = (trElement) => {
    const trProps = {
      ...trElement.props,
      onBlur: () => {
        this.exitEdit();
      },
    };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  }
}

class DragCell extends React.Component {
  render() {
    return (
      <td onDragOver={(e) => {
        DragCell.reorder(this.props.dataItem);
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      }}>
        <span
          className='k-icon k-i-reorder'
          draggable='true'
          style={{ cursor: 'move' }}
          onDragEnd={() => DragCell.dragEnd()}
          onDragStart={(e) => {
            DragCell.dragStart(this.props.dataItem);
            e.dataTransfer.setData('dragging', '');
          }}
        />
      </td>
    );
  }
}

const WeeksTableHeader = ({ title, weekStartDate }) => (
  <React.Fragment>
    <p style={{ textAlign: 'center' }}>{title}</p>
    <p>{weekStartDate}</p>
  </React.Fragment>
)

const CustomCell = ({ dataItem, field }) => (
  <td style={{ fontSize: '20px' }}>
    {dataItem[field]}
  </td>
)

class PlanTabView extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    DragCell.reorder = this.reorder.bind(this);
    DragCell.dragStart = this.dragStart.bind(this);
    DragCell.dragEnd = this.dragEnd.bind(this);
    this.weekTableRenderers = new Renderers(this.weekTableEnterEdit, this.weekTableExitEdit, 'inEdit');
    this.routeTableRenderers = new Renderers(this.routeTableEnterEdit, this.routeTableExitEdit, 'inEdit');
  }

  state = {
    routeTableEditField: undefined,
    weekTableEditField: undefined,
    weeksTableData: [{ week: 'Actual Rate' }, { week: 'Plan Rate' }, { week: 'Working Days' }, { week: 'Target Rate' }],
    originalWeeksTableData: [],
    routeTableChanges: false,
    weekTableChanges: false,
    weekTableUpdateData: [],
    updatingWeekTable: false,
    googleChartsLoaded: false,
    updatingRouteTable: false,
    showRouteDetailsModal: false,
    loading: false,
  }

  setState = (state) => {
    if (this._isMounted) {
      super.setState(state);
    }
  }

  componentWillReceiveProps(newPorps) {
    this.updateWeeksTableData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (window.google) {
      delete window.google;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.targetRate !== prevProps.targetRate) {
      console.log(`targetRate changed from ${prevProps.targetRate} to ${this.props.targetRate}`);
      this.setState({ routeTableChanges: true });
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (window.google && window.google.charts) {
      this.setState({ googleChartsLoaded: true });
    } else {
      loadCharts(() => {
        window.google.charts.load('current', { 'packages': ['corechart'] });
        this.setState({ googleChartsLoaded: true })
      });
    }
    this.updateWeeksTableData();
  }

  setShowRouteModalDetails = (showRouteDetailsModal) => {
    this.setState({ showRouteDetailsModal });
  }

  updateWeeksTableData = () => {
    const { plan } = this.context;
    const { weeksTableData } = this.state;
    if (plan) {
      plan.planWeeks.forEach(({ week, planRate, workingDays, actualRate, targetRate }) => {
        console.log("week:",targetRate);
        weeksTableData[0][week] = actualRate;
        weeksTableData[1][week] = planRate;
        weeksTableData[2][week] = workingDays;
        //show targetRate if it exists
        // passing '' will cause gql rejections
        weeksTableData[3][week] = targetRate ? targetRate : null;
      });
    }
    this.setState({ weeksTableData, originalWeeksTableData: [...weeksTableData] });
  }

  weekTableEnterEdit = (dataItem, field) => {
    const weeksTableData = this.state.weeksTableData.map(item => ({
      ...item,
      inEdit: item.week === dataItem.week && dataItem.week !== 'Actual Rate' ? field : undefined
    }));

    this.setState({
      weeksTableData,
      weekTableEditField: field
    });
  }

  routeTableEnterEdit = (dataItem, field) => {
    const routeCodes = this.props.routeCodes.map(item => ({
      ...item,
      inEdit: item.route === dataItem.route ? field : undefined
    }));

    this.props.setRouteCodes(routeCodes);

    this.setState({
      routeTableEditField: field
    });
  }

  weekTableExitEdit = async () => {
    const { plan } = this.context;
    const { weekTableUpdateData } = this.state;
    const weeksTableData = this.state.weeksTableData.map(item => (
      { ...item, inEdit: undefined }
    ));

    const week = +this.state.weekTableEditField;
    const planRate = this.state.weeksTableData[1][this.state.weekTableEditField];
    const planId = this.context.plan.id;
    const workingDays = this.state.weeksTableData[2][this.state.weekTableEditField];
    const targetRate = this.state.weeksTableData[3][this.state.weekTableEditField];

    if (planRate >= 0 && workingDays >= 1 && workingDays <= 7 && (planRate !== plan.planWeeks[week - 1].planRate || workingDays !== plan.planWeeks[week - 1].workingDays || targetRate !== plan.planWeeks[week - 1].targetRate)) {
      const updateWeekIndex = weekTableUpdateData.findIndex(x => x.week === week);
      if(updateWeekIndex >= 0){
        weekTableUpdateData[updateWeekIndex] = { week, planRate, planId, workingDays, targetRate }
      } else {
        weekTableUpdateData.push({ week, planRate, planId, workingDays, targetRate });
      }
    }

    this.setState({
      weeksTableData,
      weekTableEditField: undefined,
      weekTableUpdateData
    });
  }

  routeTableExitEdit = async () => {
    const routeCodes = this.props.routeCodes.map(item => (
      { ...item, inEdit: undefined }
    ));

    this.props.setRouteCodes(routeCodes);

    this.setState({
      routeTableEditField: undefined,
    });
  }

  weekTableItemChange = (event) => {
    if (event.dataItem.week === 'Working Days' && (event.value < 1 || event.value > 7)) {
      return;
    }
    event.dataItem[event.field] = event.value;
    this.setState({
      weekTableChanges: true
    });
  }

  routeTableItemChange = (event) => {
    if (event.value <= 0) {
      return;
    }
    if (event.dataItem[event.field] !== event.value) {
      this.setState({
        routeTableChanges: true
      });
    }
    event.dataItem[event.field] = event.value;
  }

  dragEnd = async () => {
    console.log('planTabView dragEnd loading:'+this.state.loading);
    if (this.state.loading !== true){
      this.setState.loading = true;
      this.setState({
        routeTableChanges: true //set routeTableChanges to true to show the save button
      });
    }
  }

  updateCurrentProjectSequence = async (routeCodes) => {
    const { currentProject, setCurrentProjectSequence } = this.context;
    const projectSequence = await updateProjectPlanningRouteCodeOrder(currentProject.id, routeCodes);
    setCurrentProjectSequence(projectSequence);
  }

  reorder = (dataItem) => {
    const { activeItem } = this.state;
    const { routeCodes } = this.props;
    if (activeItem === dataItem) {
      return;
    }

    let reorderedData = routeCodes.slice();
    let prevIndex = reorderedData.findIndex(p => (p === activeItem));
    let nextIndex = reorderedData.findIndex(p => (p === dataItem));

    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItem);

    this.props.setRouteCodes(reorderedData);

    this.setState({
      active: activeItem
    });
  }

  dragStart = (dataItem) => {
    this.props.setRouteCodes(this.props.routeCodes);
    this.setState({
      activeItem: dataItem
    });
  }

  daysToMilliseconds = (days) => {
    return days * 24 * 60 * 60 * 1000;
  }

  convertDaysToTime = (value) => {
    let m1 = parseFloat(value);
    if (isNaN(m1)) m1 = 0;
    let t2 = m1 * 24;
    let days = Math.floor(t2 / 24);
    let st3 = t2 - (days * 24);
    let hours = Math.floor(st3);
    let t5 = (st3 - hours) * 60;
    let minutes = Math.floor(t5);
    let seconds = (t5 - minutes) * 60;
    seconds = Math.floor(seconds);
    return [days, hours, minutes, seconds];
  }

  renderGanttChart = (plan, routeCodes) => {
    const adjustAddDays = (route, remainingWeekDays, currentWeek, plan, currentStartDate) => {
      const diff = route.adjustedDays - route.workingDays;
      if (diff > remainingWeekDays) {
        let currentWeekIndex = currentWeek;
        let tempCurrentWeek = plan.planWeeks[currentWeek];
        let tempWorkingDays = tempCurrentWeek ? tempCurrentWeek.workingDays : plan.workingDays;
        let noneWorkingDays = 7 - tempWorkingDays;
        let daysCounter = noneWorkingDays;
        let adjustedDays = route.adjustedDays - route.workingDays - remainingWeekDays;
        currentWeekIndex += 1;
        tempCurrentWeek = plan.planWeeks[currentWeekIndex];
        tempWorkingDays = tempCurrentWeek ? tempCurrentWeek.workingDays : plan.workingDays;
        while (adjustedDays > tempWorkingDays) {
          daysCounter += 7 - tempWorkingDays;
          adjustedDays -= tempWorkingDays;
          currentWeekIndex += 1;
          tempCurrentWeek = planWeeks[currentWeekIndex];
          tempWorkingDays = tempCurrentWeek ? tempCurrentWeek.workingDays : plan.workingDays;
        }
        if (adjustedDays > 0) {
          daysCounter += route.adjustedDays - route.workingDays;
        }
        currentStartDate.add(daysCounter, 'days');
      } else {
        currentStartDate.add(diff, 'days');
      }
    }

    const adjustSubtractDays = (route, remainingWeekDays, currentWeek, plan, currentStartDate) => {
      const diff = route.workingDays - route.adjustedDays;
      if (diff <= remainingWeekDays && remainingWeekDays !== 0) return currentStartDate.subtract(diff, 'days');
      let currentWeekIndex = currentWeek - 1;
      let tempCurrentWeek = plan.planWeeks[currentWeekIndex];
      let tempWorkingDays = tempCurrentWeek ? tempCurrentWeek.workingDays : plan.workingDays;
      // begin with what was left in the week
      let daysToSubtract = remainingWeekDays;
      let workingDaysCounter = remainingWeekDays;
      const workingDaysDifference = route.workingDays - route.adjustedDays;
      // go through and add all 7 days per week until we've counted back all working days. ( difference to be removed later )
      while (workingDaysDifference > workingDaysCounter) {
        workingDaysCounter += tempWorkingDays;
        daysToSubtract += 7;
        currentWeekIndex -= 1;
        tempCurrentWeek = planWeeks[currentWeekIndex];
        tempWorkingDays = tempCurrentWeek ? tempCurrentWeek.workingDays : plan.workingDays;
      }
      // once workingDaysCounter is greater than workingDaysDifference, we will need to remove the difference ( add the negative ) from the total since both of these should equal zero to be accurate.
      const countedDaysDifference = workingDaysDifference - workingDaysCounter;
      currentStartDate.subtract(daysToSubtract + countedDaysDifference, 'days');
    }

    const startDate = moment.utc(plan.startDate).startOf('day');
    const endDate = moment.utc(plan.endDate).startOf('day');

    let currentStartDate = moment(startDate);
    let currentWeek = 0;
    let remainingWeekDays = plan.planWeeks[0].workingDays;
    const { planWeeks } = plan;
    routeCodes = routeCodes.map((route, index) => {

      route.workingDays = 0;

      let result = [
        index,
        route.route,
        route.route,
        moment(currentStartDate).toDate()
      ];
      let routeTotalCount = route.totalCount;
      route.startDate = this.formatDate(currentStartDate.toDate());
      do {
        const { planRate, workingDays } = planWeeks[currentWeek] || { planRate: plan.weeklyCapacity, workingDays: plan.workingDays };
        const dailyRate = planRate / workingDays;
        const days = routeTotalCount / dailyRate;

        if (remainingWeekDays === 0) {
          remainingWeekDays = workingDays;
        }

        if (days < remainingWeekDays) {
          route.workingDays += Math.ceil(days);
          routeTotalCount -= (dailyRate * days).toFixed(3);
          remainingWeekDays -= days;
          const [d, h, m, s] = this.convertDaysToTime(days);
          currentStartDate.add(d, 'days').add(h, 'hours').add(m, 'minutes').add(s, 'seconds');
        } else if (days >= remainingWeekDays) {
          route.workingDays += Math.ceil(remainingWeekDays);
          routeTotalCount -= (dailyRate * remainingWeekDays).toFixed(3);
          currentWeek += 1;
          const [d, h, m, s] = this.convertDaysToTime(remainingWeekDays);
          currentStartDate.add(d, 'days').add(h, 'hours').add(m, 'minutes').add(s, 'seconds');
          currentStartDate.add(7 - workingDays, 'days');
          const date = currentStartDate.toDate();
          if (date.getUTCHours() === 23 && date.getUTCMinutes() === 59) {
            currentStartDate.add(1, 'day').startOf('day');
          }
          remainingWeekDays = 0;
        }

      } while (routeTotalCount > 0);

      const { workingDays } = planWeeks[currentWeek] || { workingDays: plan.workingDays };

      if (remainingWeekDays === 0) {
        remainingWeekDays = workingDays;
      }

      result = [
        ...result,
        moment(currentStartDate).toDate(),
        null,
        route.percentageDone,
        null
      ];

      if (route.adjustedDays > route.workingDays) {
        adjustAddDays(route, remainingWeekDays, currentWeek, plan, currentStartDate);
      } else if (route.adjustedDays < route.workingDays && route.adjustedDays > 0) {
        adjustSubtractDays(route, workingDays - remainingWeekDays, currentWeek, plan, currentStartDate);
      }

      route.endDate = this.formatDate(moment(currentStartDate).toDate());

      result[4] = null;
      result[5] = this.daysToMilliseconds(Math.ceil(moment(currentStartDate).diff(moment(result[3]), 'hours') / 24));

      route.adjustedDays = route.adjustedDays === 0 ? route.workingDays : route.adjustedDays;

      return result;
    });

    const enableMarkers = (routeCodes) => {
      // add plan start date marker
      const planStartDateMarker = [
        0,
        'Start',
        'Start',
        startDate.toDate(),
        moment(startDate).endOf('day').toDate(),
        null,
        100,
        null
      ];

      // add end date marker
      const planEndDateMarker = [
        0,
        'End',
        'End',
        endDate.toDate(),
        moment(endDate).endOf('day').toDate(),
        null,
        100,
        null
      ];

      const planTodaysDateMarker = [
        0,
        'Today',
        'Today',
        moment().startOf('day').toDate(),
        moment().startOf('day').endOf('day').toDate(),
        null,
        100,
        null
      ];

      // add todays date marker
      if (moment() < moment(currentStartDate)) {
        const routeIndex = routeCodes.findIndex(route => {
          const startDate = route[3];
          const endDate = route[4];
          return moment().isBetween(startDate, endDate);
        });
        routeCodes = [
          ...routeCodes.slice(0, routeIndex),
          planTodaysDateMarker,
          ...routeCodes.slice(routeIndex, routeCodes.length),
          planEndDateMarker
        ];
      } else {
        routeCodes.push(planEndDateMarker);
        routeCodes.push(planTodaysDateMarker);
      }

      routeCodes.unshift(planStartDateMarker);

      // fix indexes
      routeCodes.map((routeCode, index) => {
        routeCode[0] = index;
        return routeCode;
      })
      return routeCodes;
    }

    routeCodes = enableMarkers(routeCodes);

    return (
      <div style={{ marginTop: '10px' }}>
        <Header as='h2' textAlign='center'>Route Plan</Header>
        <Chart
          style={{ fontFamily: 'Lato' }}
          height={`${35 * routeCodes.length + 100}px`}
          chartType='Gantt'
          loader={<div>Loading Chart</div>}
          data={[
            [
              { type: 'string', label: 'Task ID' },
              { type: 'string', label: 'Task Name' },
              { type: 'string', label: 'Resource' },
              { type: 'date', label: 'Start Date' },
              { type: 'date', label: 'End Date' },
              { type: 'number', label: 'Duration' },
              { type: 'number', label: 'Percent Complete' },
              { type: 'string', label: 'Dependencies' },
            ],
            ...routeCodes
          ]}
          options={{
            height: '100%',
            gantt: {
              criticalPathEnabled: false,
              trackHeight: 35,
              labelStyle: {
                fontSize: 18,
                fontName: 'Lato',
                color: '#757575'
              },
            },
          }}
        />
      </div>
    )
  }

  formatDate = (date) => {
    if (date) {
      const result = moment.utc(date);
      if (result.hours() === 23 && result.minutes() === 59) {
        result.add(1, 'day').startOf('day');
      }
      return result.format('MM/DD/yyyy');
    }
    return '';
  }

  saveWeekTableChanges = async () => {
    this.setState({ updatingWeekTable: true });
    const { currentProject, getActivePlan } = this.context;
    const { weekTableUpdateData } = this.state;
    await updatePlanWeek(weekTableUpdateData);
    await getActivePlan(currentProject);
    this.updateWeeksTableData();
    let routeCodes = this.props.routeCodes.map(route => {
      if (route !== '' && route !== null && route !== undefined){
        return { ...route, adjustedDays: 0 };
      } else {
        console.log('empty route found');
        return { route: 'None', adjustedDays: 0 };
      }
    });
    routeCodes = routeCodes.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.route === value.route
      ))
    );
    await this.updateCurrentProjectSequence(routeCodes.map(({ route, adjustedDays }) => {
      return { route, adjustedDays };
    }));
    this.props.setRouteCodes(routeCodes);
    this.props.setOriginalRouteCodes([...routeCodes]);
    this.setState({ weekTableUpdateData: [], updatingWeekTable: false });
  }

  cancelWeekTableChanges = () => {
    this.setState({ weekTableUpdateData: [], weeksTableData: [...this.state.originalWeeksTableData] });
  }

  saveRouteTableChanges = async () => {
    this.setState({ updatingRouteTable: true });
    let routeCodes = this.props.routeCodes.map(({ route, adjustedDays }) => {
      if (!('adjustedDays' in { route, adjustedDays }) || adjustedDays === undefined) {
        adjustedDays = 0;
      }
    
      if (route === '' || route === null || route === undefined) {
        console.log('empty route found');
        return { route: 'None', adjustedDays: 0 };
      } else {
        return { route, adjustedDays };
      }
    });
    
    
    routeCodes = routeCodes.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.route === value.route
      ))
    );
    await this.updateCurrentProjectSequence(routeCodes);
    this.props.setOriginalRouteCodes([...routeCodes]);
    this.setState({ updatingRouteTable: false, routeTableChanges: false });
  }

  cancelRouteTableChanges = () => {
    this.props.setRouteCodes([...this.props.originalRouteCodes]);
    this.setState({
      routeTableChanges: false
    });
  }

  renderRouteTable = (routeCodes) => {
    const { updatingRouteTable, routeTableChanges,loading } = this.state;
    const { user } = this.context;
    return (
      <div style={{ paddingBottom: '10px' }}>
        <Grid
          className='plan-tab-table'
          data={routeCodes}
          onItemChange={this.routeTableItemChange}
          {...(isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) ? { cellRender: this.routeTableRenderers.cellRender } : {})}
          rowRender={this.routeTableRenderers.rowRender}
          editField='inEdit'
        >
          <GridNoRecords>
            Loading data...
          </GridNoRecords>
          <GridToolbar>
            {<div style={{ ...routeTableChanges ? { display: 'flex' } : {} }}>
              {routeTableChanges && <div style={{ flex: 1 }}>
                <Button
                  disabled={!isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin])}
                  loading={updatingRouteTable}
                  positive={true}
                  onClick={this.saveRouteTableChanges}
                >
                  Save Changes
                  </Button>
                <Button
                  disabled={updatingRouteTable}
                  onClick={this.cancelRouteTableChanges}
                >
                  Cancel Changes
                  </Button>
              </div>}
              {!routeTableChanges && <div>
                <Button onClick={() => this.setShowRouteModalDetails(true)}>Show Route Plan Detail</Button>
              </div>}
            </div>}
          </GridToolbar>
          {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) && <Column title='' width='80px' cell={DragCell} editable={false} draggable={loading} />}
          <Column field='route' title='Route' width='150px' editable={false}/>
          <Column field='totalCount' title='Total' width='150px' editable={false} />
          <Column field='remainingCount' title='Remaining' width='150px' editable={false} />
          <Column field='startDate' title='Start' width='150px' editable={false} />
          <Column field='endDate' title='End' width='150px' editable={false} />
          <Column field='workingDays' title='Working Days' width='130px' editable={false} />
          <Column title='Adjusted Days' width='150px' editor='numeric' field='adjustedDays' />
        </Grid>
      </div >
    )
  }

  render() {
    const { weeksTableData, weekTableUpdateData, updatingWeekTable, googleChartsLoaded, showRouteDetailsModal } = this.state;
    const { routeCodes } = this.props;
    const { workOrderStatusCounts = {}, plan, user } = this.context;
    const { Total } = workOrderStatusCounts;
    const workOrdersCount = Total;
    const weeks = plan.planWeeks.length;
    const targetWeeklyRate = workOrdersCount / weeks;
    const actualTotal = plan.planWeeks.map(week => week.actualRate).reduce((a, b) => a + b, 0);
    const planRateTotal = plan.planWeeks.map(week => week.planRate).reduce((a, b) => a + b, 0);
    const overUnder = planRateTotal - workOrdersCount;
    return (
      <div className='planning-plan-tab-view'>
        <Grid className='plan-tab-table' data={plan ? [{ workOrdersCount, startDate: this.formatDate(plan.startDate), endDate: this.formatDate(plan.endDate) }] : []}>
          <GridNoRecords>
            Loading data...
          </GridNoRecords>
          <Column cell={(props) => <CustomCell {...props} />} field='workOrdersCount' title='Work Orders' width='180px' />
          <Column cell={(props) => <CustomCell {...props} />} field='startDate' title='Start' width='180px' />
          <Column cell={(props) => <CustomCell {...props} />} field='endDate' title='End' width='180px' />
        </Grid>

        <div style={{ display: 'flex', margin: 0, marginTop: 10, justifyContent: 'space-around' }}>
          {weeks && <Statistic size='tiny' label='# Of Weeks' value={weeks} />}
          {targetWeeklyRate && <Statistic size='tiny' label='Target Weekly Rate' value={targetWeeklyRate.toFixed(3)} />}
          {workOrdersCount && <Statistic size='tiny' label='Actual Total' value={actualTotal} />}
          {planRateTotal && <Statistic size='tiny' label='Plan Total' value={planRateTotal.toFixed(3)} />}
          {overUnder !== null && overUnder !== undefined && <Statistic size='tiny' label='Over/Under' value={overUnder.toFixed(3)} />}
        </div>

        <Grid
          style={{ margin: 0 }}
          onItemChange={this.weekTableItemChange}
          {...(isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) ? { cellRender: this.weekTableRenderers.cellRender } : {})}
          rowRender={this.weekTableRenderers.rowRender}
          editField='inEdit'
          data={weeksTableData}>
          <GridNoRecords>
            Loading data...
          </GridNoRecords>
          {weekTableUpdateData.length > 0 && <GridToolbar>
            <Button
              disabled={!isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin])}
              loading={updatingWeekTable}
              positive={true}
              onClick={this.saveWeekTableChanges}
            >
              Save Changes
            </Button>
            <Button
              disabled={updatingWeekTable}
              onClick={this.cancelWeekTableChanges}
            >
              Cancel Changes
            </Button>
          </GridToolbar>}
          <Column field='week' title='Week' width='190px' editable={false} />
          {plan.planWeeks.map(({ week, weekStartDate }, index) => <Column editor='numeric' key={index} field={week.toString()} title={week.toString()} headerCell={(props) => <WeeksTableHeader {...props} weekStartDate={this.formatDate(weekStartDate)} />} width='100px' />)}
        </Grid>

        {routeCodes.length > 0 && googleChartsLoaded && this.renderGanttChart(plan, routeCodes)}
        {routeCodes.length > 0 && this.renderRouteTable(routeCodes)}

        {showRouteDetailsModal && <RouteDetailsModal
          routeCodes={routeCodes}
          showModal={showRouteDetailsModal}
          closeModal={() => this.setShowRouteModalDetails(false)} />}
      </div>
    )
  }
}

export default PlanTabView;