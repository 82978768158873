import React from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { isEnabled } from '../../../core/services/auth.service';
import ROLES from '../../../constants/roles';
import TAB_TYPE from '../../../constants/qaModalTypes';

const QualityAssuranceDetailsActions = ({
  fileInputRef,
  onReplacePhotoClick,
  replacePhoto,
  showAddPhotoModal,
  selectedWorkOrder,
  handleEscalate,
  handleReject,
  handleApprove,
  disableApprove,
  modalDetailsLoading,
  workOrders,
  handleApproveAndNext,
  onNext,
  user,
  type,
  closeWorkOrderUponApproval,
  onCloseWorkOrderUponApprovalChange,
}) => {
  let isRework = false;
  if (selectedWorkOrder && selectedWorkOrder.customDataFields) {
    isRework = selectedWorkOrder.customDataFields.findIndex(({ key }) => key === 'reworkReason') > -1;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        {type !== TAB_TYPE.COMPLETED && (
          <>
            {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) && <Button onClick={onReplacePhotoClick} type='file' color='orange'>
              Replace Photo
            </Button>}
            <input accept="image/jpg" ref={fileInputRef} type='file' hidden onChange={async (event) => replacePhoto(event)} />
            {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) && <Button onClick={() => showAddPhotoModal(selectedWorkOrder.id, selectedWorkOrder.oldMeterNumber)} color='orange'>
              Add Photo
            </Button>}
          </>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        {type === TAB_TYPE.ESCALATED && isRework && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, justifyContent: 'center' }}><div><Checkbox onChange={onCloseWorkOrderUponApprovalChange} checked={closeWorkOrderUponApproval} label='Close the work order upon approval.' /></div></div>}
        {type !== TAB_TYPE.ESCALATED && <Button color='yellow' disabled={modalDetailsLoading} onClick={() => handleEscalate()}>
          Escalate
        </Button>}
        <Button color='red' disabled={modalDetailsLoading} onClick={() => handleReject()}>
          Reject
        </Button>
        {type !== TAB_TYPE.COMPLETED && <Button primary disabled={modalDetailsLoading || disableApprove} onClick={() => handleApprove(selectedWorkOrder.id, closeWorkOrderUponApproval)}>
          Approve
        </Button>}
        {workOrders.length > 1 && type !== TAB_TYPE.COMPLETED ? (<Button primary disabled={disableApprove} onClick={() => handleApproveAndNext(selectedWorkOrder.id, closeWorkOrderUponApproval)}>
          Approve & Next
        </Button>) : null}
        <Button color='grey' onClick={() => onNext()}>
          Next
        </Button>
      </div>
    </div>
  )
}

export default QualityAssuranceDetailsActions;