import React from 'react';
import { Button } from 'semantic-ui-react';

const Search = ({ searchRef, onSearch, placeholder }) => (
  <React.Fragment>
    <div className='field ui input' style={{ paddingRight: '10px' }}>
      <input ref={searchRef} type='text' placeholder={placeholder} style={{ width: '400px', paddingRight: '5px' }} />
    </div>
    <Button primary onClick={onSearch}>Search</Button>
  </React.Fragment>
)

export default Search;