import { SET_POPUP, RESET_ADMIN_OVERVIEW_STATE } from '../types/administrationOverview.types';

const initialState = {
  popup: null,
}

const administrationOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POPUP:
      return { ...state, popup: action.payload };
    case RESET_ADMIN_OVERVIEW_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export default administrationOverviewReducer;