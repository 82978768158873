import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Button } from 'semantic-ui-react';
import { EXPORT_BEFORE_UPLOAD_OPERATION_TYPE_LABEL, EXPORT_ARGUMENT_VALUE_TYPE } from '../constants';

const CustomActionsColumn = ({ dataItem, onDelete }) => (
  <td>
    {dataItem.selected ? (
      <div style={{ display: 'flex' }}>
        <Button circular icon='delete' onClick={() => onDelete(dataItem.id)} />
      </div>
    ) : null}
  </td>
)

const ExportReportBeforeUploadTable = ({
  onDeleteBeforeUpload,
  beforeUpload
}) => {
  const [data, setData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    setData(beforeUpload.map((dataItem, index) => Object.assign({ selected: false, id: index }, dataItem)));
    setSelectedRowId(null);
  }, [beforeUpload]);

  const getTableData = () => {
    return data.map((item) => {
      return { 
        ...item, 
        name: EXPORT_BEFORE_UPLOAD_OPERATION_TYPE_LABEL[item.name],
        selected: selectedRowId === item.id,
        values: item.args.map(item => {
          const type = typeof (item) === 'object' ? EXPORT_ARGUMENT_VALUE_TYPE.json : typeof (item)
          const value = typeof (item) === EXPORT_ARGUMENT_VALUE_TYPE.string ? item : JSON.stringify(item);
          return `${value} (${type})`
        }).join(', ')
      }
    });
  }

  const rowClick = (event) => {
    setSelectedRowId(event.dataItem.id);
  };

  const onDelete = (id) => {
    onDeleteBeforeUpload(id);
    setSelectedRowId(null);
  }

  return (
    <>
      <div>
        <Grid
          data={getTableData()}
          selectedField='selected'
          onRowClick={rowClick}
        >
          <GridNoRecords>
            No data
          </GridNoRecords>
          <Column field='name' width='200px' title='Operation' />
          <Column field='values' title='Values' width='500px' />
          <Column field='' title='' cell={(props) => <CustomActionsColumn {...props} onDelete={onDelete} />} />
        </Grid>
      </div>
    </>
  )
}

export default ExportReportBeforeUploadTable;