import React, { useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { PROJECT_STATUSES } from './index';

const CustomGanttForm = ({ dataItem, onCancel, onClose, onSubmit }) => {
    const [startDate, setStartDate] = useState(dataItem.startDate);
    const [endDate, setEndDate] = useState(dataItem.endDate);
    const [projectStatus, setProjectStatus] = useState(dataItem.projectStatus);

    const handleStartDateChange = (event) => {
      setStartDate(event.value);
    };

    const handleEndDateChange = (event) => {
      setEndDate(event.value);
    };

    const handleStatusChange = (event) => {
      setProjectStatus(event.value);
    };

    const handleSubmit = () => {
      onSubmit({ dataItem: { ...dataItem, startDate, endDate, projectStatus } });
    };

    return (
      <Dialog title="Edit Project Dates" onClose={onClose} modal={false}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <label>Project Name:</label>
            <span>{dataItem.text}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
            <label style={{paddingRight:"20px"}}>Start Date:</label>
            <DatePicker value={startDate} onChange={handleStartDateChange} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
            <label style={{paddingRight:"20px"}}>End Date:</label>
            <DatePicker value={endDate} onChange={handleEndDateChange} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
            <label style={{paddingRight:"20px"}}>Status:</label>
            <DropDownList data={PROJECT_STATUSES} value={projectStatus}  onChange={handleStatusChange} />
          </div>
        </div>
        <DialogActionsBar>
          <button className="k-button" onClick={onCancel}>
            Cancel
          </button>
          <button className="k-button k-primary" onClick={handleSubmit}>
            Submit
          </button>
        </DialogActionsBar>
      </Dialog>
    );
  };
  
  export default CustomGanttForm;
  
  