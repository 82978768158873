import gql from 'graphql-tag';

export const UPDATE_PROJECT_REPORT_PREFERENCES_MUTATION = gql`
  mutation UpdateProjectReportPreferences($projectReportPreferencesId: ID!, $reportConfiguration: String!) {
    updateProjectReportPreferences(input:{
      projectReportPreferencesId: $projectReportPreferencesId
      reportConfiguration: $reportConfiguration
    }) {
      id
    }
  }
`;

export const CREATE_PROJECT_REPORT_PREFERENCES_MUTATION = gql`
mutation CreateProjectReportPreferences($reportType: String!, $projectId: ID!, $projectReportPreferencesId: ID, $reportName: String!, $userId: ID) {
  createProjectReportPreferences(input:{
    projectReportPreferencesId: $projectReportPreferencesId
    userId: $userId
    reportName: $reportName
    projectId: $projectId
    reportType: $reportType
  }) {
    id
  }
}
`;