import React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { Checkbox } from 'semantic-ui-react';

const dateFormat = { year: '2-digit', day: '2-digit', month: '2-digit' };
const dateLocales = 'en-US';

const getDateByDay = (day) => {
  const date = new Date();
  date.setDate(date.getDate() + day);
  return new Intl.DateTimeFormat(dateLocales, { ...dateFormat }).format(date)
}

const TableView = ({ data, showBlackOutAssignments, onCheckboxToggle, projectHasBlackOuts }) => (
  <Grid className='projects-table' data={data}>
    <GridNoRecords>
      Loading data...
    </GridNoRecords>
    {projectHasBlackOuts && <GridToolbar>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <label style={{ paddingRight: '15px' }}>Show assignments for next 7 days (effected by blackouts)</label>
          <Checkbox toggle checked={showBlackOutAssignments} onChange={onCheckboxToggle} />
        </div>
      </div>
    </GridToolbar>}
    <Column field='fullName' title='Technician' width='150px' />
    <Column field='userName' title='Username' width='150px' />
    <Column field='assignmentsDay0' title={getDateByDay(0)} width='110px' />
    <Column field='assignmentsDay1' title={getDateByDay(1)} width='110px' />
    <Column field='assignmentsDay2' title={getDateByDay(2)} width='110px' />
    <Column field='assignmentsDay3' title={getDateByDay(3)} width='110px' />
    <Column field='assignmentsDay4' title={getDateByDay(4)} width='110px' />
    <Column field='assignmentsDay5' title={getDateByDay(5)} width='110px' />
    <Column field='assignmentsDay6' title={getDateByDay(6)} width='110px' />
  </Grid>
)

export default TableView;