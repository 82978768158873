import { CognitoUserPool } from 'amazon-cognito-identity-js';
import packageJson from './../../package.json';

const version = packageJson.version;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const qsServiceBaseUrl = process.env.REACT_APP_QS_SERVICE_BASE_URL;

const config = {
  version,
  region: process.env.REACT_APP_APPSYNC_CLIENT_REGION,
  cognitoPool: new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  }),
  apolloClient: {
    uri: process.env.REACT_APP_APOLLO_CLIENT_URI
  },
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  googleAnalyticsTrackingCode: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
  mapbox: {
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    style: process.env.REACT_APP_MAPBOX_STYLE,
    satelliteStyle: process.env.REACT_APP_MAPBOX_SATELLITE_STYLE,
    geoCodignStyle: process.env.REACT_APP_MAPBOX_GEO_CODING_STYLE,
  },
  mapDataApi: `${apiBaseUrl}/getMapDataPreSignedUrl`,
  workOrderDetailsImageApi: `${apiBaseUrl}/getImagePreSignedUrl`,
  adminExportAssignmentsReportApi: `${apiBaseUrl}/exportAssignmentsReport`,
  adminExportTechnicianSummaryReportApi: `${apiBaseUrl}/exportTechSummaryReport`,
  invokeAdminExportPayrollReportApi: `${apiBaseUrl}/invokeExportPayrollReport`,
  invokeAdminExportDetailViewReportApi: `${apiBaseUrl}/invokeExportDetailViewReport`,
  invokeExportTechSummaryReport: `${apiBaseUrl}/invokeExportTechSummaryReport`,
  invokeAdminExportDurationViewReportApi: `${apiBaseUrl}/invokeExportDurationReport`,
  exportReportByTypeApi: `${apiBaseUrl}/exportReportByType`,
  exportWorkOrdersReportApi: `${apiBaseUrl}/exportWorkOrdersReport`,
  exportInventoryReportApi: `${apiBaseUrl}/exportInventoryReport`,
  exportAppointmentsReportApi: `${apiBaseUrl}/exportAppointmentsReport`,
  getUploadUrlApi: `${apiBaseUrl}/getUploadURL`,
  addPhotoApi: `${apiBaseUrl}/addPhoto`,
  adminCreateUserApi: `${apiBaseUrl}/adminCreateUser`,
  adminUpdateUserApi: `${apiBaseUrl}/adminUpdateUser`,
  invokeGeoJsonFileCreationApi: `${apiBaseUrl}/invokeGeoJsonFileCreation`,
  getInventoryUploadUrlApi: `${apiBaseUrl}/getInventoryUploadUrl`,
  getDomainInfoApi: `${apiBaseUrl}/getDomainInfo`,
  supportUrl: process.env.REACT_APP_SUPPORT_URL,
  waterInventoryImportTemplateUrl: process.env.REACT_APP_S3_WATER_INVENTORY_IMPORT_TEMPLATE_URL,
  electricInventoryImportTemplateUrl: process.env.REACT_APP_S3_ELECTRIC_INVENTORY_IMPORT_TEMPLATE_URL,
  getQuickSightDashboardEmbedUrlApi: `${apiBaseUrl}/getQuickSightDashboardEmbedUrl`,
  getUserAvatar: `${apiBaseUrl}/getUserAvatar`,
  adminCreateUserResendInviteApi: `${apiBaseUrl}/adminCreateUserResendInvite`,
  createSSOUserApi: `${apiBaseUrl}/createSSOUser`,
  qsServiceNamespaceApi: `${qsServiceBaseUrl}/namespaces`,
  qsServiceGroupApi: `${qsServiceBaseUrl}/groups`,
}

export default config;