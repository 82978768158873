import client from '../apollo';
import { LIST_UPLOAD_STATUS_BY_PROJECT_QUERY } from '../../graphql/queries/uploadStatus';

export const listUploadStatusesByProject = async (projectId) => {
  try {
    const { data } = await client.query({
      query: LIST_UPLOAD_STATUS_BY_PROJECT_QUERY,
      variables: { projectId },
      fetchPolicy: 'no-cache',
    });
    const uploadStatuses = data.listUploadStatusesByProject;
    return uploadStatuses.items && uploadStatuses.items.length > 0 ? uploadStatuses.items : [];
  } catch (error) {
    console.log(error);
  }
}