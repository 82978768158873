import React from 'react';
import { Menu, Header, Icon, Label, Dropdown, Checkbox, Popup } from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react'
import { MAP_LAYOUT_TYPES } from '../../../../constants/mapLayout';
import AppContext from '../../../../core/context/app.context';
import * as mapActions from '../../../../core/redux/actions/map.actions';
import { connect } from 'react-redux';

const DROPDOWN_OPTIONS = [
  {
    key: 0,
    value: MAP_LAYOUT_TYPES.WorkOrderStatus,
    text: MAP_LAYOUT_TYPES.WorkOrderStatus,
  },
  {
    key: 1,
    value: MAP_LAYOUT_TYPES.ProjectStatusOverview,
    text: MAP_LAYOUT_TYPES.ProjectStatusOverview,
  },
  {
    key: 2,
    value: MAP_LAYOUT_TYPES.District,
    text: MAP_LAYOUT_TYPES.District,
  },
  {
    key: 3,
    value: MAP_LAYOUT_TYPES.Substation,
    text: MAP_LAYOUT_TYPES.Substation
  },
  {
    key: 4,
    value: MAP_LAYOUT_TYPES.Circuit,
    text: MAP_LAYOUT_TYPES.Circuit,
  },
  {
    key: 5,
    value: MAP_LAYOUT_TYPES.Cycle,
    text: MAP_LAYOUT_TYPES.Cycle,
  },
  {
    key: 6,
    value: MAP_LAYOUT_TYPES.Route,
    text: MAP_LAYOUT_TYPES.Route,
  },
  {
    key: 7,
    value: MAP_LAYOUT_TYPES.Assignment,
    text: MAP_LAYOUT_TYPES.Assignment,
  },
  {
    key: 8,
    value: MAP_LAYOUT_TYPES.FormSize,
    text: MAP_LAYOUT_TYPES.FormSize,
  },
];

const DROPDOWN_OPTIONS_WITH_OVERLAY = [
  {
    key: 0,
    value: MAP_LAYOUT_TYPES.WorkOrderStatus,
    text: MAP_LAYOUT_TYPES.WorkOrderStatus,
  },
  {
    key: 1,
    value: MAP_LAYOUT_TYPES.WorkOrderStatusPOI,
    text: MAP_LAYOUT_TYPES.WorkOrderStatusPOI,
  },
  {
    key: 2,
    value: MAP_LAYOUT_TYPES.ProjectStatusOverview,
    text: MAP_LAYOUT_TYPES.ProjectStatusOverview,
  },
  {
    key: 3,
    value: MAP_LAYOUT_TYPES.District,
    text: MAP_LAYOUT_TYPES.District,
  },
  {
    key: 4,
    value: MAP_LAYOUT_TYPES.Substation,
    text: MAP_LAYOUT_TYPES.Substation
  },
  {
    key: 5,
    value: MAP_LAYOUT_TYPES.Circuit,
    text: MAP_LAYOUT_TYPES.Circuit,
  },
  {
    key: 6,
    value: MAP_LAYOUT_TYPES.Cycle,
    text: MAP_LAYOUT_TYPES.Cycle,
  },
  {
    key: 7,
    value: MAP_LAYOUT_TYPES.Route,
    text: MAP_LAYOUT_TYPES.Route,
  },
  {
    key: 8,
    value: MAP_LAYOUT_TYPES.Assignment,
    text: MAP_LAYOUT_TYPES.Assignment,
  },
  {
    key: 9,
    value: MAP_LAYOUT_TYPES.FormSize,
    text: MAP_LAYOUT_TYPES.FormSize,
  }
];

const trigger = (mapStatus) => (
  <span>
    View: <b>{mapStatus}</b>
  </span>
)

const TopMenu = ({
  config,
  style = {},
  mapViewActive,
  geoJsonSize,
  setMapLayoutType,
  setShowMapToolbar,
  showMapToolbar,
  layoutType,
  refreshMap,
  resizeMap,
  searchMap,
  disableFilters,
  disableViews,
  setDisableFilters,
  customViewData,
  setGeoJsonSize,
  geoJsonDataCopy
}) => {
  const onMapLayoutTypeChange = (event, data, currentProject) => {
    setMapLayoutType(data.value);
    searchMap(currentProject.projectWorkOrderType, false, currentProject.projectTimeZone);
    if (data.value === MAP_LAYOUT_TYPES.CustomDataFields) {
      setShowMapToolbar(false);
      setDisableFilters(true);
      setGeoJsonSize(customViewData.length);
    } else {
      setDisableFilters(false);
      setGeoJsonSize(geoJsonDataCopy.features.length);
    }
  }

  const onToggleSideMenu = async (toggleSideMenu) => {
    await toggleSideMenu();
    if (mapViewActive) {
      resizeMap();
    }
  }

  const getMapLayoutTypeOptions = (currentProject) => {
    let { projectConfiguration } = currentProject;
    const options = projectConfiguration && projectConfiguration.additionalFeatures && projectConfiguration.additionalFeatures.overlayGeoJSON ?
      [...DROPDOWN_OPTIONS_WITH_OVERLAY]
      :
      [...DROPDOWN_OPTIONS]
    if (projectConfiguration && projectConfiguration.additionalFeatures && projectConfiguration.additionalFeatures.customDataGeoJson) {
      options.push({
        key: options.length + 1,
        value: MAP_LAYOUT_TYPES.CustomDataFields,
        text: MAP_LAYOUT_TYPES.CustomDataFields,
      });
    }
    return options;
  }

  return (
    <AppContext.Consumer>{({ workOrderInvalidLocationCount, toggleSideMenu, workOrdersPageMapViewActive, currentProject }) => (
      <div style={{ display: 'flex', ...workOrdersPageMapViewActive ? { margin: '1em 1em' } : { marginBottom: '14px' } }}>
        <Menu stackable style={{ ...style, flex: '1' }}>
          <Menu.Item>
            <Icon name='list' onClick={() => onToggleSideMenu(toggleSideMenu)} style={{ cursor: 'pointer', fontSize: '23px' }} />
          </Menu.Item>
          <Menu.Item>
            <Header><Icon name={config.header.iconName} />{config.header.title}</Header>
          </Menu.Item>
          {config.tabs.map((tab, key) => (
            <Menu.Item
              key={key}
              active={tab.active}
              onClick={tab.onClick}
            >
              {tab.iconName ? <Icon name={tab.iconName} /> : null}
              {tab.title}
              {tab.loader && tab.loader.loading ? <div style={{ paddingLeft: '5px' }}><Loader active inline size='small' /></div> :
                tab.label ? <Label color={tab.label.color}>{tab.label.value}</Label> : null}
            </Menu.Item>
          ))}
          {disableFilters ? (
            <Popup
              trigger={(
                mapViewActive && <Menu.Item>
                  <Checkbox disabled={true} checked={showMapToolbar} label='Show Filters' />
                  <span style={{ marginLeft: 5 }}>
                    <Icon circular name='info' color='grey' size='small' />
                  </span>
                </Menu.Item>
              )}
              content={disableFilters && disableViews ? 'Map data still loading...' : 'Filters are not available on Custom View'}
              size='mini'
            />
          ) : (
            <>
              {mapViewActive && <Menu.Item>
                <Checkbox checked={showMapToolbar} onChange={() => setShowMapToolbar(!showMapToolbar)} label='Show Filters' />
              </Menu.Item>}
            </>
          )}
          {mapViewActive && <Menu.Item>
            <span style={workOrderInvalidLocationCount > 0 ? { marginRight: 5 } : {}}>{geoJsonSize} items shown</span>
            {workOrderInvalidLocationCount > 0 && <Popup
              trigger={<Icon circular name='info' color='grey' size='small' />}
              content={`${workOrderInvalidLocationCount} items not shown (invalid coordinates)`}
              size='mini'
            />}
          </Menu.Item>}
          {mapViewActive && <Menu.Menu position='right'>
            {disableViews ? (
              <Popup
                trigger={
                  <Menu.Item>
                    <Dropdown disabled={true} trigger={trigger(layoutType)} options={getMapLayoutTypeOptions(currentProject)} onChange={(event, data) => onMapLayoutTypeChange(event, data, currentProject)} value={layoutType} />
                    <span style={{ marginLeft: 7 }}>
                      <Icon circular name='info' color='grey' size='small' />
                    </span>
                  </Menu.Item>}
                content={'Map data still loading...'}
                size='mini'
              />
            ) : (
              <Menu.Item>
                <Dropdown trigger={trigger(layoutType)} options={getMapLayoutTypeOptions(currentProject)} onChange={(event, data) => onMapLayoutTypeChange(event, data, currentProject)} value={layoutType} />
              </Menu.Item>
            )}
            <Menu.Item
              active={false}
              onClick={refreshMap}
            >
              <Icon style={{ margin: 0 }} name='refresh' />
            </Menu.Item>
          </Menu.Menu>}
        </Menu>
      </div>
    )}</AppContext.Consumer>
  )
}

const mapStateToProps = ({ map }) => {
  return {
    showMapToolbar: map.showMapToolbar,
    layoutType: map.layoutType,
    geoJsonSize: map.geoJsonSize,
    disableFilters: map.disableFilters,
    disableViews: map.disableViews,
    customViewData: map.customViewData,
    geoJsonDataCopy: map.geoJsonDataCopy
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMapLayoutType: (layoutType) => dispatch(mapActions.setLayoutType(layoutType)),
    setShowMapToolbar: (showMapToolbar) => dispatch(mapActions.setShowMapToolbar(showMapToolbar)),
    resizeMap: () => dispatch(mapActions.resizeMap()),
    searchMap: (workOrderType, zoom, projectTimeZone) => dispatch(mapActions.searchMap(workOrderType, zoom, projectTimeZone)),
    setDisableFilters: (disableFilters) => dispatch(mapActions.setDisableFilters(disableFilters)),
    setGeoJsonSize: (size) => dispatch(mapActions.setGeoJsonSize(size))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);