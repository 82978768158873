import client from '../apollo';
import {
  RETURN_ASSET_MUTATION,
  ASSIGN_ALL_INVENTORIES_BY_PROJECT_ID_MUTATION,
  REMOVE_ASSETS_MUTATION,
  TRANSFER_TO_UTILITY_MUTATION,
  RELEASE_TO_PRODUCTION_MUTATION,
  UPDATE_INVENTORY_NON_SERIALIZED_ASSET_QUANTITY_MUTATION,
  APPROVE_FOR_TRANSFER_MUTATION,
  RECEIVE_FOR_PRODUCTION_MUTATION,
  TRANSFER_TO_WAREHOUSE_MUTATION,
} from '../../graphql/mutations/inventory';
import {
  GET_INVENTORY_STATUS_BY_METER_TYPE_QUERY,
  GET_INVENTORY_STATUS_BY_METER_TYPE_WITH_DELETED_QUERY,
  GET_INVENTORY_STATUS_BY_REGISTER_TYPE_QUERY,
  GET_INVENTORY_STATUS_BY_RADIO_TYPE_QUERY,
  GET_INVENTORY_STATUS_BY_RADIO_TYPE_WITH_DUAL_QUERY,
  GET_INVENTORY_FILTER_CRITERIA_QUERY,
  GET_INVENTORY_FILTER_CRITERIA_ADVANCED_MANAGEMENT_QUERY,
  LIST_INVENTORY_NON_SERIALIZED_ASSETS_QUERY,
  GET_INVENTORY_FILTER_CRITERIA_MULTIPLE_WAREHOUSES_QUERY,
  LIST_INVENTORY_ASSET_LOCATION
} from '../../graphql/queries/inventory';
import { getToken } from './auth.service';
import config from '../../config';

export const approveForTransfer = async (projectId, inventoryIds) => {
  try {
    await client.mutate({
      mutation: APPROVE_FOR_TRANSFER_MUTATION,
      variables: { projectId, inventoryIds }
    });
  } catch (error) {
    console.log(error);
  }
}

export const transferToWarehouse = async (projectId, inventoryIds, assetDestination) => {
  try {
    await client.mutate({
      mutation: TRANSFER_TO_WAREHOUSE_MUTATION,
      variables: { projectId, inventoryIds, assetDestination }
    });
  } catch (error) {
    console.log(error);
  }
}

export const receiveForProduction = async (projectId, inventoryIds) => {
  try {
    await client.mutate({
      mutation: RECEIVE_FOR_PRODUCTION_MUTATION,
      variables: { projectId, inventoryIds }
    });
  } catch (error) {
    console.log(error);
  }
}

export const returnAsset = async (projectId, mfgSerialNumber) => {
  try {
    await client.mutate({
      mutation: RETURN_ASSET_MUTATION,
      variables: {
        projectId,
        mfgSerialNumber
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const assignAllInventoriesByProjectId = async (projectId) => {
  try {
    await client.mutate({
      mutation: ASSIGN_ALL_INVENTORIES_BY_PROJECT_ID_MUTATION,
      variables: {
        projectId
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const getInventoryStatusByMeterType = async (projectId, assetLocation) => {
  try {
    let { data } = await client.query({
      query: GET_INVENTORY_STATUS_BY_METER_TYPE_QUERY,
      variables: { projectId, assetLocation },
      fetchPolicy: 'no-cache'
    });
    return data && data.getInventoryStatusByMeterType ? data.getInventoryStatusByMeterType.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const getInventoryStatusByMeterTypeWithDeleted = async (projectId, assetLocation) => {
  try {
    let { data } = await client.query({
      query: GET_INVENTORY_STATUS_BY_METER_TYPE_WITH_DELETED_QUERY,
      variables: { projectId, assetLocation },
      fetchPolicy: 'no-cache'
    });
    return data && data.getInventoryStatusByMeterTypeWithDeleted ? data.getInventoryStatusByMeterTypeWithDeleted.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const getInventoryStatusByRegisterType = async (projectId, assetLocation) => {
  try {
    let { data } = await client.query({
      query: GET_INVENTORY_STATUS_BY_REGISTER_TYPE_QUERY,
      variables: { projectId, assetLocation },
      fetchPolicy: 'no-cache'
    });
    return data && data.getInventoryStatusByRegisterType ? data.getInventoryStatusByRegisterType.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const getInventoryStatusByRadioType = async (projectId, assetLocation) => {
  try {
    let { data } = await client.query({
      query: GET_INVENTORY_STATUS_BY_RADIO_TYPE_QUERY,
      variables: { projectId, assetLocation },
      fetchPolicy: 'no-cache'
    });
    return data && data.getInventoryStatusByRadioType ? [data.getInventoryStatusByRadioType] : [];
  } catch (error) {
    console.log(error);
  }
}

export const getInventoryStatusByRadioTypeWithDual = async (projectId, assetLocation) => {
  try {
    let { data } = await client.query({
      query: GET_INVENTORY_STATUS_BY_RADIO_TYPE_WITH_DUAL_QUERY,
      variables: { projectId, assetLocation },
      fetchPolicy: 'no-cache'
    });
    return data && data.getInventoryStatusByRadioTypeWithDual ? [data.getInventoryStatusByRadioTypeWithDual] : [];
  } catch (error) {
    console.log(error);
  }
}

export const getUploadUrl = async (projectId, fileName) => {
  try {
    const token = await getToken();
    let response = await fetch(`${config.getInventoryUploadUrlApi}/${projectId}/${encodeURI(fileName)}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token
      }
    });
    response = await response.json();
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const uploadInventory = async (signedUrl, file, type) => {
  try {
    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': `${type === 'csv' ? 'text/csv' : 'text/xml'}`
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const getFilterTypes = async (projectId, advancedInventoryManagement, multipleWarehouses) => {
  let query;
  if (advancedInventoryManagement) {
    if (multipleWarehouses && multipleWarehouses.length > 0) {
      query = GET_INVENTORY_FILTER_CRITERIA_MULTIPLE_WAREHOUSES_QUERY;
    } else {
      query = GET_INVENTORY_FILTER_CRITERIA_ADVANCED_MANAGEMENT_QUERY;
    }
  } else {
    query = GET_INVENTORY_FILTER_CRITERIA_QUERY;
  }
  
  try {
    const { data } = await client.query({
      query,
      fetchPolicy: 'no-cache',
      variables: { projectId }
    });
    return data.listInventoryFilterCriteria || [];
  } catch (error) {
    console.log(error);
  }
}

export const removeAssets = async (projectId, inventoryIds) => {
  try {
    await client.mutate({
      mutation: REMOVE_ASSETS_MUTATION,
      variables: { projectId, inventoryIds }
    });
  } catch (error) {
    console.log(error);
  }
}

export const transferToUtility = async (projectId, inventoryIds) => {
  try {
    await client.mutate({
      mutation: TRANSFER_TO_UTILITY_MUTATION,
      variables: { projectId, inventoryIds }
    });
  } catch (error) {
    console.log(error);
  }
}

export const releaseToProduction = async (projectId, inventoryIds) => {
  try {
    await client.mutate({
      mutation: RELEASE_TO_PRODUCTION_MUTATION,
      variables: { projectId, inventoryIds }
    });
  } catch (error) {
    console.log(error);
  }
}

export const listInventoryNonSerializedAssets = async (projectId) => {
  try {
    let { data } = await client.query({
      query: LIST_INVENTORY_NON_SERIALIZED_ASSETS_QUERY,
      variables: { projectId },
      fetchPolicy: 'no-cache'
    });
    return data && data.listInventoryNonSerializedAssets && data.listInventoryNonSerializedAssets.items && data.listInventoryNonSerializedAssets.items.length > 0 ? data.listInventoryNonSerializedAssets.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const listInventoryAssetLocation = async (projectId) => {
  try {
    let { data } = await client.query({
      query: LIST_INVENTORY_ASSET_LOCATION,
      variables: { projectId },
      fetchPolicy: 'no-cache'
    });
    return data && data.listInventoryAssetLocation && data.listInventoryAssetLocation.items && data.listInventoryAssetLocation.items.length > 0 ? data.listInventoryAssetLocation.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const updateNonSerializedAssetQuantity = async (item, userId) => {
  try {
    await client.mutate({
      mutation: UPDATE_INVENTORY_NON_SERIALIZED_ASSET_QUANTITY_MUTATION,
      variables: { ...item, userId }
    });
  } catch (error) {
    throw error;
  }
}