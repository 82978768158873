import * as React from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import AppContext from '../../../core/context/app.context';

export const AssignmentsEditor = (props) => {
  const context = React.useContext(AppContext);

  const [users, setUsers] = React.useState([]);
  const [assignedUsers, setAssignedUsers] = React.useState([]);

  React.useEffect(() => {
    const { currentProject, projectUserByWorkOrderStatusAndCount } = context;
    const usersList = currentProject ? projectUserByWorkOrderStatusAndCount.map(({ id, fullName, userName }) => ({ id, fullName, userName })) : [];
    setUsers(usersList);
    if(props.value){
      setAssignedUsers(props.value)
    } else if(props.selectedWorkOrder){
      const assigned = props.selectedWorkOrder.workOrderAssignments ? usersList.filter((item) => props.selectedWorkOrder.workOrderAssignments.includes(item.userName)) : [];
      setAssignedUsers(assigned)
    }
  }, []);

  const handleChange = event => {
    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value
      });
    }
  };

  return (
    <MultiSelect
      disabled={false}
      onChange={handleChange}
      value={assignedUsers}
      data={users.map(u => Object.assign({}, { id: u.id, fullName: u.fullName }))}
      dataItemKey={'id'}
      textField={'fullName'}
    />
  );
};