import React, { useState } from 'react';
import { Button, Modal, Checkbox } from 'semantic-ui-react';
import CustomErrorMessage from '../../../../shared/components/messages/error';

export const WorkOrdersAssignmentsModalScheduler = ({
  closeModal,
  error,
  users,
  onModalConfirm,
  assignmentsList,
  handleAssignmentChange,
  selectedAppointments
}) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      onModalConfirm();
    }
  }

  const SelectedWorkOrdersUI = () => {
    const [showAll, setShowAll] = useState(false);
    const firstTen = selectedAppointments.slice(0, 10);
    const lastWOs = selectedAppointments.slice(10);

    const showMoreStyles = {
      color: 'blue',
      textDecoration: 'underline',
      cursor: 'pointer',
    }
    return (<>
      <h3>Changes will be made to the following appointments:</h3>
      {showAll ?
        <p>{[...firstTen, ...lastWOs].map(appointment => appointment.workOrderNumber).join(' • ')}</p> :
        <p>{firstTen.map(appointment => appointment.workOrderNumber).join(' • ')}</p>
      }
      {lastWOs.length > 0 &&
        <p onClick={() => setShowAll(prev => !prev)} style={showMoreStyles}>
          {showAll ? '...show less.' : `...show ${lastWOs.length} more.`}
        </p>
      }
    </>)
  }

  return (
    <div onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' onClose={closeModal} open={true}>
        <Modal.Header>Assignments</Modal.Header>
        <Modal.Content>
          <SelectedWorkOrdersUI />
          {error ? <CustomErrorMessage message='Error. Unable to fetch users' /> : (
            <table style={{ width: '100%' }} className='assignments-table'>
              <tbody>
                <tr>
                  <th>Assigned</th>
                  <th>Unassigned</th>
                  <th>Technician</th>
                </tr>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td><Checkbox value={user.id} onChange={(e, d) => handleAssignmentChange(d, 'assign')} checked={assignmentsList.assigned.includes(user.id)}/></td>
                    <td><Checkbox value={user.id} onChange={(e, d) => handleAssignmentChange(d, 'unassign')} checked={assignmentsList.unassigned.includes(user.id)}/></td>
                    <td>
                      {user.fullName} {user.Assigned ? `[${user.Assigned}]` : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>Cancel</Button>
          <Button onClick={onModalConfirm} positive icon='checkmark' labelPosition='right' content='Confirm' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}
// export default WorkOrdersAssignmentsModalScheduler;