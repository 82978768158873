import React, { useContext, useState } from 'react';
import { Table, Checkbox, Message, Image } from 'semantic-ui-react';
import EDIT_ICON from '../../../assets/icons/baseline_edit_black_18dp.png';
import AppContext from '../../../core/context/app.context';
import { isEnabled } from '../../../core/services/auth.service';
import EditModal, { MODAL_TYPE } from '../../../routes/quality-assurance/components/modal/editModal';
import VerifyModal from '../../../routes/quality-assurance/components/modal/verifyModal';
import CustomDataArrayModal from './customDataArrayModal';
import ROLES from '../../../constants/roles';

const MOBILE_TEMPLATES_COMPONENT_TYPES = {
  'selection-item': 'selection-item',
  'text-entry': 'text-entry',
  'multiline-text': 'multiline-text',
  'switch-item': 'switch-item',
  'radio-item': 'radio-item',
  'dynamic-list': 'dynamic-list'
}

const CustomDataTab = ({
  data,
  user,
  currentProject,
  selectedWorkOrder,
  updateWorkOrder,
  getWorkOrderDetails,
  isQualityAssurance,
  qaDoubleEntryVerification,
  verifyWorkOrder,
  workOrdersVerified
}) => {
  const [modalType, setModalType] = useState('');
  const [customData, setCustomData] = useState(undefined);
  const [showVerifyModal, setShowVerifyModal] = useState(true);

  const context = useContext(AppContext);

  const onCloseCustomDataArrayModal = () => {
    setCustomData(undefined);
  }

  const onShowCustomDataArrayModal = (customData) => {
    setCustomData(customData);
  }

  const updateOldMeterKwhReading = async (id, oldMeterKwhReading) => {
    await updateWorkOrder({ id, oldMeterKwhReading, workOrderReviewedUserId: user.id });
  }

  const getProjectMobileTemplateSelectionItemOptions = (screens, actionParameter) => {
    const screen = screens.find(i => i.name === actionParameter);
    const [screenComponent] = screen.components;
    if (screenComponent && screenComponent.items && screenComponent.items.length > 0) {
      return screenComponent.items[0].items.map(({ title }) => title);
    }
    return [];
  }

  const getProjectMobileTemplates = () => {
    let allCustomDataFields = [];

    const projectMobileTemplates = currentProject.projectMobileTemplates;
    const projectImportConfig = currentProject.projectImportConfig;

    if (projectMobileTemplates && projectMobileTemplates.mobileTemplates) {
      const mobileTemplates = projectMobileTemplates.mobileTemplates;
      if (mobileTemplates && mobileTemplates.length > 0) {
        const [mobileTemplate] = mobileTemplates;
        if (mobileTemplate && mobileTemplate.screens && mobileTemplate.screens.length > 0) {
          const { screens } = mobileTemplate;
          const getScreenComponentCustomDataFields = (workOrderDetailScreen) => {
            if (workOrderDetailScreen) {
              const { components } = workOrderDetailScreen;
              if (components && components.length > 0) {
                for (let component of components) {
                  const customDataFields = component.items
                    .filter(componentItem => componentItem.dataProperty && componentItem.dataProperty === 'customDataFields')
                    .map(({ actionParameter, type, customDataSection }) => Object.assign({}, { actionParameter, type, key: customDataSection }));
                  allCustomDataFields = [...allCustomDataFields, ...customDataFields];
                }
                allCustomDataFields.map(customDataField => {
                  customDataField.readOnly = true;
                  if (projectImportConfig && projectImportConfig.tables && projectImportConfig.tables.WorkOrder && projectImportConfig.tables.WorkOrder.columns && projectImportConfig.tables.WorkOrder.columns.customDataFields) {
                    if (projectImportConfig.tables.WorkOrder.columns.customDataFields.indexOf(customDataField.key) === -1) {
                      customDataField.readOnly = false;
                    }
                  } else {
                    customDataField.readOnly = false;
                  }
                  if (customDataField.type === MOBILE_TEMPLATES_COMPONENT_TYPES['selection-item']) {
                    customDataField.options = getProjectMobileTemplateSelectionItemOptions(screens, customDataField.actionParameter);
                  }
                  return customDataField;
                });
              }
            }
          }
          screens.forEach(screen => getScreenComponentCustomDataFields(screen));
        }
      }
    }
    const results = {};
    for (let customData of allCustomDataFields) {
      results[customData.key] = customData;
    }
    if (data) {
      for (let customData of data) {
        if (results[customData.key]) {
          results[customData.key].value = customData.value;
        } else {
          results[customData.key] = {
            ...customData,
            readOnly: true
          }
        }
      }
    }
    return results;
  }

  const updateCustomDataFields = async (id, value = '', key, boolean = false) => {
    let customDataFields = selectedWorkOrder.customDataFields && selectedWorkOrder.customDataFields.length > 0 ? [...selectedWorkOrder.customDataFields] : [];
    const oldCustomData = customDataFields.find(i => i.key === key);
    if (oldCustomData) {
      oldCustomData.value = boolean ? JSON.stringify(value) : value;
    } else {
      customDataFields = [...customDataFields, {
        key,
        type: boolean ? 'Boolean' : 'String',
        value: boolean ? JSON.stringify(value) : value,
      }];
    }
    await updateWorkOrder({ id, customDataFields });
  }

  const onEditCustomDataArray = async (value) => {
    const customDataFields = selectedWorkOrder.customDataFields && selectedWorkOrder.customDataFields.length > 0 ? [...selectedWorkOrder.customDataFields] : [];
    const oldCustomData = customDataFields.find(i => i.key === customData.key);
    oldCustomData.value = value.length > 0 ? JSON.stringify(value) : JSON.stringify([]);
    await updateWorkOrder({ id: selectedWorkOrder.id, customDataFields });
    onCloseCustomDataArrayModal();
  }

  const getCustomDataArrayProperties = (key) => {
    const { currentProject } = context;
    if (currentProject) {
      const screens = currentProject.projectMobileTemplates.mobileTemplates[0].screens;
      const workOrderDetailsScreen = screens.find(screen => screen.name === 'workorder-detail');
      if (workOrderDetailsScreen) {
        let dynamicList;
        for (const component of workOrderDetailsScreen.components) {
          for (const componentItem of component.items) {
            if (componentItem.type === 'dynamic-list' && componentItem.customDataSection === key) {
              dynamicList = componentItem;
            }
          }
        }
        if (dynamicList) {
          return dynamicList.items
            .filter(i => i.dataProperty === 'customDataFields')
            .map(i => Object.assign({}, { key: i.customDataSection, type: i.dataPropertyType, options: i.type === 'selection-item' ? getProjectMobileTemplateSelectionItemOptions(screens, i.actionParameter) : undefined }));
        }
      }
    }

    return [];
  }

  const getCustomDataArrayLength = (item) => {
    console.log(item)
    if (item && item.value) {
      return JSON.parse(item.value).length;
    }
    return 0;
  }

  const renderTable = (
  ) => {
    const template = getProjectMobileTemplates();
    const user = context.user;
    const customDataArrayProperties = customData ? getCustomDataArrayProperties(customData.key) : [];
    return (
      <React.Fragment>
        {showVerifyModal && qaDoubleEntryVerification && isQualityAssurance && selectedWorkOrder && selectedWorkOrder.id && workOrdersVerified && !workOrdersVerified.includes(selectedWorkOrder.id) && !selectedWorkOrder.workOrderFlaggedAsDamaged && !selectedWorkOrder.workOrderFlaggedAs3strike && <VerifyModal
          addComment
          escalate
          updateWorkOrderStatus
          userId={user.id}
          selectedWorkOrder={selectedWorkOrder}
          photoIndex={0}
          title={'Data Verification'}
          closeModal={setShowVerifyModal}
          showModal={showVerifyModal}
          inputValue={selectedWorkOrder.oldMeterKwhReading}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateOldMeterKwhReading}
          verifyWorkOrder={verifyWorkOrder}
          modalType='text' />}
        {customData && <CustomDataArrayModal
          customData={customData}
          editable={!customData.readOnly && isQualityAssurance && isEnabled(user.userRoles || [], [ROLES.SystemAdmin, ROLES.AccountAdmin])}
          closeModal={onCloseCustomDataArrayModal}
          onSubmit={onEditCustomDataArray}
          properties={customDataArrayProperties}
          showModal={!!customData} />}
        {Object.values(template).length > 0 ? (
          <>
            {Object.values(template).map((item, index) =>
              item.key === modalType && <div key={index}><EditModal
                isCustomData={true}
                title={item.key}
                closeModal={() => setModalType('')}
                showModal={item.key === modalType}
                inputValue={item.value}
                workOrderId={selectedWorkOrder.id}
                customDataKey={item.key}
                getWorkOrderDetails={getWorkOrderDetails}
                update={updateCustomDataFields}
                options={item.options || []}
                modalType={item.type === MOBILE_TEMPLATES_COMPONENT_TYPES['selection-item'] ? MODAL_TYPE.dropdown : MODAL_TYPE.text} />
              </div>)}
            <Table definition compact>
              <Table.Body>
                {Object.values(template).map((item, index) =>
                  <Table.Row key={index}>
                    <Table.Cell width={6}>{item.key}</Table.Cell>
                    {item.type === MOBILE_TEMPLATES_COMPONENT_TYPES['dynamic-list'] ?
                      <Table.Cell width={6}>
                        <span onClick={() => onShowCustomDataArrayModal(item)} style={{ color: '#2285D0', cursor: 'pointer' }}>Show List ({getCustomDataArrayLength(item)})</span>
                      </Table.Cell> : (
                        <>
                          {item.type === 'Boolean' || item.type === MOBILE_TEMPLATES_COMPONENT_TYPES['switch-item'] || item.type === MOBILE_TEMPLATES_COMPONENT_TYPES['radio-item'] ? (
                            <Table.Cell width={6}>
                              <Checkbox disabled={item.readOnly || !isQualityAssurance} onChange={(e, { checked }) => updateCustomDataFields(selectedWorkOrder.id, checked, item.key, true)} checked={item.value === 'true'} />
                            </Table.Cell>
                          ) : (
                            <Table.Cell width={6}>
                              {item.readOnly || !isQualityAssurance ? (
                                <>{item.value}</>
                              ) : (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div>
                                    {item.value}
                                  </div>
                                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Image src={EDIT_ICON} style={{ cursor: 'pointer' }} onClick={() => setModalType(item.key)} />
                                  </div>
                                </div>
                              )}
                            </Table.Cell>
                          )}
                        </>
                      )}
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        ) : <Message color='grey' >No Data Available...</Message>}
      </React.Fragment>
    )
  }

  return renderTable();
}

export default CustomDataTab;