import React, { useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';

const NewFileColumnModal = ({ modalRef, showModal, closeModal, onSubmit, tableColumnNames, fileColumnNamesCopy }) => {
  const [tableColumnName, setTableColumnName] = useState('');
  const [fileColumnNames, setFileColumnNames] = useState([]);
  const [value, setValue] = useState('');

  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmitButtonClick();
    }
  }

  const onSubmitButtonClick = () => {
    if (value && tableColumnName && fileColumnNames.length > 0) {
      onSubmit({
        tableColumnName,
        fileColumnNames,
        value
      });
    }
  }

  const onTableColumnNameChange = (event, { value }) => {
    setTableColumnName(value);
  }

  const onFileColumnNameChange = (event, { value }) => {
    setFileColumnNames([...value]);
  }

  const onValueChange = (event, { value }) => {
    setValue(value);
  }

  const getTableColumns = () => {
    return tableColumnNames.map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }));
  }

  const getFileColumns = () => {
    return [...new Set(fileColumnNamesCopy)].map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }));
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>Join Columns</Modal.Header>
        <Modal.Content>
          <Form onSubmit={onSubmitButtonClick} id='submit-form'>
            <Form.Select
              fluid
              search
              value={tableColumnName}
              onChange={onTableColumnNameChange}
              label='Table Column'
              options={getTableColumns()}
              placeholder='Table Column'
            />
            <Form.Select
              fluid
              search
              multiple
              value={fileColumnNames}
              onChange={onFileColumnNameChange}
              label='File Columns'
              options={getFileColumns()}
              placeholder='File Columns'
            />
            <Form.Input
              value={value}
              onChange={onValueChange}
              label='Text'
              placeholder='Text'
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>Cancel</Button>
          <Button disabled={!value || !tableColumnName || fileColumnNames.length === 0} type='submit' form='submit-form' positive icon='checkmark' labelPosition='right' content='Join Columns' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default NewFileColumnModal;