import { MAP_LAYOUT_TYPES, MAP_LAYOUT_IDS } from '../constants/mapLayout';
import MAP_LAYOUT_DEFAULT_COLORS from '../routes/workorders/constants/mapLayoutDefaultColors';
import _ from 'lodash';
import { getRandomColor } from '../helpers/color';
import * as turf from '@turf/turf';

export const getFeaturePaint = (feature, layoutType) => {
  const { properties } = feature;

  const workOrderFlaggedAs3strike = properties['3s'];
  const workOrderStatus = properties.ws;
  const workOrderReasonCodes = properties.reas;
  const workOrderIsInBlackOut = properties.bo;
  const workOrderNeedsAppointment = properties.appt;
  const workOrderNeedsSiteTest = properties.nst;
  const workOrderFlaggedAsCNC = properties.cnc;
  const workOrderFlaggedAsAdHoc = properties.ah;

  if (layoutType === MAP_LAYOUT_TYPES.ProjectStatusOverview) {
    if (!workOrderFlaggedAs3strike && (workOrderStatus === 'InReview' || workOrderStatus === 'Completed' || workOrderStatus === 'Closed')) {
      return ['CHECKED_ICON', '#EEBF04'];
    } else if (workOrderStatus === 'Open' || workOrderStatus === 'Assigned') {
      return ['OPEN_ICON', '#616161'];
    } else if (
      (workOrderReasonCodes === '' && (workOrderStatus === 'InProgress' || workOrderStatus === 'Escalated')) ||
      (workOrderReasonCodes && workOrderReasonCodes !== '' && workOrderReasonCodes.indexOf('RTU Review') === -1 && (workOrderStatus === 'InProgress' || workOrderStatus === 'Escalated'))) {
      return ['ASSIGNED_CNC_ICON', '#E19613'];
    } else if (workOrderStatus === 'InProgress' && workOrderReasonCodes !== '' && workOrderReasonCodes.indexOf('RTU Review') > -1) {
      return ['UTILITY_REVIEW_ICON', '#C84F0A'];
    } else if (workOrderFlaggedAs3strike && (workOrderStatus === 'Closed' || workOrderStatus === 'Completed')) {
      return ['THREE_STRIKE_ICON', '#D0021B'];
    }
    return ['OPEN_ICON', '#616161'];
  } else {
    if (workOrderIsInBlackOut && workOrderStatus !== 'Completed' && workOrderStatus !== 'Closed' && !workOrderFlaggedAs3strike) {
      return ['BLACKOUT_ICON', '#212121'];
    } else if (workOrderStatus === 'Assigned' && workOrderNeedsAppointment) {
      return ['APPOINTMENT_ICON', '#C84F0A'];
    } else if (workOrderStatus === 'Assigned' && workOrderNeedsSiteTest) {
      return ['SITE_TEST_ICON', '#C84F0A'];
    } else if (workOrderStatus === 'Assigned' && !workOrderNeedsAppointment) {
      return ['ASSIGNED_ICON', '#63A318'];
    } else if (workOrderStatus === 'InProgress' && workOrderFlaggedAsCNC && workOrderNeedsAppointment) {
      return ['APPOINTMENT_ICON', '#C84F0A'];
    } else if (workOrderStatus === 'InProgress' && workOrderFlaggedAsCNC && workOrderNeedsSiteTest) {
      return ['SITE_TEST_ICON', '#C84F0A'];
    } else if (workOrderStatus === 'InProgress' && workOrderFlaggedAsCNC && !workOrderNeedsSiteTest && !workOrderNeedsAppointment) {
      return ['ASSIGNED_CNC_ICON', '#E19613'];
    } else if (workOrderStatus === 'Completed' && workOrderFlaggedAs3strike) {
      return ['THREE_STRIKE_ICON', '#D0021B'];
    } else if (workOrderStatus === 'InReview' && workOrderFlaggedAsAdHoc) {
      return ['AD_HOC_ICON', '#196AB8'];
    } else if (workOrderStatus === 'InReview' && !workOrderFlaggedAsAdHoc) {
      return ['SAVED_ICON', '#196AB8'];
    } else if (workOrderStatus === 'Open' && workOrderNeedsSiteTest) {
      return ['SITE_TEST_ICON', '#C84F0A'];
    } else if (workOrderStatus === 'Open' && !workOrderNeedsSiteTest) {
      return ['OPEN_ICON', '#616161'];
    }
    return ['CHECKED_ICON', '#EEBF04'];
  }
}

const degreesToRadians = (degrees) => {
  return degrees * Math.PI / 180;
}

export const getDistanceBetweenPoints = (lat1, lng1, lat2, lng2) => {
  // The radius of the planet earth in meters
  let R = 6378137;
  let dLat = degreesToRadians(lat2 - lat1);
  let dLong = degreesToRadians(lng2 - lng1);
  let a = Math.sin(dLat / 2)
    *
    Math.sin(dLat / 2)
    +
    Math.cos(degreesToRadians(lat1))
    *
    Math.cos(degreesToRadians(lat1))
    *
    Math.sin(dLong / 2)
    *
    Math.sin(dLong / 2);

  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distance = R * c;

  return distance.toFixed(2);
}

export const isValidLngLat = (latitude, longitude) => {
  return latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
}

const getLayoutPaintProperty = (data) => {
  const result = {};
  if (data.length === 0) {
    return getRandomColor();
  }
  data.forEach(type => {
    result[type] = getRandomColor();
  });
  return result;
}

export const filterData = (data, layoutType, layoutData, map) => {
  const tempData = {...data, features: _.cloneDeep(data.features)}
  const paint = ['match', ['get', 'color']];

  let colors;

  switch(layoutType) {
    case MAP_LAYOUT_TYPES.FormSize: {
      colors = getLayoutPaintProperty(layoutData.formSizes);
      break;
    }
    case MAP_LAYOUT_TYPES.Route: {
      colors = getLayoutPaintProperty(layoutData.routeCodes);
      break;
    }
    case MAP_LAYOUT_TYPES.Cycle: {
      colors = getLayoutPaintProperty(layoutData.billingCycleCodes);
      break;
    }
    case MAP_LAYOUT_TYPES.Substation: {
      colors = getLayoutPaintProperty(layoutData.substationCodes);
      break;
    }
    case MAP_LAYOUT_TYPES.Circuit: {
      colors = getLayoutPaintProperty(layoutData.circuitCodes);
      break;
    }
    case MAP_LAYOUT_TYPES.District: {
      colors = getLayoutPaintProperty(layoutData.districtCodes);
      break;
    }
    case MAP_LAYOUT_TYPES.Assignment: {
      colors = getLayoutPaintProperty(layoutData.workOrderAssignments);
      break;
    }
    default: {
      
    }
  }

  const defaultColor = '#616161';

  if (colors && typeof(colors) !== 'string') {
    Object.values(colors).forEach(color => {
      paint.push(color);
      paint.push(color);
    });
    paint.push(defaultColor);
  } else if (map || (map && typeof(colors) === 'string')) {
    map.setPaintProperty(MAP_LAYOUT_IDS.Primary, 'circle-color', MAP_LAYOUT_DEFAULT_COLORS);
  }
  tempData.features = tempData.features.map((feature, index) => {
    const result = {};
    const properties = [
      'li',
      'num',
      'ws',
      'lvd',
      'str',
      't',
      'asgn',
      'grps',
      'dc',
      'rc',
      'sc',
      'bcc',
      'cc',
      'a',
      'tfn',
      'tlvd',
      'id',
      'lat',
      'lng',
      'an',
      'mff',
      'ms',
      'wt'
    ];
    properties.forEach(property => {
      if (feature.properties.hasOwnProperty(property)) {
        const value = feature.properties[property];
        if (value !== null && value !== undefined) {
          result[property] = value;
        } 
      }
    });
    const [icon, color] = getFeaturePaint(feature, layoutType);
    result.icon = icon;
    result.color = color;

    if (colors) {
      switch(layoutType) {
        case MAP_LAYOUT_TYPES.FormSize: {
          const size = feature.properties.ms || feature.properties.ms === '' ? 'ms' : 'mff';
          result.color = typeof(colors) === 'string' ? defaultColor : colors[feature.properties[size]];
          break;
        }
        case MAP_LAYOUT_TYPES.Route: {
          result.color = typeof(colors) === 'string' ? defaultColor : colors[feature.properties.rc];
          break;
        }
        case MAP_LAYOUT_TYPES.Cycle: {
          result.color = typeof(colors) === 'string' ? defaultColor : colors[feature.properties.bcc];
          break;
        }
        case MAP_LAYOUT_TYPES.Substation: {
          result.color = typeof(colors) === 'string' ? defaultColor : colors[feature.properties.sc];
          break;
        }
        case MAP_LAYOUT_TYPES.Circuit: {
          result.color = typeof(colors) === 'string' ? defaultColor : colors[feature.properties.cc];
          break;
        }
        case MAP_LAYOUT_TYPES.District: {
          result.color = typeof(colors) === 'string' ? defaultColor : colors[feature.properties.dc];
          break;
        }
        case MAP_LAYOUT_TYPES.Assignment: {
          result.color = typeof(colors) === 'string' ? defaultColor : colors[feature.properties.asgn];
          break;
        }
        default: {
          
        }
      }
    }
    feature.properties = result;
    return feature;
  });
  if (colors && typeof(colors) !== 'string') {
    map.setPaintProperty(MAP_LAYOUT_IDS.Primary, 'circle-color', paint);
  }
  return tempData;
}

export const fitBounds = (map, { features }) => {
  const bbox = turf.bbox(
    turf.featureCollection(
      features
        .map(feature => feature.geometry.coordinates)
        .map(i => turf.point(i))));
  map.fitBounds(bbox, { padding: 60 });
}