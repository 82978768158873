import gql from 'graphql-tag';

export const UPDATE_PROJECT_PLANNING_ROUTE_CODE_ORDER_MUTATION = gql`
  mutation updateProjectPlanningRouteCodeOrder($projectId: ID!, $planningRouteCodeOrder: [PlanningRouteCodeOrderInput]!) {
    updateProjectPlanningRouteCodeOrder(input:{
      projectId: $projectId
      planningRouteCodeOrder: $planningRouteCodeOrder
    }) {
      id
      projectSequence
    }
  }
`;

export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject(
    $projectName: String!,
    $tenantId: ID!,
    $isActive: Boolean!,
    $isDemo: Boolean!,
    $projectBucket: String!,
    $projectShortCode: String!,
    $projectBucket: String!,
    $projectTimeZone: String!,
    $projectHasBlackOuts: Boolean!,
    $latitude: Float!,
    $longitude: Float!
    $projectWorkOrderType: String!
  ) {
    createProject(input:{
      projectName: $projectName
      tenantId: $tenantId
      isActive: $isActive
      isDemo: $isDemo
      projectBucket: $projectBucket
      projectShortCode: $projectShortCode
      projectTimeZone: $projectTimeZone
      projectHasBlackOuts: $projectHasBlackOuts
      latitude: $latitude
      longitude: $longitude
      projectWorkOrderType: $projectWorkOrderType
    }) {
      id
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject(
    $id: ID!,
    $projectName: String,
    $projectNumber: String,
    $projectCategory: String,
    $isActive: Boolean,
    $isDemo: Boolean,
    $autoImport: Boolean,
    $projectShortCode: String,
    $projectTimeZone: String,
    $projectHasBlackOuts: Boolean,
    $latitude: Float,
    $longitude: Float,
    $projectMobileTemplates: String,
    $projectWorkOrderType: String,
    $projectExportConfig: String,
    $projectImportConfig: String,
    $projectConfiguration: String,
    $projectDefaults: String,
    $projectStartDate: AWSDate,
    $projectEndDate: AWSDate,
    $projectStatus: String
  ) {
    updateProject(input:{
      id: $id
      projectName: $projectName
      projectNumber: $projectNumber
      projectCategory: $projectCategory
      isActive: $isActive
      isDemo: $isDemo
      projectShortCode: $projectShortCode
      autoImport: $autoImport
      projectTimeZone: $projectTimeZone
      projectHasBlackOuts: $projectHasBlackOuts
      latitude: $latitude
      longitude: $longitude
      projectMobileTemplates: $projectMobileTemplates
      projectWorkOrderType: $projectWorkOrderType
      projectExportConfig: $projectExportConfig
      projectImportConfig: $projectImportConfig
      projectConfiguration: $projectConfiguration
      projectDefaults: $projectDefaults
      projectStartDate: $projectStartDate
      projectEndDate: $projectEndDate
      projectStatus: $projectStatus
    }) {
      id
    }
  }
`;