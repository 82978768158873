import gql from 'graphql-tag';

export const LIST_PROJECT_REPORT_PREFERENCES_QUERY = gql`
  query ListProjectReportPreferences($projectId: ID!, $reportType: String!) {
    listProjectReportPreferences(projectId: $projectId, reportType: $reportType) {
      items {
        id
        reportName
      }
    }
  }
`;