import client from '../apollo';
import { GET_LATEST_VERSION_BY_TYPE_QUERY } from '../../graphql/queries/version';
import { CREATE_VERSION_MUTATION } from '../../graphql/mutations/version';

export const getLatestVersionByType = async () => {
  try {
    const { data } = await client.query({
      query: GET_LATEST_VERSION_BY_TYPE_QUERY,
      fetchPolicy: 'no-cache'
    });
    return data.getLatestVersionByType || null;
  } catch (error) {
    console.log(error);
  }
}

export const createVersion = async (versionName) => {
  try {
    await client.mutate({
      mutation: CREATE_VERSION_MUTATION,
      fetchPolicy: 'no-cache',
      variables: {
        versionName
      }
    });
  } catch (error) {
    console.log(error);
  }
}