import React from 'react';
import { Modal, Button, Form, Label, Input, Message } from 'semantic-ui-react';
import ErrorMessage from '../../../../shared/components/messages/error';
import AppContext from '../../../../core/context/app.context';
import ReviewGallery from '../../../../shared/components/review-gallery';
import Splitter from '../../../../shared/components/workorder-details/splitter';
import * as commentService from '../../../../core/services/comment.service';
import { createWorkOrderHistory } from '../../../../core/services/workOrder.service';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';

export const MODAL_TYPE = {
  text: 'text',
  dropdown: 'dropdown',
  number: 'number'
}

export default class VerifyModal extends React.Component {
  _isMounted = false;
  static contextType = AppContext;

  state = {
    inputValue: null,
    workOrderId: null,
    updatingWorkOrder: false,
    submitted: false,
    success: false,
    failure: false,
    error: null,
    color: 'grey'
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  onSlide = (index) => {
    const direction = index > this.state.currentIndex ? 'next' : 'prev';
    this.setState({ currentIndex: index, direction });
  };

  handleEscalate = async (selectedWorkOrder) => {
    this.props.handleEscalate(selectedWorkOrder);
  }

  componentDidMount() {
    this._isMounted = true;
    const { inputValue, workOrderId, workOrderType, onGallerySlide, photoIndex, selectedWorkOrder, verifiedWorkOrders, handleEscalate } = this.props;
    this.setState({ inputValue, workOrderId, workOrderType, onGallerySlide, photoIndex, selectedWorkOrder, verifiedWorkOrders, handleEscalate });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(newProps) {
    const { inputValue, workOrderId, workOrderType, onGallerySlide, photoIndex, selectedWorkOrder, verifiedWorkOrders, handleEscalate } = newProps;
    this.setState({ inputValue, workOrderId, workOrderType, onGallerySlide, photoIndex, selectedWorkOrder, verifiedWorkOrders, handleEscalate });
  }

  onChange = (event, data) => {
    this.setState({ inputValue: data.value });
  }

  closeModal = () => {
    const { closeModal } = this.props;
    this.setState({ inputValue: null, error: null });
    closeModal();
  }

  onSave = async () => {
    const { update, customDataKey, submitWithoutValue } = this.props;
    const { inputValue, workOrderId } = this.state;
    if (inputValue || submitWithoutValue) {
      try {
        this.setState({ updatingWorkOrder: true, error: null });
        await update(workOrderId, inputValue, customDataKey);
      } catch (error) {
        this.setState({ error: 'Unexpected error has occurred', updatingWorkOrder: false });
      }
    }
  }

  handleKeyPress = (event) => {
    const { inputValue } = this.state;
    if ((event.charCode === 13)||(event.key === 'Enter') || (event.key ==='NumpadEnter')) {
      event.preventDefault();
      this.onNext(this.state.input,inputValue);
    }
  }
  
  addComment = async (workOrderId, comment) => {
    const { user } = this.context;
    const userId = user.id;
    await commentService.addComment(userId, workOrderId, comment);
  }

  onNext = async (value,inputValue) => {
    try{
      const { update,userId,customDataKey,verifyWorkOrder,selectedWorkOrder } = this.props;
      const { workOrderId  } = this.state;
      let kwhText = ''; 
      if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
        kwhText = 'KWH ';
      } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
        kwhText = '';
      } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
        kwhText = '';
      }
      if (value) {
        if (value.toString()===inputValue.toString()){
          this.setState({success: true});
          await createWorkOrderHistory({
            historyContent: `Verification: QA matched ${kwhText}value ${inputValue.toString()}.`,
            historyUser: `${userId}`,
            historyWorkOrder: `${workOrderId}`
          });
          setTimeout(async () => {
            verifyWorkOrder(workOrderId);
            this.setState({ updatingWorkOrder: true, error: null });
            await update(workOrderId, value.toString(), customDataKey);
            this.setState({submitted: true});
            this.closeModal();
          }, 3000);
        } else {
          this.setState({failure: true});
        }
      }
    } catch (err){
      console.log(err.message);
    }
  }

  onCommit = async (value,inputValue) => {
    const { update, closeModal,customDataKey,userId,verifyWorkOrder,selectedWorkOrder } = this.props;
    const { workOrderId } = this.state;
    let kwhText = ''; 
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      kwhText = 'KWH ';
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      kwhText = '';
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      kwhText = '';
    }
    try {
      if (value.toString()===inputValue.toString()){
        await createWorkOrderHistory({
          historyContent: `Verification: QA did not match. Accepted technician ${kwhText}value ${value.toString()}.`,
          historyUser: `${userId}`,
          historyWorkOrder: `${workOrderId}`
        })
      } else {
        await createWorkOrderHistory({
          historyContent: `Verification: QA did not match. Changed technician ${kwhText}value from ${inputValue.toString()} to ${value.toString()}.`,
          historyUser: `${userId}`,
          historyWorkOrder: `${workOrderId}`
        })
      }
      this.setState({ updatingWorkOrder: true, error: null });
      await update(workOrderId, value.toString(), customDataKey);
    } catch (error) {
      this.setState({ error: 'Unexpected error has occurred', updatingWorkOrder: false });
    } finally {
      verifyWorkOrder(workOrderId);
      this.setState({submitted: true});
      closeModal();
    }
  }

  handleInputChange = e => {
    this.setState({ input: e.target.value });
    this.setState({ color: 'blue' });
  };

  render() {
    const { showModal, title, modalType, options, selectedWorkOrder} = this.props;
    const { inputValue, updatingWorkOrder, error, submitted, success, failure, color, currentIndex } = this.state;
    let kwhText = ''; 
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      kwhText = 'KWH ';
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      kwhText = '';
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      kwhText = '';
    }
    return (!submitted && 
      <div onKeyPress={this.handleKeyPress}>
        <Modal dimmer={{ style: {zIndex: 1002}}} size='fullscreen' open={showModal}>
          <Modal.Header>QA:{title}</Modal.Header>
          <Modal.Content image scrolling className='modal-size'>
          <Splitter>
            <Modal.Description className='details-description'>
            {modalType === MODAL_TYPE.text || modalType === MODAL_TYPE.number ? (
              <>
              {!failure && 
              <Form>
              <Label size='large'>
              Enter Old Meter {kwhText}reading:
              </Label>
                <Input
                size='small'
                action={{
                  color: color,
                  labelPosition: 'right',
                  icon: 'clipboard check',
                  content: 'Next',
                  onClick: (e)=>this.onNext(this.state.input,inputValue)
                }}
                onChange={this.handleInputChange}
                placeholder='Enter value'
                autoFocus
              />
              {success &&
                <Message positive>
                  <Message.Header>Verified</Message.Header>
                  <p>Matched tech's data exactly! Window closing...</p>
                </Message>
              }
              </Form>
              } 
              {failure &&
              <Form>
                <Label size='large'>
                Enter Old Meter {kwhText}reading:
                </Label>
                  <Input
                  size='small'
                  action={{
                    color: 'orange',
                    labelPosition: 'right',
                    icon: 'clipboard check',
                    content: 'Confirm',
                    onClick: (e)=>this.onCommit(this.state.input,inputValue)
                  }}
                  defaultValue={this.state.input}
                  onChange={this.handleInputChange}
                  placeholder='Enter value'
                  autoFocus
                />
                <Message negative>
                  <Message.Header>Verification Failed</Message.Header>
                  <p>Enter and confirm the correct value above.</p>
                </Message>
                <Message>
                  <Message.Header>Tech entered value:</Message.Header>
                  <p>{inputValue}</p>
                </Message>
              </Form>
              }
              </>
            ) : (
              <Form.Select clearable autoFocus value={inputValue || ''} onChange={this.onChange} style={{ width: '100%' }} options={options.map(i=>Object.assign({}, {key: i, text: i, value: i}))}/>
            )}
            {error && <ErrorMessage message={error} />}
            </Modal.Description>
            {selectedWorkOrder && <ReviewGallery onSlide={(e)=>this.onSlide(e)} photoIndex={currentIndex} workOrder={selectedWorkOrder} />}
            </Splitter>
          </Modal.Content>
          <Modal.Actions>
            <Button color='yellow' loading={updatingWorkOrder} onClick={() => this.handleEscalate(selectedWorkOrder)}>Escalate</Button>
            <Button onClick={this.closeModal} negative>Close</Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}