import React, { Component } from 'react';
import { Button, Icon, Image, Dropdown, Input, Message } from 'semantic-ui-react';
import AppContext from '../../core/context/app.context';
import TopMenu from '../../shared/components/top-menu';
import BACKGROUND_IMAGE from '../../assets/ReportBackground.png';
import ErrorMessage from '../../shared/components/messages/error';
import config from '../../config';
import { getToken } from '../../core/services/auth.service';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';

class InventoryReportRoute extends Component {

  static contextType = AppContext;

  state = {
    error: '',
    exporting: false,
    assetDeployed: '',
    assetNumber: ''
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Inventory Report` : 'Inventory Report'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  downloadReport = async (projectId, assetDeployed, assetNumber) => {
    try {
      const token = await getToken();
      const response = await fetch(`${config.exportInventoryReportApi}/${projectId}/${assetDeployed}${assetNumber ? `/${assetNumber}` : ''}`, {
        method: 'get',
        headers: new Headers({
          'Authorization': token
        })
      });
      if (!response.ok) {
        throw new Error('No data found');
      }
      const url = await response.json();
      window.open(url);
    } catch (error) {
      throw error;
    }
  }

  export = async () => {
    this.setState({ error: '' });
    try {
      let { assetDeployed, assetNumber } = this.state;
      const { currentProject } = this.context;
      if (assetDeployed) {
        this.setState({ exporting: true });
        await this.downloadReport(currentProject.id, assetDeployed, assetNumber);
        this.setState({ exporting: false });
      } else {
        this.setState({ error: 'Asset Deployed is required' });
      }
    } catch (error) {
      this.setState({ error: error.message, exporting: false });
    }
  }

  onAssetDeployedChange = (event, data) => {
    this.setState({ assetDeployed: data.value });
  }

  onAssetNumberChange = (event, data) => {
    this.setState({ assetNumber: data.value });
  }

  renderInventoryReportRouteContent = () => {
    const { error, exporting } = this.state;
    return (
      <React.Fragment>
        <Message color='grey' content='This report exports data for All assets, deployed assets, non-deployed assets or a single asset.' />
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <Dropdown
            clearable
            placeholder='Asset Deployed'
            selection
            options={[
              { key: 0, value: 'all', text: 'All' },
              { key: 1, value: 'yes', text: 'Yes' },
              { key: 2, value: 'no', text: 'No' }]}
            onChange={this.onAssetDeployedChange} />
          <Input style={{ marginLeft: '10px' }} type='text' placeholder='Asset Number' onChange={this.onAssetNumberChange} />
          <Button loading={exporting} style={{ marginLeft: '10px' }} onClick={this.export}><Icon name='download' />Export</Button>
        </div>
        {error && <div style={{ marginTop: '10px' }}>
          <ErrorMessage message={error} />
        </div>}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
          <Image src={BACKGROUND_IMAGE} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        {this.renderInventoryReportRouteContent()}
      </ProjectRouteWrapper>
    );
  }
}

export default InventoryReportRoute;