import React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';

class ConfirmActionModal extends React.Component {

  _isMounted = false;
  static contextType = AppContext;

  state = {
    mfgSerialNumber: '',
    showModal: false,
    error: false
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  componentWillMount = () => {
    this._isMounted = true;
    const { showModal, mfgSerialNumber } = this.props;
    this.setState({ showModal, mfgSerialNumber: mfgSerialNumber || '' });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps = (newProps) => {
    const { showModal, mfgSerialNumber } = newProps;
    this.setState({ showModal, mfgSerialNumber: mfgSerialNumber || '' });
  }

  onModalConfirm = async () => {
    try {
      this.setState({ error: false });
      const { onModalConfirm } = this.props;
      await onModalConfirm();
    } catch (error) {
      console.log(error);
      this.setState({ error: true });
    }
  }

  render() {
    const { showModal, mfgSerialNumber, error } = this.state;
    const { closeModal, description, title, defaultErrorMessage, disabled } = this.props;
    const hasMfgSerialNumberProp = Object.keys(this.props).indexOf(i => i === 'mfgSerialNumber') > -1;
    return (
      <Modal size='tiny' open={showModal}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          {description}
          {this.props.children}
          {error && <Message negative>
            <Message.Header>{defaultErrorMessage}</Message.Header>
          </Message>}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>Cancel</Button>
          <Button onClick={this.onModalConfirm} disabled={(hasMfgSerialNumberProp && mfgSerialNumber.length === 0) || disabled} positive icon='checkmark' labelPosition='right' content='Update' />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ConfirmActionModal;