import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const DemoModal = ({ closeModal, showModal }) => (
  <Modal size='tiny' open={showModal}>
    <Modal.Header>Demo Project</Modal.Header>
    <Modal.Content>
      <p>Feature disabled…</p>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={closeModal} primary>Close</Button>
    </Modal.Actions>
  </Modal>
)

export default DemoModal;