import React, { useEffect, useState } from 'react';
import { Popup } from 'react-mapbox-gl';
import * as photoService from '../../../core/services/photo.service';
import { connect } from 'react-redux';
import * as date from '../../../helpers/date';
import PW_BADGE_PLACEHOLDER from '../../../assets/PWBadgePlaceholder.png';

const PopupRenderer = ({ popup }) => {

  const [image, setImage] = useState('');

  useEffect(() => {
    const getUserAvatar = async () => {
      const avatar = await photoService.getUserAvatar(popup.properties.avatar);
      setImage(avatar);
    }

    if (popup && popup.properties && popup.properties.avatar) {
      getUserAvatar();
    }

    if (!popup) {
      setImage('');
    }
  }, [popup]);

  const onBadgeError = (event) => {
    event.target.setAttribute('src', PW_BADGE_PLACEHOLDER);
  }

  const renderPopup = () => {
    let {
      workOrderNumber,
      fullName,
      workOrderLastVisitDate
    } = popup.properties;

    workOrderLastVisitDate = workOrderLastVisitDate ? (date.format({ workOrderLastVisitDate }, ['workOrderLastVisitDate'])).workOrderLastVisitDate : '';

    return (
      <Popup coordinates={popup.lngLat}>
        {image && <><img onError={onBadgeError} alt='avatar' src={image || PW_BADGE_PLACEHOLDER} width='250px' /><br /></>}
        {fullName && <><b>Technician:</b> {fullName}<br /></>}
        {workOrderNumber && <><b>Work Order Number:</b> {workOrderNumber}<br /></>}
        {workOrderLastVisitDate && <><b>Last Visit Date:</b> {workOrderLastVisitDate}<br /></>}
      </Popup>
    )
  }

  return (
    <>
      {popup && renderPopup()}
    </>
  )
}

const mapStateToProps = ({ administrationOverview }) => {
  return {
    popup: administrationOverview.popup,
  }
}

export default connect(mapStateToProps, null)(PopupRenderer);