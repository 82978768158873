import React from 'react';
import { Button, Icon, Modal, Checkbox } from 'semantic-ui-react';
import CustomErrorMessage from '../../../../shared/components/messages/error';

const WorkOrdersAssignmentsModal = ({
  iconName,
  title,
  showModal,
  closeModal,
  error,
  users,
  onModalConfirm,
  addUserToAssignedList,
  addUserToUnAssignedList,
  assignUsersList,
  unassingUsersList,
  assigning
}) => (
    <Modal size='tiny' onClose={closeModal} open={showModal}>
      <Modal.Header><Icon name={iconName} /> {title}</Modal.Header>
      <Modal.Content>
        {error ? <CustomErrorMessage message='Error. Unable to fetch users' /> : (
          <table style={{ width: '100%' }} className='assignments-table'>
            <tbody>
              <tr>
                <th>Assigned</th>
                <th>Unassigned</th>
                <th>Technician</th>
              </tr>
              {users.map((user, index) => (
                <tr key={index}>
                  <td><Checkbox value={user.id} onChange={addUserToAssignedList} checked={assignUsersList.indexOf(user.id) > -1} /></td>
                  <td><Checkbox value={user.id} onChange={addUserToUnAssignedList} checked={unassingUsersList.indexOf(user.id) > -1} /></td>
                  <td>
                    {user.fullName} {user.counts ? `[${user.counts}]` : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button loading={assigning} onClick={onModalConfirm} disabled={error} positive icon='checkmark' labelPosition='right' content='Confirm' />
      </Modal.Actions>
    </Modal>
  )

export default WorkOrdersAssignmentsModal;