import React from 'react';
import { useApolloClient } from '@apollo/client';

const withApollo = (Component) => {
  return (props) => {
    const client = useApolloClient();

    return <Component client={client} {...props} />;
  };
};

export default withApollo;