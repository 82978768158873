import gql from 'graphql-tag';

export const GET_MAP_STATUS_BY_ID_AND_STATUS_QUERY = gql`
  query getMapStatusByIdAndStatus($id: ID!) {
    getMapStatusByIdAndStatus(id: $id, status: Done) {
      id
      url
      overlayUrl
      customDataUrl
    }
  }
`;