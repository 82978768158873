import gql from 'graphql-tag';

export const LIST_TENANTS_QUERY = gql`
  query listTenants {
    listTenants {
      items {
        id
        tenantName
        tenantCode
      }
    }
  }
`;