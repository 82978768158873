import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Input, Modal } from 'semantic-ui-react';
import { capitalize } from '../../../../helpers/string';
import REPORT_TABLE_COLUMNS from '../../../../constants/reportTableColumns';
import { EXPORT_ARGUMENT_VALUE_TYPE, EXPORT_COLUMN_TYPE, EXPORT_CONDITION_OPERATION_TYPE, EXPORT_CONDITION_OPERATION_TYPE_LABEL } from '../constants';

const argumentTypeSchema = {
  type: '',
  value: ''
}

const conditionSchema = {
  column_name: '',
  type: '',
  operation: ''
}

const ReportConditionModal = ({ showModal, closeModal, workOrderType, onSaveCondition, selectedReportCondition }) => {
  const [condition, setCondition] = useState({ ...conditionSchema });
  const [argument, setArgument] = useState({ ...argumentTypeSchema });

  useEffect(() => {
    if (selectedReportCondition) {
      const { column_name, type, operation } = selectedReportCondition;
      setCondition({ column_name, type, operation: operation.name });
      setArgument({
        type: typeof (operation.args[0]) === 'object' ? EXPORT_ARGUMENT_VALUE_TYPE.json : typeof (operation.args[0]),
        value: typeof (operation.args[0]) === EXPORT_ARGUMENT_VALUE_TYPE.string ? operation.args[0] : JSON.stringify(operation.args[0])
      });
    }
  }, []);

  const onColumnNameChange = (event, { value }) => {
    setCondition({ ...condition, column_name: value });
  }

  const onColumnTypeChange = (event, { value }) => {
    const newCondtionState = { ...condition, type: value };
    if (EXPORT_COLUMN_TYPE.boolean === value) {
      setArgument({ ...argument, value: false });
      newCondtionState.operation = EXPORT_CONDITION_OPERATION_TYPE.match;
    } else {
      setArgument({ ...argument, value: '' });
    }
    setCondition(newCondtionState);
  }

  const onOperationChange = (event, { value }) => {
    setCondition({ ...condition, operation: value });
  }

  const onArgumentTypeChange = (event, { value }) => {
    setArgument({ type: value, value: '' });
  }

  const onArgumentValueChange = (event, { value }) => {
    setArgument({ ...argument, value });
  }

  const onBooleanArgumentChange = (event, { checked }) => {
    setArgument({ ...argument, value: checked });
  }

  const getConditionOperationOptions = () => {
    if (condition.type === EXPORT_COLUMN_TYPE.boolean) {
      return [
        { key: 0, value: EXPORT_CONDITION_OPERATION_TYPE.match, text: capitalize(EXPORT_CONDITION_OPERATION_TYPE.match) },
      ]
    }
    return [
      { key: 0, value: EXPORT_CONDITION_OPERATION_TYPE.match, text: EXPORT_CONDITION_OPERATION_TYPE_LABEL[EXPORT_CONDITION_OPERATION_TYPE.match] },
      { key: 1, value: EXPORT_CONDITION_OPERATION_TYPE.matchKeyValue, text: EXPORT_CONDITION_OPERATION_TYPE_LABEL[EXPORT_CONDITION_OPERATION_TYPE.matchKeyValue] },
      { key: 2, value: EXPORT_CONDITION_OPERATION_TYPE.includes, text: EXPORT_CONDITION_OPERATION_TYPE_LABEL[EXPORT_CONDITION_OPERATION_TYPE.includes] },
      { key: 3, value: EXPORT_CONDITION_OPERATION_TYPE.excludes, text: EXPORT_CONDITION_OPERATION_TYPE_LABEL[EXPORT_CONDITION_OPERATION_TYPE.excludes] },
      { key: 4, value: EXPORT_CONDITION_OPERATION_TYPE.includeIfKeyExists, text: EXPORT_CONDITION_OPERATION_TYPE_LABEL[EXPORT_CONDITION_OPERATION_TYPE.includeIfKeyExists] },
    ]
  }

  const getColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  const getColumnTypes = () => {
    return [
      { key: 0, value: EXPORT_COLUMN_TYPE.boolean, text: capitalize(EXPORT_COLUMN_TYPE.boolean) },
      { key: 1, value: EXPORT_COLUMN_TYPE.string, text: capitalize(EXPORT_COLUMN_TYPE.string) },
      { key: 2, value: EXPORT_COLUMN_TYPE.json, text: EXPORT_COLUMN_TYPE.json.toUpperCase() },
    ]
  }

  const onSubmit = () => {
    const { operation, ...rest } = condition;
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.number) {
      argument.value = +argument.value;
    }
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.string && argument.value !== EXPORT_ARGUMENT_VALUE_TYPE.string) {
      argument.value = argument.value.toString();
    }
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.json) {
      argument.value = JSON.parse(argument.value);
    }
    if (selectedReportCondition) {
      onSaveCondition({ operation: { name: operation, args: [argument.value] }, ...rest }, selectedReportCondition.id);
    } else {
      onSaveCondition({ operation: { name: operation, args: [argument.value] }, ...rest });
    }
  }

  return (
    <Modal dimmer='blurring' size='tiny' open={showModal}>
      <Modal.Header>New Condition</Modal.Header>
      <Modal.Content>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <span style={{ paddingRight: '10px' }}>Table Column:</span>
            <Dropdown
              search
              placeholder='Column Name'
              className='export-condition-dropdown'
              selection
              onChange={onColumnNameChange}
              value={condition.column_name}
              options={getColumns()} />
          </div>
          <div>
            <span style={{ paddingRight: '10px' }}>Column Type:</span>
            <Dropdown
              placeholder='Type'
              className='export-condition-small-dropdown'
              selection
              onChange={onColumnTypeChange}
              value={condition.type}
              options={getColumnTypes()} />
          </div>
          <div>
            <span style={{ paddingRight: '10px' }}>Operation:</span>
            <Dropdown
              placeholder='Operation'
              className='export-condition-small-dropdown'
              selection
              onChange={onOperationChange}
              value={condition.operation}
              options={getConditionOperationOptions()} />
          </div>
          {condition.type === EXPORT_COLUMN_TYPE.boolean ? (
            <Checkbox label='Value' toggle onChange={onBooleanArgumentChange} checked={argument.value} />
          ) : (
              <div>
                <span style={{ paddingRight: '10px' }}>Value Type:</span>
                <Dropdown
                  placeholder='Value Type'
                  className='export-operation-value-type-small-dropdown'
                  onChange={onArgumentTypeChange}
                  value={argument.type}
                  selection
                  options={[
                    { key: EXPORT_ARGUMENT_VALUE_TYPE.string, value: EXPORT_ARGUMENT_VALUE_TYPE.string, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.string) },
                    { key: EXPORT_ARGUMENT_VALUE_TYPE.number, value: EXPORT_ARGUMENT_VALUE_TYPE.number, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.number) },
                    { key: EXPORT_ARGUMENT_VALUE_TYPE.json, value: EXPORT_ARGUMENT_VALUE_TYPE.json, text: EXPORT_ARGUMENT_VALUE_TYPE.json.toUpperCase() }
                  ]} />
                <span style={{ paddingRight: '10px' }}>Value:</span>
                <Input type={argument.type === EXPORT_ARGUMENT_VALUE_TYPE.number ? EXPORT_ARGUMENT_VALUE_TYPE.number : EXPORT_ARGUMENT_VALUE_TYPE.string} placeholder='Value' className='export-operation-value-input' value={argument.value} onChange={onArgumentValueChange} />
              </div>
            )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Save' />
      </Modal.Actions>
    </Modal>
  )
}

export default ReportConditionModal;