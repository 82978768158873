import React from 'react';
import { Popup } from 'react-mapbox-gl';
import { connect } from 'react-redux';

const LayoutIconPopupRenderer = ({ layoutIconPopup }) => {

  const renderPopup = () => {
    let { overlayName, overlayType } = layoutIconPopup.properties;

    return (
      <Popup coordinates={layoutIconPopup.lngLat}>
        {overlayType && <><b>Type:</b> {overlayType || 'Unknown'}<br /></>}
        {overlayName && <><b>Name:</b> {overlayName || 'Unknown'}<br /></>}
      </Popup>
    )
  }

  return (
    <>
      {layoutIconPopup && renderPopup()}
    </>
  )
}

const mapStateToProps = ({ map }) => {
  return {
    layoutIconPopup: map.layoutIconPopup
  }
}

export default connect(mapStateToProps, null)(LayoutIconPopupRenderer);