import React from 'react';
import AppContext from '../core/context/app.context';
import { Segment } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import routeMaps from '../core/route-maps/route-maps';
import './styles.css';

const TenantRouteWrapper = (props) => (
  <AppContext.Consumer>{({ currentTenant }) => (
    <React.Fragment>
      {
        !currentTenant ? <Redirect to={routeMaps.administrationProjects} /> : (
          <Segment basic className='segment'>
            <div className='container'>
              {props.children}
            </div>
          </Segment>
        )
      }
    </React.Fragment>)}
  </AppContext.Consumer>
);

export default TenantRouteWrapper;