import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from './authRoute';
import NoAuthRoute from './noAuthRoute';
import { authRoutes, noAuthRoutes } from '../../route-maps';
import LoginRoute from '../../../routes/login';

const RoutesRenderer = () => (
	<Switch>
		{authRoutes.map(({ path, comp, ...rest }, i) => (
			<AuthRoute key={i} path={path} component={comp} {...rest} />
		))}
		{noAuthRoutes.map(({ path, comp, ...rest }, i) => (
			<NoAuthRoute key={i} path={path} component={comp} {...rest} />
		))}
		<Route component={LoginRoute} />
	</Switch>
);

export default RoutesRenderer;