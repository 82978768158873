import gql from 'graphql-tag';

export const GET_LATEST_VERSION_BY_TYPE_QUERY = gql`
  query GetLatestVersionByType {
    getLatestVersionByType(versionType: "Web") {
      id
      versionType
      versionName
      versionDescription
    }
  }
`;