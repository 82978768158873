import gql from 'graphql-tag';

export const CREATE_QUALIFICATION_MUTATION = gql`
  mutation CreateQualification($qualificationName: String!, $qualificationDescription: String!, $qualificationDefinition: String!, $tenantId: ID!) {
    createQualification(input: {
      qualificationName: $qualificationName,
      qualificationDescription: $qualificationDescription,
      qualificationDefinition: $qualificationDefinition,
      tenantId: $tenantId
    }) {
      id
    }
  }
`;

export const UPDATE_QUALIFICATION_MUTATION = gql`
  mutation UpdateQualification($qualificationId: ID!, $qualificationName: String, $qualificationDescription: String, $qualificationDefinition: String) {
    updateQualification(input: {
      qualificationId: $qualificationId,
      qualificationName: $qualificationName,
      qualificationDescription: $qualificationDescription,
      qualificationDefinition: $qualificationDefinition
    }) {
      id
    }
  }
`;

export const DELETE_QUALIFICATION_MUTATION = gql`
  mutation DeleteQualification($qualificationId: ID!) {
    deleteQualification(input: {
      qualificationId: $qualificationId
    }) {
      id
    }
  }
`;