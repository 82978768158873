import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import routeMaps from '../../route-maps';
import { isAuthenticated } from '../../../core/services/auth.service';
import SideMenu from '../../../shared/components/menu';
import AppContext from '../../context/app.context';
import ReactGA from 'react-ga';

class AuthRoute extends Component {

  static contextType = AppContext;
  
  componentDidMount = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(this.props.path);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('message', this.handleMessage);
    }
  }

  getRoute = (renderProps) => {
    const { component: Comp, location } = this.props;
    if (!isAuthenticated()) {
      return <Redirect to={{ pathname: `${routeMaps.login}`, state: { from: location } }} />;
    }
    return (
      <AppContext.Consumer>{({ showSideMenu }) => (
        <div className='app'>
          {showSideMenu && <SideMenu {...renderProps} />}
          <div className='app-main-content' style={showSideMenu ? { paddingLeft: '210px' } : {}}>
            <Comp {...renderProps} />
          </div>
        </div>
      )}
      </AppContext.Consumer>
    );
  };

  render() {
    const { component, uiState, ...rest } = this.props;
    return <Route {...rest} render={renderProps => this.getRoute(renderProps)} />;
  }
}

export default withRouter(AuthRoute);