import React, { Component } from 'react';
import TopMenu from '../../shared/components/top-menu';
import AppContext from '../../core/context/app.context';
import { LIST_WORKORDERS_BY_TYPE_AND_STATUS_QUERY } from '../../graphql/queries/workorder';
import QualityAssuranceInReview from './components/tab/in-review';
import QualityAssuranceEscalated from './components/tab/escalated';
import QualityAssuranceCompleted from './components/tab/completed';
import { convertUrlsToSignedUrls, updateWorkOrderStatus } from '../../core/services/workOrder.service';
import { ESCALATE_WORKORDER_MUTATION, REJECT_WORKORDER_MUTATION } from '../../graphql/mutations/workorder';
import { getUploadUrl, replacePhoto, addPhoto } from '../../core/services/photo.service';
import { useTimeZone } from '../../helpers/date';
import * as commentService from '../../core/services/comment.service';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import withApollo from '../../hoc/withApollo';
import { orderPhotosByType } from '../../helpers/workOrderPhoto';
import MODAL_TYPES from '../../constants/qaModalTypes';

class QualityAssuranceRoute extends Component {

  _isMounted = false;
  static contextType = AppContext;

  replaceFileInputRef = React.createRef();
  addFileInputRef = React.createRef();

  state = {
    inReviewActive: true,
    escalatedActive: false,
    completedActive: false,
    workOrdersWithStatusInReview: [],
    workOrdersWithStatusEscalated: [],
    workOrdersWithStatusCompleted: [],
    workOrdersVerified: [],
    error: false,
    loading: true,
    searchBy: '',
    inReviewLoading: true,
    escalatedLoading: true,
    completedLoading: true,
    photoType: null,
    showAddModal: false,
    uploadingNewPhoto: false,
    showCustomInputText: false,
    customPhotoType: '',
    showDemoModal: false
  };

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  async componentWillMount() {
    this._isMounted = true;
    await this.getWorkOrdersWithStatusInReview();
    this.getWorkOrdersWithStatusEscalated();
    this.getWorkOrdersWithStatusCompleted();
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getWorkOrdersByTypeAndStatus = async (queryBuilder, type, status) => {
    try {
      const { currentProject } = this.context;
      let workOrders = [];
      if (currentProject) {
        const { client } = this.props;
        let offset = null;
        do {
          let { data } = await client.query({
            query: queryBuilder(type, status),
            variables: { projectId: currentProject.id, offset, limit: 1000 },
            fetchPolicy: 'no-cache'
          });
          data = data.listWorkOrdersByWorkOrderTypeAndWorkOrderStatus.items;
          if (data.length > 0) {
            workOrders = [...workOrders, ...data];
            offset = data.length + (offset || 0);
          } else {
            offset = null;
          }
        } while (offset !== null);
      }
      return workOrders;
    } catch (error) {
      console.log(error);
      this.setState({ error: true });
    }
  }

  formatWorkOrderDetailsPhotos = async (workOrder) => {
    let workOrderCopy = Object.assign({}, workOrder);
    if (workOrderCopy.photos && workOrderCopy.photos.items && workOrderCopy.photos.items.length > 0) {
      workOrderCopy = orderPhotosByType(workOrderCopy);
      const urls = workOrderCopy.photos.items.map(({ file }) => {
        return `${file.bucket}/${file.key}`;
      });
      let signedUrls = await convertUrlsToSignedUrls(JSON.stringify(urls));
      signedUrls = signedUrls.map((url, index) => {
        return { original: url, thumbnail: url, photoName: workOrder.photos.items[index].file.key, bucket: workOrder.photos.items[index].file.bucket }
      });
      workOrderCopy.photos = signedUrls;
    } else {
      workOrderCopy.photos = [];
    }
    return workOrderCopy;
  }

  escalate = async (workOrderId, comment) => {
    await this.updateWorkOrderStatus({ query: ESCALATE_WORKORDER_MUTATION, workOrderId, comment });
  }

  reject = async (workOrderId, comment) => {
    await this.updateWorkOrderStatus({ query: REJECT_WORKORDER_MUTATION, workOrderId, comment });
  }

  addComment = async (workOrderId, comment) => {
    const { user } = this.context;
    const userId = user.id;
    await commentService.addComment(userId, workOrderId, comment);
  }

  getWorkOrdersWithStatusInReview = async () => {
    const { currentProject } = this.context;
    if (currentProject) {
      const { projectTimeZone = null, projectWorkOrderType: workOrderType } = currentProject;
      this.setState({ inReviewLoading: true });
      let workOrdersWithStatusInReview = await this.getWorkOrdersByTypeAndStatus(LIST_WORKORDERS_BY_TYPE_AND_STATUS_QUERY, workOrderType, 'InReview');
      workOrdersWithStatusInReview = useTimeZone(workOrdersWithStatusInReview, ['workOrderLastVisitDate'], projectTimeZone);
      this.setState({ workOrdersWithStatusInReview, inReviewLoading: false });
    }
  }

  getWorkOrdersWithStatusEscalated = async () => {
    const { currentProject } = this.context;
    if (currentProject) {
      const { projectTimeZone, projectWorkOrderType: workOrderType } = currentProject;
      this.setState({ escalatedLoading: true });
      let workOrdersWithStatusEscalated = await this.getWorkOrdersByTypeAndStatus(LIST_WORKORDERS_BY_TYPE_AND_STATUS_QUERY, workOrderType, 'Escalated');
      workOrdersWithStatusEscalated = useTimeZone(workOrdersWithStatusEscalated, ['workOrderLastVisitDate'], projectTimeZone);
      workOrdersWithStatusEscalated = workOrdersWithStatusEscalated.map(workOrder => {
        workOrder.isRework = false;
        if (workOrder.customDataFields) {
          workOrder.isRework = workOrder.customDataFields.findIndex(({ key }) => key === 'reworkReason') > -1;
        }
        return workOrder;
      });
      this.setState({ workOrdersWithStatusEscalated, escalatedLoading: false });
    }
  }

  getWorkOrdersWithStatusCompleted = async () => {
    const { currentProject } = this.context;
    if (currentProject) {
      const { projectTimeZone, projectWorkOrderType: workOrderType } = currentProject;
      this.setState({ completedLoading: true });
      let workOrdersWithStatusCompleted = await this.getWorkOrdersByTypeAndStatus(LIST_WORKORDERS_BY_TYPE_AND_STATUS_QUERY, workOrderType, 'Completed');
      workOrdersWithStatusCompleted = useTimeZone(workOrdersWithStatusCompleted, ['workOrderLastVisitDate'], projectTimeZone);
      this.setState({ workOrdersWithStatusCompleted, completedLoading: false });
    }
  }

  showInReview = () => {
    const { inReviewLoading } = this.state;
    if (!inReviewLoading) {
      this.setState({ inReviewActive: true, escalatedActive: false, completedActive: false, searchBy: '' });
    }
  }

  showEscalated = () => {
    const { escalatedLoading } = this.state;
    if (!escalatedLoading) {
      this.setState({ inReviewActive: false, escalatedActive: true, completedActive: false, searchBy: '' });
    }
  }

  showCompleted = () => {
    const { completedLoading } = this.state;
    if (!completedLoading) {
      this.setState({ inReviewActive: false, escalatedActive: false, completedActive: true, searchBy: '' });
    }
  }

  onSearch = searchBy => {
    this.setState({ searchBy });
  }

  updateWorkOrderStatus = async ({
    query,
    workOrderId,
    comment = null,
    closeWorkOrderUponApproval = false
  }) => {
    const { user } = this.context;
    const userId = user.id;
    await updateWorkOrderStatus({
      query,
      workOrderId,
      userId,
      comment,
      closeWorkOrderUponApproval
    });
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const {
      workOrdersWithStatusInReview,
      workOrdersWithStatusEscalated,
      workOrdersWithStatusCompleted,
      inReviewLoading,
      escalatedLoading,
      completedLoading
    } = this.state;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Meters` : 'Meters'}`,
        iconName: 'flag'
      },
      tabs: [
        {
          active: this.state.inReviewActive,
          onClick: this.showInReview,
          title: 'In Review',
          label: {
            color: 'blue',
            value: workOrdersWithStatusInReview.length
          },
          loader: {
            loading: inReviewLoading
          }
        },
        {
          active: this.state.escalatedActive,
          onClick: this.showEscalated,
          title: 'Escalated',
          label: {
            color: 'green',
            value: workOrdersWithStatusEscalated ? workOrdersWithStatusEscalated.length : 0
          },
          loader: {
            loading: escalatedLoading
          }
        },
        {
          active: this.state.completedActive,
          onClick: this.showCompleted,
          title: 'Completed',
          label: {
            color: 'yellow',
            value: workOrdersWithStatusCompleted.length
          },
          loader: {
            loading: completedLoading
          }
        },
        {
          onClick: () => {
            this.getWorkOrdersWithStatusInReview();
            this.getWorkOrdersWithStatusEscalated();
            this.getWorkOrdersWithStatusCompleted();
          },
          refresh: true
        }
      ]
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  filterWorkOrders = workOrders => {
    const { searchBy } = this.state;
    return workOrders
      .filter(workOrder => Object.values(workOrder).join(' ').toLowerCase().indexOf(searchBy) > -1);
  }

  replacePhoto = async (event, selectedWorkOrder, photoIndex) => {
    this.setState({ loadingWorkOrderDetails: true });
    const { currentProject } = this.context;
    const { photoName } = selectedWorkOrder.photos[photoIndex];
    const photo = event.target.files[0];
    if (photo) {
      const uploadUrl = await getUploadUrl(currentProject.id, photoName.split('/')[1]);
      await replacePhoto(uploadUrl, photo);
      this.replaceFileInputRef.current.value = '';
    }
  }

  showAddPhotoModal = () => {
    const { currentProject } = this.context;
    if (currentProject && currentProject.isDemo) {
      this.setState({ showDemoModal: true });
    } else {
      this.setState({ showAddModal: true });
    }
  }

  setShowDemoModal = (showDemoModal) => {
    this.setState({ showDemoModal });
  }

  addPhoto = async (event, workOrderId, oldMeterNumber) => {
    this.setState({ uploadingNewPhoto: true });
    const { currentProject } = this.context;
    const { photoType, customPhotoType } = this.state;
    const photo = event.target.files[0];
    if (photo) {
      const type = customPhotoType ? customPhotoType : photoType;
      await addPhoto(photo, workOrderId, oldMeterNumber, type, currentProject.id);
      this.closeAddPhotoModal();
      this.setState({ uploadingNewPhoto: false });
    }
  }

  onPhotoTypeChange = (event, data) => {
    this.setState({ photoType: data.value, showCustomInputText: data.value === 'Custom' });
  }

  onCustomTypeChange = (event, data) => {
    this.setState({ customPhotoType: data.value });
  }

  closeAddPhotoModal = () => {
    this.setState({ showAddModal: false, photoType: null, customPhotoType: '', showCustomInputText: false });
  }

  closeDemoModal = () => {
    this.setState({ showDemoModal: false });
  }

  onReplacePhotoClick = () => {
    const { currentProject } = this.context;
    if (currentProject.isDemo) {
      this.setShowDemoModal(true);
    } else {
      this.replaceFileInputRef.current.click();
    }
  }

  verifyWorkOrder = async (addWorkOrder) => {
    //console.log("verifyWorkOrder:"+addWorkOrder+":"+this.state.workOrdersVerified.length);
    // const verifiedWorkOrders = [];
    // this.state.workOrdersVerified.forEach((workOrder)=>{
    //   console.log("workOrder:"+workOrder);
    //   verifiedWorkOrders.push(workOrder);
    // });
    //console.log(JSON.stringify(verifiedWorkOrders));
    this.state.workOrdersVerified.push(addWorkOrder);
  }

  constructor(props) {
    super(props)

    this.verifyWorkOrder = this.verifyWorkOrder.bind(this)
  }

  render() {
    const {
      inReviewActive,
      workOrdersWithStatusInReview,
      workOrdersWithStatusEscalated,
      workOrdersWithStatusCompleted,
      workOrdersVerified,
      escalatedActive,
      completedActive,
      inReviewLoading,
      escalatedLoading,
      photoType,
      showAddModal,
      uploadingNewPhoto,
      showCustomInputText,
      customPhotoType,
      showDemoModal,
    } = this.state;
    const { user } = this.context;
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        {inReviewActive ? <QualityAssuranceInReview
          onReplacePhotoClick={this.onReplacePhotoClick}
          showDemoModal={showDemoModal}
          setShowDemoModal={this.setShowDemoModal}
          qualityAssuranceHeaderLabelColor='blue'
          qualityAssuranceType={MODAL_TYPES.IN_REVIEW}
          isQualityAssurance={true}
          customPhotoType={customPhotoType}
          onCustomTypeChange={this.onCustomTypeChange}
          showCustomInputText={showCustomInputText}
          addComment={this.addComment}
          getWorkOrdersWithStatusEscalated={this.getWorkOrdersWithStatusEscalated}
          getWorkOrdersWithStatusInReview={this.getWorkOrdersWithStatusInReview}
          getWorkOrdersWithStatusCompleted={this.getWorkOrdersWithStatusCompleted}
          workOrdersVerified={workOrdersVerified}
          verifyWorkOrder={this.verifyWorkOrder}
          updateWorkOrderStatus={this.updateWorkOrderStatus}
          workOrders={this.filterWorkOrders(workOrdersWithStatusInReview)}
          onSearch={this.onSearch}
          formatWorkOrderDetailsPhotos={this.formatWorkOrderDetailsPhotos}
          escalate={this.escalate}
          reject={this.reject}
          inReviewLoading={inReviewLoading}
          user={user}
          replacePhoto={this.replacePhoto}
          showAddPhotoModal={this.showAddPhotoModal}
          addPhoto={this.addPhoto}
          onPhotoTypeChange={this.onPhotoTypeChange}
          closeAddPhotoModal={this.closeAddPhotoModal}
          replaceFileInputRef={this.replaceFileInputRef}
          addFileInputRef={this.addFileInputRef}
          showAddModal={showAddModal}
          uploadingNewPhoto={uploadingNewPhoto}
          photoType={photoType} /> : null}
        {escalatedActive ? <QualityAssuranceEscalated
          onReplacePhotoClick={this.onReplacePhotoClick}
          showDemoModal={showDemoModal}
          setShowDemoModal={this.setShowDemoModal}
          qualityAssuranceHeaderLabelColor='green'
          qualityAssuranceType={MODAL_TYPES.ESCALATED}
          isQualityAssurance={true}
          customPhotoType={customPhotoType}
          onCustomTypeChange={this.onCustomTypeChange}
          showCustomInputText={showCustomInputText}
          addComment={this.addComment}
          getWorkOrdersWithStatusInReview={this.getWorkOrdersWithStatusInReview}
          getWorkOrdersWithStatusCompleted={this.getWorkOrdersWithStatusCompleted}
          getWorkOrdersWithStatusEscalated={this.getWorkOrdersWithStatusEscalated}
          updateWorkOrderStatus={this.updateWorkOrderStatus}
          workOrders={workOrdersWithStatusEscalated}
          onSearch={this.onSearch}
          formatWorkOrderDetailsPhotos={this.formatWorkOrderDetailsPhotos}
          reject={this.reject}
          escalatedLoading={escalatedLoading}
          replaceFileInputRef={this.replaceFileInputRef}
          addFileInputRef={this.addFileInputRef}
          showAddModal={showAddModal}
          uploadingNewPhoto={uploadingNewPhoto}
          photoType={photoType}
          replacePhoto={this.replacePhoto}
          showAddPhotoModal={this.showAddPhotoModal}
          addPhoto={this.addPhoto}
          onPhotoTypeChange={this.onPhotoTypeChange}
          closeAddPhotoModal={this.closeAddPhotoModal}
          user={user} /> : null}
        {completedActive ? <QualityAssuranceCompleted
          showDemoModal={showDemoModal}
          setShowDemoModal={this.setShowDemoModal}
          qualityAssuranceHeaderLabelColor='yellow'
          qualityAssuranceType={MODAL_TYPES.COMPLETED}
          isQualityAssurance={true}
          reject={this.reject}
          addComment={this.addComment}
          getWorkOrdersWithStatusCompleted={this.getWorkOrdersWithStatusCompleted}
          getWorkOrdersWithStatusEscalated={this.getWorkOrdersWithStatusEscalated}
          updateWorkOrderStatus={this.updateWorkOrderStatus}
          workOrders={this.filterWorkOrders(workOrdersWithStatusCompleted)}
          onSearch={this.onSearch}
          formatWorkOrderDetailsPhotos={this.formatWorkOrderDetailsPhotos}
          escalate={this.escalate}
          user={user} /> : null}
      </ProjectRouteWrapper>
    );
  }
}

export default withApollo(QualityAssuranceRoute);