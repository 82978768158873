import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

const Search = ({ searchRef, onSearch }) => (
  <div style={{ display: 'flex' }}>
    <div className='field ui input' style={{ paddingRight: '5px', minWidth: '450px' }}>
      <Popup
        content='single or range x:y'
        style={{ fontSize: '12px'}}
        trigger={
          <input
            ref={searchRef}
            type='text'
            placeholder='Search Customer Asset #, Mfg Serial #, Line/Order #'
            style={{ width: '400px', paddingRight: '5px' }}
          />
        }
      />
    </div>
    <Button primary onClick={onSearch}>Search</Button>
  </div>
)

export default Search;