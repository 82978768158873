import gql from 'graphql-tag';

export const GET_SNAPSHOT_HISTORY_BY_PROJECT_ID = gql`
  query getSnapshotHistoryByProjectId($projectId: ID!) {
    getSnapshotHistoryByProjectId(project: $projectId) {
      snapshot {
        id
        user {
          userName
        }
        createdDate
      }
      lastCreationFailed
      lastRestoreFailed
      snapshotInProgress {
        id
        status
      }
      lastRestoreCompleted {
        user {
          userName
        }
        createdDate
      }
    }
  }`;