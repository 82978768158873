import client from '../apollo';
import { LIST_PROJECT_REPORT_PREFERENCES_QUERY } from '../../graphql/queries/projectReportPreferences';
import { CREATE_PROJECT_REPORT_PREFERENCES_MUTATION, UPDATE_PROJECT_REPORT_PREFERENCES_MUTATION } from '../../graphql/mutations/projectReportPreferences';

export const listProjectReportPreferences = async (projectId, reportType) => {
  try {
    const { data } = await client.query({
      query: LIST_PROJECT_REPORT_PREFERENCES_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        projectId,
        reportType
      }
    });
    return data && data.listProjectReportPreferences ? data.listProjectReportPreferences.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const updateProjectReportPreferences = async (projectReportPreferencesId, reportConfiguration) => {
  try {
    await client.mutate({
      mutation: UPDATE_PROJECT_REPORT_PREFERENCES_MUTATION,
      variables: {
        projectReportPreferencesId,
        reportConfiguration
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const createProjectReportPreferences = async (projectReportPreferences) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_PROJECT_REPORT_PREFERENCES_MUTATION,
      variables: {
        ...projectReportPreferences
      }
    });
    return data && data.createProjectReportPreferences ? data.createProjectReportPreferences.id : null;
  } catch (error) {
    throw error;
  }
}