import React, { Component, useContext } from 'react';
import AppContext from '../../../core/context/app.context';
import routeMaps from '../../../core/route-maps';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import config from '../../../config';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import TopMenu from '../../../shared/components/top-menu';
import WORKORDER_PERSON_POSITION_ICON from '../../../assets/icons/pins/woPersonPosition.png';
import PopupRenderer from './popup';
import { useDispatch } from 'react-redux';
import { setPopup } from '../../../core/redux/actions/administrationOverview.actions';
import Loader from '../../../shared/components/loader';
import mapboxgl from 'mapbox-gl';

const workOrderPersonPositionIcon = new Image();
workOrderPersonPositionIcon.src = WORKORDER_PERSON_POSITION_ICON;

const mapRef = ReactMapboxGl({ accessToken: config.mapbox.accessToken });

const MapRenderer = React.memo(({ component: MapComponent, technicians, projects, history }) => {
  const dispatch = useDispatch();
  const context = useContext(AppContext);

  const onShowPopup = async (event) => {
    const { features, lngLat } = event;
    const properties = features[0].properties;
    dispatch(setPopup({ lngLat, properties }));
  }

  const onHidePopup = () => {
    dispatch(setPopup(null));
  }

  const adjustProjectsWithSameLocation = (projects) => {
    const projectsByLatitudeAndLongitude = {};
    return projects.map(project => {
      const key = `${project.latitude},${project.longitude}`;
      if (projectsByLatitudeAndLongitude[key] !== undefined) {
        projectsByLatitudeAndLongitude[key] = projectsByLatitudeAndLongitude[key] + 1;
        if (projectsByLatitudeAndLongitude[key] > 0) {
          project.hideTitleOnLargeZoomLevel = true;
        }
        if (projectsByLatitudeAndLongitude[key] === 1) {
          project.latitude += 0.01000;
          project.longitude += 0.01000;
        } else if (projectsByLatitudeAndLongitude[key] === 2) {
          project.latitude -= 0.01000;
          project.longitude += 0.01000;
        } else if (projectsByLatitudeAndLongitude[key] === 3) {
          project.latitude += 0.01000;
          project.longitude -= 0.01000;
        } else if (projectsByLatitudeAndLongitude[key] === 4) {
          project.latitude -= 0.01000;
          project.longitude -= 0.01000;
        }
      } else {
        projectsByLatitudeAndLongitude[key] = 0
      }
      return project;
    });
  }

  const onMapLoad = (map) => {
    map.addControl(new mapboxgl.FullscreenControl());
    const scale = new mapboxgl.ScaleControl({
      maxWidth: 200,
      unit: 'imperial'
    });
    map.addControl(scale);
  }

  const onMouseEnter = (mapWithEvt) => {
    mapWithEvt.map.getCanvas().style.cursor = 'pointer';
  }

  const onMouseLeave = (mapWithEvt) => {
    mapWithEvt.map.getCanvas().style.cursor = '';
  }

  const onClick = (mapWithEvt, { projectId }) => {
    const { tenantProjects, setProject, setAdministrationOverviewLoading } = context;
    setAdministrationOverviewLoading(true);
    const project = tenantProjects.find(i => i.id === projectId);
    if (project.projectConfiguration && typeof (project.projectConfiguration) === 'string') {
      project.projectConfiguration = JSON.parse(project.projectConfiguration);
    }
    if (project.projectMobileTemplates && typeof (project.projectMobileTemplates) === 'string') {
      project.projectMobileTemplates = JSON.parse(project.projectMobileTemplates);
    }
    if (project.projectImportConfig && typeof (project.projectImportConfig) === 'string') {
      project.projectImportConfig = JSON.parse(project.projectImportConfig);
    }
    setProject(project);
    setTimeout(() => history.push(routeMaps.dashboard), 0);
  }

  const adjustedProjects = adjustProjectsWithSameLocation(projects);

  return (
    <>
      {MapComponent && <MapComponent
        onStyleLoad={onMapLoad}
        style={config.mapbox.style}
        center={[-96.2074085, 38.6177717]}
        zoom={[4.2]}
        containerStyle={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
        }}
      >
        <PopupRenderer />
        {projects && projects.length > 0 && <Layer type='circle' id='project' paint={{
          'circle-opacity': 0.6,
          'circle-color': [
            'step',
            ['get', 'count'],
            '#577590',
            5000,
            '#43AA8B',
            50000,
            '#90BE6D',
            100000,
            '#F3722C',
            150000,
            '#F8961E',
            250000,
            '#F9C74F'
          ],
          'circle-radius': [
            'step',
            ['get', 'count'],
            10,
            5000,
            20,
            50000,
            30,
            100000,
            40,
            150000,
            50,
            250000,
            60
          ]
        }}>
          {adjustedProjects.map((i, index) => <Feature key={index} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onClick={(mapWithEvt) => onClick(mapWithEvt, i)} coordinates={[i.longitude, i.latitude]} properties={{ ...i }} />)}
        </Layer>}

        {projects && projects.length > 0 && <Layer type='symbol' id='main-project-count'
          maxZoom={10} layout={{
            'text-field': '{projectShortCode}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16,
            'text-allow-overlap': true
          }}>
          {adjustedProjects.filter(i => !i.hideTitleOnLargeZoomLevel).map((i, index) => <Feature key={index} coordinates={[i.longitude, i.latitude]} properties={{ ...i }} />)}
        </Layer>}

        {projects && projects.length > 0 && <Layer type='symbol' id='main-project-count-min'
          minZoom={10} layout={{
            'text-field': '{projectShortCode} \n {count}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16,
            'text-allow-overlap': true
          }}>
          {adjustedProjects.filter(i => !i.hideTitleOnLargeZoomLevel).map((i, index) => <Feature key={index} coordinates={[i.longitude, i.latitude]} properties={{ ...i }} />)}
        </Layer>}

        {projects && projects.length > 0 && <Layer type='symbol' id='sub-project-count-min'
          minZoom={10} layout={{
            'text-field': '{projectShortCode} \n {count}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16,
            'text-allow-overlap': true
          }}>
          {adjustedProjects.filter(i => i.hideTitleOnLargeZoomLevel).map((i, index) => <Feature key={index} coordinates={[i.longitude, i.latitude]} properties={{ ...i }} />)}
        </Layer>}

        {technicians && technicians.length > 0 && <Layer
          type='symbol'
          id='technicians-with-install'
          filter={['case', ['==', ['get', 'install'], true], true, false]}
          layout={{
            'icon-image': 'WORKORDER_PERSON_POSITION_ICON',
            'icon-allow-overlap': true
          }}
          onMouseLeave={onHidePopup}
          onMouseEnter={(event) => onShowPopup(event)}
          images={['WORKORDER_PERSON_POSITION_ICON', workOrderPersonPositionIcon]}>
          {technicians.map((i, index) => <Feature key={index} coordinates={[i.longitude, i.latitude]} properties={{ ...i }} />)}
        </Layer>}

        {technicians && technicians.length > 0 && <Layer
          type='circle'
          id='technicians-without-install'
          onMouseLeave={onHidePopup}
          onMouseEnter={(event) => onShowPopup(event)}
          filter={['case', ['==', ['get', 'install'], false], true, false]}
          paint={{
            'circle-color': '#C84F0A',
            'circle-stroke-color': '#ffffff',
            'circle-stroke-width': 1,
            'circle-stroke-opacity': .5,
          }}
        >
          {technicians.map((i, index) => <Feature key={index} coordinates={[i.longitude, i.latitude]} properties={{ ...i }} />)}
        </Layer>}
      </MapComponent>}
    </>
  )
});

class AdministrationOverviewRoute extends Component {

  static contextType = AppContext;
  _isMounted = false;

  setState = (state) => {
    if (this._isMounted) {
      super.setState(state);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentTenant, setAdministrationOverviewLoading, initAdministrationOverview } = this.context;
    setAdministrationOverviewLoading(true);
    const { history } = this.props;
    if (!currentTenant) {
      history.push(routeMaps.administrationProjects);
    } else {
      await initAdministrationOverview(currentTenant);
    }
  }

  onRefresh = async () => {
    const { currentTenant, initAdministrationOverview } = this.context;
    initAdministrationOverview(currentTenant);
  }

  renderTopMenu = () => {
    const { currentTenant } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentTenant && currentTenant.tenantName ? `${currentTenant.tenantName} - Map Overview` : 'Map Overview'}`,
        iconName: 'flag'
      },
      tabs: [
        {
          refresh: true,
          onClick: this.onRefresh
        }
      ]
    };
    return <TopMenu rootStyle={{ display: 'flex', margin: '1em 1em', zIndex: 1 }} config={topMenuConfig} />;
  }

  renderMap = () => {
    const { administrationOverviewTechnicians: technicians, administrationOverviewProjects: projects } = this.context;
    const { history } = this.props;
    return (
      <MapRenderer
        history={history}
        hidePopup={this.hidePopup}
        setPopup={this.setPopup}
        component={mapRef}
        technicians={technicians}
        projects={projects} />
    )
  }

  render() {
    const { administrationOverviewLoading: loading } = this.context;
    return (
      <div className='container'>
        {this.renderTopMenu()}
        {this.renderMap()}
        <Loader loading={loading} />
      </div>
    )
  }
}

export default AdministrationOverviewRoute;


