import React from 'react';
import { Menu, Header, Icon } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';
import ROLES from '../../../../constants/roles';
import { isEnabled } from '../../../../core/services/auth.service';


const TopMenu = ({ config, style = {}, onSettingsClick, plan, user }) => (
  <div style={{ display: 'flex', marginBottom: '14px', zIndex: 1 }}>
    <Menu stackable style={{ ...style, flex: '1' }}>
      <Menu.Item>
        <AppContext.Consumer>{({ toggleSideMenu }) => (
          <Icon name='list' onClick={toggleSideMenu} style={{ cursor: 'pointer', fontSize: '23px' }} />
        )}</AppContext.Consumer>
      </Menu.Item>
      <Menu.Item>
        <Header><Icon name={config.header.iconName} />{config.header.title}</Header>
      </Menu.Item>
      {plan && config.tabs.map((tab, key) => (
        <Menu.Item
          key={key}
          active={tab.active}
          onClick={tab.onClick}
        >
          {tab.iconName ? <Icon name={tab.iconName} /> : null}
          {tab.title}
        </Menu.Item>
      ))}
      {plan && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]) && <Menu.Menu position='right'>
        <Menu.Item
          active={false}
          onClick={onSettingsClick}
        >
          <Icon style={{ margin: 0, marginRight: '5px' }} name='settings' />
          Settings
        </Menu.Item>
      </Menu.Menu>}
    </Menu>
  </div>
)

export default TopMenu;