import React, { useEffect } from 'react';
import {
  SchedulerEditItem,
} from '@progress/kendo-react-scheduler';

export const EditItemWithDynamicTitle = (props) => {
  useEffect(() => {
    const shiftClickEvent = new CustomEvent("appointmentShiftClick", { detail: props.dataItem });
    const clickEvent = new CustomEvent("appointmentSingleClick", { detail: props.dataItem });
    const dblClickEvent = new CustomEvent("appointmentDoubleClick", { detail: props.dataItem });
    const deleteClickEvent = new CustomEvent("deleteClick", { detail: props.dataItem });
    const appointmentSlot = document.getElementById(props.dataItem.id);
    const deleteButton = document.getElementById(props.dataItem.id).childNodes[2].childNodes[0];
    if(props.editable === true) {
      appointmentSlot.addEventListener('click', (e) => {
        if(e.metaKey) document.dispatchEvent(shiftClickEvent);
        if(!e.metaKey) document.dispatchEvent(clickEvent);
      });
      appointmentSlot.addEventListener('dblclick', (e) => {
        document.dispatchEvent(dblClickEvent);
      });
      deleteButton.addEventListener('click', (e) => {
        // delete button is an <a> element, when you click it it causes the flashing issue
        e.preventDefault();
        document.dispatchEvent(deleteClickEvent);
      });
    }
  }, []);

  return (
    <SchedulerEditItem
      {...props}
      id={props.dataItem.id}
      className={`${setClass(props.dataItem)}${props.isSelected ? ' custom-selected': ''}`}
      title={generateTitle(props.dataItem)}
      resizeItem={false}
      onDragAction={null} // Drag is causing issues with mistaken updates on appointments
      resize={null}
      onResizeItemChange={null}
    />
  );
};

function nameToClass(name) {
  var className = ['assigned1', 'assigned2', 'assigned3', 'assigned4', 'assigned5', 'assigned6', 'assigned7', 'assigned8', 'assigned9', 'assigned10'];
  var hash = hashStr(name);
  var index = hash % className.length;
  return className[index];
}

function hashStr(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    var charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
}

const generateTitle = (dataItem) => {
  let title = `${dataItem.workOrderNumber}`;
  if (dataItem.userIds.length > 0) {
    title = title + ` (${dataItem.userIds.map(u => u.fullName).join(' | ')})`;
  }
  if (dataItem.appointmentNotes) {
    title = title + ` - ${dataItem.appointmentNotes}`;
  }
  return title;
};

const setClass = (dataItem) => {
  const { workOrderStatus, appointmentEndDate } = dataItem
  const pastAppointment = new Date(appointmentEndDate) < new Date();
  if (pastAppointment && (workOrderStatus === 'Open' || workOrderStatus === 'Assigned' || workOrderStatus === 'InProgress')) {
    return 'needs-attention';
  } else if(workOrderStatus === 'Closed' || workOrderStatus === 'InReview' || workOrderStatus === 'Escalated' || workOrderStatus === 'Completed'){
    return 'greyed-out'
  } else if(dataItem.userIds.length < 1){
    return 'unAssigned';
  } else {
    var ids = JSON.stringify(dataItem.userIds.map(u => u.fullName).join(''));
    return nameToClass(ids);
  }
};