import React from 'react';
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem
} from '@progress/kendo-react-charts';

const BarChart = ({ data }) => (
  <Chart>
    <ChartTitle text="Workflow Progress" />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={data.categories} />
    </ChartCategoryAxis>
    <ChartSeries>
      <ChartSeriesItem type="bar" stack={{ type: '100%' }} data={[data.done]} />
      <ChartSeriesItem type="bar" data={[data.total - data.done]} />
    </ChartSeries>
  </Chart>
);

function arePropsEqual(oldProps, newProps) {
  return JSON.stringify(oldProps) === JSON.stringify(newProps);
}

export default React.memo(BarChart, arePropsEqual);