import React from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';

const handleKeyPress = (event, onModalConfirm) => {
  if (event.charCode === 13) {
    onModalConfirm(event);
  }
}

const SaveWorkOrdersGroup = ({ saveWorkOrdersGroup, workOrderGroups, onWorkOrderGroupNameChange }) => (
  <div onKeyPress={(e) => handleKeyPress(e, saveWorkOrdersGroup)}>
    <Form id='save-group-form' onSubmit={saveWorkOrdersGroup}>
      <Form.Field>
        <Form.Input autoFocus required list='groupNames' placeholder='Group Name' onChange={onWorkOrderGroupNameChange} />
        <datalist id='groupNames'>
          {workOrderGroups.map((group, key) => <option key={key} value={group} />)}
        </datalist>
      </Form.Field>
    </Form>
  </div>
)

const WorkOrdersMapOptionsModal = ({
  showModal,
  closeModal,
  selectedWorkOrders,
  loading,
  saveWorkOrdersGroup,
  workOrderGroups,
  onWorkOrderGroupNameChange
}) => (
    <Modal dimmer='blurring' size='tiny' onClose={closeModal} open={showModal}>
      <Modal.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label>Save Group</label>
          <label style={{ fontWeight: 'normal', fontSize: '18px' }}>Selected: {selectedWorkOrders.length}</label>
        </div>
      </Modal.Header>
      <Modal.Content>
        <SaveWorkOrdersGroup
          onWorkOrderGroupNameChange={onWorkOrderGroupNameChange}
          workOrderGroups={workOrderGroups}
          saveWorkOrdersGroup={saveWorkOrdersGroup} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative disabled={loading}>Cancel</Button>
        <button type='submit' form='save-group-form' className={`ui positive button${loading ? ' loading' : ''}`}>Save</button>
      </Modal.Actions>
    </Modal>
  )

export default WorkOrdersMapOptionsModal;