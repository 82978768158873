import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as schedulerActions from '../../../../core/redux/actions/scheduler.actions';

const OverrideAppointmentDialog = ({
  setShowAppointmentAlreadyExistModal,
  createAppointment,
  setNewAppointment,
}) => {
  const onCancel = () => {
    setShowAppointmentAlreadyExistModal(false);
    setNewAppointment(null);
  }

  return (
    <Dialog title={'Appointment Exists'} onClose={() => setShowAppointmentAlreadyExistModal(false)}>
      <span>Do you want to replace the existing appointment with this one?</span>
      <DialogActionsBar>
        <button className='k-button' onClick={createAppointment}>
          Replace
        </button>
        <button className='k-button' onClick={onCancel}>
          Cancel
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowAppointmentAlreadyExistModal: (showModal) => dispatch(schedulerActions.setShowAppointmentAlreadyExistModal(showModal)),
    setNewAppointment: (newAppointment) => dispatch(schedulerActions.setNewAppointment(newAppointment)),
    createAppointment: () => dispatch(schedulerActions.createAppointment()),
  }
}

export default connect(null, mapDispatchToProps)(OverrideAppointmentDialog);