import React from 'react';
import Header from './header';
import { Label } from 'semantic-ui-react';
import { MODAL_TYPES_LABELS } from '../../../constants/qaModalTypes';

const QualityAssuranceHeader = ({ currentProject, selectedWorkOrder, labelColor, type, workOrders }) => (
  <div style={{ display: 'flex' }}>
    <div>
      <Header currentProject={currentProject} selectedWorkOrder={selectedWorkOrder} isQA={true} />
    </div>
    <div style={{ flex: 1, textAlign: 'right', marginRight: '25px' }}>
      {MODAL_TYPES_LABELS[type]} <Label color={labelColor}>{workOrders.length}</Label>
    </div>
  </div>
)

export default QualityAssuranceHeader;