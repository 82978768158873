import client from '../apollo';
import { CREATE_PLAN_MUTATION, UPDATE_PLAN_MUTATION, UPDATE_PLAN_WEEK_MUTATION, UPDATE_PLAN_WEEK_ACTUAL_RATE_MUTATION } from '../../graphql/mutations/plan';
import { GET_ACTIVE_PLAN_QUERY } from '../../graphql/queries/plan';

export const updatePlanWeekActualRate = async (planId, project) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PLAN_WEEK_ACTUAL_RATE_MUTATION,
      variables: {
        project,
        planId,
      }
    });
    return data && data.updatePlanWeekActualRate ? data.updatePlanWeekActualRate : null;
  } catch (error) {
    console.log(error);
  }
}

export const createPlan = async (project, { startDate, endDate, weeklyCapacity, workingDays }) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_PLAN_MUTATION,
      variables: {
        project,
        startDate,
        endDate,
        weeklyCapacity,
        workingDays
      }
    });
    return data && data.createPlan ? data.createPlan : null;
  } catch (error) {
    console.log(error);
  }
}

export const updatePlan = async (plan) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PLAN_MUTATION,
      variables: {
        ...plan
      }
    });
    return data && data.updatePlan ? data.updatePlan : null;
  } catch (error) {
    console.log(error);
  }
}

export const getActivePlan = async (project) => {
  try {
    const { data } = await client.query({
      query: GET_ACTIVE_PLAN_QUERY,
      variables: {
        project
      },
      fetchPolicy: 'no-cache'
    });
    return data && data.getActivePlan ? data.getActivePlan : null;
  } catch (error) {
    console.log(error);
  }
}

export const updatePlanWeek = async (planWeekUpdateList) => {
  try {
    await client.mutate({
      mutation: UPDATE_PLAN_WEEK_MUTATION,
      variables: {
        planWeekUpdateList
      }
    });
  } catch (error) {
    console.log(error);
  }
}