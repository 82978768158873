import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Label, Icon } from 'semantic-ui-react';
import PhotoNotAvailable from '../../../assets/PhotoNotAvailable.png';
import './styles.css';

const defaultWorkOrderImage = [
  {
    original: PhotoNotAvailable,
    thumbnail: PhotoNotAvailable
  }
];

const ReviewGallery = ({ onSlide, workOrder, photoIndex }) => {

  const getPhotoType = () => {
    if (workOrder && workOrder.photos && workOrder.photos.length > 0 && workOrder.photos[photoIndex].photoName !== undefined) {
      return workOrder.photos[photoIndex].photoName
        .split('-')[workOrder.photos[photoIndex].photoName.split('-').length - 1]
        .split('.')[0]
    }
    return '';
  }

  return (
    <ImageGallery
      renderItem={({ original }) =>
        <React.Fragment>
          <Label className='label' as='a' attached='top right'><Icon name='camera' />{getPhotoType()}</Label>
          <img id='gallery-image' src={original} alt='workorder' />
        </React.Fragment>}
      items={workOrder && workOrder.photos && workOrder.photos.length > 0 ? workOrder.photos : defaultWorkOrderImage}
      showPlayButton={false}
      thumbnailPosition='right'
      onSlide={onSlide}
    />
  )
}

export default ReviewGallery;

