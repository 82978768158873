import React, { useEffect, useState, useCallback } from 'react';
import { Form } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { connect } from 'react-redux';

import CustomFormEditor from './custom-form-editor';

import { getWorkOrderByOldMeterNumber } from '../../../../core/services/workOrder.service';
import { getAppointmentByWorkOrder } from '../../../../core/services/appointment.service';
import * as schedulerActions from '../../../../core/redux/actions/scheduler.actions';
import AppContext from '../../../../core/context/app.context';



const formStateInitial = {
    appointmentStartDate: '',
    appointmentEndDate: '',
    workOrderNumber: '',
    userIds: '',
    appointmentSequence: '',
    appointmentNotes: '',
    id: '',
    workOrderId: '',
}

const CustomSchedulerForm = (props) => {
    const [formState, setFormState] = useState({...formStateInitial, ...props.selectedWorkOrder});
    const [validForm, setValidForm] = useState(false);
    const requiredValidator = useCallback(value => value === undefined || value === null || value === '' || value.length === 0 ? 'Field is required.' : undefined, []);
    const context = React.useContext(AppContext);
    const formType = props.selectedWorkOrder.id ? 'update' : 'create';
    const dialogTitle = formType === 'update' ? `Appointment for WO: ${props.selectedWorkOrder.workOrderNumber}` : 'Create New Appointment';

    useEffect(() => {
        if(formState.appointmentNotes.length <= 0 || formState.workOrderNumber.length <= 0){
            setValidForm(false)
        } else {
            setValidForm(true)
        }
    }, [formState])

    useEffect(() => {
        setFormState(prev => ({
            ...prev,
            // convert times to browsers timezone for UI purposes
            appointmentStartDate: transferTimeZones(formState.appointmentStartDate, false),
            appointmentEndDate: transferTimeZones(formState.appointmentEndDate, false)
        }))
    }, [props.selectedWorkOrder]);

    useEffect(() => {
        return () => {
            props.setWorkOrderNotFoundError(false);
        }
    }, [props.selectedWorkOrder]);

    const transferTimeZones = (date, toProjectTimeZone) => {
        // used to swap between browser timezone (for UI purposes)
        // and then back to project timezone
        if(!date) return '';
        const toBrowserTimeZone = new Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZone: context.currentProject.projectTimeZone,
            hour12: false,
        }).format(date);
        const TIMEZONE = new Intl.DateTimeFormat('en-US', {
            timeZoneName: "shortOffset",
            timeZone: context.currentProject.projectTimeZone,
            hour12: false,
        }).formatToParts(date)[6].value;
        const projectTimeZone = new Date(`${date.toLocaleString()} ${TIMEZONE}`);
        const finalDate = toProjectTimeZone ? projectTimeZone : new Date(toBrowserTimeZone);
        return finalDate
    }

    const formValidator = (_dataItem, formValueGetter) => {
        let result = {};
        result.workOrderNumber = [requiredValidator(formState.workOrderNumber)].filter(Boolean).reduce((current, acc) => current || acc, '');
        result.appointmentNotes = [requiredValidator(formState.appointmentNotes)].filter(Boolean).reduce((current, acc) => current || acc, '');
        return result;
    };

    const handleSubmit = async (event) => {
        const { setWorkOrderNotFoundError, setValidating, setValidationMessage } = props;
        if(formState.workOrderNumber.length <= 0 || formState.appointmentNotes.length <= 0) return;
        setValidating(true);
        setValidationMessage(null);
        setWorkOrderNotFoundError(false);
        const appointmentId = props.selectedWorkOrder.id;
        const { currentProject, user } = context;
        let workOrder;
        let oldAppointment;
        if (!appointmentId || appointmentId.length <= 0 || formState.workOrderNumber) {
            // Check if workorder exist when creating a new appointment
            workOrder = await getWorkOrderByOldMeterNumber(currentProject.id, currentProject.projectWorkOrderType, formState.workOrderNumber);
            if (!workOrder) {
                setValidating(false);
                setWorkOrderNotFoundError(true);
            return;
            }
            oldAppointment = await getAppointmentByWorkOrder(currentProject.id, workOrder.id);
        }
        const newAppointment = {
            ...formState,
            appointmentStartDate: transferTimeZones(formState.appointmentStartDate, true),
            appointmentEndDate: transferTimeZones(formState.appointmentEndDate, true),
            schedulerUserId: user.id,
            userIds: formState.userIds ? formState.userIds.map(a => a.id) : '',
            projectId: currentProject.id,
            timeZone: currentProject.projectTimeZone,
        }
        delete newAppointment['isAllDay'];
        delete newAppointment['workOrderNumber'];
        setValidating(false);
        if (oldAppointment) {
            newAppointment.workOrderId = workOrder ? workOrder.id : props.selectedWorkOrder.workOrderId;
            newAppointment.override = true;
        } else if (workOrder) {
            newAppointment.workOrderId = workOrder.id;
        }
        props.setNewAppointment(newAppointment);
        props.onSubmit(newAppointment, formType);
    }

    const handleCancel = (event) => {
        const { setValidating, setValidationMessage } = props;
        setValidationMessage(null);
        setValidating(false);
        props.setSelectedAppointments([]);
        props.onClose(event);
    }

    return (
        <Dialog minWidth={'560px'} title={dialogTitle} onClose={handleCancel}>
            <Form
                {...props}
                validator={formValidator}
                onSubmit={handleSubmit}
                render={(rest) => (<>
                    <CustomFormEditor
                        {...rest}
                        openSharedAddressModal={props.openSharedAddressModal}
                        filterDuplicateAddresses={props.filterDuplicateAddresses}
                        selectedWorkOrder={props.selectedWorkOrder}
                        appointmentId={props.selectedWorkOrder.id}
                        formState={formState}
                        setFormState={setFormState}
                    />
                    <DialogActionsBar>
                        <Button disabled={!validForm} onClick={handleSubmit}>SUBMIT</Button>
                        <Button onClick={handleCancel}>CANCEL</Button>
                    </DialogActionsBar>
                </>)}
            />
        </Dialog>
    );
};





const mapDispatchToProps = (dispatch) => {
    return {
        setWorkOrderNotFoundError: (workOrderNotFoundError) => dispatch(schedulerActions.setWorkOrderNotFoundError(workOrderNotFoundError)),
        setShowAppointmentAlreadyExistModal: (showModal) => dispatch(schedulerActions.setShowAppointmentAlreadyExistModal(showModal)),
        setNewAppointment: (newAppointment) => dispatch(schedulerActions.setNewAppointment(newAppointment)),
        setValidating: (validating) => dispatch(schedulerActions.setValidating(validating)),
        setValidationMessage: (validationMessage) => dispatch(schedulerActions.setValidationMessage(validationMessage)),
    }
}

export default connect(null, mapDispatchToProps)(CustomSchedulerForm);