import gql from 'graphql-tag';

export const ADMIN_CREATE_WORKORDER_MUTATION = gql`
  mutation AdminCreateWorkOrder(
    $locationId: String! ,
    $workOrderNumber: String! ,
    $routeCode: String! ,
    $billingCycleCode: String! ,
    $workOrderType: WorkOrderType!,
    $workOrderFlaggedAsAdHoc: Boolean!,
    $accountNumber: String,
    $street: String!,
    $city: String!,
    $stateCode: String!,
    $zipCode: String!,
    $latitude: Float!,
    $longitude: Float!,
    $projectId: ID!
  ) {
    adminCreateWorkOrder(input: {
      locationId: $locationId,
      workOrderNumber: $workOrderNumber,
      routeCode: $routeCode,
      billingCycleCode: $billingCycleCode,
      workOrderType: $workOrderType,
      workOrderFlaggedAsAdHoc: $workOrderFlaggedAsAdHoc,
      accountNumber: $accountNumber,
      street: $street,
      city: $city,
      stateCode: $stateCode,
      zipCode: $zipCode,
      latitude: $latitude,
      longitude: $longitude,
      projectId: $projectId
    }) {
      id
    }
  }
`;

export const CREATE_WORKORDER_HISTORY = gql`
  mutation CreateWorkOrderHistory($historyContent: String!, $historyUser: ID!, $historyWorkOrder: ID!) {
  createWorkOrderHistory(input: {historyContent: $historyContent, historyUser: $historyUser, historyWorkOrder: $historyWorkOrder}) {
    id
  }
}
`;

export const RTU_WORKORDER_MUTATION = gql`
  mutation RTUWorkOrder($id: ID!, $currentUserId: ID!, $workOrderFlaggedAs3strike: Boolean!) {
    rtuWorkOrder(input: {
      id: $id,
      currentUserId: $currentUserId,
      workOrderFlaggedAs3strike: $workOrderFlaggedAs3strike
    })
    {
      id
    }
  }
`;

export const ASSIGN_WORKORDERS_MUTATION = gql`
  mutation AssignWorkOrders($projectId: ID!, $userIds: [String]!, $workOrderIds: [String]!, $isMapView: Boolean) {
    assignWorkOrders(input: {
      projectId: $projectId,
      userIds: $userIds,
      workOrderIds: $workOrderIds,
      isMapView: $isMapView
    }) {
      projectId,
      latestAssignmentsUrl
    }
  }
`;

export const UNASSIGN_WORKORDERS_MUTATION = gql`
  mutation UnassignWorkOrders($projectId: ID!, $userIds: [String]!, $workOrderIds: [String]!, $isMapView: Boolean) {
    unassignWorkOrders(input: {
      projectId: $projectId,
      userIds: $userIds,
      workOrderIds: $workOrderIds,
      isMapView: $isMapView
    }) {
      projectId,
      latestAssignmentsUrl
    }
  }
`;

export const APPROVE_WORKORDER_MUTATION = gql`
  mutation ApproveWorkOrder($workOrderId: ID!, $userId: ID!, $isRework: Boolean) {
    approveWorkOrder(input: {
      workOrderId: $workOrderId
      userId: $userId
      isRework: $isRework
    }) {
      id
    }
  }
`;

export const REJECT_WORKORDER_MUTATION = gql`
  mutation RejectWorkOrder($workOrderId: ID!, $userId: ID!, $comment: String!){
    rejectWorkOrder(input: {
      workOrderId: $workOrderId
      userId: $userId
      comment: $comment
    }) {
      id
    }
  }
`;

export const ESCALATE_WORKORDER_MUTATION = gql`
  mutation EscalateWorkOrder($workOrderId: ID!, $userId: ID!, $comment: String!){
    escalateWorkOrder(input: {
      workOrderId: $workOrderId
      userId: $userId
      comment: $comment
    }) {
      id
    }
  }
`;

export const DELETE_WORKORDER_MUTATION = gql`
  mutation DeleteWorkOrder($projectId: ID!, $workOrderIds: [ID]!) {
    deleteWorkOrder(projectId: $projectId, workOrderIds: $workOrderIds) {
      id
    }
  }
`;

export const RE_OPEN_CLOSED_WORKORDER_MUTATION = gql`
  mutation ReOpenClosedWorkOrder($projectId: ID!, $workOrderIds: [ID]!, $userId: ID!, $workOrderStatus: WorkOrderStatus, $rework: WorkOrderRework) {
    reOpenClosedWorkOrder(input: {
      projectId: $projectId
      userId: $userId
      workOrderIds: $workOrderIds
      workOrderStatus: $workOrderStatus
      rework: $rework
    }) {
      id
    }
  }
`;

export const DUPLICATE_WORKORDER_MUTATION = gql`
  mutation DuplicateWorkOrder($projectId: ID!, $workOrderId: ID!, $userId: ID!) {
    duplicateWorkOrder(input: {
      projectId: $projectId
      userId: $userId
      workOrderId: $workOrderId
    }) {
      id
    }
  }
`;

export const SAVE_WORKORDERS_GROUP_MUTATION = gql`
  mutation SaveWorkOrdersGroup($projectId: ID!, $groupName: String!, $workOrderIds: [String]!) {
    saveWorkOrdersGroup(input: {
      projectId: $projectId, 
      groupName: $groupName, 
      workOrderIds: $workOrderIds
    }) {
      id
    }
  }
`;

export const UPDATE_WORKORDER_MUTATION = gql`
  mutation UpdateWorkOrder(
    $id: ID!,
    $workOrderType: WorkOrderType,
    $workOrderStatus: WorkOrderStatus,
    $workOrderNeedsAppointment: Boolean,
    $workOrderNeedsSiteTest: Boolean,
    $workOrderFlaggedAsCNC: Boolean,
    $workOrderIsGeocoded: Boolean,
    $workOrderFlaggedAs3strike: Boolean,
    $workOrderFlaggedAsDamaged: Boolean,
    $workOrderFlaggedAsAdHoc: Boolean,
    $workOrderFlaggedAsPlated: Boolean,
    $workOrderFlaggedAsEscalated: Boolean,
    $workOrderIsInBlackOut: Boolean,
    $workOrderAttemptNumber: Int,
    $workOrderReasonCodes: [String],
    $workOrderSiteConditions: [String],
    $districtCode: String,
    $substationCode: String,
    $circuitCode: String,
    $billingCycleCode: String,
    $routeCode: String,
    $bookCode: String,
    $accountNumber: String,
    $customerName: String,
    $homePhoneNumber: String,
    $mobilePhoneNumber: String,
    $businessPhoneNumber: String,
    $street: String,
    $street2: String,
    $street3: String,
    $city: String,
    $stateCode: String,
    $zipCode: String,
    $customerContactedOnSite: Boolean,
    $locationId: String ,
    $mapNumber: String,
    $latitude: Float,
    $longitude: Float,
    $capturedGpsAltitude: Float,
    $capturedGpsHorizontalAccuracy: Float,
    $capturedGpsPositionSource: String,
    $capturedGpsVerticalAccuracy: Float,
    $capturedLatitude: Float,
    $capturedLongitude: Float,
    $workOrderIsDeleted: Boolean,
    $workOrderPhotosExported: Boolean,
    $oldMeterNumber: String,
    $newMeterNumber: String,
    $newMeterAssetNumber: String,
    $newMeterDial: Int,
    $meterStatus: String,
    $meterType: String,
    $serviceType: String,
    $serviceDescription: String,
    $meterReadNotes: String,
    $ReadSequence: Int,
    $oldMeterKwLastReading: Float,
    $oldMeterKwhLastReading: Float,
    $oldMeterKvaLastReading: Float,
    $oldMeterKvarLastReading: Float,
    $oldMeterKwReading: Float,
    $oldMeterKwhReading: Float,
    $oldMeterKvaReading: Float,
    $oldMeterKvarReading: Float,
    $newMeterKwReading: Float,
    $newMeterKwhReading: Float,
    $newMeterKvaReading: Float,
    $newMeterKvarReading: Float,
    $newMeterScan: String,
    $meterClass: String,
    $meterVoltage: String,
    $meterFormFactor: String,
    $billingMultiplier: Float,
    $isMedical: Boolean,
    $needsOpenDisconnectSwitch: Boolean,
    $hasDisconnectedSwitch: Boolean,
    $meterSetNumber: String,
    $demandCode: String,
    $oldMeterLastReading: Float,
    $oldMeterReading: Float,
    $newMeterNotDeployed: Boolean,
    $oldRadioNumber: String,
    $newRadioNumber: String,
    $newRadioNotDeployed: Boolean,
    $newRadioValidated: Boolean,
    $newRadioSNR: Float,
    $newRegisterNotDeployed: Boolean,
    $oldRegisterNumber: String,
    $newRegisterNumber: String,
    $oldGenericNumber: String,
    $newGenericNumber: String,
    $newMeterReading: Float,
    $newLidSize: String,
    $meterSize: String,
    $lineSize: String,
    $lineType: String,
    $lidSize: String
    $workOrderReviewedUserId: ID
    $workOrderEscalatedCategory: String
    $customDataFields: [CustomDataFieldInput]
  ) {
    updateWorkOrder(input: {
      id: $id
      workOrderType: $workOrderType
      workOrderStatus: $workOrderStatus
      workOrderNeedsAppointment: $workOrderNeedsAppointment
      workOrderNeedsSiteTest: $workOrderNeedsSiteTest
      workOrderFlaggedAsCNC: $workOrderFlaggedAsCNC
      workOrderIsGeocoded: $workOrderIsGeocoded
      workOrderFlaggedAs3strike: $workOrderFlaggedAs3strike
      workOrderFlaggedAsDamaged: $workOrderFlaggedAsDamaged
      workOrderFlaggedAsAdHoc: $workOrderFlaggedAsAdHoc
      workOrderFlaggedAsPlated: $workOrderFlaggedAsPlated
      workOrderFlaggedAsEscalated: $workOrderFlaggedAsEscalated
      workOrderIsInBlackOut: $workOrderIsInBlackOut
      workOrderAttemptNumber: $workOrderAttemptNumber
      workOrderReasonCodes: $workOrderReasonCodes
      workOrderSiteConditions: $workOrderSiteConditions
      districtCode: $districtCode
      substationCode: $substationCode
      circuitCode: $circuitCode
      billingCycleCode: $billingCycleCode
      routeCode: $routeCode
      bookCode: $bookCode
      accountNumber: $accountNumber
      customerName: $customerName
      homePhoneNumber: $homePhoneNumber
      mobilePhoneNumber: $mobilePhoneNumber
      businessPhoneNumber: $businessPhoneNumber
      street: $street
      street2: $street2
      street3: $street3
      city: $city
      stateCode: $stateCode
      zipCode: $zipCode
      customerContactedOnSite: $customerContactedOnSite
      locationId: $locationId
      mapNumber: $mapNumber
      latitude: $latitude
      longitude: $longitude
      capturedGpsAltitude: $capturedGpsAltitude
      capturedGpsHorizontalAccuracy: $capturedGpsHorizontalAccuracy
      capturedGpsPositionSource: $capturedGpsPositionSource
      capturedGpsVerticalAccuracy: $capturedGpsVerticalAccuracy
      capturedLatitude: $capturedLatitude
      capturedLongitude: $capturedLongitude
      workOrderIsDeleted: $workOrderIsDeleted
      workOrderPhotosExported: $workOrderPhotosExported
      oldMeterNumber: $oldMeterNumber
      newMeterNumber: $newMeterNumber
      newMeterAssetNumber: $newMeterAssetNumber
      newMeterDial: $newMeterDial
      meterStatus: $meterStatus
      meterType: $meterType
      serviceType: $serviceType
      serviceDescription: $serviceDescription
      meterReadNotes: $meterReadNotes
      ReadSequence: $ReadSequence
      oldMeterKwLastReading: $oldMeterKwLastReading
      oldMeterKwhLastReading: $oldMeterKwhLastReading
      oldMeterKvaLastReading: $oldMeterKvaLastReading
      oldMeterKvarLastReading: $oldMeterKvarLastReading
      oldMeterKwReading: $oldMeterKwReading
      oldMeterKwhReading: $oldMeterKwhReading
      oldMeterKvaReading: $oldMeterKvaReading
      oldMeterKvarReading: $oldMeterKvarReading
      newMeterKwReading: $newMeterKwReading
      newMeterKwhReading: $newMeterKwhReading
      newMeterKvaReading: $newMeterKvaReading
      newMeterKvarReading: $newMeterKvarReading
      newMeterScan: $newMeterScan
      meterClass: $meterClass
      meterVoltage: $meterVoltage
      meterFormFactor: $meterFormFactor
      billingMultiplier: $billingMultiplier
      isMedical: $isMedical
      needsOpenDisconnectSwitch: $needsOpenDisconnectSwitch
      hasDisconnectedSwitch: $hasDisconnectedSwitch
      meterSetNumber: $meterSetNumber
      demandCode: $demandCode
      oldMeterLastReading: $oldMeterLastReading
      oldMeterReading: $oldMeterReading
      newMeterNotDeployed: $newMeterNotDeployed
      oldRadioNumber: $oldRadioNumber
      newRadioNumber: $newRadioNumber
      newRadioNotDeployed: $newRadioNotDeployed
      newRadioValidated: $newRadioValidated
      newRadioSNR: $newRadioSNR
      newRegisterNotDeployed: $newRegisterNotDeployed
      oldRegisterNumber: $oldRegisterNumber
      newRegisterNumber: $newRegisterNumber
      oldGenericNumber: $oldGenericNumber
      newGenericNumber: $newGenericNumber
      newMeterReading: $newMeterReading
      newLidSize: $newLidSize
      meterSize: $meterSize
      lineSize: $lineSize
      lineType: $lineType
      lidSize: $lidSize
      workOrderReviewedUserId: $workOrderReviewedUserId
      workOrderEscalatedCategory: $workOrderEscalatedCategory
      customDataFields: $customDataFields
    })
    {
      id
    }
  }
`;

export const SWAP_WORKORDERS_MUTATION = gql`
  mutation swapWorkOrders($projectId: ID!, $workOrderId1: ID!, $workOrderId2: ID!, $userId: ID!) {
    swapWorkOrders(projectId: $projectId, workOrderId1: $workOrderId1, workOrderId2: $workOrderId2, userId: $userId) {
      id
    }
  }
`;

export const UPDATE_WORKORDER_NEW_METER_NUMBER_MUTATION = gql`
  mutation updateWorkOrderNewMeterNumber($projectId: ID!, $workOrderId: ID!, $workOrderType: WorkOrderType!, $newMeterNumber: String!) {
    updateWorkOrderNewMeterNumber(input: {
      projectId: $projectId
      workOrderId: $workOrderId
      workOrderType: $workOrderType
      newMeterNumber: $newMeterNumber
    }) {
      id
    }
  }
`;


export const COMPLETE_CALL_MUTATION = gql`
  mutation CompleteCall($userId: ID!, $workOrderId: ID!, $comment: String!, $callSuccess: Boolean) {
    completeCall(
      workOrderId: $workOrderId, 
      comment: $comment,
      userId: $userId,
      callSuccess: $callSuccess
    ) {
      id
    }
  }
`;