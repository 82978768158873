import React, { useState, useEffect, useRef } from 'react';
import { FormModal } from './FormModal';

import { Dialog } from "@progress/kendo-react-dialogs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Button } from '@progress/kendo-react-buttons';
import { Scheduler, AgendaView, SchedulerTask } from "@progress/kendo-react-scheduler";

import * as commentService from '../../../core/services/comment.service';



const formatDate = ( inputDate ) => {
    const outputDate = new Date(inputDate);
    const options = {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true // Use 12-hour clock
    };
    return outputDate.toLocaleString("en-US", options);
}

const resources = [
    {
        name: "Availability",
        data: [
            { text: "Availabile", value: "Available", color: "#65c475" },
            { text: "Booked", value: "Booked", color: "#727272" },
        ],
        field: "description",
        valueField: "value",
        textField: "text",
        colorField: "color",
    },
];





export const WorkOrderDetails = ({ selectedWorkOrder, closeModal, fetchWorkOrder, user, setLoading, scheduleNow = false, resetScheduleNow, availabilityData, fetchAvailability, openUpdateCallStatusModal, timeZone }) => {
    const [searchDates, setSearchDates] = useState({});
    const [agendaDate, setAgendaDate] = useState(new Date());
    const [selectedTab, setSelectedTab] = useState(scheduleNow ? 3 : 0);
    const [formModal, setFormModal] = useState(false);
    const [comment, setComment] = useState('');
    const [formFill, setFormFill] = useState({
        appointmentStartDate: '',
        appointmentEndDate: '',
        appointmentId: '',
    });

    useEffect(() => {
        initializeSearchDates();
        return resetScheduleNow();
    }, []);


    if(!selectedWorkOrder){
        console.error('No Work Order Selected.');
        closeModal();
        return <></>;
    }

    const handleSelect = (evt) => {
        setSelectedTab(evt.selected);
    };

    const submitComment = async () => {
        setLoading(true);
        try {
            await commentService.addComment(user.id, selectedWorkOrder.id, comment);
            await fetchWorkOrder(selectedWorkOrder.id);
            setComment('');
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
        setLoading(false);
    };

    const handleComment = (evt) => {
        setComment(evt.target.value);
    }

    const triggerFormModal = ( info ) => {
        setFormFill({
            appointmentEndDate: info.end,
            appointmentStartDate: info.start,
            appointmentId: selectedWorkOrder.appointmentId,
            workOrderId: selectedWorkOrder.id,
            projectId: selectedWorkOrder.project.id,
        })
        setFormModal(true);
    }

    const initializeSearchDates = ( inputDate = new Date() ) => {
        const startDate = new Date(inputDate);
        const startOfWeek = new Date(inputDate);
        const dayOfWeek = inputDate.getDay();
        const diffToMonday = (dayOfWeek + 7) % 7;
        startDate.setDate(startDate.getDate() - diffToMonday);
        startOfWeek.setDate(startOfWeek.getDate() - diffToMonday);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 7);
        setSearchDates({ startDate, endDate });
        setAgendaDate(startOfWeek);
        return [ startDate, endDate ]
    }

    const handleSearchDates = async ( inputDate = new Date() ) => {
        setLoading(true);
        const [ startDate, endDate ] = initializeSearchDates(inputDate);
        setSearchDates({ startDate, endDate });
        await fetchAvailability({ workOrderId: selectedWorkOrder.id, startDate, endDate });
        setLoading(false);
    }

    const handleAgendaDateChange = ( evt ) => {
        const value = !evt.isTrusted ? evt.value._localDate : evt.value;
        setAgendaDate(value);
        if(value > searchDates.startDate && value < searchDates.endDate) return;
        handleSearchDates(value);
    }

    return (
        <>
            <Dialog width={'90%'} height={'90%'} title={<CustomTitleBar workOrderNumber={selectedWorkOrder.workOrderNumber} openUpdateCallStatusModal={openUpdateCallStatusModal} />} onClose={closeModal}>
                <TabStrip className='csr-details-modal' selected={selectedTab} onSelect={handleSelect}>
                    <TabStripTab title={'Details'}>
                        <table className={'details-table'}>
                            <tbody>
                                <tr>
                                    <td width={6}>Account Number</td>
                                    <td>{selectedWorkOrder.accountNumber ? selectedWorkOrder.accountNumber : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Date Enrolled</td>
                                    <td>{selectedWorkOrder.dateEnrolled ? selectedWorkOrder.dateEnrolled : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Call Status</td>
                                    <td>{selectedWorkOrder.bookCode ? selectedWorkOrder.bookCode : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>District Code</td>
                                    <td>{selectedWorkOrder.districtCode ? selectedWorkOrder.districtCode : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Customer Name</td>
                                    <td>{selectedWorkOrder.customerName ? selectedWorkOrder.customerName : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Street</td>
                                    <td>
                                        { selectedWorkOrder.street ? selectedWorkOrder.street : '' }
                                        { selectedWorkOrder.street2 && `\n${selectedWorkOrder.street2}` }
                                        { selectedWorkOrder.street3 && `\n${selectedWorkOrder.street3}` }
                                    </td>
                                </tr>
                                <tr>
                                    <td width={6}>Home Phone Number</td>
                                    <td>{selectedWorkOrder.homePhoneNumber ? selectedWorkOrder.homePhoneNumber : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Mobile Phone Number</td>
                                    <td>{selectedWorkOrder.mobilePhoneNumber ? selectedWorkOrder.mobilePhoneNumber : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Business Phone Number</td>
                                    <td>{selectedWorkOrder.businessPhoneNumber ? selectedWorkOrder.businessPhoneNumber : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Work Order Status</td>
                                    <td>{selectedWorkOrder.workOrderStatus ? selectedWorkOrder.workOrderStatus : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Appointment Date</td>
                                    <td>{selectedWorkOrder.appointmentStartDate ? formatDate(selectedWorkOrder.appointmentStartDate) : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Last Visit Date</td>
                                    <td>{selectedWorkOrder.workOrderLastVisitDate ? formatDate(selectedWorkOrder.workOrderLastVisitDate) : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Technician</td>
                                    <td>{selectedWorkOrder.userName ? selectedWorkOrder.userName : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Assignments</td>
                                    <td>{selectedWorkOrder.workOrderAssignments ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.workOrderAssignments}</div> : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Service Type</td>
                                    <td>{selectedWorkOrder.serviceType ? selectedWorkOrder.serviceType : ''}</td>
                                </tr>
                                <tr>
                                    <td width={6}>Service Description</td>
                                    <td>{selectedWorkOrder.serviceDescription ? <div style={{ maxWidth: '272px', overflowWrap: 'break-word' }}>{selectedWorkOrder.serviceDescription}</div> : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </TabStripTab>
                    <TabStripTab title={'Comments'} >
                        { selectedWorkOrder.workOrderComments.items.map((comment, index) => <CommentCard key={index} item={comment} />) }
                        <textarea className={'details-modal-text-area'} onChange={handleComment} value={comment} />
                        <br></br>
                        <Button onClick={submitComment} primary={true} disabled={!comment}>Submit Comment</Button>
                    </TabStripTab>
                    <TabStripTab title={'History'} >
                        { selectedWorkOrder.workOrderHistorys.items.map((comment, index) => {
                            comment.content = comment.historyContent;
                            comment.contentDate = comment.historyDate;
                            comment.user = comment.historyUser;
                            return (
                                <CommentCard key={index} item={comment} />
                            )
                        })}
                    </TabStripTab>
                    <TabStripTab title={'Availability'} >
                        <Scheduler timezone={timeZone} date={agendaDate} onDateChange={handleAgendaDateChange} data={availabilityData} resources={resources}>
                            <AgendaView task={(props) => <CustomTask {...props} triggerFormModal={triggerFormModal} />} />
                        </Scheduler>
                    </TabStripTab>
                </TabStrip>
            </Dialog>
            { formModal && <FormModal fetchWorkOrder={fetchWorkOrder} fetchAvailability={fetchAvailability} setLoading={setLoading} formFill={formFill} closeModal={() => setFormModal(false)} /> }
        </>
    );
}





const CustomTask = ( props ) => {
    const ref = useRef(null);
    const handleDoubleClick = () => {
        const { start, end } = props.dataItem
        props.triggerFormModal({ start, end });
    }
    useEffect(() => {
        if (ref.current) {
            const targetElement = ref.current.element.parentElement.parentElement;
            targetElement.addEventListener('dblclick', handleDoubleClick);
            targetElement.classList.add('custom-task')
        }
        return () => {
            if (ref.current) {
                const targetElement = ref.current.element.parentElement.parentElement;
                targetElement.removeEventListener('dblclick', handleDoubleClick);
            }
        };
    }, [ref]);
    return <SchedulerTask { ...props } ref={ref} />
}






const CommentCard = ({ item }) => {
    return (
        <div className={'comment-card'}>
            <div className={'comment-card-header'}>
                <p className={'title'}>{item.user.userName}</p>
                {/* 00/00/00, 00:00 AM/PM */}
                <p className={'sub-title'}>{formatDate(item.contentDate)}</p>
            </div>
            <div className={'comment-card-body'}>
                <p>{item.content}</p>
            </div>
        </div>
    )
}






const CustomTitleBar = ({ openUpdateCallStatusModal, workOrderNumber }) => {
    const rip = { margin: 0, padding: 0 }
    return(
        <div style={{ ...rip, display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={rip}>Work Order Details for: {workOrderNumber}</div>
            <Button onClick={openUpdateCallStatusModal} primary={true}>Update Call Status</Button>
        </div>
    );
};