import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Button } from 'semantic-ui-react';
import { EXPORT_ARGUMENT_VALUE_TYPE } from '../constants';

const CustomConditionOperationTypeColumn = ({ dataItem }) => (
  <td>
    {typeof (dataItem.operation.args[0]) === 'object' ? EXPORT_ARGUMENT_VALUE_TYPE.json : typeof (dataItem.operation.args[0])}
  </td>
)

const CustomConditionOperationValueColumn = ({ dataItem }) => (
  <td>
    {typeof (dataItem.operation.args[0]) === EXPORT_ARGUMENT_VALUE_TYPE.string ? dataItem.operation.args[0] : JSON.stringify(dataItem.operation.args[0])}
  </td>
)

const CustomActionsColumn = ({ dataItem, onEdit, onDelete }) => (
  <td>
    {dataItem.selected ? (
      <div style={{ display: 'flex' }}>
        <Button circular icon='edit' onClick={() => onEdit(dataItem.id)} />
        <Button circular icon='delete' onClick={() => onDelete(dataItem.id)} />
      </div>
    ) : null}
  </td>
)

const ExportReportConditionsTable = ({ conditions, onDeleteConditions, onEditCondition }) => {
  const [data, setData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    setData(conditions.map((dataItem, index) => Object.assign({ selected: false, id: index }, dataItem)));
    setSelectedRowId(null);
  }, [conditions]);

  const getTableData = () => {
    return data.map((item) => {
      return { ...item, selected: selectedRowId === item.id }
    });
  }

  const rowClick = (event) => {
    setSelectedRowId(event.dataItem.id);
  };

  const onDelete = (id) => {
    onDeleteConditions(id);
    setSelectedRowId(null);
  }

  const onEdit = (id) => {
    onEditCondition(id);
    setSelectedRowId(null);
  }

  return (
    <>
      <div>
        <Grid
          data={getTableData()}
          selectedField='selected'
          onRowClick={rowClick}
        >
          <GridNoRecords>
            No data
          </GridNoRecords>
          <Column field='column_name' width='270px' title='Table Column' />
          <Column field='operation.name' title='Operation' width='150px' />
          <Column field='operation.args' title='Type' width='150px' cell={CustomConditionOperationTypeColumn} />
          <Column field='operation.args' title='Value' width='300px' cell={CustomConditionOperationValueColumn} />
          <Column field='' title='' cell={(props) => <CustomActionsColumn {...props} onDelete={onDelete} onEdit={onEdit} />} />
        </Grid>
      </div>
    </>
  )
}

export default ExportReportConditionsTable;