import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { updateProject } from '../../../../core/services/project.service';
import AppContext from '../../../../core/context/app.context';
import { buildSchema, coerceInputValue } from 'graphql';
import { PROJECT_MOBILE_TEMPLATES_SCHEMA } from '../constants';
import 'jsoneditor-react/es/editor.min.css';
import SuccessMessage from '../../../../shared/components/messages/message';
import ErrorMessage from '../../../../shared/components/messages/error';

const schema = buildSchema(PROJECT_MOBILE_TEMPLATES_SCHEMA);

const MobileStep = ({ project, onUpdateProjectMobileTemplates }) => {

  const context = useContext(AppContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectMobileTemplates, setProjectMobileTemplates] = useState(project.projectMobileTemplates || {});
  const [successNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);

  useEffect(() => {
    if (successNotification || errorNotification) {
      setTimeout(() => {
        setSuccessNotification(false);
        setErrorNotification(false);
      }, 3000);
    }
  }, [successNotification, errorNotification]);

  const onChange = (value) => {
    setProjectMobileTemplates(value);
  }

  const onSave = async () => {
    let hasError = false;
    setLoading(true);
    setSuccessNotification(false);
    setErrorNotification(false);
    setError('');
    try {
      await new Promise((resolve, reject) => {
        coerceInputValue(projectMobileTemplates, schema.getType('MobileTemplateContainer'), (path, invalidValue, error) => {
          console.log(error);
          hasError = true;
          reject({ message: 'Configuration does not match schema!' });
        });
        resolve();
      });
    } catch (error) {
      setError(error);
    }
    if (!hasError) {
      try {
        await updateProject({ id: project.id, projectMobileTemplates: JSON.stringify(projectMobileTemplates, null, 0) });
        onUpdateProjectMobileTemplates(projectMobileTemplates);
        context.setTenant(context.currentTenant);
        setSuccessNotification(true);
      } catch (error) {
        setErrorNotification(true);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className='json-editor'>
        <Editor
          onChange={onChange}
          mode='text'
          value={projectMobileTemplates}
        />
      </div>
      {error && <div style={{ marginTop: 10 }}>
        <ErrorMessage message={error.message} />
      </div>}
      {successNotification && <div style={{ marginTop: 10 }}>
        <SuccessMessage header message='Your data has been saved' />
      </div>}
      {errorNotification && <div style={{ marginTop: 10 }}>
        <ErrorMessage header message='Failed to save the data' />
      </div>}
      <div style={{ marginTop: '10px', textAlign: 'right', paddingBottom: 10 }}>
        <Button loading={loading} onClick={onSave} positive content='Save' />
      </div>
    </>
  )
}

export default MobileStep;