import WORKORDER_TABLES from './workOrderTables';

export default {
  [WORKORDER_TABLES.WorkOrder]: [
    'workOrderNumber',
    'recordLocator',
    'workOrderNeedsAppointment',
    'workOrderNeedsSiteTest',
    'workOrderIsGeocoded',
    'customDataFields',
    'districtCode',
    'substationCode',
    'circuitCode',
    'billingCycleCode',
    'routeCode',
    'bookCode',
    'locationId',
    'accountNumber',
    'customerName',
    'homePhoneNumber',
    'mobilePhoneNumber',
    'businessPhoneNumber',
    'street',
    'street2',
    'street3',
    'city',
    'stateCode',
    'zipCode',
    'locationId',
    'mapNumber',
    'latitude',
    'longitude',
  ],
  [WORKORDER_TABLES.WorkOrderMeterDeploy]: [
    'oldMeterNumber',
    'oldMeterKwLastReading',
    'oldMeterKwhLastReading',
    'oldMeterKvaLastReading',
    'oldMeterKvarLastReading',
    'newRadioNumber',
    'newRegisterNotDeployed',
    'newRegisterNumber',
    'newMeterDial',
    'newLidSize',
    'meterStatus',
    'meterType',
    'meterClass',
    'meterVoltage',
    'meterFormFactor',
    'billingMultiplier',
    'meterReadNotes',
    'serviceType',
    'serviceDescription',
    'ReadSequence',
    'isMedical',
    'needsOpenDisconnectSwitch',
    'hasDisconnectedSwitch',
    'meterSetNumber',
    'demandCode',
    'ptRatio',
    'ctRatio',
    'turnsRatio'
  ],
  [WORKORDER_TABLES.WorkOrderWaterDeploy]: [
    'oldMeterNumber',
    'oldMeterLastReading',
    'oldMeterReading',
    'newMeterNotDeployed',
    'oldRadioNumber',
    'newRadioNotDeployed',
    'newRegisterNotDeployed',
    'oldRegisterNumber',
    'oldGenericNumber',
    'newGenericNumber',
    'newMeterDial',
    'newLidSize',
    'meterStatus',
    'meterType',
    'meterSize',
    'lineSize',
    'lineType',
    'lidSize',
    'meterReadNotes',
    'serviceType',
    'serviceDescription',
    'ReadSequence'
  ]
}