import {
  SET_SHOW_INFO,
  SET_SHOW_ADDITIONAL,
  SET_SHOW_AUDIT,
  SET_SHOW_COMMENTS,
  SET_SHOW_HISTORY,
  RESET_WO_DETAILS_MENU_STATE,
} from '../types/workOrderDetailsMenu.types';

export const setShowInfo = () => {
  return {
    type: SET_SHOW_INFO,
  }
}

export const setShowAdditional = () => {
  return {
    type: SET_SHOW_ADDITIONAL,
  }
}

export const setShowAudit = () => {
  return {
    type: SET_SHOW_AUDIT,
  }
}

export const setShowComments = () => {
  return {
    type: SET_SHOW_COMMENTS,
  }
}

export const setShowHistory = () => {
  return {
    type: SET_SHOW_HISTORY,
  }
}

export const resetWODetailsMenuState = () => {
  return {
    type: RESET_WO_DETAILS_MENU_STATE,
  }
}