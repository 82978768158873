import gql from 'graphql-tag';

export const LIST_ROLES_QUERY = gql`
  query listRoles {
    listRoles {
      items {
        id
        role
      }
    }
  }
`;

