export default [
  'match',
  ['get', 'color'],
  '#212121', '#212121',
  '#C84F0A', '#C84F0A',
  '#63A318', '#63A318',
  '#E19613', '#E19613',
  '#D0021B', '#D0021B',
  '#196AB8', '#196AB8',
  '#616161', '#616161',
  '#EEBF04', '#EEBF04',
  '#EEBF04'
];