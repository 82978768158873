const WORKORDER_TYPES = {
  WaterMeterDeploy: 'WaterMeterDeploy',
  WaterDeploy: 'WaterDeploy',
  WaterSiteSurvey: 'WaterSiteSurvey',
  MeterDeploy: 'MeterDeploy'
}

export const WORKORDER_TYPES_LABEL = {
  [WORKORDER_TYPES.WaterDeploy]: 'Water Deploy',
  [WORKORDER_TYPES.WaterSiteSurvey]: 'Water Site Survey',
  [WORKORDER_TYPES.WaterMeterDeploy]: 'Water Meter Deploy',
  [WORKORDER_TYPES.MeterDeploy]: 'Meter Deploy'
}

export default WORKORDER_TYPES;