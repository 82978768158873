import React from 'react';
import {
  Grid,
  GridColumn as Column
} from '@progress/kendo-react-grid';
import Loader from '../../../../../shared/components/loader';
import { Icon } from 'semantic-ui-react';

class IsActiveIconCell extends React.Component {
  render() {
    const { dataItem } = this.props;
    const { isActive } = dataItem;
    return (
      <td>
        {isActive ? <Icon name='check' color='green' /> : <Icon name='close' color='red' />}
      </td>
    );
  }
}

const UserListView = ({ users, loading, onSortChange, sort, onRowClick }) => (
  <React.Fragment>
    <Loader loading={loading} />
    <Grid
      className='workorders-listview-grid'
      style={{ height: '92%' }}
      data={users}
      sortable
      sort={sort}
      onSortChange={onSortChange}
      onRowClick={onRowClick}>
      <Column field='fullName' title='Full Name' width='180px' />
      <Column field='userName' title='User Name' width='150px' />
      <Column field='roles' title='Roles' width='150px' />
      <Column field='projects' title='Projects' width='400px' />
      <Column field='isActive' title='Active' width='150px' cell={IsActiveIconCell} />
    </Grid>
  </React.Fragment>
)

export default UserListView;