import client from '../apollo';
import { LIST_QUALIFICATIONS_BY_TENANT_QUERY, LIST_QUALIFICATION_BY_USER_QUERY, LIST_QUALIFICATION_DEFINITION_VALUES_BY_TENANT_QUERY } from '../../graphql/queries/qualification';
import { CREATE_QUALIFICATION_MUTATION, DELETE_QUALIFICATION_MUTATION, UPDATE_QUALIFICATION_MUTATION } from '../../graphql/mutations/qualification';

export const listQualificationsByTenant = async (tenantId) => {
  try {
    const { data } = await client.query({
      query: LIST_QUALIFICATIONS_BY_TENANT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId
      }
    });
    const qualifications = data.listQualificationsByTenant;
    return qualifications && qualifications.items && qualifications.items.length > 0 ? qualifications.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const listQualificationDefinitionValuesByTenant = async (tenantId, workOrderType) => {
  try {
    const { data } = await client.query({
      query: LIST_QUALIFICATION_DEFINITION_VALUES_BY_TENANT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        workOrderType
      }
    });
    const values = data.listQualificationDefinitionValuesByTenant;
    return values && values.items && values.items.length > 0 ? values.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const listQualificationByUser = async (userId) => {
  try {
    const { data } = await client.query({
      query: LIST_QUALIFICATION_BY_USER_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        userId
      }
    });
    const values = data.listQualificationByUser;
    return values && values.items && values.items.length > 0 ? values.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const createQualification = async (qualification) => {
  try {
    await client.mutate({
      mutation: CREATE_QUALIFICATION_MUTATION,
      variables: { ...qualification }
    });
  } catch (error) {
    throw error;
  }
}

export const updateQualification = async (qualification) => {
  try {
    await client.mutate({
      mutation: UPDATE_QUALIFICATION_MUTATION,
      variables: { ...qualification }
    });
  } catch (error) {
    throw error;
  }
}

export const deleteQualification = async (qualificationId) => {
  try {
    await client.mutate({
      mutation: DELETE_QUALIFICATION_MUTATION,
      variables: { qualificationId }
    });
  } catch (error) {
    console.log(error);
  }
}