

import gql from 'graphql-tag';

export const LIST_UPLOAD_STATUS_BY_PROJECT_QUERY = gql`
  query listUploadStatus($projectId: ID!) {
    listUploadStatusesByProject(project: $projectId) {
      items {
        createdDate
        message
        status
      }
    }
  }
`;