import React, { useContext, useState } from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';
import { adminCreateWorkOrder } from '../../../../core/services/workOrder.service';
import CustomErrorMessage from '../../../../shared/components/messages/error';

const handleKeyPress = (event, onModalConfirm) => {
  if (event.charCode === 13) {
    onModalConfirm(event);
  }
}

const CreateWorkOrderForm = ({ createWorkOrder, error }) => {
  const [locationId, setLocationId] = useState('');
  const [workOrderNumber, setWorkOrderNumber] = useState('');
  const [routeCode, setRouteCode] = useState('');
  const [billingCycleCode, setBillingCycleCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const onLocationIdChange = (event, { value }) => {
    setLocationId(value);
  }

  const onWorkOrderNumberChange = (event, { value }) => {
    setWorkOrderNumber(value);
  }

  const onRouteCodeChange = (event, { value }) => {
    setRouteCode(value);
  }

  const onBillingCycleCodeChange = (event, { value }) => {
    setBillingCycleCode(value);
  }

  const onAccountNumberChange = (event, { value }) => {
    setAccountNumber(value);
  }

  const onStreetChange = (event, { value }) => {
    setStreet(value);
  }

  const onCityChange = (event, { value }) => {
    setCity(value);
  }

  const onStateCodeChange = (event, { value }) => {
    setStateCode(value);
  }

  const onZipCodeChange = (event, { value }) => {
    setZipCode(value);
  }

  const onLatitudeChange = (event, { value }) => {
    setLatitude(value);
  }

  const onLongitudeChange = (event, { value }) => {
    setLongitude(value);
  }

  return (
    <div onKeyPress={(e) => handleKeyPress(e, createWorkOrder)}>
      <Form id='create-workorder-form' onSubmit={(e) => createWorkOrder({
        locationId,
        workOrderNumber,
        routeCode,
        billingCycleCode,
        accountNumber,
        street,
        city,
        stateCode,
        zipCode,
        latitude,
        longitude,
      })}>
      <Form.Field>
        <label>Location Id (required)</label>
        <Form.Input autoFocus required placeholder='Location Id' onChange={onLocationIdChange} value={locationId} />
      </Form.Field>
        <Form.Field>
          <label>Work Order Number/Old Meter Number (required)</label>
          <Form.Input required placeholder='Work Order Number' onChange={onWorkOrderNumberChange} value={workOrderNumber} />
        </Form.Field>
        <Form.Field>
          <label>Route Code (required)</label>
          <Form.Input required placeholder='Route Code' onChange={onRouteCodeChange} value={routeCode} />
        </Form.Field>
        <Form.Field>
          <label>Billing Cycle Code (required)</label>
          <Form.Input required placeholder='Billing Cycle Code' onChange={onBillingCycleCodeChange} value={billingCycleCode} />
        </Form.Field>
        <Form.Field>
          <label>Account Number</label>
          <Form.Input placeholder='Account Number' onChange={onAccountNumberChange} value={accountNumber} />
        </Form.Field>
        <Form.Field>
          <label>Street</label>
          <Form.Input placeholder='Street' onChange={onStreetChange} value={street} />
        </Form.Field>
        <Form.Field>
          <label>City</label>
          <Form.Input placeholder='City' onChange={onCityChange} value={city} />
        </Form.Field>
        <Form.Field>
          <label>State Code</label>
          <Form.Input placeholder='State Code' onChange={onStateCodeChange} value={stateCode} />
        </Form.Field>
        <Form.Field>
          <label>Zip Code</label>
          <Form.Input placeholder='Zip Code' onChange={onZipCodeChange} value={zipCode} />
        </Form.Field>
        <Form.Field>
          <label>Latitude</label>
          <Form.Input placeholder='Latitude' type='number' onChange={onLatitudeChange} value={latitude} />
        </Form.Field>
        <Form.Field>
          <label>Longitude</label>
          <Form.Input placeholder='Longitude' type='number' onChange={onLongitudeChange} value={longitude} />
        </Form.Field>
        {error && <CustomErrorMessage message={error} />}
      </Form>
    </div>
  )
}

const CreateWorkOrderModal = ({
  showModal,
  closeModal,
}) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createWorkOrder = async (workOrder) => {
    try {
      setError(null);
      const { currentProject } = context;
      setLoading(true);
      await adminCreateWorkOrder({
        ...workOrder,
        projectId: currentProject.id,
        workOrderType: currentProject.projectWorkOrderType,
        workOrderFlaggedAsAdHoc: true
      })
      closeModal(null, null, workOrder.workOrderNumber);
    } catch (error) {
      setError('Unexpected error occurred');
      setLoading(false);
    }
  }

  return (
    <Modal dimmer='blurring' size='tiny' onClose={closeModal} open={showModal}>
      <Modal.Header>
        Create Work Order
      </Modal.Header>
      <Modal.Content>
        <CreateWorkOrderForm error={error} createWorkOrder={createWorkOrder} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative disabled={loading}>Cancel</Button>
        <button type='submit' form='create-workorder-form' className={`ui positive button${loading ? ' loading' : ''}`}>Create</button>
      </Modal.Actions>
    </Modal>
  )
}

export default CreateWorkOrderModal;