import React from 'react';
import { Modal, Button, Input } from 'semantic-ui-react';

const QuantityModal = ({ quantity, modalRef, showModal, closeModal, loading, onSubmit, onQuantityChange }) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmit();
    }
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>Update Quantity</Modal.Header>
        <Modal.Content>
          <span style={{ paddingRight: '10px' }}>Additional Quantity: </span><Input type='number' placeholder='Quantity' onChange={onQuantityChange} />
          <p style={{ paddingTop: '20px', paddingRight: '10px' }}><i>Amount to add (or to subtract enter a negative value, i.e. -100)</i></p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} disabled={loading} negative>Cancel</Button>
          <Button onClick={onSubmit} disabled={isNaN(+quantity) || quantity === ''} loading={loading} positive icon='checkmark' labelPosition='right' content='Save' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default QuantityModal;