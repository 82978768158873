import gql from 'graphql-tag';

export const UPDATE_PLAN_WEEK_ACTUAL_RATE_MUTATION = gql`
  mutation updatePlanWeekActualRate($planId: ID!, $project: ID!){
    updatePlanWeekActualRate(input:{
      planId: $planId,
      project: $project
    }) {
      id
      startDate
      endDate
      weeklyCapacity
      isActive
      workingDays
      planWeeks {
        id
        planId
        planRate
        targetRate
        week
        workingDays
        weekStartDate
        actualRate
      }
    }
}`;

export const CREATE_PLAN_MUTATION = gql`
  mutation createPlan($project: ID!, $startDate: String!, $endDate: String!, $weeklyCapacity: Float!, $workingDays: Int!) {
    createPlan(
      input:{
        startDate: $startDate
        endDate: $endDate
        weeklyCapacity: $weeklyCapacity
        project: $project
        workingDays: $workingDays
      }
    ) {
      id
      startDate
      endDate
      weeklyCapacity
      isActive
      workingDays
      planWeeks {
        id
        planId
        planRate
        targetRate
        week
        workingDays
        weekStartDate
        actualRate
      }
    }
  }
`;

export const UPDATE_PLAN_MUTATION = gql`
  mutation updatePlan($id:ID!, $startDate: String!, $endDate: String!, $weeklyCapacity: Float!, $workingDays: Int!, $overrideAllWeeksPlanRates: Boolean!, $overrideAllWeeksWorkingDays: Boolean!, $project: ID!) {
    updatePlan(
      input:{
        startDate: $startDate
        endDate: $endDate
        weeklyCapacity: $weeklyCapacity
        id: $id
        workingDays: $workingDays
        overrideAllWeeksPlanRates: $overrideAllWeeksPlanRates
        overrideAllWeeksWorkingDays: $overrideAllWeeksWorkingDays
        project: $project
      }
    ) {
      id
      startDate
      endDate
      weeklyCapacity
      isActive
      workingDays
      planWeeks {
        id
        planId
        planRate
        targetRate
        week
        workingDays
        weekStartDate
        actualRate
      }
    }
  }
`;

export const UPDATE_PLAN_WEEK_MUTATION = gql`
  mutation updatePlanWeek($planWeekUpdateList: [UpdatePlanWeekInput]!) {
    updatePlanWeek(input: {
      planWeekUpdateList: $planWeekUpdateList
    }
    ) {
      id
    }
  }
`;