import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Input, Modal, Table } from 'semantic-ui-react';

const LookupsMappingForm = ({ lookupKey, onLookupKeyChange, lookupValue, onLookupValueChange, onAddLookupsMapping }) => (
  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
    <div style={{ paddingRight: 10 }}>
      Key: <Input type='text' placeholder='Key' value={lookupKey} onChange={onLookupKeyChange} />
    </div>
    <div>
      Value: <Input type='text' placeholder='Value' value={lookupValue} onChange={onLookupValueChange} />
    </div>
    <div style={{ paddingLeft: 10 }}>
      <Button icon onClick={onAddLookupsMapping}>
        <Icon name='add' />
      </Button>
    </div>
  </div>
);

const LookupsMappingTable = ({ lookups, selectedTableColumnLookups, selectedFileColumnLookup, onRemoveLookupMapping }) => (
  <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={4}>Key</Table.HeaderCell>
        <Table.HeaderCell width={4}>Value</Table.HeaderCell>
        <Table.HeaderCell width={1}></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {selectedTableColumnLookups === 'customDataFields' ? (
        Object.keys(lookups[selectedTableColumnLookups][selectedFileColumnLookup]).map((key, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              {key}
            </Table.Cell>
            <Table.Cell>
              {lookups[selectedTableColumnLookups][selectedFileColumnLookup][key]}
            </Table.Cell>
            <Table.Cell>
              <div style={{ textAlign: 'center' }}>
                <Icon onClick={(event) => onRemoveLookupMapping(event, key)} name='remove' className='remove-icon' />
              </div>
            </Table.Cell>
          </Table.Row>
        ))
      ) : (
        Object.keys(lookups[selectedTableColumnLookups]).map((key, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              {key}
            </Table.Cell>
            <Table.Cell>
              {lookups[selectedTableColumnLookups][key]}
            </Table.Cell>
            <Table.Cell>
              <div style={{ textAlign: 'center' }}>
                <Icon onClick={(event) => onRemoveLookupMapping(event, key)} name='remove' className='remove-icon' />
              </div>
            </Table.Cell>
          </Table.Row>
        ))
      )}
    </Table.Body>
  </Table>
);

const LookupsModal = ({
  modalRef,
  showModal,
  closeModal,
  onSubmit,
  tableColumnNames,
  fileColumnNames,
  configLookups
}) => {
  const [lookups, setLookups] = useState({ ...configLookups });
  const [tableColumnName, setTableColumnName] = useState('');
  const [fileColumnName, setFileColumnName] = useState('');
  const [selectedTableColumnLookups, setSelectedTableColumnLookups] = useState('');
  const [selectedFileColumnLookup, setSelectedFileColumnLookup] = useState('');
  const [lookupKey, setLookupKey] = useState('');
  const [lookupValue, setLookupValue] = useState('');

  useEffect(() => {
    setLookups({ ...configLookups });
  }, [configLookups]);

  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmit(lookups);
    }
  }

  const getTableColumns = () => {
    const tableColumnsInUse = Object.keys(lookups);
    return tableColumnNames
      .filter(tableColumnName => tableColumnsInUse.indexOf(tableColumnName) === -1)
      .map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }));
  }

  const getTableColumnLookups = () => {
    return Object.keys(lookups).map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }));
  }

  const getFileColumnLookups = () => {
    return Object.keys(lookups[selectedTableColumnLookups]).map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }));
  }

  const getFileColumns = () => {
    if (selectedTableColumnLookups === 'customDataFields') {
      const fileColumnsInUse = Object.keys(lookups[selectedTableColumnLookups]);
      return [...new Set(fileColumnNames)]
        .filter(fileColumnName => fileColumnsInUse.indexOf(fileColumnName) === -1)
        .map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }))
    }
    return [];
  }

  const onTableColumnNameChange = (event, { value }) => {
    setTableColumnName(value);
  }

  const onFileColumnNameChange = (event, { value }) => {
    setFileColumnName(value);
  }

  const onTableColumnAdd = () => {
    if (tableColumnName) {
      if (!lookups[tableColumnName]) {
        lookups[tableColumnName] = {};
        setLookups({ ...lookups });
        setTableColumnName('');
      }
      setLookupKey('');
      setLookupValue('');
      setFileColumnName('');
      setSelectedFileColumnLookup('');
      setSelectedTableColumnLookups(tableColumnName);
    }
  }

  const onFileColumnAdd = () => {
    if (fileColumnName) {
      if (!lookups[selectedTableColumnLookups][fileColumnName]) {
        lookups[selectedTableColumnLookups][fileColumnName] = {};
        setLookups({ ...lookups });
        setFileColumnName('');
      }
      setLookupKey('');
      setLookupValue('');
      setSelectedFileColumnLookup(fileColumnName);
    }
  }

  const onTableColumnLookupsChange = (event, { value }) => {
    setSelectedTableColumnLookups(value);
  }

  const onFileColumnLookupsChange = (event, { value }) => {
    setLookupKey('');
    setLookupValue('');
    setSelectedFileColumnLookup(value);
  }

  const onRemoveSelectedTableColumnLookups = () => {
    if (selectedTableColumnLookups) {
      delete lookups[selectedTableColumnLookups];
      setLookups({ ...lookups });
    }
    Object.keys(lookups).length > 0 ?
      setSelectedTableColumnLookups(Object.keys(lookups)[0])
      :
      setSelectedTableColumnLookups('');
    setLookupKey('');
    setLookupValue('');
    setFileColumnName('');
    setSelectedFileColumnLookup('');
  }

  const onRemoveSelectedFileColumnLookups = () => {
    if (selectedFileColumnLookup) {
      delete lookups[selectedTableColumnLookups][selectedFileColumnLookup];
      setLookups({ ...lookups });
    }
    Object.keys(lookups[selectedTableColumnLookups]).length > 0 ?
      setSelectedFileColumnLookup(Object.keys(lookups[selectedTableColumnLookups])[0])
      :
      setSelectedFileColumnLookup('');
    setLookupKey('');
    setLookupValue('');
  }

  const onAddTableColumnLookupsMapping = () => {
    lookups[selectedTableColumnLookups][lookupKey] = lookupValue;
    setLookupKey('');
    setLookupValue('');
    setLookups({ ...lookups });
  }

  const onAddFileColumnLookupsMapping = () => {
    lookups[selectedTableColumnLookups][selectedFileColumnLookup][lookupKey] = lookupValue;
    setLookupKey('');
    setLookupValue('');
    setLookups({ ...lookups });
  }

  const onLookupKeyChange = (event, { value }) => {
    setLookupKey(value);
  }

  const onLookupValueChange = (event, { value }) => {
    setLookupValue(value);
  }

  const onRemoveLookupMapping = (event, key) => {
    delete lookups[selectedTableColumnLookups][key];
    setLookups({ ...lookups });
  }

  const isLookupMappingFormVisible = () => {
    return selectedTableColumnLookups && (
      selectedTableColumnLookups !== 'customDataFields' || (selectedTableColumnLookups === 'customDataFields' && selectedFileColumnLookup)
    );
  }

  const isLookupMappingTableVisible = () => {
    return selectedTableColumnLookups && (
      (selectedTableColumnLookups !== 'customDataFields' && Object.keys(lookups[selectedTableColumnLookups]).length > 0)
      ||
      (selectedFileColumnLookup && (selectedTableColumnLookups === 'customDataFields' && Object.keys(lookups[selectedTableColumnLookups][selectedFileColumnLookup]).length > 0))
    );
  }

  const onSave = () => {
    onSubmit(lookups);
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='small' open={showModal}>
        <Modal.Header>Lookups</Modal.Header>
        <Modal.Content>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Add Table Column:</span>
            <div style={{ paddingLeft: 10 }}>
              <Dropdown
                search
                value={tableColumnName}
                onChange={onTableColumnNameChange}
                options={getTableColumns()}
                placeholder='Table Column'
                selection
              />
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Button icon onClick={onTableColumnAdd}>
                <Icon name='add' />
              </Button>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Select Table Column Lookups:</span>
            <div style={{ paddingLeft: 10 }}>
              <Dropdown
                search
                value={selectedTableColumnLookups}
                onChange={onTableColumnLookupsChange}
                options={getTableColumnLookups()}
                placeholder='Table Column Lookups'
                selection
              />
            </div>
            {selectedTableColumnLookups && <div style={{ paddingLeft: 10 }}>
              <Button icon onClick={onRemoveSelectedTableColumnLookups}>
                <Icon name='trash' />
              </Button>
            </div>}
          </div>

          {selectedTableColumnLookups && selectedTableColumnLookups === 'customDataFields' && <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Add File Column:</span>
            <div style={{ paddingLeft: 10 }}>
              <Dropdown
                search
                value={fileColumnName}
                onChange={onFileColumnNameChange}
                options={getFileColumns()}
                placeholder='File Column'
                selection
              />
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Button icon onClick={onFileColumnAdd}>
                <Icon name='add' />
              </Button>
            </div>
          </div>}

          {selectedTableColumnLookups && selectedTableColumnLookups === 'customDataFields' && <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Select File Column Lookups:</span>
            <div style={{ paddingLeft: 10 }}>
              <Dropdown
                search
                value={selectedFileColumnLookup}
                onChange={onFileColumnLookupsChange}
                options={getFileColumnLookups()}
                placeholder='File Column Lookups'
                selection
              />
            </div>
            {selectedTableColumnLookups && <div style={{ paddingLeft: 10 }}>
              <Button icon onClick={onRemoveSelectedFileColumnLookups}>
                <Icon name='trash' />
              </Button>
            </div>}
          </div>}

          {isLookupMappingFormVisible() && <LookupsMappingForm
            lookupKey={lookupKey}
            onLookupKeyChange={onLookupKeyChange}
            lookupValue={lookupValue}
            onLookupValueChange={onLookupValueChange}
            onAddLookupsMapping={selectedTableColumnLookups === 'customDataFields' ? onAddFileColumnLookupsMapping : onAddTableColumnLookupsMapping} />}

          {isLookupMappingTableVisible() && <LookupsMappingTable
            lookups={lookups}
            selectedTableColumnLookups={selectedTableColumnLookups}
            selectedFileColumnLookup={selectedFileColumnLookup}
            onRemoveLookupMapping={onRemoveLookupMapping} />}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>Cancel</Button>
          <Button onClick={onSave} positive icon='checkmark' labelPosition='right' content='Save' />
        </Modal.Actions>
      </Modal>
    </div>
  )
};

export default LookupsModal;