export const orderPhotosByType = (workOrder) => {
  let oldMeterPhotoIndex = null;
  let newMeterPhotoIndex = null;
  workOrder.photos.items.forEach((image, index) => {
    if (image.photoType === 'OldMeterPhoto') {
      oldMeterPhotoIndex = index;
    }
    if (image.photoType === 'NewMeterPhoto') {
      newMeterPhotoIndex = index;
    }
  });
  if (oldMeterPhotoIndex) {
    let tempImage = workOrder.photos.items[0];
    workOrder.photos.items[0] = workOrder.photos.items[oldMeterPhotoIndex];
    workOrder.photos.items[oldMeterPhotoIndex] = tempImage;
  } 
  if (newMeterPhotoIndex) {
    let tempImage = workOrder.photos.items[1];
    workOrder.photos.items[1] = workOrder.photos.items[newMeterPhotoIndex];
    workOrder.photos.items[newMeterPhotoIndex] = tempImage;
  }
  return workOrder;
}