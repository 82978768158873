import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../core/context/app.context';
import routeMaps from '../../core/route-maps';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import TopMenu from '../../shared/components/top-menu';
import INVENTORY_ROUTE_TAB_LABELS from '../../constants/inventoryRouteTabLabels';
import { listInventoryNonSerializedAssets, updateNonSerializedAssetQuantity } from '../../core/services/inventory.service';
import Loader from '../../shared/components/loader';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Button } from 'semantic-ui-react';
import QuantityModal from './quantityModal';
import ROLES from '../../constants/roles';
import './styles.css';
import { isEnabled } from '../../core/services/auth.service';

const CustomCell = ({ dataItem, onClick }) => (
  <td>
    <Button basic icon='add square' onClick={() => onClick(dataItem)} />
  </td>
)

const NonSerializedAssetsRoute = ({ history }) => {
  const modalRef = useRef();
  const context = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { currentProject } = context;
      if (currentProject && currentProject.id) {
        await getData(currentProject.id);
        setLoading(false);
      }
    }
    init();
  }, []);

  const getData = async (projectId) => {
    const results = await listInventoryNonSerializedAssets(projectId);
    setData(results);
  }

  const renderTopMenu = () => {
    const { currentProject } = context;
    const nonSerializedInventory = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory ? currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory : false;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - ${INVENTORY_ROUTE_TAB_LABELS.NonSerializedAssets}` : `${INVENTORY_ROUTE_TAB_LABELS.NonSerializedAssets}`}`,
        iconName: 'flag'
      },
      tabs: nonSerializedInventory ? [
        {
          active: false,
          onClick: () => history.push(routeMaps.serializedAssets),
          iconName: '',
          title: INVENTORY_ROUTE_TAB_LABELS.SerializedAssets,
        },
        {
          active: true,
          onClick: () => history.push(routeMaps.nonSerializedAssets),
          iconName: '',
          title: INVENTORY_ROUTE_TAB_LABELS.NonSerializedAssets,
        }
      ] : []
    };
    return <TopMenu config={topMenuConfig} />;
  }

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setQuantity('');
  }

  const updateQuantity = async () => {
    try {
      const { currentProject, user } = context;
      setUpdating(true);
      const { id, projectId } = selectedItem;
      await updateNonSerializedAssetQuantity({ id, projectId, quantity: +quantity }, user.id);
      await getData(currentProject.id);
      setUpdating(false);
      setShowModal(false);
      setSelectedItem(null);
      setQuantity('');
    } catch (error) {
      console.log(error);
    }
  }

  const onSelectItem = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  }

  const onQuantityChange = (event, { value }) => {
    setQuantity(value);
  }

  const renderButtonColumn = () => {
    const { user } = context;
    if (user) {
      if (isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin])) {
        return <Column cell={(props) => <CustomCell onClick={onSelectItem} {...props} />} />
      }
    }
    return null;
  }

  return (
    <ProjectRouteWrapper>
      {showModal && <QuantityModal
        onQuantityChange={onQuantityChange}
        quantity={quantity}
        showModal={showModal}
        loading={updating}
        modalRef={modalRef}
        closeModal={onCloseModal}
        onSubmit={updateQuantity} />}
      <Loader loading={loading} />
      {renderTopMenu()}
      <Grid
        onClick={e => e.preventDefault()}
        className='inventory-non-serialized-assets-listview-grid'
        style={{ height: '92%' }}
        data={data}
      >
        <GridNoRecords>
          No data
        </GridNoRecords>
        <Column field='assetType' title='Type' width='150px' />
        <Column field='assetKey' title='Field' width='150px' />
        <Column field='assetName' title='Value' width='150px' />
        <Column field='assetQuantity' title='Quantity' width='120px' />
        <Column field='assetTotalQuantity' title='Total Quantity' width='150px' />
        {renderButtonColumn()}
      </Grid>
    </ProjectRouteWrapper>
  )
}

export default NonSerializedAssetsRoute;