import React, { Component } from 'react';
import AppContext from '../../../../core/context/app.context';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { Sparkline } from '@progress/kendo-react-charts';
import { Checkbox, Loader } from 'semantic-ui-react';
import { Splitter } from '@progress/kendo-react-layout';
import { BlackOutsWeekView } from '../blackouts';
import ROLES from '../../../../constants/roles';
import { isEnabled } from '../../../../core/services/auth.service';
import { updateProjectPlanningRouteCodeOrder } from '../../../../core/services/project.service';
import './styles.css';

class Chart extends React.Component {
  render() {
    const { dataItem } = this.props;
    const { Open, Assigned, InProgress, InReview, Escalated, Completed, Closed, RTU, Deleted, RouteTotal } = dataItem;
    const chartData = [Open, Assigned, InProgress, InReview, Escalated, Completed, Closed];
    const style = {};
    if (Closed >= parseFloat((90 / 100) * (RouteTotal - RTU - Deleted)) && Closed < (RouteTotal - RTU - Deleted)) {
      style.backgroundColor = '#fff4bf';
    } else if (Closed === (RouteTotal - RTU - Deleted)) {
      style.backgroundColor = '#edf0f7';
    }
    return (
      <td style={style}>
        <Sparkline data={chartData} type='area' /><span style={{ paddingLeft: '10px' }}>{parseInt((Closed / (RouteTotal - RTU - Deleted)) * 100)}%</span>
      </td>
    );
  }
}

class StatusByRouteTableCell extends React.Component {
  render() {
    const { dataItem, field } = this.props;
    const style = {};
    const { Closed, RTU, Deleted, RouteTotal } = dataItem;
    if (Closed >= parseInt((90 / 100) * (RouteTotal - RTU - Deleted)) && Closed < (RouteTotal - RTU - Deleted)) {
      style.backgroundColor = '#fff4bf';
    } if (Closed === (RouteTotal - RTU - Deleted)) {
      style.backgroundColor = '#edf0f7';
    }
    return (
      <td style={style}>
        {dataItem[field]}
      </td>
    );
  }
}

class CustomCell extends React.Component {

  getCellColor = (availableRowColumnValue, value) => {
    const style = {};
    if (availableRowColumnValue >= value && value !== 0) {
      style.backgroundColor = '#EEFFD2';
    }
    if (availableRowColumnValue < value) {
      style.backgroundColor = '#FFD2B9';
    }
    if (value === 0) {
      style.backgroundColor = 'rgba(0,0,0,0.07)';
    }
    return style;
  }

  render() {
    const { dataItem, dataIndex, field, columnIndex, showRoutePlanning, selectRow, selectedRows, data, user } = this.props;
    const selected = selectedRows.indexOf(dataIndex) > -1;
    let style = {};

    const columnOffset = showRoutePlanning || !isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]) ? 1 : 2;

    if (dataIndex === 0) {
      style.backgroundColor = 'rgb(184,184,184)';
    }
    if (showRoutePlanning) {
      if (selected && columnIndex !== columnOffset && field !== 'Meter Remaining Total' && field !== 'Register Remaining Total') {
        let total = 0;
        for (let i = 0; i <= selectedRows.indexOf(dataIndex); i++) {
          total += data[selectedRows[i]][field];
        }
        style = this.getCellColor(RouteStatus.firstRow[field], total);
      }
    } else {
      if (dataIndex !== 0 && columnIndex !== columnOffset && field !== 'Meter Remaining Total' && field !== 'Register Remaining Total') {
        style = this.getCellColor(RouteStatus.firstRow[field], dataItem[field]);
      }
    }
    return (
      <td style={style}>
        {dataItem[field]}{columnIndex === columnOffset && showRoutePlanning && dataIndex !== 0 ? <Checkbox checked={selected} onChange={() => selectRow(dataIndex)} style={{ paddingLeft: '15px' }} /> : null}
      </td>
    );
  }
}

const WorkOrderStatusByRoute = ({ workOrderStatusByRoute }) => (
  <div style={{ paddingTop: '20px' }}>
    <Grid data={workOrderStatusByRoute}>
      <GridToolbar>
        <b className='workorder-status-by-route-table-title'>Work Order Status by Route</b>
      </GridToolbar>
      <GridNoRecords>
        No data
      </GridNoRecords>
      <Column field='routeCode' title='Route' width='150px' cell={StatusByRouteTableCell} />
      <Column field='chart' title=' ' cell={Chart} width='150px' />
      <Column field='Open' title='Open' width='100px' cell={StatusByRouteTableCell} />
      <Column field='Assigned' title='Assigned' width='100px' cell={StatusByRouteTableCell} />
      <Column field='InProgress' title='In Progress' width='120px' cell={StatusByRouteTableCell} />
      <Column field='InReview' title='In Review' width='120px' cell={StatusByRouteTableCell} />
      <Column field='Escalated' title='Escalated' width='110px' cell={StatusByRouteTableCell} />
      <Column field='Completed' title='Completed' width='120px' cell={StatusByRouteTableCell} />
      <Column field='Closed' title='Closed' width='100px' cell={StatusByRouteTableCell} />
      <Column field='RTU' title='RTU' width='100px' cell={StatusByRouteTableCell} />
      <Column field='Deleted' title='Deleted' width='100px' cell={StatusByRouteTableCell} />
      <Column field='RouteTotal' title='Route Total' width='120px' cell={StatusByRouteTableCell} />
    </Grid>
  </div>
);

const InventoryByRoute = ({
  meterSizeByRouteRows,
  selectRow,
  selectedRows,
  showRoutePlanning,
  meterSizeByRouteHasMeter,
  meterSizeByRouteHasRegister,
  meterSizeByRouteHasRadio,
  meterSizeByRouteMeterColumns,
  meterSizeByRouteRegisterColumns,
  showBlackOuts,
  setShowBlackOuts,
  blackoutsSchedulerLoading,
  inventoryByRouteRef,
  user
}) => (
    <div ref={inventoryByRouteRef}>
      <Grid data={meterSizeByRouteRows}>
        <GridToolbar>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <label style={{ paddingRight: '15px', fontSize: '17px', color: 'white' }}><b>Inventory by Route</b></label>
            </div>
            <div>
              <label style={{ paddingRight: '15px', color: 'white' }}>Show Blackouts</label>
              {blackoutsSchedulerLoading ? <Loader active inline size='small' inverted /> : <Checkbox toggle checked={showBlackOuts} onChange={setShowBlackOuts} />}
            </div>
          </div>
        </GridToolbar>
        <GridNoRecords>
          Loading data...
         </GridNoRecords>
        {!showRoutePlanning && isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]) && <Column title="" width="80px" cell={DragCell} />}
        {meterSizeByRouteHasMeter && (<Column title="Meter">
          {meterSizeByRouteMeterColumns.map((column, index) => <Column cell={(props) => <CustomCell user={user} data={meterSizeByRouteRows} selectedRows={selectedRows} selectRow={selectRow} showRoutePlanning={showRoutePlanning} {...props} />} field={column} title={column === 'Meter Remaining Total' ? 'Remaining Total' : column} key={index} />)}
        </Column>)}
        {meterSizeByRouteHasRegister && (<Column title="Register">
          {meterSizeByRouteRegisterColumns.map((column, index) => <Column cell={(props) => <CustomCell user={user} data={meterSizeByRouteRows} selectedRows={selectedRows} selectRow={selectRow} showRoutePlanning={showRoutePlanning} {...props} />} field={column} title={column === 'Meter Remaining Total' ? 'Remaining Total' : column} key={index} />)}
        </Column>)}
        {meterSizeByRouteHasRadio && (<Column title='Radio'>
          <Column cell={(props) => <CustomCell user={user} data={meterSizeByRouteRows} selectedRows={selectedRows} selectRow={selectRow} showRoutePlanning={showRoutePlanning} {...props} />} field='Radio' title='Radio' width='120px' />
        </Column>)}
      </Grid>
    </div>
  )


class DragCell extends React.Component {
  render() {
    return (
      <td onDragOver={(e) => {
        DragCell.reorder(this.props.dataItem);
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      }}>
        {this.props.dataIndex !== 0 && (
          <span
            className='k-icon k-i-reorder'
            draggable='true'
            style={{ cursor: 'move' }}
            onDragEnd={() => DragCell.dragEnd()}
            onDragStart={(e) => {
              DragCell.dragStart(this.props.dataItem);
              e.dataTransfer.setData('dragging', '');
            }}
          />
        )}
      </td>
    );
  }
}

class RouteStatus extends Component {
  _isMounted = false;
  static contextType = AppContext;
  static firstRow = null;
  inventoryByRouteRef = React.createRef();

  constructor(props) {
    super(props);
    DragCell.dragStart = this.dragStart.bind(this);
    DragCell.reorder = this.reorder.bind(this);
    DragCell.dragEnd = this.dragEnd.bind(this);
  }

  state = {
    showRoutePlanning: false,
    selectedRows: [],
    panes: [
      {},
      { size: '50%' }
    ],
    showBlackOuts: false,
    blackoutsSchedulerLoading: false,
    activeItem: null,
    meterSizeByRouteRows: []
  }

  dragEnd = async () => {
    const { meterSizeByRouteRows } = this.state;
    const routeCodes = meterSizeByRouteRows
    .filter(item => item.Route !== 'Available')
    .map(({ Route: route, adjustedDays }) => {
      if (!('adjustedDays' in { Route: route, adjustedDays }) || adjustedDays === undefined) {
        adjustedDays = 0;
      }
      return { route, adjustedDays };
    });
    await this.updateCurrentProjectSequence(routeCodes);
  }

  updateCurrentProjectSequence = async (routeCodes) => {
    const { currentProject, setCurrentProjectSequence,
      getDashboardRoutesData } = this.context;
    await updateProjectPlanningRouteCodeOrder(currentProject.id, routeCodes).then((projectSequence)=>{
        setCurrentProjectSequence(projectSequence);
      }
    ).finally(
      getDashboardRoutesData()
    );
  }

  setBlackoutsSchedulerLoading = (blackoutsSchedulerLoading) => {
    this.setState({ blackoutsSchedulerLoading });
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const { meterSizeByRouteRows } = this.context;
    this.setState({ meterSizeByRouteRows });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectRow = (row) => {
    const { selectedRows } = this.state;
    const rowIndex = selectedRows.indexOf(row);
    rowIndex > -1 ? selectedRows.splice(rowIndex, 1) : selectedRows.push(row);
    this.setState({ selectedRows });
  }

  onChange = (event) => {
    this.setState({
      panes: event.newState
    });
  }

  scrollToRef = () => {
    this.inventoryByRouteRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  setShowBlackOuts = (event) => {
    const { showBlackOuts } = this.state;
    this.setState({ blackoutsSchedulerLoading: true });
    setTimeout(() => {
      this.setState({ showBlackOuts: !showBlackOuts });
    }, 0);
  }

  reorder = (dataItem) => {
    const { activeItem, meterSizeByRouteRows } = this.state;
    if (activeItem === dataItem) {
      return;
    }

    let reorderedData = meterSizeByRouteRows.slice();
    let prevIndex = reorderedData.findIndex(p => (p === activeItem));
    let nextIndex = reorderedData.findIndex(p => (p === dataItem));

    if (nextIndex !== 0) {
      reorderedData.splice(prevIndex, 1);
      reorderedData.splice(nextIndex, 0, activeItem);

      this.setState({
        meterSizeByRouteRows: reorderedData,
        active: activeItem
      });
    }
  }

  dragStart = (dataItem) => {
    this.setState({
      meterSizeByRouteRows: this.state.meterSizeByRouteRows,
      activeItem: dataItem
    });
  }

  renderTables = () => {
    const { showRoutePlanning, selectedRows, showBlackOuts, blackoutsSchedulerLoading, meterSizeByRouteRows } = this.state;
    const {
      workOrderStatusByRoute,
      meterSizeByRouteMeterColumns,
      meterSizeByRouteRegisterColumns,
      meterSizeByRouteHasMeter,
      meterSizeByRouteHasRegister,
      meterSizeByRouteHasRadio,
      user,
      loadingDashboardRoutes
    } = this.context;
    RouteStatus.firstRow = meterSizeByRouteRows[0];
    return (
      <div className='route-status'>
        <InventoryByRoute
          user={user}
          meterSizeByRouteMeterColumns={meterSizeByRouteMeterColumns}
          meterSizeByRouteRegisterColumns={meterSizeByRouteRegisterColumns}
          meterSizeByRouteHasMeter={meterSizeByRouteHasMeter}
          meterSizeByRouteHasRegister={meterSizeByRouteHasRegister}
          meterSizeByRouteHasRadio={meterSizeByRouteHasRadio}
          meterSizeByRouteRows={meterSizeByRouteRows}
          selectedRows={selectedRows}
          showRoutePlanning={showRoutePlanning}
          showBlackOuts={showBlackOuts}
          selectRow={this.selectRow}
          setShowBlackOuts={this.setShowBlackOuts}
          blackoutsSchedulerLoading={blackoutsSchedulerLoading}
          inventoryByRouteRef={this.inventoryByRouteRef} 
          />
          {!loadingDashboardRoutes && <WorkOrderStatusByRoute 
            workOrderStatusByRoute={workOrderStatusByRoute} 
            />
          }
      </div>
    )
  }

  render() {
    const { showBlackOuts } = this.state;
    return (
      <React.Fragment>
        {showBlackOuts ? (
          <Splitter
            style={{ height: '100%' }}
            orientation='vertical'
            panes={this.state.panes}
            onChange={this.onChange}
          >
            <div>
              {this.renderTables()}
            </div>
            {showBlackOuts && <div>
              <BlackOutsWeekView
                blackouts={this.props.blackouts}
                setBlackoutsSchedulerLoading={this.setBlackoutsSchedulerLoading}
                scrollToRef={this.scrollToRef} />
            </div>}
          </Splitter>
        ) : (
            <React.Fragment>
              {this.renderTables()}
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }
}

export default RouteStatus;