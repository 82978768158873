import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import QualityAssuranceExport from '../button/export';
import { Checkbox, Input } from 'semantic-ui-react';
import { APPROVE_WORKORDER_MUTATION } from '../../../../graphql/mutations/workorder';
import CommentModal from '../../../../shared/components/modal/comment';
import { getWorkOrderDetails } from '../../../../core/services/workOrder.service';
import AddPhotoModal from '../modal/addPhotoModal';
import { useTimeZone } from '../../../../helpers/date';
import AppContext from '../../../../core/context/app.context';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import MeterDeployDetailsModal from '../../../../shared/components/workorder-details/meterDeployDetails';
import WaterMeterDeployDetailsModal from '../../../../shared/components/workorder-details/waterMeterDeployDetails';
import WaterDeployDetailsModal from '../../../../shared/components/workorder-details/waterDeployDetails';
import DemoModal from '../../../../shared/components/modal/demoModal';
import { orderBy } from '@progress/kendo-data-query';
import MODAL_TYPES from '../../../../constants/qaModalTypes';

class QualityAssuranceEscalated extends Component {

  static contextType = AppContext;
  _isMounted = false;
  searchRef = React.createRef();

  state = {
    selectedWorkOrder: {},
    showMeterDeployDetails: false,
    showWaterDeployDetails: false,
    showWaterMeterDeployDetails: false,
    selectedWorkOrderIndex: 0,
    showCommentModal: false,
    modalType: '',
    modalDetailsLoading: false,
    workOrders: [],
    photoIndex: 0,
    detailsOpenCount: 0,
    sort: [],
    searchBy: '',
    unfilteredWorkOrders: [],
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  componentWillMount = () => {
    this._isMounted = true;
    const { workOrders, photoType, showAddModal, uploadingNewPhoto } = this.props;
    this.setState({ workOrders, photoType, showAddModal, uploadingNewPhoto, unfilteredWorkOrders: [...workOrders] });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps = (newProps) => {
    const { workOrders, photoType, showAddModal, uploadingNewPhoto } = newProps;
    this.setState({ workOrders, photoType, showAddModal, uploadingNewPhoto, unfilteredWorkOrders: [...workOrders] });
  }

  rowClick = event => {
    const { workOrders } = this.state;
    const selectedWorkOrderIndex = workOrders.findIndex(workOrder => workOrder === event.dataItem);
    const selectedWorkOrder = workOrders[selectedWorkOrderIndex];
    this.getWorkOrderDetails(selectedWorkOrder.id);
    let newState = {};
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { showMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { showWaterMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { showWaterDeployDetails: true };
    }
    this.setState({ ...newState, selectedWorkOrderIndex });
  }

  handleSearch = (event, data) => {
    const searchBy = data.value.toLowerCase();
    this.filterWorkOrders(searchBy, this.state.showExcludedRecords);
  }

  filterWorkOrders = (searchBy, showExcludedRecords) => {
    const { unfilteredWorkOrders } = this.state;
    if (showExcludedRecords) {
      this.setState({
        workOrders: unfilteredWorkOrders
          .filter(workOrder => Object.values(workOrder).join(' ').toLowerCase().indexOf(searchBy) > -1 && workOrder.isRework)
      });
    } else {
      this.setState({
        workOrders: unfilteredWorkOrders
          .filter(workOrder => Object.values(workOrder).join(' ').toLowerCase().indexOf(searchBy) > -1)
      });
    }
  }

  onShowExcludedRecords = (event, { checked }) => {
    this.setState({ showExcludedRecords: checked });
    this.filterWorkOrders(this.searchRef.current.inputRef.current.value, checked);
  }

  closeDetails = () => {
    const {
      getWorkOrdersWithStatusEscalated,
      getWorkOrdersWithStatusCompleted,
      getWorkOrdersWithStatusInReview,
    } = this.props;
    const { refreshTabs, selectedWorkOrder } = this.state;
    let newState = { photoIndex: 0, detailsOpenCount: 0 };
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { ...newState, showMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { ...newState, showWaterMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { ...newState, showWaterDeployDetails: false };
    }
    if (refreshTabs) {
      this.setState({ ...newState, workOrders: [], refreshTabs: false });
      getWorkOrdersWithStatusInReview();
      getWorkOrdersWithStatusEscalated();
      getWorkOrdersWithStatusCompleted();
    } else {
      this.setState(newState);
    }
  }

  onNext = async () => {
    let { selectedWorkOrderIndex, workOrders } = this.state;
    let nextWorkOrder = null;
    if (workOrders.length - 1 < selectedWorkOrderIndex + 1) {
      nextWorkOrder = workOrders[0];
      selectedWorkOrderIndex = 0;
    } else {
      selectedWorkOrderIndex = selectedWorkOrderIndex + 1;
      nextWorkOrder = workOrders[selectedWorkOrderIndex];
    }
    await this.getWorkOrderDetails(nextWorkOrder.id);
    this.setState({ selectedWorkOrderIndex, workOrders });
  }

  approve = async (workOrderId, closeWorkOrderUponApproval) => {
    const { updateWorkOrderStatus } = this.props;
    updateWorkOrderStatus({ query: APPROVE_WORKORDER_MUTATION, workOrderId: workOrderId || null, closeWorkOrderUponApproval });
  }

  approveWithPromise = async (workOrderId, closeWorkOrderUponApproval) => {
    const { updateWorkOrderStatus, getWorkOrdersWithStatusEscalated, getWorkOrdersWithStatusCompleted } = this.props;
    await updateWorkOrderStatus({ query: APPROVE_WORKORDER_MUTATION, workOrderId: workOrderId || null, closeWorkOrderUponApproval });
    getWorkOrdersWithStatusEscalated();
    getWorkOrdersWithStatusCompleted();
  }

  onApprove = (workOrderId, closeWorkOrderUponApproval) => {
    const { selectedWorkOrder } = this.state;
    let newState = {};
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { showMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { showWaterMeterDeployDetails: false };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { showWaterDeployDetails: false };
    }
    this.setState(newState);
    this.approveWithPromise(workOrderId, closeWorkOrderUponApproval);
  }

  handleApproveAndNext = async (workOrderId, closeWorkOrderUponApproval) => {
    this.setState({ refreshTabs: true });
    this.approve(workOrderId, closeWorkOrderUponApproval);
    let { selectedWorkOrderIndex, workOrders } = this.state;
    workOrders.splice(selectedWorkOrderIndex, 1);
    let nextWorkOrder = null;
    if (workOrders.length - 1 < selectedWorkOrderIndex) {
      nextWorkOrder = workOrders[0];
      selectedWorkOrderIndex = 0;
    } else {
      nextWorkOrder = workOrders[selectedWorkOrderIndex];
    }
    await this.getWorkOrderDetails(nextWorkOrder.id);
    this.setState({ selectedWorkOrderIndex, workOrders });
  }

  onReject = () => {
    this.setState({ showCommentModal: true, modalType: MODAL_TYPES.REJECT });
  }

  onCommentSubmit = async (comment) => {
    const { selectedWorkOrder, modalType } = this.state;
    const { getWorkOrdersWithStatusEscalated, reject } = this.props;
    if (modalType === MODAL_TYPES.REJECT) {
      await reject(selectedWorkOrder.id, comment);
      getWorkOrdersWithStatusEscalated();
      this.closeDetails();
    } else {
      this.getWorkOrderDetails(selectedWorkOrder.id);
    }
  }

  onCloseCommentModal = () => {
    this.setState({ showCommentModal: false, modalType: '' });
  }

  getWorkOrderDetails = async (id) => {
    const { currentProject } = this.context;
    if (currentProject) {
      const { formatWorkOrderDetailsPhotos } = this.props;
      this.setState({ modalDetailsLoading: true });
      let workOrder = await getWorkOrderDetails(id);
      workOrder = await formatWorkOrderDetailsPhotos(workOrder);
      workOrder = useTimeZone(workOrder, ['workOrderLastVisitDate', 'workOrderAuditDate'], currentProject.projectTimeZone);
      if (workOrder.workOrderComments && workOrder.workOrderComments.items && workOrder.workOrderComments.items.length > 0) {
        workOrder.workOrderComments.items = useTimeZone(workOrder.workOrderComments.items, ['contentDate'], currentProject.projectTimeZone);
      }
      if (workOrder.workOrderHistorys && workOrder.workOrderHistorys.items && workOrder.workOrderHistorys.items.length > 0) {
        workOrder.workOrderHistorys.items = useTimeZone(workOrder.workOrderHistorys.items, ['historyDate'], currentProject.projectTimeZone);
      }
      this.setState({ modalDetailsLoading: false, selectedWorkOrder: workOrder });
    }
  }

  onGallerySlide = (photoIndex) => {
    this.setState({ photoIndex });
  }

  addPhoto = async (event, workOrderId, oldMeterNumber) => {
    const { addPhoto } = this.props;
    await addPhoto(event, workOrderId, oldMeterNumber);
    this.getWorkOrderDetails(workOrderId);
  }

  replacePhoto = async (event) => {
    this.setState({ modalDetailsLoading: true });
    const { replacePhoto } = this.props;
    const { selectedWorkOrder, photoIndex } = this.state;
    await replacePhoto(event, selectedWorkOrder, photoIndex);
    this.getWorkOrderDetails(selectedWorkOrder.id);
  }

  setModalDetailsLoading = (modalDetailsLoading) => {
    this.setState({ modalDetailsLoading });
  }

  setDetailsOpenCount = (detailsOpenCount) => {
    this.setState({ detailsOpenCount });
  }

  onSortChange = (e) => {
    const { workOrders } = this.state;
    const results = orderBy(workOrders, e.sort);
    this.setState({
      sort: e.sort,
      workOrders: results
    });
  }

  render() {
    const {
      showMeterDeployDetails,
      showWaterMeterDeployDetails,
      selectedWorkOrder,
      showCommentModal,
      modalDetailsLoading,
      photoIndex,
      showWaterDeployDetails,
      detailsOpenCount,
      modalType,
    } = this.state;
    const {
      escalatedLoading,
      showAddPhotoModal,
      onPhotoTypeChange,
      closeAddPhotoModal,
      photoType,
      showAddModal,
      uploadingNewPhoto,
      replaceFileInputRef,
      addFileInputRef,
      user,
      showCustomInputText,
      onCustomTypeChange,
      customPhotoType,
      getWorkOrdersWithStatusEscalated,
      getWorkOrdersWithStatusCompleted,
      qualityAssuranceType,
      isQualityAssurance,
      qualityAssuranceHeaderLabelColor,
      showDemoModal,
      setShowDemoModal,
      onReplacePhotoClick
    } = this.props;
    const { currentProject } = this.context;
    const oldMeterReadingHighThreshold = currentProject.projectConfiguration.oldMeterReadingHighThreshold || null;
    let workOrders = escalatedLoading ? [] : this.state.workOrders;
    const hasRework = workOrders.findIndex(workOrder => workOrder.isRework) > -1;
    return (
      <div className='quality-assurance-escalated'>
        <div className='quality-assurance-tool-bar'>
          <QualityAssuranceExport workOrders={workOrders} />
          {hasRework && <div style={{
            display: 'flex',
            gap: 10,
            paddingLeft: 10,
            paddingRight: 10,
            flexDirection: 'column-reverse'
          }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <span>Show Re-Open No Export</span>
              <div style={{ paddignLeft: 10 }}><Checkbox toggle onClick={this.onShowExcludedRecords} /></div>
            </div>
          </div>}
          {showDemoModal && <DemoModal showModal={showDemoModal} closeModal={() => setShowDemoModal(false)} />}
          {showAddModal && <AddPhotoModal
            showCustomInputText={showCustomInputText}
            onCustomTypeChange={onCustomTypeChange}
            customPhotoType={customPhotoType}
            showModal={showAddModal}
            closeModal={closeAddPhotoModal}
            uploadingNewPhoto={uploadingNewPhoto}
            onSubmit={this.addPhoto}
            photoType={photoType}
            onPhotoTypeChange={onPhotoTypeChange}
            fileInputRef={addFileInputRef}
            selectedWorkOrder={selectedWorkOrder} />}
          {showCommentModal && <CommentModal
            submitWithoutComment={modalType === MODAL_TYPES.REJECT}
            workOrderId={selectedWorkOrder.id}
            userId={user.id}
            showModal={showCommentModal}
            closeModal={this.onCloseCommentModal}
            onSubmit={this.onCommentSubmit} />}
          {showWaterMeterDeployDetails && <WaterMeterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            onReplacePhotoClick={onReplacePhotoClick}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            getWorkOrdersWithStatusCompleted={getWorkOrdersWithStatusCompleted}
            getWorkOrdersWithStatusEscalated={getWorkOrdersWithStatusEscalated}
            getWorkOrderDetails={this.getWorkOrderDetails}
            currentProject={currentProject}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            showModal={showWaterMeterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            workOrders={workOrders}
            handleReject={this.onReject}
            handleApprove={this.onApprove}
            handleApproveAndNext={this.handleApproveAndNext}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onNext={this.onNext}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            fileInputRef={replaceFileInputRef}
            replacePhoto={this.replacePhoto}
            showAddPhotoModal={showAddPhotoModal}
          />}
          {showWaterDeployDetails && <WaterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            onReplacePhotoClick={onReplacePhotoClick}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            getWorkOrdersWithStatusCompleted={getWorkOrdersWithStatusCompleted}
            getWorkOrdersWithStatusEscalated={getWorkOrdersWithStatusEscalated}
            getWorkOrderDetails={this.getWorkOrderDetails}
            currentProject={currentProject}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            showModal={showWaterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            handleApprove={this.onApprove}
            handleApproveAndNext={this.handleApproveAndNext}
            handleReject={this.onReject}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onNext={this.onNext}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            fileInputRef={replaceFileInputRef}
            replacePhoto={this.replacePhoto}
            showAddPhotoModal={showAddPhotoModal} />}
          {showMeterDeployDetails && <MeterDeployDetailsModal
            setModalDetailsLoading={this.setModalDetailsLoading}
            onReplacePhotoClick={onReplacePhotoClick}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            getWorkOrdersWithStatusCompleted={getWorkOrdersWithStatusCompleted}
            getWorkOrdersWithStatusEscalated={getWorkOrdersWithStatusEscalated}
            getWorkOrderDetails={this.getWorkOrderDetails}
            currentProject={currentProject}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={isQualityAssurance}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            fileInputRef={replaceFileInputRef}
            replacePhoto={this.replacePhoto}
            showAddPhotoModal={showAddPhotoModal}
            handleApproveAndNext={this.handleApproveAndNext}
            handleApprove={this.onApprove}
            handleReject={this.onReject}
            showModal={showMeterDeployDetails}
            closeModal={this.closeDetails}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onNext={this.onNext} />}
          <Input ref={this.searchRef} placeholder='Search...' icon='search' fluid style={{ flex: 1 }} onChange={this.handleSearch} />
        </div>
        <Grid
          className='quality-assurance-escalated-grid'
          style={{ height: '90%' }}
          sortable={{
            mode: 'single'
          }}
          sort={this.state.sort}
          onSortChange={this.onSortChange}
          data={workOrders}
          onRowClick={this.rowClick}>
          <Column field='oldMeterNumber' title='Old Meter' width='140px' />
          <Column field='meterStatus' title='Meter Status' width='120px' />
          <Column field='workOrderLastVisitDate' sortable={false} title='Last Visit' width='160px' />
          <Column field='workOrderResourceUser' title='Technician' width='200px' />
          <Column field='districtCode' title='District' width='120px' />
          <Column field='newMeterNumber' title='New Meter' width='160px' />
          <Column field='newMeterAssetNumber' title='Asset' width='160px' />
          <Column field='newRadioNumber' title='New Radio' width='160px' />
          <Column field='meterReadNotes' title='Read Notes' width='200px' />
          <Column field='workOrderAttemptNumber' title='Attempt' width='120px' />
          <Column field='workOrderFlaggedAsCNC' title='CNC' width='120px' />
          <Column field='workOrderFlaggedAs3strike' title='3 Strike' width='120px' />
          <Column field='workOrderFlaggedAsDamaged' title='Damaged' width='130px' />
          <Column field='workOrderFlaggedAsAdHoc' title='Ad Hoc' width='120px' />
          <Column field='workOrderIsInBlackOut' title='Blackout' width='140px' />
        </Grid>
      </div >
    );
  }
}

export default QualityAssuranceEscalated;