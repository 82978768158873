import React, { Component } from 'react';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button, Icon } from 'semantic-ui-react';

class QualityAssuranceExport extends Component {
  _exporter;

  export = () => {
    this._exporter.save();
  }

  render() {
    const { workOrders } = this.props;
    return (
      <React.Fragment>
        <Button onClick={this.export}><Icon name='download' />Export</Button>
        <ExcelExport
          data={workOrders}
          fileName={'qa-workorder-export.xlsx'}
          ref={(exporter) => { this._exporter = exporter; }}
        >
          <ExcelExportColumn field='oldMeterNumber' title='Old Meter' width={125} />
          <ExcelExportColumn field='workOrderLastVisitDate' title='Last Visit' width={150} />
          <ExcelExportColumn field='workOrderAssignments' title='Technician' width={125} />
          <ExcelExportColumn field='newMeterNumber' title='New Meter' width={125} />
          <ExcelExportColumn field='meterFormFactor' title='Form' width={100} />
          <ExcelExportColumn field='meterReadNotes' title='Read Notes' width={200} />
          <ExcelExportColumn field='workOrderAttemptNumber' title='Attempt' width={120} />
          <ExcelExportColumn field='workOrderFlaggedAsCNC' title='CNC' width={120} />
          <ExcelExportColumn field='workOrderFlaggedAs3strike' title='3 Strike' width={120} />
          <ExcelExportColumn field='workOrderFlaggedAsDamaged' title='Damaged' width={120} />
          <ExcelExportColumn field='workOrderFlaggedAsPlated' title='Plated' width={120} />
          <ExcelExportColumn field='workOrderFlaggedAsAdHoc' title='Ad Hoc' width={120} />
          <ExcelExportColumn field='workOrderIsInBlackOut' title='Blackout' width={120} />
          <ExcelExportColumn field='workOrderFlaggedAsEscalated' title='Escalated' width={120} />
        </ExcelExport>
      </React.Fragment>
    );
  }
}

export default QualityAssuranceExport;