import React, { Component } from 'react';
import TopMenu from './components/top-menu';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import AppContext from '../../core/context/app.context';
import { Icon, Button } from 'semantic-ui-react';
import { getUploadUrl, uploadInventory } from '../../core/services/inventory.service';
import {
  Grid,
  GridColumn as Column
} from '@progress/kendo-react-grid';
import config from '../../config';
import { isWaterProject } from '../../helpers/workOrderType';

class InventoryImportAssets extends Component {
  static contextType = AppContext;
  _isMounted = false;
  refresh = null;

  fileInputRef = React.createRef();

  state = {
    uploading: false
  }

  componentDidMount() {
    this._isMounted = true;
    const { listUploadStatusesByProject } = this.context;
    this.refresh = setInterval(listUploadStatusesByProject, 2000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.refresh);
  }

  renderTopMenu = () => {
    const { currentProject, listUploadStatusesByProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Import Assets` : 'Import Assets'}`,
        iconName: 'flag'
      }
    };
    return <TopMenu config={topMenuConfig} refresh={listUploadStatusesByProject} />;
  }

  upload = async (event) => {
    try {
      this.setState({ uploading: true });
      const { currentProject } = this.context;
      const file = event.target.files[0];
      const { name } = file;
      const type = name.split('.')[1];
      const url = await getUploadUrl(currentProject.id, name);
      await uploadInventory(url, file, type);
      this.setState({ uploading: false });
      this.fileInputRef.current.value = '';
    } catch (error) {
      this.setState({ uploading: true });
      console.log(error);
    }
  }

  cellWithBackGround = (props) => {
    let color = '';
    if (props.dataItem.status === 'Pending') {
      color = 'blue';
    } else if (props.dataItem.status === 'Error') {
      color = 'red';
    } else {
      color = 'green';
    }
    return (
      <td style={{ color }}>
        {props.dataItem.status}
      </td>
    );
  };

  downloadTemplate = () => {
    const { currentProject } = this.context;
    if (currentProject && currentProject.projectWorkOrderType && isWaterProject(currentProject.projectWorkOrderType)) {
      window.open(config.waterInventoryImportTemplateUrl);
    } else {
      window.open(config.electricInventoryImportTemplateUrl);
    }
  }

  render() {
    const { uploading } = this.state;
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        <div style={{ paddingBottom: '10px' }}>
          <Button loading={uploading} onClick={() => this.fileInputRef.current.click()}>
            <Icon name='upload' />Upload file
          </Button>
          <Button onClick={this.downloadTemplate}>
            <Icon name='download' />Download .CSV Template
          </Button>
          <input accept='text/xml, text/csv' ref={this.fileInputRef} type='file' hidden onChange={this.upload} />
        </div>
        <AppContext.Consumer>{({ uploadStatuses }) => (
          <React.Fragment>
            <Grid
              className='inventory-import-assets-listview-grid'
              style={{ height: '92%' }}
              data={uploadStatuses}>
              <Column field='createdDate' title='Created' width='150px' />
              <Column field='status' title='Status' width='150px' cell={this.cellWithBackGround} />
              <Column field='message' title='Message' width='600px' />
            </Grid>
          </React.Fragment>
        )}
        </AppContext.Consumer>
      </ProjectRouteWrapper>
    );
  }
}

export default InventoryImportAssets;