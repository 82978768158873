import React, { useContext, useEffect, useState } from 'react';
import { Popup } from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { useTimeZone } from '../../../../helpers/date';
import * as photoService from '../../../../core/services/photo.service';
import AppContext from '../../../../core/context/app.context';
import PW_BADGE_PLACEHOLDER from '../../../../assets/PWBadgePlaceholder.png';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import { MAP_LAYOUT_TYPES } from '../../../../constants/mapLayout';

const PopupRenderer = ({ popup, layoutIconPopup, layoutType }) => {
  const appContext = useContext(AppContext);

  const [image, setImage] = useState('');

  useEffect(() => {
    const getUserAvatar = async () => {
      const avatar = await photoService.getUserAvatar(popup.properties.a);
      setImage(avatar);
    }

    if (popup && popup.properties && popup.properties.a) {
      getUserAvatar();
    }

    if (!popup) {
      setImage('');
    }
  }, [popup]);

  const onBadgeError = (event) => {
    event.target.setAttribute('src', PW_BADGE_PLACEHOLDER);
  }

  const renderPopup = () => {
    const { currentProject } = appContext;
    let {
      li,
      num,
      t,
      asgn,
      grps,
      dc,
      rc,
      sc,
      bcc,
      cc,
      str,
      ws,
      tfn,
      lvd,
      tlvd,
      ms,
      mff,
      ...rest
    } = popup.properties;

    const workOrderType = currentProject.projectWorkOrderType;

    const customDataFields = {...rest};

    if (layoutType === MAP_LAYOUT_TYPES.CustomDataFields) {
      delete customDataFields['id'];
    }

    let locationId = li ? li : '';
    let workOrderNumber = num ? num : '';
    let technician = t ? t : '';
    let workOrderAssignments = asgn ? asgn : '';
    let workOrderGroups = grps ? grps : '';
    let districtCode = dc ? dc : '';
    let routeCode = rc ? rc : '';
    let substationCode = sc ? sc : '';
    let billingCycleCode = bcc ? bcc : '';
    let circuitCode = cc ? cc : '';
    let street = str ? str : '';
    let workOrderStatus = ws ? ws : '';
    let technicianFullName = tfn ? tfn : '';
    let workOrderLastVisitDate = lvd ? lvd : '';
    let technicianWorkOrderLastVisitDate = tlvd ? tlvd : '';
    let meterSize = ms ? ms : '';
    let meterFormFactor = mff ? mff : '';

    workOrderLastVisitDate = workOrderLastVisitDate ? (useTimeZone({ workOrderLastVisitDate }, ['workOrderLastVisitDate'], currentProject.projectTimeZone)).workOrderLastVisitDate : '';

    if (technicianWorkOrderLastVisitDate) {
      technicianWorkOrderLastVisitDate = (useTimeZone({ technicianWorkOrderLastVisitDate }, ['technicianWorkOrderLastVisitDate'], currentProject.projectTimeZone)).technicianWorkOrderLastVisitDate;
    }

    return (
      <Popup coordinates={popup.lngLat}>
        {layoutType === MAP_LAYOUT_TYPES.CustomDataFields ? (
         <>
         {workOrderNumber && <><b>Work Order Number:</b> {workOrderNumber}<br /></>}
         {Object.keys(customDataFields).map((key, index) => <React.Fragment key={index}><b>{key}:</b> {customDataFields[key]}<br /></React.Fragment>)}
         </>
        ): (
          <>
          {technicianWorkOrderLastVisitDate && (
          <>
            {image && <><img onError={onBadgeError} alt='avatar' src={image || PW_BADGE_PLACEHOLDER} width='250px' /><br /></>}
            {technicianFullName && <><b>Technician:</b> {technicianFullName}<br /></>}
            {technicianWorkOrderLastVisitDate && <><b>Last Visit Date:</b> {technicianWorkOrderLastVisitDate}<br /></>}
            <hr />
          </>
        )}
        {workOrderNumber && <><b>Work Order Number:</b> {workOrderNumber}<br /></>}
        {workOrderStatus && <><b>Work Order Status:</b> {workOrderStatus}<br /></>}
        {workOrderLastVisitDate && !technicianWorkOrderLastVisitDate && <><b>Last Visit Date:</b> {workOrderLastVisitDate}<br /></>}
        {locationId && <><b>Location Id:</b> {locationId}<br /></>}
        {street && <><b>Street:</b> {street}<br /></>}
        {technician && !technicianWorkOrderLastVisitDate && <><b>Technician:</b> {technician}<br /></>}
        {workOrderAssignments && <><b>Assignment:</b> {workOrderAssignments.length > 150 ? workOrderAssignments.slice(0, 150) + '...' : workOrderAssignments}<br /></>}
        {workOrderGroups && <><b>Groups:</b> {workOrderGroups}<br /></>}
        {districtCode && <><b>District:</b> {districtCode}<br /></>}
        {routeCode && <><b>Route:</b> {routeCode}<br /></>}
        {substationCode && <><b>Sub:</b> {substationCode}<br /></>}
        {billingCycleCode && <><b>Cycle:</b> {billingCycleCode}<br /></>}
        {circuitCode && <><b>Circuit:</b> {circuitCode}<br /></>}
        {(workOrderType === WORKORDER_TYPES.WaterDeploy || 
        workOrderType === WORKORDER_TYPES.WaterSiteSurvey) && meterSize && <><b>Form/Size:</b> {meterSize}<br /></>}
        {(workOrderType === WORKORDER_TYPES.MeterDeploy || 
        workOrderType === WORKORDER_TYPES.WaterMeterDeploy) && meterFormFactor && <><b>Form/Size:</b> {meterFormFactor}<br /></>}
          </>
        )}
      </Popup>
    )
  }

  return (
    <>
      {popup && !layoutIconPopup && renderPopup()}
    </>
  )
}

const mapStateToProps = ({ map }) => {
  return {
    popup: map.popup,
    layoutIconPopup: map.layoutIconPopup,
    layoutType: map.layoutType
  }
}

export default connect(mapStateToProps, null)(PopupRenderer);