import React from 'react';
import { Modal, Button, Dropdown, Checkbox, Input } from 'semantic-ui-react';

const dropdownTrigger = (workOrderStatus) => (
  <span>
    <b>{workOrderStatus ? `Work Order Status: ${workOrderStatus}` : '(Optional) Select Work Order Status...'}</b>
  </span>
);

const ReOpenClosedWorkOrderConfirmModal = ({
  loading,
  modalRef,
  showModal,
  closeModal,
  onSubmit,
  reOpenWorkOrderStatus,
  setReOpenWorkOrderStatus,
  excludeFromFutureExports,
  onExcludeFromFutureExportsChange,
  onReworkReasonChange,
  onReworkDetailsChange,
  rework,
}) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmit();
    }
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>Re-Open Closed Work Order</Modal.Header>
        <Modal.Content>
          <p><strong>Are you sure you want to re-open the selected work order(s)?</strong></p>
          <p><i>Peak Workflow will determine appropriate status for re-opened item, or you many override below.</i></p>
          <Dropdown selectOnBlur={false} button={true} trigger={dropdownTrigger(reOpenWorkOrderStatus)} disabled={!!excludeFromFutureExports} clearable onChange={setReOpenWorkOrderStatus} options={[
            { key: 0, value: 'InReview', text: 'In Review' },
            { key: 1, value: 'Escalated', text: 'Escalated' },
            { key: 2, value: 'InProgress', text: 'In Progress' }
          ]} />
          <div style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            marginTop: 10
          }}>
            <div style={{ display: 'flex', gap: 10, paddingTop: 10, paddingBottom: 10 }}>
              <span>Exclude from future exports</span>
              <div style={{ paddignLeft: 10 }}><Checkbox toggle onClick={onExcludeFromFutureExportsChange} checked={excludeFromFutureExports} /></div>
            </div>
          </div>
          {excludeFromFutureExports && <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
            <span>Reason: </span><Input placeholder='Reason' value={rework.reworkReason} onChange={onReworkReasonChange} />
            <span>Details: </span> <Input placeholder='Details' value={rework.reworkDetails} onChange={onReworkDetailsChange} />
          </div>}
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={loading} onClick={closeModal} negative>No</Button>
          <Button loading={loading} disabled={loading || (excludeFromFutureExports && (!rework.reworkReason || !rework.reworkDetails))} onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Yes' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default ReOpenClosedWorkOrderConfirmModal;