import React, { Component } from 'react';
import { Segment, Image } from 'semantic-ui-react';
import AppContext from '../../core/context/app.context';
import TopMenu from '../../shared/components/top-menu';
import LOGO from '../../assets/pw-aus-dashboard.png';
import FLOW from '../../assets/pw-workflow-diagram.png';
import { getTechniciansAssignmentsPerDayAndByProjectId, listProjectUserByWorkOrderStatusAndCount } from '../../core/services/user.service';
import DonutChart from './components/chart/donut';
import BarChart from './components/chart/bar';
import TableView from './components/grid';
import CustomMessage from '../../shared/components/messages/message';
import TechniciansTableView from './components/inventory-assignment-count-gird';
import TechniciansAssignmentsForNextFiveDaysTableView from './components/technicians-grid';
import * as workOrderService from '../../core/services/workOrder.service';
import ProductionStatus from './components/production-status';
import RouteStatus from './components/route-status';
import { isWaterProject } from '../../helpers/workOrderType';
import InventoryStatusByMeterTypeTableView from './components/inventory-status-by-meter-type-grid';
import InventoryStatusByRegisterTypeTableView from './components/inventory-status-by-register-type-grid';
import InventoryNonSerializedTableView from './components/inventory-non-serialized-grid';
import InventoryStatusByRadioTypeTableView from './components/inventory-status-by-radio-type-grid';
import { BlackOutsMonthView } from './components/blackouts';
import { getProjectByProjectId, isDemoProject } from '../../core/services/project.service';
import ProgressChart from '../../shared/components/chart/progress';
import TestResultsChart from './components/chart/pie';
import CustomLoader from '../../shared/components/loader';
import routeMaps from '../../core/route-maps';

class DashboardRoute extends Component {

  _isMounted = false;
  static contextType = AppContext;

  state = {
    total: 0,
    dashboardActive: true,
    assignmentsActive: false,
    inventoryActive: false,
    routesActive: false,
    blackOutsActive: false,
    loading: true
  }

  showDashboard = () => {
    this.setState({
      dashboardActive: true,
      assignmentsActive: false,
      inventoryActive: false,
      routesActive: false,
      blackOutsActive: false
    })
  }

  showBlackOuts = () => {
    const { loadingDashboardBlackOuts } = this.context;
    if (!loadingDashboardBlackOuts) {
      this.setState({
        dashboardActive: false,
        assignmentsActive: false,
        inventoryActive: false,
        routesActive: false,
        blackOutsActive: true
      })
    }
  }

  showAssignments = () => {
    const { loadingDashboardAssigments } = this.context;
    if (!loadingDashboardAssigments) {
      this.setState({
        dashboardActive: false,
        assignmentsActive: true,
        inventoryActive: false,
        routesActive: false,
        blackOutsActive: false
      })
    }
  }

  showInventory = () => {
    const { loadingDashboardInventory } = this.context;
    if (!loadingDashboardInventory) {
      this.setState({
        dashboardActive: false,
        assignmentsActive: false,
        inventoryActive: true,
        routesActive: false,
        blackOutsActive: false
      })
    }
  }

  showRoutes = () => {
    const { loadingDashboardRoutes } = this.context;
    if (!loadingDashboardRoutes) {
      this.setState({
        dashboardActive: false,
        assignmentsActive: false,
        inventoryActive: false,
        routesActive: true,
        blackOutsActive: false
      })
    }
  }

  renderTopMenu = () => {
    const { currentProject, loadingDashboardAssigments, loadingDashboardInventory, loadingDashboardRoutes, loadingDashboard } = this.context;
    const { dashboardActive, assignmentsActive, inventoryActive, routesActive, blackOutsActive } = this.state;

    let tabs = [
      {
        active: dashboardActive,
        onClick: this.showDashboard,
        iconName: '',
        title: 'Dashboard',
        loader: {
          loading: loadingDashboard
        }
      },
      {
        active: assignmentsActive,
        onClick: this.showAssignments,
        iconName: '',
        title: 'Assignments',
        loader: {
          loading: loadingDashboardAssigments
        }
      },
      {
        active: inventoryActive,
        onClick: this.showInventory,
        iconName: '',
        title: 'Inventory',
        loader: {
          loading: loadingDashboardInventory
        }
      }
    ];

    if (currentProject && currentProject.projectWorkOrderType && isWaterProject(currentProject.projectWorkOrderType)) {
      tabs.push({
        active: routesActive,
        onClick: this.showRoutes,
        iconName: '',
        title: 'Routes',
        loader: {
          loading: loadingDashboardRoutes
        }
      })
    }

    if (currentProject && currentProject.projectHasBlackOuts) {
      tabs.push({
        active: blackOutsActive,
        onClick: this.showBlackOuts,
        iconName: '',
        title: 'Blackouts',
        loader: {
          loading: loadingDashboardRoutes
        }
      })
    }

    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Dashboard` : 'Dashboard'}`,
        iconName: 'flag'
      },
      tabs: currentProject ? tabs : []
    };
    return <TopMenu config={topMenuConfig} />;
  }

  async componentDidMount() {
    try {
      this._isMounted = true;
      const { currentProject, user, initUser, setWorkOrdersPageListViewActive, setWorkOrdersPageMapViewActive } = this.context;
      const { projectId, workOrderId } = this.props.match.params;
      if (!user) await initUser();
      if (!projectId && !workOrderId) this.setState({ loading: false });
      if (projectId && workOrderId) {
        await this.initProject(projectId);
        setWorkOrdersPageListViewActive(true);
        setWorkOrdersPageMapViewActive(false);
        this.props.history.push({ pathname: routeMaps.workOrders, state: { workOrderId } });
      } else if (currentProject) {
        this.initProject(currentProject.id);
      } 
    } catch (error) {
      console.error(error);
    }
  }

  initProject = async (projectId) => {
    const { setProject } = this.context;
    const project = await getProjectByProjectId(projectId);
    project['projectConfiguration'] = JSON.parse(project.projectConfiguration || '{}');
    project['projectSequence'] = JSON.parse(project.projectSequence || '{}');
    project['projectImportConfig'] = JSON.parse(project.projectImportConfig || '{}');
    setProject(project);
    const { getBlackOuts, getDashboardRoutesData, getDashboardInventoryData, getDashboardProductionStatusData, getActivePlan, updateInvalidLocationCount } = this.context;
    const showSiteTestDashboard = project && project.projectConfiguration && project.projectConfiguration.additionalFeatures && project.projectConfiguration.additionalFeatures.showSiteTestDashboard ? project.projectConfiguration.additionalFeatures.showSiteTestDashboard : false;
    if (showSiteTestDashboard) {
      this.getMeterTestResults(project);
      this.getSiteTestResults(project);
    }
    const showSiteTestDashboardV2 = project && project.projectConfiguration && project.projectConfiguration.additionalFeatures && project.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 ? project.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 : false;
    if (showSiteTestDashboardV2) {
      this.getMeterTestResults(project);
      this.getSiteTestResults(project, 'v2');
    }
    this.getWorkOrdersStatusCounts(project);
    this.getProjectUserByWorkOrderStatusAndCount(project);
    updateInvalidLocationCount();
    getDashboardRoutesData();
    getDashboardInventoryData();
    getBlackOuts(project);
    getDashboardProductionStatusData();
    getActivePlan(project);
  }

  UNSAFE_componentWillReceiveProps() {
    const { currentProject } = this.context;
    if ((currentProject && this.state.routesActive && !isWaterProject(currentProject.projectWorkOrderType)) || (currentProject && !currentProject.projectHasBlackOuts && this.state.blackOutsActive)) {
      this.showDashboard();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getMeterTestResults = async (project) => {
    const { setMeterTestResults } = this.context;
    setMeterTestResults([]);
    const data = await workOrderService.getMeterTestResults(project);
    setMeterTestResults(data.getMeterTestResults);
  }

  getSiteTestResults = async (project, version) => {
    const { setSiteTestResults } = this.context;
    setSiteTestResults([]);
    const data = await workOrderService.getSiteTestResults(project, version);
    setSiteTestResults(data.getSiteTestResults);
  }

  getWorkOrdersStatusCounts = async (project) => {
    const { setWorkOrderStatusCounts } = this.context;
    setWorkOrderStatusCounts([]);
    const data = await workOrderService.getWorkOrdersStatusCounts(project);
    setWorkOrderStatusCounts(data.listWorkOrderStatusCounts);
  }

  getTehnicians = async (project) => {
    const { showBlackOutAssignments, setTechniciansAssignmentsPerDay } = this.context;
    setTechniciansAssignmentsPerDay([]);
    if (showBlackOutAssignments) {
      const techniciansAssignmentsPerDay = await getTechniciansAssignmentsPerDayAndByProjectId(project.id);
      setTechniciansAssignmentsPerDay(techniciansAssignmentsPerDay);
    }
  }

  getProjectUserByWorkOrderStatusAndCount = async (project) => {
    const { setProjectUserByWorkOrderStatusAndCount, setLoadingDashboardAssigments } = this.context;
    setLoadingDashboardAssigments(true);
    const projectUserByWorkOrderStatusAndCount = await listProjectUserByWorkOrderStatusAndCount(project.id);
    setProjectUserByWorkOrderStatusAndCount(projectUserByWorkOrderStatusAndCount);
    setLoadingDashboardAssigments(false);
  }

  getDonutChartData = () => {
    const { workOrderStatusCounts } = this.context;
    if (workOrderStatusCounts) {
      const {
        Assigned,
        Closed,
        Completed,
        Escalated,
        InProgress,
        InReview,
        Open,
        Total
      } = workOrderStatusCounts;
      let listWorkOrderStatusCounts = [];
      listWorkOrderStatusCounts.push({ name: `Open (${Open})`, value: Open });
      listWorkOrderStatusCounts.push({ name: `Assigned (${Assigned})`, value: Assigned });
      listWorkOrderStatusCounts.push({ name: `InProgress (${InProgress})`, value: InProgress });
      listWorkOrderStatusCounts.push({ name: `InReview (${InReview})`, value: InReview });
      listWorkOrderStatusCounts.push({ name: `Escalated (${Escalated})`, value: Escalated });
      listWorkOrderStatusCounts.push({ name: `Completed (${Completed})`, value: Completed });
      listWorkOrderStatusCounts.push({ name: `Closed (${Closed})`, value: Closed });
      return { listWorkOrderStatusCounts, total: Total };
    }
  }

  getBarChartData = () => {
    const { workOrderStatusCounts } = this.context;
    const {
      Closed,
      Completed,
      Total
    } = workOrderStatusCounts;
    const done = [Completed + Closed];
    const total = [Total];
    const categories = ['Completed/Closed'];
    return { done, total, categories };
  }

  getTableData = () => {
    const { workOrderStatusCounts } = this.context;
    const {
      Assigned = 0,
      Closed = 0,
      Completed = 0,
      Escalated = 0,
      InProgress = 0,
      InReview = 0,
      Open = 0,
      Total = 0
    } = workOrderStatusCounts;
    const completedAndClosed = Closed + Completed;
    const remaining = Assigned + Escalated + InProgress + InReview + Open;
    const percentComplete = `${Total > 0 ? `${Math.round((completedAndClosed / Total) * 100)}%` : '0%'}`;
    return [{
      total: Total,
      completedAndClosed,
      remaining,
      percentComplete
    }];
  }

  onShowBlackOutAssignmentsChange = async (event, { checked }) => {
    const { setShowBlackOutAssignments, currentProject } = this.context;
    await setShowBlackOutAssignments(checked);
    this.getTehnicians(currentProject);
  }

  onInactiveCheckboxToggleChange = async (event, { checked }) => {
    const { setShowInactiveTechnicians, currentProject } = this.context;
    await setShowInactiveTechnicians(checked);
    this.getTehnicians(currentProject);
  }

  renderInventoryTabContent = () => {
    const {
      currentProject,
      inventoryStatusByMeterType,
      inventoryNonSerializedAssets,
      inventoryStatusByRegisterType,
      inventoryStatusByRadioType,
      assetLocations,
      inventoryStatusDataByAssetLocation
    } = this.context;
    const inventoryDashboardShowSplitLocations = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowSplitLocations ? currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowSplitLocations : false;
    const inventoryDashboardShowWithDeleted = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDeleted ? currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDeleted : false;
    const inventoryDashboardShowWithDualCounts = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDualCounts ? currentProject.projectConfiguration.additionalFeatures.inventoryDashboardShowWithDualCounts : false;
    return (
      <>
        {inventoryStatusByMeterType && inventoryStatusByMeterType.length > 0 ? <InventoryStatusByMeterTypeTableView data={inventoryStatusByMeterType} showDeleted={inventoryDashboardShowWithDeleted} split={inventoryDashboardShowSplitLocations} assetLocations={assetLocations} /> : null}
        {inventoryDashboardShowSplitLocations ? (
          <>
            {assetLocations.map(location => (
              <React.Fragment key={location}>
                {inventoryStatusDataByAssetLocation[location].inventoryStatusByRegisterType.length > 0 ? <InventoryStatusByRegisterTypeTableView data={inventoryStatusDataByAssetLocation[location].inventoryStatusByRegisterType} assetLocation={location} /> : null}
                {inventoryStatusDataByAssetLocation[location].inventoryStatusByRadioType.length > 0 ? <InventoryStatusByRadioTypeTableView data={inventoryStatusDataByAssetLocation[location].inventoryStatusByRadioType} assetLocation={location} /> : null}
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            {inventoryStatusByRegisterType && inventoryStatusByRegisterType.length > 0 ? <InventoryStatusByRegisterTypeTableView data={inventoryStatusByRegisterType} /> : null}
            {inventoryStatusByRadioType && inventoryStatusByRadioType.length > 0 && (inventoryStatusByRadioType[0].totalAssetsAvailable > 0 || inventoryDashboardShowWithDualCounts) ? <InventoryStatusByRadioTypeTableView data={inventoryStatusByRadioType} withDual={inventoryDashboardShowWithDualCounts} /> : null}
          </>
        )}
        {inventoryNonSerializedAssets && inventoryNonSerializedAssets.length > 0 ? <InventoryNonSerializedTableView data={inventoryNonSerializedAssets} /> : null}
      </>
    )
  }

  render() {
    const { dashboardActive, inventoryActive, assignmentsActive, routesActive, blackOutsActive, loading } = this.state;
    const { meterSizeByRouteRows, currentProject, plan } = this.context;
    const projectPlanning = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.projectPlanning;
    const planDashboardData = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.planDashboardData;

    let weeks;
    let planRate;
    let actualTargetRate;
    let targetRate;
    if (plan) {
      weeks = Array.from(Array(plan.planWeeks.length), (_, i) => (i + 1).toString());
      planRate = plan.planWeeks.map(({ planRate }) => planRate);
      actualTargetRate = plan.planWeeks.map(({ actualRate }) => actualRate);
      targetRate = plan.planWeeks.map(({ targetRate }) => targetRate);
    }

    const showSiteTestDashboard = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.showSiteTestDashboard ? currentProject.projectConfiguration.additionalFeatures.showSiteTestDashboard : false;
    const showSiteTestDashboardV2 = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 ? currentProject.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 : false;

    return (
      <Segment basic className='projects'>
        {this.renderTopMenu()}
        <CustomLoader loading={loading} />
        <AppContext.Consumer>{({
          workOrderStatusCounts,
          showBlackOutAssignments,
          showInactiveTechnicians,
          techniciansAssignmentsPerDay,
          projectUserByWorkOrderStatusAndCount,
          blackouts,
          siteTestResults,
          meterTestResults
        }) => (
          <React.Fragment>
            {!currentProject && (
              <React.Fragment>
                <CustomMessage message={'Please select a project...'} />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                  <Image src={LOGO} className='logo' />
                </div>
              </React.Fragment>
            )}
            {inventoryActive && this.renderInventoryTabContent()}
            {assignmentsActive && (
              <React.Fragment>
                {showBlackOutAssignments && currentProject.projectHasBlackOuts ? (
                  <TechniciansAssignmentsForNextFiveDaysTableView
                    onCheckboxToggle={(this.onShowBlackOutAssignmentsChange)}
                    showBlackOutAssignments={showBlackOutAssignments}
                    onInactiveCheckboxToggle={(this.onInactiveCheckboxToggleChange)}
                    showInactiveTechnicians={showInactiveTechnicians}
                    data={techniciansAssignmentsPerDay || []}
                    projectHasBlackOuts={currentProject.projectHasBlackOuts} />

                ) : (
                  <TechniciansTableView
                    onCheckboxToggle={this.onShowBlackOutAssignmentsChange}
                    showBlackOutAssignments={showBlackOutAssignments}
                    onInactiveCheckboxToggle={(this.onInactiveCheckboxToggleChange)}
                    showInactiveTechnicians={showInactiveTechnicians}
                    projectHasBlackOuts={currentProject.projectHasBlackOuts}
                    data={projectUserByWorkOrderStatusAndCount || []} />
                )}
              </React.Fragment>)}
            {routesActive && meterSizeByRouteRows.length > 0 && <RouteStatus blackouts={blackouts} />}
            {blackOutsActive && <BlackOutsMonthView blackouts={blackouts} />}

            {workOrderStatusCounts && dashboardActive && (
              <>
                {showSiteTestDashboard && siteTestResults && meterTestResults && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                    <div style={{ flex: 1, flexBasis: '50%', marginRight: '10px' }}>
                      <TestResultsChart title='Site Test Results' series={[
                        { category: 'Pass', value: siteTestResults['Pass'] },
                        { category: 'Fail', value: siteTestResults['Fail'] },
                        { category: 'No Secondary Current', value: siteTestResults['NoSecondaryCurrent'] },
                        { category: 'Not Requested', value: siteTestResults['NotRequested'] },
                        { category: '(blank)', value: siteTestResults['Blank'] }
                      ]} />
                    </div>
                    <div style={{ flex: 1, flexBasis: '50%' }}>
                      <TestResultsChart title='Meter Test Results' series={[
                        { category: 'Pass', value: meterTestResults['Pass'] },
                        { category: 'Fail', value: meterTestResults['Fail'] },
                        { category: 'Not Requested', value: meterTestResults['NotRequested'] },
                        { category: '(blank)', value: meterTestResults['Blank'] }
                      ]} />
                    </div>
                  </div>
                )}
                {showSiteTestDashboardV2 && siteTestResults && meterTestResults && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                    <div style={{ flex: 1, flexBasis: '50%', marginRight: '10px' }}>
                      <TestResultsChart title='Site Test Results' series={[
                        { category: 'Pass', value: siteTestResults['Pass'] },
                        { category: 'Fail', value: siteTestResults['Fail'] },
                        { category: 'Pass - Admittance', value: siteTestResults['NoSecondaryCurrent'] },
                        { category: 'Passed Non CT Meter', value: siteTestResults['NotRequested'] },
                        { category: '(blank)', value: siteTestResults['Blank'] }
                      ]} />
                    </div>
                    <div style={{ flex: 1, flexBasis: '50%' }}>
                      <TestResultsChart title='Meter Test Results' series={[
                        { category: 'Pass', value: meterTestResults['Pass'] },
                        { category: 'Fail', value: meterTestResults['Fail'] },
                        { category: 'Not Requested', value: meterTestResults['NotRequested'] },
                        { category: '(blank)', value: meterTestResults['Blank'] }
                      ]} />
                    </div>
                  </div>
                )}
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1, flexBasis: '50%', marginRight: '10px' }}>
                      {projectPlanning && plan ? <ProgressChart weeks={weeks} planRate={planRate} actualTargetRate={actualTargetRate} targetRate={targetRate} /> : <BarChart data={this.getBarChartData()} />}
                    </div>
                    <div style={{ flex: 1, flexBasis: '50%' }}>
                      <DonutChart data={this.getDonutChartData()} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1, flexBasis: '50%', marginRight: projectPlanning && plan ? 0 : '10px' }}>
                      <TableView plan={plan} planDashboardData={planDashboardData} projectPlanning={projectPlanning} data={this.getTableData() || []} />
                    </div>
                    {!isDemoProject(currentProject.id) && !projectPlanning && (
                      <div style={{ flex: 1, flexBasis: '50%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                          <Image src={FLOW} className='flow' />
                        </div>
                      </div>
                    )}
                  </div>
                  <ProductionStatus />
                </div></>)}
          </React.Fragment>
        )}</AppContext.Consumer>
      </Segment>
    );
  }
}

export default DashboardRoute;