import client from '../apollo';
import { LIST_ROLES_QUERY } from '../../graphql/queries/role';

export const getAllRoles = async () => {
  try {
    const { data } = await client.query({
      query: LIST_ROLES_QUERY,
      fetchPolicy: 'cache-first'
    });
    const roles = data.listRoles;
    return roles && roles.items && roles.items.length > 0 ? roles.items : [];
  } catch (error) {
    console.log(error);
  }
}