import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { updateProject } from '../../../../core/services/project.service';
import SuccessMessage from '../../../../shared/components/messages/message';
import ErrorMessage from '../../../../shared/components/messages/error';

const initProjectConfiguration = (project) => {
  try {
    return JSON.parse(project.projectConfiguration);
  } catch (error) {
    console.log('Project configuration init failed');
  }
  return {};
}

const ConfigurationStep = ({ project, onUpdateProjectConfiguration }) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [projectConfiguration, setProjectConfiguration] = useState(() => initProjectConfiguration(project));
  const [successNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);

  useEffect(() => {
    if (successNotification || errorNotification) {
      setTimeout(() => {
        setSuccessNotification(false);
        setErrorNotification(false);
      }, 3000);
    }
  }, [successNotification, errorNotification]);

  const onChange = (value) => {
    setProjectConfiguration(value);
  }

  const onSave = async () => {
    try {
      setLoading(true);
      setSuccessNotification(false);
      setErrorNotification(false);
      const newProjectConfiguration = JSON.stringify(projectConfiguration, null, 0);
      await updateProject({ id: project.id, projectConfiguration: newProjectConfiguration });
      onUpdateProjectConfiguration(newProjectConfiguration);
      context.setTenant(context.currentTenant);
      setSuccessNotification(true);
    } catch (error) {
      setErrorNotification(true);
    }
    setLoading(false);
  }

  return (
    <>
      <div className='json-editor'>
        <Editor
          onChange={onChange}
          mode='text'
          value={projectConfiguration}
        />
      </div>
      {successNotification && <div style={{ marginTop: 10 }}>
        <SuccessMessage header message='Your data has been saved' />
      </div>}
      {errorNotification && <div style={{ marginTop: 10 }}>
        <ErrorMessage header message='Failed to save the data' />
      </div>}
      <div style={{ marginTop: '10px', textAlign: 'right', paddingBottom: '10px' }}>
        <Button loading={loading} onClick={onSave} positive content='Save' />
      </div>
    </>
  )
}

export default ConfigurationStep;