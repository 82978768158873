import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomGanttForm from "./CustomGanttForm";
import Loader from '../../../shared/components/loader';
import { setCurrentColumns, setMasterColumns } from '../../../core/redux/actions/ganttState.actions';
import { getProjectsByTenantGantt, updateProject } from '../../../core/services/project.service';
import { getWorkOrdersStatusCounts } from '../../../core/services/workOrder.service';
import AppContext from '../../../core/context/app.context';
import TopMenu from '../../../shared/components/top-menu';
import RouteWrapper from '../../../hoc/routeWrapper';
import routeMaps from '../../../core/route-maps';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Switch } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import {
    Gantt,
    GanttWeekView,
    GanttMonthView,
    GanttYearView,
    GanttRow,
    orderBy,
    mapTree,
    extendDataItem,
    filterBy,
} from "@progress/kendo-react-gantt";

import './ganntColorCodes.css';

export const PROJECT_STATUSES = [
    'Planning',
    'In Progress',
    'On Hold',
    'Completed'
];

const dependencyModelFields = {
    id: "id",
    fromId: "fromId",
    toId: "toId",
    type: "type",
};

const taskModelFields = {
    id: "id",
    start: "startDate",
    end: "endDate",
    title: "text",
    percentComplete: "progress",
    progress: "progress",
    isRollup: "isRollup",
    isExpanded: "isExpanded",
    isInEdit: "isInEdit",
    children: "children",
};

const getTaskId = getter(taskModelFields.id);

function transformProjectData(projectData) {
    const projectCategoryList = [];
    if (!projectData || projectData.length === 0) {
        return [];
    }
    let dateRange = {
        start: null,
        end: null
    }
    const transformedProjectData = projectData.map((project, index) => {
        const { projectCategory, projectStartDate, projectEndDate, projectStatus } = project;
        if(projectCategory && !projectCategoryList.includes(projectCategory)) projectCategoryList.push(projectCategory);
        const defaultStartDate = new Date();
        const defaultEndDate = new Date();
        defaultStartDate.setDate(defaultStartDate.getDate() + 30);
        defaultEndDate.setDate(defaultStartDate.getDate() + 90);
        const isDefaultDates = (!projectStartDate && !projectEndDate) ? true : false;
        if(index === 0){
            dateRange.start = projectStartDate ? new Date(projectStartDate) : defaultStartDate;
            dateRange.end = projectEndDate ? new Date(projectEndDate) : defaultEndDate;
        } else {
            if(projectStartDate && new Date(projectStartDate) < dateRange.start) dateRange.start = new Date(projectStartDate);
            if(projectEndDate && new Date(projectEndDate) > dateRange.end) dateRange.end = new Date(projectEndDate);
        }
        return {
            progress: 0, 
            percentComplete: 0,
            workOrders: 0,
            id: project.id,
            text: project.projectName,
            isActive: project.isActive,
            startDate: projectStartDate ? new Date(projectStartDate) : defaultStartDate,
            endDate: projectEndDate ? new Date(projectEndDate) : defaultEndDate,
            projectCategory: projectCategory ? projectCategory : 'Other',
            cssClass: project.isActive ? '' : 'gantt-inactive',
            projectStatus: projectStatus ? projectStatus : 'Planning',
            isExpanded: true,
            isDefaultDates: isDefaultDates,
        };
    });
    const groupedProjectData = projectCategoryList.map((category, index) => {
        return {
            id: index + 1,
            text: category,
            isExpanded: true,
            cssClass: 'category-header',
            children: transformedProjectData.filter(({ projectCategory }) => projectCategory === category)
        }
    });
    return { data: groupedProjectData.filter(({children}) => children.length > 0), dateRange: dateRange, expanded: projectCategoryList.map((x, i) => i + 1)};
}





const CategoryHeader = ({ props, toggle }) => {
    const caret = props.dataItem.isExpanded ? 'k-icon k-i-caret-alt-down' : 'k-icon k-i-caret-alt-right'
    return(
        <div style={{padding: '10px 24px', width: 'min-content', position: 'sticky', left: '0', display: 'flex', alignItems: 'center'}}>
            <div onClick={() => toggle(props.dataItem.id)} className={caret}></div>
            <div>{props.dataItem.text}</div>
        </div>
    )
}
const RenderGanttRow = ({ props, toggle }) => {
    let rowCssClass = '';
    if(!props.dataItem.children){
        const statusCss = (!props.dataItem.projectStatus) ? 'planning' : props.dataItem.projectStatus.toLowerCase().replace(/[/| ]/g, '-');
        const isDefaultDates = props.dataItem.isDefaultDates ? 'default-dates ' : '';
        rowCssClass = `${isDefaultDates}${statusCss} ${props.dataItem.cssClass}`;
    }
    const newChildren = props.children.map(i => <td></td>);
    if(props.dataItem.cssClass === 'category-header'){
        return(
            <GanttRow rowHeight={35} onDoubleClick={props.onDoubleClick} children={[<CategoryHeader props={props} toggle={toggle} />, ...newChildren]} level={props.level} levels={props.levels} render={(row) => {
                return {...row, props: {...row.props, className: `${row.props.className} gantt-color-codes ${props.dataItem.cssClass}`}};
            }} />
        )
    }
    return(
        <GanttRow rowHeight={49} onDoubleClick={props.onDoubleClick} children={props.children} level={props.level} levels={props.levels} render={(row) => {
            return {...row, props: {...row.props, className: `${row.props.className} gantt-color-codes ${rowCssClass}`}};
        }} />
    )
};





class AdministrationProjectOverviewRoute extends Component {
    constructor(props) {
        super(props);
        this.ganttRef = React.createRef();
        this.dropDownRef = React.createRef();
    }
    static contextType = AppContext;

    state = {
        ganttView: 'year',
        ganttExpandedState: [],
        ganttKey: 0,
        editItem: null,
        projectData: [],
        taskData: [],
        showInactiveProjects: false,
        loading: true,
        ganttDateRange: {
            start: new Date('1/1/2023'),
            end: new Date('12/31/2024')
        },
        dropDownStyle: {
            display: 'none'
        },
        dataState: {
            sort: [
                {
                    field: "text",
                    dir: 'asc'
                }
            ],
            filter: [],
        }
    };

    componentDidMount = async () => {
        const { history } = this.props;
        const { currentTenant } = this.context;
        if (!currentTenant) {
            history.push(routeMaps.administrationProjects);
        } else {
            this.fetchProjectDataAndCounts();
        }
    };

    fetchProjectDataAndCounts = async () => {
        try {
            this.setState({ loading: true })
            const { currentTenant } = this.context;
            await this.fetchProjectData(currentTenant.id);
            this.fetchProjectDataCounts();
        } catch (error) {
            console.error('Error fetching project data:', error);
        }
    };

    async fetchProjectDataCounts(){
        try {
            const projectDataWithStatusCounts = await Promise.all(
                this.state.taskData.map(async (category) => {
                    return {...category, children: await Promise.all(category.children.map(async (project) => {
                        if (project.id === null || project.id === undefined) {
                            console.error('Project ID is null or undefined:', project);
                            return project;
                        }
                        const {listWorkOrderStatusCounts: statusCounts} = await getWorkOrdersStatusCounts(project);
                        const total = statusCounts && statusCounts.Total ? statusCounts.Total : 0;
                        const percentComplete = total > 0 ? ((statusCounts.Completed || 0) / total) : 0;
                        return {
                            ...project,
                            statusCounts: statusCounts && statusCounts.listWorkOrderStatusCounts ? statusCounts.listWorkOrderStatusCounts : {},
                            progress: percentComplete, 
                            percentComplete: percentComplete * 100,
                            workOrders: total,
                        };
                    }))}
                })
            );
            this.setState({ taskData: projectDataWithStatusCounts, loading: false });
        } catch (error) {
            console.error('Error getting status counts...', error)
        }
    }

    async fetchProjectData(tenantId) {
        let projects = [];
        if (tenantId) {
            projects = await getProjectsByTenantGantt(tenantId);
            this.setState({ projectData: projects });
            const transformedProjectData = transformProjectData(projects);
            this.setState({ taskData: transformedProjectData.data, ganttDateRange: transformedProjectData.dateRange, ganttExpandedState: transformedProjectData.expanded });
        } else {
            this.setState({ projectData: projects });
        };
    };

    componentDidUpdate = ((props, prevState) => {
        if(this.state.ganttView !== prevState.ganttView){
            this.changeViewDaySnap();
        }
    });

    renderTopMenu = () => {
        const { currentTenant } = this.context;
        const topMenuConfig = {
        header: {
            title: `${currentTenant && currentTenant.tenantName ? `${currentTenant.tenantName} - Project Overview` : 'Project Overview'}`,
            iconName: 'flag'
        },
        tabs: [
            {
                active: false,
                onClick: this.fetchProjectDataAndCounts,
                iconName: 'refresh',
                title: 'Refresh',
            }
        ]
        };
        return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
    };

    changeViewDaySnap = () => {
        let todayCell = this.ganttRef.current.element.getElementsByClassName('current-date-cell')[0];
        let scrollElement = this.ganttRef.current.element.getElementsByClassName('k-treelist-scrollable')[0];
        let columnsElement = this.ganttRef.current.element.getElementsByClassName('k-gantt-columns')[0];
        if(!todayCell || !scrollElement || !columnsElement) return;
        const left = todayCell.getBoundingClientRect().left - scrollElement.getBoundingClientRect().left - columnsElement.getBoundingClientRect().left - 20;
        scrollElement.scroll({
            top: 0,
            left,
            behavior: 'instant'
        });
    };

    formatDate = (date) => {
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', });
        const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
        return `${year}-${month}-${day}`;
    };

    onDataStateChange = (event) => {
        this.setState({
            dataState: {
            sort: event.dataState.sort,
            filter: event.dataState.filter,
            },
        });
    };

    onEdit = (event) => {
        if(event.dataItem.children)return this.onExpandChange(event);
        this.setState({ editItem: event.dataItem });
    };

    onFormCancel = () => this.setState({ editItem: null });

    onFormSubmit = async (event) => {
        const { taskData } = this.state;
        const { dataItem } = event;
        try {
            const projectToUpdate = {
                id: dataItem.id,
                projectStartDate: dataItem.startDate ? this.formatDate(dataItem.startDate) : null,
                projectEndDate: dataItem.endDate ? this.formatDate(dataItem.endDate) : null,
                projectStatus: dataItem.projectStatus
            };
            await updateProject(projectToUpdate);
            const ganttDateRange = {
                start: new Date(dataItem.startDate) < new Date(this.state.ganttDateRange.start) ? new Date(dataItem.startDate) : new Date(this.state.ganttDateRange.start),
                end: new Date(dataItem.endDate) < new Date(this.state.ganttDateRange.end) ? new Date(dataItem.endDate) : new Date(this.state.ganttDateRange.end),
            };
            const categoryIndex = taskData.findIndex((item) => item.text === dataItem.projectCategory);
            const dataItemIndex = taskData[categoryIndex].children.findIndex((item) => item.id === dataItem.id);
            const updatedCategoryData = {
                ...taskData[categoryIndex],
                children: [
                    ...taskData[categoryIndex].children.slice(0, dataItemIndex),
                    dataItem,
                    ...taskData[categoryIndex].children.slice(dataItemIndex + 1),
                ]
            }
            const updatedTaskData = [
                ...taskData.slice(0, categoryIndex),
                updatedCategoryData,
                ...taskData.slice(categoryIndex + 1),
            ];
            this.setState({
                taskData: updatedTaskData,
                editItem: null,
                ganttKey: this.state.ganttKey + 1,
                ganttDateRange
            });
            
        } catch (error) {
            console.error("Error updating project:", error);
        };
    };

    customHeaderCell = (props, view) => {
        let range = props.range;
        let today = new Date();
        if(props.rowIndex === 0) return <div>{props.text}</div>
        if (props.type === 'month') {
            if(range.start <= today && range.end >= today){
                return <div className="current-date-cell">*{props.text}</div>
            }
            return <div>{props.text}</div>
        } else if(props.type === 'week'){
            const displayText = props.text.replace(/\w{3}, (\d{1,2}\/\d{1,2}) - \w{3}, (\d{1,2}\/\d{1,2})/, '$1');
            if(range.start <= today && range.end >= today){
                return <div className="current-date-cell">*{displayText}</div>
            }
            return <div>{displayText}</div>
        } else if (props.type === 'day'){
            if(range.start <= today && range.end >= today){
                return <div className="current-date-cell">*{props.text}</div>
            }
            return <div>{props.text}</div>;
        } else {
            return <div>{props.text}</div>;
        };
    };

    weekHeaderCell = (props) => {
        return this.customHeaderCell(props, "week");
    };

    monthHeaderCell = (props) => {
        return this.customHeaderCell(props, "month");
    };

    yearHeaderCell = (props) => {
        return this.customHeaderCell(props, "year");
    };

    handleColumnToggle =(e) => {
        // keeps things consistently in the same order.
        const orderedArray = [
            ...e.value.filter(x => x.field === 'startDate'), 
            ...e.value.filter(x => x.field === 'endDate'), 
            ...e.value.filter(x => x.field === 'percentComplete'), 
            ...e.value.filter(x => x.field === 'projectStatus'), 
            ...e.value.filter(x => x.field === 'workOrders'),
        ];
        this.props.setCurrentColumns(orderedArray);
    }

    onExpandChange = (event) => {
        const id = getTaskId(event.dataItem);
        const newExpandedState = event.value
            ? this.state.ganttExpandedState.filter((currentId) => currentId !== id)
            : [...this.state.ganttExpandedState, id];
        this.setState({
            ganttExpandedState: newExpandedState,
        });
    };

    processedData = () => {
        const sort = this.state.dataState.sort;
        const filteredData = filterBy(
            this.state.taskData,
            this.state.dataState.filter,
            taskModelFields.children
        );
        const sortedData = orderBy(filteredData, sort, taskModelFields.children);
        return mapTree(sortedData, taskModelFields.children, (task) =>
            extendDataItem(task, taskModelFields.children, {
                [taskModelFields.isExpanded]: this.state.ganttExpandedState.includes(
                    getTaskId(task)
                ),
            })
        );
    };

    onCategoryToggle = (id) => {
        const catIndex = this.state.ganttExpandedState.findIndex(stateId => stateId === id);
        if(catIndex >= 0)this.setState({ganttExpandedState: this.state.ganttExpandedState.filter(state => state !== id)});
        if(catIndex < 0)this.setState({ganttExpandedState: [...this.state.ganttExpandedState, id]});
    }

    toggleShowInactive = (evt) => {
        this.setState({ showInactiveProjects: evt.value });
    }

    onGanttViewChange = (view) => {
        this.setState({ ganttView: view })
    }

    render() {
        const multiSelect = document.getElementById('gantt-column-selector');
        const columnSelectorParent = multiSelect && multiSelect.offsetParent ? multiSelect.offsetParent.parentElement : null;
        if(columnSelectorParent){
            // Styling the multiselect doesn't work as it does not apply the styles to the parent element. 
            columnSelectorParent.style.width = '60%';
        }

        const data = this.state.showInactiveProjects ? this.processedData() : this.processedData().map(cat => ({
            ...cat,
            children: cat.children.filter( x => x.isActive)
        })).filter(x => x.children.length > 0);

        if (!data || data.length === 0) {
            return null; // or return a loading indicator, or some other placeholder component
        }
        const ganttStyle = {
            width: "100%",
        };

        // if there is only one year in the range, manually adding one will help take up space for styling
        const { start, end } = this.state.ganttDateRange;
        const dateRange = {
            start: new Date(start.getFullYear(), 0, 1),
            end: new Date(end.getFullYear(), 11, 31),
        }
        const startRangeYear = this.state.ganttDateRange.start.getFullYear();
        const endRangeYear = this.state.ganttDateRange.end.getFullYear();
        const dateRangePadding = {
            start: new Date(startRangeYear, 0, 1),
            end: new Date(startRangeYear + 1, 11, 31),
        }
        const rangeForGantt = startRangeYear === endRangeYear ? dateRangePadding : dateRange;
        return (
            <RouteWrapper className="route-wrapper">
                <Loader loading={this.state.loading} message={'This may take a moment...'} />
                {this.renderTopMenu()}
                <div className='gantt-wrapper' style={{ height: '100%', overflow: 'scroll' }}>
                    <div className='k-widget k-toolbar k-gantt-toolbar k-gantt-header custom-gantt-toolbar' id='custom-gantt-toolbar'>
                        <MultiSelect
                            id='gantt-column-selector'
                            dataItemKey="title"
                            data={this.props.columnsStateMaster}
                            value={this.props.columnsStateCurrent}
                            textField='title'
                            onChange={this.handleColumnToggle}
                            placeholder='Show / Hide Columns...'
                        />
                        <span id='gantt-spacer' className={'k-spacer'}></span>
                        <div id='gantt-inactive-toggle' style={{display:'none'}}>
                            <span>Show Inactive</span>
                            <Switch onChange={this.toggleShowInactive} />
                        </div>
                        <span id='gantt-spacer' className={'k-spacer'}></span>
                        <div className="k-button-group" role="group">
                            <button
                                role="button"
                                type="button"
                                onClick={() => this.onGanttViewChange('year')}
                                className={this.state.ganttView === 'year' ? 'k-button k-state-active' : 'k-button'}
                                aria-checked={this.state.ganttView === 'year' ? true : false}
                                tabindex={this.state.ganttView === 'year' ? '0' : '-1'}
                            >
                                Year
                            </button>
                            <button
                                role="button"
                                type="button"
                                onClick={() => this.onGanttViewChange('month')}
                                className={this.state.ganttView === 'month' ? 'k-button k-state-active' : 'k-button'}
                                aria-checked={this.state.ganttView === 'month' ? true : false}
                                tabindex={this.state.ganttView === 'month' ? '0' : '-1'}
                            >
                                Month
                            </button>
                            <button
                                role="button"
                                type="button"
                                onClick={() => this.onGanttViewChange('week')}
                                className={this.state.ganttView === 'week' ? 'k-button k-state-active' : 'k-button'}
                                aria-checked={this.state.ganttView === 'week' ? true : false}
                                tabindex={this.state.ganttView === 'week' ? '0' : '-1'}
                            >
                                Week
                            </button>
                        </div>
                    </div>
                    <Gantt
                        ref={this.ganttRef}
                        key={this.state.ganttKey}
                        style={ganttStyle}
                        taskData={data}
                        taskModelFields={taskModelFields}
                        dependencyData={this.state.dependencyData}
                        dependencyModelFields={dependencyModelFields}
                        columns={this.props.columnsStateColumns}
                        dataState={this.state.dataState}
                        onDataStateChange={this.onDataStateChange}
                        onTaskDoubleClick={this.onEdit}
                        onRowDoubleClick={this.onEdit}
                        row={(props) => <RenderGanttRow props={props} toggle={this.onCategoryToggle} />}
                        onExpandChange={this.onExpandChange}
                        view={this.state.ganttView}
                    >
                        <GanttYearView timelineHeaderCell={this.yearHeaderCell} dateRange={rangeForGantt} />
                        <GanttMonthView timelineHeaderCell={this.monthHeaderCell} dateRange={dateRange} />
                        <GanttWeekView timelineHeaderCell={this.weekHeaderCell} dateRange={dateRange} />
                    </Gantt>
                </div>
                {this.state.editItem && (
                    <CustomGanttForm
                        dataItem={this.state.editItem}
                        onCancel={this.onFormCancel}
                        onClose={this.onFormCancel}
                        onSubmit={this.onFormSubmit}
                    />
                )}
            </RouteWrapper>
        );
    };
};



const mapStateToProps = ({ gantt }) => {
    return {
        columnsStateMaster: gantt.master,
        columnsStateCurrent: gantt.current,
        columnsStateColumns: gantt.columns
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentColumns: (newState) => dispatch(setCurrentColumns(newState)),
        setMasterColumns: (newState) => dispatch(setMasterColumns(newState)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationProjectOverviewRoute);
