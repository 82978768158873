import React from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import ErrorMessage from '../../../../shared/components/messages/error';
import AppContext from '../../../../core/context/app.context';
import { formatError } from '../../../../graphql/custom-errors';

export const MODAL_TYPE = {
  text: 'text',
  dropdown: 'dropdown',
  number: 'number'
}

export default class EditModal extends React.Component {
  _isMounted = false;
  static contextType = AppContext;

  state = {
    inputValue: null,
    workOrderId: null,
    updatingWorkOrder: false,
    error: null
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const { inputValue, workOrderId, workOrderType } = this.props;
    this.setState({ inputValue, workOrderId, workOrderType });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(newProps) {
    const { inputValue, workOrderId, workOrderType } = newProps;
    this.setState({ inputValue, workOrderId, workOrderType });
  }

  onChange = (event, data) => {
    this.setState({ inputValue: data.value });
  }

  closeModal = () => {
    const { closeModal } = this.props;
    this.setState({ inputValue: null, error: null });
    closeModal();
  }

  onSave = async () => {
    const { getWorkOrderDetails, update, closeModal, customDataKey, isCustomData } = this.props;
    const { inputValue, workOrderId } = this.state;
    // CustomDataFields saved with empty values should be saved as null;
    const updatedValue = (!isCustomData && inputValue === '') ? null : inputValue;
    try {
      this.setState({ updatingWorkOrder: true, error: null });
      await update(workOrderId, updatedValue, customDataKey);
      closeModal();
      getWorkOrderDetails(workOrderId);
    } catch (error) {
      this.setState({ error: formatError(error.message), updatingWorkOrder: false });
    }
  }

  handleKeyPress = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      this.onSave();
    }
  }

  render() {
    const { showModal, title, modalType, options } = this.props;
    const { inputValue, updatingWorkOrder, error } = this.state;
    return (
      <div onKeyPress={this.handleKeyPress}>
        <Modal dimmer={{ style: {zIndex: 1002}}} size='tiny' open={showModal}>
          <Modal.Header>Edit - {title}</Modal.Header>
          <Modal.Content>
            {modalType === MODAL_TYPE.text || modalType === MODAL_TYPE.number ? (
              <Form.Input autoFocus onChange={this.onChange} value={inputValue || ''} type={modalType} style={{ width: '100%' }} />
            ) : (
              <Form.Select clearable autoFocus value={inputValue || ''} onChange={this.onChange} style={{ width: '100%' }} options={options.map(i=>Object.assign({}, {key: i, text: i, value: i}))}/>
            )}
            {error && <ErrorMessage message={error} />}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal} negative>Cancel</Button>
            <Button onClick={this.onSave} loading={updatingWorkOrder} positive icon='checkmark' labelPosition='right' content='Save' />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}