import routeMaps from '../route-maps';
import LoginRoute from '../../../routes/login';

export default [
  {
    comp: LoginRoute,
    path: routeMaps.login,
    exact: true,
  },
]
