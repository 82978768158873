import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { TRANSFORMATION_DATE_FORMATS } from '../constants';

const DropdownDatePicker = ({ placeholder, className, onChange, value }) => {
  return (
    <Dropdown
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      value={value}
      selection
      clearable
      options={[
        { key: TRANSFORMATION_DATE_FORMATS['yyyy-mm-dd hh:mm:ss'], value: TRANSFORMATION_DATE_FORMATS['yyyy-mm-dd hh:mm:ss'], text: TRANSFORMATION_DATE_FORMATS['yyyy-mm-dd hh:mm:ss'] },
        { key: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy hh:mm:ss tt'], value: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy hh:mm:ss tt'], text: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy hh:mm:ss tt'] },
        { key: TRANSFORMATION_DATE_FORMATS['m/d/yy hh:mm'], value: TRANSFORMATION_DATE_FORMATS['m/d/yy hh:mm'], text: TRANSFORMATION_DATE_FORMATS['m/d/yy hh:mm'] },
        { key: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy'], value: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy'], text: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy'] },
        { key: TRANSFORMATION_DATE_FORMATS['yymmdd'], value: TRANSFORMATION_DATE_FORMATS['yymmdd'], text: TRANSFORMATION_DATE_FORMATS['yymmdd'] },
        { key: TRANSFORMATION_DATE_FORMATS['yyyymmdd'], value: TRANSFORMATION_DATE_FORMATS['yyyymmdd'], text: TRANSFORMATION_DATE_FORMATS['yyyymmdd'] },
        { key: TRANSFORMATION_DATE_FORMATS['mmddyyyy'], value: TRANSFORMATION_DATE_FORMATS['mmddyyyy'], text: TRANSFORMATION_DATE_FORMATS['mmddyyyy'] },
        { key: TRANSFORMATION_DATE_FORMATS['hhmm'], value: TRANSFORMATION_DATE_FORMATS['hhmm'], text: TRANSFORMATION_DATE_FORMATS['hhmm'] },
        { key: TRANSFORMATION_DATE_FORMATS['hh:mm'], value: TRANSFORMATION_DATE_FORMATS['hh:mm'], text: TRANSFORMATION_DATE_FORMATS['hh:mm'] },
        { key: TRANSFORMATION_DATE_FORMATS['dmyy'], value: TRANSFORMATION_DATE_FORMATS['dmyy'], text: TRANSFORMATION_DATE_FORMATS['dmyy'] },
        { key: TRANSFORMATION_DATE_FORMATS['mdyy'], value: TRANSFORMATION_DATE_FORMATS['mdyy'], text: TRANSFORMATION_DATE_FORMATS['mdyy'] },
        { key: TRANSFORMATION_DATE_FORMATS['yyyy-mm-dd'], value: TRANSFORMATION_DATE_FORMATS['yyyy-mm-dd'], text: TRANSFORMATION_DATE_FORMATS['yyyy-mm-dd'] },
        { key: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy hh:mm'], value: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy hh:mm'], text: TRANSFORMATION_DATE_FORMATS['mm/dd/yyyy hh:mm'] },
        { key: TRANSFORMATION_DATE_FORMATS['yyyymmddhhmm'], value: TRANSFORMATION_DATE_FORMATS['yyyymmddhhmm'], text: TRANSFORMATION_DATE_FORMATS['yyyymmddhhmm'] },
        { key: TRANSFORMATION_DATE_FORMATS['yyyymmddhhmm'], value: TRANSFORMATION_DATE_FORMATS['mm-dd-yyyy'], text: TRANSFORMATION_DATE_FORMATS['mm-dd-yyyy'] },
      ]} />
  )
}

export default DropdownDatePicker;