import client from '../apollo';
import { GET_SNAPSHOT_HISTORY_BY_PROJECT_ID } from '../../graphql/queries/snapshot';
import { CREATE_SNAPSHOT_MUTATION, RESTORE_FROM_SNAPSHOT_MUTATION } from '../../graphql/mutations/snapshot';

export const getSnapshotHistoryByProjectId = async (projectId) => {
  try {
    const { data } = await client.query({
      query: GET_SNAPSHOT_HISTORY_BY_PROJECT_ID,
      fetchPolicy: 'no-cache',
      variables: {
        projectId
      } 
    });
    return data.getSnapshotHistoryByProjectId;
  } catch (error) {
    console.log(error);
  }
}

export const createSnapshot = async (projectId) => {
  try {
    await client.mutate({
      mutation: CREATE_SNAPSHOT_MUTATION,
      variables: {
        projectId
      }
    });
  } catch (error) {
    throw error;
  }
}

export const restoreFromSnapshot = async (projectId) => {
  try {
    await client.mutate({
      mutation: RESTORE_FROM_SNAPSHOT_MUTATION,
      variables: {
        projectId
      }
    });
  } catch (error) {
    throw error;
  }
}