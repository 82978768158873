import React from 'react';
import { Menu, Header, Icon, Button } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';
import ROLES from '../../../../constants/roles';
import { isEnabled } from '../../../../core/services/auth.service';

const TopMenu = ({ user, config, style = {}, currentTenant, onAddProject }) => (
  <div style={{ display: 'flex', marginBottom: '14px', zIndex: 1 }}>
    <Menu stackable style={{ ...style, flex: '1' }}>
      <Menu.Item>
        <AppContext.Consumer>{({ toggleSideMenu }) => (
          <Icon name='list' onClick={toggleSideMenu} style={{ cursor: 'pointer', fontSize: '23px' }} />
        )}</AppContext.Consumer>
      </Menu.Item>
      <Menu.Item>
        <Header><Icon name={config.header.iconName} />{config.header.title}</Header>
      </Menu.Item>
      {currentTenant && user && isEnabled(user.userRoles, [ROLES.SystemAdmin]) ? (
      <Menu.Menu position='right'>
        <Menu.Item fitted>
          <Button onClick={onAddProject} style={{ margin: 0, marginLeft: '10px', marginRight: '10px' }} primary>Create Project</Button>
        </Menu.Item>
      </Menu.Menu>
      ) : null}
    </Menu>
  </div>
)

export default TopMenu;