import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

export const ConfirmAppointmentHoursDialog = ({ onConfirm, onCancel }) => {

    return (
        <Dialog title={'Appointment Out of Operating Hours'} onClose={onCancel}>
            <span>This appointment is not within the specified business hours. Are you sure you want to continue?</span>
            <DialogActionsBar>
                <button className='k-button' onClick={onConfirm}>
                    Yes
                </button>
                <button className='k-button' onClick={onCancel}>
                    No
                </button>
            </DialogActionsBar>
        </Dialog>
    )
}
