import React, { useState } from 'react';
import { isEnabled } from '../../../core/services/auth.service';
import * as commentService from '../../../core/services/comment.service';
import { Comment, Header, Form, Button } from 'semantic-ui-react';
import ROLES from '../../../constants/roles';
import VerifyModal from '../../../routes/quality-assurance/components/modal/verifyModal';

const CommentsTabContent = ({ 
  workOrder, 
  user, 
  updateWorkOrder,
  verifyWorkOrder,
  qaDoubleEntryVerification,
  isQualityAssurance,
  workOrdersVerified,
  getWorkOrderDetails,
  selectedWorkOrder
}) => {
  const [comment, setComment] = useState('');
  const [showVerifyModal, setShowVerifyModal] = useState(true);

  const onCommentChange = (event, { value }) => {
    setComment(value);
  }

  const submitComment = async () => {
    try {
      await commentService.addComment(user.id, workOrder.id, comment);
      // const updatedWorkOrder = {
      //   id: workOrder.id
      // };
      // await updateWorkOrder(updatedWorkOrder);
      await getWorkOrderDetails(workOrder.id);
      setComment('');
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };  

  const updateOldMeterKwhReading = async (id, oldMeterKwhReading) => {
    await updateWorkOrder({ id, oldMeterKwhReading, workOrderReviewedUserId: user.id });
  }

  return (
    <React.Fragment>
      <Comment.Group>
        <Header as='h3' dividing>
          Comments
      </Header>
        {workOrder && workOrder.workOrderComments && workOrder.workOrderComments.items.length > 0 && (
          workOrder.workOrderComments.items.map((comment, index) => (
            <Comment key={index}>
              <Comment.Content>
                <Comment.Author as='a'>{comment.user.userName}</Comment.Author>
                <Comment.Metadata>{comment.contentDate}</Comment.Metadata>
                <Comment.Text>{comment.content}</Comment.Text>
              </Comment.Content>
            </Comment>
          ))
        )}
      
        {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin]) && (
          <>
          {showVerifyModal && qaDoubleEntryVerification && isQualityAssurance && selectedWorkOrder && selectedWorkOrder.id && workOrdersVerified && !workOrdersVerified.includes(selectedWorkOrder.id) && !selectedWorkOrder.workOrderFlaggedAsDamaged && !selectedWorkOrder.workOrderFlaggedAs3strike && <VerifyModal
          addComment
          escalate
          updateWorkOrderStatus
          userId={user.id}
          selectedWorkOrder={selectedWorkOrder}
          photoIndex={0}
          title={'Data Verification'}
          closeModal={setShowVerifyModal}
          showModal={showVerifyModal}
          inputValue={selectedWorkOrder.oldMeterKwhReading}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateOldMeterKwhReading}
          verifyWorkOrder={verifyWorkOrder}
          modalType='text' />}
          <Form reply onSubmit={submitComment}>
            <Form.TextArea onChange={onCommentChange} value={comment} required />
            <Button content='Add Comment' labelPosition='left' icon='edit' primary />
          </Form>
          </>)}
      </Comment.Group>
    </React.Fragment>
  )
}

export default CommentsTabContent;