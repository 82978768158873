import {
    SET_CURRENT_COLUMNS,
    SET_MASTER_COLUMNS
} from '../types/ganttState.types';

export const setCurrentColumns = (state) => {
    return {
        type: SET_CURRENT_COLUMNS,
        payload: state
    }
}

export const setMasterColumns = (state) => {
    return {
        type: SET_MASTER_COLUMNS,
        payload: state
    }
}