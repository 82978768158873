import client from '../apollo';
import { LIST_TENANTS_QUERY } from '../../graphql/queries/tenant';

export const getAllTenants = async () => {
  try {
    const { data } = await client.query({
      query: LIST_TENANTS_QUERY
    });
    const tenants = data.listTenants;
    return tenants && tenants.items && tenants.items.length > 0 ? tenants.items : [];
  } catch (error) {
    console.log(error);
  }
}