import React, { Component } from 'react';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import AppContext from '../../core/context/app.context';
import TopMenu from '../workorders/components/top-menu';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import * as quicksightService from '../../core/services/quicksight.service';
import Loader from '../../shared/components/loader';

class AnalysisChartsViewRoute extends Component {
  static contextType = AppContext;
  _isMounted = false;

  state = {
    loading: false
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    const dashboardContainerHTML = document.getElementById('dashboardContainer');
    if (dashboardContainerHTML) {
      const { currentProject } = this.context;
      const dashboardId = currentProject.projectConfiguration.analysisChartId;
      const dashboardProjectId = currentProject.projectConfiguration.analysisChartProjectId;
      if (dashboardId) {
        const url = await quicksightService.getQuickSightDashboardEmbedUrl(dashboardId);
        let options = {
          url,
          container: dashboardContainerHTML,
          scrolling: "yes",
          locale: "en-US",
          footerPaddingEnabled: true,
          height: "AutoFit"
        };
        if (dashboardProjectId) {
          options.parameters = {
              project: [dashboardProjectId]
            };
        } 
        const dashboard = embedDashboard(options);
        this.setState({ loading: false });
        dashboard.on('error', (error) => console.log(error));
        dashboard.on('load', () => { });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Analysis Charts View` : 'Analysis Charts View'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  renderAnalysisChartsViewRouteContent = () => {
    return (
      <div id='dashboardContainer'></div>
    );
  }

  render() {
    return (
      <ProjectRouteWrapper>
        <Loader loading={this.state.loading} />
        {this.renderTopMenu()}
        {this.renderAnalysisChartsViewRouteContent()}
      </ProjectRouteWrapper>
    );
  }
}

export default AnalysisChartsViewRoute;