import gql from 'graphql-tag';

export const LIST_QUALIFICATIONS_BY_TENANT_QUERY = gql`
  query ListQualificationsByTenant($tenantId: ID!) {
    listQualificationsByTenant(tenantId: $tenantId) {
      items {
        id
        qualificationName
        qualificationDescription
        qualificationDefinition
      }
    }
  }
`;

export const LIST_QUALIFICATION_DEFINITION_VALUES_BY_TENANT_QUERY = gql`
  query ListQualificationDefinitionValuesByTenant($tenantId: ID!, $workOrderType: WorkOrderType!) {
    listQualificationDefinitionValuesByTenant(tenantId: $tenantId, workOrderType: $workOrderType) {
      items
    }
  }
`;

export const LIST_QUALIFICATION_BY_USER_QUERY = gql`
  query ListQualificationByUser($userId: ID!) {
    listQualificationByUser(userId: $userId) {
      items {
        id
        qualificationName
      }
    }
  }
`;