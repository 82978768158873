import React, { useEffect, useState } from 'react';
import {

Chart,
ChartTooltip,
ChartTitle,
ChartLegend,
ChartSeries,
ChartSeriesItem,
ChartCategoryAxis,
ChartCategoryAxisItem
} from '@progress/kendo-react-charts';

const ProgressChart = ({ weeks, planRate, actualTargetRate, targetRate }) => {
  const [step, setStep] = useState(1);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    if (weeks) {
      if (weeks.length > 20 && weeks.length <= 40) {
        setStep(2);
        setSkip(1)
      } else if (weeks.length > 40 && weeks.length <= 60) {
        setStep(3);
        setSkip(2)
      } else if (weeks.length > 60 && weeks.length <= 100) {
        setStep(4);
        setSkip(3)
      } else if (weeks.length > 100 && weeks.length <= 200) {
          setStep(8);
          setSkip(7);
      } else if (weeks.length > 200) {
        setStep(16);
        setSkip(15);
      }
    }
  }, []);

return (
  <Chart seriesColors={['#90C2E5', '#265AA1', '#FFC000']}>
    <ChartTooltip visible={true} />
    <ChartLegend position='bottom' />
    <ChartTitle text='Workflow Progress' />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem crosshair={{ visible: true, tooltip: { visible: true }}} labels={{ step, skip }} categories={weeks} title={{ text: 'Weeks' }} />
    </ChartCategoryAxis>
    <ChartSeries>
      <ChartSeriesItem name='Plan' type='area' data={planRate} />
      <ChartSeriesItem name='Actual' type='area' data={actualTargetRate} />
      {targetRate && <ChartSeriesItem name='Target' type='line' data={targetRate} />}
    </ChartSeries>
  </Chart>
)
}

function arePropsEqual(oldProps, newProps) {
return JSON.stringify(oldProps) === JSON.stringify(newProps);
}

export default React.memo(ProgressChart, arePropsEqual);
