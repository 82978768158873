import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Input, Table } from 'semantic-ui-react';
import { updateProject } from '../../../../core/services/project.service';
import AppContext from '../../../../core/context/app.context';
import { getUserByUsername } from '../../../../core/services/user.service';
import WORKORDER_TYPES, { WORKORDER_TYPES_LABEL } from '../../../../constants/workOrderTypes';
import SuccessMessage from '../../../../shared/components/messages/message';
import ErrorMessage from '../../../../shared/components/messages/error';
import './styles.css';

const overviewFormDataTemplate = {
  id: '',
  isActive: false,
  isDemo: false,
  projectName: '',
  projectNumber: '',
  projectCategory: '',
  projectShortCode: '',
  projectBucket: '',
  projectTimeZone: '',
  projectHasBlackOuts: false,
  latitude: '',
  longitude: '',
  projectWorkOrderType: ''
}

const OverviewStep = ({ project, onSetProject }) => {
  const context = useContext(AppContext);

  const [overviewFormData, setOverviewFormData] = useState({ ...overviewFormDataTemplate });
  const [loading, setLoading] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);

  useEffect(() => {
    if (successNotification || errorNotification) {
      setTimeout(() => {
        setSuccessNotification(false);
        setErrorNotification(false);
      }, 3000);
    }
  }, [successNotification, errorNotification]);

  useEffect(() => {
    const {
      id,
      isActive,
      isDemo,
      projectName,
      projectNumber,
      projectCategory,
      projectShortCode,
      projectBucket,
      projectTimeZone,
      projectHasBlackOuts,
      latitude,
      longitude,
      projectWorkOrderType,
    } = project;

    setOverviewFormData({
      id,
      isActive,
      isDemo,
      projectName,
      projectNumber,
      projectCategory,
      projectShortCode,
      projectBucket,
      projectTimeZone,
      projectHasBlackOuts,
      latitude,
      longitude,
      projectWorkOrderType,
    });
  }, [project]);

  const onIsDemoChange = (event, { checked }) => {
    setOverviewFormData({ ...overviewFormData, isDemo: checked });
  }

  const onIsActiveChange = (event, { checked }) => {
    setOverviewFormData({ ...overviewFormData, isActive: checked });
  }

  const onProjectNameChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, projectName: value });
  }

  const onProjectNumberChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, projectNumber: value });
  }

  const onProjectCategoryChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, projectCategory: value });
  }

  const onShortCodeChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, projectShortCode: value });
  }

  const onTimeZoneChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, projectTimeZone: value });
  }

  const onLongitudeChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, longitude: +value });
  }

  const onLatitudeChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, latitude: +value });
  }

  const onHasBlackOutsChange = (event, { checked }) => {
    setOverviewFormData({ ...overviewFormData, projectHasBlackOuts: checked });
  }

  const onWorkOrderTypeChange = (event, { value }) => {
    setOverviewFormData({ ...overviewFormData, projectWorkOrderType: value });
  }

  const onSubmit = async () => {
    setLoading(true);
    setSuccessNotification(false);
    setErrorNotification(false);
    try {
      if (!overviewFormData.projectName) {
        throw new Error('Project name is required');
      }
      if (!overviewFormData.projectShortCode) {
        throw new Error('Project short code is required');
      }
      if (!overviewFormData.projectTimeZone) {
        throw new Error('Project time zone is required');
      }
      if (!overviewFormData.longitude && overviewFormData.longitude !== 0) {
        throw new Error('Project longitude is required');
      }
      if (!overviewFormData.latitude && overviewFormData.latitude !== 0) {
        throw new Error('Project latitude is required');
      }
      if (!overviewFormData.projectWorkOrderType) {
        throw new Error('Project work order type is required');
      }
      await updateProject({ ...overviewFormData });
      onSetProject({ ...project, ...overviewFormData });
      const { currentTenant, user, setTenant, setUser } = context;
      const newUser = await getUserByUsername(user.userName);
      setUser(newUser);
      setTenant(currentTenant);
      setLoading(false);
      setSuccessNotification(true);
    } catch (error) {
      setLoading(false);
      setErrorNotification(true);
    }
  }

  return (
    <>
      <div style={{ marginTop: '10px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Table celled definition className='overview-step-table'>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Project Name</Table.Cell>
                <Table.Cell><Input fluid type='text' placeholder='Project Name' value={overviewFormData.projectName} onChange={onProjectNameChange} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Bucket</Table.Cell>
                <Table.Cell>
                  <Input fluid type='text' placeholder='pw-clientcode-projectcode' value={overviewFormData.projectBucket} disabled={true} />
                  <i>Bucket name must be unique and must not contain spaces or uppercase letters.</i>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Short Code</Table.Cell>
                <Table.Cell><Input fluid type='text' placeholder='projectcode (All Caps)' value={overviewFormData.projectShortCode} onChange={onShortCodeChange} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Project Number</Table.Cell>
                <Table.Cell><Input fluid type='text' placeholder='Project Number' value={overviewFormData.projectNumber} onChange={onProjectNumberChange} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Project Category</Table.Cell>
                <Table.Cell><Input fluid type='text' placeholder='Project Category' value={overviewFormData.projectCategory} onChange={onProjectCategoryChange} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Time Zone</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    fluid
                    value={overviewFormData.projectTimeZone}
                    placeholder='Time Zone'
                    selection
                    options={[
                      { key: 0, value: 'America/Chicago', text: 'America/Chicago' },
                      { key: 1, value: 'America/Los_Angeles', text: 'America/Los_Angeles' },
                      { key: 2, value: 'America/New_York', text: 'America/New_York' },
                      { key: 3, value: 'America/Denver', text: 'America/Denver' },
                      { key: 4, value: 'Pacific/Honolulu', text: 'Pacific/Honolulu' }
                    ]}
                    onChange={onTimeZoneChange} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Work Order Type</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    fluid
                    value={overviewFormData.projectWorkOrderType}
                    placeholder='Work Order Type'
                    selection
                    options={[
                      { key: 0, value: WORKORDER_TYPES.MeterDeploy, text: WORKORDER_TYPES_LABEL.MeterDeploy },
                      { key: 1, value: WORKORDER_TYPES.WaterDeploy, text: WORKORDER_TYPES_LABEL.WaterDeploy },
                      { key: 2, value: WORKORDER_TYPES.WaterMeterDeploy, text: WORKORDER_TYPES_LABEL.WaterMeterDeploy },
                      { key: 3, value: WORKORDER_TYPES.WaterSiteSurvey, text: WORKORDER_TYPES_LABEL.WaterSiteSurvey }
                    ]}
                    onChange={onWorkOrderTypeChange} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Latitude</Table.Cell>
                <Table.Cell><Input type='number' fluid placeholder='30.3077422' value={overviewFormData.latitude} onChange={onLatitudeChange} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Longitude</Table.Cell>
                <Table.Cell><Input type='number' fluid placeholder='-97.8236121' value={overviewFormData.longitude} onChange={onLongitudeChange} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Has Blackouts</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={overviewFormData.projectHasBlackOuts}
                    onChange={onHasBlackOutsChange}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Is Active</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={overviewFormData.isActive}
                    onChange={onIsActiveChange}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Is Demo</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={overviewFormData.isDemo}
                    onChange={onIsDemoChange}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {successNotification && <div style={{ marginTop: 10 }}>
            <SuccessMessage header message='Your data has been saved' />
          </div>}
          {errorNotification && <div style={{ marginTop: 10 }}>
            <ErrorMessage message='Failed to save the data' />
          </div>}
          <div style={{ textAlign: 'right', paddingBottom: 10, paddingTop: 10 }}>
            <Button onClick={onSubmit} positive content='Save' loading={loading} />
          </div>
        </div>
        <div style={{ flex: 0.5 }}></div>
      </div>
    </>
  )
}

export default OverviewStep;