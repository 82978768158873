import React, { useContext, useState } from 'react';
import { Button, Modal, Input } from 'semantic-ui-react';
import config from '../../../config';
import AppContext from '../../../core/context/app.context';
import ReactMapboxGl, { MapContext } from 'react-mapbox-gl';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { isValidLngLat } from '../../../helpers/map';
import CustomErrorMessage from '../messages/error';

const Map = ReactMapboxGl({ accessToken: config.mapbox.accessToken });
const geocoder = new MapboxGeocoder({
  countries: 'US',
  types: 'address',
  marker: {
    draggable: true
  },
  accessToken: config.mapbox.accessToken,
  mapboxgl: mapboxgl,
  limit: 7
});

let mapRef;
let marker = new mapboxgl.Marker({ draggable: true });

const MapRenderer = React.memo(({ setNewLocation, workOrder, project, setLatitude, setLongitude }) => {

  const getCenter = () => {
    if (workOrder.latitude && workOrder.longitude && isValidLngLat(workOrder.latitude, workOrder.longitude)) {
      return [workOrder.longitude, workOrder.latitude];
    } else if (project && project.latitude && project.longitude) {
      return [project.longitude, project.latitude];
    } else {
      return [-99.9424339, 38.8251558];
    }
  }

  return (
    <Map
      style={config.mapbox.geoCodignStyle}
      center={getCenter()}
      zoom={workOrder.latitude && workOrder.longitude && isValidLngLat(workOrder.latitude, workOrder.longitude) ? [17] : [10]}
      containerStyle={{
        height: '500px'
      }}
    >
      <MapContext.Consumer>
        {(map) => {
          mapRef = map;
          if (workOrder.latitude && workOrder.longitude && isValidLngLat(workOrder.latitude, workOrder.longitude)) {
            marker.setLngLat([workOrder.longitude, workOrder.latitude]).addTo(map);
          }
          marker.on('dragend', () => {
            const lngLat = marker.getLngLat();
            setNewLocation([lngLat.lng, lngLat.lat]);
            setLongitude(lngLat.lng);
            setLatitude(lngLat.lat);
          });
          geocoder.on('result', (e) => {
            geocoder.clear();
            marker.remove();
            setNewLocation(e.result.center);
            const [lng, lat] = e.result.center;
            setLongitude(lng);
            setLatitude(lat);
            marker.setLngLat(e.result.center).addTo(map);
          });
          map.addControl(geocoder, 'top-left');
          if (workOrder && workOrder.street) {
            geocoder.setInput(`${workOrder.street ? `${workOrder.street} ` : ''}${workOrder.stateCode ? `${workOrder.stateCode} ` : ''}${workOrder.zipCode ? `${workOrder.zipCode} ` : ''}`);
          }
        }}
      </MapContext.Consumer>
    </Map>
  )
})


const GeoCodingModal = ({ workOrder, showModal, closeModal, updateWorkOrder, setShowGeoCodingModal }) => {
  const [newLocation, setNewLocation] = useState([]);
  const [latitude, setLatitude] = useState(workOrder.latitude || '');
  const [longitude, setLongitude] = useState(workOrder.longitude || '');
  const [error, setError] = useState(null);
  const context = useContext(AppContext);

  const saveNewWorkOrderLodaction = () => {
    const { user } = context;
    const [longitude, latitude] = newLocation;
    updateWorkOrder({ id: workOrder.id, longitude, latitude, workOrderReviewedUserId: user.id, workOrderIsGeocoded: true });
    setShowGeoCodingModal(false);
  }

  const onFind = () => {
    setError(null);
    if (isValidLngLat(latitude, longitude)) {
      marker.remove();
      marker.setLngLat([longitude, latitude]).addTo(mapRef);
      mapRef.flyTo({
        center: [
          longitude,
          latitude
        ],
      });
      setNewLocation([longitude, latitude]);
    } else {
      setError('Error. Longitude must be between -180 and 180; Latitude must be between -90 and 90')
    }
  }

  const onLatitudeChange = (event, { value }) => {
    setLatitude(value);
  }

  const onLongitudeChange = (event, { value }) => {
    setLongitude(value);
  }

  return (
    <Modal dimmer={{ style: {zIndex: 1002}}} size='large' open={showModal}>
      <Modal.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span>Update Location {`| ${workOrder.workOrderNumber}`}</span>
          </div>
          <div style={{ fontWeight: 'normal' }}>
            <span>{`${workOrder.street}, ` || ''}</span>
            <span>{`${workOrder.city}, ` || ''}</span>
            <span>{`${workOrder.stateCode}, ` || ''}</span>
            <span>{`${workOrder.zipCode}` || ''}</span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <div style={{ display: 'flex', paddingBottom: 10 }}>
          <div style={{ marginRight: 10 }}>
            <span style={{ marginRight: 5 }}>Latitude:</span>
            <Input type='text' placeholder='Latitude' value={latitude} onChange={onLatitudeChange} />
          </div>
          <div style={{ marginRight: 10 }}>
            <span style={{ marginRight: 5 }}>Longitude:</span>
            <Input type='text' placeholder='Longitude' value={longitude} onChange={onLongitudeChange} />
          </div>
          <Button onClick={onFind}>Find</Button>
        </div>
        {error && <CustomErrorMessage message={error} headerStyle={{}} />}
        <MapRenderer
          setLongitude={setLongitude}
          setLatitude={setLatitude}
          setNewLocation={setNewLocation}
          workOrder={workOrder}
          project={context.currentProject} />
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span>After selecting location, click and drag to update manually if needed. Note: Updating location here will override future imports.</span>
          </div>
          <div>
            <Button onClick={closeModal} primary>Close</Button>
            {newLocation.length > 0 && <Button disabled={!isValidLngLat(latitude, longitude)} onClick={saveNewWorkOrderLodaction} positive>Save Location</Button>}
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default GeoCodingModal;