import React, { Component } from 'react';
import TenantRouteWrapper from '../../../hoc/tenantRouteWrapper';
import TopMenu from '../../../shared/components/top-menu';
import Loader from '../../../shared/components/loader';
import * as qualificationService from '../../../core/services/qualification.service';
import AppContext from '../../../core/context/app.context';
import QualificationListView from './components/list-view';
import { Button, Input } from 'semantic-ui-react';
import { orderBy } from '@progress/kendo-data-query';
import QualificationModal from './components/list-view/modal/qualificationModal';
import routeMaps from '../../../core/route-maps';
import ROLES from '../../../constants/roles';
import { isEnabled } from '../../../core/services/auth.service';
import { Redirect } from 'react-router-dom';

class QualificationManagementRoute extends Component {
  _isMounted = false;
  static contextType = AppContext;

  state = {
    qualifications: [],
    loading: true,
    searchBy: '',
    sort: [],
    showQualificationModal: false,
    selectedQualification: null
  }

  componentDidMount() {
    this._isMounted = true;
    this.listQualificationsByTenant();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  listQualificationsByTenant = async () => {
    const { currentTenant } = this.context;
    if (currentTenant) {
      const qualifications = await qualificationService.listQualificationsByTenant(currentTenant.id);
      if (this._isMounted) {
        this.setState({ qualifications, loading: false });
      }
    }
  }

  openQualificationModal = () => {
    this.setState({ showQualificationModal: true });
  }

  closeQualificationModal = () => {
    this.setState({ showQualificationModal: false, selectedQualification: null });
  }

  onSortChange = event => {
    this.setState({ sort: event.sort });
  };

  onSearchChange = (event, data) => {
    const searchBy = data.value.toLowerCase();
    this.setState({ searchBy });
  }

  openQualificationDetails = (event) => {
    this.setState({ selectedQualification: event.dataItem, showQualificationModal: true });
  }

  filterQualifications = (qualifications) => {
    const { searchBy } = this.state;
    return qualifications
      .filter(qualification => Object.values(qualification).join(' ').toLowerCase().indexOf(searchBy) > -1);
  }

  onSaveQualification = async () => {
    this.setState({ loading: true });
    this.closeQualificationModal();
    await this.listQualificationsByTenant();
  }

  renderTopMenu = () => {
    const { currentTenant } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentTenant && currentTenant.tenantName ? `${currentTenant.tenantName} - Qualifications` : 'Qualifications'}`,
        iconName: 'id card'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  renderUserManagementRouteContent = () => {
    const { sort, qualifications, showQualificationModal, selectedQualification } = this.state;
    const { currentTenant } = this.context;
    return (
      <>
        {showQualificationModal && <QualificationModal
          onSave={this.onSaveQualification}
          currentTenant={currentTenant}
          showModal={showQualificationModal}
          closeModal={this.closeQualificationModal}
          selectedQualification={selectedQualification} />}
        <div className='qualifications-listview'>
          <div className='qualifications-listview-tool-bar'>
            <Button onClick={this.openQualificationModal}>Create Qualification</Button>
            <Input placeholder='Search...' icon='search' fluid style={{ flex: 1 }} onChange={this.onSearchChange} />
          </div>
          <QualificationListView
            onRowClick={this.openQualificationDetails}
            onSortChange={this.onSortChange}
            sort={sort}
            qualifications={this.filterQualifications(orderBy(qualifications, sort))} />
        </div>
      </>
    )
  }

  render() {
    return (
      <AppContext.Consumer>{({ user }) => (
        <TenantRouteWrapper>
          {user && user.userRoles && !isEnabled(user.userRoles, [ROLES.SystemAdmin]) && <Redirect to={routeMaps.administrationProjects} />}
          <Loader loading={this.state.loading} />
          {this.renderTopMenu()}
          {this.renderUserManagementRouteContent()}
        </TenantRouteWrapper>
      )}
      </AppContext.Consumer>
    )
  }
}

export default QualificationManagementRoute;