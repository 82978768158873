import client from '../apollo';
import { GET_APPOINTMENT_BY_WORKORDER_QUERY, LIST_APPOINTMENTS_BY_PROJECT_QUERY, LIST_WORKORDERS_DUPLICATE_ADDRES, GET_APPOINTMENT_AVAILABILITY, LIST_APPOINTMENT_AVAILABILITY } from '../../graphql/queries/appointment';
import { UPDATE_APPOINTMENT_MUTATION, CREATE_APPOINTMENT_MUTATION, CREATE_APPOINTMENT_WITH_NOTIFICATION_MUTATION, UPDATE_APPOINTMENT_WITH_NOTIFICATION_MUTATION, DELETE_APPOINTMENT_MUTATION, VALIDATE_APPOINTMENT_MUTATION, CREATE_APPOINTMENT_AVAILABILITY_MUTATION, UPDATE_APPOINTMENT_AVAILABILITY_MUTATION, DELETE_APPOINTMENT_AVAILABILITY_MUTATION } from '../../graphql/mutations/appointment';

export const listAppointmentsByProject = async (projectId, listStartDate, listEndDate, filterDuplicateAddress) => {
  if ((listStartDate === null) || (listStartDate === undefined)) {
    listStartDate = new Date(Date.now() - 2592000000).toISOString();
  }
  if ((listEndDate === null) || (listEndDate === undefined)) {
    listEndDate = new Date(Date.now() + 5184000000).toISOString();
  }
  const addMilliseconds = (date, milliseconds) => {
    const result = new Date(date);
    result.setMilliseconds(result.getMilliseconds() + milliseconds);
    return result;
  };
  try {
    const { data } = await client.query({
      query: LIST_APPOINTMENTS_BY_PROJECT_QUERY,
      variables: {
        projectId,
        listStartDate,
        listEndDate,
        filterDuplicateAddress,
      },
      fetchPolicy: 'no-cache',
    });
    const rawData = data && data.listAppointmentsByProject && data.listAppointmentsByProject.items && data.listAppointmentsByProject.items.length > 0 ? data.listAppointmentsByProject.items : [];
    return rawData.map(dataItem => {
      const { 
        appointmentStartDate,
        appointmentEndDate,
        listTechniciansByAppointment,
        appointmentSequence,
        ...rest
      } = dataItem;
      const result = { 
        appointmentStartDate: new Date(addMilliseconds(appointmentStartDate, appointmentSequence)),
        appointmentEndDate: new Date(appointmentEndDate),
        appointmentSequence: appointmentSequence,
        isAllDay: false,
        ...rest,
      };
      const userIds = [];
      listTechniciansByAppointment.items.forEach(({ id, fullName }) => userIds.push({ id, fullName }));
      result.userIds = userIds;
      return result;
    });
  } catch (error) {
    console.log(error);
  }
}



export const listDuplicateLocationsForWorkOrders = async ( workOrderId ) => {
  try {
    const response = await client.query({
      query: LIST_WORKORDERS_DUPLICATE_ADDRES,
      variables: {
        workOrderId
      },
      fetchPolicy: 'no-cache',
    });
    return response && response.data && response.data.listWorkOrdersDuplicateAddress && response.data.listWorkOrdersDuplicateAddress.items ? response.data.listWorkOrdersDuplicateAddress.items : [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const createAppointment = async (appointment) => {
  try {
    await client.mutate({
      mutation: CREATE_APPOINTMENT_MUTATION,
      variables: {
        ...appointment
      }
    });
  } catch (error) {
    throw error;
  }
}

export const createAppointmentWithNotification = async (appointment) => {
  try {
    await client.mutate({
      mutation: CREATE_APPOINTMENT_WITH_NOTIFICATION_MUTATION,
      variables: {
        ...appointment
      }
    });
  } catch (error) {
    throw error;
  }
}

export const updateAppointment = async (appointment) => {
  try {
    await client.mutate({
      mutation: UPDATE_APPOINTMENT_MUTATION,
      variables: {
        ...appointment
      }
    });
  } catch (error) {
    throw error;
  }
}

export const updateAppointmentWithNotification = async (appointment) => {
  try {
    await client.mutate({
      mutation: UPDATE_APPOINTMENT_WITH_NOTIFICATION_MUTATION,
      variables: {
        ...appointment
      }
    });
  } catch (error) {
    throw error;
  }
}

export const validateAppointment = async (appointment) => {
  try {
    const { data } = await client.mutate({
      mutation: VALIDATE_APPOINTMENT_MUTATION,
      variables: {
        ...appointment
      }
    });
    return data.validateAppointment;
  } catch (error) {
    throw error;
  }
}

export const deleteAppointment = async (appointmentId, projectId, workOrderId) => {
  try {
    await client.mutate({
      mutation: DELETE_APPOINTMENT_MUTATION,
      variables: {
        appointmentId, 
        projectId, 
        workOrderId
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const getAppointmentByWorkOrder = async (projectId, workOrderId) => {
  try {
    const { data } = await client.query({
      query: GET_APPOINTMENT_BY_WORKORDER_QUERY,
      variables: {
        projectId, 
        workOrderId
      },
      fetchPolicy: 'no-cache',
    });
    return data && data.getAppointmentByWorkOrder ? data.getAppointmentByWorkOrder : null;
  } catch (error) {
    console.log(error);
  }
}

export const getAppointmentAvailability = async ({ workOrderId, startDate, endDate }) => {
  try {
    const { data } = await client.query({
      query: GET_APPOINTMENT_AVAILABILITY,
      variables: {
        startDate, 
        endDate, 
        workOrderId
      },
      fetchPolicy: 'no-cache',
    });
    return data && data.getAppointmentAvailability ? data.getAppointmentAvailability : null;
  } catch (error) {
    console.log(error);
  }
}

export const createAppointmentAvailability = async (inputBody) => {
  try {
    const { projectId, region, date, timeslot, capacity } = inputBody;
    const { data } = await client.query({
      query: CREATE_APPOINTMENT_AVAILABILITY_MUTATION,
      variables: {
        projectId,
        region, 
        date, 
        timeslot,
        capacity
      },
      fetchPolicy: 'no-cache',
    });
    return data && data.createAppointmentAvailability ? data.createAppointmentAvailability : null;
  } catch (error) {
    console.log(error);
  }
}

export const updateAppointmentAvailability = async ({ projectId, region, date, timeslot, capacity }) => {
  try {
    const { data } = await client.query({
      query: UPDATE_APPOINTMENT_AVAILABILITY_MUTATION,
      variables: {
        projectId,
        region, 
        date, 
        timeslot,
        capacity
      },
      fetchPolicy: 'no-cache',
    });
    return data && data.updateAppointmentAvailability ? data.updateAppointmentAvailability : null;
  } catch (error) {
    console.log(error);
  }
}

export const deleteAppointmentAvailability = async ({ projectId, region, date, timeslot }) => {
  try {
    const { data } = await client.query({
      query: DELETE_APPOINTMENT_AVAILABILITY_MUTATION,
      variables: {
        projectId,
        region, 
        date, 
        timeslot
      },
      fetchPolicy: 'no-cache',
    });
    return data && data.deleteAppointmentAvailability ? data.deleteAppointmentAvailability : null;
  } catch (error) {
    console.log(error);
  }
}

export const listAppointmentAvailability = async ({ projectId }) => {
  try {
    const { data } = await client.query({
      query: LIST_APPOINTMENT_AVAILABILITY,
      variables: {
        projectId
      },
      fetchPolicy: 'no-cache',
    });
    return data && data.listAppointmentAvailability && data.listAppointmentAvailability.items ? data.listAppointmentAvailability.items : [];
  } catch (error) {
    console.log(error);
  }
}