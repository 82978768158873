import gql from 'graphql-tag';

export const CREATE_APPOINTMENT_MUTATION = gql`
  mutation CreateAppointment(
    $projectId: ID!
    $userIds: [String]!
    $workOrderId: ID!
    $appointmentStartDate: AWSDateTime!
    $appointmentEndDate: AWSDateTime!
    $appointmentNotes: String
    $schedulerUserId: ID
  ) {
    createAppointment(input:{
      projectId: $projectId
      userIds: $userIds
      workOrderId: $workOrderId
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      appointmentNotes: $appointmentNotes
      schedulerUserId: $schedulerUserId
    }) {
      id
    }
  }
`;

export const CREATE_APPOINTMENT_WITH_NOTIFICATION_MUTATION = gql`
  mutation CreateAppointmentWithNotification(
    $projectId: ID!
    $userIds: [String]
    $workOrderId: ID!
    $appointmentStartDate: AWSDateTime!
    $appointmentEndDate: AWSDateTime!
    $appointmentNotes: String
    $schedulerUserId: ID
    $email: String
    $phoneNumber: String
    $contactName: String
  ) {
    createAppointmentWithNotification(input:{
      projectId: $projectId
      userIds: $userIds
      workOrderId: $workOrderId
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      appointmentNotes: $appointmentNotes
      schedulerUserId: $schedulerUserId
      email: $email
      phoneNumber: $phoneNumber
      contactName: $contactName
    }) {
      id
    }
  }
`;

export const UPDATE_APPOINTMENT_MUTATION = gql`
  mutation UpdateAppointment(
    $id: ID!
    $projectId: ID!
    $userIds: [String]!
    $workOrderId: ID!
    $appointmentStartDate: AWSDateTime
    $appointmentEndDate: AWSDateTime
    $appointmentNotes: String
    $schedulerUserId: ID
  ) {
    updateAppointment(input:{
      id: $id
      projectId: $projectId
      userIds: $userIds
      workOrderId: $workOrderId
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      appointmentNotes: $appointmentNotes
      schedulerUserId: $schedulerUserId
    }) {
      id
    }
  }
`;

export const UPDATE_APPOINTMENT_WITH_NOTIFICATION_MUTATION = gql`
  mutation UpdateAppointmentWithNotification(
    $id: ID!
    $projectId: ID!
    $userIds: [String]
    $workOrderId: ID!
    $appointmentStartDate: AWSDateTime
    $appointmentEndDate: AWSDateTime
    $appointmentNotes: String
    $schedulerUserId: ID
    $email: String
    $phone: String
  ) {
    updateAppointmentWithNotification(input:{
      id: $id
      projectId: $projectId
      userIds: $userIds
      workOrderId: $workOrderId
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      appointmentNotes: $appointmentNotes
      schedulerUserId: $schedulerUserId
      email: $email
      phone: $phone
    }) {
      id
    }
  }
`;

export const VALIDATE_APPOINTMENT_MUTATION = gql`
  mutation ValidateAppointment(
    $id: ID
    $projectId: ID!
    $userIds: [String]!
    $workOrderId: ID!
    $appointmentStartDate: AWSDateTime!
    $appointmentEndDate: AWSDateTime!
  ) {
    validateAppointment(input:{
      id: $id
      projectId: $projectId
      userIds: $userIds
      workOrderId: $workOrderId
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
    }) {
      ok
      message
    }
  }
`;

export const DELETE_APPOINTMENT_MUTATION = gql`
  mutation DeleteAppointment($appointmentId: ID!, $workOrderId: ID!, $projectId: ID!) {
    deleteAppointment(input:{
      projectId: $projectId
      workOrderId: $workOrderId
      appointmentId: $appointmentId
    }) {
      id
    }
  }
`;

export const CREATE_APPOINTMENT_AVAILABILITY_MUTATION = gql`
  mutation CreateAppointmentAvailability($capacity: Int!, $projectId: ID!, $date: String!, $region: String!, $timeslot: String!) {
    createAppointmentAvailability(input: {
      region: $region
      date: $date
      projectId: $projectId
      timeslot: $timeslot
      capacity: $capacity
    }) {
      projectId
    }
  }
`;

export const UPDATE_APPOINTMENT_AVAILABILITY_MUTATION = gql`
  mutation UpdateAppointmentAvailability($projectId: ID!, $date: String!, $region: String!, $timeslot: String!, $capacity: Int!) {
    updateAppointmentAvailability(input:{
      region: $region
      date: $date
      projectId: $projectId
      timeslot: $timeslot
      capacity: $capacity
    }) {
      projectId
    }
  }
`;

export const DELETE_APPOINTMENT_AVAILABILITY_MUTATION = gql`
  mutation DeleteAppointmentAvailability($projectId: ID!, $date: String!, $region: String!, $timeslot: String!) {
    deleteAppointmentAvailability(input:{
      region: $region
      date: $date
      projectId: $projectId
      timeslot: $timeslot
    }) {
      projectId
    }
  }
`;