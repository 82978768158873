import React, { useContext, useState, useEffect } from 'react';
import config from '../../../config';
import MAP_DETAILS_ICON from '../../../assets/icons/map-details.svg';
import { Modal, Button, Segment, Header, Message } from 'semantic-ui-react';
import { getDistanceBetweenPoints } from '../../../helpers/map';
import GeoCodingModal from '../modal/geocodingModal';
import ROLES from '../../../constants/roles';
import { isEnabled,getToken } from '../../../core/services/auth.service';
import AppContext from '../../../core/context/app.context';
import './styles.css';
import { isValidLngLat } from '../../../helpers/map';

const PDFViewer = ({ closeModal, showModal, workOrder }) => {
  const [PDF_FILE, SET_PDF_FILE] = useState('');
  const { currentProject } = useContext(AppContext);

  useEffect(() => {
    initProject();
  }, []);

  const fetchPDF = async (pdfObject) => {
    try {
      const token = await getToken();
      const response = await fetch('https://fp91y3nb0a.execute-api.us-east-1.amazonaws.com/dev/convert', {
        method: 'post',
        mode: 'cors', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(pdfObject)
      });
      if (!response.ok) throw new Error('Failed to fetch PDF');
      const blob = await response.blob();
      const fileURL = URL.createObjectURL(blob);
      SET_PDF_FILE(fileURL);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  }

  const initProject = async() => {
    const project = currentProject;
    const showSiteTestDashboard = project && project.projectConfiguration && project.projectConfiguration.additionalFeatures && project.projectConfiguration.additionalFeatures.showSiteTestDashboard ? project.projectConfiguration.additionalFeatures.showSiteTestDashboard : false;
    if (showSiteTestDashboard) {
      const pdfBody = {
        getWorkOrder: workOrder,
        base64_company_seal: "https://prestodirect.com/imagesfff/products/detail/digital_corporate_seal_cs_b.gif",
        base64_company_logo: ''
      }
      // const meterResults = await getMeterTestResults(project);
      // const sitResults = await getSiteTestResults(project);
      // console.log('v3?');
      // console.log(meterResults);
      // console.log(sitResults);
      console.log(workOrder);
      fetchPDF(pdfBody);
    }
    // const showSiteTestDashboardV2 = project && project.projectConfiguration && project.projectConfiguration.additionalFeatures && project.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 ? project.projectConfiguration.additionalFeatures.showSiteTestDashboardV2 : false;
    // if (showSiteTestDashboardV2) {
    //   console.log('v2')
    //   const meterResults = await getMeterTestResults(project);
    //   const sitResults = await getSiteTestResults(project, 'v2');
    //   fetchPDF({ getWorkOrder: workOrder, sitResults, meterResults });
    // }
  }
  // const pdfExportComponent = React.useRef<SiteReport>(null);

  // const exportPDFWithComponent = () => {
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  // };

  return (
    <Modal className='pdf-modal' dimmer={{ style: {zIndex: 1002}}} size='large' open={showModal}>
      <Modal.Content>
        <object className='pdf-viewer' data={PDF_FILE} aria-label=''></object>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} primary>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}



const StaticMapDetails = ({ longitude, latitude, capturedLatitude, capturedLongitude, showModal, closeDetails, capturedGpsPositionSource, capturedGpsHorizontalAccuracy }) => (
  <Modal dimmer={{ style: {zIndex: 1002}}} size='tiny' open={showModal} onClose={closeDetails}>
    <Modal.Header>GPS Information</Modal.Header>
    <Modal.Content>
      <div style={{ width: '100%', justifyContent: 'center' }}>
        {capturedLatitude && capturedLongitude ? (
          <img alt='static-map' src={`https://api.mapbox.com/styles/v1/${config.mapbox.satelliteStyle}/static/pin-l-s+14B98B(${longitude},${latitude}),pin-l-c+c10000(${capturedLongitude},${capturedLatitude})/${longitude},${latitude},18/500x400?access_token=${config.mapbox.accessToken}`}></img>
        ) : (
            <img alt='static-map' src={`https://api.mapbox.com/styles/v1/${config.mapbox.satelliteStyle}/static/pin-l-s+14B98B(${longitude},${latitude})/${longitude},${latitude},18/500x400?access_token=${config.mapbox.accessToken}`}></img>
          )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, marginTop: '10px', marginRight: '5px' }}>
          <Segment raised>
            <Header as='h3'>Source</Header>
            <p>Latitude: {latitude}</p>
            <p>Longitude: {longitude}</p>
          </Segment>
        </div>
        <div style={{ flex: 1, marginTop: '10px', marginLeft: '5px' }}>
          {longitude && latitude && capturedLatitude && capturedLongitude ? (
            <Segment raised>
              <Header as='h3'>Captured</Header>
              <p>Latitude: {capturedLatitude}</p>
              <p>Longitude: {capturedLongitude}</p>
              <p>Device: {capturedGpsPositionSource || '~'}</p>
              <p>Est Horizontal Accuracy: {capturedGpsHorizontalAccuracy + 'm' || '~'}</p>
              <p>Distance Between Points: {getDistanceBetweenPoints(latitude, longitude, capturedLatitude, capturedLongitude) + 'm' || '~'}</p>
            </Segment>
          ) : (
              <Segment raised>
                <Header as='h3'>Captured</Header>
                <p>Latitude:</p>
                <p>Longitude:</p>
                <p>Device:</p>
                <p>Est Horizontal Accuracy:</p>
                <p>Distance Between Points:</p>
              </Segment>
            )}
        </div>
      </div>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={closeDetails} primary>Close</Button>
    </Modal.Actions>
  </Modal>
)



const MapIconWrapper = ({ onClick, children }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <img
      style={{ cursor: 'pointer', position: 'absolute', left: '296px' }}
      src={MAP_DETAILS_ICON}
      alt='workorder-details'
      onClick={onClick}
    />
    {children}
  </div>
)


const GeoCoding = ({ user, setShowGeoCodingModal, setShowPDFModal, showSiteReport, workOrder }) => {
  return (
    <>
      {user && user.userRoles && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.ClientAdmin]) && (
        <div className='geo-coding-buttons'>
          <Button onClick={() => setShowGeoCodingModal(true)}>Update Location</Button>
          {showSiteReport && <Button onClick={() => setShowPDFModal(true)}>Site Report</Button>}
        </div>
      )}
    </>
  );
};


const StaticMap = ({ workOrder, width = 320, height = 100, style, updateWorkOrder }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showGeoCodingModal, setShowGeoCodingModal] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false)

  const { user, currentProject } = useContext(AppContext);
  const showSiteReport = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.showSiteReport ? currentProject.projectConfiguration.additionalFeatures.showSiteReport : false;
  const onDetailsClick = () => {
    setShowDetails(true);
  }

  const { latitude, longitude, capturedLatitude, capturedLongitude, capturedGpsPositionSource, capturedGpsHorizontalAccuracy } = workOrder;

  return (
    <React.Fragment>
      {showPDFModal && <PDFViewer workOrder={workOrder} showModal={showPDFModal} closeModal={() => setShowPDFModal(false)} />}
      {showGeoCodingModal && <GeoCodingModal setShowGeoCodingModal={setShowGeoCodingModal} updateWorkOrder={updateWorkOrder} workOrder={workOrder} showModal={showGeoCodingModal} closeModal={() => setShowGeoCodingModal(false)} />}
      {showDetails && <StaticMapDetails longitude={longitude} latitude={latitude} capturedLatitude={capturedLatitude} capturedLongitude={capturedLongitude} showModal={showDetails} closeDetails={() => setShowDetails(false)} capturedGpsPositionSource={capturedGpsPositionSource} capturedGpsHorizontalAccuracy={capturedGpsHorizontalAccuracy} />}
      {latitude && longitude && capturedLatitude && capturedLongitude && isValidLngLat(latitude, longitude) && isValidLngLat(capturedLatitude, capturedLongitude) ? (
        <MapIconWrapper onClick={onDetailsClick}>
          <img alt='static-map' style={style} src={`https://api.mapbox.com/styles/v1/${config.mapbox.satelliteStyle}/static/pin-l-s+14B98B(${longitude},${latitude}),pin-l-c+c10000(${capturedLongitude},${capturedLatitude})/${longitude},${latitude},16/${width}x${height}?access_token=${config.mapbox.accessToken}`} />
          <GeoCoding setShowGeoCodingModal={setShowGeoCodingModal} setShowPDFModal={setShowPDFModal} user={user} showSiteReport={showSiteReport} />
        </MapIconWrapper>
      ) : workOrder && latitude && longitude && isValidLngLat(latitude, longitude) ? (
        <MapIconWrapper onClick={onDetailsClick}>
          <img alt='static-map' style={style} src={`https://api.mapbox.com/styles/v1/${config.mapbox.satelliteStyle}/static/pin-l-s+14B98B(${longitude},${latitude})/${longitude},${latitude},16/${width}x${height}?access_token=${config.mapbox.accessToken}`} />
          <GeoCoding setShowGeoCodingModal={setShowGeoCodingModal} setShowPDFModal={setShowPDFModal} user={user} showSiteReport={showSiteReport} />
        </MapIconWrapper>
      ) :
          <Message className='google-map-message-container'>
            <Message.Header className='google-map-message-container-header'>
              <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', }}>
                <div className='google-map-message-container-header-message'>
                  <p>Map is not available</p>
                </div>
                <GeoCoding setShowGeoCodingModal={setShowGeoCodingModal} setShowPDFModal={setShowPDFModal} user={user} showSiteReport={showSiteReport} />
              </div>
            </Message.Header>
          </Message>}
    </React.Fragment>
  )
}


export default StaticMap;