import React from 'react';



export const CustomDateHeaderForWeekView = (props) => {
    const today = new Date();
    const isToday = today.toDateString() === props.date.toDateString() ? 'today-header' : '';
    const isPast = props.date < today ? 'past-date' : '';
    const [day, fullDate] = props.children.props.children.split(', ');
    const [ , date] = fullDate.split('/')
    return (
        <div date={props.date} className={`custom-scheduler-header-cell k-scheduler-cell k-heading-cell ${isToday} ${isPast}`}>
            <div className={'k-custom-date-header k-link k-nav-day'}>
            <span className={'header-day'}>
                {day.toUpperCase()}
            </span>
            <span className={'header-date'}>
                {date}
            </span>
            </div>
        </div>
    )
}

export const CustomDateHeaderForDayView = (props) => {
    const today = new Date();
    const isToday = today.toDateString() === props.date.toDateString() ? 'day-view-today-header' : '';
    const isPast = props.date < today ? 'past-date' : '';
    const [day, fullDate] = props.children.props.children.split(', ');
    const [ , date] = fullDate.split('/')
    return (
        <div date={props.date} className={`custom-scheduler-header-cell k-scheduler-cell k-heading-cell ${isToday} ${isPast}`}>
            <span className={'k-custom-date-header k-link k-nav-day'}>
                {day.toUpperCase()} {date}
            </span>
        </div>
    )
}

export const CustomDateHeaderForMonthView = (props) => {
    const day = props.children.props.children.slice(0, 3);
    return (
        <div date={props.date} className={`custom-scheduler-header-cell k-scheduler-cell k-heading-cell`}>
            <span style={{ fontWeight: 'bold' }} className={'k-custom-date-header k-link k-nav-day'}>
                {day.toUpperCase()}
            </span>
        </div>
    )
}

export const CustomTimeHeaderForWeekAndDayView = (props) => {
    const time = props.children.replace(/(\d{1,2}):\d{2} ([A|P]M)/, '$1 $2');
    return(
        <div className="k-scheduler-cell k-heading-cell k-side-cell k-major-cell custom-scheduler-header-cell">
            {time}
        </div>
    )
}