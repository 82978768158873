import React, { Component } from 'react';
import AppContext from '../../../../core/context/app.context';
import { Grid, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import moment from 'moment';
import '../../styles.css';
import ProgressChart from '../../../../shared/components/chart/progress';

const CustomCell = ({ dataItem, field }) => (
  <td style={{ fontSize: '20px' }}>
    {dataItem[field]}
  </td>
)

class PlanResultsTabView extends Component {
  static contextType = AppContext;

  formatDate = (date) => {
    if (date) {
      return moment.utc(date).format('MM/DD/yyyy');
    }
    return '';
  }

  render() {
    const { plan } = this.context;
    const weeks = Array.from(Array(plan.planWeeks.length), (_, i) => (i + 1).toString());
    const planRate = plan.planWeeks.map(({ planRate }) => planRate);
    const actualTargetRate = plan.planWeeks.map(({ actualRate }) => actualRate);
    const targetRate = plan.planWeeks.map(({ targetRate }) => targetRate);
    const { workOrderStatusCounts = {} } = this.context;
    const { Total: total, Completed: completed, Closed: closed } = workOrderStatusCounts;
    let completedAndClosed = completed + closed;
    let remaining = total - completed - closed;
    let planAvgPerWeek = (planRate.reduce((a, b) => a + b, 0) / plan.planWeeks.length).toFixed(3);
    let percentComplete = ((completedAndClosed / total) * 100).toFixed(3);
    const now = moment();
    const daysLeft = moment(plan.endDate).diff(now, 'days');
    let targetPerWeek = daysLeft < 0 ? remaining : (remaining / (daysLeft / 7)).toFixed(3); // includes now until end date
    const currentWeek = plan.planWeeks.find((week, index) => now.isBetween(moment(week.weekStartDate), moment(plan.planWeeks[index].weekStartDate).add(7, 'days')));
    let avgPerWeek = '-';
    let planCompleted;
    if (!currentWeek) {
      planCompleted = 100;
    } else if (currentWeek.week === 1) {
      planCompleted = ((((now.diff(moment(currentWeek.weekStartDate), 'days') * (currentWeek.planRate / 7)) ) / total) * 100).toFixed(3);
    } else {
      const previousWeeks = plan.planWeeks.filter(({ week }) => week < currentWeek.week).map(({ planRate }) => planRate);
      const previouslyCompleted = previousWeeks.reduce((a, b) => a + b, 0);
      // previouslyCompleted + days of a current week plan
      planCompleted = (((now.diff(moment(currentWeek.weekStartDate), 'days') * (currentWeek.planRate / 7)) + previouslyCompleted) / total * 100).toFixed(3);
      const totalActualTargetPerWeek = plan.planWeeks.map(({ actualRate }) => actualRate).splice(0, currentWeek.week - 1).reduce((a, b) => a + b, 0);
      avgPerWeek = (totalActualTargetPerWeek / (currentWeek.week - 1)).toFixed(3);
    }

    const { planDashboardData } = this.props;

    if (planDashboardData) {
      completedAndClosed = planDashboardData.completedAndClosed ? planDashboardData.completedAndClosed : completedAndClosed;
      remaining = planDashboardData.remaining ? planDashboardData.remaining : remaining;
      percentComplete = planDashboardData.percentComplete ? planDashboardData.percentComplete : percentComplete;
      avgPerWeek = planDashboardData.avgPerWeek ? planDashboardData.avgPerWeek : avgPerWeek;
      planAvgPerWeek = planDashboardData.planAvgPerWeek ? planDashboardData.planAvgPerWeek : planAvgPerWeek;
      planCompleted = planDashboardData.planCompleted ? planDashboardData.planCompleted : planCompleted;
      targetPerWeek = planDashboardData.targetPerWeek ? planDashboardData.targetPerWeek : targetPerWeek;
    }

    return (
      <div className='planning-plan-results-view'>
        <ProgressChart
          weeks={weeks}
          planRate={planRate}
          actualTargetRate={actualTargetRate}
          targetRate={targetRate} />
        <Grid className='plan-tab-table' data={plan ? [{ total, startDate: this.formatDate(plan.startDate), endDate: this.formatDate(plan.endDate) }] : []}>
          <GridNoRecords>
            Loading data...
          </GridNoRecords>
          <Column cell={(props) => <CustomCell {...props} />} field='total' title='Work Orders' width='180px' />
          <Column cell={(props) => <CustomCell {...props} />} field='startDate' title='Start' width='180px' />
          <Column cell={(props) => <CustomCell {...props} />} field='endDate' title='End' width='180px' />
        </Grid>
        <div className='planning-results-grid'>
          <Grid
            resizable={true}
            data={[{
              'completed': completedAndClosed || 0,
              'remaining': remaining || 0,
              'percentComplete': `${percentComplete || 0}%`,
              'avgPerWeek': avgPerWeek,
            }]}>
            <GridToolbar>
              <b className='grid-header'>Actual Progress</b>
            </GridToolbar>
            <Column cell={(props) => <CustomCell {...props} />} field='completed' title='Completed/Closed' width='170px' />
            <Column cell={(props) => <CustomCell {...props} />} field='remaining' title='Remaining' width='160px' />
            <Column cell={(props) => <CustomCell {...props} />} field='percentComplete' title='Percent Complete' width='170px' />
            <Column cell={(props) => <CustomCell {...props} />} field='avgPerWeek' title='Avg per Week' width='170px' />
          </Grid>
        </div>
        <div className='planning-results-grid'>
          <Grid
            resizable={true}
            data={[{
              'planDuration': `${plan.planWeeks.length} wks`,
              'planAvgPerWeek': planAvgPerWeek,
              'planCompleted': `${planCompleted === 100 && remaining > 0 ? '-' : planCompleted + '%'}`,
              'targetPerWeek': targetPerWeek || 0
            }]}>
            <GridToolbar>
              <b className='grid-header'>Plan Progress</b>
            </GridToolbar>
            <Column cell={(props) => <CustomCell {...props} />} field='planDuration' title='Plan Duration' width='140px' />
            <Column cell={(props) => <CustomCell {...props} />} field='planAvgPerWeek' title='Plan Avg per Week' width='170px' />
            <Column cell={(props) => <CustomCell {...props} />} field='planCompleted' title='Plan Complete' width='170px' />
            <Column cell={(props) => <CustomCell {...props} />} field='targetPerWeek' title='Target per Week' width='220px' />
          </Grid>
        </div>
      </div>
    )
  }

}

export default PlanResultsTabView;