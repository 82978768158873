import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';

const InventoryNonSerializedTableView = ({ data }) => {
  const [dataByType, setDataByType] = useState({});

  useEffect(() => {
    data.forEach(item => {
      if (dataByType.hasOwnProperty(item.assetType)) {
        dataByType[item.assetType].push(item);
      } else {
        dataByType[item.assetType] = [item];
      }
    });
    setDataByType({ ...dataByType });
  }, []);

  return (
    <>
      {Object.keys(dataByType).length > 0 && (
        <>
          {Object.keys(dataByType).map(tableType => (
            <Grid className='projects-table inventory-levels-by-form-factor-table' data={dataByType[tableType]} key={tableType}>
              <GridToolbar>
                <b className='inventory-levels-by-form-factor-table-title'>{tableType} - Inventory Status</b>
              </GridToolbar>
              <GridNoRecords>
                No data
            </GridNoRecords>
              <Column field='assetName' title='Name' width='250px' />
              <Column field='assetTotalQuantity' title='Total Assets Received' width='300px' />
              <Column field='assetQuantity' title='Total Avail Assets (In Stock)' width='300px' />
            </Grid>
          ))}
        </>
      )}
    </>
  )
}

export default InventoryNonSerializedTableView;