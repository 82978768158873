import React, { Component } from 'react';
import TopMenu from '../../shared/components/top-menu';
import AppContext from '../../core/context/app.context';
import ErrorMessage from '../../shared/components/messages/error';
import Loader from '../../shared/components/loader';
import InventoryAllAssetsListView from './components/grid/list-view';
import { useTimeZone } from '../../helpers/date';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import { LIST_INVENTORIES_BY_ASSET_WORKORDER_TYPE_QUERY } from '../../graphql/queries/inventory';
import withApollo from '../../hoc/withApollo';
import INVENTORY_ROUTE_TAB_LABELS from '../../constants/inventoryRouteTabLabels';
import routeMaps from '../../core/route-maps';

class InventoryRoute extends Component {

  static contextType = AppContext;
  _isMounted = false;

  state = {
    inventories: [],
    error: false,
    loading: false,
    currentOffset: 0,
    pageLimit: 0
  }

  async componentWillMount() {
    this._isMounted = true;
    let pageLimit = localStorage.getItem('INVENTORY_PAGE_LIMIT_SIZE');
    if (!pageLimit) {
      localStorage.setItem('INVENTORY_PAGE_LIMIT_SIZE', '50');
      pageLimit = '50';
    }
    await this.setState({ pageLimit: JSON.parse(pageLimit) });
    this.getInventories();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setState = (state) => {
    if (this._isMounted) {
      super.setState(state);
    }
  }

  changePageLimit = async (newLimit) => {
    localStorage.setItem('INVENTORY_PAGE_LIMIT_SIZE', newLimit);
    this.setState({ pageLimit: newLimit });
  }

  setLoading = (loading) => {
    this.setState({ loading });
  }

  getInventories = async (offset = 0, search, filter) => {
    try {
      const { currentProject } = this.context;
      this.setState({ loading: true });
      if (currentProject) {
        const { client } = this.props;
        let { pageLimit } = this.state;
        const response = await client.query({
          query: LIST_INVENTORIES_BY_ASSET_WORKORDER_TYPE_QUERY,
          variables: {
            projectId: currentProject.id,
            offset,
            limit: pageLimit,
            search,
            filter,
            assetWorkOrderType: currentProject.projectWorkOrderType
          },
          fetchPolicy: 'no-cache'
        });
        let inventories = response.data.listInventorysByAssetWorkOrderType.items || [];
        inventories = useTimeZone(inventories, ['assetDeployedDate', 'assetReceivedDate', 'shippedDate', 'assetTransferredDate', 'assetDeletedDate'], currentProject.projectTimeZone);
        const resultCount = response.data.listInventorysByAssetWorkOrderType.resultCount;
        this.setState({ currentOffset: offset, inventories, loading: false, resultCount });
      }
    } catch (error) {
      this.setState({ error: true, loading: false });
    }
  }

  filterInventories = inventories => {
    return inventories.map(inventory => Object.assign({ selected: false }, inventory))
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const { history } = this.props;
    const nonSerializedInventory = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory ? currentProject.projectConfiguration.additionalFeatures.nonSerializedInventory : false;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - ${nonSerializedInventory ? INVENTORY_ROUTE_TAB_LABELS.SerializedAssets : INVENTORY_ROUTE_TAB_LABELS.AllAssets}` : `${nonSerializedInventory ? INVENTORY_ROUTE_TAB_LABELS.SerializedAssets : INVENTORY_ROUTE_TAB_LABELS.AllAssets}`}`,
        iconName: 'flag'
      },
      tabs: nonSerializedInventory ? [
        {
          active: true,
          onClick: () => history.push(routeMaps.serializedAssets),
          iconName: '',
          title: INVENTORY_ROUTE_TAB_LABELS.SerializedAssets,
        },
        {
          active: false,
          onClick: () => history.push(routeMaps.nonSerializedAssets),
          iconName: '',
          title: INVENTORY_ROUTE_TAB_LABELS.NonSerializedAssets,
        }
      ] : []
    };
    return <TopMenu config={topMenuConfig} />;
  }

  renderInventoryAllAssetsContent = () => {
    const { inventories, currentOffset, pageLimit, resultCount } = this.state;
    return (
      <React.Fragment>
        <InventoryAllAssetsListView
          getInventories={this.getInventories}
          inventories={this.filterInventories(inventories)}
          currentOffset={currentOffset}
          pageLimit={pageLimit}
          total={resultCount}
          changePageLimit={this.changePageLimit}
          setLoading={this.setLoading} />
      </React.Fragment>
    );
  }

  render() {
    const { error, loading } = this.state;
    return (
      <ProjectRouteWrapper>
        <Loader loading={loading} />
        {this.renderTopMenu()}
        {error ? <ErrorMessage message='Error. Unable to fetch inventories' /> : this.renderInventoryAllAssetsContent()}
      </ProjectRouteWrapper>
    );
  }
}

export default withApollo(InventoryRoute);