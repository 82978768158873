import React from 'react';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import './styles.css';

const InventoryStatusByMeterTypeTableView = ({ data, split, showDeleted, assetLocations }) => (
  <>
  <Grid className='projects-table inventory-levels-by-form-factor-table' data={data}>
    <GridToolbar>
      <b className='inventory-levels-by-form-factor-table-title'>Meter - Inventory Status</b>
    </GridToolbar>
    <GridNoRecords>
      No data
    </GridNoRecords>
    <Column field='size' title='Size' width='250px' />
    <Column field='totalWoPerSize' title='Total WO per Size' width='200px' />
    <Column field='remainingWoPerSize' title='Remaining WO per Size ' width='200px' />
    {split ? (
      assetLocations.map(location => (
        <Column title={location} key={location}>
          <Column field={`assets.${location}.totalAssetsAvailable`} title='Total Assets Received' width='200px' />
          <Column field={`assets.${location}.remainingAssetsAvailable`} title='Total Avail Assets (In Stock)' width='240px' />
        </Column>
      ))) : [
      <Column key={0} field='totalAssetsAvailable' title='Total Assets Received' width='200px' />,
      (showDeleted ? <Column key={1} field='remainingAssetsAvailable' title='Total Avail Assets' width='240px' /> : <Column key={1} field='remainingAssetsAvailable' title='Total Avail Assets (In Stock)' width='240px' />)
    ]}
    {showDeleted && [
      <Column key={3} field='assetsDeleted' title='Assets Removed' width='200px' />,
      <Column key={4} field='assetsTransferred' title='Assets Transferred' width='200px' />,
      <Column key={5} field='stockAvailable' title='Total Stock' width='240px' />  
    ]}
    <Column field='assetsNeededToShip' title='Assets Needed (to Ship)' width='200px' />
  </Grid>
  </>
)

export default InventoryStatusByMeterTypeTableView;