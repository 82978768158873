import React from 'react';
import { Grid, GridColumn as Column, GridDetailRow } from '@progress/kendo-react-grid';
import { EXPORT_ARGUMENT_VALUE_TYPE, EXPORT_TRANSOFRMATION_TYPE_LABEL } from '../constants';
import { Button } from 'semantic-ui-react';
import ExportReportTemplateTransformationDetails from './exportReportTemplateTransformationDetails';

const CustomActionsColumn = ({ dataItem, onDelete, onAttachCondition }) => (
  <td>
    {dataItem.selected ? (
      <div style={{ display: 'flex' }}>
        <Button circular icon='cogs' onClick={() => onAttachCondition(dataItem)} />
        <Button circular icon='delete' onClick={() => onDelete(dataItem)} />
      </div>
    ) : null}
  </td>
)

class ExportReportTemplateDetails extends GridDetailRow {
  state = {
    data: [],
    selectedRowId: null
  }

  componentDidMount() {
    const { transformations } = this.props.dataItem;
    if (transformations) {
      const data = transformations.map((dataItem, index) => Object.assign({ selected: false, id: index, templateId: this.props.dataItem.id, expanded: false }, dataItem));
      this.setState({ data });
    }
  }

  getTransformationsData = () => {
    const { data, selectedRowId } = this.state;
    return data.map(transformation => {
      return {
        templateId: transformation.templateId,
        id: transformation.id,
        conditions: transformation.conditions || [],
        selected: transformation.id === selectedRowId,
        expanded: transformation.expanded,
        name: EXPORT_TRANSOFRMATION_TYPE_LABEL[transformation.name],
        values: transformation.args && transformation.args.length > 0 ? transformation.args.map(item => {
          const type = typeof (item) === 'object' ? EXPORT_ARGUMENT_VALUE_TYPE.json : typeof (item)
          const value = typeof (item) === EXPORT_ARGUMENT_VALUE_TYPE.string ? item : JSON.stringify(item);
          return `${value} (${type})`
        }).join(', ') : ''
      }
    });
  }

  onRowClick = (event) => {
    this.setState({ selectedRowId: event.dataItem.id });
  };

  onDelete = (item) => {
    const { id, templateId } = item;
    this.props.onDeleteTemplateTransformations(templateId, id);
  }

  onAttachCondition = (item) => {
    const { id, templateId } = item;
    this.props.onAttachTransformationCondition(templateId, id);
  }

  expandChange = (event) => {
    const { data } = this.state;
    data[event.dataItem.id].expanded = !event.dataItem.expanded;
    this.setState({ selectedRowId: event.dataItem.id, data });
  }

  render() {
    const dataItem = this.props.dataItem;
    const { data } = this.state;
    return (
      <>
        <section style={dataItem.transformations ? { paddingBottom: '10px' } : {}}>
          {dataItem.hasOwnProperty('key') && <p><strong>Key:</strong> {dataItem.key}</p>}
          {dataItem.hasOwnProperty('override') && <p><strong>Override:</strong> {dataItem.override}</p>}
          {dataItem.hasOwnProperty('same_as') && <p><strong>Same As:</strong> {dataItem.same_as}</p>}
          {dataItem.hasOwnProperty('default_to_column') && <p><strong>Default To Column:</strong> {dataItem.default_to_column}</p>}
          {dataItem.hasOwnProperty('default_to') && <p><strong>Default To Value:</strong> {dataItem.default_to}</p>}
          {dataItem.hasOwnProperty('prefix') && <p><strong>Prefix:</strong> {dataItem.prefix}</p>}
          {dataItem.hasOwnProperty('suffix') && <p><strong>Suffix:</strong> {dataItem.suffix}</p>}
        </section>

        {data.length > 0 && <Grid
          selectedField='selected'
          onRowClick={this.onRowClick}
          expandField='expanded'
          onExpandChange={this.expandChange}
          detail={(props) => <ExportReportTemplateTransformationDetails {...props} onDeleteTemplateTransformationConditions={this.props.onDeleteTemplateTransformationConditions} />}
          data={this.getTransformationsData()}>
          <Column field='name' width='200px' title='Name' />
          <Column field='values' title='Values' width='500px' />
          <Column field='' title='' cell={(props) => <CustomActionsColumn {...props} onDelete={this.onDelete} onAttachCondition={this.onAttachCondition} />} />
        </Grid>}
      </>
    );
  }
}

export default ExportReportTemplateDetails;