import gql from 'graphql-tag';

export const ASSIGN_INVENTORIES_MUTATION = gql`
  mutation AssignInventory($projectId: ID!, $userIds: [String]!, $inventoryIds: [String]!) {
    assignInventory (input: {
      projectId: $projectId,
      userIds: $userIds,
      inventoryIds: $inventoryIds
    }) {
      id
    }
  }
`;

export const UNASSIGN_INVENTORIES_MUTATION = gql`
  mutation UnassignInventory($projectId: ID!, $userIds: [String]!, $inventoryIds: [String]!) {
    unassignInventory (input: {
      projectId: $projectId,
      userIds: $userIds,
      inventoryIds: $inventoryIds
    }) {
      id
    }
  }
`;

export const RETURN_ASSET_MUTATION = gql`
  mutation returnAssetToInventory($projectId: ID!, $mfgSerialNumber: String!) {
    returnAssetToInventory(input: {
      project: $projectId,
      mfgSerialNumber: $mfgSerialNumber
    }) {
      id
    }
  }
`;

export const ASSIGN_ALL_INVENTORIES_BY_PROJECT_ID_MUTATION = gql`
  mutation assignAllInventories($projectId: ID!) {
    assignAllInventoriesByProjectId(projectId: $projectId) {
      id
    }
  }
`;

export const REMOVE_ASSETS_MUTATION = gql`
  mutation RemoveAssets($projectId: ID!, $inventoryIds: [String]!) {
    removeAssets (input: {
      projectId: $projectId,
      inventoryIds: $inventoryIds
    }) {
      id
    }
  }
`;

export const TRANSFER_TO_UTILITY_MUTATION = gql`
  mutation TransferToUtility($projectId: ID!, $inventoryIds: [String]!) {
    transferToUtility (input: {
      projectId: $projectId,
      inventoryIds: $inventoryIds
    }) {
      id
    }
  }
`;

export const RELEASE_TO_PRODUCTION_MUTATION = gql`
  mutation ReleaseToProduction($projectId: ID!, $inventoryIds: [String]!) {
    releaseToProduction (input: {
      projectId: $projectId,
      inventoryIds: $inventoryIds
    }) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_NON_SERIALIZED_ASSET_QUANTITY_MUTATION = gql`
  mutation UpdateNonSerializedAssetQuantity($id: ID!, $quantity: Int, $projectId: ID!, $userId: ID!) {
    updateNonSerializedAssetQuantity(input:{
      id: $id,
      quantity: $quantity,
      projectId: $projectId,
      userId: $userId
    }) {
      id
    }
  }
`;

export const APPROVE_FOR_TRANSFER_MUTATION = gql`
  mutation ApproveForTransfer($projectId: ID!, $inventoryIds: [String]!) {
    approveForTransfer (input: {
      projectId: $projectId,
      inventoryIds: $inventoryIds
    }) {
      id
    }
  }
`;

export const TRANSFER_TO_WAREHOUSE_MUTATION = gql`
  mutation TransferToWarehouse($projectId: ID!, $inventoryIds: [String]!, $assetDestination: String!) {
    transferToWarehouse (input: {
      projectId: $projectId,
      inventoryIds: $inventoryIds
      assetDestination: $assetDestination
    }) {
      id
    }
  }
`;

export const RECEIVE_FOR_PRODUCTION_MUTATION = gql`
  mutation ReceiveForProduction($projectId: ID!, $inventoryIds: [String]!) {
    receiveForProduction (input: {
      projectId: $projectId,
      inventoryIds: $inventoryIds
    }) {
      id
    }
  }
`;