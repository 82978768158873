import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { isInBlackOutRange } from '../../../../core/services/blackout.service';

const InventoryWarningCell = ({ dataItem }) => (
  <td style={{ textAlign: 'center', fontSize: '24px', padding: 0, margin: 0 }}>
    {dataItem.inventoryWarning ? <Icon name='exclamation triangle' color='orange' /> : <Icon name='check' color='grey' />}
  </td>
)

const BlackoutWarningCell = ({ dataItem }) => (
  <td style={{ textAlign: 'center', fontSize: '24px', padding: 0, margin: 0 }}>
    {dataItem.isBlackout ? <Icon name='exclamation circle' color='orange' /> : <Icon name='check' color='grey' />}
  </td>
)

class RouteDetailsModal extends React.Component {
  static contextType = AppContext;
  _isMounted = false;

  state = {
    routeCodes: []
  }

  setState = (state) => {
    if (this._isMounted) {
      super.setState(state);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentProject, getDashboardRoutesData } = this.context;

    await getDashboardRoutesData();

    const routeCodes = this.props.routeCodes.map(route => Object.assign({}, { ...route }));
    const meterSizeByRouteRowsCopy = this.context.meterSizeByRouteRows.map(route => Object.assign({}, { ...route }));

    if (meterSizeByRouteRowsCopy.length > 0) {
      const availableRow = meterSizeByRouteRowsCopy.shift();
      delete availableRow['Meter Remaining Total'];
      delete availableRow['Route'];
      meterSizeByRouteRowsCopy.forEach((route, index) => {
        Object.keys(availableRow).forEach(routeName => {
          if (availableRow[routeName] >= route[routeName]) {
            availableRow[routeName] -= route[routeName];
          } else {
            routeCodes[index].inventoryWarning = true;
          }
        })
      })
    }

    const formatDate = (date) => {
      date = date.split('/');
      return `${date[2]}-${date[0]}-${date[1]}`;
    }

    const routes = [];
    const startDates = [];
    const endDates = [];
    const billingCycleCodes = [];

    routeCodes.forEach(({ route, startDate, endDate, billingCycleCode }) => {
      routes.push(route);
      startDates.push(formatDate(startDate));
      endDates.push(formatDate(endDate));
      billingCycleCodes.push(billingCycleCode);
    });

    const data = await isInBlackOutRange(currentProject.id, routes, startDates, endDates, billingCycleCodes);
    data.forEach(({ route, isBlackout }) => {
      const item = routeCodes.find(item => item.route === route);
      item.isBlackout = isBlackout;
    });

    this.setState({ routeCodes });
  }

  render() {
    const { showModal, closeModal } = this.props;
    const { routeCodes } = this.state;
    return (
      <Modal size='large' open={showModal}>
        <Modal.Header><Icon name='calendar alternate outline' />Route Plan Detail</Modal.Header>
        <Modal.Content>
          <div style={{ paddingBottom: '10px' }}>
            <Grid
              data={routeCodes}
            >
              <GridNoRecords>
                Loading data...
              </GridNoRecords>
              <Column field='route' title='Route' width='150px' />
              <Column field='startDate' title='Start' width='150px' />
              <Column field='endDate' title='End' width='150px' />
              <Column title='Inventory' width='130px' field='none' cell={InventoryWarningCell} />
              <Column title='Blackout' width='130px' field='none' cell={BlackoutWarningCell} />
              <Column title='Billing Cycle' width='130px' field='billingCycleCode' />
            </Grid>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} positive>Close</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default RouteDetailsModal;