import gql from 'graphql-tag';

export const GET_TECHNICIANS_ASSIGNMENTS_PER_DAY_AND_PROJECT_ID_QUERY = gql`
  query ListTechnicianAssignmentsCountByDay($projectId: ID!) {
    listTechnicianAssignmentsCountByDay (project: $projectId) {
      items {
        id
        userName
        fullName
      }
      assignmentsDay0
      assignmentsDay1
      assignmentsDay2
      assignmentsDay3
      assignmentsDay4
      assignmentsDay5
      assignmentsDay6
    }
  }
`;

export const LIST_PROJECT_USER_BY_WORKORDER_STATUS_AND_COUNT = gql`
  query ListProjectUserByWorkOrderStatusAndCount($projectId: ID!) {
    listProjectUserByWorkOrderStatusAndCount (project: $projectId) {
      items {
        id
        userName
        fullName
        Assigned
        InProgress
        InReview
        Escalated
        Completed
        qualificationDefinition
      }
    }
  }
`;

export const LIST_WORKORDER_TECHNICIANS_BY_PROJECT_ID_QUERY = gql`
  query ListWorkOrderTechniciansByProject($projectId: ID!) {
    listWorkOrderTechniciansByProject (project: $projectId) {
      items {
        id
        userName
        fullName
        counts
      }
    }
  }
`;

export const LIST_INVENTORY_TECHNICIANS_BY_PROJECT_ID_QUERY = gql`
  query ListInventoryTechniciansByProject($projectId: ID!) {
    listInventoryTechniciansByProject (project: $projectId) {
      items {
        id
        userName
        fullName
        counts
      }
    }
  }
`;

export const GET_USERS_QUERY = gql`
  query ListUsersByProject($projectId: ID!) {
    listUsersByProject (project: $projectId) {
      items {
        id
        userName
        fullName
      }
    }
  }
`;

export const GET_USER_BY_USERNAME = gql`
  query GetUserByUserName($userName: String!) {
    getUserByUserName (userName: $userName) {
      id
      userName
      email
      tenant {
        id
        tenantName
      }
      fullName
      userProjects {
        items {
          id
          projectName
          projectShortCode
          projectConfiguration
        }
      }
      userRoles {
        items {
          role
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userName
    }
  }
`;

export const GET_USER_DETAILS_QUERY = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userName
      email
      phone
      avatar
      isActive
      tenant {
        id
        tenantName
      }
      fullName
      userProjects {
        items {
          id
          projectName
        }
      }
      userRoles {
        items {
          id
          role
        }
      }
    }
  }
`;

export const LIST_USERS_BY_PROJECT_AND_ROLE_QUERY = gql`
  query ListUsersByProjectAndRole($tenant: ID!) {
    listUsersByProjectAndRole(tenant: $tenant) {
      items {
        id
        userName
        fullName
        isActive
        roles
        projects
      }
    }
  }
`;

export const ADMIN_LIST_USERS_BY_PROJECT_AND_ROLE_QUERY = gql`
  query AdminListUsersByProjectAndRole($tenant: ID!, $limit: Int, $offset: Int) {
    adminListUsersByProjectAndRole(tenant: $tenant, limit: $limit, offset: $offset) {
      items {
        id
        userName
        fullName
        isActive
        roles
        projects
      }
    }
  }
`;

export const LIST_TECHNICIANS_BY_INSTALL_DATE_AND_TENANT_QUERY = gql`
  query listTechniciansByInstallDateAndTenant($tenantId: ID!, $fromDate: String!, $toDate: String!) {
    listTechniciansByInstallDateAndTenant(tenantId: $tenantId, fromDate: $fromDate, toDate: $toDate) {
      items {
        userId
        userName
        latitude
        longitude
        install
        workOrderNumber
        avatar
        fullName
        workOrderLastVisitDate
      }
    }
  }
`;