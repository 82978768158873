import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { Loader } from "@progress/kendo-react-indicators";
import { listDuplicateLocationsForWorkOrders } from '../../../../core/services/appointment.service';

export const WorkOrdersWithSharedAddress = ({ closeModal, selectedWorkOrder }) => {
  const [associatedWorkOrders, setAssociatedWorkOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    init();
    console.log(selectedWorkOrder);
  }, []);
  const init = async () => {
    try {
      setLoading(true);
      const wos = await listDuplicateLocationsForWorkOrders(selectedWorkOrder.workOrderId);
      console.log(wos)
      setAssociatedWorkOrders(wos);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }
  return (
    <div>
      <Modal dimmer='blurring' size='tiny' onClose={closeModal} open={true}>
        <Modal.Header>Selected Work Order: {selectedWorkOrder.workOrderNumber}</Modal.Header>
        <Modal.Content>
          { loading && <Loader /> }
          { associatedWorkOrders[0] &&
            <div className='shared-address-container'>
              { associatedWorkOrders[0].street && <p>{associatedWorkOrders[0].street}</p> }
              { associatedWorkOrders[0].street2 && <p>{associatedWorkOrders[0].street2}</p> }
              { associatedWorkOrders[0].street3 && <p>{associatedWorkOrders[0].street3}</p> }
              { associatedWorkOrders[0].city && <p>{associatedWorkOrders[0].city}</p> }
              { associatedWorkOrders[0].stateCode && <p>{associatedWorkOrders[0].stateCode}</p> }
              { associatedWorkOrders[0].zip && <p>{associatedWorkOrders[0].zip}</p> }
            </div>
          }
          <ul>
            { associatedWorkOrders.map(wo => <li>{ wo.workOrderNumber }</li>) }
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>Close</Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}