import React from 'react';
import {
  Grid,
  GridColumn as Column
} from '@progress/kendo-react-grid';

const QualificationListView = ({ onSortChange, sort, qualifications, onRowClick }) => (
  <Grid
    onSortChange={onSortChange}
    sort={sort}
    sortable
    className='qualifications-listview-grid'
    style={{ height: '92%' }}
    data={qualifications}
    onRowClick={onRowClick}>
    <Column field='qualificationName' title='Name' width='250px' />
    <Column field='qualificationDescription' title='Description' width='500px' />
  </Grid>
)

export default QualificationListView;