import { AuthenticationDetails, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import config from '../../config';
import jwt_decode from 'jwt-decode';
import ROLES from '../../constants/roles';
import client from '../../core/apollo';
import store from '../../core/redux';
import { resetSchedulerState } from '../redux/actions/scheduler.actions';
import { resetMapState } from '../redux/actions/map.actions';
import { resetAdminOverviewState } from '../redux/actions/administrationOverview.actions'
import { resetWODetailsMenuState } from '../redux/actions/workOrderDetailsMenu.actions';

export const ACCESS_TOKEN = 'PW_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'PW_REFRESH_TOKEN';

const clearGlobalState = () => {
  client.clearStore();
  localStorage.clear();
  store.dispatch(resetSchedulerState());
  store.dispatch(resetMapState());
  store.dispatch(resetAdminOverviewState());
  store.dispatch(resetWODetailsMenuState());
}

export const isAuthenticated = () => {
  return !!localStorage.getItem(ACCESS_TOKEN);
}

export const getCurrentUser = async () => {
  const cognitoUser = config.cognitoPool.getCurrentUser();
  return cognitoUser;
}

export const validateToken = async () => {
  const cognitoUser = config.cognitoPool.getCurrentUser();
  if (cognitoUser) {
    return cognitoUser.getSession((err, session) => {
      if (err || !session.isValid()) {
        return null;
      }
      return session.getIdToken().getJwtToken();
    });
  }
  return null;
}

export const login = (username, password) => {
  clearGlobalState();
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: config.cognitoPool
  });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        resolve(result);
      },
      onFailure: err => {
        reject(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        reject({ newPasswordRequired: true });
      }
    })
  });
}

export const completeNewPasswordChallenge = (username, tempPassword, newPassword) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: tempPassword,
  });
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: config.cognitoPool
  });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => { },
      onFailure: (err) => reject(err),
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        delete userAttributes.email;
        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: result => {
            resolve(result);
          },
          onFailure: err => {
            reject(err);
          }
        })
      }
    });
  });
}

const refreshToken = async () => {
  try {
    const token = await new Promise((resolve, reject) => {
      const cognitoUser = config.cognitoPool.getCurrentUser();

      if (!cognitoUser) {
        reject('Cognito user not found.');
      }

      let RefreshToken = getRefreshToken();
      RefreshToken = new CognitoRefreshToken({ RefreshToken });
      cognitoUser.refreshSession(RefreshToken, (err, session) => {
        if (err) {
          reject(err);
        } else {
          const accessToken = session.idToken.jwtToken;
          const refreshToken = session.refreshToken.token;
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
          resolve(accessToken);
        }
      });
    });
    return token;
  } catch (err) {
    logout();
    redirectToLogin();
    console.log(err);
  }
}

export const getToken = async () => {
  let token = localStorage.getItem(ACCESS_TOKEN) || null;
  if (token) {
    if (new Date(jwt_decode(token).exp * 1000) <= new Date()) {
      token = await refreshToken();
    }
  }
  return token ? token : null;
}

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
}

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN);
}

export const logout = () => {
  const cognitoUser = config.cognitoPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
  clearGlobalState();
  removeAccessToken();
  removeRefreshToken();
}

// Determine if route/component functionality will be disabled for specific user roles
export const isDisabled = (userRoles, roles) => {
  if (userRoles) {
    return roles.some(role => userRoles.includes(role));
  }
  return false;
}

export const isEnabled = (userRoles, roles) => {
  if (userRoles && userRoles.length > 0) {
    return roles.some(role => userRoles.includes(role));
  }
  return false;
}

export const setAccessToken = (token) => {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);
  }
}

export const setRefreshToken = (token) => {
  if (token) {
    localStorage.setItem(REFRESH_TOKEN, token);
  }
}

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
}

export const sendVerificationCode = (username) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: config.cognitoPool
    });
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        resolve();
      },
      onFailure: (err) => {
        console.log(err)
        reject();
      },
      inputVerificationCode() {
        resolve();
      }
    });
  });
}

export const changePassword = (username, verificationCode, newPassword) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: config.cognitoPool
    });
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        reject(err.message);
      }
    });
  });
}

export const hasSystemAdminRole = (roles) => {
  return roles.findIndex(role => role === ROLES.SystemAdmin) > -1;
}

export const sha256 = async (str) => {
	return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

export const generateNonce = async () => {
	const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
	const hashArray = Array.from(new Uint8Array(hash));
	return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
};

export const base64URLEncode = (string) => {
	return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=+$/, "")
};

export const getTokenDetailsFromCognito = async (callbackCode, codeVerifier) => {
  const CognitoDomain = 'https://unified-sso-pw.auth.us-east-1.amazoncognito.com';
  const clientId = '70gp1do9v4ih7dcoddd1r8v5gs';

  try {
    const response = await fetch(`${CognitoDomain}/oauth2/token`, {
      method: "POST",
      headers: new Headers({ "content-type": "application/x-www-form-urlencoded" }),
      body: Object.entries({
        "grant_type": "authorization_code",
        "client_id": clientId,
        "code": callbackCode,
        "code_verifier": codeVerifier,
        "redirect_uri": window.location.origin,
      }).map(([k, v]) => `${k}=${v}`).join("&"),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error data:', errorData);
      throw new Error(errorData.message || 'Error retrieving tokens from Cognito');
    }

    const tokens = await response.json();
    setAccessToken(tokens.access_token);
    setRefreshToken(tokens.refresh_token);

    return tokens;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};

export const getSSODomains = async (userDomain) => {
  console.log('getSSODomains', userDomain);
  let ssoDomains = [];
  try {
    const response = await fetch(`${config.getSSODomainsAPI}?domain=${encodeURIComponent(userDomain)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    ssoDomains = data.ssoDomains;

    return ssoDomains;
  } catch (error) {
    console.log(error);
    return ssoDomains;
  }
};

const redirectToLogin = () => {
  if (window.location.pathname !== '/') {
    window.location.href = '/'
  };
}