import React from 'react';
import { Grid, GridColumn as Column, GridDetailRow } from '@progress/kendo-react-grid';
import { EXPORT_ARGUMENT_VALUE_TYPE } from '../constants';
import { Button } from 'semantic-ui-react';

const CustomActionsColumn = ({ dataItem, onDelete }) => (
  <td>
    {dataItem.selected ? (
      <Button circular icon='delete' onClick={() => onDelete(dataItem)} />
    ) : null}
  </td>
)

class ExportReportTemplateTransformationDetails extends GridDetailRow {
  state = {
    data: [],
    selectedRowId: null
  }

  componentDidMount() {
    const { conditions } = this.props.dataItem;
    if (conditions) {
      const data = conditions.map((dataItem, index) => Object.assign({ selected: false, id: index }, dataItem));
      this.setState({ data });
    }
  }

  getTransformationConditionsData = () => {
    const { data, selectedRowId } = this.state;
    const { templateId, id: transformationId } = this.props.dataItem;
    return data.map(condition => {
      const type = typeof (condition.value) === 'object' ? EXPORT_ARGUMENT_VALUE_TYPE.json : typeof (condition.value)
      const value = typeof (condition.value) === EXPORT_ARGUMENT_VALUE_TYPE.string ? condition.value : JSON.stringify(condition.value);
      return {
        id: condition.id,
        name: condition.name,
        transformationId,
        templateId,
        selected: condition.id === selectedRowId,
        values: `${value} (${type})`
      }
    });
  }

  onRowClick = (event) => {
    this.setState({ selectedRowId: event.dataItem.id });
  };

  onDelete = (item) => {
    const {id, transformationId, templateId} = item;
    this.props.onDeleteTemplateTransformationConditions(id, transformationId, templateId);
  }

  render() {
    const { data } = this.state;
    return (
      <>
        {data.length > 0 && <Grid
          selectedField='selected'
          onRowClick={this.onRowClick}
          data={this.getTransformationConditionsData()}>
          <Column field='name' width='200px' title='Name' />
          <Column field='values' title='Values' width='500px' />
          <Column field='' title='' cell={(props) => <CustomActionsColumn {...props} onDelete={this.onDelete} />} />
        </Grid>}
      </>
    )
  }
}

export default ExportReportTemplateTransformationDetails;