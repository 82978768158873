import React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { Checkbox } from 'semantic-ui-react';
import './styles.css';

const rowRender = (showInactiveTechnicians) => (trElement, props) => {
  let active = true;
  if (
    (props.dataItem.Assigned === 0) && 
    (props.dataItem.Completed === 0) && 
    (props.dataItem.Escalated === 0) &&
    (props.dataItem.InProgress === 0) &&
    (props.dataItem.InReview === 0)) {
      active = false;
    }
  const white = {
    backgroundColor: '',
  };
  const gray = {
    backgroundColor: 'rgb(0, 0, 0, 0.1)',
  };
  const hidden = {
    display: 'none'
  }
  if (showInactiveTechnicians===true){
    const trProps = {
      style: active ? white : gray,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  } else {
    const trProps = {
      style: active ? white : hidden,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  } 
};

const TableView = ({ data, showBlackOutAssignments, showInactiveTechnicians, onCheckboxToggle, onInactiveCheckboxToggle, projectHasBlackOuts }) => {
  return (
  <Grid className='projects-table workorder-assignments-table' data={data} rowRender={rowRender(showInactiveTechnicians)}>
    <GridToolbar>
      {projectHasBlackOuts ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <label style={{ paddingRight: '15px', fontSize: '17px', color: 'white' }}><b>Assignments Status</b></label>
          </div>
          <div>
            <label style={{ paddingRight: '15px', color: 'white' }}>Show assignments for next 7 days (effected by blackouts)</label>
            <Checkbox toggle checked={showBlackOutAssignments} onChange={onCheckboxToggle} />
          </div>
          <div>
              <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
              <Checkbox toggle checked={showInactiveTechnicians} onChange={onInactiveCheckboxToggle} />
          </div>
        </div>
      ) : (
        
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <label style={{ paddingRight: '15px', fontSize: '17px', color: 'white' }}><b>Assignment Status</b></label>
          </div>
          <div>
              <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
              <Checkbox toggle checked={showInactiveTechnicians} onChange={onInactiveCheckboxToggle} />
          </div>
        </div>
        )}
    </GridToolbar>
    <GridNoRecords>
      No data
    </GridNoRecords>
    <Column field='fullName' title='Technician' width='175px' />
    <Column field='userName' title='Username' width='250px' />
    <Column field='Assigned' title='Assigned' width='125px' />
    <Column field='InProgress' title='In Progress' width='125px' />
    <Column field='InReview' title='In Review' width='125px' />
    <Column field='Escalated' title='Escalated' width='125px' />
    <Column field='Completed' title='Completed' width='125px' />
  </Grid>
  );
};
export default TableView;