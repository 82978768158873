import React, { Component } from 'react';
import LoginForm from './components/forms/login';
import routeMaps from '../../core/route-maps';
import * as authService from '../../core/services/auth.service';
import * as userService from '../../core/services/user.service';
import AppContext from '../../core/context/app.context';
import SendVerificationCode from './components/forms/sendVerificationCode';
import SSOCodeHandler from './components/forms/SSOCodeHandler';
import ChangePassword from './components/forms/changePassword';
import CompleteNewPasswordChallengeForm from './components/forms/completeNewPasswordChallenge';
import ROLES from '../../constants/roles';
import TermsOfUseModal from '../../shared/components/modal/termsOfUse';
import PrivacyPolicyModal from '../../shared/components/modal/privacyPolicy';
import { withRouter } from 'react-router-dom';

class LoginRoute extends Component {

  static contextType = AppContext;
  
  state = {
    username: '',
    password: '',
    error: null,
    loading: false,
    showSendVerificationCodeForm: false,
    showChangePasswordForm: false,
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: '',
    showPasswordSuccessfullyChangedMessage: false,
    showCompleteNewPasswordChallengeForm: false,
    ssoDomains: '',
    showPassword: true,
    foundSSOCode: false,
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      this.setState({ foundSSOCode: true });  
      this.setState({ loading: true });
    } 
  }

  handleSubmit = async (event) => {
    let { username, password } = this.state;
    const { setUser, initGlobalState } = this.context;
    const { location } = this.props;
    const {state} = location;
    username = username.replace(/ /g, '');
    password = password.replace(/ /g, '');
    event.preventDefault();
    try {
      // if (username.indexOf('@') > -1) {
      //   const fetchSSOData = async () => {
      //     const ssoDomains = await authService.getSSODomains(username.split('@')[1].toLowerCase());
      //     if (ssoDomains) {
      //       console.log('ssoDomains', ssoDomains);
      //       const CognitoDomain = ssoDomains.cognitoDomain;
      //       const clientId = ssoDomains.webClientId;
      //       const idp = ssoDomains.idp;
      //       sessionStorage.setItem(`defaultProjectId`, ssoDomains.defaultProjectId);
      //       sessionStorage.setItem(`defaultRoleId`, ssoDomains.defaultRoleId);
      //       sessionStorage.setItem(`defaultTenantId`, ssoDomains.defaultTenantId);
      //       const stateNonce = await authService.generateNonce();
      //       const codeVerifier = await authService.generateNonce();
      //       sessionStorage.setItem(`codeVerifier-${stateNonce}`, codeVerifier);
      //       sessionStorage.setItem(`email`, username);
      //       sessionStorage.setItem(`username`, idp+"_"+username);
      //       const codeChallenge = authService.base64URLEncode(await authService.sha256(codeVerifier));
      //       //initiate SSO provider;            
      //       window.open(`https://${CognitoDomain}/oauth2/authorize?identity_provider=${idp}&redirect_uri=${window.location.origin}&response_type=CODE&client_id=${clientId}&state=${stateNonce}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=email openid`,`_self`);
      //       //throw error if window.open fails;            
      //       const error = { message: 'Redirecting...' };            
      //       throw error;
      //     }
      //     this.setState({ error: null, loading: true });
      //     const response = await authService
      //       .login(username, password)
      //       .catch(error => { throw error });
      //     authService.setAccessToken(response.idToken.jwtToken);
      //     authService.setRefreshToken(response.refreshToken.token);
      //     const user = await userService.getUserByUsername(username);
      //     initGlobalState();
      //     setUser(user);

      //     if (state && state.from) {
      //       this.props.history.replace(state.from);
      //     }

      //     if (user.userRoles.indexOf(ROLES.AccountAdmin) > -1 || user.userRoles.indexOf(ROLES.SystemAdmin) > -1) {
      //       this.props.history.push(routeMaps.administrationProjects);
      //     } else {
      //       if (user.userRoles.indexOf('Technician') > -1 && user.userRoles.length === 1) {
      //         const error = { message: 'You currently have mobile access only. Please log into the Peak Workflow App.' };
      //         throw error;
      //       } else {
      //         this.props.history.push(routeMaps.dashboard);
      //       }
      //     }
      //   }
      //   fetchSSOData().catch(console.error);
      // } else {
        this.setState({ error: null, loading: true });
        const response = await authService
          .login(username, password)
          .catch(error => { throw error });
        authService.setAccessToken(response.idToken.jwtToken);
        authService.setRefreshToken(response.refreshToken.token);
        const user = await userService.getUserByUsername(username);
        initGlobalState();
        setUser(user);

        if (state && state.from) {
          this.props.history.replace(state.from);
        }

        if (user.userRoles.indexOf(ROLES.AccountAdmin) > -1 || user.userRoles.indexOf(ROLES.SystemAdmin) > -1) {
          this.props.history.push(routeMaps.administrationProjects);
        } else if(user.userRoles.indexOf(ROLES.CSR) > -1) {
          this.props.history.push(routeMaps.csrPortal);
        } else {
          if (user.userRoles.indexOf('Technician') > -1 && user.userRoles.length === 1) {
            const error = { message: 'You currently have mobile access only. Please log into the Peak Workflow App.' };
            throw error;
          } else {
            this.props.history.push(routeMaps.dashboard);
          }
        }
      //}
    } catch (error) {
      if (error && error.newPasswordRequired) {
        this.setState({
          showCompleteNewPasswordChallengeForm: true,
          loading: false
        });
      } else {
        console.log(error);
        this.setState({ error, loading: false });
      }
    }
  }

  isDomain = (str) => {
    const domainRegexp = /^(?:(?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i;
    return domainRegexp.test(str) ? true : false;
  }

  onUsernameChange = (event) => {
    this.setState({ showPassword: true});
    // const value = event.target.value;
    // if (value.indexOf('@') > 0){
    //   if (this.isDomain(value.split('@')[1].toLowerCase())) {
    //     const fetchSSOData = async () => {
    //       await authService.getSSODomains(value.split('@')[1].toLowerCase()).then((response)=>{
    //         if (response && JSON.stringify(response).length > 50) {
    //           this.setState({ showPassword: false});
    //           this.setState({ ssoDomains: response});
    //         }
    //       });
    //     }
    //     fetchSSOData().catch(console.error);
    //   }
    // }
    this.setState({ username: event.target.value});
  }

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value });
  }

  onShowSendVerificationCodeForm = () => {
    this.setState({ showSendVerificationCodeForm: true, username: '', error: null });
  }

  onHideSendVerificationCodeForm = () => {
    this.setState({ showSendVerificationCodeForm: false, username: '', error: null });
  }

  sendVerificationCode = async (event) => {
    try {
      this.setState({ loading: true, error: null });
      const { username } = this.state;
      event.preventDefault();
      await authService.sendVerificationCode(username);
      this.setState({ loading: false, showChangePasswordForm: true, showSendVerificationCodeForm: false });
    } catch (error) {
      this.setState({ loading: false, error: { message: 'Unable to send verification code' } });
    }
  }

  onHideChangePasswordForm = () => {
    this.setState({ showChangePasswordForm: false, error: null });
  }

  changePassword = async (event) => {
    try {
      this.setState({ loading: true, error: null });
      const { verificationCode, newPassword, confirmNewPassword } = this.state;
      const username = this.state.username.replace(/ /g, '');
      event.preventDefault();
      if (newPassword !== confirmNewPassword) {
        this.setState({ loading: false, error: { message: 'Passwords do not match' } });
      } else {
        await authService.changePassword(username, verificationCode, newPassword);
        this.setState({ showPasswordSuccessfullyChangedMessage: true });
        setTimeout(() => {
          this.setState({ loading: false, showChangePasswordForm: false, showPasswordSuccessfullyChangedMessage: false, username: '' });
        }, 3000);
      }
    } catch (error) {
      this.setState({ loading: false, error: { message: error } });
    }
  }

  onVerificationCodeChange = (event) => {
    this.setState({ verificationCode: event.target.value });
  }

  onNewPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value });
  }

  onConfirmNewPasswordChange = (event) => {
    this.setState({ confirmNewPassword: event.target.value });
  }

  onHideCompleteNewPasswordChallengeForm = () => {
    this.setState({ showCompleteNewPasswordChallengeForm: false, error: null });
  }

  completeNewPasswordChallenge = async (event) => {
    try {
      this.setState({ loading: true, error: null });
      const { password, newPassword, confirmNewPassword } = this.state;
      const username = this.state.username.replace(/ /g, '');
      const tempPassword = password.replace(/ /g, '');
      if (newPassword !== confirmNewPassword) {
        this.setState({ loading: false, error: { message: 'Passwords do not match' } });
      } else {
        await authService.completeNewPasswordChallenge(username, tempPassword, newPassword);
        this.setState({ showPasswordSuccessfullyChangedMessage: true });
        setTimeout(() => {
          this.setState({
            loading: false,
            showCompleteNewPasswordChallengeForm: false,
            showPasswordSuccessfullyChangedMessage: false,
            username: '',
            password: '',
            newPassword: ''
          });
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: { message: error.message || 'Unexpected error' } });
    }
  }

  render() {
    const {
      error,
      loading,
      showSendVerificationCodeForm,
      showChangePasswordForm,
      showPasswordSuccessfullyChangedMessage,
      showCompleteNewPasswordChallengeForm,
      showPassword,
      ssoDomains,
      foundSSOCode,
      username
    } = this.state;
    const {setUser} = this.context;
    const {history} = this.props;
    return (
      <AppContext.Consumer>
        {({ showPrivacyPolicyModal, showTermsOfUseModal, setShowPrivacyPolicyModal, setShowTermsOfUseModal }) => (
          <React.Fragment>
            <TermsOfUseModal showModal={showTermsOfUseModal} closeModal={() => setShowTermsOfUseModal(false)} />
            <PrivacyPolicyModal showModal={showPrivacyPolicyModal} closeModal={() => setShowPrivacyPolicyModal(false)} />
            {foundSSOCode && <SSOCodeHandler
              error={error}
              loading={loading}
              setUser={setUser}
              username={username}
              history={history}
            />}
            {showCompleteNewPasswordChallengeForm && <CompleteNewPasswordChallengeForm
              error={error}
              handleSubmit={this.completeNewPasswordChallenge}
              onHideCompleteNewPasswordChallengeForm={this.onHideCompleteNewPasswordChallengeForm}
              loading={loading}
              onNewPasswordChange={this.onNewPasswordChange}
              onConfirmNewPasswordChange={this.onConfirmNewPasswordChange}
              showPasswordSuccessfullyChangedMessage={showPasswordSuccessfullyChangedMessage}
            />}
            {showSendVerificationCodeForm ? <SendVerificationCode
              error={error}
              handleSubmit={this.sendVerificationCode}
              onUsernameChange={this.onUsernameChange}
              loading={loading}
              onHideSendVerificationCodeForm={this.onHideSendVerificationCodeForm} /> :
              showChangePasswordForm ? <ChangePassword
                error={error}
                loading={loading}
                onHideChangePasswordForm={this.onHideChangePasswordForm}
                handleSubmit={this.changePassword}
                onVerificationCodeChange={this.onVerificationCodeChange}
                onNewPasswordChange={this.onNewPasswordChange}
                onConfirmNewPasswordChange={this.onConfirmNewPasswordChange}
                showPasswordSuccessfullyChangedMessage={showPasswordSuccessfullyChangedMessage}
              /> : (!showCompleteNewPasswordChallengeForm && <LoginForm
                error={error}
                handleSubmit={this.handleSubmit}
                onUsernameChange={this.onUsernameChange}
                onPasswordChange={this.onPasswordChange}
                loading={loading}
                onShowSendVerificationCodeForm={this.onShowSendVerificationCodeForm}
                setShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
                setShowTermsOfUseModal={setShowTermsOfUseModal}
                showPassword={showPassword}
                ssoDomains={ssoDomains}
              />)}
          </React.Fragment>)}
      </AppContext.Consumer>
    );
  }
}



export default withRouter(LoginRoute);