import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const TermsOfUseModal = ({ showModal, closeModal }) => (
  <Modal dimmer='blurring' size='large' open={showModal}>
    <Modal.Header>Terms Of Use</Modal.Header>
    <Modal.Content>
      <div>
        <p>Last Updated 3/11/2020</p>
        <p>These Terms of Use govern how you may use the Peak Workflow™ web platform (the “Platform”) and the tools and features found here.&nbsp;The Platform is owned by Conductive Digital, LLC, who has licensed the right to use the Platform to business partners and customers (“Platform Licensees”) pursuant to its standard Master License Agreement and Sublicense Agreements (the “Platform License.”).&nbsp;The Platform License will govern in the event of any conflict between the Platform License and these Terms.&nbsp;You may use the Platform only as an employee or independent contractor of an authorized Platform Licensee, and by using the Platform, you agree to all of these Terms of Use, applicable terms in the Platform License and to our Privacy Policy as explained below.&nbsp; <br />
          <strong>YOUR USE OF THE PLATFORM</strong><br />
          You represent and warrant to us that you are an authorized employee and/or independent contractor of a Platform Licensee, and that you will only use the Platform as authorized under the Platform License and these terms. You acknowledge and agree that we may monitor and track your use of the Platform to ensure compliance with these terms and analyze use of the Platform, and that any questions, suggestions, ideas, and feedback you give us may be freely used by us to assess, evaluate, and improve the Platform. &nbsp;</p>
        <p><strong>SITE SECURITY</strong><br />
          You agree to take all reasonable measures to protect and keep confidential your login credentials for the Platform and the non-public aspects of the Platform. You agree not to share, disclose, or make copies of, any parts of the Platform, or allow others to access or use the Platform. You may not violate or attempt to violate the operational integrity or security of the Platform, or use or attempt to use the Platform for any unauthorized or unlawful purpose. Such violations may result in civil or criminal liability. We may involve, and cooperate with, the Licensee and law enforcement authorities in prosecuting anyone who is involved in such violations.</p>
        <p><strong>RIGHTS IN PLATFORM TOOLS AND FEATURES</strong><br />
          Unless otherwise noted, the features and tools of the Platform, including software, code, user interfaces, text, images, designs, photos, video, and other content (collectively, the “Tools”) are owned and/or licensed by Conductive Digital, LLC. You expressly acknowledge and agree that no right, title or interest in any of the Platform’s features or Tools is transferred to you as a result of accessing or using them. All intellectual property rights in the Platform, including copyright, patent, trademark, trade secret, and trade dress rights, are expressly reserved by Conductive Digital, LLC. </p>
        <p><strong>PERSONAL INFORMATION</strong><br />
          Your submission of personal information to us is governed by our <u>privacy policy</u>. </p>
        <p><strong>DISCLAIMER AND LIMITATION OF LIABILITY</strong><br />
          The Platform, and the features, information, data and Tools found on the Platform, are provided “as is” without warranties of any kind, either express or implied, including without limitation, warranties of title, implied warranties of merchantability, fitness for a particular purpose or non-infringement of intellectual property. We make no guarantee that the features and Tools of the Platform will be uninterrupted or error-free, that the servers and networks making the Platform available will be free of viruses or other harmful components. We make no warranty or representation regarding the results, reliability, accuracy, or currency of any information, services, Tools or features associated with or acquired pursuant to the use of the Platform. You agree that your use of the Platform is at your own risk, and we are not liable for any damages of any kind related to your use of the Platform.</p>
        <p><strong>INDEMNITY</strong><br />
          You agree to defend, indemnify and hold Conductive Digital, LLC, its directors, officers, employees, agents and affiliates harmless from any and all claims, liabilities, damages, costs and expenses, including reasonable attorneys' fees, in any way arising from, related to or in connection with your use of the Platform, the Tools, or your violation of these terms.</p>
        <p><strong>DISPUTE RESOLUTION</strong><br />
          You agree that in the event of any claim, action or dispute arising out of or related to your use of the Platform, or the breach, enforcement, interpretation, or validity of these terms, you will first communicate in writing to us at the contact address used in connection with the Platform, and describe the facts and circumstances, and allow us at least thirty days to respond or resolve the dispute prior to taking any other form of action, including legal action. </p>
        <p><strong>CHOICE OF LAW</strong><br />
          These terms shall be construed in accordance with the laws of the State of Minnesota, without regard to any conflict of law provisions. Any dispute arising under these terms shall be resolved exclusively by the Minnesota state or federal courts located in Hennepin County. No modification or waiver of these terms shall be enforceable unless in writing signed by us. These terms are severable, and if any one is deemed unenforceable, all other terms shall remain in full force and effect. These terms, along with the other agreements and policies referenced herein, constitute the entire agreement between us relating to your use of the Platform.</p>
        <p><strong>CHANGES TO THESE TERMS</strong><br />
          We may update these Terms of Use from time to time. When we do, we revise the “Last Updated” date at the top of this page. We encourage you to check this page periodically for any changes. You acknowledge and agree that it is your responsibility to review these Terms of Use periodically and become aware of modifications.</p>
        <p><strong>CONTACTING US</strong><br />
          If you have any questions about these Terms of Use, or your experience with the Platform, or want to notify us of anything, please contact us at: info@conductivedigital.com </p>
      </div>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={closeModal} primary content='Close' />
    </Modal.Actions>
  </Modal>
)

export default TermsOfUseModal;