import gql from 'graphql-tag';

export const LIST_PROJECTS_QUERY = gql`
  query listProjects($limit: Int, $offset: Int) {
    listProjects(limit: $limit, offset: $offset) {
      items {
        id
        projectName
      }
    }
  }
`;

export const GET_PROJECT_BY_PROJECT_ID_QUERY = gql`
  query GetProject($projectId: ID!) {
    getProject(id: $projectId) {
      id
      projectName
      projectNumber
      projectCategory
      projectTimeZone
      projectHasBlackOuts
      projectConfiguration
      projectSequence
      projectBucket
      isDemo
      isActive
      longitude
      latitude
      projectMobileTemplates {
        settings {
          label
          value
        }
        mobileTemplates {
          workOrderType
          screens {
            name
            title
            backAction
            components {
              group
              items {
                type
                separatorVisible
                componentHeight
                presentationStyle
                instructionText
                isEditable
                title
                titlePrefix
                titleData
                subtitleData
                infoImageData
                infoStyle
                fontSize
                isMultilineText
                action
                actionParameter
                dataProperty
                dataPropertyType
                customDataSection
                photoType
                captureGps
                switchVisible
                switchOnText
                switchDataProperty
                defaultSwitchValue
                isMultiSelect
                alignment
                colorStyle
                inventoryType
                isMandatory
                storeDestination
                validationDataProperty
                placeholderText
                trueText
                falseText
                titleWidth
                refreshButtonVisible
                coreLocationVisible
                infoAdditionalData
                items {
                  type
                  separatorVisible
                  componentHeight
                  presentationStyle
                  instructionText
                  isEditable
                  title
                  titlePrefix
                  titleData
                  subtitleData
                  infoImageData
                  infoStyle
                  fontSize
                  isMultilineText
                  action
                  actionParameter
                  dataProperty
                  dataPropertyType
                  customDataSection
                  photoType
                  captureGps
                  switchVisible
                  switchOnText
                  switchDataProperty
                  defaultSwitchValue
                  isMultiSelect
                  alignment
                  colorStyle
                  inventoryType
                  isMandatory
                  storeDestination
                  validationDataProperty
                  placeholderText
                  trueText
                  falseText
                  titleWidth
                  refreshButtonVisible
                  coreLocationVisible
                  infoAdditionalData
                }
              }
            }
          }
        }
      }
      projectWorkOrderType
      projectShortCode
      projectImportConfig
      projectExportConfig
      autoImport
      projectDefaults {
        defaultTypeName
        defaultTypeValues {
          label
          value
          sortOrder
          isDefault
        }
      }
    }
  }
`;

export const LIST_PROJECTS_BY_TENANT_QUERY = gql`
  query listProjectsByTenant($isActive: Boolean, $limit: Int, $offset: Int, $tenantId: ID!) {
    listProjectsByTenant(isActive: $isActive,limit: $limit, offset: $offset, tenantId: $tenantId) {
      items {
        id
        projectName
        projectNumber
        projectCategory
        longitude
        latitude
        projectConfiguration
        projectWorkOrderType
        projectHasBlackOuts
        projectShortCode
        projectTimeZone
        autoImport
        projectBucket
        isActive
        isDemo
      }
    }
  }
`;

export const LIST_PROJECTS_BY_TENANT_QUERY_SHORT = gql`
  query listProjectsByTenant($isActive: Boolean, $limit: Int, $offset: Int, $tenantId: ID!) {
    listProjectsByTenant(isActive: $isActive,limit: $limit, offset: $offset, tenantId: $tenantId) {
      items {
        id
        projectName
        projectWorkOrderType
        projectShortCode
        projectTimeZone
        projectBucket
        isActive
        isDemo
      }
    }
  }
`;

export const LIST_PROJECTS_BY_TENANT_QUERY_GANTT = gql`
  query listProjectsByTenant($isActive: Boolean, $limit: Int, $offset: Int, $tenantId: ID!) {
    listProjectsByTenant(isActive: $isActive,limit: $limit, offset: $offset, tenantId: $tenantId) {
      items {
        id
        projectName
        projectWorkOrderType
        projectShortCode
        isActive
        isDemo
        projectStartDate
        projectEndDate
        projectCategory
        projectStatus
      }
    }
  }
`;

export const LIST_ALL_ACTIVE_PROJECT_LOCATIONS_BY_TENANT_QUERY = gql`
  query listAllActiveProjectLocationsByTenant($tenantId: ID!) {
    listAllActiveProjectLocationsByTenant(tenantId: $tenantId) {
      items {
        projectId
        count
        projectName
        latitude
        longitude
        projectShortCode
      }
    }
  }
`;



