import React, { useState } from 'react';
import { Modal, Button, Input } from 'semantic-ui-react';
import { createProjectReportPreferences } from '../../core/services/projectReportPreferences';
import REPORT_TYPES from '../../constants/reportTypes';
import CustomErrorMessage from '../../shared/components/messages/error';

const CustomReportViewModal = ({
  modalRef,
  showModal,
  closeModal,
  projectId,
  selectedReportView,
  userId,
  initReportViews,
  setSelectedReportView
}) => {
  const [reportName, setReportName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmit();
    }
  }

  const onSubmit = async () => {
    try {
      setError(null);
      setLoading(true);
      const inputData = {
        reportType: REPORT_TYPES.WORKORDER_REPORT,
        reportName,
        projectId
      };
      if (selectedReportView === userId) {
        inputData.userId = selectedReportView;
      } else {
        inputData.projectReportPreferencesId = selectedReportView;
      }
      const newReportViewId = await createProjectReportPreferences(inputData);
      await initReportViews();
      setSelectedReportView(newReportViewId);
      closeModal();
    } catch (error) {
      setError(error.message)
      setLoading(false);
    }
  }

  const onReportNameChange = (event, { value }) => {
    setReportName(value);
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>Save New Report View</Modal.Header>
        <Modal.Content>
          Report View Name: <Input value={reportName} type='text' placeholder='Report View Name' onChange={onReportNameChange} />
          {error && <CustomErrorMessage message={error} />}
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={loading} onClick={closeModal}>Cancel</Button>
          <Button disabled={reportName.length === 0} loading={loading} onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Save' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default CustomReportViewModal;