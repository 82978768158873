import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const DetailsActions = ({ onNext, closeModal, hideNext = false }) => (
  <div>
    {onNext && !hideNext && <Button color='grey' onClick={() => onNext()}>Next</Button>}
    <Button primary onClick={closeModal}>Close <Icon name='chevron right' /></Button>
  </div>
)

export default DetailsActions;