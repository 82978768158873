import React from 'react';
import { Menu, Header, Icon, Dropdown } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';

const trigger = (mapView) => (
  <span>
    View: <b>{mapView}</b>
  </span>
)

const TopMenu = ({ config, style = {}, onMapViewChange, mapView, mapViews }) => (
  <div style={{ display: 'flex', marginBottom: '14px' }}>
    <AppContext.Consumer>{({ toggleSideMenu }) => (
      <Menu stackable style={{ ...style, flex: '1' }}>
        <Menu.Item>
          <Icon name='list' onClick={toggleSideMenu} style={{ cursor: 'pointer', fontSize: '23px' }} />
        </Menu.Item>
        <Menu.Item>
          <Header><Icon name={config.header.iconName} />{config.header.title}</Header>
        </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item>
            <Dropdown trigger={trigger(mapView)} options={mapViews} onChange={onMapViewChange} value={mapView} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )}</AppContext.Consumer>
  </div>
)

export default TopMenu;