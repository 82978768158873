const USER_NOT_AUTHORIZED = 'AUTH_ERROR_CODE_001';
const WORKORDER_NOT_FOUND = 'WORKORDER_ERROR_CODE_001';
const INVENTORY_NOT_FOUND = 'INVENTORY_ERROR_CODE_001';
const INVENTORY_ALREADY_DEPLOYED = 'INVENTORY_ERROR_CODE_002';

export const formatError = (error) => {
  switch(error) {
    case USER_NOT_AUTHORIZED: {
      return 'Not authorized.';
    }
    case WORKORDER_NOT_FOUND: {
      return 'Work order not found.';
    }
    case INVENTORY_NOT_FOUND: {
      return 'Asset not found.';
    }
    case INVENTORY_ALREADY_DEPLOYED: {
      return 'Asset is already deployed.';
    }
    default: {
      return 'Unexpected error.';
    }
  }
}