import React, { Component } from 'react';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import AppContext from '../../core/context/app.context';
import TopMenu from './components/top-menu';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import * as quicksightService from '../../core/services/quicksight.service';
import Loader from '../../shared/components/loader';

class AnalysisMapViewRoute extends Component {
  static contextType = AppContext;
  _isMounted = false;

  state = {
    mapView: null,
    mapViews: [],
    loading: false
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    const { currentProject } = this.context;
    if (currentProject) {
      const analysisMapIds = currentProject.projectConfiguration.analysisMapIds;
      if (analysisMapIds && analysisMapIds.length > 0) {
        const mapViews = analysisMapIds.map(({ id, name }) => {
          return {
            key: id,
            text: name,
            value: name
          }
        })
        this.setState({ mapViews, mapView: mapViews[0].value });
        this.loadQuickSightDashboard(mapViews[0].key);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const { mapView, mapViews } = this.state;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Analysis Map View` : 'Analysis Map View'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu
      config={topMenuConfig}
      style={{ zIndex: 2 }}
      onMapViewChange={this.onMapViewChange}
      mapView={mapView}
      mapViews={mapViews} />;
  }

  renderAnalysisMapViewRouteContent = () => {
    return (
      <div id='dashboardContainer'></div>
    );
  }

  onMapViewChange = (event, data) => {
    const mapView = data.value;
    this.setState({ mapView });
    const dashboardId = this.state.mapViews.find(i => i.value === mapView).key;
    this.loadQuickSightDashboard(dashboardId);
  }

  loadQuickSightDashboard = async (dashboardId) => {
    this.setState({ loading: true });
    const dashboardContainerHTML = document.getElementById('dashboardContainer');
    dashboardContainerHTML.innerHTML = '';
    if (dashboardId) {
      const url = await quicksightService.getQuickSightDashboardEmbedUrl(dashboardId);
      const options = {
        url,
        container: dashboardContainerHTML,
        scrolling: 'yes',
        locale: 'en-US',
        footerPaddingEnabled: true,
        height: 'AutoFit',
      }
      const dashboard = embedDashboard(options);
      this.setState({ loading: false });
      dashboard.on('error', (error) => console.log(error));
      dashboard.on('load', () => { });
    }
  }

  render() {
    return (
      <ProjectRouteWrapper>
        <Loader loading={this.state.loading} />
        {this.renderTopMenu()}
        {this.renderAnalysisMapViewRouteContent()}
      </ProjectRouteWrapper>
    );
  }
}

export default AnalysisMapViewRoute;