import React, { Component } from 'react';
import { Button, Icon, Image, Message, Checkbox } from 'semantic-ui-react';
import AppContext from '../../../core/context/app.context';
import TopMenu from '../../../shared/components/top-menu';
import BACKGROUND_IMAGE from '../../../assets/ReportBackground.png';
import ErrorMessage from '../../../shared/components/messages/error';
import config from '../../../config';
import { getToken } from '../../../core/services/auth.service';
import RouteWrapper from '../../../hoc/routeWrapper';
import routeMaps from '../../../core/route-maps';

class AssignmentsReportRoute extends Component {

  static contextType = AppContext;

  state = {
    error: '',
    exporting: false,
    assignmentsOnly: false
  }

  componentDidMount = () => {
    const { history } = this.props;
    const { currentTenant } = this.context;
    if (!currentTenant) {
      history.push(routeMaps.administrationProjects);
    }
  }

  renderTopMenu = () => {
    const { currentTenant } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentTenant && currentTenant.tenantName ? `${currentTenant.tenantName} - Assignments Report` : 'Assignments Report'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  downloadReport = async (tenantId, assignmentsOnly) => {
    try {
      const token = await getToken();
      let response = await fetch(`${config.adminExportAssignmentsReportApi}/${tenantId}/${assignmentsOnly}`, {
        method: 'get',
        headers: new Headers({
          'Authorization': token
        })
      });
      if (!response.ok) {
        throw new Error('No data found');
      }
      const url = await response.json();
      window.open(url);
    } catch (error) {
      throw error;
    }
  }

  setAssignmentsOnly = () => {
    const { assignmentsOnly } = this.state;
    this.setState({ assignmentsOnly: !assignmentsOnly });
  }

  export = async () => {
    this.setState({ error: '' });
    try {
      const { currentTenant } = this.context;
      const { assignmentsOnly } = this.state;
      this.setState({ exporting: true });
      await this.downloadReport(currentTenant.id, assignmentsOnly);
      this.setState({ exporting: false });
    } catch (error) {
      this.setState({ error: error.message, exporting: false });
    }
  }

  renderAssignmentsReportRouteContent = () => {
    const { error, exporting, assignmentsOnly } = this.state;
    return (
      <React.Fragment>
        <Message color='grey' content='This report is only shown for Account Admins, it exports a Summary view of work order assignments across all projects and technicians.' />
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: '10px' }}>
            <Checkbox checked={assignmentsOnly} onChange={this.setAssignmentsOnly} label='Assignments only' />
          </div>
          <Button loading={exporting} onClick={this.export} style={{ marginLeft: '10px' }}><Icon name='download' />Export</Button>
        </div>
        {error && <div style={{ marginTop: '10px' }}>
          <ErrorMessage message={error} />
        </div>}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
          <Image src={BACKGROUND_IMAGE} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <RouteWrapper>
        {this.renderTopMenu()}
        {this.renderAssignmentsReportRouteContent()}
      </RouteWrapper>
    );
  }
}

export default AssignmentsReportRoute;