import React, { useEffect } from 'react';
import { Fade } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup as KendoNotificationGroup } from '@progress/kendo-react-notification';
import { connect } from 'react-redux';
import * as schedulerActions from '../../../core/redux/actions/scheduler.actions';

let appointmentStartDateWarningTimeoutRef;
let errorTimeoutRef;

const NotificationGroup = ({
  appointmentStartDateWarning,
  setAppointmentStartDateWarning,
  error,
  setError
}) => {
  const timeoutCleanUp = () => {
    if (appointmentStartDateWarningTimeoutRef) {
      clearTimeout(appointmentStartDateWarningTimeoutRef);
      appointmentStartDateWarningTimeoutRef = undefined;
    }
    if (errorTimeoutRef) {
      clearTimeout(errorTimeoutRef);
      errorTimeoutRef = undefined;
    }
  }

  useEffect(() => {
    timeoutCleanUp();
    if (appointmentStartDateWarning) {
      appointmentStartDateWarningTimeoutRef = setTimeout(() => {
        setAppointmentStartDateWarning(false)
      }, 10000);
    }
    if (error) {
      errorTimeoutRef = setTimeout(() => {
        setError(null);
      }, 10000);
    }
  }, [appointmentStartDateWarning, error]);

  useEffect(() => {
    return () => {
      timeoutCleanUp();
    }
  }, []);

  return (
    <KendoNotificationGroup style={{
      bottom: 10,
      right: 25,
      alignItems: 'flex-end',
      zIndex: 1
    }}>
      {appointmentStartDateWarning && (
        <Fade enter={true} exit={true}>
          <Notification
            type={{
              style: 'warning',
              icon: true,
            }}
            closable={true}
            onClose={() => setAppointmentStartDateWarning(false)}
          >
            <span>Warning! Appointment start date is in the past.</span>
          </Notification>
        </Fade>
      )}
      {error && (
        <Fade enter={true} exit={true}>
          <Notification
            type={{
              style: 'error',
              icon: true,
            }}
            closable={true}
            onClose={() => setError(null)}
          >
            <span>{error}</span>
          </Notification>
        </Fade>
      )}
    </KendoNotificationGroup>
  )
}

const mapStateToProps = ({ scheduler }) => {
  return {
    error: scheduler.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setError: (error) => dispatch(schedulerActions.setError(error)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationGroup);