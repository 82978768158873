import client from '../apollo';
import { LIST_PROJECTS_QUERY, GET_PROJECT_BY_PROJECT_ID_QUERY, LIST_PROJECTS_BY_TENANT_QUERY, LIST_PROJECTS_BY_TENANT_QUERY_SHORT, LIST_PROJECTS_BY_TENANT_QUERY_GANTT, LIST_ALL_ACTIVE_PROJECT_LOCATIONS_BY_TENANT_QUERY } from '../../graphql/queries/project';
import { CREATE_PROJECT_MUTATION, UPDATE_PROJECT_MUTATION, UPDATE_PROJECT_PLANNING_ROUTE_CODE_ORDER_MUTATION } from '../../graphql/mutations/project';

export const getAllProjects = async () => {
  try {
    let result = [];
    let offset = null;
    do {
      const { data } = await client.query({
        query: LIST_PROJECTS_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          offset,
          limit: 1000
        }
      });
      const projects = data.listProjects;
      if (projects && projects.items && projects.items.length > 0) {
        result = [...result, ...projects.items];
        offset = projects.items.length + (offset || 0);
      } else {
        offset = null;
      }
    } while (offset !== null);
    return result;
  } catch (error) {
    console.log(error);
  }
}

export const getProjectByProjectId = async (projectId) => {
  try {
    const { data } = await client.query({
      query: GET_PROJECT_BY_PROJECT_ID_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        projectId
      }
    });
    return data && data.getProject ? data.getProject : null;
  } catch (error) {
    console.log(error);
  }
}

export const listProjectsByTenant = async (tenantId, isActive) => {
  try {
    let result = [];
    let offset = null;
    do {
      const { data } = await client.query({
        query: LIST_PROJECTS_BY_TENANT_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          isActive,
          offset,
          limit: 100,
          tenantId
        }
      });
      const projects = data.listProjectsByTenant;
      if (projects && projects.items && projects.items.length > 0) {
        result = [...result, ...projects.items];
        offset = projects.items.length + (offset || 0);
      } else {
        offset = null;
      }
    } while (offset !== null);
    return result;
  } catch (error) {
    console.log(error);
  }
}

export const getAllProjectsByTenantShort = async (tenantId) => {
  try {
    let result = [];
    let offset = null;
    const { data } = await client.query({
      query: LIST_PROJECTS_BY_TENANT_QUERY_SHORT,
      fetchPolicy: 'no-cache',
      variables: {
        offset,
        limit: 1000,
        tenantId
      }
    });
    const projects = data.listProjectsByTenant;
    if (projects && projects.items && projects.items.length > 0) {
      result = [...result, ...projects.items];
    }
    return result;
  } catch (error) {
    console.log(error);
  }
}

export const getProjectsByTenantGantt = async (tenantId) => {
  try {
    let result = [];
    let offset = null;
    const { data } = await client.query({
      query: LIST_PROJECTS_BY_TENANT_QUERY_GANTT,
      fetchPolicy: 'no-cache',
      variables: {
        offset,
        limit: 1000,
        isActive: true,
        tenantId
      }
    });
    const projects = data.listProjectsByTenant;
    if (projects && projects.items && projects.items.length > 0) {
      result = [...result, ...projects.items];
    }
    return result;
  } catch (error) {
    console.log(error);
  }
}

// Anytown
export const isDemoProject = (projectId) => {
  return projectId === '5342bef0-db8b-4ed5-9558-a9b0d247bf26';
}

export const updateProjectPlanningRouteCodeOrder = async (projectId, planningRouteCodeOrder) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT_PLANNING_ROUTE_CODE_ORDER_MUTATION,
      variables: {
        projectId,
        planningRouteCodeOrder
      }
    });
    const projectSequence = data && data.updateProjectPlanningRouteCodeOrder && data.updateProjectPlanningRouteCodeOrder.projectSequence ? JSON.parse(data.updateProjectPlanningRouteCodeOrder.projectSequence) : null;
    return projectSequence;
  } catch (error) {
    console.log(error);
  }
}

export const createProject = async (project, tenantId) => {
  try {
    await client.mutate({
      mutation: CREATE_PROJECT_MUTATION,
      variables: {
        ...project,
        tenantId
      }
    });
  } catch (error) {
    throw new Error('Unexpected error occured during create project');
  }
}

export const updateProject = async (project) => {
  try {
    await client.mutate({
      mutation: UPDATE_PROJECT_MUTATION,
      variables: {
        ...project
      }
    });
  } catch (error) {
    throw new Error('Unexpected error occured during create project');
  }
}

export const listAllActiveProjectLocationsByTenant = async (tenantId) => {
  try {
    const { data } = await client.query({
      query: LIST_ALL_ACTIVE_PROJECT_LOCATIONS_BY_TENANT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId
      }
    });
    return data && data.listAllActiveProjectLocationsByTenant && data.listAllActiveProjectLocationsByTenant.items ? data.listAllActiveProjectLocationsByTenant.items : [];
  } catch (error) {
    console.log(error);
  }
}