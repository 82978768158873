import React from 'react';
import { Table, Checkbox, Message } from 'semantic-ui-react';

const AuditDataTab = ({ data, workOrderAuditDate }) => {
  if (!data) {
    data = [];
  }
  return (
    <React.Fragment>
      {(data && data.length > 0) || workOrderAuditDate ? (
        <Table definition compact>
          <Table.Body>
            {data.map((customData, index) =>
              <Table.Row key={index}>
                <Table.Cell width={6}>{customData.key}</Table.Cell>
                {customData.type === 'Boolean' ? (
                  <Table.Cell width={6}>
                    <Checkbox disabled={true} checked={customData.value === 'true'} />
                  </Table.Cell>
                ) : (
                    <Table.Cell width={6}>{customData.value}</Table.Cell>
                  )}
              </Table.Row>
            )}
            {workOrderAuditDate &&
              <Table.Row>
                <Table.Cell width={6}>Audit Date</Table.Cell>
                <Table.Cell width={6}>{workOrderAuditDate}</Table.Cell>
              </Table.Row>}
          </Table.Body>
        </Table>
      ) : <Message color='grey' >No Data Available...</Message>}
    </React.Fragment>
  )
}

export default AuditDataTab;