import React, { useState } from 'react';
import { Button, Dropdown, Input, Modal } from 'semantic-ui-react';
import { capitalize } from '../../../../helpers/string';
import REPORT_TABLE_COLUMNS from '../../../../constants/reportTableColumns';
import { EXPORT_ARGUMENT_VALUE_TYPE, EXPORT_TRANSFORMATION_CONDITION_OPERATION } from '../constants';

const argumentSchema = {
  type: '',
  value: ''
}

const ExportReportTemplateTransformationConditionModal = ({ showModal, closeModal, onSaveTransformationCondition, workOrderType }) => {
  const [condition, setCondition] = useState({ name: EXPORT_TRANSFORMATION_CONDITION_OPERATION.match, table_column_name: '' });
  const [argument, setArgument] = useState({ ...argumentSchema });

  const onSubmit = () => {
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.json) {
      argument.value = JSON.parse(argument.value);
    }
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.number) {
      argument.value = +argument.value;
    }
    onSaveTransformationCondition({ ...condition, value: argument.value });
  }

  const onTableColumnNameChange = (event, { value }) => {
    setCondition({ ...condition, table_column_name: value });
  }

  const onArgumentTypeChange = (event, { value }) => {
    setArgument({ ...argument, type: value });
  }

  const onArgumentValueChange = (event, { value }) => {
    setArgument({ ...argument, value });
  }

  const getTableColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  return (
    <Modal dimmer='blurring' size='small' open={showModal}>
      <Modal.Header>New Transformation Condition</Modal.Header>
      <Modal.Content>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Table Column:</span>
          <Dropdown
            search
            placeholder='Table Column'
            selection
            className='export-dropdown'
            value={condition.table_column_name}
            onChange={onTableColumnNameChange}
            options={getTableColumns()} />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Condition Name:</span>
          <Dropdown
            search
            placeholder='Condition Name'
            selection
            className='export-transformation-type-dropdown'
            value={condition.name}
            options={[
              { key: 0, value: EXPORT_TRANSFORMATION_CONDITION_OPERATION.match, text: capitalize(EXPORT_TRANSFORMATION_CONDITION_OPERATION.match) },
            ]} />
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Type:</span>
          <Dropdown
            placeholder='Value Type'
            className='export-transformation-value-type-small-dropdown'
            value={argument.type}
            onChange={onArgumentTypeChange}
            selection
            options={[
              { key: EXPORT_ARGUMENT_VALUE_TYPE.string, value: EXPORT_ARGUMENT_VALUE_TYPE.string, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.string) },
              { key: EXPORT_ARGUMENT_VALUE_TYPE.number, value: EXPORT_ARGUMENT_VALUE_TYPE.number, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.number) },
              { key: EXPORT_ARGUMENT_VALUE_TYPE.json, value: EXPORT_ARGUMENT_VALUE_TYPE.json, text: EXPORT_ARGUMENT_VALUE_TYPE.json.toUpperCase() }
            ]} />
          <span style={{ paddingRight: '10px' }}>Value:</span>
          <Input onChange={onArgumentValueChange} value={argument.value} type={argument.type === EXPORT_ARGUMENT_VALUE_TYPE.number ? EXPORT_ARGUMENT_VALUE_TYPE.number : EXPORT_ARGUMENT_VALUE_TYPE.string} placeholder='Value' className='export-operation-value-input' />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Save' />
      </Modal.Actions>
    </Modal>
  )
}

export default ExportReportTemplateTransformationConditionModal;