import gql from 'graphql-tag';

export const CREATE_COMMENT_MUTATION = gql`
  mutation createComment($userId: ID, $content: String!, $workOrderId: ID!) {
    createComment(input:{
      content: $content
      user: $userId
      workOrder: $workOrderId
    }) {
      id
    }
  }
`;