import React, { Component } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Button, Icon, Dropdown } from 'semantic-ui-react';
import InventoryAssigmentsModal from '../modal/assignments';
import { ASSIGN_INVENTORIES_MUTATION, UNASSIGN_INVENTORIES_MUTATION } from '../../../../graphql/mutations/inventory';
import AppContext from '../../../../core/context/app.context';
import { isDisabled, isEnabled } from '../../../../core/services/auth.service';
import * as userService from '../../../../core/services/user.service';
import Search from '../search';
import ROLES from '../../../../constants/roles';
import ConfirmActionModal from '../modal/confirmActionModal';
import * as inventoryService from '../../../../core/services/inventory.service';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import withApollo from '../../../../hoc/withApollo';
import AssignAllConfirmModal from '../modal/assignAllConfirmModal';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import { CONFIRM_MODAL_TYPES } from '../../constants';

class InventoryAllAssetsListView extends Component {

  static contextType = AppContext;
  searchRef = React.createRef();
  assignAllConfirmModalRef = React.createRef();

  state = {
    sort: [],
    showAssignModal: false,
    showUnAssignModal: false,
    selectedInventories: [],
    users: [],
    error: false,
    searchBy: '',
    pageLimit: 0,
    mfgSerialNumber: '',
    assignUsersList: [],
    unassingUsersList: [],
    assigning: false,
    filterBy: [],
    filterTypes: [],
    advancedInventoryManagement: false,
    multipleWarehouses: [],
    showConfirmActionModal: false,
    confirmModalType: null,
    showAssignAllConfirmModal: false,
    loadingTechnicians: false,
    assetDestination: null
  }

  async componentWillMount() {
    try {
      const { pageLimit } = this.props;
      const { currentProject } = this.context;
      const advancedInventoryManagement = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.advancedInventoryManagement ? currentProject.projectConfiguration.additionalFeatures.advancedInventoryManagement : false;
      const multipleWarehouses = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.multipleWarehouses ? currentProject.projectConfiguration.additionalFeatures.multipleWarehouses : false;
      this.setState({ advancedInventoryManagement, loadingTechnicians: true, multipleWarehouses: multipleWarehouses || [] });
      const users = await userService.listInventoryTechniciansByProject(currentProject.id);
      this.setState({ loadingTechnicians: false });
      const filterTypes = await this.getFilterTypes(advancedInventoryManagement, multipleWarehouses);
      this.setState({ users, pageLimit, filterTypes });
    } catch (error) {
      this.setState({ error: true });
    }
  }

  componentWillReceiveProps(newProps) {
    const { pageLimit } = newProps;
    this.setState({ pageLimit });
  }

  getFilterTypes = async (advancedInventoryManagement, multipleWarehouses) => {
    try {
      const { currentProject } = this.context;
      const filterTypes = await inventoryService.getFilterTypes(currentProject.id, advancedInventoryManagement, multipleWarehouses);
      let filters = [];
      Object.keys(filterTypes).forEach(filterType => {
        filterTypes[filterType].forEach(filterName => {
          filters.push({ id: filters.length, filterName });
        });
      });
      return filters;
    } catch (error) {
      console.log(error);
    }
  }

  onAssetDestinationChange = (event, { value }) => {
    this.setState({ assetDestination: value });
  }

  onSearch = () => {
    const { filterBy } = this.state;
    const searchBy = this.searchRef.current.value.trimLeft().trimRight().replaceAll(`'`,`\\'`);
    const { getInventories } = this.props;
    this.setState({ selectedInventories: [], searchBy });
    getInventories(null, searchBy, filterBy);
  }

  onSortChange = event => {
    this.setState({ sort: event.sort });
  }

  openAssignAllConfirmModal = () => {
    this.setState({ showAssignAllConfirmModal: true });
  }

  closeAssignAllConfirmModal = () => {
    this.setState({ showAssignAllConfirmModal: false });
  }

  openAssignModal = () => {
    this.setState({ showAssignModal: true });
  }

  openUnAssignModal = () => {
    this.setState({ showUnAssignModal: true });
  }

  closeAssignModal = () => {
    this.setState({ showAssignModal: false, assignUsersList: [], unassingUsersList: [] });
  };

  openConfirmActionModalModal = (confirmModalType) => {
    this.setState({ showConfirmActionModal: true, confirmModalType });
  }

  closeConfirmActionModal = () => {
    this.setState({ showConfirmActionModal: false, confirmModalType: null, assetDestination: null });
  }

  onFilterTypeChange = (event) => {
    const { getInventories } = this.props;
    let { filterBy, searchBy } = this.state;
    const newFilterList = event.target.value;
    filterBy =
      newFilterList.length > 0
        ? newFilterList.map(filter => filter.filterName)
        : [];
    if (filterBy.length === 0) {
      getInventories(null, searchBy);
    }
    this.setState({ filterBy });
  };

  selectionChange = event => {
    let { selectedInventories } = this.state;
    event.dataItem.selected = !event.dataItem.selected;
    const inventoryIndex = selectedInventories.findIndex(inventoryId => inventoryId === event.dataItem.id);
    if (inventoryIndex > -1) {
      selectedInventories.splice(inventoryIndex, 1)
    } else {
      selectedInventories.push(event.dataItem.id);
      this.setState({ mfgSerialNumber: event.dataItem.mfgSerialNumber });
    }
    this.setState({ selectedInventories });
    this.forceUpdate();
  }

  headerSelectionChange = event => {
    let { inventories } = this.props;
    const checked = event.syntheticEvent.target.checked;
    inventories.forEach(item => item.selected = checked);
    const inventoriesIds = checked ? inventories.map(inventory => inventory.id) : [];
    this.setState({ selectedInventories: inventoriesIds });
    this.forceUpdate();
  }

  rowClick = event => {
    const { inventories } = this.props;
    let { selectedInventories } = this.state;
    selectedInventories = [];
    let last = this.lastSelectedIndex;
    const current = inventories.findIndex(dataItem => dataItem === event.dataItem);
    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }
    if (!event.nativeEvent.ctrlKey) {
      inventories.forEach(item => item.selected = false);
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      inventories[i].selected = select;
      selectedInventories.push(inventories[i].id);
      this.setState({ selectedInventories, mfgSerialNumber: inventories[i].mfgSerialNumber });
    }
    this.forceUpdate();
  }

  addUserToAssignedList = (event, data) => {
    const { assignUsersList, unassingUsersList } = this.state;
    const userId = data.value;
    const assingUsersListIndex = assignUsersList.indexOf(userId);
    const unassingUsersListIndex = unassingUsersList.indexOf(userId);
    assingUsersListIndex > -1 ? assignUsersList.splice(assingUsersListIndex, 1) : assignUsersList.push(userId);
    if (unassingUsersListIndex > -1) {
      unassingUsersList.splice(unassingUsersListIndex, 1);
    }
    this.setState({ assignUsersList });
  }

  addUserToUnAssignedList = (event, data) => {
    const { unassingUsersList, assignUsersList } = this.state;
    const userId = data.value;
    const unassingUsersListIndex = unassingUsersList.indexOf(userId);
    const assingUsersListIndex = assignUsersList.indexOf(userId);
    unassingUsersListIndex > -1 ? unassingUsersList.splice(unassingUsersListIndex, 1) : unassingUsersList.push(userId);
    if (assingUsersListIndex > -1) {
      assignUsersList.splice(assingUsersListIndex, 1);
    }
    this.setState({ unassingUsersList });
  }

  handleInventoryAssigments = async (mutation, users) => {
    let { selectedInventories, searchBy, filterBy } = this.state;
    let { getInventories } = this.props;
    try {
      const { client } = this.props;
      const { currentProject } = this.context;
      await client.mutate({
        mutation,
        variables: { projectId: currentProject.id, userIds: users, inventoryIds: selectedInventories }
      });
      getInventories(null, searchBy, filterBy);
    } catch (error) {
      console.log(error);
    }
  }

  removeAssets = async () => {
    let { getInventories } = this.props;
    const { selectedInventories, searchBy, filterBy } = this.state;
    const { currentProject } = this.context;
    await inventoryService.removeAssets(currentProject.id, selectedInventories);
    this.setState({ selectedInventories: [], showConfirmActionModal: false, confirmModalType: null });
    getInventories(null, searchBy, filterBy);
  }

  transferToUtility = async () => {
    let { getInventories } = this.props;
    const { selectedInventories, searchBy, filterBy } = this.state;
    const { currentProject } = this.context;
    await inventoryService.transferToUtility(currentProject.id, selectedInventories);
    this.setState({ selectedInventories: [], showConfirmActionModal: false, confirmModalType: null });
    getInventories(null, searchBy, filterBy);
  }

  releaseToProduction = async () => {
    let { getInventories } = this.props;
    const { selectedInventories, searchBy, filterBy } = this.state;
    const { currentProject } = this.context;
    await inventoryService.releaseToProduction(currentProject.id, selectedInventories);
    this.setState({ selectedInventories: [], showConfirmActionModal: false, confirmModalType: null });
    getInventories(null, searchBy, filterBy);
  }

  onModalConfirm = async () => {
    let { setLoading } = this.props;
    setLoading(true);
    const { currentProject } = this.context;
    const { assignUsersList, unassingUsersList } = this.state;
    this.setState({ assigning: true });
    if (assignUsersList.length > 0) {
      await this.handleInventoryAssigments(ASSIGN_INVENTORIES_MUTATION, assignUsersList);
    }
    if (unassingUsersList.length > 0) {
      await this.handleInventoryAssigments(UNASSIGN_INVENTORIES_MUTATION, unassingUsersList);
    }
    this.setState({ loadingTechnicians: true });
    const users = await userService.listInventoryTechniciansByProject(currentProject.id);
    this.closeAssignModal();
    this.setState({ loadingTechnicians: false, selectedInventories: [], assignUsersList: [], unassingUsersList: [], users, assigning: false });
    setLoading(false);
  }

  pageChange = async ({ page }) => {
    const { getInventories, changePageLimit } = this.props;
    const { searchBy, filterBy } = this.state;
    await changePageLimit(page.take);
    getInventories(page.skip, searchBy, filterBy);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }

  assignAllInventories = async () => {
    this.closeAssignAllConfirmModal();
    const { currentProject } = this.context;
    const { getInventories, setLoading } = this.props;
    setLoading(true);
    await inventoryService.assignAllInventoriesByProjectId(currentProject.id);
    this.setState({ selectedInventories: [] });
    getInventories();
  }

  returnAsset = async (event) => {
    try {
      const { mfgSerialNumber, searchBy, filterBy } = this.state;
      const { getInventories } = this.props;
      const { currentProject } = this.context;
      await inventoryService.returnAsset(currentProject.id, mfgSerialNumber);
      this.setState({ selectedInventories: [], assignUsersList: [], unassingUsersList: [], showConfirmActionModal: false });
      getInventories(null, searchBy, filterBy);
    } catch (error) {
      throw error;
    }
  }

  approveForTransfer = async () => {
    let { getInventories } = this.props;
    const { selectedInventories, searchBy, filterBy } = this.state;
    const { currentProject } = this.context;
    await inventoryService.approveForTransfer(currentProject.id, selectedInventories);
    this.setState({ selectedInventories: [], showConfirmActionModal: false, confirmModalType: null });
    getInventories(null, searchBy, filterBy);
  }

  transferToWarehouse = async () => {
    let { getInventories } = this.props;
    const { selectedInventories, searchBy, filterBy, assetDestination } = this.state;
    const { currentProject } = this.context;
    await inventoryService.transferToWarehouse(currentProject.id, selectedInventories, assetDestination);
    this.setState({ selectedInventories: [], showConfirmActionModal: false, confirmModalType: null, assetDestination: null });
    getInventories(null, searchBy, filterBy);
  }

  receiveForProduction = async () => {
    let { getInventories } = this.props;
    const { selectedInventories, searchBy, filterBy } = this.state;
    const { currentProject } = this.context;
    await inventoryService.receiveForProduction(currentProject.id, selectedInventories);
    this.setState({ selectedInventories: [], showConfirmActionModal: false, confirmModalType: null });
    getInventories(null, searchBy, filterBy);
  }

  moreDropDownOptions = () => {
    const { advancedInventoryManagement, selectedInventories, multipleWarehouses } = this.state;
    const { user } = this.context;

    const isReturnAssetDisabled = selectedInventories.length !== 1 || !isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin]);
    const isReleaseToProductionDisabled = selectedInventories.length === 0 || !isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);
    const isTransferToUtilityDisabled = selectedInventories.length === 0 || !isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);
    const isRemoveRejectDisabled = selectedInventories.length === 0 || !isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);
    const isApproveForTransferDisabled = selectedInventories.length === 0 || !isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);
    const isTransferToWarehouseDisabled = selectedInventories.length === 0 || !isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);
    const isReceiveForProductionDisabled = selectedInventories.length === 0 || !isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);

    let options = [{ key: 0, text: 'Return Asset', disabled: isReturnAssetDisabled, onClick: () => !isReturnAssetDisabled && this.openConfirmActionModalModal(CONFIRM_MODAL_TYPES.RETURN_ASSET) }];

    if (advancedInventoryManagement && multipleWarehouses && multipleWarehouses.length > 0) {
      options.push({ key: 1, text: 'Approve for Transfer', disabled: isApproveForTransferDisabled, onClick: () => !isApproveForTransferDisabled && this.openConfirmActionModalModal(CONFIRM_MODAL_TYPES.APPROVE_FOR_TRANSFER) });
      options.push({ key: 2, text: 'Transfer to Warehouse', disabled: isTransferToWarehouseDisabled, onClick: () => !isTransferToWarehouseDisabled && this.openConfirmActionModalModal(CONFIRM_MODAL_TYPES.TRANSFER_TO_WAREHOUSE) });
      options.push({ key: 3, text: 'Receive for Production', disabled: isReceiveForProductionDisabled, onClick: () => !isReceiveForProductionDisabled && this.openConfirmActionModalModal(CONFIRM_MODAL_TYPES.RECEIVE_FOR_PRODUCTION) });
    }

    // console.log(advancedInventoryManagement, multipleWarehouses)

    if (advancedInventoryManagement && (multipleWarehouses === null || multipleWarehouses.length === 0)) {
      options.push({ key: 4, text: 'Release to Production', disabled: isReleaseToProductionDisabled, onClick: () => !isReleaseToProductionDisabled && this.openConfirmActionModalModal(CONFIRM_MODAL_TYPES.RELEASE_TO_PRODUCTION) });
    }

    if (advancedInventoryManagement) {
      options.push({ key: 5, text: 'Transfer to Utility', disabled: isTransferToUtilityDisabled, onClick: () => !isTransferToUtilityDisabled && this.openConfirmActionModalModal(CONFIRM_MODAL_TYPES.TRANSFER_TO_UTILITY) });
      options.push({ key: 6, text: 'Remove/Reject', disabled: isRemoveRejectDisabled, onClick: () => !isRemoveRejectDisabled && this.openConfirmActionModalModal(CONFIRM_MODAL_TYPES.REMOVE_REJECT) });
    }

    return options;
  }

  render() {
    const {
      sort,
      selectedInventories,
      error,
      users,
      showAssignModal,
      pageLimit,
      showConfirmActionModal,
      mfgSerialNumber,
      assignUsersList,
      unassingUsersList,
      assigning,
      filterTypes,
      advancedInventoryManagement,
      confirmModalType,
      showAssignAllConfirmModal,
      loadingTechnicians,
      multipleWarehouses,
      assetDestination
    } = this.state;
    const { inventories, currentOffset, total } = this.props;
    const { user, currentProject } = this.context;
    return (
      <div className='inventory-all-assets-list-view' onKeyPress={this.handleKeyPress}>
        <div className='inventory-all-assets-list-view-tool-bar'>
          {showAssignAllConfirmModal && <AssignAllConfirmModal
            showModal={showAssignAllConfirmModal}
            modalRef={this.assignAllConfirmModalRef}
            closeModal={this.closeAssignAllConfirmModal}
            onSubmit={this.assignAllInventories}
          />}
          {showAssignModal && <InventoryAssigmentsModal
            title='Assignment'
            iconName='users'
            error={error}
            users={users}
            showModal={showAssignModal}
            closeModal={this.closeAssignModal}
            onModalConfirm={this.onModalConfirm}
            addUserToAssignedList={this.addUserToAssignedList}
            addUserToUnAssignedList={this.addUserToUnAssignedList}
            assignUsersList={assignUsersList}
            unassingUsersList={unassingUsersList}
            assigning={assigning} />}
          {showConfirmActionModal && confirmModalType === CONFIRM_MODAL_TYPES.RETURN_ASSET && <ConfirmActionModal
            defaultErrorMessage={'Error. Unable to return the asset.'}
            title={'Return Asset to Inventory'}
            description={'This will allow an inventory asset to be redeployed and CANNOT be undone. It will not remove any existing links to a work order.'}
            onModalConfirm={this.returnAsset}
            showModal={showConfirmActionModal}
            closeModal={this.closeConfirmActionModal}
            mfgSerialNumber={mfgSerialNumber} />}
          {showConfirmActionModal && confirmModalType === CONFIRM_MODAL_TYPES.RELEASE_TO_PRODUCTION && <ConfirmActionModal
            description={'This will remove this item or group from quarantine and allow for deployment in the field. Assets in this state are available for assignment and deployment.'}
            defaultErrorMessage={'Error. Release to production has failed.'}
            title={'Release to Production'}
            onModalConfirm={this.releaseToProduction}
            showModal={showConfirmActionModal}
            closeModal={this.closeConfirmActionModal} />}
          {showConfirmActionModal && confirmModalType === CONFIRM_MODAL_TYPES.TRANSFER_TO_UTILITY && <ConfirmActionModal
            description={'Mark item(s) from no longer available inventory and transferred to utility for their exclusive use. Assets in this state may not be used.'}
            defaultErrorMessage={'Error. Transfer to utility has failed.'}
            title={'Transfer to Utility'}
            onModalConfirm={this.transferToUtility}
            showModal={showConfirmActionModal}
            closeModal={this.closeConfirmActionModal} />}
          {showConfirmActionModal && confirmModalType === CONFIRM_MODAL_TYPES.REMOVE_REJECT && <ConfirmActionModal
            description={'This will remove or reject this item or group from available inventory and mark as deleted.'}
            defaultErrorMessage={'Error. Remove/Reject has failed.'}
            title={'Remove/Reject'}
            onModalConfirm={this.removeAssets}
            showModal={showConfirmActionModal}
            closeModal={this.closeConfirmActionModal} />}
          {showConfirmActionModal && confirmModalType === CONFIRM_MODAL_TYPES.APPROVE_FOR_TRANSFER && <ConfirmActionModal
            description={'Mark item(s) as passed testing and awaiting transfer to another warehouse or distribution center. Assets in this state may not be used until transferred to a destination.'}
            defaultErrorMessage={'Error. Approve for Transfer has failed.'}
            title={'Approve for Transfer'}
            onModalConfirm={this.approveForTransfer}
            showModal={showConfirmActionModal}
            closeModal={this.closeConfirmActionModal} />}
          {showConfirmActionModal && confirmModalType === CONFIRM_MODAL_TYPES.TRANSFER_TO_WAREHOUSE && <ConfirmActionModal
            description={'Mark item(s) as In-Transit to a selected destination warehouse or distribution center. Assets in this state may not be used until received at the destination.'}
            defaultErrorMessage={'Error. Transfer to Warehouse has failed.'}
            title={'Transfer to Warehouse'}
            onModalConfirm={this.transferToWarehouse}
            showModal={showConfirmActionModal}
            closeModal={this.closeConfirmActionModal}
            disabled={!assetDestination}>
            <div style={{ paddingTop: 10 }}>
              <Dropdown onChange={this.onAssetDestinationChange} value={assetDestination} placeholder='Destination' selection options={multipleWarehouses.map(i => Object.assign({}, { key: i, text: i, value: i }))} />
            </div>
          </ConfirmActionModal>}
          {showConfirmActionModal && confirmModalType === CONFIRM_MODAL_TYPES.RECEIVE_FOR_PRODUCTION && <ConfirmActionModal
            modalType={confirmModalType}
            description={'Mark item(s) as received at destination and ready for deployment in the field. Assets in this state are available for assignment and deployment.'}
            defaultErrorMessage={'Error. Receive for Production has failed.'}
            title={'Receive for Production'}
            onModalConfirm={this.receiveForProduction}
            showModal={showConfirmActionModal}
            closeModal={this.closeConfirmActionModal} />}
          <React.Fragment>
            <Button onClick={this.openAssignModal} loading={loadingTechnicians} disabled={loadingTechnicians || selectedInventories.length === 0 || isDisabled(user.userRoles, [ROLES.Viewer])}><Icon name='users' />Assignment</Button>
            {(multipleWarehouses === null || multipleWarehouses.length === 0) && <Button onClick={advancedInventoryManagement ? this.openAssignAllConfirmModal : this.assignAllInventories} disabled={!isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin])}><Icon name='add user' />Assign All</Button>}
            <Dropdown button={true} text={'More'} className='more-dropdown' pointing={false} options={this.moreDropDownOptions()} />
            <div style={{ paddingRight: '5px', flex: 1 }}>
              <MultiSelect
                onChange={this.onFilterTypeChange}
                style={{ width: '100%' }}
                label='Filter by...'
                data={filterTypes}
                textField='filterName'
              />
            </div>
            <Search
              searchRef={this.searchRef}
              onSearch={this.onSearch} />
          </React.Fragment>
        </div>
        <Grid
          className='inventory-all-assets-list-view-grid'
          sortable
          sort={sort}
          onSortChange={this.onSortChange}
          data={orderBy(inventories, sort)}
          onSelectionChange={this.selectionChange}
          onHeaderSelectionChange={this.headerSelectionChange}
          onRowClick={this.rowClick}
          selectedField='selected'
          pageable={{ pageSizes: [50, 100, 500, 1000] }}
          total={total}
          onPageChange={this.pageChange}
          skip={currentOffset}
          take={pageLimit}>
          <GridToolbar>
            <div style={{ display: 'flex' }}>
              <p style={{ width: '100%', textAlign: 'right' }}>Selected rows ({selectedInventories.length})</p>
            </div>
          </GridToolbar>
          {inventories.length > 0 && <Column
            field='selected'
            width='50px'
            headerSelectionValue={inventories.findIndex(dataItem => dataItem.selected === false) === -1} />}
          <Column field='assetNumber' title='Asset Number' width='165px' />
          <Column field='assetType' title='Asset Type' width='125px' />
          <Column field='inventoryAssignments' title='Assignments' width='400px' />
          <Column field='assetDeployed' title='Deployed' width='120px' />
          <Column field='assetDeployedDate' title='Deployed Date' width='150px' />
          <Column field='workOrderNumber' title='Work Order' width='175px' />
          <Column field='manufacturer' title='Manufacturer' width='175px' />
          <Column field='mfgSerialNumber' title='MFG Serial #' width='150px' />
          <Column field='form' title='Form/Size' width='175px' />
          <Column field='class' title='Class' width='120px' />
          <Column field='orderNumber' title='Order Number' width='150px' />
          <Column field='lineNumber' title='Line Number' width='150px' />
          {currentProject && currentProject.projectWorkOrderType === WORKORDER_TYPES.MeterDeploy && <Column field='voltage' title='Voltage' width='120px' />}
          {currentProject && currentProject.projectWorkOrderType === WORKORDER_TYPES.MeterDeploy && <Column field='palletNumber' title='Pallet Number' width='150px' />}
          {advancedInventoryManagement && multipleWarehouses && multipleWarehouses.length > 0 && <Column field='assetInTransit' title='Asset In Transit' width='175px' />}
          {advancedInventoryManagement && multipleWarehouses && multipleWarehouses.length > 0 && <Column field='assetApproved' title='Asset Approved' width='140px' />}
          {advancedInventoryManagement && <Column field='assetIsInQuarantine' title='Quarantined' width='130px' />}
          {advancedInventoryManagement && <Column field='assetIsDeleted' title='RMA' width='130px' />}
          {advancedInventoryManagement && <Column field='assetDeletedDate' title='Deleted Date' width='160px' />}
          {advancedInventoryManagement && <Column field='assetTransferred' title='Trans to Utility' width='130px' />}
          {advancedInventoryManagement && <Column field='assetTransferredDate' title='Trans to Util Date' width='160px' />}
          <Column field='shippedDate' title='Shipped Date' width='150px' />
          <Column field='shippedTo' title='Shipped To' width='150px' />
          <Column field='assetLocation' title='Asset Location' width='150px' />
          <Column field='assetDestination' title='Asset Destination' width='150px' cell={AssetDestinationCell} />
        </Grid>
      </div>
    );
  }
}

const AssetDestinationCell = ({ dataItem }) => (
  <td>
    { dataItem.assetLocation && dataItem.assetLocation === 'In Transit' ? dataItem.assetDestination : '' }
  </td>
)

export default withApollo(InventoryAllAssetsListView);