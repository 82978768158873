import React from 'react';
import { Modal, Button, Icon, Header, Statistic, Form, Dropdown, Checkbox } from 'semantic-ui-react';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import ErrorMessage from '../../../../shared/components/messages/error';
import './styles.css';
import { isEnabled } from '../../../../core/services/auth.service';
import ROLES from '../../../../constants/roles'

const PlanModal = ({
  showModal,
  closeModal,
  onSubmit,
  currentProject,
  onPlanDateChange,
  onWeeklyCapacityChange,
  workOrdersCount,
  error,
  loading,
  plan,
  onPlanWorkingDaysChange,
  overrideAllWeeksPlanRates,
  overrideAllWeeksWorkingDays,
  onOverrideAllWeeksPlanRatesChange,
  onOverrideAllWeeksWorkingDaysChange,
  workingDays,
  user
}) => {

  const startDate = plan ? new Date(plan.startDate) : null;
  const endDate = plan ? new Date(plan.endDate) : null;

  const startDateUTC = startDate ? new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()) : null;
  const endDateUTC = endDate ? new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate()) : null;

  return (
    <Modal size='tiny' open={showModal}>
      <Modal.Header><Icon name='calendar alternate outline' />Plan Details</Modal.Header>
      <Modal.Content>
        <div className='plan-modal-header'>
          <Header as='h1'>{currentProject.projectName}</Header>
          <Statistic className='workOrders-statistic'>
            <Statistic.Value>{workOrdersCount}</Statistic.Value>
            <Statistic.Label>Work Orders</Statistic.Label>
          </Statistic>
        </div>
        <DateRangePicker format='yyyy-MM-dd' onChange={onPlanDateChange} defaultValue={{ start: startDateUTC, end: endDateUTC }} />
        <div className='plan-weekly-capacity'>
          <Header as='h3' className='plan-weekly-capacity-label'>Weekly Capacity: </Header>
          <Form.Input type='number' onChange={onWeeklyCapacityChange} defaultValue={plan ? plan.weeklyCapacity : ''} />
          <div className='override-checkbox'>
            <Checkbox label='override all weeks' checked={overrideAllWeeksPlanRates} onChange={onOverrideAllWeeksPlanRatesChange} />
          </div>
        </div>
        <div className='days-per-week'>
          <Header as='h3' className='days-per-week-label'>Days Per Week: </Header>
          <Dropdown
            defaultValue={(plan && plan.workingDays) || workingDays}
            selection
            options={[
              { key: 5, value: 5, text: 'MON-FRI' },
              { key: 6, value: 6, text: 'MON-SAT' },
              { key: 7, value: 7, text: 'MON-SUN' }]}
            onChange={onPlanWorkingDaysChange} />
          <div className='override-checkbox'>
            <Checkbox label='override all weeks' checked={overrideAllWeeksWorkingDays} onChange={onOverrideAllWeeksWorkingDaysChange} />
          </div>
        </div>
        {error && <ErrorMessage message={error.message} />}
      </Modal.Content>
      <Modal.Actions>
        {!loading && <Button onClick={closeModal}>Cancel</Button>}
        <Button disabled={!isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin])} onClick={onSubmit} positive loading={loading}>Save</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default PlanModal;