
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { from } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { getToken } from '../services/auth.service';
import config from '../../config';
import { setContext } from 'apollo-link-context';

const httpLink = createHttpLink({ uri: config.apolloClient.uri });

const logoutLink = onError(({ networkError }) => {
  if (networkError && (networkError.statusCode === 403 || networkError.statusCode === 401)) {
    window.location.href = '/';
  } else if (networkError) {
    console.log(networkError);
  }
});

const middlewareLink = setContext(async (request, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? token : ''
    },
  };
});

export default new ApolloClient({
  link: from([
    logoutLink,
    middlewareLink,
    httpLink
  ]),
  cache: new InMemoryCache({
    addTypename: false
  })
});