import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { Button } from 'semantic-ui-react';
import ExportReportTemplateDetails from './exportReportTemplateDetails';

const CustomIndexColumn = ({ dataIndex }) => (
  <td>
    {dataIndex + 1}
  </td>
)

const CustomActionsColumn = ({ dataItem, onEdit, onDelete, onAttachTransformation }) => (
  <td>
    {dataItem.selected ? (
      <div style={{ display: 'flex' }}>
        <Button circular icon='cogs' onClick={() => onAttachTransformation(dataItem.id)} />
        <Button circular icon='edit' onClick={() => onEdit(dataItem.id)} />
        <Button circular icon='delete' onClick={() => onDelete(dataItem.id)} />
      </div>
    ) : null}
  </td>
)

class DragCell extends React.Component {
  render() {
    return (
      <td onDragOver={(e) => {
        DragCell.reorder(this.props.dataItem);
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      }}>
        <span
          className='k-icon k-i-reorder'
          draggable='true'
          style={{ cursor: 'move' }}
          onDragEnd={() => DragCell.dragEnd()}
          onDragStart={(e) => {
            DragCell.dragStart(this.props.dataItem);
            e.dataTransfer.setData('dragging', '');
          }}
        />
      </td>
    );
  }
}

class ExportReportTemplateTable extends Component {
  constructor(props) {
    super(props);
    DragCell.reorder = this.reorder.bind(this);
    DragCell.dragStart = this.dragStart.bind(this);
    DragCell.dragEnd = this.dragEnd.bind(this);
  }

  state = {
    data: [],
    activeItem: null,
    selectedRowId: null,
    currentReportType: ''
  }

  componentDidMount() {
    const { template, currentReportType } = this.props;
    this.setState({ currentReportType, data: template.map((dataItem, index) => Object.assign({ selected: false, id: index }, dataItem)) });
  }

  componentWillReceiveProps(newProps) {
    const { template } = newProps;
    const { data, currentReportType } = this.state;
    if (currentReportType !== newProps.currentReportType) {
      this.setState({
        currentReportType: newProps.currentReportType,
        data: template.map((dataItem, index) => Object.assign({ selected: false, id: index }, dataItem))
      });
    } else {
      this.setState({
        selectedRowId: null,
        data: template.map((dataItem, index) => Object.assign({ selected: false, id: index, expanded: data && data[index] ? data[index].expanded : false }, dataItem))
      });
    }
  }

  rowClick = (event) => {
    const data = this.state.data.map((item) => {
      return { ...item, selected: event.dataItem.id === item.id }
    });
    this.setState({ selectedRowId: event.dataItem.id, data });
  };

  onDelete = (id) => {
    const { onDeleteTemplate } = this.props;
    onDeleteTemplate(id);
  }

  onEdit = (id) => {
    const { onEditTemplate } = this.props;
    onEditTemplate(id);
  }

  onAttachTransformation = (id) => {
    const { onAttachTransformation } = this.props;
    onAttachTransformation(id);
  }

  reorder = (dataItem) => {
    const { activeItem, data } = this.state;
    if (activeItem === dataItem) {
      return;
    }
    let reorderedData = data.slice();
    let prevIndex = reorderedData.findIndex(p => (p === this.state.activeItem));
    let nextIndex = reorderedData.findIndex(p => (p === dataItem));
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, this.state.activeItem);

    this.setState({
      data: reorderedData,
      active: activeItem,
    });
  }

  dragEnd = () => {
    this.props.reorderTemplate(this.state.data);
  }

  dragStart = (dataItem) => {
    this.setState({
      data: this.state.data,
      activeItem: dataItem
    });
  }

  expandChange = (event) => {
    const { data } = this.state;
    data.find(item => item.id === event.dataItem.id).expanded = !data.find(item => item.id === event.dataItem.id).expanded;
    this.setState({ selectedRowId: event.dataItem.id, data });
  }

  render() {
    const { onDeleteTemplateTransformations, onAttachTransformationCondition, onDeleteTemplateTransformationConditions } = this.props;
    return (
      <>
        <div>
          <Grid
            data={this.state.data}
            selectedField='selected'
            onRowClick={this.rowClick}
            expandField='expanded'
            onExpandChange={this.expandChange}
            detail={(props) => <ExportReportTemplateDetails
              {...props}
              onDeleteTemplateTransformationConditions={onDeleteTemplateTransformationConditions}
              onDeleteTemplateTransformations={onDeleteTemplateTransformations}
              onAttachTransformationCondition={onAttachTransformationCondition} />}
          >
            <GridNoRecords>
              No data
            </GridNoRecords>
            <Column title='' width='80px' cell={DragCell} />
            <Column field='' width='80px' title='#' cell={CustomIndexColumn} />
            <Column field='table_column_name' width='300px' title='Table Column' />
            <Column field='column_name' title='File Column' width='300px' />
            <Column field='' title='' cell={(props) => <CustomActionsColumn {...props} onDelete={this.onDelete} onEdit={this.onEdit} onAttachTransformation={this.onAttachTransformation} />} />
          </Grid>
        </div>
      </>
    )
  }
}

export default ExportReportTemplateTable;