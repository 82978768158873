import * as React from 'react';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle
} from '@progress/kendo-react-charts';
import 'hammerjs';

const labelContent = (props) => {
  return `${props.dataItem.category} - ${props.dataItem.value}`;
}

const ChartContainer = ({ title, series }) => (
  <Chart title={title}>
    <ChartTitle text={title} padding={{ bottom: 20 }} />
    <ChartLegend position='bottom' />
    <ChartSeries>
      <ChartSeriesItem type='pie' data={series} field='value' categoryField='category' labels={{ visible: true, content: labelContent }} />
    </ChartSeries>
  </Chart>
);

export default ChartContainer;