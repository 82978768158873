import React, { useEffect } from 'react';
import { SchedulerSlot } from '@progress/kendo-react-scheduler';
export const CustomSlot = (props) => {
    const slotID = props.start.toISOString();
    useEffect(() => {
        const clickEvent = new CustomEvent("singleClickEmptySlot", { detail: { start: props.start, end: props.end } });
        const dblClickEvent = new CustomEvent("doubleClickEmptySlot", { detail: { start: props.start, end: props.end } });
        const appointmentSlot = document.getElementById(slotID);
        const click = e => document.dispatchEvent(clickEvent);
        const dblclick = e => document.dispatchEvent(dblClickEvent);
        if(props.view === 'agenda') appointmentSlot.addEventListener('click', click);
        appointmentSlot.addEventListener('dblclick', dblclick);
        return () => {
            appointmentSlot.removeEventListener('click', click);
            appointmentSlot.removeEventListener('dblclick', dblclick);
        }
    }, []);
    return (
        <SchedulerSlot {...props} onClick={null} onDoubleClick={null} id={slotID} />
    )
}