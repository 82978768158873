import React, { useState } from 'react';
import { Splitter as KendoSplitter } from '@progress/kendo-react-layout';
import './styles.css';

const Splitter = (props) => {
  const [panes, setPanes] = useState([{ size: '45%', collapsible: true, resizable: false }]);

  const onChange = (event) => {
    setPanes(event.newState);
  }

  return (
    <KendoSplitter style={{ border: 'none', overflow: 'unset' }} panes={panes} onChange={onChange}>
      {props.children}
    </KendoSplitter>
  )
}

export default Splitter;