import React from 'react';
import { connect } from 'react-redux';
import { Icon, Message } from 'semantic-ui-react';

const MapLatestDataLoader = ({
  mapStatusId
}) => (
  <>
    {mapStatusId ? <div style={{ position: 'absolute', right: '10px', bottom: '29px' }}>
      <Message icon compact info size='small'>
        <Icon name='circle notched' loading />
        <Message.Content>
          <Message.Header>Map</Message.Header>
            Fetching latest data...
          </Message.Content>
      </Message>
    </div> : null}
  </>
)

const mapStateToProps = ({ map }) => {
  return {
    mapStatusId: map.mapStatusId,
  }
}

export default connect(mapStateToProps, null)(MapLatestDataLoader);