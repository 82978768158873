import React, { Component } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import config from '../../config';
import Loader from '../../shared/components/loader';
import AppContext from '../../core/context/app.context';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import TopMenu from '../../shared/components/top-menu';
import CHECKED_ICON from '../../assets/icons/pins/wo-checked.png';
import EDIT_ICON from '../../assets/icons/pins/wo-saved-in-review.png';
import { renderToString } from 'react-dom/server';
import * as workOrderService from '../../core/services/workOrder.service';
import routeMaps from '../../core/route-maps';
import * as mapActions from '../../core/redux/actions/map.actions';
import { connect } from 'react-redux';
import { getMapStatusByIdAndStatus } from '../../core/services/mapStatus.service';
import { invokeGeoJsonFileCreation } from '../../core/services/map.service';

const serviceTypeOptions = [
  'Service Type',
  'Residential Home',
  'Mobile Home',
  'Apartment/Condo',
  'Unknown',
  'Business',
  'Shed',
  'Barn',
  'Church',
  'School',
  'Water Pump/Well',
  'Sign/Traffic Signal'
];

const CustomDropDown = () => {
  return (
    <select id='serviceTypes' style={{ marginTop: 10 }}>
      {serviceTypeOptions.map((option, index) => {
        if (index === 0) {
          return <option key={index} disabled value={option} selected>{option}</option>
        }
        return <option key={index} value={option}>{option}</option>
      })}
    </select>
  )
}

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== 'undefined'; // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector('body.first-hit-completed'); // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error('google api is already presented');
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
}

class MapContainer extends Component {
  _isMounted = false;
  static contextType = AppContext;

  state = {
    googleMapGeoJsonFileUrl: null
  }

  componentDidMount() {
    this._isMounted = true;
    const { currentProject } = this.context;
    if (currentProject) {
      const hasServiceType = currentProject && (currentProject.id === '03cb1e60-82ca-11eb-a9b6-55b04578f029');
      if (!hasServiceType) {
        this.props.history.push(routeMaps.dashboard);
      }
    }
    this.getMapData();
  }

  getMapData = async () => {
    const { currentProject } = this.context;
    if (currentProject) {
      const { projectWorkOrderType } = currentProject;

      let mapStatusId = await invokeGeoJsonFileCreation(currentProject.id, projectWorkOrderType);
      this.props.setMapStatusId(mapStatusId);

      this.setState({ googleMapGeoJsonFileUrl: null });

      const fetchMapData = setInterval(async () => {
        const { mapStatusId } = this.props;
        if (mapStatusId) {
          const mapStatus = await getMapStatusByIdAndStatus(mapStatusId);
          if (mapStatus) {
            clearInterval(fetchMapData);
            this.setState({ googleMapGeoJsonFileUrl: mapStatus.url });
          }
        } else {
          clearInterval(fetchMapData);
        }
      }, 2000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { setMapStatusId } = this.props;
    setMapStatusId('');
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  fitBounds = (map, pins) => {
    if (window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      pins.forEach(pin => {
        const latitude = pin.getGeometry().get().lat();
        const longitude = pin.getGeometry().get().lng();
        bounds.extend(new window.google.maps.LatLng(latitude, longitude));
      })
      map.fitBounds(bounds);
    }
  }

  applyDefaultMapPinIcons = (map) => {
    map.data.setStyle(feature => {
      const pinIconInformation = {
        serviceType: feature.getProperty('st'),
      };
      return {
        icon: this.getPinIcon(pinIconInformation),
      };
    });
  }

  getPinIcon = (workOrder) => {
    return workOrder.serviceType && serviceTypeOptions.indexOf(workOrder.serviceType) > 0 ? CHECKED_ICON : EDIT_ICON;
  }

  addInfoWindow = (map) => {
    const infowindow = new window.google.maps.InfoWindow({
      pixelOffset: new window.google.maps.Size(0, -25)
    });

    const onServiceTypesChange = (e, featureEvent) => {
      const { user } = this.context;
      featureEvent.feature.setProperty('st', e.target.value);
      workOrderService.updateWorkOrder({
        id: featureEvent.feature.getProperty('id'),
        serviceType: e.target.value,
        serviceDescription: user.userName,
        workOrderReviewedUserId: user.id,
        workOrderStatus: 'Closed'
      });
    }

    map.data.addListener('mouseover', async (featureEvent) => {
      const workOrderNumber = featureEvent.feature.getProperty('num');
      const meterFormFactor = featureEvent.feature.getProperty('mff');
      const customerName = featureEvent.feature.getProperty('cn');
      const form = featureEvent.feature.getProperty('fo');
      const street = featureEvent.feature.getProperty('str');
      const serviceType = featureEvent.feature.getProperty('st');
      infowindow.setPosition(featureEvent.latLng);
      infowindow.setContent(`
        ${workOrderNumber ? `<b>Work Order Number:</b> ${workOrderNumber}<br/>` : ''}
        ${customerName ? `<b>Customer Name:</b> ${customerName}<br/>` : ''}
        ${street ? `<b>Street:</b> ${street}<br/>` : ''}
        ${meterFormFactor ? `<b>Work Order Form:</b> ${meterFormFactor}<br/>` : ''}
        ${form ? `<b>Inventory Form:</b> ${form}<br/>` : ''}
        ${renderToString(<CustomDropDown />)}
      `);
      infowindow.open(map);

      const dropdown = document.getElementById('serviceTypes');
      if (dropdown) {
        dropdown.addEventListener('change', (e) => onServiceTypesChange(e, featureEvent));
        if (serviceType && serviceTypeOptions.indexOf(serviceType) > 0) {
          dropdown.options[serviceTypeOptions.indexOf(serviceType)].selected = true;
        }
      }
    });
    map.data.addListener('mouseout', (event) => {
      infowindow.close();
      const dropdown = document.getElementById('serviceTypes');
      if (dropdown) {
        dropdown.removeEventListener('change', () => onServiceTypesChange, true);
      }
    });
  }

  onMapLoad = async (map) => {
    const { googleMapGeoJsonFileUrl } = this.state;
    if (googleMapGeoJsonFileUrl) {
      map.data.loadGeoJson(googleMapGeoJsonFileUrl, null, (features) => this.fitBounds(map, features));
      this.applyDefaultMapPinIcons(map);
      this.addInfoWindow(map);
    }
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Project Tools - Service Type` : 'Project Tools - Service Type'}`,
        iconName: 'flag'
      },
      tabs: [{
        refresh: true,
        onClick: () => this.getMapData()
      }]
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  render() {
    const { googleMapGeoJsonFileUrl } = this.state;
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        <LoadScriptOnlyIfNeeded googleMapsApiKey={config.googleMapsApiKey}>
          {googleMapGeoJsonFileUrl ? <GoogleMap
            streetViewControl={true}
            id='map1'
            mapContainerStyle={{ height: '91%', position: 'absolute', left: 0, right: 0, top: '65px', paddingTop: '66px' }}
            onLoad={this.onMapLoad}
          /> : <Loader loading={!googleMapGeoJsonFileUrl} />}
        </LoadScriptOnlyIfNeeded>
      </ProjectRouteWrapper>
    )
  }
}

const mapStateToProps = ({ map }) => {
  return {
    mapStatusId: map.mapStatusId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMapStatusId: (id) => dispatch(mapActions.setMapStatusId(id)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(MapContainer));