import React, { Component } from 'react';
import AppContext from '../../core/context/app.context';
import TopMenu from './components/top-menu';
import { Segment, Button, Header, Icon } from 'semantic-ui-react';
import PlanModal from './components/modal/planModal';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import PlanTabView from './components/tabs/planTabView';
import { createPlan, updatePlan, updatePlanWeekActualRate } from '../../core/services/plan.service';
import PlanResultsTabView from './components/tabs/resultsTabView';
import { updateProjectPlanningRouteCodeOrder } from '../../core/services/project.service';
import { listWorkOrderRouteCodes } from '../../core/services/workOrder.service';

class PlanningRoute extends Component {

  static contextType = AppContext;
  _isMounted = false;

  state = {
    isResultsTabActive: false,
    isPlanTabActive: true,
    weeklyCapacity: '',
    startDate: null,
    endDate: null,
    showPlanModal: false,
    error: null,
    loading: false,
    workingDays: 5,
    overrideAllWeeksPlanRates: false,
    overrideAllWeeksWorkingDays: false,
    routeCodes: [],
    originalRouteCodes: []
  }

  setState = (state) => {
    if (this._isMounted) {
      super.setState(state);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.init();
  }

  setRouteCodes = (routeCodes) => {
    this.setState({ routeCodes });
  }

  setOriginalRouteCodes = (originalRouteCodes) => {
    this.setState({ originalRouteCodes });
  }

  sortRouteCodes = (order, routeCodes) => {
    if (routeCodes){
      for (let x = 0; x < order.length; x++) {
        let index = routeCodes.findIndex(i => i.route === order[x].route);
        let temp = routeCodes[x];
        routeCodes[x] = routeCodes[index];
        routeCodes[index] = temp;
        routeCodes[x].adjustedDays = order[x].adjustedDays
      }
    }
    return routeCodes;
  }

  updateCurrentProjectConfiguration = async () => {
    const { currentProject, setCurrentProjectSequence } = this.context;
    const routeCodes = this.state.routeCodes.map((route) => {
      return {
        ...route, adjustedDays: 0, workingDays: 0
      }
    });
    const projectSequence = await updateProjectPlanningRouteCodeOrder(currentProject.id, routeCodes.map(({ route, adjustedDays }) => {
      return {
        route, adjustedDays
      }
    }));
    setCurrentProjectSequence(projectSequence);
    return routeCodes;
  }

  init = async () => {
    const { currentProject, setCurrentProjectSequence } = this.context;
    if (currentProject) {
      let routeCodes = await listWorkOrderRouteCodes(currentProject.id);
      if (currentProject && currentProject.projectSequence && currentProject.projectSequence.planningRouteCodeOrder) {
        routeCodes = this.sortRouteCodes(currentProject.projectSequence.planningRouteCodeOrder, routeCodes);
      } else {
        if(routeCodes){
          routeCodes = routeCodes.map(routeCode => {
            return {
              ...routeCode,
              adjustedDays: 0
            }
          });
          const projectSequence = await updateProjectPlanningRouteCodeOrder(currentProject.id, routeCodes.map(({ route, adjustedDays }) => {
            return {
              route, adjustedDays
            }
          }));
          setCurrentProjectSequence(projectSequence);
        } else {
          console.log("routeCodes:"+routeCodes);
        }
      }
      if (routeCodes) {
        this.setState({ routeCodes, originalRouteCodes: [...routeCodes] });
      }
    }
  }

  showPlanTab = () => {
    this.setState({ isPlanTabActive: true, isResultsTabActive: false });
  }

  showResultsTab = () => {
    this.setState({ isPlanTabActive: false, isResultsTabActive: true });
  }

  renderTopMenu = () => {
    const { currentProject, plan, user } = this.context;
    const { isPlanTabActive, isResultsTabActive } = this.state;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Planning` : 'Planning'}`,
        iconName: 'flag'
      },
      tabs: [
        {
          active: isPlanTabActive,
          onClick: this.showPlanTab,
          title: 'Plan'
        },
        {
          active: isResultsTabActive,
          onClick: this.showResultsTab,
          title: 'Results'
        }
      ]
    };
    return <TopMenu config={topMenuConfig} onSettingsClick={() => this.setShowPlanModal(true)} plan={plan} user={user} />;
  }

  onOverrideAllWeeksPlanRatesChange = (event, data) => {
    this.setState({ overrideAllWeeksPlanRates: data.checked });
  }

  onOverrideAllWeeksWorkingDaysChange = (event, data) => {
    this.setState({ overrideAllWeeksWorkingDays: data.checked });
  }

  savePlan = async () => {
    const { currentProject, setPlan, plan } = this.context;
    this.setState({ error: null, loading: true });
    let { startDate, endDate, error, weeklyCapacity, workingDays, overrideAllWeeksPlanRates, overrideAllWeeksWorkingDays } = this.state;
    if (!startDate || !endDate) {
      error = { message: 'Start/End dates are required' }
      this.setState({ error, loading: false });
    }
    else if (!weeklyCapacity || !Number(weeklyCapacity)) {
      error = { message: 'Weekly Capacity is required' }
      this.setState({ error, loading: false });
    } else {
      let newPlan;
      let routeCodes = this.state.routeCodes;
      if (plan) {
        newPlan = await updatePlan({ id: plan.id, startDate, endDate, weeklyCapacity, workingDays, overrideAllWeeksPlanRates, overrideAllWeeksWorkingDays, project: currentProject.id });
        routeCodes = await this.updateCurrentProjectConfiguration();
        if (new Date(plan.startDate).getTime() !== new Date(startDate).getTime() || new Date(plan.endDate).getTime() !== new Date(endDate).getTime()) {
          newPlan = await updatePlanWeekActualRate(plan.id, currentProject.id);
        }
      } else {
        newPlan = await createPlan(currentProject.id, { startDate, endDate, weeklyCapacity, workingDays });
      }
      setPlan(newPlan);
      this.setState({
        showPlanModal: false, loading: false, overrideAllWeeksPlanRates: false, overrideAllWeeksWorkingDays: false, routeCodes, originalRouteCodes: [...routeCodes]
      });
    }
  }

  onWeeklyCapacityChange = (event) => {
    this.setState({ weeklyCapacity: event.target.value });
  }

  setShowPlanModal = (showPlanModal) => {
    const { plan } = this.context;
    if (showPlanModal && plan) {
      const { weeklyCapacity, startDate, endDate, workingDays } = plan;
      this.setState({
        showPlanModal,
        weeklyCapacity,
        workingDays,
        startDate: this.formatDate(new Date(startDate)),
        endDate: this.formatDate(new Date(endDate))
      });
    } else {
      this.setState({ showPlanModal });
    }
    if (!showPlanModal) {
      this.setState({ overrideAllWeeksPlanRates: false, overrideAllWeeksWorkingDays: false });
    }
  }

  onPlanDateChange = (event) => {
    const { end, start } = event.target.value;
    this.setState({ startDate: this.formatDate(start), endDate: this.formatDate(end) });
  }

  formatDate(date) {
    if (date) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  onPlanWorkingDaysChange = (event, data) => {
    this.setState({ workingDays: data.value });
  }

  renderPlanningRouteContent = () => {
    const { currentProject, plan, workOrderStatusCounts = {}, user } = this.context;
    const planDashboardData = currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.planDashboardData;
    const { Total } = workOrderStatusCounts;
    const workOrdersCount = Total;
    let { showPlanModal, isPlanTabActive, error, loading, overrideAllWeeksPlanRates, overrideAllWeeksWorkingDays, isResultsTabActive, workingDays, routeCodes, originalRouteCodes } = this.state;
    return (
      <React.Fragment>
        {isPlanTabActive && plan && <PlanTabView originalRouteCodes={originalRouteCodes} setOriginalRouteCodes={this.setOriginalRouteCodes} formatDate={this.formatDate} routeCodes={routeCodes} setRouteCodes={this.setRouteCodes} />}
        {isResultsTabActive && plan && <PlanResultsTabView planDashboardData={planDashboardData} />}
        {showPlanModal && <PlanModal
          user={user}
          workingDays={workingDays}
          onPlanWorkingDaysChange={this.onPlanWorkingDaysChange}
          onPlanDateChange={this.onPlanDateChange}
          workOrdersCount={workOrdersCount}
          onWeeklyCapacityChange={this.onWeeklyCapacityChange}
          showModal={showPlanModal}
          onSubmit={this.savePlan}
          closeModal={() => this.setShowPlanModal(false)}
          currentProject={currentProject}
          error={error}
          loading={loading}
          plan={plan}
          overrideAllWeeksPlanRates={overrideAllWeeksPlanRates}
          overrideAllWeeksWorkingDays={overrideAllWeeksWorkingDays}
          onOverrideAllWeeksPlanRatesChange={this.onOverrideAllWeeksPlanRatesChange}
          onOverrideAllWeeksWorkingDaysChange={this.onOverrideAllWeeksWorkingDaysChange}
        />}
        {!plan && <div style={{ marginBottom: '10px' }}>
          <Segment placeholder>
            <Header icon>
              <Icon name='calendar alternate outline' />
              <h1>Create a project Plan</h1>
            </Header>
            <Segment.Inline>
              <Button primary onClick={() => this.setShowPlanModal(true)}>Get Started</Button>
            </Segment.Inline>
          </Segment>
        </div>}
      </React.Fragment>
    )
  }

  render() {
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        {this.renderPlanningRouteContent()}
      </ProjectRouteWrapper>
    )
  }
}

export default PlanningRoute;