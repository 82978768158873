import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import ReactPlayer from 'react-player/vimeo';
import TopMenu from '../../shared/components/top-menu';
import RouteWrapper from '../../hoc/routeWrapper';
import { Dialog } from '@progress/kendo-react-dialogs';

import './styles.css'; 

class WhatsNewRoute extends Component {
  state = {
    features: [],
    dialogVisible: false,
    selectedFeature: null,
  };

  componentDidMount() {
    this.loadFeatures();
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `What's New`,
        iconName: 'star'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  loadFeatures = async () => {
    try {
      const response = await fetch('/markdown/features.json');
      const features = await response.json();
      features.sort((a, b) => b.version.localeCompare(a.version));
      this.setState({ features });
    } catch (error) {
      console.error('Error loading features:', error);
    }
  };

  handleRowClick = (e) => {
    this.setState({
      selectedFeature: e.dataItem,
      dialogVisible: true,
    });
  };

  groupFeaturesByLetter = () => {
    const groupedFeatures = this.state.features.reduce((groups, feature) => {
      const letter = feature.title.charAt(0).toUpperCase();
      if (!groups[letter]) {
        groups[letter] = [];
      }
      groups[letter].push(feature);
      return groups;
    }, {});
    return groupedFeatures;
  };

  handleFeatureClick = (feature) => {
    this.setState({
      selectedFeature: feature,
      dialogVisible: true,
    });
  };

  renderWhatsNew = () => {
    const sortedFeaturesByLetter = this.groupFeaturesByLetter();

    return (
      <>
        <h1 className="section-header">Latest Features</h1>
        <div className="features-container">
          {this.state.features
            .filter(feature => {
              const latestVersions = this.state.features
                  .map(feature => feature.version)
                  .sort((a, b) => b.localeCompare(a))
                  .reduce((unique, item) => 
                      unique.includes(item) ? unique : [...unique, item], [])
                  .slice(0, 2);
              return latestVersions.includes(feature.version);
            })
            .map((feature, index) => (
              <Card key={index} className="feature-card">
                <CardHeader>
                  <h2>v{feature.version}: {feature.title}</h2>
                </CardHeader>
                <CardBody className="card-content">
                  <p>{feature.description}</p>
                </CardBody>
                {feature.videoUrl && (
                  <div className="card-video">
                    <ReactPlayer url={feature.videoUrl} width="100%" height="296px" controls={true} />
                  </div>
                )}
              </Card>
            ))}
        </div>
        <h1 className="section-header">Previous Features</h1>
        <div className="features-grid">
          {Object.entries(sortedFeaturesByLetter).sort((a, b) => a[0].localeCompare(b[0])).map(([letter, features]) => (
            <div key={letter} className="features-column">
              <h2>{letter}</h2>
              {features.map(feature => (
                <div key={feature.id} className="feature" onClick={() => this.handleFeatureClick(feature)}>
                  {feature.title}
                </div>
              ))}
            </div>
          ))}
        </div>
        {this.state.dialogVisible && (
          <Dialog title={this.state.selectedFeature.title} onClose={() => this.setState({ dialogVisible: false })}>
            <p>{this.state.selectedFeature.description}</p>
            {this.state.selectedFeature.videoUrl && (
              <div className="card-video">
                <ReactPlayer url={this.state.selectedFeature.videoUrl} width="100%" controls={true} />
              </div>
            )}
          </Dialog>
        )}
      </>
    );
  }

  render() {
    return (
      <RouteWrapper>
        {this.renderTopMenu()}
        {this.renderWhatsNew()}
      </RouteWrapper>
    );
  }
}

export default WhatsNewRoute;
