export const getTodaysDateForCSVFile = () => {
  let date = new Date();
  let month = date.getUTCMonth() + 1;
  let year = date.getUTCFullYear();
  let day = date.getUTCDate();
  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;
  return `${year}${month}${day}`;
}

export const useTimeZone = (data, dataFields, projectTimeZone) => {
  if (!projectTimeZone) {
    return data;
  }
  const isArray = Array.isArray(data);
  if (!isArray) {
    data = [data];
  }
  data = data.map(dataItem => {
    for (let key in dataItem) {
      if (dataFields.indexOf(key) > -1) {
        const dataItemDate = dataItem[key];
        if (dataItemDate) {
          dataItem[key] = new Intl.DateTimeFormat(
            'en-US',
            { dateStyle: 'short', timeStyle: 'short', projectTimeZone }
          )
            .format(new Date(dataItemDate));
        }
      }
    }
    return dataItem;
  });
  return isArray ? data : data[0];
}

export const format = (data, dataFields) => {
  const isArray = Array.isArray(data);
  if (!isArray) {
    data = [data];
  }
  data = data.map(dataItem => {
    for (let key in dataItem) {
      if (dataFields.indexOf(key) > -1) {
        const dataItemDate = dataItem[key];
        if (dataItemDate) {
          dataItem[key] = new Intl.DateTimeFormat(
            'en-US',
            { dateStyle: 'short', timeStyle: 'short' }
          )
            .format(new Date(dataItemDate));
        }
      }
    }
    return dataItem;
  });
  return isArray ? data : data[0];
}