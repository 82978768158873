import React, { useState } from 'react';
import { Button, Checkbox, Dropdown, Input, Modal } from 'semantic-ui-react';
import { capitalize } from '../../../../helpers/string';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import {
  EXPORT_ARGUMENT_VALUE_TYPE,
  EXPORT_TEMPLATE_TRANSOFRMATION_TYPE,
  EXPORT_TRANSOFRMATION_TYPE_LABEL,
  OVERRIDE_WITH_CONDITIONS_OPERATIONS
} from '../constants';
import REPORT_TABLE_COLUMNS from '../../../../constants/reportTableColumns';
import DropdownDatePicker from './dropdownDatePicker';
import './styles.css';

const overrideWithConditionsFormInitialData = {
  tableColumnName: '',
  conditionValueType: EXPORT_ARGUMENT_VALUE_TYPE.string,
  overrideValueType: EXPORT_ARGUMENT_VALUE_TYPE.string,
  conditionValue: '',
  overrideValue: '',
  conditions: [],
  customDataFieldsKey: '',
  operation: '=',
  overrideWithTableColumnName: '',
  overrideWithTableColumnNameOption: false
}

const useIfExistFormInitialData = {
  tableColumnName1: '',
  tableColumnName2: '',
  defaultTo: '',
  defaultValueType: ''
}

const useIfNotExistFormInitialData = {
  tableColumnName1: '',
  tableColumnName2: '',
  defaultTo: '',
  defaultValueType: ''
}


const argumentSchema = {
  type: '',
  value: ''
}

const AddSubtractForm = ({
  tableColumnName1,
  tableColumnName2,
  onTableColumnName1Change,
  onTableColumnName2Change,
  workOrderType
}) => {

  const getTableColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>Table Column 1:</span>
        <Dropdown
          search
          placeholder='Table Column 1'
          selection
          value={tableColumnName1}
          className='export-dropdown'
          onChange={onTableColumnName1Change}
          options={getTableColumns()} />
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>Table Column 2:</span>
        <Dropdown
          search
          placeholder='Table Column 2'
          selection
          value={tableColumnName2}
          className='export-dropdown'
          onChange={onTableColumnName2Change}
          options={getTableColumns()} />
      </div>
    </>
  )
}

const MapBooleanForm = ({
  ifTrue,
  ifNotTrue,
  onSetIfTrue,
  onSetIfNotTrue
}) => {
  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>If True:</span>
        <Input placeholder='If True' value={ifTrue} onChange={onSetIfTrue} />
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>If Not True:</span>
        <Input placeholder='If Not True' value={ifNotTrue} onChange={onSetIfNotTrue} />
      </div>
    </>
  )
}

const UseIfExistForm = ({
  workOrderType,
  useIfExistFormData,
  setUseIfExistFormData
}) => {
  const getTableColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  const onTableColumnName1Change = (event, { value }) => {
    setUseIfExistFormData({
      ...useIfExistFormData,
      tableColumnName1: value
    });
  }

  const onTableColumnName2Change = (event, { value }) => {
    setUseIfExistFormData({
      ...useIfExistFormData,
      tableColumnName2: value
    });
  }

  const onDefaultValueTypeChange = (event, { value }) => {
    setUseIfExistFormData({
      ...useIfExistFormData,
      defaultValueType: value
    });
  }

  const onDefaultValueChange = (event, { value }) => {
    setUseIfExistFormData({
      ...useIfExistFormData,
      defaultTo: value
    });
  }

  const {
    tableColumnName1,
    tableColumnName2,
    defaultTo,
    defaultValueType
  } = useIfExistFormData;

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <div style={{ paddingBottom: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Use:</span>
          <Dropdown
            search
            placeholder='Table Column'
            selection
            value={tableColumnName1}
            className='export-transformation-modal-dropdown'
            onChange={onTableColumnName1Change}
            options={getTableColumns()} />
        </div>
        <div>
          <span style={{ paddingRight: '10px' }}>If exist:</span>
          <Dropdown
            search
            placeholder='Table Column'
            selection
            value={tableColumnName2}
            className='export-transformation-modal-dropdown'
            onChange={onTableColumnName2Change}
            options={getTableColumns()} />
        </div>
        <div style={{ paddingTop: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Default Value Type:</span>
          <Dropdown
            placeholder='Type'
            className='export-transformation-value-type-small-dropdown'
            onChange={onDefaultValueTypeChange}
            value={defaultValueType}
            selection
            options={[
              { key: EXPORT_ARGUMENT_VALUE_TYPE.string, value: EXPORT_ARGUMENT_VALUE_TYPE.string, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.string) },
              { key: EXPORT_ARGUMENT_VALUE_TYPE.number, value: EXPORT_ARGUMENT_VALUE_TYPE.number, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.number) },
            ]} />
          <span style={{ paddingRight: '10px' }}>Default To:</span>
          <Input value={defaultTo} onChange={onDefaultValueChange} type={defaultValueType === EXPORT_ARGUMENT_VALUE_TYPE.string ? 'text' : 'number'} placeholder='Value' />
        </div>
      </div>
    </>
  )
}

const UseIfNotExistForm = ({
  workOrderType,
  useIfNotExistFormData,
  setUseIfNotExistFormData
}) => {
  const getTableColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  const onTableColumnName1Change = (event, { value }) => {
    setUseIfNotExistFormData({
      ...useIfNotExistFormData,
      tableColumnName1: value
    });
  }

  const onTableColumnName2Change = (event, { value }) => {
    setUseIfNotExistFormData({
      ...useIfNotExistFormData,
      tableColumnName2: value
    });
  }

  const onDefaultValueTypeChange = (event, { value }) => {
    setUseIfNotExistFormData({
      ...useIfNotExistFormData,
      defaultValueType: value
    });
  }

  const onDefaultValueChange = (event, { value }) => {
    setUseIfNotExistFormData({
      ...useIfNotExistFormData,
      defaultTo: value
    });
  }

  const {
    tableColumnName1,
    tableColumnName2,
    defaultTo,
    defaultValueType
  } = useIfNotExistFormData;

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <div style={{ paddingBottom: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Use:</span>
          <Dropdown
            search
            placeholder='Table Column'
            selection
            value={tableColumnName1}
            className='export-transformation-modal-dropdown'
            onChange={onTableColumnName1Change}
            options={getTableColumns()} />
        </div>
        <div>
          <span style={{ paddingRight: '10px' }}>If not exist:</span>
          <Dropdown
            search
            placeholder='Table Column'
            selection
            value={tableColumnName2}
            className='export-transformation-modal-dropdown'
            onChange={onTableColumnName2Change}
            options={getTableColumns()} />
        </div>
        <div style={{ paddingTop: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Default Value Type:</span>
          <Dropdown
            placeholder='Type'
            className='export-transformation-value-type-small-dropdown'
            onChange={onDefaultValueTypeChange}
            value={defaultValueType}
            selection
            options={[
              { key: EXPORT_ARGUMENT_VALUE_TYPE.string, value: EXPORT_ARGUMENT_VALUE_TYPE.string, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.string) },
              { key: EXPORT_ARGUMENT_VALUE_TYPE.number, value: EXPORT_ARGUMENT_VALUE_TYPE.number, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.number) },
            ]} />
          <span style={{ paddingRight: '10px' }}>Default To:</span>
          <Input value={defaultTo} onChange={onDefaultValueChange} type={defaultValueType === EXPORT_ARGUMENT_VALUE_TYPE.string ? 'text' : 'number'} placeholder='Value' />
        </div>
      </div>
    </>
  )
}

const OverrideWithConditionsForm = ({
  workOrderType,
  overrideWithConditionsFormData,
  setOverrideWithConditionsFormData
}) => {
  const onOverrideWithTableColumnNameChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormData,
      overrideWithTableColumnName: value
    });
  }

  const onOverrideWithTableColumnNameOptionChange = (event, { checked }) => {
    const newState = { ...overrideWithConditionsFormData, overrideWithTableColumnNameOption: checked };
    if (!checked) {
      newState.overrideWithTableColumnName = '';
      newState.overrideValue = '';
      newState.overrideValueType = EXPORT_ARGUMENT_VALUE_TYPE.string;
    }
    setOverrideWithConditionsFormData(newState);
  }

  const onConditionValueChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormData,
      conditionValue: value
    });
  }

  const onOverrideValueChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormData,
      overrideValue: value
    });
  }

  const onTableColumnNameChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      tableColumnName: value,
      conditionValueType: EXPORT_ARGUMENT_VALUE_TYPE.string,
      overrideValueType: EXPORT_ARGUMENT_VALUE_TYPE.string,
      conditionValue: '',
      overrideValue: '',
      conditions: [],
      customDataFieldsKey: '',
      overrideWithTableColumnName: '',
      overrideWithTableColumnNameOption: false,
      operation: OVERRIDE_WITH_CONDITIONS_OPERATIONS['=']
    });
  }

  const onConditionValueTypeChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormData,
      conditionValueType: value,
      conditionValue: '',
    });
  }

  const onOverrideValueTypeChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormData,
      overrideValueType: value,
      overrideValue: '',
    });
  }

  const getTableColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  const onAddCondition = () => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormInitialData,
      tableColumnName: overrideWithConditionsFormData.tableColumnName,
      conditions: [...overrideWithConditionsFormData.conditions, {
        conditionValueType: overrideWithConditionsFormData.conditionValueType,
        conditionValue: overrideWithConditionsFormData.conditionValue,
        overrideWithTableColumnName: overrideWithConditionsFormData.overrideWithTableColumnName,
        overrideValueType: overrideWithConditionsFormData.overrideValueType,
        overrideValue: overrideWithConditionsFormData.overrideValue,
        operation: overrideWithConditionsFormData.operation,
      }]
    });
  }

  const onOperationChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormData,
      operation: value,
    });
  }

  const onCustomDataFieldsKeyChange = (event, { value }) => {
    setOverrideWithConditionsFormData({
      ...overrideWithConditionsFormData,
      customDataFieldsKey: value
    });
  }

  const {
    tableColumnName,
    conditionValueType,
    overrideValueType,
    conditionValue,
    overrideValue,
    conditions,
    customDataFieldsKey,
    operation,
    overrideWithTableColumnName,
    overrideWithTableColumnNameOption
  } = overrideWithConditionsFormData;

  const conditionsWithTableColumnOverride = conditions.filter(condition => condition.overrideWithTableColumnName);
  const conditionsWithoutTableColumnOverride = conditions.filter(condition => !condition.overrideWithTableColumnName);

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <div>
          <span style={{ paddingRight: '10px' }}>Table Column:</span>
          <Dropdown
            search
            placeholder='Table Column'
            selection
            value={tableColumnName}
            className='export-transformation-modal-dropdown'
            onChange={onTableColumnNameChange}
            options={getTableColumns()} />
          {tableColumnName === 'customDataFields' && <Input placeholder='Key' onChange={onCustomDataFieldsKeyChange} value={customDataFieldsKey} />}
        </div>
        <div style={{ paddingTop: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Operation:</span>
          <Dropdown
            search
            placeholder='Operation'
            selection
            value={operation}
            className='export-transformation-modal-dropdown'
            onChange={onOperationChange}
            options={[
              { key: OVERRIDE_WITH_CONDITIONS_OPERATIONS['='], value: OVERRIDE_WITH_CONDITIONS_OPERATIONS['='], text: OVERRIDE_WITH_CONDITIONS_OPERATIONS['='] },
              { key: OVERRIDE_WITH_CONDITIONS_OPERATIONS['>'], value: OVERRIDE_WITH_CONDITIONS_OPERATIONS['>'], text: OVERRIDE_WITH_CONDITIONS_OPERATIONS['>'] },
              { key: OVERRIDE_WITH_CONDITIONS_OPERATIONS['<'], value: OVERRIDE_WITH_CONDITIONS_OPERATIONS['<'], text: OVERRIDE_WITH_CONDITIONS_OPERATIONS['<'] }
            ]} />
          {tableColumnName === 'customDataFields' && <Input placeholder='Key' onChange={onCustomDataFieldsKeyChange} value={customDataFieldsKey} />}
        </div>
        <div style={{ paddingTop: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Condition Value Type:</span>
          <Dropdown
            placeholder='Type'
            className='export-transformation-value-type-small-dropdown'
            onChange={onConditionValueTypeChange}
            value={conditionValueType}
            selection
            options={[
              { key: EXPORT_ARGUMENT_VALUE_TYPE.string, value: EXPORT_ARGUMENT_VALUE_TYPE.string, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.string) },
              { key: EXPORT_ARGUMENT_VALUE_TYPE.number, value: EXPORT_ARGUMENT_VALUE_TYPE.number, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.number) },
            ]} />
          <span style={{ paddingRight: '10px' }}>Value:</span>
          <Input value={conditionValue} onChange={onConditionValueChange} type={conditionValueType === EXPORT_ARGUMENT_VALUE_TYPE.string ? 'text' : 'number'} placeholder='Value' />
        </div>
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Override With Table Column Name</span>
          <Checkbox checked={overrideWithTableColumnNameOption} onChange={onOverrideWithTableColumnNameOptionChange} />
        </div>
        {overrideWithTableColumnNameOption ? (<div style={{ paddingBottom: '10px' }}>
          <span style={{ paddingRight: '10px' }}>Table Column:</span>
          <Dropdown
            search
            placeholder='Override With Table Column Name'
            selection
            value={overrideWithTableColumnName}
            className='export-transformation-modal-dropdown'
            onChange={onOverrideWithTableColumnNameChange}
            options={getTableColumns()} />
          {tableColumnName === 'customDataFields' && <Input placeholder='Key' onChange={onCustomDataFieldsKeyChange} value={customDataFieldsKey} />}
        </div>) : (
          <div style={{ paddingBottom: '10px' }}>
            <span style={{ paddingRight: '10px' }}>Override Value Type:</span>
            <Dropdown
              placeholder='Type'
              className='export-transformation-value-type-small-dropdown'
              onChange={onOverrideValueTypeChange}
              value={overrideValueType}
              selection
              options={[
                { key: EXPORT_ARGUMENT_VALUE_TYPE.string, value: EXPORT_ARGUMENT_VALUE_TYPE.string, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.string) },
                { key: EXPORT_ARGUMENT_VALUE_TYPE.number, value: EXPORT_ARGUMENT_VALUE_TYPE.number, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.number) },
              ]} />
            <span style={{ paddingRight: '10px' }}>Value:</span>
            <Input value={overrideValue} onChange={onOverrideValueChange} type={overrideValueType === EXPORT_ARGUMENT_VALUE_TYPE.string ? 'text' : 'number'} placeholder='Value' />
          </div>)}
        <div style={{ paddingBottom: '10px' }}>
          <Button onClick={onAddCondition}>Add</Button>
        </div>
        {conditionsWithoutTableColumnOverride.length > 0 && <Grid data={conditionsWithoutTableColumnOverride}>
          <Column title='Condition Operation' field='operation' width='200px' />
          <Column title='Condition Type' field='conditionValueType' width='150px' />
          <Column title='Condition Value' field='conditionValue' width='150px' />
          <Column title='Override Type' field='overrideValueType' width='150px' />
          <Column title='Override Value' field='overrideValue' width='150px' />
        </Grid>}
        <br />
        {conditionsWithTableColumnOverride.length > 0 && <Grid data={conditionsWithTableColumnOverride}>
          <Column title='Condition Operation' field='operation' width='200px' />
          <Column title='Condition Type' field='conditionValueType' width='150px' />
          <Column title='Condition Value' field='conditionValue' width='150px' />
          <Column title='Override With Table Column Name' field='overrideWithTableColumnName' width='250px' />
        </Grid>}
      </div>
    </>
  )
}

const EqualForm = ({
  tableColumnName1,
  tableColumnName2,
  onTableColumnName1Change,
  onTableColumnName2Change,
  isEqualValue,
  isNotEqualValue,
  isNullValue,
  onIsEqualValueChange,
  onIsNotEqualValueChange,
  onIsNullValueChange,
  workOrderType,
  onCustomDataFieldsKey1Change,
  onCustomDataFieldsKey2Change,
  customDataFieldsKey1,
  customDataFieldsKey2,
}) => {

  const getTableColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>Table Column 1:</span>
        <Dropdown
          search
          placeholder='Table Column 1'
          selection
          value={tableColumnName1}
          className='export-transformation-modal-dropdown'
          onChange={onTableColumnName1Change}
          options={getTableColumns()} />
        {tableColumnName1 === 'customDataFields' && <Input placeholder='Key' onChange={onCustomDataFieldsKey1Change} value={customDataFieldsKey1} />}
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>Table Column 2:</span>
        <Dropdown
          search
          placeholder='Table Column 2'
          selection
          value={tableColumnName2}
          className='export-transformation-modal-dropdown'
          onChange={onTableColumnName2Change}
          options={getTableColumns()} />
        {tableColumnName2 === 'customDataFields' && <Input placeholder='Key' onChange={onCustomDataFieldsKey2Change} value={customDataFieldsKey2} />}
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>Equal value:</span>
        <Input value={isEqualValue} onChange={onIsEqualValueChange} placeholder='Equal Value' />
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>Not equal value:</span>
        <Input value={isNotEqualValue} onChange={onIsNotEqualValueChange} placeholder='Not Equal Value' />
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <span style={{ paddingRight: '10px' }}>If both values are null:</span>
        <Input value={isNullValue} onChange={onIsNullValueChange} placeholder='If both values are null' />
      </div>
    </>
  )
}

const ExportReportTemplateTransformationModal = ({ showModal, closeModal, onSaveTransformation, workOrderType }) => {
  const [transformation, setTransformation] = useState({});
  const [argument, setArgument] = useState({ ...argumentSchema });

  const [tableColumnName1, setTableColumnName1] = useState('');
  const [tableColumnName2, setTableColumnName2] = useState('');

  const [customDataFieldsKey1, setCustomDataFieldsKey1] = useState('');
  const [customDataFieldsKey2, setCustomDataFieldsKey2] = useState('');

  const [isEqualValue, setIsEqualValue] = useState('');
  const [isNotEqualValue, setIsNotEqualValue] = useState('');
  const [isNullValue, setIsNullValue] = useState('');

  const [ifTrue, setIfTrue] = useState('');
  const [ifNotTrue, setIfNotTrue] = useState('');

  const [overrideWithConditionsFormData, setOverrideWithConditionsFormData] = useState({ ...overrideWithConditionsFormInitialData });

  const [useIfExistFormData, setUseIfExistFormData] = useState({ ...useIfExistFormInitialData });
  const [useIfNotExistFormData, setUseIfNotExistFormData] = useState({ ...useIfNotExistFormInitialData });

  const onSetIfTrue = (event, { value }) => {
    setIfTrue(value);
  }

  const onSetIfNotTrue = (event, { value }) => {
    setIfNotTrue(value);
  }

  const onCustomDataFieldsKey1Change = (event, { value }) => {
    setCustomDataFieldsKey1(value);
  }

  const onCustomDataFieldsKey2Change = (event, { value }) => {
    setCustomDataFieldsKey2(value);
  }

  const onTableColumnName1Change = (event, { value }) => {
    setTableColumnName1(value);
  }

  const onTableColumnName2Change = (event, { value }) => {
    setTableColumnName2(value);
  }

  const onIsEqualValueChange = (event, { value }) => {
    setIsEqualValue(value);
  }

  const onIsNotEqualValueChange = (event, { value }) => {
    setIsNotEqualValue(value);
  }

  const onIsNullValueChange = (event, { value }) => {
    setIsNullValue(value);
  }

  const onTransformationNameChange = (event, { value }) => {
    if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfExist) {
      setUseIfExistFormData({ ...useIfExistFormInitialData });
    }
    if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfNotExist) {
      setUseIfNotExistFormData({ ...useIfNotExistFormInitialData });
    }
    if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.overrideWithConditions) {
      setOverrideWithConditionsFormData({ ...overrideWithConditionsFormInitialData });
    }
    if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.equal || transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.add || transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.subtract) {
      setTableColumnName1('');
      setTableColumnName2('');
      if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.equal) {
        setIsEqualValue('');
        setIsNotEqualValue('');
        setIsNullValue('');
        setCustomDataFieldsKey1('');
        setCustomDataFieldsKey2('');
      }
    }
    setTransformation({ ...transformation, name: value, args: [] });
    if (value === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.formatDate) {
      setArgument({ type: EXPORT_ARGUMENT_VALUE_TYPE.string, value: '' });
    } else {
      setArgument({ type: '', value: '' });
    }
  }

  const onArgumentTypeChange = (event, { value }) => {
    setArgument({ ...argument, type: value });
  }

  const onArgumentValueChange = (event, { value }) => {
    setArgument({ ...argument, value });
  }

  const onAddArgument = () => {
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.json) {
      argument.value = JSON.parse(argument.value);
    }
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.number) {
      argument.value = +argument.value;
    }
    setTransformation({ ...transformation, args: [...transformation.args ? transformation.args : [], argument.value] });
    setArgument({ ...argumentSchema });
  }

  const onAddSubtractTransformationSave = () => {
    onSaveTransformation({ ...transformation, args: [{ tableColumnName1, tableColumnName2 }] });
  }

  const onEqualTransformationSave = () => {
    onSaveTransformation({ ...transformation, args: [{ tableColumnName1, tableColumnName2, isEqualValue, isNotEqualValue, isNullValue, customDataFieldsKey1, customDataFieldsKey2 }] });
  }

  const onTruncateTransformationSave = () => {
    onSaveTransformation({ ...transformation });
  }

  const onMapBooleanTransformationSave = () => {
    onSaveTransformation({ ...transformation, args: [{ ifTrue, ifNotTrue }] });
  }

  const onUseIfExistTransformationSave = () => {
    const { tableColumnName1, tableColumnName2, defaultTo, defaultValueType } = useIfExistFormData;
    const arg = {
      tableColumnName1,
      tableColumnName2
    };
    if (defaultValueType) {
      arg.defaultTo = defaultValueType === EXPORT_ARGUMENT_VALUE_TYPE.string ? defaultTo : +defaultTo;
    }
    onSaveTransformation({
      ...transformation, args: [arg]
    });
  }

  const onUseIfNotExistTransformationSave = () => {
    const { tableColumnName1, tableColumnName2, defaultTo, defaultValueType } = useIfNotExistFormData;
    const arg = {
      tableColumnName1,
      tableColumnName2
    };
    if (defaultValueType) {
      arg.defaultTo = defaultValueType === EXPORT_ARGUMENT_VALUE_TYPE.string ? defaultTo : +defaultTo;
    }
    onSaveTransformation({
      ...transformation, args: [arg]
    });
  }

  const onOverrideWithConditionsTransformationSave = () => {
    const { tableColumnName, conditions, customDataFieldsKey } = overrideWithConditionsFormData;
    const arg = {
      tableColumnName,
      conditions: conditions.map(({
        conditionValueType,
        conditionValue,
        overrideValueType,
        overrideValue,
        operation = OVERRIDE_WITH_CONDITIONS_OPERATIONS['='],
        overrideWithTableColumnName
      }) => {
        const result = {
          value: conditionValueType === 'number' ? +conditionValue : conditionValue,
          operation
        };
        if (overrideWithTableColumnName) {
          result.override_with_table_column_name = overrideWithTableColumnName;
        } else {
          result.override = overrideValueType === 'number' ? +overrideValue : overrideValue;
        }
        return result;
      })
    };
    if (tableColumnName === 'customDataFields') {
      arg.customDataFieldsKey = customDataFieldsKey;
    }
    onSaveTransformation({
      ...transformation, args: [arg]
    });
  }

  const onSubmit = () => {
    if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfExist) {
      onUseIfExistTransformationSave();
    } else if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfNotExist) {
      onUseIfNotExistTransformationSave();
    } else if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.truncate) {
      onTruncateTransformationSave();
    } else if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.mapBoolean) {
      onMapBooleanTransformationSave();
    } else if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.add || transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.subtract) {
      onAddSubtractTransformationSave();
    } else if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.equal) {
      onEqualTransformationSave();
    } else if (transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.overrideWithConditions) {
      onOverrideWithConditionsTransformationSave();
    } else {
      onSaveTransformation(transformation);
    }
  }

  return (
    <Modal dimmer='blurring' size='small' open={showModal}>
      <Modal.Header>New Transformation</Modal.Header>
      <Modal.Content>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ paddingBottom: '10px' }}>
            <span style={{ paddingRight: '10px' }}>Name:</span>
            <Dropdown
              search
              placeholder='Name'
              selection
              className='export-transformation-type-dropdown'
              onChange={onTransformationNameChange}
              options={[
                { key: 0, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE['/'], text: EXPORT_TRANSOFRMATION_TYPE_LABEL['/'] },
                { key: 1, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.truncate, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.truncate },
                { key: 2, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.toFixed, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.toFixed },
                { key: 3, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.formatDate, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.formatDate },
                { key: 4, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.pad, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.pad },
                { key: 5, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.add, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.add },
                { key: 6, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.subtract, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.subtract },
                { key: 7, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.equal, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.equal },
                { key: 8, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.mapBoolean, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.mapBoolean },
                { key: 9, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.overrideWithConditions, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.overrideWithConditions },
                { key: 10, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfExist, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.useIfExist },
                { key: 10, value: EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfNotExist, text: EXPORT_TRANSOFRMATION_TYPE_LABEL.useIfNotExist },
              ]} />
          </div>
          <div style={{ paddingBottom: '10px' }}>
            {transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfNotExist &&
              <UseIfNotExistForm
                setUseIfNotExistFormData={setUseIfNotExistFormData}
                useIfNotExistFormData={useIfNotExistFormData}
                workOrderType={workOrderType} />}
            {transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfExist &&
              <UseIfExistForm
                setUseIfExistFormData={setUseIfExistFormData}
                useIfExistFormData={useIfExistFormData}
                workOrderType={workOrderType} />}
            {transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.overrideWithConditions &&
              <OverrideWithConditionsForm
                overrideWithConditionsFormData={overrideWithConditionsFormData}
                setOverrideWithConditionsFormData={setOverrideWithConditionsFormData}
                workOrderType={workOrderType} />}
            {transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.mapBoolean &&
              <MapBooleanForm
                ifTrue={ifTrue}
                ifNotTrue={ifNotTrue}
                onSetIfNotTrue={onSetIfNotTrue}
                onSetIfTrue={onSetIfTrue}
              />}
            {transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.equal &&
              <EqualForm
                onCustomDataFieldsKey1Change={onCustomDataFieldsKey1Change}
                onCustomDataFieldsKey2Change={onCustomDataFieldsKey2Change}
                customDataFieldsKey1={customDataFieldsKey1}
                customDataFieldsKey2={customDataFieldsKey2}
                isEqualValue={isEqualValue}
                isNotEqualValue={isNotEqualValue}
                isNullValue={isNullValue}
                onIsEqualValueChange={onIsEqualValueChange}
                onIsNotEqualValueChange={onIsNotEqualValueChange}
                onIsNullValueChange={onIsNullValueChange}
                tableColumnName1={tableColumnName1}
                tableColumnName2={tableColumnName2}
                onTableColumnName1Change={onTableColumnName1Change}
                onTableColumnName2Change={onTableColumnName2Change}
                workOrderType={workOrderType} />}
            {(transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.add || transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.subtract) &&
              <AddSubtractForm
                tableColumnName1={tableColumnName1}
                tableColumnName2={tableColumnName2}
                onTableColumnName1Change={onTableColumnName1Change}
                onTableColumnName2Change={onTableColumnName2Change}
                workOrderType={workOrderType} />}
            {transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfExist &&
              transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfNotExist &&
              transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.overrideWithConditions &&
              transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.add &&
              transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.subtract &&
              transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.mapBoolean &&
              transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.equal &&
              transformation.name !== EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.truncate && (
                <>
                  <span style={{ paddingRight: '10px' }}>Type:</span>
                  <Dropdown
                    placeholder='Type'
                    className='export-transformation-value-type-small-dropdown'
                    onChange={onArgumentTypeChange}
                    value={argument.type}
                    selection
                    options={[
                      { key: EXPORT_ARGUMENT_VALUE_TYPE.string, value: EXPORT_ARGUMENT_VALUE_TYPE.string, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.string) },
                      { key: EXPORT_ARGUMENT_VALUE_TYPE.number, value: EXPORT_ARGUMENT_VALUE_TYPE.number, text: capitalize(EXPORT_ARGUMENT_VALUE_TYPE.number) },
                      { key: EXPORT_ARGUMENT_VALUE_TYPE.json, value: EXPORT_ARGUMENT_VALUE_TYPE.json, text: EXPORT_ARGUMENT_VALUE_TYPE.json.toUpperCase() }
                    ]} />
                  <span style={{ paddingRight: '10px' }}>Value:</span>
                  {transformation.name === EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.formatDate ? (
                    <DropdownDatePicker
                      placeholder='Date Format'
                      className='export-transformation-value-type-small-dropdown'
                      onChange={onArgumentValueChange}
                      value={argument.value}
                    />
                  ) : (
                    <Input value={argument.value} onChange={onArgumentValueChange} type={argument.type === EXPORT_ARGUMENT_VALUE_TYPE.number ? EXPORT_ARGUMENT_VALUE_TYPE.number : EXPORT_ARGUMENT_VALUE_TYPE.string} placeholder='Value' className='export-operation-value-input' />
                  )}
                  <Button disabled={!argument.type} onClick={onAddArgument}>Add</Button>
                </>
              )}
          </div>
          {transformation.args && transformation.args.length > 0 && <Grid data={transformation.args.map(item => Object.assign({}, {
            type: typeof (item) === 'object' ? EXPORT_ARGUMENT_VALUE_TYPE.json : typeof (item),
            value: typeof (item) === EXPORT_ARGUMENT_VALUE_TYPE.string ? item : JSON.stringify(item)
          }))}>
            <Column title='Type' field='type' width='150px' />
            <Column title='Value' field='value' width='150px' />
          </Grid>}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Save' />
      </Modal.Actions>
    </Modal>
  )
}

export default ExportReportTemplateTransformationModal;