import { applyMiddleware, combineReducers, createStore } from 'redux';
import mapReducer from './reducers/map.reducer';
import administrationOverviewReducer from './reducers/administrationOverview.reducer';
import schedulerReducer from './reducers/scheduler.reducer';
import workOrderDetailsMenuReducer from './reducers/workOrderDetailsMenu.reducer';
import ganttStateReducer from './reducers/ganttState.reducer';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  map: mapReducer,
  administrationOverview: administrationOverviewReducer,
  scheduler: schedulerReducer,
  workOrderDetailsMenu: workOrderDetailsMenuReducer,
  gantt: ganttStateReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;