import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RoutesRenderer from './core/components/router';
import VersionNotification from './shared/components/notification';
import GlobalState from './core/context/GlobalState';
import { ApolloProvider } from '@apollo/client';
import client from './core/apollo';
import ReactGA from 'react-ga';
import config from './config';
import { Provider } from 'react-redux';
import store from './core/redux';
import './App.css';
import './semantic/dist/semantic.min.css';
import '@progress/kendo-theme-material/dist/all.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'hammerjs';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(config.googleAnalyticsTrackingCode, {
    debug: false,
    titleCase: false,
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  });
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <VersionNotification />
          <GlobalState>
            <BrowserRouter>
              <RoutesRenderer />
            </BrowserRouter>
          </GlobalState>
        </ApolloProvider>
      </Provider>
    );
  }
}

export default App;
