export const TRANSFORMATION_DATE_FORMATS = {
  'yyyy-mm-dd hh:mm:ss': 'yyyy-mm-dd hh:mm:ss',
  'mm/dd/yyyy hh:mm:ss tt': 'mm/dd/yyyy hh:mm:ss tt',
  'm/d/yy hh:mm': 'm/d/yy hh:mm',
  'mm/dd/yyyy': 'mm/dd/yyyy',
  'yymmdd': 'yymmdd',
  'yyyymmdd': 'yyyymmdd',
  'mmddyyyy': 'mmddyyyy',
  'hhmm': 'hhmm',
  'hh:mm': 'hh:mm',
  'dmyy': 'dmyy',
  'mdyy': 'mdyy',
  'yyyy-mm-dd': 'yyyy-mm-dd',
  'mm/dd/yyyy hh:mm': 'mm/dd/yyyy hh:mm',
  'yyyymmddhhmm': 'yyyymmddhhmm',
  'mm-dd-yyyy': 'mm-dd-yyyy'
}

export const EXPORT_ARGUMENT_VALUE_TYPE = {
  string: 'string',
  json: 'json',
  number: 'number'
}

export const EXPORT_COLUMN_TYPE = {
  string: 'string',
  json: 'json',
  boolean: 'boolean',
}

export const EXPORT_CONDITION_OPERATION_TYPE = {
  match: 'match',
  matchKeyValue: 'matchKeyValue',
  includes: 'includes',
  excludes: 'excludes',
  includeIfKeyExists: 'includeIfKeyExists'
}

export const EXPORT_CONDITION_OPERATION_TYPE_LABEL = {
  [EXPORT_CONDITION_OPERATION_TYPE.match]: 'Match',
  [EXPORT_CONDITION_OPERATION_TYPE.matchKeyValue]: 'Match Key/Value',
  [EXPORT_CONDITION_OPERATION_TYPE.includes]: 'Includes',
  [EXPORT_CONDITION_OPERATION_TYPE.excludes]: 'Excludes',
  [EXPORT_CONDITION_OPERATION_TYPE.includeIfKeyExists]: 'Include If Key Exists',
}

export const EXPORT_TEMPLATE_TRANSOFRMATION_TYPE = {
  '/': '/',
  'truncate': 'truncate',
  'toFixed': 'toFixed',
  'formatDate': 'formatDate',
  'pad': 'pad',
  'add': 'add',
  'subtract': 'subtract',
  'equal': 'equal',
  'mapBoolean': 'mapBoolean',
  'overrideWithConditions': 'overrideWithConditions',
  'useIfExist': 'useIfExist',
  'useIfNotExist': 'useIfNotExist',
}

export const EXPORT_TRANSOFRMATION_TYPE_LABEL = {
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE['/']]: 'Divide by number',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.truncate]: 'Remove any fractional digits',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.toFixed]: 'Formats a number using fixed-point notation',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.formatDate]: 'Format date',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.pad]: 'Pads the current value with another value',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.add]: 'Add two numbers',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.subtract]: 'Subtract two numbers',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.equal]: 'Equal',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.mapBoolean]: 'Map Boolean',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.overrideWithConditions]: 'Override with conditions',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfExist]: 'Use if exist',
  [EXPORT_TEMPLATE_TRANSOFRMATION_TYPE.useIfNotExist]: 'Use if not exist'
}

export const OVERRIDE_WITH_CONDITIONS_OPERATIONS = {
  '=': '=',
  '>': '>',
  '<': '<'
}

export const EXPORT_TRANSFORMATION_CONDITION_OPERATION = {
  match: 'match',
}

export const EXPORT_BEFORE_UPLOAD_OPERATION_TYPE = {
  unshiftRow: 'unshiftRow',
  pushRow: 'pushRow'
}

export const EXPORT_BEFORE_UPLOAD_OPERATION_TYPE_LABEL = {
  [EXPORT_BEFORE_UPLOAD_OPERATION_TYPE.unshiftRow]: 'Unshift Row',
  [EXPORT_BEFORE_UPLOAD_OPERATION_TYPE.pushRow]: 'Push Row'
}

export const PROJECT_MOBILE_TEMPLATES_SCHEMA = `
  input ProjectMobileTemplateSettingsValue {
    label: String!
      value: String!
  }

  input MobileTemplateContainer {
    settings: [ProjectMobileTemplateSettingsValue!]
    mobileTemplates: [MobileTemplate]!
  }

  input MobileTemplate {
    workOrderType: WorkOrderType
    screens: [MobileScreen]
  }

  enum WorkOrderType {
    # v2
    MeterDeploy
    WaterMeterDeploy
    # v3
    ElecDeploy
    ElecNetworkInstall
    ElecSiteSurvey
    ElecSiteTest
    WaterDeploy
    WaterSiteSurvey
  }

  input MobileScreen {
    name: String
    title: String
    backAction: String
    components: [MobileComponentGroup]
  }

  input MobileComponentGroup {
    name: String
    group: String
    isVisible: Boolean
    items: [MobileComponent]
  }

  input MobileComponent {
    name: String
    type: String
    isVisible: Boolean
    isMandatory: Boolean
    validationOverrides: [MobileOverrides]
    visibilityOverrides: [MobileOverrides]
    separatorVisible: Boolean
    componentHeight: Int
    presentationStyle: String
    instructionText: String
    isEditable: Boolean
    populateOnEdit: Boolean
    title: String
    titleWidth: Int
    titlePrefix: String
    titleData: String
    subtitleData: String
    infoImageData: String
    infoAdditionalData: String
    infoStyle: String
    fontSize: String
    isMultilineText: Boolean
    action: String
    actionParameter: String
    dataProperty: String
    dataPropertyType: String
    customDataSection: String
    photoType: String
    captureGps: Boolean
    switchVisible: Boolean
    switchOnText: String
    switchDataProperty: String
    defaultSwitchValue: Boolean
    isMultiSelect: Boolean
    alignment: String
    colorStyle: String
    inventoryType: String
    storeDestination: String
    validationDataProperty: String
    placeholderText: String
    trueText: String
    falseText: String
    coreLocationVisible: Boolean
    refreshButtonVisible: Boolean
    items: [MobileComponent]
  }

  input MobileOverrides {
    name: String
    value: Boolean
  }
`

export const PROJECT_DEFAULTS_SCHEMA = `
  input ProjectDefaultsInput {
    projectDefaults: [ProjectDefaultInput]
  }

  input ProjectDefaultInput {
    defaultTypeName: String!
    defaultTypeValues: [DefaultTypeValueInput!]!
  }

  input DefaultTypeValueInput {
    label: String!
    value: String!
    sortOrder: Int
    isDefault: Boolean
  }
`