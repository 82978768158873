import client from '../apollo';
import {
  GET_WORKORDER_BY_ID,
  LIST_WORKORDERS_BY_TYPE_QUERY,
  GET_WORKORDER_FILTER_CRITERIA_QUERY,
  LIST_WORKORDERS_STATUS_COUNTS_QUERY,
  LIST_WORKORDER_STATUS_BY_ROUTE_QUERY,
  LIST_METER_SIZE_BY_ROUTE_QUERY,
  LIST_WEEKLY_PRODUCTION_STATUS,
  LIST_WORKORDERS_BY_TENANT_QUERY,
  LIST_WORKORDERS_BY_CUSTOMER_INFORMATION,
  LIST_DAILY_PRODUCTION_STATUS_BY_METER_TYPE,
  LIST_DAILY_PRODUCTION_STATUS_BY_REGISTER_TYPE,
  LIST_DAILY_PRODUCTION_STATUS_BY_RADIO_TYPE,
  LIST_WORKORDER_GROUPS_QUERY,
  LIST_WORKORDER_ROUTE_CODES_QUERY,
  LIST_WORKORDERS_MAP_LAYOUT_DATA_QUERY,
  GET_WORKORDER_INVALID_LOCATION_COUNT_QUERY,
  GET_SITE_TEST_RESULTS_QUERY,
  GET_METER_TEST_RESULTS_QUERY,
  GET_WORKORDER_BY_OLD_METER_NUMBER_QUERY,
  GET_SERVICE_CODE_BY_WORKORDER,
  LIST_DAILY_PRODUCTION_STATUS_BY_NON_SERIALIZED_INVENTORY_QUERY,
  LIST_CSR_PORTAL_QUERY,
} from '../../graphql/queries/workorder';
import config from '../../config';
import { getToken } from './auth.service';
import { getUserById } from './user.service';
import {
  DELETE_WORKORDER_MUTATION,
  SAVE_WORKORDERS_GROUP_MUTATION,
  RTU_WORKORDER_MUTATION,
  UPDATE_WORKORDER_MUTATION,
  RE_OPEN_CLOSED_WORKORDER_MUTATION,
  ADMIN_CREATE_WORKORDER_MUTATION,
  CREATE_WORKORDER_HISTORY,
  SWAP_WORKORDERS_MUTATION,
  UPDATE_WORKORDER_NEW_METER_NUMBER_MUTATION,
  COMPLETE_CALL_MUTATION,
  DUPLICATE_WORKORDER_MUTATION
} from '../../graphql/mutations/workorder';

export const duplicateWorkOrder = async ({ projectId, workOrderId, userId }) => {
  try {
    await client.mutate({
      mutation: DUPLICATE_WORKORDER_MUTATION,
      variables: { projectId, workOrderId, userId }
    });
  } catch (error) {
    console.log(error);
  }
}

export const reOpenClosedWorkOrder = async ({ projectId, workOrderIds, userId, workOrderStatus, rework = null }) => {
  try {
    await client.mutate({
      mutation: RE_OPEN_CLOSED_WORKORDER_MUTATION,
      variables: { projectId, workOrderIds, userId, workOrderStatus, rework }
    });
  } catch (error) {
    console.log(error);
  }
}

export const rtuWorkOrder = async (workOrder) => {
  try {
    await client.mutate({
      mutation: RTU_WORKORDER_MUTATION,
      variables: {
        ...workOrder
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const getWorkOrderDetails = async (id) => {
  try {
    const workOrderId = typeof id === 'string' ? id : id.id;
    const { data } = await client.query({
      query: GET_WORKORDER_BY_ID,
      variables: { id: workOrderId },
      fetchPolicy: 'no-cache'
    });
    if (data && data.getWorkOrder) {
      const workOrderDetails = data.getWorkOrder;
      if (workOrderDetails.workOrderResourceUserId) {
        const data = await getUserById(workOrderDetails.workOrderResourceUserId);
        return { userName: data.userName, ...workOrderDetails };
      }
      return workOrderDetails;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}

export const getServiceCodeByWorkOrder = async ( id ) => {
  try {
    const { data } = await client.query({
      query: GET_SERVICE_CODE_BY_WORKORDER,
      variables: { id },
      fetchPolicy: 'no-cache'
    });
    if (data && data.getServiceCodeByWorkOrder) {
      return data.getServiceCodeByWorkOrder.serviceCode;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}

export const getWorkOrders = async ({ projectId, workOrderType, offset, limit, search, filter, multipleValuesSearch }) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDERS_BY_TYPE_QUERY,
      variables: { projectId, offset, limit, search, filter, multipleValuesSearch, workOrderType },
      fetchPolicy: 'no-cache'
    });
    const { resultCount, items } = data.listWorkOrdersByWorkOrderType;
    return { workOrders: items, resultCount };
  } catch (error) {
    console.log(error);
  }
}

export const getCsrPortalWorkOrders = async (projectId, offset) => {
  try {
    const { data } = await client.query({
      query: LIST_CSR_PORTAL_QUERY,
      variables: { projectId, offset },
      fetchPolicy: 'no-cache'
    });
    const result = data && data.listCsrPortalWorkOrders ? data.listCsrPortalWorkOrders.items : [];
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const completeCall = async ({ userId, workOrderId, comment, callSuccess }) => {
  try {
    await client.mutate({
      mutation: COMPLETE_CALL_MUTATION,
      variables: {
        userId,
        workOrderId,
        comment,
        callSuccess,
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getWorkOrdersByTenant = async ({ tenant, offset, limit, search }) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDERS_BY_TENANT_QUERY,
      variables: { tenant, offset, limit, search },
      fetchPolicy: 'no-cache'
    });
    return data && data.listWorkOrdersByTenant ? data.listWorkOrdersByTenant.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const getWorkOrdersByCustomerInformation = async ({ tenant, offset, limit, search }) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDERS_BY_CUSTOMER_INFORMATION,
      variables: { tenant, offset, limit, search },
      fetchPolicy: 'no-cache'
    });
    return data && data.listWorkOrdersByCustomerInformation ? data.listWorkOrdersByCustomerInformation.items : [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const getFilterTypes = async (projectId) => {
  try {
    const { data } = await client.query({
      query: GET_WORKORDER_FILTER_CRITERIA_QUERY,
      fetchPolicy: 'no-cache',
      variables: { projectId }
    });
    return data.listWorkOrderFilterCriteria || [];
  } catch (error) {
    console.log(error);
  }
}

export const convertUrlsToSignedUrls = async (url) => {
  const token = await getToken();
  const response = await fetch(`${config.workOrderDetailsImageApi}?imagesPaths=${url}`, {
    method: 'get',
    headers: new Headers({
      'Authorization': token
    })
  });
  const signedUrl = await response.json();
  return signedUrl;
}

export const updateWorkOrderStatus = async ({
  query,
  workOrderId,
  userId,
  oldMeterKwhReading,
  oldMeterKwReading,
  oldMeterKvaReading,
  oldMeterKvarReading,
  comment,
  closeWorkOrderUponApproval = false
}) => {
  try {
    await client.mutate({
      mutation: query,
      variables: {
        workOrderId,
        userId,
        oldMeterKwhReading,
        oldMeterKwReading,
        oldMeterKvaReading,
        oldMeterKvarReading,
        comment,
        isRework: closeWorkOrderUponApproval
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const getWorkOrdersStatusCounts = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDERS_STATUS_COUNTS_QUERY,
      variables: { projectId: project.id },
      fetchPolicy: 'no-cache'
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export const getMeterTestResults = async (project) => {
  try {
    const { data } = await client.query({
      query: GET_METER_TEST_RESULTS_QUERY,
      variables: { projectId: project.id },
      fetchPolicy: 'no-cache'
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export const getSiteTestResults = async (project, version) => {
  try {
    const { data } = await client.query({
      query: GET_SITE_TEST_RESULTS_QUERY,
      variables: { projectId: project.id, resultVersion: version },
      fetchPolicy: 'no-cache'
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export const deleteWorkOrder = async (projectId, workOrderIds) => {
  try {
    await client.mutate({
      mutation: DELETE_WORKORDER_MUTATION,
      variables: { projectId, workOrderIds }
    });
  } catch (error) {
    console.log(error);
  }
}

export const workOrderAssignments = async (mutation, projectId, userIds, workOrderIds, isMapView = false) => {
  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        projectId,
        userIds,
        workOrderIds,
        isMapView
      }
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export const listWorkOrderStatusByRoute = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDER_STATUS_BY_ROUTE_QUERY,
      variables: { projectId: project.id },
      fetchPolicy: 'no-cache'
    });

    const sortedRows = [];

    if (data && data.listWorkOrderStatusByRoute && data.listWorkOrderStatusByRoute.items.length > 0) {
      const { projectSequence } = project;
      if (projectSequence && projectSequence.planningRouteCodeOrder && projectSequence.planningRouteCodeOrder.length > 0) {
        const { planningRouteCodeOrder } = projectSequence;
        planningRouteCodeOrder.forEach(routeCode => {
          let row = data.listWorkOrderStatusByRoute.items.find(item => item.routeCode === routeCode.route);
          if (row) {
            sortedRows.push(row);
          }
        });
      } else {
        return [...data.listWorkOrderStatusByRoute.items];
      }
      return sortedRows;
    }

    return [];
  } catch (error) {
    console.log(error);
  }
}

export const listMeterSizeByRoute = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_METER_SIZE_BY_ROUTE_QUERY,
      variables: { projectId: project.id },
      fetchPolicy: 'no-cache'
    });
    if (data && data.listMeterSizeByRoute) {
      let rows = [];
      const listMeterSizeByRoute = data.listMeterSizeByRoute;
      const {
        meterAvailable,
        registerAvailable,
        routes,
        meterCounts,
        registerCounts,
        meterColumnNames,
        registerColumnNames,
        radioCounts,
        radioAvailable,
        hasMeter,
        hasRegister,
        hasRadio
      } = listMeterSizeByRoute;

      if (hasMeter) {
        meterCounts.forEach((item, itemIndex) => {
          const row = { Route: routes[itemIndex] };
          item.counts.forEach((count, itemIndex) => {
            row[meterColumnNames[itemIndex]] = count;
            row['Meter Remaining Total'] = (row['Meter Remaining Total'] || 0) + count;;
          });
          if (hasRegister) {
            registerCounts[itemIndex].counts.forEach((count, itemIndex) => {
              row[registerColumnNames[itemIndex]] = count;
              row['Register Remaining Total'] = (row['Register Remaining Total'] || 0) + count;
            });
          }
          if (hasRadio) {
            row['Radio'] = radioCounts[itemIndex].counts[0];
          }
          rows.push(row);
        });

        meterColumnNames.push('Meter Remaining Total');
        if (hasRegister) {
          registerColumnNames.push('Register Remaining Total');
        }
      }

      if (!hasMeter && hasRegister) {
        registerCounts.forEach((item, itemIndex) => {
          const row = { Route: routes[itemIndex], 'Register Remaining Total': 0 };
          item.counts.forEach((count, itemIndex) => {
            row[registerColumnNames[itemIndex]] = count;
            row['Register Remaining Total'] += count;
          });
          if (hasRadio) {
            row['Radio'] = radioCounts[itemIndex].counts[0];
          }
          rows.push(row);
        });

        registerColumnNames.push('Register Remaining Total');
      }

      const availableRow = { Route: 'Available' };
      if (hasMeter) {
        meterColumnNames.forEach((column, index) => {
          availableRow[column] = meterAvailable[index];
        });
        availableRow['Meter Remaining Total'] = 0
      }
      if (hasRegister) {
        registerColumnNames.forEach((column, index) => {
          availableRow[column] = registerAvailable[index];
        });
        availableRow['Register Remaining Total'] = 0
      }
      if (hasRadio) {
        availableRow['Radio'] = radioAvailable[0];
      }

      rows.unshift(availableRow);

      if (hasMeter) {
        meterColumnNames.unshift('Route');
      }
      if (!hasMeter && hasRegister) {
        registerColumnNames.unshift('Route');
      }

      const { projectSequence } = project;
      if (projectSequence) {
        const { planningRouteCodeOrder } = projectSequence;
        if (planningRouteCodeOrder && planningRouteCodeOrder.length > 0) {
          let sortedRows = [rows[0]];
          planningRouteCodeOrder.forEach(routeCode => {
            let row = rows.find(item => item.Route === routeCode.route);
            if (row) {
              row.adjustedDays = routeCode.adjustedDays;
              sortedRows.push(row);
            }
          });
          rows = sortedRows;
        }
      }

      return [meterColumnNames, registerColumnNames, rows, hasMeter, hasRegister, hasRadio];
    }
    return [[], [], [], false, false, false];
  } catch (error) {
    console.log(error);
  }
}

export const listWeeklyProductionStatus = async (project, week) => {
  try {
    const { data } = await client.query({
      query: LIST_WEEKLY_PRODUCTION_STATUS,
      variables: { projectId: project.id, week },
      fetchPolicy: 'no-cache'
    });
    let weeklyProductionStatus = data && data.listWeeklyProductionStatus ? data.listWeeklyProductionStatus.items : [];
    let status = [...weeklyProductionStatus];
    if (status.length > 0) {
      const columns = Object.keys(status[0]);
      const totals = { userName: 'Totals' };
      columns.forEach(columnName => {
        if (columnName !== 'userName') {
          status.forEach(row => {
            totals[columnName] = row[columnName] + (totals[columnName] || 0)
          })
        }
      });
      status.push(totals);
    }
    return status;
  } catch (error) {
    console.log(error);
  }
}

export const listDailyProductionStatusByMeterType = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_DAILY_PRODUCTION_STATUS_BY_METER_TYPE,
      variables: { projectId: project.id },
      fetchPolicy: 'no-cache'
    });
    let dailyProductionStatus = data && data.listDailyProductionStatusByMeterType ? data.listDailyProductionStatusByMeterType : [];
    if (dailyProductionStatus.items.length > 0) {
      const results = [];
      for (let [index, row] of dailyProductionStatus.items.entries()) {
        let tempRow = { counts: [...row.counts] };
        tempRow.counts.unshift(dailyProductionStatus.technicians[index]);
        const result = {};
        dailyProductionStatus.columnNames.forEach((column, index) => {
          result[column] = tempRow.counts[index];
        });
        results.push(result);
      }
      return [dailyProductionStatus.columnNames, results, dailyProductionStatus.hasMeter];
    }
    return [[], [], false];
  } catch (error) {
    console.log(error);
  }
}

export const listDailyProductionStatusByRegisterType = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_DAILY_PRODUCTION_STATUS_BY_REGISTER_TYPE,
      variables: { projectId: project.id },
      fetchPolicy: 'no-cache'
    });
    let dailyProductionStatus = data && data.listDailyProductionStatusByRegisterType ? data.listDailyProductionStatusByRegisterType : [];
    if (dailyProductionStatus.items.length > 0) {
      const results = [];
      for (let [index, row] of dailyProductionStatus.items.entries()) {
        let tempRow = { counts: [...row.counts] };
        tempRow.counts.unshift(dailyProductionStatus.technicians[index]);
        const result = {};
        dailyProductionStatus.columnNames.forEach((column, index) => {
          result[column] = tempRow.counts[index];
        });
        results.push(result);
      }
      return [dailyProductionStatus.columnNames, results, dailyProductionStatus.hasRegister];
    }
    return [[], [], false];
  } catch (error) {
    console.log(error);
  }
}

export const listDailyProductionStatusByNonSerializedInventory = async (project) => {
  const { data } = await client.query({
    query: LIST_DAILY_PRODUCTION_STATUS_BY_NON_SERIALIZED_INVENTORY_QUERY,
    variables: { projectId: project.id },
    fetchPolicy: 'no-cache'
  });
  let results = data && data.listDailyProductionStatusByNonSerializedInventory ? data.listDailyProductionStatusByNonSerializedInventory : null;
 
  if (results && results.technicians.length > 0 && results.assetTypes.length > 0) {
    const mergeResult = {};

    for (const [assetTypeIndex, assetType] of results.assetTypes.entries()) {
      mergeResult[assetType] = {};
      mergeResult[assetType].columnNames = ['Technician', ...results.assetTypeColumns[assetTypeIndex].columns];
      mergeResult[assetType].rows = [];
  
      for (let [itemIndex, item] of results.assetTypeItems[assetTypeIndex].items.entries()) {
        const row = {};
        mergeResult[assetType].columnNames.forEach((column, columnIndex) => {
          row[column] = columnIndex === 0 ? results.technicians[itemIndex] : item.counts[columnIndex - 1];
          if (itemIndex === results.assetTypeItems[assetTypeIndex].items.length - 1) {
            row['Technician'] = 'Total';
          }
        })
        mergeResult[assetType].rows.push(row);
      }
    }
    return mergeResult;
  }
  return null;
}


export const listDailyProductionStatusByRadioType = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_DAILY_PRODUCTION_STATUS_BY_RADIO_TYPE,
      variables: { projectId: project.id },
      fetchPolicy: 'no-cache'
    });
    let dailyProductionStatus = data && data.listDailyProductionStatusByRadioType ? data.listDailyProductionStatusByRadioType : [];
    if (dailyProductionStatus.items.length > 0) {
      const results = [];
      for (let [index, row] of dailyProductionStatus.items.entries()) {
        let tempRow = { counts: [...row.counts] };
        tempRow.counts.unshift(dailyProductionStatus.technicians[index]);
        const result = {};
        dailyProductionStatus.columnNames.forEach((column, index) => {
          result[column] = tempRow.counts[index];
        });
        results.push(result);
      }
      return [dailyProductionStatus.columnNames, results, dailyProductionStatus.hasRadio];
    }
    return [[], [], false];
  } catch (error) {
    console.log(error);
  }
}

export const saveWorkOrderGroups = async (project, groupName, workOrderIds) => {
  try {
    await client.mutate({
      mutation: SAVE_WORKORDERS_GROUP_MUTATION,
      variables: {
        projectId: project.id,
        groupName,
        workOrderIds
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const listWorkOrderGroups = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDER_GROUPS_QUERY,
      variables: { project },
      fetchPolicy: 'no-cache'
    });
    return data && data.listWorkOrderGroups && data.listWorkOrderGroups.items && data.listWorkOrderGroups.items.length > 0 ? data.listWorkOrderGroups.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const listWorkOrderRouteCodes = async (project) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDER_ROUTE_CODES_QUERY,
      variables: { project },
      fetchPolicy: 'no-cache'
    });
    return data && data.listWorkOrderRouteCodes && data.listWorkOrderRouteCodes.items && data.listWorkOrderRouteCodes.items.length > 0 ? data.listWorkOrderRouteCodes.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const updateWorkOrder = async (workOrder) => {
  try {
    await client.mutate({
      mutation: UPDATE_WORKORDER_MUTATION,
      variables: {
        ...workOrder
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const updateWorkOrderNewMeterNumber = async (payload) => {
  try {
    await client.mutate({
      mutation: UPDATE_WORKORDER_NEW_METER_NUMBER_MUTATION,
      variables: {
        ...payload
      }
    });
  } catch (error) {
    throw error;
  }
}

export const listWorkOrdersMapLayoutData = async (project, workOrderType) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDERS_MAP_LAYOUT_DATA_QUERY,
      variables: { project, workOrderType },
      fetchPolicy: 'no-cache'
    });
    return data && data.listWorkOrdersMapLayoutData ? data.listWorkOrdersMapLayoutData : [];
  } catch (error) {
    console.log(error);
  }
}

export const getWorkOrderInvalidLocationCount = async (project) => {
  try {
    const { data } = await client.query({
      query: GET_WORKORDER_INVALID_LOCATION_COUNT_QUERY,
      variables: { project: project.id },
      fetchPolicy: 'no-cache'
    });
    return data && data.getWorkOrderInvalidLocationCount ? data.getWorkOrderInvalidLocationCount.count : null;
  } catch (error) {
    console.log(error);
  }
}

export const adminCreateWorkOrder = async (workOrder) => {
  try {
    await client.mutate({
      mutation: ADMIN_CREATE_WORKORDER_MUTATION,
      variables: {
        ...workOrder
      }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const createWorkOrderHistory = async ({historyContent, historyUser, historyWorkOrder}) => {
  try {
    await client.mutate({
      mutation: CREATE_WORKORDER_HISTORY,
      variables: { historyContent: historyContent, historyUser: historyUser, historyWorkOrder: historyWorkOrder }
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getWorkOrderByOldMeterNumber = async (projectId, workOrderType, oldMeterNumber) => {
  try {
    const { data } = await client.query({
      query: GET_WORKORDER_BY_OLD_METER_NUMBER_QUERY,
      variables: { projectId, workOrderType, oldMeterNumber }
    });
    return data && data.getWorkOrderByOldMeterNumber ? data.getWorkOrderByOldMeterNumber : null;
  } catch (error) {
    console.log(error);
  }
}

export const swapWorkOrders = async (projectId, workOrderId1, workOrderId2, userId) => {
  try {
    await client.mutate({
      mutation: SWAP_WORKORDERS_MUTATION,
      variables: {
        projectId,
        workOrderId1,
        workOrderId2,
        userId
      }
    });
  } catch (error) {
    throw error;
  }
}