import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const Search = ({ searchRef, onSearch, placeholder, loading, hasWorkOrders, onClearSearch }) => (
  <React.Fragment>
    <div className='field ui input' style={{ paddingRight: '10px', width: '100%' }}>
      {hasWorkOrders && <div onClick={onClearSearch} style={{ cursor: 'pointer', position: 'absolute', right: 14, top: 10 }}>
        <Icon name='delete' />
      </div>}
      <input id='search' ref={searchRef} type='text' placeholder={placeholder} style={{ width: '400px', paddingRight: '5px' }} />
    </div>
    <Button loading={loading} primary onClick={onSearch}>Search</Button>
  </React.Fragment>
)

export default Search;