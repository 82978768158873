import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Image, Input, Modal, Table } from 'semantic-ui-react';
import EDIT_ICON from '../../../assets/icons/baseline_edit_black_18dp.png';
import CUSTOM_DATA_FIELD_TYPES from '../../../constants/customDataFieldTypes';

const renderInputFields = (properties, onInputValueChange, onCheckboxValueChange, values) => {
  return properties.map((customData, index) => {
    if (customData.type === CUSTOM_DATA_FIELD_TYPES.String) {
      if (customData.options && customData.options.length > 0) {
        return (
          <InputField text={customData.key} key={index} >
            <Dropdown selection onChange={(event, data) => onInputValueChange(event, data, customData)} value={values[customData.key] || ''} options={customData.options.map((optionItem, index) => Object.assign({}, { key: index, text: optionItem, value: optionItem }))} />
          </InputField>
        )
      }
      return (
        <InputField text={customData.key} key={index} >
          <Input placeholder={customData.key} type='text' onChange={(event, data) => onInputValueChange(event, data, customData)} value={values[customData.key] || ''} />
        </InputField>
      )
    } else if (customData.type === CUSTOM_DATA_FIELD_TYPES.Float || customData.type === CUSTOM_DATA_FIELD_TYPES.Int) {
      return (
        <InputField text={customData.key} key={index} >
          <Input placeholder={customData.key} type='number' onChange={(event, data) => onInputValueChange(event, data, customData)} value={+values[customData.key]} />
        </InputField>
      )
    } else if (customData.type === CUSTOM_DATA_FIELD_TYPES.Boolean) {
      return (
        <InputField text={customData.key} key={index} >
          <Checkbox onChange={(event, data) => onCheckboxValueChange(event, data, customData)} checked={values[customData.key] === 'true'} />
        </InputField>
      )
    }
    return null;
  });
}

const InputField = ({ text, children }) => (
  <div style={{ display: 'flex', marginBottom: 10 }}>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 10 }}>
      <span>{text}:</span>
    </div>
    {children}
  </div>
)

const AddCustomDataArrayItemModal = ({ closeModal, showModal, onAdd, properties }) => {
  const [result, setResult] = useState({});

  useEffect(() => {
    for (const customData of properties) {
      const { key, type } = customData;
      if (type === CUSTOM_DATA_FIELD_TYPES.String) {
        result[key] = '';
      } else if (type === CUSTOM_DATA_FIELD_TYPES.Int || type === CUSTOM_DATA_FIELD_TYPES.Float) {
        result[key] = '0';
      } else if (type === CUSTOM_DATA_FIELD_TYPES.Boolean) {
        result[key] = 'false';
      } else {
        result[key] = '';
      }
    }
    setResult({ ...result });
  }, []);

  const onInputValueChange = (event, { value }, { key }) => {
    setResult({ ...result, [key]: value + '' });
  }

  const onCheckboxValueChange = (event, { checked }, { key }) => {
    setResult({ ...result, [key]: checked ? 'true' : 'false' });
  }

  const onSubmit = () => {
    properties.forEach(customData => {
      customData.value = result[customData.key];
    });
    onAdd(properties);
  }

  return (
    <>
      <Modal size='tiny' open={showModal}>
        <Modal.Header>Add</Modal.Header>
        <Modal.Content>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {renderInputFields(properties, onInputValueChange, onCheckboxValueChange, result)}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} primary>Cancel</Button>
          <Button positive icon='checkmark' labelPosition='right' content='Add' onClick={onSubmit} />
        </Modal.Actions>
      </Modal>
    </>
  )
}

const EditCustomDataArrayItemModal = ({ closeModal, showModal, item, onEdit, onDelete }) => {
  const [result, setResult] = useState({});

  useEffect(() => {
    for (const customData of item) {
      const { key, value } = customData;
      result[key] = value;
    }
    setResult({ ...result });
  }, []);

  const onInputValueChange = (event, { value }, { key }) => {
    setResult({ ...result, [key]: value + '' });
  }

  const onCheckboxValueChange = (event, { checked }, { key }) => {
    setResult({ ...result, [key]: checked ? 'true' : 'false' });
  }

  const onSubmit = () => {
    item.forEach(customData => {
      customData.value = result[customData.key];
    });
    onEdit(item);
  }

  return (
    <>
      <Modal size='tiny' open={showModal}>
        <Modal.Header>Edit</Modal.Header>
        <Modal.Content>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {renderInputFields(item, onInputValueChange, onCheckboxValueChange, result)}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button onClick={onDelete} color='red'>Delete</Button>
            </div>
            <div>
              <Button onClick={closeModal} primary>Cancel</Button>
              <Button positive icon='checkmark' labelPosition='right' content='Edit' onClick={onSubmit} />
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  )
}

const CustomDataArrayModal = ({ closeModal, showModal, customData, onSubmit, editable, properties }) => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [selectedItemKey, setSelectedItemKey] = useState(undefined);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    const data = JSON.parse(customData.value || null) || [];
    setData(data);
    setTitle(customData.key);
  }, []);

  const onShowEditModal = (item, itemKey) => {
    for (const customDataItem of item) {
      const { options } = properties.find(property => property.key === customDataItem.key);
      customDataItem.options = options ? options : undefined;
    }
    setSelectedItem(item);
    setShowEditModal(true);
    setSelectedItemKey(itemKey);
  }

  const onCloseEditModal = () => {
    setSelectedItem(undefined);
    setSelectedItemKey(undefined);
    setShowEditModal(false);
  }

  const onCloseAddModal = () => {
    setSelectedItem(undefined);
    setSelectedItemKey(undefined);
    setShowAddModal(false);
  }

  const onEdit = (item) => {
    data[selectedItemKey] = item;
    setData([...data]);
    onCloseEditModal();
  }

  const onDelete = () => {
    data.splice(selectedItemKey, 1);
    setData([...data]);
    onCloseEditModal();
  }

  const onAdd = (item) => {
    setData([...data, item]);
    onCloseAddModal();
  }

  return (
    <>
      {showAddModal && <AddCustomDataArrayItemModal
        showModal={showAddModal}
        closeModal={onCloseAddModal}
        properties={[...properties]}
        onAdd={onAdd} />}
      {showEditModal && <EditCustomDataArrayItemModal
        onDelete={onDelete}
        showModal={showEditModal}
        closeModal={onCloseEditModal}
        item={selectedItem}
        onEdit={onEdit} />}
      <Modal dimmer={{ style: { zIndex: '1002' }}} size='large' open={showModal}>
        <Modal.Header>Additional - {title}</Modal.Header>
        <Modal.Content>
          {editable && <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setShowAddModal(true)} primary>Add</Button>
          </div>}
          {data.length > 0 && <Table celled>
            {data.length > 0 && <Table.Header>
              <Table.Row>
                {properties.map((column, key) => <Table.HeaderCell key={key}>{column.key}</Table.HeaderCell>)}
                {editable && <Table.HeaderCell></Table.HeaderCell>}
              </Table.Row>
            </Table.Header>}
            <Table.Body>
              {data.map((row, rowKey) => (
                <Table.Row key={rowKey}>
                  {row.map((column, columnKey) =>
                    <Table.Cell key={columnKey}>
                      <span>{column.value}</span>
                    </Table.Cell>)}
                  {editable && <Table.Cell>
                    <Image src={EDIT_ICON} style={{ cursor: 'pointer' }} onClick={() => onShowEditModal(row, rowKey)} />
                  </Table.Cell>}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} primary>{editable ? 'Cancel' : 'Close'}</Button>
          {editable && <Button positive icon='checkmark' labelPosition='right' content='Save' onClick={() => onSubmit(data)} />}
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default CustomDataArrayModal;