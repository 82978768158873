import React, { Component } from 'react';
import { Route } from 'react-router-dom';

class NoAuthRoute extends Component {
  getRoute = renderProps => {
    const { component: Comp } = this.props;
    return <Comp {...renderProps} />;
  };

  render() {
    const { component, uiState, ...rest } = this.props;
    return <Route {...rest} render={renderProps => this.getRoute(renderProps)} />;
  }
}


export default NoAuthRoute;