import React, { Component, useEffect, useState } from 'react';
import AppContext from '../../../core/context/app.context';
import TopMenu from './top-menu';
import { Segment, Header, Button, Icon, Message } from 'semantic-ui-react';
import routeMaps from '../../../core/route-maps';
import config from '../../../config';
import { getAllTenants } from '../../../core/services/tenant.service';
import ROLES from '../../../constants/roles';
import Search from './search';
import {
  Grid,
  GridColumn as Column
} from '@progress/kendo-react-grid';
import WORKORDER_DETAILS_ICON from '../../../assets/icons/workorder-details.svg';
import * as workOrderService from '../../../core/services/workOrder.service';
import { getToken, isEnabled } from '../../../core/services/auth.service';
import { useTimeZone } from '../../../helpers/date';
import MeterDeployDetailsModal from '../../../shared/components/workorder-details/meterDeployDetails';
import WaterMeterDeployDetailsModal from '../../../shared/components/workorder-details/waterMeterDeployDetails';
import WaterDeployDetailsModal from '../../../shared/components/workorder-details/waterDeployDetails';
import WORKORDER_TYPES from '../../../constants/workOrderTypes';
import Loader from '../../../shared/components/loader';
import { orderPhotosByType } from '../../../helpers/workOrderPhoto';
import AddProjectModal from './modal/addProject';
import { createProject } from '../../../core/services/project.service';
import './styles.css';
import { connect } from 'react-redux';
import * as mapActions from '../../../core/redux/actions/map.actions';

const initNewProject = {
  isActive: false,
  isDemo: false,
  projectName: '',
  projectShortCode: '',
  projectBucket: '',
  projectTimeZone: '',
  projectHasBlackOuts: false,
  latitude: '',
  longitude: '',
  projectWorkOrderType: ''
}

class AdministrationProjectsRoute extends Component {

  static contextType = AppContext;
  _isMounted = false;
  searchRef = React.createRef();
  addProjectModalRef = React.createRef();

  state = {
    workOrders: [],
    searchBy: '',
    loading: false,
    showMeterDeployDetails: false,
    showWaterMeterDeployDetails: false,
    selectedWorkOrder: null,
    modalDetailsLoading: false,
    showWaterDeployDetails: false,
    photoIndex: 0,
    selectedWorkOrderIndex: 0,
    showAddProjectModal: false,
    newProject: { ...initNewProject },
    addProjectError: null,
    addProjectLoading: false,
    showNoResultsFoundMessage: false
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setState = (state) => {
    if (this._isMounted) {
      super.setState(state);
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    let { user, initUser, setTenants, setTenant, currentTenant, setProject } = this.context;
    setProject(null);
    if (!user) {
      user = await initUser();
    }
    if (user.userRoles.indexOf(ROLES.SystemAdmin) > -1) {
      const tenants = await getAllTenants();
      setTenants(tenants);
    } else {
      if (!currentTenant) {
        setTenant(user.tenant);
      }
    }
  }

  setModalDetailsLoading = (modalDetailsLoading) => {
    this.setState({ modalDetailsLoading });
  }

  onUpdateProject = ({
    id,
    isActive,
    isDemo,
    projectName,
    projectShortCode,
    projectBucket,
    projectTimeZone,
    projectHasBlackOuts,
    latitude,
    longitude,
    projectWorkOrderType
  }) => {
    this.setState({
      newProject: {
        id,
        isActive,
        isDemo,
        projectName,
        projectShortCode,
        projectBucket,
        projectTimeZone,
        projectHasBlackOuts,
        latitude,
        longitude,
        projectWorkOrderType
      },
      showAddProjectModal: true,
      addProjectError: null,
    });
    this.addProjectModalRef.current.focus();
  }

  onAddProject = () => {
    const { administrationActiveProjectsPageActive } = this.context;
    this.setState({
      addProjectError: null,
      showAddProjectModal: true,
      newProject: { ...initNewProject, isActive: administrationActiveProjectsPageActive }
    });
    this.addProjectModalRef.current.focus();
  }

  addProject = async () => {
    this.setState({ addProjectLoading: true, addProjectError: null });
    const { newProject } = this.state;
    try {
      if (!newProject.projectName) {
        throw new Error('Project name is required');
      }
      if (!newProject.projectBucket) {
        throw new Error('Project bucket name is required');
      }
      if (!newProject.projectShortCode) {
        throw new Error('Project short code is required');
      }
      if (!newProject.projectTimeZone) {
        throw new Error('Project time zone is required');
      }
      if (!newProject.projectWorkOrderType) {
        throw new Error('Work order type is required');
      }
      if (!newProject.longitude && newProject.longitude !== 0) {
        throw new Error('Project longitude is required');
      }
      if (!newProject.latitude && newProject.latitude !== 0) {
        throw new Error('Project latitude is required');
      }
      const { setTenant, currentTenant } = this.context;
      await createProject(newProject, currentTenant.id);
      setTenant(currentTenant);
      this.setState({ showAddProjectModal: false, newProject: { ...initNewProject }, addProjectLoading: false });
    } catch (error) {
      this.setState({ addProjectLoading: false, addProjectError: error });
    }
  }

  onIsDemoChange = (event, { checked }) => {
    this.setState({ newProject: { ...this.state.newProject, isDemo: checked } });
  }

  onIsActiveChange = (event, { checked }) => {
    this.setState({ newProject: { ...this.state.newProject, isActive: checked } });
  }

  onProjectNameChange = (event, { value }) => {
    this.setState({ newProject: { ...this.state.newProject, projectName: value } });
  }

  onBucketNameChange = (event, { value }) => {
    this.setState({ newProject: { ...this.state.newProject, projectBucket: value } });
  }

  onShortCodeChange = (event, { value }) => {
    this.setState({ newProject: { ...this.state.newProject, projectShortCode: value } });
  }

  onTimeZoneChange = (event, { value }) => {
    this.setState({ newProject: { ...this.state.newProject, projectTimeZone: value } });
  }

  onWorkOrderTypeChange = (event, { value }) => {
    this.setState({ newProject: { ...this.state.newProject, projectWorkOrderType: value } });
  }

  onLongitudeChange = (event, { value }) => {
    this.setState({ newProject: { ...this.state.newProject, longitude: +value } });
  }

  onLatitudeChange = (event, { value }) => {
    this.setState({ newProject: { ...this.state.newProject, latitude: +value } });
  }

  onHasBlackOutsChange = (event, { checked }) => {
    this.setState({ newProject: { ...this.state.newProject, projectHasBlackOuts: checked } });
  }

  closeAddProjectModal = () => {
    this.setState({ showAddProjectModal: false });
  }

  renderTopMenu = () => {
    const { currentTenant, administrationActiveProjectsPageActive, user } = this.context;
    const projectsTitle = administrationActiveProjectsPageActive ? 'Active Projects' : 'Inactive Projects';
    const topMenuConfig = {
      header: {
        title: `${currentTenant && currentTenant.tenantName ? `${currentTenant.tenantName} - ${projectsTitle}` : `${projectsTitle}`}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} currentTenant={currentTenant} user={user} onAddProject={this.onAddProject} />;
  }

  onGallerySlide = (photoIndex) => {
    this.setState({ photoIndex });
  }

  setProject = (project) => {
    const { setProject } = this.context;
    if (project.projectConfiguration && typeof (project.projectConfiguration) === 'string') {
      project.projectConfiguration = JSON.parse(project.projectConfiguration);
    }
    if (project.projectMobileTemplates && typeof (project.projectMobileTemplates) === 'string') {
      project.projectMobileTemplates = JSON.parse(project.projectMobileTemplates);
    }
    if (project.projectSequence && typeof (project.projectSequence) === 'string') {
      project.projectSequence = JSON.parse(project.projectSequence);
    }
    if (project.projectImportConfig && typeof (project.projectImportConfig) === 'string') {
      project.projectImportConfig = JSON.parse(project.projectImportConfig);
    }
    setProject(project);
  }

  openProject = (project) => {
    const { history, resetMapState } = this.props;
    this.setProject(project);
    resetMapState();
    history.push(routeMaps.dashboard);
  }

  getImagePreSignedUrl = async imagesPaths => {
    const token = await getToken();
    const response = await fetch(
      `${config.workOrderDetailsImageApi}?imagesPaths=${imagesPaths}`,
      {
        method: 'get',
        headers: new Headers({
          Authorization: token
        })
      }
    );
    const imageUrl = await response.json();
    return imageUrl;
  };

  filterWorkOrderCommentsByUserName = (workOrder) => {
    const { user } = this.context;
    if (workOrder.workOrderComments && workOrder.workOrderComments.items && workOrder.workOrderComments.items.length > 0 && workOrder.userName) {
      workOrder.workOrderComments.items = useTimeZone(workOrder.workOrderComments.items, ['contentDate'], workOrder.project.projectTimeZone);
      // show all comments if user is SystemAdmin/ClientAdmin/AccountAdmin/Admin
      if (!isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.ClientAdmin, ROLES.AccountAdmin, ROLES.Admin])) {
        workOrder.workOrderComments.items = workOrder.workOrderComments.items.filter(comment => comment.user.userName === workOrder.userName);
      }
    }
    return workOrder;
  }

  formatWorkOrderDetailsPhotos = async workOrder => {
    let workOrderCopy = Object.assign({}, workOrder);
    if (workOrderCopy.photos.items && workOrderCopy.photos.items.length > 0) {
      workOrderCopy = orderPhotosByType(workOrderCopy);
      const imagesPaths = workOrderCopy.photos.items.map(({ file }) => {
        return `${file.bucket}/${file.key}`;
      });
      const urls = await this.getImagePreSignedUrl(JSON.stringify(imagesPaths));
      const imagesUrls = urls.map((url, index) => {
        return { original: url, thumbnail: url, photoName: workOrder.photos.items[index].file.key };
      });
      workOrderCopy.photos = imagesUrls;
    } else {
      workOrderCopy.photos = [];
    }
    return workOrderCopy;
  };

  onNext = () => {
    let { selectedWorkOrderIndex } = this.state;
    let { workOrders } = this.state;
    let nextWorkOrder = null;
    if (workOrders.length - 1 < selectedWorkOrderIndex + 1) {
      nextWorkOrder = workOrders[0];
      selectedWorkOrderIndex = 0;
    } else {
      selectedWorkOrderIndex = selectedWorkOrderIndex + 1;
      nextWorkOrder = workOrders[selectedWorkOrderIndex];
    }
    this.onPreviewClick({ dataItem: nextWorkOrder });
  }

  onPreviewClick = async ({ dataItem }) => {
    const { workOrders } = this.state;
    const { tenantProjects } = this.context;
    this.setState({ modalDetailsLoading: true });
    const selectedWorkOrderIndex = workOrders.findIndex(workOrder => workOrder === dataItem);
    let selectedWorkOrder = await workOrderService.getWorkOrderDetails(dataItem.id);
    selectedWorkOrder = await this.formatWorkOrderDetailsPhotos(selectedWorkOrder);
    selectedWorkOrder = this.filterWorkOrderCommentsByUserName(selectedWorkOrder);
    selectedWorkOrder['workOrderAssignments'] = dataItem['workOrderAssignments'] || '';
    selectedWorkOrder = useTimeZone(selectedWorkOrder, ['workOrderLastVisitDate', 'workOrderDeletedDate'], selectedWorkOrder.project.projectTimeZone);
    let newState = { selectedWorkOrder, selectedWorkOrderIndex, modalDetailsLoading: false };
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { ...newState, showMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { ...newState, showWaterMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { ...newState, showWaterDeployDetails: true };
    }
    const project = tenantProjects.find(i => i.id === selectedWorkOrder.project.id);
    this.setProject(project);
    this.setState(newState);
  };

  PreviewButtonRenderer = props => (
    <td style={{ textAlign: 'center' }}>
      <img
        onClick={() => this.onPreviewClick(props)}
        style={{ paddingTop: '11px', cursor: 'pointer' }}
        src={WORKORDER_DETAILS_ICON}
        alt='workorder-details'
      />
    </td>
  );

  onSearch = async () => {
    const { currentTenant } = this.context;
    const searchBy = this.searchRef.current.value.trimLeft().trimRight().replaceAll(`'`, `\\'`);
    this.setState({ searchBy, loading: true, workOrders: [], showNoResultsFoundMessage: false });
    const workOrders = await workOrderService.getWorkOrdersByTenant({ tenant: currentTenant.id, search: searchBy });
    const newState = { loading: false, workOrders };
    if (workOrders.length === 0) {
      newState.showNoResultsFoundMessage = true;
    }
    this.setState(newState);
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }

  onGetWorkOrderDetails = async (workOrderId) => {
    this.setState({ modalDetailsLoading: true });
    let selectedWorkOrder = await workOrderService.getWorkOrderDetails(workOrderId);
    selectedWorkOrder = await this.formatWorkOrderDetailsPhotos(selectedWorkOrder);
    selectedWorkOrder = this.filterWorkOrderCommentsByUserName(selectedWorkOrder);
    this.setState({ selectedWorkOrder, modalDetailsLoading: false });
  }

  updateWorkOrder = async (workOrderId, update) => {
    if (typeof (update) === 'function') {
      await update();
    }
    const { searchBy } = this.state;
    const { currentTenant } = this.context;
    this.onGetWorkOrderDetails(workOrderId)
    const workOrders = await workOrderService.getWorkOrdersByTenant({ tenant: currentTenant.id, search: searchBy });
    this.setState({ workOrders });
  }

  closeMeterDeployDetails = () => {
    const { setProject } = this.context;
    setProject(null);
    this.setState({ showMeterDeployDetails: false, photoIndex: 0 });
  };

  closeWaterMeterDeployDetails = () => {
    const { setProject } = this.context;
    setProject(null);
    this.setState({ showWaterMeterDeployDetails: false, photoIndex: 0 });
  }

  closeWaterDeployDetails = () => {
    const { setProject } = this.context;
    setProject(null);
    this.setState({ showWaterDeployDetails: false, photoIndex: 0 });
  }

  onClearSearch = () => {
    document.getElementById('search').value = '';
    this.setState({ workOrders: [] });
  }

  render() {
    const {
      tenantProjects,
      user,
      loadingTenantProjects,
      currentProject,
      administrationActiveProjectsPageActive
    } = this.context;
    const {
      workOrders,
      loading,
      showMeterDeployDetails,
      showWaterMeterDeployDetails,
      modalDetailsLoading,
      selectedWorkOrder,
      showWaterDeployDetails,
      photoIndex,
      showAddProjectModal,
      addProjectError,
      addProjectLoading,
      newProject,
      showNoResultsFoundMessage
    } = this.state;
    const { history } = this.props;
    return (
      <Segment basic className='segment'>
        {loadingTenantProjects && <Loader loading={loadingTenantProjects} />}
        <div className='container'>
          {this.renderTopMenu()}
          <AddProjectModal
            showModal={showAddProjectModal}
            closeModal={this.closeAddProjectModal}
            error={addProjectError}
            onModalConfirm={this.addProject}
            loading={addProjectLoading}
            addProjectModalRef={this.addProjectModalRef}
            onIsDemoChange={this.onIsDemoChange}
            onIsActiveChange={this.onIsActiveChange}
            onProjectNameChange={this.onProjectNameChange}
            onBucketNameChange={this.onBucketNameChange}
            onShortCodeChange={this.onShortCodeChange}
            onTimeZoneChange={this.onTimeZoneChange}
            onLongitudeChange={this.onLongitudeChange}
            onLatitudeChange={this.onLatitudeChange}
            onWorkOrderTypeChange={this.onWorkOrderTypeChange}
            onHasBlackOutsChange={this.onHasBlackOutsChange}
            newProject={newProject} />
          {tenantProjects.length > 0 ? (
            <React.Fragment>
              <div className='administration-projects-workorders-listview' onKeyPress={this.handleKeyPress}>
              {administrationActiveProjectsPageActive && 
              <>
                <div className='workorders-listview-tool-bar'>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Search
                      onClearSearch={this.onClearSearch}
                      hasWorkOrders={workOrders.length > 0}
                      searchRef={this.searchRef}
                      loading={loading}
                      onSearch={this.onSearch}
                      placeholder='Search by work order number, customer, account, street, record locator'
                    />
                  </div>
                </div>
                {showNoResultsFoundMessage &&
                  <Message
                    className='search-margin'
                    onDismiss={() => this.setState({ showNoResultsFoundMessage: false })}
                    header='Search'
                    content='No results found'
                  />}
                {showMeterDeployDetails && <MeterDeployDetailsModal
                  getWorkOrderDetails={this.onGetWorkOrderDetails}
                  setModalDetailsLoading={this.setModalDetailsLoading}
                  onNext={this.onNext}
                  user={user}
                  modalDetailsLoading={modalDetailsLoading}
                  selectedWorkOrder={selectedWorkOrder}
                  closeModal={this.closeMeterDeployDetails}
                  showModal={showMeterDeployDetails}
                  onGallerySlide={this.onGallerySlide}
                  photoIndex={photoIndex}
                  currentProject={currentProject}
                  updateWorkOrder={this.updateWorkOrder}
                />}
                {showWaterMeterDeployDetails && <WaterMeterDeployDetailsModal
                  getWorkOrderDetails={this.onGetWorkOrderDetails}
                  setModalDetailsLoading={this.setModalDetailsLoading}
                  onNext={this.onNext}
                  user={user}
                  modalDetailsLoading={modalDetailsLoading}
                  selectedWorkOrder={selectedWorkOrder}
                  closeModal={this.closeWaterMeterDeployDetails}
                  showModal={showWaterMeterDeployDetails}
                  onGallerySlide={this.onGallerySlide}
                  photoIndex={photoIndex}
                  currentProject={currentProject}
                  updateWorkOrder={this.updateWorkOrder}
                />}
                {showWaterDeployDetails && <WaterDeployDetailsModal
                  getWorkOrderDetails={this.onGetWorkOrderDetails}
                  setModalDetailsLoading={this.setModalDetailsLoading}
                  onNext={this.onNext}
                  user={user}
                  modalDetailsLoading={modalDetailsLoading}
                  selectedWorkOrder={selectedWorkOrder}
                  closeModal={this.closeWaterDeployDetails}
                  showModal={showWaterDeployDetails}
                  onGallerySlide={this.onGallerySlide}
                  photoIndex={photoIndex}
                  currentProject={currentProject}
                  updateWorkOrder={this.updateWorkOrder}
                />}
                {workOrders.length > 0 && (
                  <Grid
                    onClick={e => e.preventDefault()}
                    className='workorders-listview-grid'
                    sortable
                    style={{ height: '92%', marginBottom: '10px' }}
                    data={workOrders}
                  >
                    <Column
                      field=''
                      title='View'
                      width='150px'
                      cell={this.PreviewButtonRenderer}
                      headerClassName='workorder-details-column'
                    />
                    <Column field='workOrderNumber' title='Work Order' width='150px' />
                    <Column field='workOrderType' title='Work Order Type' width='160px' />
                    <Column field='workOrderStatus' title='Status' width='120px' />
                    <Column
                      field='workOrderAssignments'
                      title='Assignments'
                      width='250px'
                    />
                    <Column
                      field='workOrderLastVisitDate'
                      title='Last Visit'
                      width='150px'
                    />
                    <Column field='accountNumber' title='Account' width='150px' />
                    <Column field='customerName' title='Customer' width='200px' />
                    <Column field='street' title='Street' width='200px' />
                    <Column field='districtCode' title='District' width='100px' />
                    <Column field='substationCode' title='Substation' width='120px' />
                    <Column field='circuitCode' title='Circuit' width='150px' />
                    <Column field='billingCycleCode' title='Cycle' width='100px' />
                    <Column field='routeCode' title='Route' width='100px' />
                    <Column field='bookCode' title='Book' width='100px' />
                    <Column
                      field='workOrderAttemptNumber'
                      title='Attempt'
                      width='100px'
                    />
                    <Column field='workOrderFlaggedAsCNC' title='RTC/CNC' width='110px' />
                    <Column
                      field='workOrderFlaggedAs3strike'
                      title='RTU/3-S'
                      width='110px'
                    />
                    <Column
                      field='workOrderFlaggedAsAdHoc'
                      title='Ad Hoc'
                      width='100px'
                    />
                    <Column
                      field='workOrderIsInBlackOut'
                      title='Blackout'
                      width='100px'
                    />
                    <Column
                      field='workOrderFlaggedAsEscalated'
                      title='Escalated'
                      width='120px'
                    />
                    <Column
                      field='workOrderNeedsAppointment'
                      title='Needs Appointment'
                      width='180px'
                    />
                    <Column
                      field='workOrderNeedsSiteTest'
                      title='Specialty'
                      width='180px'
                    />
                  </Grid>
                )}
              </>
              }
              <ProjectsListView
                  history={history}
                  user={user}
                  active={administrationActiveProjectsPageActive ? true : false}
                  tenantProjects={tenantProjects}
                  openProject={this.openProject} />

              </div>
            </React.Fragment>
          ) : <React.Fragment><div>Select tenant to continue</div></React.Fragment>}
        </div>
      </Segment>
    )
  }
}

const ProjectsListView = ({ tenantProjects, openProject, active, history, user }) => {
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const showProjects = tenantProjects.filter(i => i.isActive === active);
    setFilteredProjects(showProjects);
  }, [active, tenantProjects]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {filteredProjects.length === 0 && 
        <Loader loading={true} />}
      {filteredProjects.length > 0 && (
        <React.Fragment>
          {filteredProjects.map((project) => (
            <div key={project.id} style={{ minWidth: '320px', height: '240px', marginRight: '15px', marginBottom: '15px' }}>
              <Segment>
                <Header className='project-title'>{project.projectName} {isEnabled(user.userRoles, [ROLES.SystemAdmin]) && <Icon fitted size='mini' name='setting' link onClick={() => history.push(routeMaps.administrationUpdateProject, { project })} />}</Header>
                <img alt='static-map' style={{ width: '308px', height: '130px' }} src={`https://api.mapbox.com/styles/v1/mapbox-conductive/ckgilz37t9ozw1aqgr64c3xav/static/pin-l-embassy+196AB8(${project.longitude},${project.latitude})/${project.longitude},${project.latitude},8/308x130?access_token=${config.mapbox.accessToken}`}></img>
                <div style={{ display: 'flex', marginTop: '6px', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Time Zone: {project.projectTimeZone}</span>
                    <span>Has Blackouts: {!!project.projectHasBlackOuts ? 'Yes' : 'No'}</span>
                  </div>
                  <div>
                    <Button onClick={() => openProject({ ...project })} color='orange'>OPEN</Button>
                  </div>
                </div>
              </Segment>
            </div>
          ))}
        </React.Fragment>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetMapState: () => dispatch(mapActions.resetMapState()),
  }
}

export default connect(null, mapDispatchToProps)(AdministrationProjectsRoute);