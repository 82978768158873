import React from 'react';
import { Menu, Header, Icon } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';

const TopMenu = ({ config, style = {}, refresh }) => (
  <div style={{ display: 'flex', marginBottom: '14px' }}>
    <Menu stackable style={{ ...style, flex: '1' }}>
      <Menu.Item>
        <AppContext.Consumer>{({ toggleSideMenu }) => (
          <Icon name='list' onClick={toggleSideMenu} style={{ cursor: 'pointer', fontSize: '23px' }} />
        )}</AppContext.Consumer>
      </Menu.Item>
      <Menu.Item>
        <Header><Icon name={config.header.iconName} />{config.header.title}</Header>
      </Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item
          active={false}
          onClick={refresh}
        >
          <Icon style={{ margin: 0 }} name='refresh' />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  </div>
)

export default TopMenu;