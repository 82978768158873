import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';

const AssignAllConfirmModal = ({ modalRef, showModal, closeModal, onSubmit }) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmit();
    }
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>Assign All Inventory</Modal.Header>
        <Modal.Content>
          <Icon name='warning' />Advanced Inventory Management is enabled, are you sure Assign All is your intended action?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>No</Button>
          <Button onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Yes' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default AssignAllConfirmModal;