import { Button, Icon, Modal } from 'semantic-ui-react';
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Scheduler as KendoScheduler, DayView, SchedulerItem } from '@progress/kendo-react-scheduler';
import { getAppointmentByWorkOrder } from '../../../core/services/appointment.service';
import * as schedulerActions from '../../../core/redux/actions/scheduler.actions';
import { isEnabled } from '../../../core/services/auth.service';
import AppContext from '../../../core/context/app.context';
import ROLES from '../../../constants/roles';
import { EditItemWithDynamicTitle } from '../../../shared/components/scheduler/custom-item';
import OverrideAppointmentDialog from '../../../shared/components/scheduler/override-appointment-dialog';
import NotificationGroup from '../../../shared/components/scheduler/notification-group';
import FormWithCustomEditor from '../../../shared/components/scheduler/custom-form';
import CUSTOM_MODEL_FIELDS from '../../../shared/components/scheduler/custom-model';
import Loader from '../loader';


const CustomItem = (props) => {
  const isSelectedWorkOrder = props.selectedWorkOrder.workOrderNumber === props.dataItem.workOrderNumber;
  return (
    <SchedulerItem
      {...props}
      editable={isSelectedWorkOrder}
      style={{
        ...props.style,
        backgroundColor: isSelectedWorkOrder ? '#4051B5' : '#A0A0A0',
        color: '#A0A0A0' ? 'white' : 'black'
      }}
      className={(isSelectedWorkOrder ? 'unAssigned' : ' ') + props.className}
    />
  );
};

const _AppointmentContent = React.memo(({
  selectedWorkOrder,
  schedulerData,
  updateWorkOrder,
  listAppointmentsByProject,
  deleteAppointment,
  updateDetailsRequired,
  createAppointment,
  updateAppointment,
  setNewAppointment,
  showAppointmentAlreadyExistModal,
  setLoading,
  setSchedulerData,
  setError
}) => {
  const context = useContext(AppContext);

  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [initDate, setInitDate] = useState(false);
  const [appointmentStartDateWarning, setAppointmentStartDateWarning] = useState(false);

  useEffect(() => {
    const { currentProject } = context;
    if (currentProject) {
      setLoading(true);
      //get current WO appointment date
      let currentAppointment = "";
      const getAppointmentData = async () => { 
        currentAppointment = await getAppointmentByWorkOrder(currentProject.id, selectedWorkOrder.id);
        if (currentAppointment) {
          try {
            const newStartDate = addMonths(new Date(currentAppointment.appointmentStartDate),-1).toISOString();
            const newEndDate = addMonths(new Date(currentAppointment.appointmentStartDate),2).toISOString();
            //call listAppointments using WO appointment date based range
            listAppointmentsByProject(currentProject.id,newStartDate,newEndDate);
          } catch {
            listAppointmentsByProject(currentProject.id);
          }
        } else {
          listAppointmentsByProject(currentProject.id);
        }
      }
      getAppointmentData();
    }
    return () => {
      setSchedulerData([]);
      setError(null);
    };
  }, []);

  useEffect(() => {
    setData(schedulerData);
    if (!initDate && schedulerData && schedulerData.length > 0) {
      const workOrderAppointment = schedulerData && schedulerData.find(item => item.workOrderId === selectedWorkOrder.id);
      const defaultDate = workOrderAppointment ? new Date(workOrderAppointment.appointmentStartDate) : new Date();
      setDate(defaultDate);
      setInitDate(true);
    }
  }, [schedulerData]);

  useEffect(() => {
    if (updateDetailsRequired && selectedWorkOrder && selectedWorkOrder.id && (typeof updateWorkOrder === 'function')) {
      updateWorkOrder(selectedWorkOrder.id);
    }
  }, [updateDetailsRequired]);

  const handleDataChange = async ({ created, updated, deleted }) => {
    const { currentProject } = context;
    if (created.length > 0 && !created[0].override) {
      createAppointment();
    }
    if (updated.length > 0) {
      const formData = updated[0];
      if (formData.workOrderNumber === selectedWorkOrder.workOrderNumber) {
        const updateInput = {
          ...formData,
          userIds: formData.userIds.map(a => a.id),
          timeZone: currentProject.projectTimeZone
        }
        delete updateInput['isAllDay'];
        delete updateInput['workOrderNumber'];
        if (updateInput.appointmentStartDate < new Date()) {
          setAppointmentStartDateWarning(true);
        }
        setNewAppointment(updateInput)
        updateAppointment();
      }
    }
    if (deleted.length > 0) {
      const { id: appointmentId, projectId, workOrderId } = deleted[0];
      deleteAppointment(appointmentId, projectId, workOrderId);
    }
  };

  function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
  } 

  const handleDateChange = ({ value }) => {
    const date = new Date(value - 0); //force event value to new date object
    setDate(date);
    const dateClicked = new Date(value - 0); //force event value to new date object
    const { currentProject } = context;
    const newStartDate = addMonths(dateClicked,-1).toISOString();
    const newEndDate = addMonths(dateClicked,2).toISOString();
    listAppointmentsByProject(currentProject.id,newStartDate,newEndDate);
  }

  const renderTabContent = () => {
    const { currentProject, user } = context;
    if (currentProject) {
      return (
        <>
          <KendoScheduler
            item={(props) => <CustomItem {...props} selectedWorkOrder={selectedWorkOrder} />}
            height={'100%'}
            date={date}
            onDateChange={handleDateChange}
            timezone={currentProject.projectTimeZone}
            data={data}
            editable={isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin])}
            editItem={EditItemWithDynamicTitle}
            modelFields={CUSTOM_MODEL_FIELDS}
            onDataChange={handleDataChange}
            form={(props) => <FormWithCustomEditor selectedWorkOrder={selectedWorkOrder} {...props} />}
          >
            <DayView workDayStart={"07:00"} workDayEnd={"19:00"} />
          </KendoScheduler>
          <NotificationGroup appointmentStartDateWarning={appointmentStartDateWarning} setAppointmentStartDateWarning={setAppointmentStartDateWarning} />
          {showAppointmentAlreadyExistModal && <OverrideAppointmentDialog />}
        </>
      )
    }
  }

  return renderTabContent();
});

const mapStateToProps = ({ scheduler }) => {
  return {
    schedulerData: scheduler.data,
    updateDetailsRequired: scheduler.updateDetailsRequired,
    showAppointmentAlreadyExistModal: scheduler.showAppointmentAlreadyExistModal,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listAppointmentsByProject: (projectId, startDate, endDate) => dispatch(schedulerActions.listAppointmentsByProject(projectId, startDate, endDate)),
    deleteAppointment: (appointmentId, projectId, workOrderId) => dispatch(schedulerActions.deleteAppointment(appointmentId, projectId, workOrderId)),
    createAppointment: () => dispatch(schedulerActions.createAppointment()),
    setNewAppointment: (newAppointment) => dispatch(schedulerActions.setNewAppointment(newAppointment)),
    updateAppointment: () => dispatch(schedulerActions.updateAppointment()),
    setLoading: (loading) => dispatch(schedulerActions.setLoading(loading)),
    setSchedulerData: (data) => dispatch(schedulerActions.setData(data)),
    setError: (error) => dispatch(schedulerActions.setError(error)),
  }
}

const AppointmentContent = connect(mapStateToProps, mapDispatchToProps)(_AppointmentContent);

const DetailsAppointmentModal = ({
  selectedWorkOrder,
  updateWorkOrder,
  onClose,
  showModal,
  loading
}) => {
  return (
    <>
      <Modal dimmer={{ style: {zIndex: 1002}}} size='small' open={showModal} onClose={onClose}>
        <Loader loading={loading} />
        <Modal.Header>
          <span>Manage Appointment</span>
        </Modal.Header>
        <Modal.Content image scrolling>
          <AppointmentContent selectedWorkOrder={selectedWorkOrder} updateWorkOrder={updateWorkOrder} />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={onClose}>Close <Icon name='chevron right' /></Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default connect(({ scheduler }) => {
  return {
    loading: scheduler.loading,
  }
}, null)(DetailsAppointmentModal);