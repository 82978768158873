// check if version 1 is greater then version 2
export const isNewerVersion = (oldVersion, newVersion) => {
  const oldParts = oldVersion.split('.');
  const newParts = newVersion.split('.');
  for (var i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i];
    const b = ~~oldParts[i];
    if (a > b) return true
    if (a < b) return false
  }
  return false
}