import React, { useState } from 'react';
import { isEnabled } from '../../../core/services/auth.service';
import { Comment, Header } from 'semantic-ui-react';
import VerifyModal from '../../../routes/quality-assurance/components/modal/verifyModal';
import ROLES from '../../../constants/roles';

const HistoryTabContent = ({ 
  workOrder, 
  user, 
  updateWorkOrder,
  verifyWorkOrder,
  qaDoubleEntryVerification,
  isQualityAssurance,
  workOrdersVerified,
  getWorkOrderDetails,
  selectedWorkOrder
 }) => {
  const [showVerifyModal, setShowVerifyModal] = useState(true);
  const updateOldMeterKwhReading = async (id, oldMeterKwhReading) => {
    await updateWorkOrder({ id, oldMeterKwhReading, workOrderReviewedUserId: user.id });
  }
  return (
    <Comment.Group>
      <Header as='h3' dividing>
        History
      </Header>

      {workOrder && workOrder.workOrderHistorys && workOrder.workOrderHistorys.items.length > 0 && (
        workOrder.workOrderHistorys.items.map((history, index) => (
          <Comment key={index}>
            <Comment.Content>
              <Comment.Author as='a'>{history.historyUser.userName}</Comment.Author>
              <Comment.Metadata>{history.historyDate}</Comment.Metadata>
              <Comment.Text>{history.historyContent}</Comment.Text>
            </Comment.Content>
          </Comment>
        ))
      )}
      {user && isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin, ROLES.ClientAdmin]) && (
          <>
          {showVerifyModal && qaDoubleEntryVerification && isQualityAssurance && selectedWorkOrder && selectedWorkOrder.id && workOrdersVerified && !workOrdersVerified.includes(selectedWorkOrder.id) && !selectedWorkOrder.workOrderFlaggedAsDamaged && !selectedWorkOrder.workOrderFlaggedAs3strike && <VerifyModal
          addComment
          escalate
          updateWorkOrderStatus
          userId={user.id}
          selectedWorkOrder={selectedWorkOrder}
          photoIndex={0}
          title={'Data Verification'}
          closeModal={setShowVerifyModal}
          showModal={showVerifyModal}
          inputValue={selectedWorkOrder.oldMeterKwhReading}
          workOrderId={selectedWorkOrder.id}
          getWorkOrderDetails={getWorkOrderDetails}
          update={updateOldMeterKwhReading}
          verifyWorkOrder={verifyWorkOrder}
          modalType='text' />}
          </>)}
    </Comment.Group>
  )
}

export default HistoryTabContent;