import client from '../apollo';
import { CREATE_COMMENT_MUTATION } from '../../graphql/mutations/comment';

export const addComment = async (userId, workOrderId, comment) => {
  try {
    await client.mutate({
      mutation: CREATE_COMMENT_MUTATION,
      variables: {
        userId,
        workOrderId,
        content: comment
      }
    });
  } catch (error) {
    console.log(error);
  }
}