import gql from 'graphql-tag';

export const CREATE_SNAPSHOT_MUTATION = gql`
  mutation createSnapshot($projectId: ID!) {
    createSnapshot(input: {
      projectId: $projectId
    }) {
      id
    }
  }`;

export const RESTORE_FROM_SNAPSHOT_MUTATION = gql`
  mutation restoreFromSnapshot($projectId: ID!) {
    restoreFromSnapshot(input: {
      projectId: $projectId
    }) {
      id
    }
  }`;