import React, { useState } from 'react';
import { Modal, Button, Input } from 'semantic-ui-react';

const NewFileColumnModal = ({ modalRef, showModal, closeModal, onSubmit }) => {
  const [newFileColumnName, setNewFileColumnName] = useState('');
  
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal && newFileColumnName) {
      event.preventDefault();
      onSubmit(newFileColumnName);
    }
  }

  const onFileColumnNameChange = (event, {value}) => {
    setNewFileColumnName(value);
  }

  const onSubmitButtonClick = () => {
    if (newFileColumnName) {
      onSubmit(newFileColumnName);
    }
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>New File Column</Modal.Header>
        <Modal.Content>
          <span style={{marginRight: 10}}>File Column:</span><Input type='text' onChange={onFileColumnNameChange} value={newFileColumnName} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>No</Button>
          <Button disabled={!newFileColumnName} onClick={onSubmitButtonClick} positive icon='checkmark' labelPosition='right' content='Yes' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default NewFileColumnModal;