import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const PrivacyPolicyModal = ({ showModal, closeModal }) => (
  <Modal dimmer='blurring' size='large' open={showModal}>
    <Modal.Header>Privacy Policy</Modal.Header>
    <Modal.Content>
      <div>
        <p>Last Updated 3/11/2020</p>
        <p>This privacy notice and policy applies to the use of the Peak Workflow™ web platform (the “Platform”) and the tools and features found here. The Platform is owned by Conductive Digital, LLC, who has licensed the right to use the Platform to business partners and customers (“Platform Licensees”) pursuant to its standard Master License Agreement and Sublicense Agreements (the “Platform License.”).&nbsp;The Platform License will govern in the event of any conflict between the Platform License and this policy.&nbsp;You may use the Platform only as an employee or independent contractor of an authorized Platform Licensee, and by using the Platform, you agree to all of these Terms of Use, applicable terms in the Platform License and to our Privacy Policy as explained below. In summary,</p>
        <ul>
          <li>We do not collect personally identifiable information about you unless you choose to provide that information to us.</li>
          <li>Any personal information you provide is protected by reasonable security measures.</li>
          <li>Non-personal information related to your use of the Platform may be automatically collected and stored.</li>
          <li>Unless you authorize us in advance, we do not disclose, give, sell, or transfer any personal information about you to any third party unless required by law.</li>
        </ul>
        <p>The Platform is not authorized for use by children. We do not disclose personal information to third parties for their direct marketing purposes. </p>
        <p><strong>PERSONAL INFORMATION VOLUNTARILY SUBMITTED</strong></p>
        <p>If you choose to provide us with personal information, we may use that information to respond to you and get you the help, information, feedback, or services you requested. Submitting personal information (name, address, telephone number, email address, etc.) is not required to access the Platform. We retain the information you voluntarily provide to us only for as long as necessary to respond to your question or request, in most cases no longer than three months, or in the case of ongoing services and resources, as long as needed to provide you with the services and/or resources you have requested. </p>
        <p><strong>INFORMATION AUTOMATICALLY COLLECTED AND STORED</strong></p>
        <p>When you use the Platform, we use analytics tools to gather and temporarily store a variety of information about your use. The basic information we collect during your visit includes:</p>
        <ul>
          <li>The name of the domain you use to access the Internet </li>
          <li>The location, date and time of your use of the Platform</li>
          <li>The Tools and features you have used on the Platform</li>
          <li>The type and version of your Web browser and operating system</li>
        </ul>
        <p>&nbsp;</p>
        <p>We may aggregate this data in order to improve the Platform and execute on the terms of the Platform License. The aggregate data is available only to our designated team members who require this information to perform their duties. It is retained only for as long as needed for proper analysis.&nbsp; </p>
        <p><strong>HOW WE MAY SHARE YOUR INFORMATION</strong></p>
        <p>We may share your personal and other information in the following ways:</p>
        <ul>
          <li>With your employer or contractor who has a Platform License to use the Platform</li>
          <li>With our team members who need access to such information to carry out work on our behalf</li>
          <li>If needed to protect rights, property and/or safety, or if needed to respond to the request of law enforcement in cases where we believe disclosure is required and/or in accordance with law</li>
          <li>As requested or directed by you</li>
        </ul>
        <p>&nbsp;</p>
        <p><strong>WEBSITE SECURITY</strong></p>
        <p>We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, and disclosure. For Platform security purposes, we use software to monitor traffic to identify unauthorized use. In the event of law enforcement investigations and as part of any required legal process, information from these sources may be used to help identify an individual.</p>
        <p><strong>CHANGES TO THIS POLICY</strong><br />
          We may update this policy from time to time. When we do, we revise the “Last Updated” date at the top of this page. We encourage you to check this page periodically for any changes. You acknowledge and agree that it is your responsibility to review this policy periodically and become aware of modifications.</p>
        <p><strong>CONTACTING US</strong></p>
        <p>If you have any questions about this policy, or your experience with the Platform, or want to notify us of anything, please contact us at: info@conductivedigital.com</p>
      </div>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={closeModal} primary content='Close' />
    </Modal.Actions>
  </Modal>
)

export default PrivacyPolicyModal;