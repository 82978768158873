import client from '../apollo';
import {
  GET_USER_BY_USERNAME,
  GET_USERS_QUERY,
  GET_USER_BY_ID,
  GET_TECHNICIANS_ASSIGNMENTS_PER_DAY_AND_PROJECT_ID_QUERY,
  LIST_TECHNICIANS_BY_INSTALL_DATE_AND_TENANT_QUERY,
  LIST_PROJECT_USER_BY_WORKORDER_STATUS_AND_COUNT,
  LIST_USERS_BY_PROJECT_AND_ROLE_QUERY, GET_USER_DETAILS_QUERY, ADMIN_LIST_USERS_BY_PROJECT_AND_ROLE_QUERY, LIST_WORKORDER_TECHNICIANS_BY_PROJECT_ID_QUERY, LIST_INVENTORY_TECHNICIANS_BY_PROJECT_ID_QUERY
} from '../../graphql/queries/user';

import {
  UPDATE_USER_MUTATION
} from '../../graphql/mutations/user';

export const listProjectUserByWorkOrderStatusAndCount = async (projectId) => {
  try {
    const { data } = await client.query({
      query: LIST_PROJECT_USER_BY_WORKORDER_STATUS_AND_COUNT,
      variables: { projectId },
      fetchPolicy: 'no-cache'
    });
    let users = data.listProjectUserByWorkOrderStatusAndCount.items || [];
    return users;
  } catch (error) {
    console.log(error);
  }
}

export const listWorkOrderTechniciansByProject = async (projectId) => {
  try {
    const { data } = await client.query({
      query: LIST_WORKORDER_TECHNICIANS_BY_PROJECT_ID_QUERY,
      variables: { projectId },
      fetchPolicy: 'no-cache',
    });
    return (data && data.listWorkOrderTechniciansByProject && data.listWorkOrderTechniciansByProject.items) || [];
  } catch (error) {
    console.log(error);
  }
}

export const listInventoryTechniciansByProject = async (projectId) => {
  try {
    const { data } = await client.query({
      query: LIST_INVENTORY_TECHNICIANS_BY_PROJECT_ID_QUERY,
      variables: { projectId },
      fetchPolicy: 'no-cache',
    });
    return (data && data.listInventoryTechniciansByProject && data.listInventoryTechniciansByProject.items) || [];
  } catch (error) {
    console.log(error);
  }
}

export const getTechniciansAssignmentsPerDayAndByProjectId = async (projectId) => {
  try {
    const { data } = await client.query({
      query: GET_TECHNICIANS_ASSIGNMENTS_PER_DAY_AND_PROJECT_ID_QUERY,
      variables: { projectId },
      fetchPolicy: 'no-cache'
    });
    let users = data.listTechnicianAssignmentsCountByDay.items || [];
    const assignmentsDay0 = data.listTechnicianAssignmentsCountByDay.assignmentsDay0 || [];
    const assignmentsDay1 = data.listTechnicianAssignmentsCountByDay.assignmentsDay1 || [];
    const assignmentsDay2 = data.listTechnicianAssignmentsCountByDay.assignmentsDay2 || [];
    const assignmentsDay3 = data.listTechnicianAssignmentsCountByDay.assignmentsDay3 || [];
    const assignmentsDay4 = data.listTechnicianAssignmentsCountByDay.assignmentsDay4 || [];
    const assignmentsDay5 = data.listTechnicianAssignmentsCountByDay.assignmentsDay5 || [];
    const assignmentsDay6 = data.listTechnicianAssignmentsCountByDay.assignmentsDay6 || [];
    if (users.length > 0) {
      users = users.map((user, index) => {
        return {
          ...user,
          assignmentsDay0: assignmentsDay0[index],
          assignmentsDay1: assignmentsDay1[index],
          assignmentsDay2: assignmentsDay2[index],
          assignmentsDay3: assignmentsDay3[index],
          assignmentsDay4: assignmentsDay4[index],
          assignmentsDay5: assignmentsDay5[index],
          assignmentsDay6: assignmentsDay6[index]
        };
      });
    }
    return users;
  } catch (error) {
    console.log(error);
  }
}

export const getUserByUsername = async (userName) => {
  try {
    const { data } = await client.query({
      query: GET_USER_BY_USERNAME,
      variables: { userName },
      fetchPolicy: 'no-cache'
    });
    if (data){
      const user = data.getUserByUserName;
      if (user && user.userRoles.items) {
        user.userRoles = user.userRoles.items.map(({ role }) => role);
      }
      return user;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
}

export const getUsers = async (projectId) => {
  try {
    const { data } = await client.query({
      query: GET_USERS_QUERY,
      variables: { projectId },
    });
    return data.listUsersByProject.items || [];
  } catch (error) {
    console.log(error);
  }
}

export const listUsersByProjectAndRole = async (tenant) => {
  try {
    const { data } = await client.query({
      query: LIST_USERS_BY_PROJECT_AND_ROLE_QUERY,
      fetchPolicy: 'no-cache',
      variables: { tenant }
    });
    if (data.listUsersByProjectAndRole && data.listUsersByProjectAndRole.items && data.listUsersByProjectAndRole.items.length > 0) {
      return data.listUsersByProjectAndRole.items;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
}

export const adminListUsersByProjectAndRole = async (tenant) => {
  try {
    const { data } = await client.query({
      query: ADMIN_LIST_USERS_BY_PROJECT_AND_ROLE_QUERY,
      fetchPolicy: 'no-cache',
      variables: { tenant }
    });
    return data && data.adminListUsersByProjectAndRole ? data.adminListUsersByProjectAndRole.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const getUserDetails = async (id) => {
  try {
    const { data } = await client.query({
      query: GET_USER_DETAILS_QUERY,
      fetchPolicy: 'no-cache',
      variables: { id }
    });
    return data && data.getUser ? data.getUser : null;
  } catch (error) {
    console.log(error);
  }
}

export const getUserById = async (id) => {
  try {
    const { data } = await client.query({
      query: GET_USER_BY_ID,
      variables: { id }
    });
    return data && data.getUser ? data.getUser : null;
  } catch (error) {
    console.log(error);
  }
}

export const updateUser = async (userId, reportingPreferences) => {
  try {
    await client.mutate({
      mutation: UPDATE_USER_MUTATION,
      variables: {
        userId,
        reportingPreferences
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export const listTechniciansByInstallDateAndTenant = async (tenantId) => {
  try {
    const fromDate = formatDate(new Date());
    const toDate = formatDate(new Date(new Date().setDate(new Date().getDate() + 1)));
    const { data } = await client.query({
      query: LIST_TECHNICIANS_BY_INSTALL_DATE_AND_TENANT_QUERY,
      fetchPolicy: 'no-cache',
      variables: { tenantId, fromDate, toDate }
    });
    return data && data.listTechniciansByInstallDateAndTenant ? data.listTechniciansByInstallDateAndTenant.items : [];
  } catch (error) {
    console.log(error);
  }
}

const formatDate = (date) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', })
  const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
  return `${year}-${month}-${day}`;
}