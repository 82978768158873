import {
    SET_CURRENT_COLUMNS,
    SET_MASTER_COLUMNS
} from '../types/ganttState.types';

const projectField = {
    field: "text",
    title: "Project",
    width: 220,
    show: true,
    expandable: true
}

const columnInitialState = [
    {
        field: "startDate",
        title: "Start",
        width: 110,
        format: "{0:MM/dd/yy}",
        show: true,
    },
    {
        field: "endDate",
        title: "End",
        width: 110,
        format: "{0:MM/dd/yy}",
        show: true,
    },
    {
        field: "percentComplete",
        title: "%\nComplete",
        width: 100,
        format: "{0:0.0}%",
        show: true,
    },
    {
        field: "projectStatus",
        title: "Status",
        width: 110,
        show: true,
    },
    {
        field: "workOrders",
        title: "WOs",
        width: 110,
        show: true,
    },
];

const initialState = {
    // Used for dropdown.
    current: JSON.parse(JSON.stringify(columnInitialState)),
    master: JSON.parse(JSON.stringify(columnInitialState)),
    // Final columns for Gantt, must have project field
    columns: [ projectField, ...JSON.parse(JSON.stringify(columnInitialState)) ]
}

const ganttStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_COLUMNS:
            return { ...state, current: action.payload, columns: [ projectField, ...action.payload ] };
        case SET_MASTER_COLUMNS:
            return { ...state, master: action.payload };
        default:
            return state;
    }
}

export default ganttStateReducer;