import gql from 'graphql-tag';

export const LIST_INVENTORY_ASSET_LOCATION = gql`
  query ListInventorysByAssetWorkOrderType($projectId: ID!) {
    listInventoryAssetLocation(projectId: $projectId) {
      items
    }
  }
`;


export const LIST_INVENTORIES_BY_ASSET_WORKORDER_TYPE_QUERY = gql`
  query ListInventorysByAssetWorkOrderType($projectId: ID!, $limit:Int, $offset: Int, $search: String, $filter: [String], $assetWorkOrderType: WorkOrderType!) {
    listInventorysByAssetWorkOrderType(project: $projectId, limit: $limit, offset: $offset, assetWorkOrderType: $assetWorkOrderType, search: $search, filter: $filter) {
      resultCount
      items {
        id
        assetWorkOrderType
        assetNumber
        assetType
        manufacturer
        inventoryAssignments
        customer
        shippedTo
        shippedToState
        shippedDate
        mfgSerialNumber
        amrSerialNumber
        palletNumber
        orderNumber
        lineNumber
        integratedSerialNumber
        cononicalAmrSerialNumber
        kH
        numDials
        form
        base
        class
        assetReceivedUserId
        assetReceivedDate
        assetLocation
        assetDestination
        assetDeployed
        assetDeployedDate
        assetTransferred
        assetTransferredDate
        assetIsInQuarantine
        assetIsDeleted
        assetDeletedDate
        voltage
        assetInTransit
        assetApproved
        workOrderNumber
      }
    }
  }
`;

export const GET_INVENTORY_STATUS_BY_METER_TYPE_QUERY = gql`
  query GetInventoryStatusByMeterType($projectId: ID!, $assetLocation: String) {
    getInventoryStatusByMeterType(project: $projectId, assetLocation: $assetLocation) {
      items {
        size
        totalWoPerSize
        remainingWoPerSize
        totalAssetsAvailable
        remainingAssetsAvailable
        assetsNeededToShip
      }
    }
  }
`;

export const GET_INVENTORY_STATUS_BY_METER_TYPE_WITH_DELETED_QUERY = gql`
  query GetInventoryStatusByMeterTypeWithDeleted($projectId: ID!, $assetLocation: String) {
    getInventoryStatusByMeterTypeWithDeleted(project: $projectId, assetLocation: $assetLocation) {
      items {
        size
        totalWoPerSize
        remainingWoPerSize
        totalAssetsAvailable
        remainingAssetsAvailable
        assetsNeededToShip
        assetsDeleted
        assetsTransferred
        stockAvailable
      }
    }
  }
`;

export const GET_INVENTORY_STATUS_BY_REGISTER_TYPE_QUERY = gql`
  query GetInventoryStatusByRegisterType($projectId: ID!, $assetLocation: String) {
    getInventoryStatusByRegisterType(project: $projectId, assetLocation: $assetLocation) {
      items {
        size
        partNumber
        totalAssetsAvailable
        remainingAssetsAvailable
        assetsNeededToShip
      }
    }
  }
`;

export const GET_INVENTORY_STATUS_BY_RADIO_TYPE_QUERY = gql`
  query GetInventoryStatusByRadioType($projectId: ID!, $assetLocation: String) {
    getInventoryStatusByRadioType(project: $projectId, assetLocation: $assetLocation) {
      totalAssetsAvailable
      remainingAssetsAvailable
      assetsNeededToShip
    }
  }
`;

export const GET_INVENTORY_STATUS_BY_RADIO_TYPE_WITH_DUAL_QUERY = gql`
  query GetInventoryStatusByRadioTypeWithDual($projectId: ID!, $assetLocation: String) {
    getInventoryStatusByRadioTypeWithDual(project: $projectId, assetLocation: $assetLocation) {
      singlePortCount
      singlePortRemaining
      singlePortAssetsDeleted
      singlePortAssetsTransferred
      singlePortAssetsDeletedTransferred
      singlePortStockAvailable
      dualPortCount
      dualPortRemaining
      dualPortAssetsDeleted
      dualPortAssetsTransferred
      dualPortAssetsDeletedTransferred
      dualPortStockAvailable      
      assetsNeededToShip
    }
  }
`;

export const GET_INVENTORY_FILTER_CRITERIA_QUERY = gql`
  query ListInventoryFilterCriteria($projectId: ID!) {
    listInventoryFilterCriteria(project: $projectId) {
      assetDeployedFilterItems
      assetTypeFilterItems
      partNumberFilterItems
      formFilterItems
      classFilterItems
      userNameFilterItems
      assetIsDeletedFilterItems
      voltageFilterItems
      assetLocationFilterItems
    }
  }
`;

export const GET_INVENTORY_FILTER_CRITERIA_ADVANCED_MANAGEMENT_QUERY = gql`
  query ListInventoryFilterCriteria($projectId: ID!) {
    listInventoryFilterCriteria(project: $projectId) {
      assetDeployedFilterItems
      assetTypeFilterItems
      partNumberFilterItems
      formFilterItems
      classFilterItems
      assetIsInQuarantineFilterItems
      assetTransferredFilterItems
      assetIsDeletedFilterItems
      userNameFilterItems
      voltageFilterItems
      assetLocationFilterItems
    }
  }
`;

export const GET_INVENTORY_FILTER_CRITERIA_MULTIPLE_WAREHOUSES_QUERY = gql`
  query ListInventoryFilterCriteria($projectId: ID!) {
    listInventoryFilterCriteria(project: $projectId) {
      assetDeployedFilterItems
      assetTypeFilterItems
      partNumberFilterItems
      formFilterItems
      classFilterItems
      assetIsInQuarantineFilterItems
      assetTransferredFilterItems
      assetIsDeletedFilterItems
      userNameFilterItems
      voltageFilterItems
      assetLocationFilterItems
      assetInTransitFilterItems
      assetApprovedFilterItems
    }
  }
`;

export const LIST_INVENTORY_NON_SERIALIZED_ASSETS_QUERY = gql`
  query ListInventoryNonSerializedAssets($projectId: ID!) {
    listInventoryNonSerializedAssets(project: $projectId) {
      items {
        id
        assetName
        assetType
        assetQuantity
        assetTotalQuantity
        assetKey
        projectId
      }
    }
  }
`;