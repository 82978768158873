import React, { Component } from 'react';
import TopMenu from '../../../shared/components/top-menu';
import { Button, Input } from 'semantic-ui-react';
import { adminListUsersByProjectAndRole, getUserDetails, listUsersByProjectAndRole } from '../../../core/services/user.service';
import { getAllRoles } from '../../../core/services/role.service';
import { listQualificationByUser, listQualificationsByTenant } from '../../../core/services/qualification.service';
import { listProjectsByTenant } from '../../../core/services/project.service';
import UserListView from './components/list-view';
import CreateUserModal from './components/modal/createUserModal';
import config from '../../../config';
import { getToken, hasSystemAdminRole } from '../../../core/services/auth.service';
import AppContext from '../../../core/context/app.context';
import { orderBy } from '@progress/kendo-data-query';
import ROLES from '../../../constants/roles';
import UserDetailsModal from './components/modal/userDetailsModal';
import * as photoService from '../../../core/services/photo.service';
import TenantRouteWrapper from '../../../hoc/tenantRouteWrapper';

class UserManagementRoute extends Component {
  _isMounted = false;
  static contextType = AppContext;
  createUserModalRef = React.createRef();
  userDetailsModalRef = React.createRef();
  fileInputRef = React.createRef();

  state = {
    users: [],
    tenants: [],
    roles: [],
    projects: [],
    showCreateUserModal: false,
    selectedTenant: null,
    selectedRoles: [],
    selectedProjects: [],
    selectedQualifications: [],
    qualifications: [],
    error: null,
    username: null,
    email: null,
    fullName: null,
    loading: false,
    sort: [],
    searchBy: '',
    sendingInvitation: false,
    selectedUser: null,
    showUserDetailsModal: false,
    isActive: null,
    phone: null,
    invitationSentMessage: false,
    avatar: null,
    avatarUrl: null
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    let { initUser, user, currentTenant } = this.context;
    if (!user) {
      user = await initUser();
    }
    const users = await this.getUsers();
    const roles = await this.getRoles();

    let projects = [];
    let qualifications = [];

    if (currentTenant) {
      // if (user.userRoles.indexOf('SystemAdmin') > -1) {
      //   projects = await getAllProjects();
      // } else {
        projects = await listProjectsByTenant(currentTenant.id);
      // }

      qualifications = await listQualificationsByTenant(currentTenant.id);
    }

    this.setState({
      users,
      roles,
      projects,
      qualifications,
      loading: false
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRoles = async () => {
    const { user } = this.context;
    if (user) {
      const roles = await getAllRoles();
      // if user does not have SystemAdmin role then remove it from list of roles
      if (!hasSystemAdminRole(user.userRoles)) {
        const systemAdminRoleIndex = roles.findIndex(({ role }) => role === ROLES.SystemAdmin);
        roles.splice(systemAdminRoleIndex, 1);
      }
      return roles;
    }
    return [];
  }

  getUsers = async () => {
    const { currentTenant, user: currentUser } = this.context;
    if (currentTenant) {
      let users = [];
      if (currentUser.userRoles.indexOf(ROLES.SystemAdmin) > -1) {
        users = await adminListUsersByProjectAndRole(currentTenant.id);
      } else {
        users = await listUsersByProjectAndRole(currentTenant.id);
      }
      if (users && users.length > 0) {
        return users;
      }
    }
    return [];
  }

  renderTopMenu = () => {
    const { currentTenant } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentTenant && currentTenant.tenantName ? `${currentTenant.tenantName} - User Management` : 'User Management'}`,
        iconName: 'user'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  sendInvitation = async () => {
    this.setState({ error: null, sendingInvitation: true, invitationSentMessage: false });
    const { selectedUser } = this.state;
    const token = await getToken();
    fetch(config.adminCreateUserResendInviteApi, {
      method: 'POST',
      headers: {
        'Authorization': token
      },
      body: JSON.stringify({ username: selectedUser.userName })
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorMessage = await response.json();
          throw errorMessage;
        }
        this.setState({ sendingInvitation: false, invitationSentMessage: true });
      })
      .catch(error => {
        this.setState({ error: { message: 'Resend is not possible. User already activated' }, sendingInvitation: false });
      });
  }

  renderUserManagementRouteContent = () => {
    const { currentTenant } = this.context;
    const {
      users,
      showCreateUserModal,
      roles,
      projects,
      qualifications,
      error,
      loading,
      sort,
      sendingInvitation,
      showUserDetailsModal,
      selectedUser,
      selectedRoles,
      selectedProjects,
      selectedQualifications,
      isActive,
      fullName,
      email,
      phone,
      invitationSentMessage,
      avatar,
      avatarUrl
    } = this.state;
    return (
      <div className='workorders-listview'>
        <div className='workorders-listview-tool-bar'>
          <Button onClick={this.toggleUserModal}>Create Invite</Button>
          <Input placeholder='Search...' icon='search' fluid style={{ flex: 1 }} onChange={this.handleSearch} />
        </div>
        <CreateUserModal
          currentTenant={currentTenant}
          createUserModalRef={this.createUserModalRef}
          showModal={showCreateUserModal}
          closeModal={this.toggleUserModal}
          title='Create User'
          error={error}
          iconName='user'
          onModalConfirm={this.createUser}
          onRoleChange={this.onRoleChange}
          onProjectChange={this.onProjectChange}
          onQualificationChange={this.onQualificationChange}
          selectedRoles={selectedRoles}
          onUsernameChange={this.onUsernameChange}
          onEmailChange={this.onEmailChange}
          onPhoneChange={this.onPhoneChange}
          onFullNameChange={this.onFullNameChange}
          roles={roles}
          projects={projects}
          qualifications={qualifications}
          loading={loading}
          avatar={avatar}
          onAvatarChange={this.onAvatarChange}
          removeAvatar={this.removeAvatar}
          fileInputRef={this.fileInputRef} />
        <UserDetailsModal
          userDetailsModalRef={this.userDetailsModalRef}
          invitationSentMessage={invitationSentMessage}
          sendingInvitation={sendingInvitation}
          sendInvitation={this.sendInvitation}
          email={email}
          phone={phone}
          onEmailChange={this.onEmailChange}
          onPhoneChange={this.onPhoneChange}
          fullName={fullName}
          loading={loading}
          error={error}
          isActive={isActive}
          onIsActiveChange={this.onIsActiveChange}
          onRoleChange={this.onRoleChange}
          onProjectChange={this.onProjectChange}
          onQualificationChange={this.onQualificationChange}
          selectedProjects={selectedProjects}
          selectedQualifications={selectedQualifications}
          selectedRoles={selectedRoles}
          onModalConfirm={this.updateUser}
          roles={roles}
          projects={projects}
          qualifications={qualifications}
          selectedUser={selectedUser}
          showUserDetailsModal={showUserDetailsModal}
          onFullNameChange={this.onFullNameChange}
          closeModal={() => this.setShowUserDetailsModal(false)}
          fileInputRef={this.fileInputRef}
          onAvatarChange={this.onAvatarChange}
          avatar={avatar}
          removeAvatar={this.removeAvatar}
          avatarUrl={avatarUrl} />
        <UserListView
          onRowClick={this.openUserDetails}
          onSortChange={this.onSortChange}
          users={this.filterUsers(orderBy(users, sort))}
          loading={loading}
          sort={sort} />
      </div>
    );
  }

  onUsernameChange = (event, data) => {
    this.setState({ username: data.value });
  }

  onEmailChange = (event, data) => {
    this.setState({ email: data.value });
  }

  onFullNameChange = (event, data) => {
    this.setState({ fullName: data.value });
  }

  onPhoneChange = (event, data) => {
    this.setState({ phone: data.value });
  }

  onProjectChange = (event, data) => {
    this.setState({ selectedProjects: data.value });
  }

  onQualificationChange = (event, data) => {
    this.setState({ selectedQualifications: data.value });
  }

  onRoleChange = (event, data) => {
    let hasTechnicianRole = false;
    for (let role of data.value) {
      if (role.includes(ROLES.Technician)) {
        hasTechnicianRole = true;
        break;
      }
    }
    const newState = { selectedRoles: data.value };
    if (!hasTechnicianRole) {
      newState.selectedQualifications = [];
    }
    this.setState(newState);
  }

  onIsActiveChange = (event, data) => {
    this.setState({ isActive: data.checked });
  }

  onAvatarChange = (event) => {
    this.setState({ error: null })
    const avatar = event.target.files[0];
    let img = new Image()
    img.src = window.URL.createObjectURL(avatar);
    img.onload = () => {
      if (img.width === 432 && img.height === 273) {
        this.setState({ avatar });
        this.fileInputRef.current.value = '';
      } else {
        this.setState({ error: { message: 'Error. Invalid dimensions. (Valid avatar dimensions 432x273)' } })
      }
    }
  }

  removeAvatar = () => {
    this.setState({ avatar: null });
  }

  toggleUserModal = () => {
    this.setState({
      showCreateUserModal: !this.state.showCreateUserModal,
      error: false,
      selectedRoles: [],
      selectedProjects: [],
      selectedQualifications: [],
      username: null,
      email: null,
      fullName: null,
      phone: null,
      avatar: null,
      avatarUrl: null
    });
    this.createUserModalRef.current.focus();
  }

  getUserAvatar = async (avatar) => {
    if (avatar) {
      this.setState({ avatarLoading: true })
      const avatarUrl = await photoService.getUserAvatar(avatar);
      this.setState({ avatarUrl, avatarLoading: false });
    }
  }

  openUserDetails = async (event) => {
    const { roles, projects } = this.state;
    const { id } = event.dataItem;
    const selectedUser = await getUserDetails(id);
    const { isActive, fullName, email, phone } = selectedUser;

    let hasTechnicianRole = false;

    const selectedRoles = selectedUser.userRoles.items.map(({ role: userRole }) => {
      const { id, role: name } = roles.find(({ role }) => userRole === role);
      if (name === 'Technician') {
        hasTechnicianRole = true;
      }
      return JSON.stringify({ id, name });
    });

    const selectedProjects = selectedUser.userProjects.items.map(({ projectName: userProject }) => {
      const foundProject = projects.find(({ projectName }) => userProject === projectName);
      return foundProject ? foundProject.id : null;
    });

    let selectedQualifications = [];

    if (hasTechnicianRole) {
      selectedQualifications = await listQualificationByUser(id);
      selectedQualifications = selectedQualifications.map(({ id }) => id);
    }

    this.getUserAvatar(selectedUser.avatar);

    this.setState({
      selectedUser,
      selectedRoles,
      selectedProjects,
      selectedQualifications,
      isActive,
      fullName,
      email,
      phone,
      showUserDetailsModal: true,
      invitationSentMessage: false,
      error: null
    });
    this.userDetailsModalRef.current.focus();
  }

  setShowUserDetailsModal = (showUserDetailsModal) => {
    let newState = { showUserDetailsModal };
    if (showUserDetailsModal) {
      console.log(this.state.selectedUser.avatar)
    } else {
      newState = {
        ...newState,
        selectedUser: null,
        fullName: null,
        selectedRoles: [],
        selectedProjects: [],
        isActive: null,
        email: null,
        phone: null,
        avatar: null,
        avatarUrl: null
      };
    }
    this.setState(newState);
  }

  encodeAvatar = (avatar) => {
    return new Promise((resolve, reject) => {
      if (avatar) {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result.split(',').pop());
        reader.readAsDataURL(avatar);
      } else {
        resolve(null);
      }
    })
  }

  updateUser = async () => {
    this.setState({ error: null, loading: true });
    let errorMessage = '';
    const {
      selectedUser,
      fullName,
      selectedRoles,
      selectedProjects,
      selectedQualifications,
      isActive,
      email,
      phone,
      avatar
    } = this.state;
    if (selectedProjects.length === 0 ||
      selectedRoles.length === 0 ||
      !fullName) {
      errorMessage = 'All fields are required.';
    }
    if (errorMessage.length > 0) {
      this.setState({ error: { message: errorMessage }, loading: false });
    } else {
      const token = await getToken();
      const encodedAvatar = await this.encodeAvatar(avatar);
      fetch(config.adminUpdateUserApi, {
        method: 'POST',
        headers: {
          'Authorization': token
        },
        body: JSON.stringify({
          fullName,
          roles: selectedRoles.map(role => JSON.parse(role)),
          projects: selectedProjects,
          username: selectedUser.userName,
          userId: selectedUser.id,
          qualifications: selectedQualifications,
          email,
          phone,
          isActive,
          avatar: encodedAvatar
        })
      }).then(async (response) => {
        if (!response.ok) {
          const errorMessage = await response.json();
          throw errorMessage;
        }
        this.setShowUserDetailsModal(false);
        const users = await this.getUsers();
        this.setState({ users, loading: false, avatar: null });
      }).catch(error => {
        this.setState({ error: { message: error }, loading: false });
      });
    }
  }

  createUser = async () => {
    this.setState({ error: null, loading: true });
    let errorMessage = '';
    const { user, currentTenant } = this.context;
    let {
      selectedProjects,
      selectedRoles,
      username,
      fullName,
      email,
      phone,
      avatar,
      selectedQualifications
    } = this.state;
    if (selectedProjects.length === 0 ||
      selectedRoles.length === 0 ||
      !username ||
      !fullName ||
      !email) {
      errorMessage = 'All fields are required.';
    }
    if (errorMessage.length > 0) {
      this.setState({ error: { message: errorMessage }, loading: false });
    } else {
      const token = await getToken();
      const encodedAvatar = await this.encodeAvatar(avatar);
      username = username.replace(/ /g, '');
      fetch(config.adminCreateUserApi, {
        method: 'POST',
        headers: {
          'Authorization': token
        },
        body: JSON.stringify({
          tenant: currentTenant.id,
          username,
          fullName,
          email,
          phone,
          roles: selectedRoles.map(role => JSON.parse(role)),
          projects: selectedProjects,
          qualifications: selectedQualifications,
          userId: user.id,
          avatar: encodedAvatar
        })
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorMessage = await response.json();
            throw errorMessage;
          }
          this.toggleUserModal();
          const users = await this.getUsers();
          this.setState({ users, loading: false });
        })
        .catch(error => {
          this.setState({ error: { message: error }, loading: false });
        });
    }
  }

  onSortChange = event => {
    this.setState({ sort: event.sort });
  };

  handleSearch = (event, data) => {
    const searchBy = data.value.toLowerCase();
    this.setState({ searchBy });
  }

  filterUsers = users => {
    const { searchBy } = this.state;
    return users
      .filter(user => Object.values(user).join(' ').toLowerCase().indexOf(searchBy) > -1);
  }

  render() {
    return (
      <TenantRouteWrapper>
        {this.renderTopMenu()}
        {this.renderUserManagementRouteContent()}
      </TenantRouteWrapper>
    )
  }

}

export default UserManagementRoute;