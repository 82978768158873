import React, { useContext } from 'react';
import AppContext from '../../../core/context/app.context';
import { Redirect } from 'react-router-dom';

const AuthComponent = (props) => {
  const context = useContext(AppContext);

  const allowAccess = () => {
    const { user } = context;
    if (user) {
      for (const routeRole of props.routeRoles) {
        if (user.userRoles.indexOf(routeRole) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <>
      {allowAccess() ? <>{props.children}</> : <Redirect to={props.redirectTo} />}
    </>
  )
}

export default AuthComponent;