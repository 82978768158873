import React, { useEffect, useState } from 'react';
import ProjectForm from './components/projectForm';
import TopMenu from '../../../shared/components/top-menu';
import TenantRouteWrapper from '../../../hoc/tenantRouteWrapper';
import { Redirect } from 'react-router-dom';
import routeMaps from '../../../core/route-maps/route-maps';
import { getProjectByProjectId } from '../../../core/services/project.service';

const AdministrationUpdateProjectRoute = ({ location }) => {
  const [project, setProject] = useState(location.state.project);
  
  useEffect(() => {
    if (location && !location.state) {
      return <Redirect to={routeMaps.administrationProjects} />
    }
    const getProject = async () => {
      const fullProject = await getProjectByProjectId(location.state.project.id);
      setProject(fullProject);
    }
    getProject();
  }, []);

  const renderTopMenu = () => {
    const topMenuConfig = {
      header: {
        title: `${project && project.projectName ? `${project.projectName} - Update Project` : 'Update Project'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  return (
    <TenantRouteWrapper>
      {renderTopMenu()}
      <ProjectForm selectedProject={project} />
    </TenantRouteWrapper>
  )
}

export default AdministrationUpdateProjectRoute;