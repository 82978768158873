import React from 'react';
import { Button, Icon, Modal, Input, Checkbox, Dropdown } from 'semantic-ui-react';
import CustomErrorMessage from '../../../../shared/components/messages/error';
import WORKORDER_TYPES, { WORKORDER_TYPES_LABEL } from '../../../../constants/workOrderTypes';

const AddProjectModal = ({
  showModal,
  closeModal,
  error,
  onModalConfirm,
  loading,
  addProjectModalRef,
  onIsDemoChange,
  onIsActiveChange,
  onProjectNameChange,
  onBucketNameChange,
  onShortCodeChange,
  onTimeZoneChange,
  onLongitudeChange,
  onLatitudeChange,
  onHasBlackOutsChange,
  newProject,
  onWorkOrderTypeChange
}) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onModalConfirm();
    }
  }

  return (
    <div tabIndex={0} onKeyPress={handleKeyPress} ref={addProjectModalRef}>
      <Modal size='tiny' onClose={closeModal} open={showModal}>
        <Modal.Header><Icon name='flag' /> {newProject.id ? 'Update Project' : 'Add Project'}</Modal.Header>
        <Modal.Content>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingRight: '15px' }}>
              <div>
                Name: <Input type='text' className='add-project-input' placeholder='Project Name' value={newProject.projectName} style={{ marginLeft: '10px', marginTop: '10px' }} onChange={onProjectNameChange} />
              </div>
              <div>
                Bucket: <Input className='add-project-input' disabled={!!newProject.id} type='text' placeholder='pw-clientcode-projectcode' value={newProject.projectBucket} style={{ marginLeft: '10px', marginTop: '10px' }} onChange={onBucketNameChange} />
                <div>
                  <i>Bucket name must be unique and must not contain spaces or uppercase letters.</i>
                </div>
              </div>
              <div>
                Short Code: <Input type='text' className='add-project-input' placeholder='projectcode (All Caps)' value={newProject.projectShortCode} style={{ marginLeft: '10px', marginTop: '10px' }} onChange={onShortCodeChange} />
              </div>
              <div>
                Time Zone: <Dropdown
                  defaultValue={newProject.projectTimeZone}
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  placeholder='Time Zone'
                  selection
                  options={[
                    { key: 0, value: 'America/Chicago', text: 'America/Chicago' },
                    { key: 1, value: 'America/Los_Angeles', text: 'America/Los_Angeles' },
                    { key: 2, value: 'America/New_York', text: 'America/New_York' },
                    { key: 3, value: 'America/Denver', text: 'America/Denver' },
                    { key: 4, value: 'Pacific/Honolulu', text: 'Pacific/Honolulu' }
                  ]}
                  onChange={onTimeZoneChange} />
              </div>
              <div>
                Work Order Type: <Dropdown
                  defaultValue={newProject.projectWorkOrderType}
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  placeholder='Work Order Type'
                  selection
                  options={[
                    { key: 0, value: WORKORDER_TYPES.MeterDeploy, text: WORKORDER_TYPES_LABEL.MeterDeploy },
                    { key: 1, value: WORKORDER_TYPES.WaterDeploy, text: WORKORDER_TYPES_LABEL.WaterDeploy },
                    { key: 2, value: WORKORDER_TYPES.WaterMeterDeploy, text: WORKORDER_TYPES_LABEL.WaterMeterDeploy },
                    { key: 3, value: WORKORDER_TYPES.WaterSiteSurvey, text: WORKORDER_TYPES_LABEL.WaterSiteSurvey }
                  ]}
                  onChange={onWorkOrderTypeChange} />
              </div>
              <div>
                Latitude: <Input type='number' className='add-project-input' placeholder='30.3077422' value={newProject.latitude} style={{ marginLeft: '10px', marginTop: '10px' }} onChange={onLatitudeChange} />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                Longitude: <Input type='number' className='add-project-input' placeholder='-97.8236121' value={newProject.longitude} style={{ marginLeft: '10px', marginTop: '10px' }} onChange={onLongitudeChange} />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                <Checkbox
                  checked={newProject.projectHasBlackOuts}
                  label='Has Blackouts'
                  onChange={onHasBlackOutsChange}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                <Checkbox
                  checked={newProject.isActive}
                  label='Is Active'
                  onChange={onIsActiveChange}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: '10px' }}>
                <Checkbox
                  checked={newProject.isDemo}
                  label='Is Demo'
                  onChange={onIsDemoChange}
                  toggle
                />
              </div>
              {error && <CustomErrorMessage message={error.message} />}
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          {!loading && <Button onClick={closeModal} negative>Cancel</Button>}
          <Button loading={loading} onClick={onModalConfirm} positive icon='checkmark' labelPosition='right' content={newProject.id ? 'Update' : 'Add'} />
        </Modal.Actions>
      </Modal>
    </div>
  )
}
export default AddProjectModal;