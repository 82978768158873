import React from 'react';
import { Button, Icon, Modal, Checkbox } from 'semantic-ui-react';
import CustomErrorMessage from '../../../../shared/components/messages/error';

const WorkOrdersAssignmentsModal = ({
  iconName,
  title,
  showModal,
  closeModal,
  error,
  users,
  onModalConfirm,
  assigning,
  addUserToAssignedList,
  addUserToUnAssignedList,
  assignUsersList,
  unassingUsersList
}) => {

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      onModalConfirm();
    }
  }

  return (
    <div onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' onClose={closeModal} open={showModal}>
        <Modal.Header><Icon name={iconName} /> {title}</Modal.Header>
        <Modal.Content>
          {error ? <CustomErrorMessage message='Error. Unable to fetch users' /> : (
            <table style={{ width: '100%' }} className='assignments-table'>
              <tbody>
                <tr>
                  <th>Assigned</th>
                  <th>Unassigned</th>
                  <th>Technician</th>
                </tr>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td><Checkbox value={user.id} onChange={(e, d) => addUserToAssignedList(e, d, user)} checked={assignUsersList.indexOf(user.id) > -1} /></td>
                    <td><Checkbox value={user.id} onChange={addUserToUnAssignedList} checked={unassingUsersList.indexOf(user.id) > -1} /></td>
                    <td>
                      {user.fullName} {user.Assigned ? `[${user.Assigned}]` : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Content>
        <Modal.Actions>
          {!assigning && <Button onClick={closeModal} negative>Cancel</Button>}
          <Button loading={assigning} onClick={onModalConfirm} disabled={error} positive icon='checkmark' labelPosition='right' content='Confirm' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}
export default WorkOrdersAssignmentsModal;