import React from 'react';
import { Segment } from 'semantic-ui-react';
import './styles.css';

const RouteWrapper = (props) => (
  <Segment basic className='segment'>
    <div className='container'>
      {props.children}
    </div>
  </Segment>

);

export default RouteWrapper;