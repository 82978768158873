import client from '../apollo';
import { GET_MAP_STATUS_BY_ID_AND_STATUS_QUERY } from "../../graphql/queries/mapStatus";

export const getMapStatusByIdAndStatus = async (id) => {
  try {
    const { data } = await client.query({
      query: GET_MAP_STATUS_BY_ID_AND_STATUS_QUERY,
      variables: {
        id
      },
      fetchPolicy: 'no-cache'
    });
    return data && data.getMapStatusByIdAndStatus ? data.getMapStatusByIdAndStatus : null;
  } catch (error) {
    console.log(error);
  }
}