import React from 'react';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import './styles.css';

const InventoryStatusByRadioTypeTableView = ({ data, assetLocation, withDual }) => (
  <>
    {withDual ? (
      <>
        <Grid className='projects-table inventory-levels-by-form-factor-table' data={data}>
          <GridToolbar>
            <b className='inventory-levels-by-form-factor-table-title'>{`${assetLocation ? assetLocation + ' - ' : ''}`}Single Port Radio - Inventory Status</b>
          </GridToolbar>
          <GridNoRecords>
            No data
          </GridNoRecords>
          <Column field='singlePortCount' title='Count' width='200px' />
          <Column field='singlePortRemaining' title='Assets Remaining' width='200px' />
          <Column field='singlePortAssetsDeleted' title='Assets Deleted' width='200px' />
          <Column field='singlePortAssetsTransferred' title='Assets Transferred' width='200px' />
          <Column field='singlePortStockAvailable' title='Stock Available' width='200px' />
        </Grid>

        <Grid className='projects-table inventory-levels-by-form-factor-table' data={data}>
          <GridToolbar>
            <b className='inventory-levels-by-form-factor-table-title'>{`${assetLocation ? assetLocation + ' - ' : ''}`}Dual Port Radio - Inventory Status</b>
          </GridToolbar>
          <GridNoRecords>
            No data
          </GridNoRecords>
          <Column field='dualPortCount' title='Count' width='200px' />
          <Column field='dualPortRemaining' title='Assets Remaining' width='200px' />
          <Column field='dualPortAssetsDeleted' title='Assets Deleted' width='200px' />
          <Column field='dualPortAssetsTransferred' title='Assets Transferred' width='200px' />
          <Column field='dualPortStockAvailable' title='Stock Available' width='200px' />
        </Grid>
      </>
    ) : (
      <Grid className='projects-table inventory-levels-by-form-factor-table' data={data}>
        <GridToolbar>
          <b className='inventory-levels-by-form-factor-table-title'>{`${assetLocation ? assetLocation + ' - ' : ''}`}Radio - Inventory Status</b>
        </GridToolbar>
        <GridNoRecords>
          No data
        </GridNoRecords>
        <Column field='totalAssetsAvailable' title='Total Assets Received' width='200px' />
        <Column field='remainingAssetsAvailable' title='Total Avail Assets (In Stock)' width='240px' />
        <Column field='assetsNeededToShip' title='Assets Needed (to Ship)' width='240px' />
      </Grid>
    )}
  </>
)

export default InventoryStatusByRadioTypeTableView;