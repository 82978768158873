import React from 'react';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import AppContext from '../../../../core/context/app.context';
import moment from 'moment';

class TableView extends React.Component {
  static contextType = AppContext;

  renderPlanTable = (plan, planDashboardData) => {
    const planRate = plan.planWeeks.map(({ planRate }) => planRate);
    const { workOrderStatusCounts = {} } = this.context;
    let { Total: total, Completed: completed, Closed: closed } = workOrderStatusCounts;
    let completedAndClosed = completed + closed;
    let remaining = total - completed - closed;
    let planAvgPerWeek = (planRate.reduce((a, b) => a + b, 0) / plan.planWeeks.length).toFixed(3);
    let percentComplete = ((completedAndClosed / total) * 100).toFixed(3);
    const now = moment();
    const daysLeft = moment(plan.endDate).diff(now, 'days');
    let targetPerWeek = daysLeft < 0 ? remaining : (remaining / (daysLeft / 7)).toFixed(3); // includes now until end date
    const currentWeek = plan.planWeeks.find((week, index) => {
      return now.isBetween(moment(week.weekStartDate), moment(plan.planWeeks[index].weekStartDate).add(7, 'days'))
    });
    let avgPerWeek = '-';
    let planCompleted;
    if (!currentWeek) {
      planCompleted = 100;
    } else if (currentWeek.week === 1) {
      planCompleted = ((((now.diff(moment(currentWeek.weekStartDate), 'days') * (currentWeek.planRate / 7)) ) / total) * 100).toFixed(3);
    } else {
      const previousWeeks = plan.planWeeks.filter(({ week }) => week < currentWeek.week).map(({ planRate }) => planRate);
      const previouslyCompleted = previousWeeks.reduce((a, b) => a + b, 0);
      // previouslyCompleted + days of a current week plan
      planCompleted = (((now.diff(moment(currentWeek.weekStartDate), 'days') * (currentWeek.planRate / 7)) + previouslyCompleted) / total * 100).toFixed(3);
      const actualTargetRate = plan.planWeeks.map(({ actualRate }) => actualRate).splice(0, currentWeek.week - 1).reduce((a, b) => a + b, 0);
      avgPerWeek = (actualTargetRate / (currentWeek.week - 1)).toFixed(3);
    }

    if (planDashboardData) {
      completedAndClosed = planDashboardData.completedAndClosed ? planDashboardData.completedAndClosed : completedAndClosed;
      remaining = planDashboardData.remaining ? planDashboardData.remaining : remaining;
      percentComplete = planDashboardData.percentComplete ? planDashboardData.percentComplete : percentComplete;
      avgPerWeek = planDashboardData.avgPerWeek ? planDashboardData.avgPerWeek : avgPerWeek;
      planAvgPerWeek = planDashboardData.planAvgPerWeek ? planDashboardData.planAvgPerWeek : planAvgPerWeek;
      planCompleted = planDashboardData.planCompleted ? planDashboardData.planCompleted : planCompleted;
      targetPerWeek = planDashboardData.targetPerWeek ? planDashboardData.targetPerWeek : targetPerWeek;
    }

    return (
      <Grid
        resizable={true}
        data={[{
          'totalWorkOrders': total || 0,
          'completed': completedAndClosed || 0,
          'remaining': remaining || 0,
          'percentComplete': `${percentComplete || 0}%`,
          'avgPerWeek': avgPerWeek,
          'planDuration': `${plan.planWeeks.length} wks`,
          'targetPerWeek': targetPerWeek || 0,
          'planCompleted': `${planCompleted === 100 && remaining > 0 ? '-' : planCompleted + '%'}`,
          'planAvgPerWeek': planAvgPerWeek
        }]}
      >
        <Column title='Total' >
          <Column field='totalWorkOrders' title='Work Orders' width='120px' />
        </Column>
        <Column title='Actual Progress' >
          <Column field='completed' title='Completed/Closed' width='170px' />
          <Column field='remaining' title='Remaining' width='160px' />
          <Column field='percentComplete' title='Percent Complete' width='170px' />
          <Column field='avgPerWeek' title='Avg per Week' width='170px' />
        </Column>
        <Column title='Plan Progress' >
          <Column field='planDuration' title='Plan Duration' width='140px' />
          <Column field='planAvgPerWeek' title='Plan Avg per Week' width='170px' />
          <Column field='planCompleted' title='Plan Complete' width='170px' />
          <Column field='targetPerWeek' title='Target per Week' width='220px' />
        </Column>
      </Grid>
    )
  }

  render() {
    const { plan, projectPlanning, planDashboardData } = this.props;
    return (
      <div style={{ marginTop: '10px' }}>
        {plan && projectPlanning ? (
          this.renderPlanTable(plan, planDashboardData)
        ) : (
            <Grid className='projects-table' data={this.props.data}>
              <GridNoRecords>
                Loading data...
          </GridNoRecords>
              <Column field='total' title='Total WOs' width='150px' />
              <Column field='completedAndClosed' title='Completed/Closed' width='170px' />
              <Column field='remaining' title='Remaining' width='150px' />
              <Column field='percentComplete' title='Percent Complete' width='170px' />
            </Grid>
          )
        }
      </div>
    )
  }
}

export default TableView;