import React, { useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import ImportStep from './importStep';
import ExportStep from './exportStep';
import OverviewStep from './overviewStep';
import DefaultsStep from './defaultsStep';
import ConfigurationStep from './configurationStep';
import MobileStep from './mobileStep';
import './styles.css';
import ReportingStep from './reportingStep';

const TAB = {
  OVERVIEW: 'OVERVIEW',
  DEFAULTS: 'DEFAULTS',
  CONFIGURATION: 'CONFIGURATION',
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
  MOBILE: 'MOBILE',
  REPORTING: 'REPORTING',
}

const projectTemplate = {
  id: '',
  isActive: false,
  isDemo: false,
  projectName: '',
  projectNumber: '',
  projectCategory: '',
  projectShortCode: '',
  projectBucket: '',
  projectTimeZone: '',
  projectHasBlackOuts: false,
  latitude: '',
  longitude: '',
  projectImportConfig: null,
  projectDefaults: '',
  projectMobileTemplates: '',
  projectExportConfig: null,
  projectWorkOrderType: '',
  projectConfiguration: '',
  autoImport: false
}

const ProjectForm = ({ selectedProject }) => {
  const [project, setProject] = useState({ ...projectTemplate });
  const [tab, setTab] = useState(TAB.OVERVIEW);

  useEffect(() => {
    if (selectedProject) {
      let {
        id,
        isActive,
        isDemo,
        projectName,
        projectNumber,
        projectCategory,
        projectShortCode,
        projectBucket,
        projectTimeZone,
        projectHasBlackOuts,
        latitude,
        longitude,
        projectImportConfig,
        projectMobileTemplates,
        projectDefaults,
        projectExportConfig,
        projectWorkOrderType,
        projectConfiguration,
        autoImport
      } = selectedProject;

      setProject({
        id,
        isActive,
        isDemo,
        projectName,
        projectNumber,
        projectCategory,
        projectShortCode,
        projectBucket,
        projectTimeZone,
        projectHasBlackOuts,
        latitude,
        longitude,
        projectImportConfig,
        projectMobileTemplates,
        projectDefaults,
        projectExportConfig,
        projectWorkOrderType,
        projectConfiguration,
        autoImport
      });
    }
  }, [selectedProject]);

  const onTabChange = (tab) => {
    setTab(tab);
  }

  const onSetProject = (data) => {
    setProject({ ...project, ...data });
  }

  const onUpdateProjectMobileTemplates = (projectMobileTemplates) => {
    setProject({ ...project, projectMobileTemplates: { ...projectMobileTemplates } });
  }

  const onUpdateProjectExportConfig = (projectExportConfig) => {
    setProject({ ...project, projectExportConfig });
  }

  const onUpdateProjectConfiguration = (projectConfiguration) => {
    setProject({ ...project, projectConfiguration });
  }

  const onUpdateProjectDefaults = (projectDefaults) => {
    setProject({ ...project, projectDefaults });
  }

  const isQSManagementEnabled = () => {
    if (project && project.projectConfiguration) {
      const projectConfiguration = JSON.parse(project.projectConfiguration);
      return projectConfiguration.additionalFeatures && projectConfiguration.additionalFeatures.qsManagement;
    }
    return false;
  }

  return (
    <>
      <Menu pointing secondary>
        <Menu.Item
          name={TAB.OVERVIEW}
          active={tab === TAB.OVERVIEW}
          onClick={() => onTabChange(TAB.OVERVIEW)}
        />
        <Menu.Item
          name={TAB.DEFAULTS}
          active={tab === TAB.DEFAULTS}
          onClick={() => onTabChange(TAB.DEFAULTS)}
        />
        <Menu.Item
          name={TAB.CONFIGURATION}
          active={tab === TAB.CONFIGURATION}
          onClick={() => onTabChange(TAB.CONFIGURATION)}
        />
        <Menu.Item
          name={TAB.IMPORT}
          active={tab === TAB.IMPORT}
          onClick={() => onTabChange(TAB.IMPORT)}
        />
        <Menu.Item
          name={TAB.EXPORT}
          active={tab === TAB.EXPORT}
          onClick={() => onTabChange(TAB.EXPORT)}
        />
        <Menu.Item
          name={TAB.MOBILE}
          active={tab === TAB.MOBILE}
          onClick={() => onTabChange(TAB.MOBILE)}
        />
        {isQSManagementEnabled() && <Menu.Item
          name={TAB.REPORTING}
          active={tab === TAB.REPORTING}
          onClick={() => onTabChange(TAB.REPORTING)}
        />}
      </Menu>

      {tab === TAB.OVERVIEW && <OverviewStep onSetProject={onSetProject} project={project} />}
      {tab === TAB.DEFAULTS && <DefaultsStep onUpdateProjectDefaults={onUpdateProjectDefaults} project={project} />}
      {tab === TAB.CONFIGURATION && <ConfigurationStep onUpdateProjectConfiguration={onUpdateProjectConfiguration} project={project} />}
      {tab === TAB.IMPORT && <ImportStep onSetProject={onSetProject} project={project} />}
      {tab === TAB.EXPORT && <ExportStep onUpdateProjectExportConfig={onUpdateProjectExportConfig} project={{ id: project.id, projectExportConfig: project.projectExportConfig, projectWorkOrderType: project.projectWorkOrderType }} />}
      {tab === TAB.MOBILE && <MobileStep onUpdateProjectMobileTemplates={onUpdateProjectMobileTemplates} project={{ id: project.id, projectMobileTemplates: project.projectMobileTemplates }} />}
      {tab === TAB.REPORTING && <ReportingStep project={project} />}
    </>
  )
}

export default ProjectForm;