import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import ErrorMessage from '../../../../shared/components/messages/error';

const SwapWorkOrdersDataModal = ({ modalRef, showModal, closeModal, loading, onSubmit, error }) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13 && showModal) {
      event.preventDefault();
      onSubmit();
    }
  }

  return (
    <div ref={modalRef} tabIndex={0} onKeyPress={handleKeyPress}>
      <Modal dimmer='blurring' size='tiny' open={showModal}>
        <Modal.Header>Exchange Data/Photos</Modal.Header>
        <Modal.Content>
          <span style={{ marginBottom: 10 }}>IMPORTANT: This action will exchange captured data and photos between 2 work orders.</span>
          {error && <ErrorMessage message={error} />}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} disabled={loading} negative>Cancel</Button>
          <Button onClick={onSubmit} loading={loading} positive icon='checkmark' labelPosition='right' content='Confirm Exchange' />
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default SwapWorkOrdersDataModal;