import React, { Component } from 'react';
import { Button, Icon, Image, Dropdown, Message, Checkbox, Input } from 'semantic-ui-react';
import AppContext from '../../core/context/app.context';
import TopMenu from '../../shared/components/top-menu';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import BACKGROUND_IMAGE from '../../assets/ReportBackground.png';
import ErrorMessage from '../../shared/components/messages/error';
import config from '../../config';
import { getToken } from '../../core/services/auth.service';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import {
  Grid, GridColumn as Column
} from '@progress/kendo-react-grid';
import { CustomColumnMenu } from './customColumnMenu';
import * as userService from '../../core/services/user.service';
import CustomReportViewModal from './customReportViewModal';
import REPORT_TYPES from '../../constants/reportTypes';
import { listProjectReportPreferences, updateProjectReportPreferences } from '../../core/services/projectReportPreferences';
import './styles.css';
import { validateEmail } from '../../helpers/validators';
import CustomMessage from '../../shared/components/messages/message';

class WorkOrdersReportRoute extends Component {
  modalRef = React.createRef();
  static contextType = AppContext;

  state = {
    fromDate: '',
    toDate: '',
    error: '',
    exporting: false,
    selectedWorkOrderStatus: 'All',
    appointmentsOnly: false,
    loadingPreviewData: false,
    workOrders: [],
    columns: [],
    format: 'csv',
    includeAuditInformation: true,
    showCustomReportViewModal: false,
    reportViews: [],
    selectedReportView: null,
    email: '',
    sendWorkOrderReportViaEmail: false,
    showEmailSuccessMessage: false
  }
  async componentDidMount() {
    const { user, currentProject } = this.context;
    await this.initReportViews();
    if (user) {
      let sendWorkOrderReportViaEmail;
      if (currentProject) {
        sendWorkOrderReportViaEmail = currentProject.projectConfiguration.sendWorkOrderReportViaEmail;
        this.setState({ sendWorkOrderReportViaEmail });
      }
      this.setState({ selectedReportView: user.id, email: user.email && sendWorkOrderReportViaEmail ? user.email : '' });
    }
    this.preview();
  }

  initReportViews = async () => {
    const { currentProject } = this.context;
    if (currentProject) {
      const reportViews = await listProjectReportPreferences(currentProject.id, REPORT_TYPES.WORKORDER_REPORT);
      this.setState({ reportViews });
    }
  }

  setSelectedReportView = (selectedReportView) => {
    this.setState({ selectedReportView });
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Work Orders Report` : 'Work Orders Report'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  onDateChange = (event) => {
    const { end, start } = event.target.value;
    this.setState({ fromDate: start, toDate: end });
  }

  // current format is yyyy-MM-dd
  formatDate(date) {
    if (date) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  preview = async () => {
    try {
      let { fromDate, toDate, selectedWorkOrderStatus, appointmentsOnly, includeAuditInformation, selectedReportView } = this.state;
      const { currentProject, user } = this.context;
      const isUserReportView = selectedReportView === (user && user.id);
      if (!isUserReportView && selectedReportView === null) {
        return;
      }
      if (selectedWorkOrderStatus) {
        const workOrderType = currentProject && currentProject.projectWorkOrderType ? currentProject.projectWorkOrderType : null;
        if (workOrderType) {
          this.setState({ loadingPreviewData: true, error: false, workOrders: [], columns: [] });
          const preview = true;
          const token = await getToken();
          fromDate = this.formatDate(fromDate);
          toDate = this.formatDate(toDate);
          let response = await fetch(`${config.exportWorkOrdersReportApi}/${currentProject.id}/${workOrderType}/${appointmentsOnly}/${includeAuditInformation}/${isUserReportView ? user.id : 'undefined'}/${!isUserReportView ? selectedReportView : 'undefined'}/${preview}/csv/${selectedWorkOrderStatus}/undefined${fromDate && toDate ? `/${fromDate}/${toDate}` : ''}`, {
            method: 'get',
            headers: new Headers({
              'Authorization': token
            })
          });
          if (!response.ok) {
            throw new Error('No data found');
          }
          const { workOrders, columns } = await response.json();
          this.setState({ loadingPreviewData: false, workOrders, columns });
        }
      } else {
        this.setState({ error: 'Work Order Status is required' });
      }
    } catch (error) {
      this.setState({ loadingPreviewData: false });
      throw error;
    }
  }

  downloadReport = async (projectId, workOrderType, workOrderStatus, fromDate, toDate, appointmentsOnly, includeAuditInformation, format, preview = false, email) => {
    try {
      const { selectedReportView } = this.state;
      const { user } = this.context;
      const isUserReportView = selectedReportView === user.id;
      const token = await getToken();
      let response = await fetch(`${config.exportWorkOrdersReportApi}/${projectId}/${workOrderType}/${appointmentsOnly}/${includeAuditInformation}/${isUserReportView ? user.id : 'undefined'}/${isUserReportView ? 'undefined' : selectedReportView}/${preview}/${format}/${workOrderStatus}/${email || 'undefined'}${fromDate && toDate ? `/${fromDate}/${toDate}` : ''}`, {
        method: 'get',
        headers: new Headers({
          'Authorization': token
        })
      });
      if (!response.ok) {
        throw new Error('No data found');
      }
      const url = await response.json();
      if (url) {
        window.open(url);
      }
    } catch (error) {
      throw error;
    }
  }

  export = async () => {
    this.setState({ error: '', showEmailSuccessMessage: false });
    const { sendWorkOrderReportViaEmail } = this.state;
    try {
      let { fromDate, toDate, selectedWorkOrderStatus, appointmentsOnly, format, includeAuditInformation, email } = this.state;
      const { currentProject } = this.context;
      if (!selectedWorkOrderStatus) {
        this.setState({ error: 'Work Order Status is required' });
        return;
      }
      if (!format) {
        this.setState({ error: 'Format is required' });
        return;
      }
      if (sendWorkOrderReportViaEmail) {
        if (!email) {
          this.setState({ error: 'Email is required' });
          return;
        } else if (!validateEmail(email)) {
          this.setState({ error: 'Email is not valid' });
          return;
        }
      }
      const workOrderType = currentProject && currentProject.projectWorkOrderType ? currentProject.projectWorkOrderType : null;
      if (workOrderType) {
        this.setState({ exporting: true });
        fromDate = this.formatDate(fromDate);
        toDate = this.formatDate(toDate);
        await this.downloadReport(currentProject.id, workOrderType, selectedWorkOrderStatus, fromDate, toDate, appointmentsOnly, includeAuditInformation, format, false, email);
        this.setState({ exporting: false });
        if (sendWorkOrderReportViaEmail) {
          this.setState({ showEmailSuccessMessage: true });
        }
      }
    } catch (error) {
      this.setState({ error: error.message, exporting: false });
    }
  }

  onWorkOrderStatusChange = (event, data) => {
    this.setState({ selectedWorkOrderStatus: data.value });
  }

  onFormatChange = (event, data) => {
    this.setState({ format: data.value });
  }

  setAppointmentsOnly = () => {
    const { appointmentsOnly } = this.state;
    this.setState({ appointmentsOnly: !appointmentsOnly });
  }

  onColumnsSubmit = async (columnsState) => {
    const { user, currentProject } = this.context;
    const { selectedReportView } = this.state;
    const isUserReportView = selectedReportView === user.id;
    if (isUserReportView) {
      const userId = user.id;
      const projectId = currentProject.id;
      const reportingPreferences = JSON.stringify({
        projectId,
        type: 'workOrderReport',
        data: columnsState
      });
      await userService.updateUser(userId, reportingPreferences);
    } else {
      await updateProjectReportPreferences(selectedReportView, JSON.stringify(columnsState));
    }
    this.setState({
      columns: columnsState
    });
    this.preview();
  }

  onColumnReorder = (event, allColumns) => {
    const { user, currentProject } = this.context;
    const userId = user.id;
    const projectId = currentProject.id;
    const columnsOrder = event.target._columns.map(column => column.title);
    const { selectedReportView } = this.state;
    const isUserReportView = selectedReportView === user.id;

    let newColumnsOrder = Array.from(columnsOrder, x => Object.create({}));
    let hiddenColumns = [];

    allColumns.forEach((column) => {
      const order = columnsOrder.indexOf(column.title);
      if (order === -1) {
        hiddenColumns.push(column);
      } else {
        newColumnsOrder[order] = column;
      }
    });
    newColumnsOrder = [...newColumnsOrder, ...hiddenColumns];

    if (isUserReportView) {
      const reportingPreferences = JSON.stringify({
        projectId,
        type: 'workOrderReport',
        data: newColumnsOrder
      });
      userService.updateUser(userId, reportingPreferences);
    } else {
      updateProjectReportPreferences(selectedReportView, JSON.stringify(newColumnsOrder));
    }

    this.setState({});
  }

  setIncludeAuditInformation = () => {
    const { includeAuditInformation } = this.state;
    this.setState({ includeAuditInformation: !includeAuditInformation });
  }

  onShowCustomReportViewModal = () => {
    this.setState({ showCustomReportViewModal: true });
  }

  onHideCustomReportViewModal = () => {
    this.setState({ showCustomReportViewModal: false });
  }

  getReportViewsOptions = () => {
    const { reportViews } = this.state;
    const { user } = this.context;
    if (user) {
      return [{ key: user.id, value: user.id, text: 'Default Report View' }, ...reportViews.map(({ id, reportName }) => Object.assign({}, { key: id, text: reportName, value: id }))];
    }
    return reportViews.map(({ id, reportName }) => Object.assign({}, { key: id, text: reportName, value: id }));
  }

  onReportViewChange = async (event, { value }) => {
    await this.setState({ selectedReportView: value });
    this.preview();
  }

  renderGridColumns = () => {
    const { columns } = this.state;
    return columns.map((column, index) => {
      return (
        column.show && (
          <Column
            key={index}
            field={column.field}
            title={column.title}
            width='240px'
            columnMenu={
              props =>
                <CustomColumnMenu
                  {...props}
                  columns={columns}
                  onColumnsSubmit={this.onColumnsSubmit}
                />
            }
          />
        )
      )
    })
  }

  onEmailChange = (event, { value }) => {
    this.setState({ email: value });
  }

  renderWorkOrdersReportRouteContent = () => {
    const { currentProject, user } = this.context;
    const {
      error,
      exporting,
      appointmentsOnly,
      loadingPreviewData,
      workOrders,
      showCustomReportViewModal,
      selectedReportView,
      columns,
      email,
      sendWorkOrderReportViaEmail,
      showEmailSuccessMessage
    } = this.state;
    const renderToolbar = () => (
      <div className='workorder-report-toolbar-container'>
        <DateRangePicker format='yyyy-MM-dd' onChange={this.onDateChange} />
        <div className='workorder-report-workorder-status-dropdown'>
          <div className='workorder-report-workorder-status-dropdown-label'>
            <p>Work Order Status:</p>
          </div>
          <Dropdown
            compact
            clearable
            placeholder='Select Work Order Status'
            style={{ marginLeft: '10px', width: '150px' }}
            selection
            defaultValue={'All'}
            options={[
              { key: 0, value: 'Assigned', text: 'Assigned' },
              { key: 1, value: 'Closed', text: 'Closed' },
              { key: 2, value: 'Completed', text: 'Completed' },
              { key: 3, value: 'Escalated', text: 'Escalated' },
              { key: 4, value: 'InProgress', text: 'InProgress' },
              { key: 5, value: 'InReview', text: 'InReview' },
              { key: 6, value: 'Open', text: 'Open' },
              { key: 7, value: 'All', text: 'All' }]}
            onChange={this.onWorkOrderStatusChange} />
        </div>
        <div style={{ display: 'none' }}>
          <div style={{ display: 'flex', fontSize: '0.8rem', marginBottom: '2px', marginLeft: '10px' }}>File Format:</div>
          <Dropdown
            compact
            clearable
            placeholder='Select Format'
            style={{ marginLeft: '10px', width: '100px' }}
            selection
            defaultValue={'csv'}
            options={[
              { key: 0, value: 'csv', text: 'CSV' },
              { key: 1, value: 'xlsx', text: 'XLSX' }]}
            onChange={this.onFormatChange} />
        </div>
        <div className='workorder-report-checkbox'>
          <Checkbox checked={appointmentsOnly} onChange={this.setAppointmentsOnly} label='Appointments only' />
        </div>
        <div className='workorder-report-preview-export-container'>
          {sendWorkOrderReportViaEmail && <Input value={email} onChange={this.onEmailChange} style={{ marginRight: '10px' }} placeholder='Email' type='email' required />}
          <Button primary loading={loadingPreviewData} onClick={this.preview}><Icon name='eye' />Preview</Button>
          <Button primary loading={exporting} onClick={this.export}><Icon name='download' />Export</Button>
        </div>
      </div>
    )

    return (
      <React.Fragment>
        {showCustomReportViewModal && <CustomReportViewModal
          initReportViews={this.initReportViews}
          setSelectedReportView={this.setSelectedReportView}
          projectId={currentProject.id || ''}
          selectedReportView={selectedReportView}
          userId={user.id || ''}
          modalRef={this.modalRef}
          showModal={showCustomReportViewModal}
          closeModal={this.onHideCustomReportViewModal} />}
        <div>
          <Message color='grey' content='This report exports work order records within a given date range. Leave date range empty to return all records. Filter to a specific status if needed, or choose All. Click Preview to get a sample view and adjust column order or show/hide in the preview grid. Drag to reorder columns and/or click the separator icon on any column header to choose columns to show or hide. Layout changes will be auto-saved to current report view. Create a new shared report view if needed that will be available project users. Note that if you choose to show Comments columns you may see duplicate rows that return multiple comments per work order.' />
        </div>
        <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
          <div style={{ display: 'flex', paddingTop: '10px', marginBottom: '2px', marginRight: '10px' }}>Report View:</div>
            <Dropdown
              placeholder='Select Report View'
              style={{ width: '180px', marginRight: 10 }}
              selection
              search
              value={selectedReportView}
              options={this.getReportViewsOptions()}
              onChange={this.onReportViewChange}
            />
          <div>
            <Button disabled={loadingPreviewData} onClick={this.onShowCustomReportViewModal}><Icon name='copy' />Create Shared Report View</Button>
          </div>
        </div>
        {renderToolbar()}
        {showEmailSuccessMessage && <div style={{ marginTop: 5, marginBottom: 10 }}>
          <CustomMessage message='You have requested a longer running report. When the information is ready you will recieve an email with a link to download the report.' />
        </div>}
        {error && <div style={{ marginTop: '5px' }}>
          <ErrorMessage message={error} style={{ marginBottom: 10 }} />
        </div>}
        {workOrders.length > 0 && <div>
          <Grid data={workOrders} reorderable onColumnReorder={(event) => this.onColumnReorder(event, columns)}>
            {this.renderGridColumns()}
          </Grid>
        </div>}
        {workOrders.length > 0 ? null : (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
          <Image src={BACKGROUND_IMAGE} style={{ position: 'absolute' }} />
        </div>)}
      </React.Fragment>
    );
  }

  render() {
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        {this.renderWorkOrdersReportRouteContent()}
      </ProjectRouteWrapper>
    );
  }
}

export default WorkOrdersReportRoute;