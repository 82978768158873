import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../core/context/app.context';
import TopMenu from '../../shared/components/top-menu';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import { Button, Icon, Message, MessageContent, MessageHeader } from 'semantic-ui-react';
import { getSnapshotHistoryByProjectId, createSnapshot, restoreFromSnapshot } from '../../core/services/snapshot.service';
import CustomLoader from '../../shared/components/loader';
import { isEnabled } from '../../core/services/auth.service';
import ROLES from '../../constants/roles';

const SnapshotStatusType = {
  Initiating_Creation: 'Initiating_Creation',
  Initiating_Restore: 'Initiating_Restore',
  Creation_In_Progress: 'Creation_In_Progress',
  Restore_In_Progress: 'Restore_In_Progress',
}

let pullingIntervalId = undefined;

const disablePulling = () => {
  clearInterval(pullingIntervalId);
  pullingIntervalId = undefined;
}

const SnapshotsRoute = () => {
  const context = useContext(AppContext);
  const [snapshotHistory, setSnapshotHistory] = useState(undefined);

  useEffect(() => {
    fetchSnapshotHistory();
    return () => {
      if (pullingIntervalId) {
        disablePulling();
      }
    }
  }, []);

  useEffect(() => {
    if (snapshotHistory && !snapshotHistory.snapshotInProgress && pullingIntervalId) {
      disablePulling();
    }
    if (snapshotHistory && snapshotHistory.snapshotInProgress && !pullingIntervalId) {
      pullingIntervalId = setInterval(() => {
        fetchSnapshotHistory();
      }, 10000);
    }
  }, [snapshotHistory]);

  const fetchSnapshotHistory = async () => {
    const { currentProject } = context;
    if (currentProject) {
      const snapshotHistory = await getSnapshotHistoryByProjectId(currentProject.id);
      setSnapshotHistory(snapshotHistory);
    }
  }

  const refreshView = () => {
    setSnapshotHistory(undefined);
    fetchSnapshotHistory();
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
    
    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  }

  const renderTopMenu = () => {
    const { currentProject } = context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Snapshots` : 'Snapshots'}`,
        iconName: 'flag'
      },
      tabs: [
        {
          onClick: refreshView,
          refresh: true
        }
      ]
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  const onCreateSnapshot = async () => {
    setSnapshotHistory({ ...snapshotHistory, snapshotInProgress: { status: SnapshotStatusType.Initiating_Creation } });
    const { currentProject } = context;
    await createSnapshot(currentProject.id);
    fetchSnapshotHistory();
  }

  const onRestoreFromSnapshot = async () => {
    setSnapshotHistory({ ...snapshotHistory, snapshotInProgress: { status: SnapshotStatusType.Initiating_Restore } });
    const { currentProject } = context;
    await restoreFromSnapshot(currentProject.id);
    fetchSnapshotHistory();
  }

  const isCreateSnapshotButtonVisible = () => {
    const { user } = context;
    if (user) {
      return isEnabled(user.userRoles, [ROLES.SystemAdmin]);
    }
    return false;
  }

  const isRestoreFromSnapshotButtonVisible = () => {
    const { user } = context;
    if (user) {
      return isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin]);
    }
    return false;
  }

  const renderRouteContent = () => {
    const notInitCreationOrCreating = snapshotHistory && snapshotHistory.snapshotInProgress && snapshotHistory.snapshotInProgress.status !== SnapshotStatusType.Initiating_Creation && snapshotHistory.snapshotInProgress.status !== SnapshotStatusType.Creation_In_Progress;
    const notInitRestoreOrRestoring = snapshotHistory && snapshotHistory.snapshotInProgress && snapshotHistory.snapshotInProgress.status !== SnapshotStatusType.Initiating_Restore && snapshotHistory.snapshotInProgress.status !== SnapshotStatusType.Restore_In_Progress;
    const showSnapshotCreationFailedError = (snapshotHistory && snapshotHistory.lastCreationFailed && !snapshotHistory.snapshotInProgress) || (snapshotHistory && snapshotHistory.lastCreationFailed && notInitCreationOrCreating);
    const showSnapshotRestoreFailedError = (snapshotHistory && snapshotHistory.lastRestoreFailed && !snapshotHistory.snapshotInProgress) || (snapshotHistory && snapshotHistory.lastRestoreFailed && notInitRestoreOrRestoring);
    const isSnapshotInitCreationInProgress = snapshotHistory && snapshotHistory.snapshotInProgress && snapshotHistory.snapshotInProgress.status === SnapshotStatusType.Initiating_Creation;
    const isSnapshotInitRestoreInProgress = snapshotHistory && snapshotHistory.snapshotInProgress && snapshotHistory.snapshotInProgress.status === SnapshotStatusType.Initiating_Restore;
    const isSnapshotCreationInProgress = snapshotHistory && snapshotHistory.snapshotInProgress && snapshotHistory.snapshotInProgress.status === SnapshotStatusType.Creation_In_Progress;
    const isSnapshotRestoreInProgress = snapshotHistory && snapshotHistory.snapshotInProgress && snapshotHistory.snapshotInProgress.status === SnapshotStatusType.Restore_In_Progress;
    const isCreateSnapshotButtonDisabled = !snapshotHistory || !!snapshotHistory.snapshotInProgress;
    const isRestoreFromSnapshotButtonDisabled = !snapshotHistory || !!snapshotHistory.snapshotInProgress || !snapshotHistory.snapshot; 
    const showLastRestoreCompletedMessage = snapshotHistory && snapshotHistory.lastRestoreCompleted && !isSnapshotInitRestoreInProgress && !isSnapshotRestoreInProgress && !showSnapshotRestoreFailedError;
    return (
      <>
        {!snapshotHistory ? <CustomLoader loading={true} /> : (
        <div>
          <div>
            {isCreateSnapshotButtonVisible() && <Button primary onClick={onCreateSnapshot} disabled={isCreateSnapshotButtonDisabled}>Create Snapshot</Button>}
            {isRestoreFromSnapshotButtonVisible() && <Button onClick={onRestoreFromSnapshot} color='green' disabled={isRestoreFromSnapshotButtonDisabled}>Restore from Snapshot</Button>}
          </div>
          {!snapshotHistory.snapshot && <Message icon color='blue'>
            <MessageContent>
              <MessageHeader>Snapshot Status <Icon name='info circle' /></MessageHeader>
              It seems that there is no available snapshot for your project.
            </MessageContent>
          </Message>}
          {snapshotHistory.snapshot && <Message icon color='green'>
            <MessageContent>
              <MessageHeader>Your Snapshot is Ready <Icon name='check circle' /></MessageHeader>
              A snapshot was created by <b>{snapshotHistory.snapshot.user.userName}</b> on <b>{formatDate(snapshotHistory.snapshot.createdDate)}</b>.
            </MessageContent>
          </Message>}
          {showLastRestoreCompletedMessage && <Message icon color='green'>
            <MessageContent>
              <MessageHeader>Your Project has been Successfully Restored <Icon name='check circle' /></MessageHeader>
              The project was restored from a snapshot by <b>{snapshotHistory.lastRestoreCompleted.user.userName}</b> on <b>{formatDate(snapshotHistory.lastRestoreCompleted.createdDate)}</b>.
            </MessageContent>
          </Message>}
          {showSnapshotCreationFailedError && <Message icon color='red'>
            <MessageContent>
              <MessageHeader>Unable to create a new snapshot <Icon name='bug' /></MessageHeader>
              The system encountered an error during the last attempt to create a snapshot of the current state. Please try again later or contact support for assistance.
            </MessageContent>
          </Message>}
          {showSnapshotRestoreFailedError && <Message icon color='red'>
            <MessageContent>
              <MessageHeader>Failed to restore project from snapshot <Icon name='bug' /></MessageHeader>
              The system encountered an error during the last attempt to restore the project from snapshot. Please try again later or contact support for assistance.
            </MessageContent>
          </Message>}
          {isSnapshotInitCreationInProgress && <Message icon color='yellow'>
            <MessageContent>
              <MessageHeader>Initiating Snapshot Creation <Icon name='circle notched' loading /></MessageHeader>
              Your request is currently being processed.
            </MessageContent>
          </Message>}
          {isSnapshotInitRestoreInProgress && <Message icon color='yellow'>
            <MessageContent>
              <MessageHeader>Initiating Snapshot Restoration <Icon name='circle notched' loading /></MessageHeader>
              Your request is currently being processed.
            </MessageContent>
          </Message>}
          {isSnapshotCreationInProgress && <Message icon color='yellow'>
            <MessageContent>
              <MessageHeader>Snapshot Creation in Progress <Icon name='circle notched' loading /></MessageHeader>
              Your project snapshot is currently being created. This process may take some time.
            </MessageContent>
          </Message>}
          {isSnapshotRestoreInProgress && <Message icon color='yellow'>
            <MessageContent>
              <MessageHeader>Restoring Project from Snapshot <Icon name='circle notched' loading /></MessageHeader>
              Your project is currently being restored from a snapshot. This process may take some time.
            </MessageContent>
          </Message>}
        </div>
      )}
    </>
    );
  }

  return (
    <ProjectRouteWrapper>
      {renderTopMenu()}
      {renderRouteContent()}
    </ProjectRouteWrapper>
  );
}

export default SnapshotsRoute;