import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import DropdownDatePicker from './dropdownDatePicker';

const reportTemplate = {
  type: '',
  headings: false,
  template: [],
  extension: '',
  conditions: []
}

const ExportReportBaseInfoModal = ({ showModal, closeModal, onSaveReportBaseInfo, selectedReport }) => {
  const [report, setReport] = useState({ ...reportTemplate });

  useEffect(() => {
    if (selectedReport) {
      setReport({ ...selectedReport });
    }
  }, []);

  const onTypeChange = (event, { value }) => {
    setReport({ ...report, type: value });
  }

  const onExtensionChange = (event, { value }) => {
    setReport({ ...report, extension: value });
  }

  const onHeadingsChange = (event, { checked }) => {
    setReport({ ...report, headings: checked });
  }

  const onWindowsRecordDelimiterChange = (event, { checked }) => {
    if (checked) {
      setReport({ ...report, record_delimiter: 'windows' });
    } else {
      delete report['record_delimiter'];
      setReport({ ...report });
    }
  }

  const onShowDelimiterChange = (event, { checked }) => {
    if (checked) {
      setReport({ ...report, delimiter: '' });
    } else {
      delete report['delimiter'];
      setReport({ ...report });
    }
  }

  const onDelimiterChange = (event, { value }) => {
    setReport({ ...report, delimiter: value });
  }

  const onSubmit = () => {
    onSaveReportBaseInfo(report);
  }

  const onFilenameShortCodeChange = (event, { checked }) => {
    if (!checked) {
      setReport({ ...report, filenameShortCode: false });
    } else {
      delete report['filenameShortCode'];
      setReport({ ...report });
    }
  }

  const onShowFileNameDelimiterChange = (event, { checked }) => {
    if (checked) {
      setReport({ ...report, filenameDelimiter: '' });
    } else {
      delete report['filenameDelimiter'];
      setReport({ ...report });
    }
  }

  const onFileNameDelimiterChange = (event, { value }) => {
    setReport({ ...report, filenameDelimiter: value });
  }

  const onFilenameDateFormatChange = (event, { value }) => {
    if (value === null || value === undefined || value === '') {
      delete report['filenameDateFormat'];
      setReport({ ...report });
    } else {
      setReport({ ...report, filenameDateFormat: value });
    }
  }

  return (
    <Modal dimmer='blurring' size='tiny' open={showModal}>
      <Modal.Header>Report Base Information</Modal.Header>
      <Modal.Content>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '10px' }}>
            <span style={{ paddingRight: '10px' }}>Type:</span>
            <Input placeholder='Report Type' onChange={onTypeChange} value={report.type} className='export-report-type-input' />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <span style={{ paddingRight: '10px' }}>Extension:</span>
            <Dropdown
              className='export-dropdown'
              placeholder='Extension'
              selection
              value={report.extension}
              options={[
                { key: 0, value: 'csv', text: 'csv' },
                { key: 1, value: 'xlsx', text: 'xlsx' },
                { key: 2, value: 'txt', text: 'txt' },
              ]}
              onChange={onExtensionChange} />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <span style={{ paddingRight: '10px' }}>File Name Date Format:</span>
            <DropdownDatePicker
              placeholder='File Name Date Format'
              onChange={onFilenameDateFormatChange}
              value={report.filenameDateFormat || null}
            />
          </div>
          <Checkbox toggle label='File Name Short Code' className='export-option' checked={!report.hasOwnProperty('filenameShortCode')} onChange={onFilenameShortCodeChange} />
          <div>
            <Checkbox toggle label='File Name Delimiter' className='export-option export-option-right-margin' onChange={onShowFileNameDelimiterChange} checked={report.hasOwnProperty('filenameDelimiter')} />
            {report.hasOwnProperty('filenameDelimiter') && <Input placeholder='File Name Delimiter' onChange={onFileNameDelimiterChange} value={report.filenameDelimiter} />}
          </div>
          <Checkbox toggle label='Headers' checked={report.headings} onChange={onHeadingsChange} className='export-option' />
          <Checkbox toggle label='Windows Record Delimiter' className='export-option' checked={report.hasOwnProperty('record_delimiter')} onChange={onWindowsRecordDelimiterChange} />
          <div>
            <Checkbox toggle label='Delimiter' className='export-option export-option-right-margin' onChange={onShowDelimiterChange} checked={report.hasOwnProperty('delimiter')} />
            {report.hasOwnProperty('delimiter') && <Input placeholder='Delimiter' onChange={onDelimiterChange} value={report.delimiter} />}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button onClick={onSubmit} disabled={!report.type || !report.extension} positive icon='checkmark' labelPosition='right' content='Save' />
      </Modal.Actions>
    </Modal>
  )
}

export default ExportReportBaseInfoModal;