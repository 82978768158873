import React, { Component } from 'react';
import { Button, Icon, Image, Message } from 'semantic-ui-react';
import AppContext from '../../core/context/app.context';
import TopMenu from '../../shared/components/top-menu';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import BACKGROUND_IMAGE from '../../assets/ReportBackground.png';
import ErrorMessage from '../../shared/components/messages/error';
import config from '../../config';
import { getToken } from '../../core/services/auth.service';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';

class ExceptionsReportRoute extends Component {

  static contextType = AppContext;

  state = {
    fromDate: '',
    toDate: '',
    error: '',
    exporting: false
  }

  renderTopMenu = () => {
    const { currentProject } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Exceptions Report` : 'Exceptions Report'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  onDateChange = (event) => {
    const { end, start } = event.target.value;
    this.setState({ fromDate: start, toDate: end });
  }

  // current format is yyyy-MM-dd
  formatDate(date) {
    if (date) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  downloadReport = async (projectId, fromDate, toDate) => {
    try {
      const token = await getToken();
      let response = await fetch(`${config.exportReportByTypeApi}/${projectId}/exceptions/${fromDate}/${toDate}`, {
        method: 'get',
        headers: new Headers({
          'Authorization': token
        })
      });
      if (!response.ok) {
        throw new Error('No data found');
      }
      const url = await response.json();
      window.open(url);
    } catch (error) {
      throw error;
    }
  }

  export = async () => {
    this.setState({ error: '' });
    try {
      let { fromDate, toDate } = this.state;
      const { currentProject } = this.context;
      if (fromDate && toDate) {
        this.setState({ exporting: true });
        fromDate = this.formatDate(fromDate);
        toDate = this.formatDate(toDate);
        await this.downloadReport(currentProject.id, fromDate, toDate);
        this.setState({ exporting: false });
      } else {
        this.setState({ error: 'From Date/To Date are required fields' });
      }
    } catch (error) {
      this.setState({ error: error.message, exporting: false });
    }
  }

  renderExceptionsReportRouteContent = () => {
    const { error, exporting } = this.state;
    return (
      <React.Fragment>
        <Message color='grey' content='This report exports work orders for a given date range that were once flagged as Could Not Complete (RTC/CNC) or as RTU/3-strike orders including the last comment from the technician.' />
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <DateRangePicker format='yyyy-MM-dd' onChange={this.onDateChange} />
          <Button loading={exporting} style={{ marginLeft: '10px' }} onClick={this.export}><Icon name='download' />Export</Button>
        </div>
        {error && <div style={{ marginTop: '10px' }}>
          <ErrorMessage message={error} />
        </div>}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
          <Image src={BACKGROUND_IMAGE} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        {this.renderExceptionsReportRouteContent()}
      </ProjectRouteWrapper>
    );
  }
}

export default ExceptionsReportRoute;