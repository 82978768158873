import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

export const ConfirmDeleteAppointmentDialog = ({ onConfirm, onCancel }) => {

    return (
        <Dialog title={'Appointment Exists'} onClose={onCancel}>
            <span>Are you sure you want to delete this appointment?</span>
            <DialogActionsBar>
                <button className='k-button' onClick={onConfirm}>
                    Confirm
                </button>
                <button className='k-button' onClick={onCancel}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    )
}