import React, { useEffect } from 'react';
import DrawControl from 'react-mapbox-gl-draw';
import { Source, MapContext } from 'react-mapbox-gl';
import bbox from '@turf/bbox';
import pointInPolygon from '@turf/boolean-point-in-polygon';
import MapLayers from './mapLayers';
import { MAP_LAYOUT_IDS } from '../../../../constants/mapLayout';
import config from '../../../../config';
import { addSelectedWorkOrders, deleteSelectedWorkOrders, setMap } from '../../../../core/redux/actions/map.actions';
import { connect, useDispatch } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { isEnabled } from '../../../../core/services/auth.service';
import ROLES from '../../../../constants/roles';
import MAP_SOURCES from '../../constants/mapSource';
import MapLatestDataLoader from './mapLatestDataLoader';
import Legend from './legend';

let mounted = false;

const MapRenderer = React.memo(({
  customDataUrl,
  overlayGeoJsonFileUrl,
  geoJsonFileUrl,
  component: MapComponent,
  onGetWorkOrderDetails,
  style,
  onMapStyleChange,
  user,
  previousMap,
  setDrawControlRef
}) => {
  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    }
  }, []);

  const dispatch = useDispatch();

  const onPolygonDrawn = (event, map) => {
    const polygon = { id: event.features[0].id, workOrders: findFeatures(event, map) }
    dispatch(addSelectedWorkOrders(polygon));
  }

  const onPolygonDelete = (event, map) => {
    const polygonId = event.features[0].id;
    dispatch(deleteSelectedWorkOrders(polygonId));
  }

  const findFeatures = (event, map) => {
    const userPolygon = event.features[0];
    const polygonBoundingBox = bbox(userPolygon);
    const southWest = [polygonBoundingBox[0], polygonBoundingBox[1]];
    const northEast = [polygonBoundingBox[2], polygonBoundingBox[3]];
    const northEastPointPixel = map.project(northEast);
    const southWestPointPixel = map.project(southWest);
    const features = map.queryRenderedFeatures([southWestPointPixel, northEastPointPixel], { layers: [MAP_LAYOUT_IDS.Primary, MAP_LAYOUT_IDS.Secondary, MAP_LAYOUT_IDS.Tertiary] });
    return features.filter(freature => pointInPolygon(freature, userPolygon)).map(({ properties }) => {
      const { id, mff, ms } = properties;
      return {
        id, mff, ms
      }
    });
  }

  const onStyleLoad = (map, event) => {
    if (mounted) {
      dispatch(setMap(map));
    }
  }

  return (
    <>
      {MapComponent && <MapComponent style={style}
        onStyleLoad={onStyleLoad}
        center={previousMap ? [previousMap.center.lng, previousMap.center.lat] : [-99.9424339, 38.8251558]}
        zoom={previousMap ? [previousMap.zoom] : [5]}
        containerStyle={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
        }}>
        <MapLatestDataLoader />
        <MapContext.Consumer>
          {(map) => (
            <React.Fragment>
              <React.Fragment>
                {customDataUrl && <Source id={'custom'} geoJsonSource={{
                  type: 'geojson',
                  data: customDataUrl,
                  cluster: true,
                  clusterMaxZoom: 8,
                  generateId: true,
                }} />}
                <Source id={MAP_SOURCES.MAP_SOURCE_ID} geoJsonSource={{
                  type: 'geojson',
                  data: geoJsonFileUrl,
                  cluster: true,
                  clusterMaxZoom: 8,
                  generateId: true,
                }} />
                {overlayGeoJsonFileUrl && <Source id={MAP_SOURCES.OVERLAY_SOURCE_ID} geoJsonSource={{
                  type: 'geojson',
                  data: overlayGeoJsonFileUrl
                }} />}
                <MapLayers map={map} onGetWorkOrderDetails={onGetWorkOrderDetails} />
              </React.Fragment>
              <Button.Group vertical className={isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.ClientAdmin, ROLES.Admin]) ? 'map-view' : 'map-view-without-draw-control'} basic size='tiny'>
                <Button icon className='map-view-button' onClick={() => onMapStyleChange(config.mapbox.style)}>
                  <Icon name='road' color='black' size='large' />
                </Button>
                <Button icon className='map-view-button' onClick={() => onMapStyleChange(`mapbox://styles/${config.mapbox.satelliteStyle}`)}>
                  <Icon name='globe' color='black' size='large' />
                </Button>
              </Button.Group>
              <Legend />
              {isEnabled(user.userRoles, [ROLES.AccountAdmin, ROLES.SystemAdmin, ROLES.ClientAdmin, ROLES.Admin]) && <DrawControl
                onDrawDelete={(event) => onPolygonDelete(event, map)}
                onDrawUpdate={(event) => onPolygonDrawn(event, map)}
                onDrawCreate={(event) => onPolygonDrawn(event, map)}
                position='top-right'
                displayControlsDefault={false}
                ref={(drawControl) => setDrawControlRef(drawControl)}
                controls={{ polygon: true, trash: true }} />}
            </React.Fragment>
          )}
        </MapContext.Consumer>
      </MapComponent>}
    </>
  )
}, (prevProps, nextProps) => {
  return (prevProps.geoJsonFileUrl === nextProps.geoJsonFileUrl);
});

const mapStateToProps = ({ map }) => {
  return {
    overlayGeoJsonFileUrl: map.overlayGeoJsonFileUrl,
    geoJsonFileUrl: map.geoJsonFileUrl,
    style: map.style,
    previousMap: map.previousMap,
    customDataUrl: map.customDataUrl,
  }
}

export default connect(mapStateToProps, null)(MapRenderer);