import React, { Component } from 'react';
import AppContext from '../../../../core/context/app.context';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import './styles.css';
import { isWaterProject } from '../../../../helpers/workOrderType';
import { isDemoProject } from '../../../../core/services/project.service';
import { Button,Checkbox } from 'semantic-ui-react';

class CustomCell extends React.Component {
  render() {
    const { dataItem, field } = this.props;
    return (
      <td>
        {dataItem[field]}
      </td>
    );
  }
}

class ProductionStatus extends Component {
  state = {
    checked: false,
  };
  static contextType = AppContext;
  handleClick = () =>
    this.setState({
      checked: !this.state.checked,
    });
  handleChange = (event, data) => {
    const value = data.checked;
    this.setState({ checked: value, });
  };;

  getMonday = (week = 0) => {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1) + week;
    return new Date(date.setDate(diff));
  }

  getWeeklyTableColumnLabel = (date) => {
    return new Intl.DateTimeFormat('en-US').format(date);
  }

  onWeeklyProductionStatusWeekNext = () => {
    const {
      setWeeklyProductionStatusWeek,
      weeklyProductionStatusWeek
    } = this.context;
    setWeeklyProductionStatusWeek(weeklyProductionStatusWeek + 7);
  }

  onWeeklyProductionStatusWeekPrevious = () => {
    const {
      setWeeklyProductionStatusWeek,
      weeklyProductionStatusWeek
    } = this.context;
    setWeeklyProductionStatusWeek(weeklyProductionStatusWeek - 7);
  }

  render() {
    const {
      currentProject,
      weeklyProductionStatus,
      weeklyProductionStatusWeek,
      dailyProductionStatusColumnsByMeterType, dailyProductionStatusRowsByMeterType, dailyProductionStatusHasMeter,
      dailyProductionStatusColumnsByRegisterType, dailyProductionStatusRowsByRegisterType, dailyProductionStatusHasRegister,
      dailyProductionStatusColumnsByRadioType, dailyProductionStatusRowsByRadioType, dailyProductionStatusHasRadio,
      dailyProductionStatusByNonSerializedInventory
    } = this.context;

    const mondayDate = this.getMonday(weeklyProductionStatusWeek);
    const tuesdayDate = new Date(new Date(mondayDate).setDate(mondayDate.getDate() + 1));
    const wednesdayDate = new Date(new Date(tuesdayDate).setDate(tuesdayDate.getDate() + 1));
    const thursdayDate = new Date(new Date(wednesdayDate).setDate(wednesdayDate.getDate() + 1));
    const fridayDate = new Date(new Date(thursdayDate).setDate(thursdayDate.getDate() + 1));
    const saturdayDate = new Date(new Date(fridayDate).setDate(fridayDate.getDate() + 1));
    const sundayDate = new Date(new Date(saturdayDate).setDate(saturdayDate.getDate() + 1));

    const anytownDemoDailyMeterColumnNames = ['Technician', '1', '11/2', '2', '3', '3/4F', '3/4S', '4', '6', 'Total'];
    const anytownDemoDailyRadioColumnNames = ['Technician', 'Radio'];

    const anytownDemoDailyMeterData = [
      { "1": 0, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "aviramontes", "11/2": 18, "3/4F": 0, "3/4S": 0, "Total": 18 },
      { "1": 0, "2": 12, "3": 4, "4": 0, "6": 0, "Technician": "bsalido", "11/2": 0, "3/4F": 0, "3/4S": 0, "Total": 16 },
      { "1": 15, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "dgarrow", "11/2": 0, "3/4F": 3, "3/4S": 1, "Total": 19 },
      { "1": 0, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "doberheu", "11/2": 0, "3/4F": 0, "3/4S": 0, "Total": 0 },
      { "1": 0, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "gcamberos", "11/2": 0, "3/4F": 12, "3/4S": 8, "Total": 20 },
      { "1": 0, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "jbarcellos", "11/2": 0, "3/4F": 7, "3/4S": 14, "Total": 21 },
      { "1": 13, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "jcadena", "11/2": 0, "3/4F": 1, "3/4S": 0, "Total": 14 },
      { "1": 0, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "sgunn", "11/2": 0, "3/4F": 7, "3/4S": 8, "Total": 15 },
      { "1": 5, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "veriberto", "11/2": 0, "3/4F": 0, "3/4S": 2, "Total": 7 },
      { "1": 6, "2": 0, "3": 0, "4": 0, "6": 0, "Technician": "vvalentin", "11/2": 0, "3/4F": 0, "3/4S": 1, "Total": 7 },
      { "1": 39, "2": 12, "3": 4, "4": 0, "6": 0, "Technician": "Total", "11/2": 18, "3/4F": 30, "3/4S": 34, "Total": 137 }
    ]

    const anytownDemoDailyRadioData = [
      { "Technician": "aviramontes", "Radio": 18 },
      { "Technician": "bsalido", "Radio": 16 },
      { "Technician": "dgarrow", "Radio": 19 },
      { "Technician": "doberheu", "Radio": 0 },
      { "Technician": "gcamberos", "Radio": 20 },
      { "Technician": "jbarcellos", "Radio": 21 },
      { "Technician": "jcadena", "Radio": 14 },
      { "Technician": "sgunn", "Radio": 15 },
      { "Technician": "veriberto", "Radio": 7 },
      { "Technician": "vvalentin", "Radio": 7 },
      { "Technician": "Total", "Radio": 137 }
    ]

    const anytownDemoWeeklyData = [
      { "userName": "aviramontes", "monday": 14, "tuesday": 5, "wednesday": 11, "thursday": 17, "friday": 18, "saturday": 0, "sunday": 0, "total": 55 },
      { "userName": "bsalido", "monday": 0, "tuesday": 0, "wednesday": 0, "thursday": 14, "friday": 16, "saturday": 0, "sunday": 0, "total": 30 },
      { "userName": "dgarrow", "monday": 12, "tuesday": 3, "wednesday": 16, "thursday": 15, "friday": 19, "saturday": 0, "sunday": 0, "total": 65 },
      { "userName": "doberheu", "monday": 0, "tuesday": 0, "wednesday": 0, "thursday": 0, "friday": 0, "saturday": 0, "sunday": 0, "total": 0 },
      { "userName": "gcamberos", "monday": 16, "tuesday": 7, "wednesday": 21, "thursday": 19, "friday": 20, "saturday": 0, "sunday": 0, "total": 83 },
      { "userName": "jbarcellos", "monday": 18, "tuesday": 9, "wednesday": 13, "thursday": 12, "friday": 21, "saturday": 0, "sunday": 0, "total": 73 },
      { "userName": "jcadena", "monday": 13, "tuesday": 4, "wednesday": 15, "thursday": 14, "friday": 14, "saturday": 0, "sunday": 0, "total": 60 },
      { "userName": "sgunn", "monday": 19, "tuesday": 6, "wednesday": 17, "thursday": 16, "friday": 15, "saturday": 0, "sunday": 0, "total": 73 },
      { "userName": "veriberto", "monday": 6, "tuesday": 2, "wednesday": 8, "thursday": 6, "friday": 7, "saturday": 0, "sunday": 0, "total": 29 },
      { "userName": "vvalentin", "monday": 12, "tuesday": 4, "wednesday": 18, "thursday": 16, "friday": 7, "saturday": 0, "sunday": 0, "total": 57 },
      { "userName": "Totals", "monday": 110, "tuesday": 40, "wednesday": 119, "thursday": 129, "friday": 137, "saturday": 0, "sunday": 0, "total": 525 }
    ]

    function isActive(a) {
      let rowActive = false;
      Object.entries(a).map(([key, value]) => {
        if ((key!=="Technician")&&(key!== "userName")&&(key!== "Total")){
          if (value!==0){
            rowActive = true;
          }
        }
        return rowActive;
      });
      return rowActive;
    }
    
    const rowRender = () => (trElement, props) => {
      let active = true;
      active = isActive(props.dataItem);
      const white = {
        backgroundColor: '',
      };
      const gray = {
        backgroundColor: 'rgb(0, 0, 0, 0.1)',
      };
      const hidden = {
        display: 'none'
      }
      if (this.state.checked===true){
        const trProps = {
          style: active ? white : gray,
        };
        return React.cloneElement(
          trElement,
          { ...trProps },
          trElement.props.children
        );
      } else {
        const trProps = {
          style: active ? white : hidden,
        };
        return React.cloneElement(
          trElement,
          { ...trProps },
          trElement.props.children
        );
      } 
    };

    return (
      <div className='production-status'>
        {currentProject && currentProject.projectWorkOrderType && isWaterProject(currentProject.projectWorkOrderType) && (
          <React.Fragment>
            {dailyProductionStatusHasMeter &&
              <React.Fragment>
                {isDemoProject(currentProject.id) ? (
                  <React.Fragment>
                    <Grid data={anytownDemoDailyMeterData} rowRender={rowRender(true)}>
                      <GridToolbar>
                          <div>
                              <b className='workorder-production-status-table-title'>Meter - Today's Activity</b>
                          </div>
                          <div>
                              <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
                              <Checkbox toggle checked={this.state.checked} onChange={this.handleChange} />
                          </div>
                      </GridToolbar>
                      <GridNoRecords>
                        Loading data...
                      </GridNoRecords>
                      {anytownDemoDailyMeterColumnNames.map((column, index) => <Column cell={CustomCell} field={column} title={column} width={index === 0 ? '240px' : '100px'} key={index} />)}
                    </Grid>
                    <br />
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      {dailyProductionStatusRowsByMeterType.length > 0 && <Grid data={dailyProductionStatusRowsByMeterType} rowRender={rowRender(this.state.checked)}>
                        <GridToolbar>
                          <div>
                              <b className='workorder-production-status-table-title'>Meter - Today's Activity</b>
                          </div>
                          <div>
                              <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
                              <Checkbox toggle checked={this.state.checked} onChange={this.handleChange} />
                          </div>
                        </GridToolbar>
                        <GridNoRecords>
                          Loading data...
                      </GridNoRecords>
                        {dailyProductionStatusColumnsByMeterType.map((column, index) => <Column cell={CustomCell} field={column} title={column} width={index === 0 ? '240px' : '100px'} key={index} />)}
                      </Grid>}
                      <br />
                    </React.Fragment>
                  )}
              </React.Fragment>}

            {dailyProductionStatusHasRegister && dailyProductionStatusRowsByRegisterType.length > 0 &&
              <React.Fragment>
                <Grid data={dailyProductionStatusRowsByRegisterType} rowRender={rowRender(true)}>
                  <GridToolbar>
                    <div>
                      <b className='workorder-production-status-table-title'>Register - Today's Activity</b>
                    </div>
                    <div>
                        <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
                        <Checkbox toggle checked={this.state.checked} onChange={this.handleChange} />
                    </div>
                  </GridToolbar>
                  <GridNoRecords>
                    Loading data...
                    </GridNoRecords>
                  {dailyProductionStatusColumnsByRegisterType.map((column, index) => {
                    return (
                      <Column cell={CustomCell} field={column} title={column} width={index === 0 ? '240px' : '100px'} key={index} />
                    )
                  })}
                </Grid>
                <br />
              </React.Fragment>}

            {dailyProductionStatusHasRadio &&
              <React.Fragment>
                {isDemoProject(currentProject.id) ? (
                  <React.Fragment>
                    <Grid data={anytownDemoDailyRadioData} rowRender={rowRender(true)}>
                      <GridToolbar>
                      <div>
                        <b className='workorder-production-status-table-title'>Radio - Today's Activity</b>
                      </div>
                      <div>
                          <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
                          <Checkbox toggle checked={this.state.checked} onChange={this.handleChange} />
                      </div>
                      </GridToolbar>
                      <GridNoRecords>
                        Loading data...
                  </GridNoRecords>
                      {anytownDemoDailyRadioColumnNames.map((column, index) => <Column cell={CustomCell} field={column} title={column} width={index === 0 ? '240px' : '100px'} key={index} />)}
                    </Grid>
                    <br />
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      {dailyProductionStatusRowsByRadioType.length > 0 && <Grid data={dailyProductionStatusRowsByRadioType} rowRender={rowRender(true)}>
                        <GridToolbar>
                          <b className='workorder-production-status-table-title'>Radio - Today's Activity</b>
                    <div>
                        <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
                        <Checkbox toggle checked={this.state.checked} onChange={this.handleChange} />
                    </div>
                        </GridToolbar>
                        <GridNoRecords>
                          Loading data...
                    </GridNoRecords>
                        {dailyProductionStatusColumnsByRadioType.map((column, index) => <Column cell={CustomCell} field={column} title={column} width={index === 0 ? '240px' : '100px'} key={index} />)}
                      </Grid>}
                      <br />
                    </React.Fragment>
                  )}
              </React.Fragment>}
          </React.Fragment>
        )
        }

        {/* Non Serialized */}
        {dailyProductionStatusByNonSerializedInventory &&
          <>
            {Object.keys(dailyProductionStatusByNonSerializedInventory).map(type => (
              <React.Fragment key={type}>
                <Grid data={dailyProductionStatusByNonSerializedInventory[type].rows} rowRender={rowRender(true)}>
                  <GridToolbar>
                      <b className='workorder-production-status-table-title'>{type} - Today's Activity</b>
                    <div>
                        <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
                        <Checkbox toggle checked={this.state.checked} onChange={this.handleChange} />
                    </div>
                  </GridToolbar>
                  <GridNoRecords>
                    Loading data...
                </GridNoRecords>
                  {dailyProductionStatusByNonSerializedInventory[type].columnNames.map((column, index) => <Column cell={CustomCell} field={column} title={column} width={index === 0 ? '240px' : '120px'} key={index} />)}
                </Grid>
                <br />
              </React.Fragment>
            ))}
          </>}

        <Grid data={isDemoProject(currentProject.id) ? anytownDemoWeeklyData : weeklyProductionStatus} rowRender={rowRender(true)}>
          <GridToolbar>
            <b className='workorder-production-status-table-title'>Weekly Activity</b>
            <div style={{ display: 'flex', flexGrow: 1}}>
                <label style={{ paddingLeft: '15px', paddingRight: '15px', color: 'white' }}>Show Inactive</label>
                <Checkbox toggle checked={this.state.checked} onChange={this.handleChange} />
            </div>
            <div>
              <div>
                <Button onClick={this.onWeeklyProductionStatusWeekPrevious} disabled={weeklyProductionStatusWeek === -28} color='blue' content='Previous' icon='left arrow' labelPosition='left' />
                <Button onClick={this.onWeeklyProductionStatusWeekNext} disabled={weeklyProductionStatusWeek === 0} color='blue' content='Next' icon='right arrow' labelPosition='right' />
              </div>
            </div>
          </GridToolbar>
          <GridNoRecords>
            Loading data...
            </GridNoRecords>
          <Column field='userName' title='Installer' width='240px' />
          <Column field='monday' title={this.getWeeklyTableColumnLabel(mondayDate)} width='120px' />
          <Column field='tuesday' title={this.getWeeklyTableColumnLabel(tuesdayDate)} width='120px' />
          <Column field='wednesday' title={this.getWeeklyTableColumnLabel(wednesdayDate)} width='120px' />
          <Column field='thursday' title={this.getWeeklyTableColumnLabel(thursdayDate)} width='120px' />
          <Column field='friday' title={this.getWeeklyTableColumnLabel(fridayDate)} width='120px' />
          <Column field='saturday' title={this.getWeeklyTableColumnLabel(saturdayDate)} width='120px' />
          <Column field='sunday' title={this.getWeeklyTableColumnLabel(sundayDate)} width='120px' />
          <Column field='total' title='Total' width='100px' />
        </Grid>
      </div >
    );
  }
}

export default ProductionStatus;