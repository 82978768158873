import React from 'react';
import { Menu, Header, Icon, Label } from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react'
import AppContext from '../../../core/context/app.context';

const TopMenu = ({ config, style = {}, rootStyle = undefined }) => (
  <div style={rootStyle ? rootStyle : { display: 'flex', marginBottom: '14px', zIndex: 1 }}>
    <Menu stackable style={{ ...style, flex: '1' }}>
      <Menu.Item>
        <AppContext.Consumer>{({ toggleSideMenu }) => (
          <Icon name='list' onClick={toggleSideMenu} style={{ cursor: 'pointer', fontSize: '23px' }} />
        )}</AppContext.Consumer>
      </Menu.Item>
      <Menu.Item>
        <Header><Icon name={config.header.iconName} />{config.header.title}</Header>
      </Menu.Item>
      {config.tabs.map((tab, key) => tab.refresh ? (
        <Menu.Menu position='right' key={key}>
          <Menu.Item
            active={false}
            onClick={tab.onClick}
          >
            <Icon style={{ margin: 0 }} name='refresh' />
          </Menu.Item>
        </Menu.Menu>
      ) : (
          <Menu.Item
            key={key}
            active={tab.active}
            onClick={tab.onClick}
          >
            {tab.iconName ? <Icon name={tab.iconName} /> : null}
            {tab.title}
            {tab.loader && tab.loader.loading ? <div style={{ paddingLeft: '5px' }}><Loader active inline size='small' /></div> :
              tab.label ? <Label color={tab.label.color}>{tab.label.value}</Label> : null}
          </Menu.Item>
        ))}
    </Menu>
  </div>
)

export default TopMenu;