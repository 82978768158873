import { SET_ERROR, SET_UPDATE_DETAILS_REQUIRED, SET_DATA, SET_LOADING, SET_NEW_APPOINTMENT, SET_SHOW_APPOINTMENT_ALREADY_EXIST_MODAL, SET_WORKORDER_NOT_FOUND_ERROR, SET_VALIDATING, SET_VALIDATION_MESSAGE, RESET_SCHEDULER_STATE } from '../types/scheduler.types';

const initialState = {
  workOrderNotFoundError: false,
  showAppointmentAlreadyExistModal: false,
  newAppointment: null,
  data: [],
  loading: false,
  updateDetailsRequired: false,
  error: null,
  validating: false,
}

const schedulerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALIDATING:
      return { ...state, validating: action.payload };
    case SET_VALIDATION_MESSAGE:
      return { ...state, validationMessage: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_UPDATE_DETAILS_REQUIRED:
      return { ...state, updateDetailsRequired: action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_DATA:
      return { ...state, data: action.payload };
    case SET_NEW_APPOINTMENT:
      return { ...state, newAppointment: action.payload };
    case SET_SHOW_APPOINTMENT_ALREADY_EXIST_MODAL:
      return { ...state, showAppointmentAlreadyExistModal: action.payload };
    case SET_WORKORDER_NOT_FOUND_ERROR:
      return { ...state, workOrderNotFoundError: action.payload };
    case RESET_SCHEDULER_STATE:
      return { ...initialState }
    default:
      return state;
  }
}

export default schedulerReducer;