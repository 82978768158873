import React from 'react';
import { Button, Form, Segment, Image } from 'semantic-ui-react'
import ErrorMessage from '../../../../shared/components/messages/error';
import LOGO from '../../../../assets/login-logo.png';

const LoginForm = ({
  handleSubmit,
  onUsernameChange,
  onPasswordChange,
  error,
  loading,
  onShowSendVerificationCodeForm,
  setShowPrivacyPolicyModal,
  setShowTermsOfUseModal,
  showPassword
}) => (
    <div className='login-form'>
      <Segment raised padded='very' size='massive' className='login-form-container'>
        <div style={{ display: 'flex', justifyContent: 'center' }}><Image src={LOGO} className='login-logo' /></div>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            {/* <Form.Input label={<b>Username <i>(or SSO-Email)</i> <span style={{ color: 'red' }}>*</span></b>} placeholder='Username' onChange={onUsernameChange} required /> */}
            <Form.Input label={<b>Username <span style={{ color: 'red' }}>*</span></b>} placeholder='Username' onChange={onUsernameChange} required />
          </Form.Field>
          {showPassword && <Form.Field>
            <Form.Input type='password' label={<b>Password</b>} placeholder='Password' onChange={onPasswordChange} required />
          </Form.Field>}
          {error ? <ErrorMessage message={error.message} /> : null}
          <div className='sign-in-button'>
            <Button style={{ margin: 0 }} loading={loading} primary type='submit'>Log in</Button>
          </div>
          <p style={{ textAlign: 'center' }}>By clicking Log in, you agree to our <span style={{ cursor: 'pointer', color: 'rgb(0, 153, 255)' }} onClick={() => setShowTermsOfUseModal(true)}>Terms of Use</span><br />and have read and acknowledge our <span onClick={() => setShowPrivacyPolicyModal(true)} style={{ cursor: 'pointer', color: 'rgb(0, 153, 255)' }}>Privacy Policy.</span></p>
          {showPassword && <p onClick={onShowSendVerificationCodeForm} style={{ color: '#0099ff', cursor: 'pointer', textAlign: 'center', marginTop: '30px' }}>Reset/Forgot Password</p>}
        </Form>
      </Segment>
    </div>
  );

export default LoginForm;