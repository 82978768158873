import React from 'react';
import { Button, Form, Segment, Image } from 'semantic-ui-react'
import ErrorMessage from '../../../../shared/components/messages/error';
import LOGO from '../../../../assets/login-logo.png';

const SendVerificationCode = ({ handleSubmit, onUsernameChange, error, loading, onHideSendVerificationCodeForm }) => (
  <div className='login-form'>
    <Segment raised padded='very' size='massive' className='send-verification-code-form-container'>
      <div style={{ display: 'flex', justifyContent: 'center' }}><Image src={LOGO} className='login-logo' /></div>
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Form.Input label='Username' placeholder='Username' onChange={onUsernameChange} required />
        </Form.Field>
        {error ? <ErrorMessage message={error.message} /> : null}
        <div className='sign-in-button'>
          <Button style={{ margin: 0 }} loading={loading} primary type='submit'>Send verification code</Button>
          <Button style={{ margin: 0, marginLeft: '10px' }} type='button' onClick={onHideSendVerificationCodeForm}>Cancel</Button>
        </div>
      </Form>
    </Segment>
  </div>
);

export default SendVerificationCode;