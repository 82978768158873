import React from 'react';
import { Button, Form, Segment, Image } from 'semantic-ui-react'
import ErrorMessage from '../../../../shared/components/messages/error';
import LOGO from '../../../../assets/login-logo.png';
import Message from '../../../../shared/components/messages/message';

const ChangePassword = ({
  handleSubmit,
  onVerificationCodeChange,
  onNewPasswordChange,
  onConfirmNewPasswordChange,
  error,
  loading,
  onHideChangePasswordForm,
  showPasswordSuccessfullyChangedMessage
}) => (
    <div className='login-form'>
      <Segment raised padded='very' size='massive' className='change-password-form-container'>
        <div style={{ display: 'flex', justifyContent: 'center' }}><Image src={LOGO} className='login-logo' /></div>
        {showPasswordSuccessfullyChangedMessage ? <Message message='Password successfully changed, you will be redirected to the login page...' /> :
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <Form.Input name='VerificationCode' label='Verification Code' placeholder='Verification Code' onChange={onVerificationCodeChange} required />
            </Form.Field>
            <Form.Field>
              <Form.Input name='NewPassword' type='password' label='New Password' placeholder='New Password' onChange={onNewPasswordChange} required />
            </Form.Field>
            <Form.Field>
              <Form.Input name='ConfirmNewPassword' type='password' label='Confirm Password' placeholder='New Password' onChange={onConfirmNewPasswordChange} required />
            </Form.Field>
            {error ? <ErrorMessage message={error.message} /> : null}
            <div className='sign-in-button'>
              <Button style={{ margin: 0 }} loading={loading} primary type='submit'>Change Password</Button>
              <Button style={{ margin: 0, marginLeft: '10px' }} type='button' onClick={onHideChangePasswordForm}>Cancel</Button>
            </div>
          </Form>}
      </Segment>
    </div>
  );

export default ChangePassword;