import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Input, Modal } from 'semantic-ui-react';
import REPORT_TABLE_COLUMNS from '../../../../constants/reportTableColumns';

const ExportReportTemplateModal = ({ showModal, closeModal, workOrderType, onSaveTemplate, selectedReportTemplate }) => {
  const [template, setTemplate] = useState({
    table_column_name: '',
    column_name: '',
  });

  useEffect(() => {
    if (selectedReportTemplate) {
      const { key, override, same_as, default_to_column, suffix, prefix, table_column_name, column_name, link, bidirectional_meter } = selectedReportTemplate;
      const templateState = {};
      if (key !== null && key !== undefined) {
        templateState.key = key;
      }
      if (override !== null && override !== undefined) {
        templateState.override = override;
      }
      if (same_as !== null && same_as !== undefined) {
        templateState.same_as = same_as;
      }
      if (default_to_column !== null && default_to_column !== undefined) {
        templateState.default_to_column = default_to_column;
      }
      if (suffix !== null && suffix !== undefined) {
        templateState.suffix = suffix;
      }
      if (prefix !== null && prefix !== undefined) {
        templateState.prefix = prefix;
      }
      if (table_column_name !== null && table_column_name !== undefined) {
        templateState.table_column_name = table_column_name;
      }
      if (column_name !== null && column_name !== undefined) {
        templateState.column_name = column_name;
      }
      if (link !== null && link !== undefined) {
        templateState.link = link;
      }
      if (bidirectional_meter !== null && bidirectional_meter !== undefined) {
        const generation = { ...bidirectional_meter.generation };
        templateState.bidirectional_meter = { ...bidirectional_meter, generation };
      }
      setTemplate(templateState);
    }
  }, []);

  const onTableColumNameChange = (event, { value }) => {
    if (value === 'customDataFields') {
      setTemplate({ ...template, table_column_name: value, key: '' });
    } else {
      delete template['key'];
      setTemplate({ ...template, table_column_name: value });
    }
  }

  const onOverrideOptionChange = (event, { checked }) => {
    if (checked) {
      template.override = '';
    } else {
      delete template['override'];
    }
    setTemplate({ ...template });
  }

  const onOverrideChange = (event, { value }) => {
    setTemplate({ ...template, override: value });
  }

  const onSameAsOptionChange = (event, { checked }) => {
    if (checked) {
      template.same_as = '';
    } else {
      delete template['same_as'];
    }
    setTemplate({ ...template });
  }

  const onSameAsChange = (event, { value }) => {
    setTemplate({ ...template, same_as: value });
  }

  const onDefaultToColumnOptionChange = (event, { checked }) => {
    if (checked) {
      template.default_to_column = '';
    } else {
      delete template['default_to_column'];
    }
    setTemplate({ ...template });
  }

  const onDefaultToColumnChange = (event, { value }) => {
    setTemplate({ ...template, default_to_column: value });
  }

  const onDefaultToValueOptionChange = (event, { checked }) => {
    if (checked) {
      template.default_to = '';
    } else {
      delete template['default_to'];
    }
    setTemplate({ ...template });
  }

  const onDefaultToValueChange = (event, { value }) => {
    setTemplate({ ...template, default_to: value });
  }

  const onSuffixOptionChange = (event, { checked }) => {
    if (checked) {
      template.suffix = '';
    } else {
      delete template['suffix'];
    }
    setTemplate({ ...template });
  }

  const onSuffixChange = (event, { value }) => {
    setTemplate({ ...template, suffix: value });
  }

  const onPrefixOptionChange = (event, { checked }) => {
    if (checked) {
      template.prefix = '';
    } else {
      delete template['prefix'];
    }
    setTemplate({ ...template });
  }

  const onPrefixChange = (event, { value }) => {
    setTemplate({ ...template, prefix: value });
  }

  const onLinkOptionChange = (event, { checked }) => {
    if (checked) {
      template.link = true;
    } else {
      delete template['link'];
    }
    setTemplate({ ...template });
  }

  const onBidirectionalMeterOptionChange = (event, { checked }) => {
    if (checked) {
      template.bidirectional_meter = {
        generation: {
          table_column_name: ''
        },
        net: 0
      }
    } else {
      delete template['bidirectional_meter'];
    }
    setTemplate({ ...template });
  }

  const onGenerationTableColumnChange = (event, { value }) => {
    const tempTamplate = { ...template };
    tempTamplate.bidirectional_meter.generation.table_column_name = value;
    if (value === 'customDataFields') {
      tempTamplate.bidirectional_meter.generation.key = '';
    } else {
      delete tempTamplate.bidirectional_meter.generation['key'];
    }
    setTemplate({ ...tempTamplate });
  }

  const onGenerationCustomDataFieldKeyChange = (event, { value }) => {
    const tempTamplate = { ...template };
    tempTamplate.bidirectional_meter.generation.key = value;
    setTemplate({ ...tempTamplate });
  }

  const onNetChange = (event, { value }) => {
    const tempTamplate = { ...template };
    tempTamplate.bidirectional_meter.net = value;
    setTemplate({ ...tempTamplate });
  }

  const onCustomDataFieldKeyChange = (event, { value }) => {
    setTemplate({ ...template, key: value });
  }

  const onFileColumnChange = (event, { value }) => {
    setTemplate({ ...template, column_name: value });
  }

  const getTableColumns = () => {
    return REPORT_TABLE_COLUMNS[workOrderType].map(column => Object.assign({}, { key: column, text: column, value: column }));
  }

  const onSubmit = () => {
    if (selectedReportTemplate) {
      onSaveTemplate({ ...template }, selectedReportTemplate.id);
    } else {
      onSaveTemplate({ ...template });
    }
  }

  return (
    <Modal dimmer='blurring' size='tiny' open={showModal}>
      <Modal.Header>{selectedReportTemplate ? 'Update Template' : 'New Template'}</Modal.Header>
      <Modal.Content>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
          <div style={{ marginBottom: '10px' }}>
            <span style={{ paddingRight: '10px' }}>Table Column:</span>
            <Dropdown
              search
              placeholder='Table Column'
              selection
              value={template.table_column_name}
              className='export-dropdown'
              onChange={onTableColumNameChange}
              options={getTableColumns()} />
          </div>
          {template.hasOwnProperty('key') && <div className='export-template-option'>
            <span style={{ paddingRight: '10px' }}>Custom Data Field Key:</span>
            <Input placeholder='Custom Data Field Key' onChange={onCustomDataFieldKeyChange} value={template.key} />
          </div>}
          <div style={{ marginBottom: '10px' }}>
            <span style={{ paddingRight: '10px' }}>File Column:</span>
            <Input placeholder='File Column' onChange={onFileColumnChange} value={template.column_name} />
          </div>
          <div className='export-template-option'>
            <Checkbox checked={template.override !== null && template.override !== undefined} label='Override' toggle className='export-template-option-checkbox' onChange={onOverrideOptionChange} />
            {template.hasOwnProperty('override') && <Input value={template.override} placeholder='Override' onChange={onOverrideChange} />}
          </div>
          <div className='export-template-option'>
            <Checkbox checked={template.same_as !== null && template.same_as !== undefined} label='Same As' toggle className='export-template-option-checkbox' onChange={onSameAsOptionChange} />
            {template.hasOwnProperty('same_as') && <Dropdown
              value={template.same_as}
              search
              placeholder='Same As'
              onChange={onSameAsChange}
              selection
              className='export-dropdown'
              options={getTableColumns()} />}
          </div>
          <div className='export-template-option'>
            <Checkbox label='Default To Column' toggle checked={template.default_to_column !== null && template.default_to_column !== undefined} className='export-template-option-checkbox' onChange={onDefaultToColumnOptionChange} />
            {template.hasOwnProperty('default_to_column') && <Dropdown
              value={template.default_to_column}
              search
              placeholder='Default To Column'
              onChange={onDefaultToColumnChange}
              selection
              className='export-dropdown'
              options={getTableColumns()} />}
          </div>
          <div className='export-template-option'>
            <Checkbox checked={template.default_to !== null && template.default_to !== undefined} label='Default To Value' toggle className='export-template-option-checkbox' onChange={onDefaultToValueOptionChange} />
            {template.hasOwnProperty('default_to') && <Input value={template.default_to} placeholder='Default To Value' onChange={onDefaultToValueChange} />}
          </div>
          <div className='export-template-option'>
            <Checkbox label='Prefix' checked={template.prefix !== null && template.prefix !== undefined} toggle className='export-template-option-checkbox' onChange={onPrefixOptionChange} />
            {template.hasOwnProperty('prefix') && <Input value={template.prefix} placeholder='Prefix' onChange={onPrefixChange} />}
          </div>
          <div className='export-template-option'>
            <Checkbox label='Suffix' checked={template.suffix !== null && template.suffix !== undefined} toggle className='export-template-option-checkbox' onChange={onSuffixOptionChange} />
            {template.hasOwnProperty('suffix') && <Input value={template.suffix} placeholder='Suffix' onChange={onSuffixChange} />}
          </div>
          <div className='export-template-option'>
            <Checkbox label='Link' checked={template.link !== null && template.link !== undefined && template.link} toggle className='export-template-option-checkbox' onChange={onLinkOptionChange} />
          </div>
          <div className='export-template-option'>
            <Checkbox label='Bidirectional Meter' checked={template.hasOwnProperty('bidirectional_meter')} toggle className='export-template-option-checkbox' onChange={onBidirectionalMeterOptionChange} />
          </div>
          {template.hasOwnProperty('bidirectional_meter') && <div>
            <span style={{ paddingRight: '10px' }}>Generation:</span>
            <Dropdown
              search
              placeholder='Generation'
              selection
              value={template.bidirectional_meter.generation.table_column_name}
              className='export-dropdown'
              onChange={onGenerationTableColumnChange}
              options={getTableColumns()} />
          </div>}
          {template.hasOwnProperty('bidirectional_meter') && template.bidirectional_meter.generation.hasOwnProperty('key') && <div style={{ paddingTop: 10 }}>
            <span style={{ paddingRight: '10px' }}>Key:</span>
            <Input value={template.bidirectional_meter.generation.key} placeholder='Custom Data Key' onChange={onGenerationCustomDataFieldKeyChange} />
          </div>}
          {template.hasOwnProperty('bidirectional_meter') && <div style={{ paddingTop: 10 }}>
            <span style={{ paddingRight: '10px' }}>Net:</span>
            <Input type='number' value={template.bidirectional_meter.net} placeholder='Net' onChange={onNetChange} />
          </div>}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Save' />
      </Modal.Actions>
    </Modal>
  )
}

export default ExportReportTemplateModal;