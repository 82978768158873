import React, { useContext, useEffect, useRef, useState } from 'react';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import WORKORDER_TABALE_COLUMNS from '../../../../constants/tableColumns';
import WORKORDER_TABALES from '../../../../constants/workOrderTables';
import { Button, Checkbox, Dropdown, Segment, Table, Input, Icon } from 'semantic-ui-react';
import AppContext from '../../../../core/context/app.context';
import { updateProject } from '../../../../core/services/project.service';
import NewFileColumnModal from './importStepNewFileColumnModal';
import SuccessMessage from '../../../../shared/components/messages/message';
import ErrorMessage from '../../../../shared/components/messages/error';
import JoinColumnsModal from './importStepJoinColumnsModal';
import LookupsModal from './importLookupsModal';
import './styles.css';

const importKeysTemplate = {
  file_key: '',
  table_key: ''
}

const getWorkOrderTypeTable = (type) => {
  return type === WORKORDER_TYPES.MeterDeploy || type === WORKORDER_TYPES.WaterMeterDeploy ? WORKORDER_TABALES.WorkOrderMeterDeploy : WORKORDER_TABALES.WorkOrderWaterDeploy;
}

const getProjectImportConfigSchema = (project) => {
  const workOrderType = project.projectWorkOrderType;
  const workOrderTypeTable = getWorkOrderTypeTable(workOrderType);
  return {
    keys: {
      [workOrderType]: {
        file_key: '',
        table_key: ''
      }
    },
    tables: {
      WorkOrder: {
        sync: [],
        columns: {}
      },
      [workOrderTypeTable]: {
        sync: [],
        columns: {}
      }
    }
  }
}

const ImportStep = ({ project, onSetProject }) => {
  let fileReader;
  const modalRef = useRef();
  const fileInputRef = useRef();

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [dataMapping, setDataMapping] = useState([]);
  const [importKeys, setImportKeys] = useState({ ...importKeysTemplate });
  const [skipExchangedByUtility, setSkipExchangedByUtility] = useState(false);
  const [reuse, setReuse] = useState(false);
  const [reuseIgnoreRTU, setReuseIgnoreRTU] = useState(false);
  const [updateInReview, setUpdateInReview] = useState(false);
  const [updateEscalated, setUpdateEscalated] = useState(false);
  const [skipNewInventoryCheck, setSkipNewInventoryCheck] = useState(false);
  const [fileColumnNames, setFileColumnNames] = useState([]);
  const [tableColumnNames, setTableColumnNames] = useState([]);
  const [workOrderType, setWorkOrderType] = useState('');
  const [projectImportConfig, setProjectImportConfig] = useState(getProjectImportConfigSchema(project));
  const [error, setError] = useState(null);
  const [showEdit, setShowEdit] = useState(undefined);
  const [exchangedLimit, setExchangedLimit] = useState('');
  const [showExchangedLimit, setShowExchangedLimit] = useState(false);
  const [showNewFileColumnModal, setShowNewFileColumnModal] = useState(false);
  const [showJoinColumnsModal, setShowJoinColumnsModal] = useState(false);
  const [showLookupsModal, setShowLookupsModal] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [autoImport, setAutoImport] = useState(false);
  const [lookups, setLookups] = useState(undefined);
  const [trimWhitespace, setTrimWhitespace] = useState(false);

  useEffect(() => {
    if (successNotification || errorNotification) {
      setTimeout(() => {
        setSuccessNotification(false);
        setErrorNotification(false);
      }, 3000);
    }
  }, [successNotification, errorNotification]);

  useEffect(() => {
    setAutoImport(project.autoImport || false);
    setWorkOrderType(project.projectWorkOrderType);
    if (project.projectImportConfig) {
      const oldProjectImportConfig = JSON.parse(project.projectImportConfig);
      //console.log('##PROP IMPORT CONFIG ON LOAD..', oldProjectImportConfig);
      setProjectImportConfig({ ...oldProjectImportConfig });
      const {
        skip_exchanged_by_utility,
        keys,
        tables,
        skip_new_inventory_check,
        exchanged_limit,
        reuse,
        reuse_ignore_rtu,
        lookups,
        update_inreview,
        update_escalated, 
        trim_whitespace
      } = oldProjectImportConfig;
      if (lookups) {
        setLookups(lookups);
      }
      setReuse(reuse);
      setReuseIgnoreRTU(reuse_ignore_rtu);
      setUpdateEscalated(update_escalated);
      setUpdateInReview(update_inreview);
      setTrimWhitespace(trim_whitespace);
      setSkipExchangedByUtility(skip_exchanged_by_utility);
      setSkipNewInventoryCheck(skip_new_inventory_check);
      if (typeof (exchanged_limit) === 'number') {
        setShowExchangedLimit(true);
        setExchangedLimit(exchanged_limit);
      }
      const oldWorkOrderType = Object.keys(keys)[0];
      setWorkOrderType(oldWorkOrderType);
      let oldDataMapping = [];
      let oldFileColumnNames = [];
      Object.keys(tables).forEach(tableName => {
        oldFileColumnNames = [...oldFileColumnNames, ...Object.values(tables[tableName].columns).flatMap(i => i)];
        Object.keys(tables[tableName].columns).forEach(tableColumnName => {
          const updatable = tables[tableName].sync.indexOf(tableColumnName) > -1;
          if (tableColumnName === 'customDataFields') {
            const custom = [];
            tables[tableName].columns[tableColumnName].forEach(fileColumnName => {
              custom.push({ fileColumnName, tableColumnName, updatable });
            });
            oldDataMapping = [...oldDataMapping, ...custom];
          } else {
            const fileColumnName = tables[tableName].columns[tableColumnName];
            oldDataMapping = [...oldDataMapping, { fileColumnName, tableColumnName, updatable }];
          }
        });
      });

      const workOrderNumberIndex = oldDataMapping.findIndex(i => i.tableColumnName === 'workOrderNumber');
      if (workOrderNumberIndex > -1) {
        let workOrderNumberMapping = oldDataMapping[workOrderNumberIndex];
        oldDataMapping = oldDataMapping.filter(i => i.tableColumnName !== 'workOrderNumber');
        oldDataMapping.unshift(workOrderNumberMapping);
        const workOrderNumberFileColumnName = oldFileColumnNames[workOrderNumberIndex];
        oldFileColumnNames.splice(workOrderNumberIndex, 1);
        oldFileColumnNames.unshift(workOrderNumberFileColumnName);
      }

      setDataMapping(oldDataMapping);
      setFileColumnNames(oldFileColumnNames);
      const workOrderTypeTable = getWorkOrderTypeTable(oldWorkOrderType);
      setTableColumnNames([...WORKORDER_TABALE_COLUMNS[WORKORDER_TABALES.WorkOrder], ...WORKORDER_TABALE_COLUMNS[workOrderTypeTable]]);
      setImportKeys({ ...keys[oldWorkOrderType] });
    }
  }, []);

  const fileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      fileReader = new FileReader();
      fileReader.onloadend = onFileRead;
      fileReader.readAsText(file);
    }
  }

  const onFileRead = () => {
    const content = fileReader.result;
    const columnNames = content.split('\n')[0].split(',').map(columnName => columnName.replace(/"/g, "").replace(/'/g, ""));
    const workOrderTypeTable = getWorkOrderTypeTable(workOrderType);
    setFileColumnNames([]);
    const newDataMapping = columnNames.map(fileColumnName => Object.assign({}, { fileColumnName, tableColumnName: '', updatable: false }));
    newDataMapping.unshift({ fileColumnName: '', tableColumnName: 'workOrderNumber', updatable: false });
    setDataMapping(newDataMapping);
    setFileColumnNames(columnNames);
    setImportKeys({ ...importKeysTemplate });
    setSkipExchangedByUtility(false);
    setReuse(false);
    setLookups(undefined);
    setReuseIgnoreRTU(false);
    setSkipNewInventoryCheck(false);
    setShowExchangedLimit(false);
    setExchangedLimit(null);
    setProjectImportConfig({
      keys: {
        [workOrderType]: {
          file_key: '',
          table_key: ''
        }
      },
      tables: {
        WorkOrder: {
          sync: [],
          columns: {}
        },
        [workOrderTypeTable]: {
          sync: [],
          columns: {}
        }
      }
    });
    setTableColumnNames([...WORKORDER_TABALE_COLUMNS[WORKORDER_TABALES.WorkOrder], ...WORKORDER_TABALE_COLUMNS[workOrderTypeTable]]);
  }

  const onTableColumnNameChange = (event, { value: tableColumnName }, index) => {
    const newDataMapping = dataMapping;
    const newDataMappingItem = dataMapping[index];
    newDataMappingItem.tableColumnName = tableColumnName;
    setDataMapping([...newDataMapping]);
  }

  const onUpdatableChange = (event, { checked }, index) => {
    const newDataMapping = dataMapping;
    const newDataMappingItem = dataMapping[index];
    newDataMappingItem.updatable = checked;
    setDataMapping([...newDataMapping]);
  }

  const onImportKeysChange = (event, { value }, key) => {
    setImportKeys({ ...importKeys, [key]: value });
  }

  const onSkipExchangedByUtilityChange = (event, { checked }) => {
    setSkipExchangedByUtility(checked);
  }

  const onReuseChange = (event, { checked }) => {
    setReuse(checked);
    if (!checked) {
      setReuseIgnoreRTU(false);
    }
  }

  const onReuseIgnoreRTU = (event, { checked }) => {
    setReuseIgnoreRTU(checked);
  }

  const onTrimWhitespace = (event, { checked }) => {
    setTrimWhitespace(checked);
  }

  const onSkipNewInventoryCheckChange = (event, { checked }) => {
    setSkipNewInventoryCheck(checked);
  }

  const onShowExchangedLimitChange = (event, { checked }) => {
    setShowExchangedLimit(checked);
  }

  const onExchangedLimitChange = (event, { value }) => {
    setExchangedLimit(+value);
  }

  const onAutoImportChange = (event, { checked }) => {
    setAutoImport(checked);
  }

  const onSave = async () => {
    let hasError = false;
    setLoading(true);
    setError(null);
    setSuccessNotification(false);
    setErrorNotification(false);
    // validations
    for (let [index, item] of dataMapping.entries()) {
      if (!item.tableColumnName) {
        setError({ message: `Table column name is missing in a row ${index + 1}` });
        hasError = true;
      }
      if (!item.fileColumnName) {
        setError({ message: `File column name is missing in a row ${index + 1}` });
        hasError = true;
      }
    }

    if (!importKeys.file_key) {
      setError({ message: 'File key is missing' });
      hasError = true;
    }
    if (!importKeys.table_key) {
      setError({ message: `Table key is missing` });
      hasError = true;
    }
    if (reuse) {
      if (importKeys.table_key !== 'locationId') {
        setError({ message: 'Primary key must be locationId' });
        hasError = true;
      }
      dataMapping.forEach(dataItem => {
        if (dataItem.tableColumnName === 'workOrderNumber' && dataItem.updatable) {
          setError({ message: `Table column workOrderNumber shouldn't be updatable` });
          hasError = true;
        }
        if (dataItem.tableColumnName === 'locationId' && dataItem.updatable) {
          setError({ message: `Table column locationId shouldn't be updatable` });
          hasError = true;
        }
      });
    }

    if (!hasError) {
      const workOrderTableColumns = [...WORKORDER_TABALE_COLUMNS[WORKORDER_TABALES.WorkOrder]];
      const workOrderTypeTableColumns = [...WORKORDER_TABALE_COLUMNS[getWorkOrderTypeTable(workOrderType)]];
      //console.log('##DATA MAPPING', dataMapping);
      //console.log('##DATA tables', workOrderTableColumns);
      //console.log('##DATA types', workOrderTypeTableColumns);

      const tempProjectImportConfig = { ...getProjectImportConfigSchema(project) };

      tempProjectImportConfig.keys = {
        [workOrderType]: importKeys
      }

      tempProjectImportConfig.tables.WorkOrder.sync = [...new Set(
        dataMapping.filter(item => workOrderTableColumns.indexOf(item.tableColumnName) > -1 && item.updatable).map(item => item.tableColumnName)
      )];
      tempProjectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].sync = [...new Set(
        dataMapping.filter(item => workOrderTypeTableColumns.indexOf(item.tableColumnName) > -1 && item.updatable).map(item => item.tableColumnName)
      )];

      dataMapping.forEach(item => {
        if (workOrderTableColumns.indexOf(item.tableColumnName) > -1) {
          if (item.tableColumnName === 'customDataFields') {
            tempProjectImportConfig.tables.WorkOrder.columns.customDataFields = [...tempProjectImportConfig.tables.WorkOrder.columns.customDataFields || [], item.fileColumnName];
          } else {
            tempProjectImportConfig.tables.WorkOrder.columns[item.tableColumnName] = item.fileColumnName;
          }
        } else {
          tempProjectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].columns[item.tableColumnName] = item.fileColumnName;
        }
      });



      const tableKeys = Object.keys(projectImportConfig.tables);
      for(let key of tableKeys){
        if(projectImportConfig.tables[key].concat) tempProjectImportConfig.tables[key].concat = projectImportConfig.tables[key].concat;
      }



      if (reuse) {
        tempProjectImportConfig.reuse = true;
      } else {
        delete tempProjectImportConfig['reuse'];
      }
      if (reuseIgnoreRTU) {
        tempProjectImportConfig.reuse_ignore_rtu = true;
      } else {
        delete tempProjectImportConfig['reuse_ignore_rtu'];
      }
      if (updateInReview) {
        tempProjectImportConfig.update_inreview = true;
      } else {
        delete tempProjectImportConfig['update_inreview'];
      }
      if (updateEscalated) {
        tempProjectImportConfig.update_escalated = true;
      } else {
        delete tempProjectImportConfig['update_escalated'];
      }
      if (trimWhitespace) {
        tempProjectImportConfig.trim_whitespace = true;
      } else {
        delete tempProjectImportConfig['trim_whitespace'];
      }
      if (skipExchangedByUtility) {
        tempProjectImportConfig.skip_exchanged_by_utility = true;
      } else {
        delete tempProjectImportConfig['skip_exchanged_by_utility'];
      }
      if (skipNewInventoryCheck) {
        tempProjectImportConfig.skip_new_inventory_check = true;
      } else {
        delete tempProjectImportConfig['skip_new_inventory_check'];
      }
      if (showExchangedLimit && typeof (exchangedLimit) === 'number') {
        tempProjectImportConfig.exchanged_limit = exchangedLimit;
      } else {
        delete tempProjectImportConfig['exchanged_limit'];
      }
      if (lookups) {
        tempProjectImportConfig.lookups = lookups;
      }

      const newProjectImportConfig = JSON.stringify(tempProjectImportConfig, null, 0).replace('\\r', '');
      try {
        await updateProject({ id: project.id, projectImportConfig: newProjectImportConfig, autoImport });
        onSetProject({ ...project, autoImport, projectImportConfig: newProjectImportConfig });
        context.setTenant(context.currentTenant);
        setSuccessNotification(true);
      } catch (error) {
        setErrorNotification(true);
      }
    }

    setLoading(false);
  }

  const onMouseOver = (event, index) => {
    setShowEdit(index);
  }

  const onMouseLeave = () => {
    setShowEdit(undefined);
  }

  const onFileColumnNameEdit = (event, { value }, index) => {
    const oldFileColumnNameIndex = fileColumnNames.indexOf(dataMapping[index].fileColumnName);
    if (importKeys.file_key === dataMapping[index].fileColumnName) {
      importKeys.file_key = value;
      setImportKeys({ ...importKeys });
    }
    fileColumnNames[oldFileColumnNameIndex] = value;
    dataMapping[index].fileColumnName = value;
    setFileColumnNames([...fileColumnNames]);
    setDataMapping([...dataMapping]);
  }

  const removeColumnMapping = (event, index) => {
    const fileColumnName = dataMapping[index].fileColumnName;
    if (importKeys.file_key === fileColumnName) {
      importKeys.file_key = '';
      setImportKeys({ ...importKeys });
    }
    setFileColumnNames([...fileColumnNames.filter(i => i !== fileColumnName)]);
    let newDataMapping = dataMapping.map(i => {
      if (i.fileColumnName === fileColumnName) {
        i.fileColumnName = '';
      }
      return i;
    });
    newDataMapping = newDataMapping.filter(i => i.fileColumnName !== '');
    setDataMapping([...newDataMapping]);
  }

  const onNewFileColumn = (fileColumnName) => {
    fileColumnNames.push(fileColumnName);
    dataMapping.push({ fileColumnName, tableColumnName: '', updatable: false });
    setFileColumnNames([...fileColumnNames]);
    setDataMapping([...dataMapping]);
    setShowNewFileColumnModal(false);
  }

  const onCloseNewFileColumnModal = () => {
    setShowNewFileColumnModal(false);
  }

  const onCloseJoinColumnsModal = () => {
    setShowJoinColumnsModal(false);
  }

  const onCloseLookupsModal = () => {
    setShowLookupsModal(false);
  }

  const onWorkOrderFileColumnChange = (event, { value }, index) => {
    dataMapping[index].fileColumnName = value;
    setDataMapping([...dataMapping]);
  }

  const getTableColumns = (index) => {
    if (dataMapping.findIndex(i => i.tableColumnName === 'workOrderNumber') === -1 || dataMapping[index].tableColumnName === 'workOrderNumber') {
      return tableColumnNames.map(name => Object.assign({}, { key: name + index, value: name, text: name }))
    } else {
      return tableColumnNames.filter(i => i !== 'workOrderNumber').map(name => Object.assign({}, { key: name + index, value: name, text: name }))
    }
  }

  const onJoinColumns = (data) => {
    const workOrderTableColumns = [...WORKORDER_TABALE_COLUMNS[WORKORDER_TABALES.WorkOrder]];
    if (workOrderTableColumns.indexOf(data.tableColumnName) > -1) {
      projectImportConfig.tables.WorkOrder.concat = [...(projectImportConfig.tables.WorkOrder.concat || []), data];
    } else {
      projectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].concat = [...(projectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].concat || []), data];
    }
    setProjectImportConfig({ ...projectImportConfig });
    setShowJoinColumnsModal(false);
  }

  const onRemoveColumnsJoin = (event, tableColumnName) => {
    const workOrderTableColumns = [...WORKORDER_TABALE_COLUMNS[WORKORDER_TABALES.WorkOrder]];
    if (workOrderTableColumns.indexOf(tableColumnName) > -1) {
      const index = projectImportConfig.tables.WorkOrder.concat.findIndex(i => i.tableColumnName === tableColumnName);
      projectImportConfig.tables.WorkOrder.concat.splice(index, 1);
    } else {
      const index = projectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].concat.findIndex(i => i.tableColumnName === tableColumnName);
      projectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].concat.splice(index, 1);
    }
    setProjectImportConfig({ ...projectImportConfig });
  }

  const onSaveLookups = (lookups) => {
    setShowLookupsModal(false);
    setLookups(lookups);
  }

  const onUpdateInReviewChange = (event, { checked }) => {
    setUpdateInReview(checked);
  }

  const onUpdateEscalatedChange = (event, { checked }) => {
    setUpdateEscalated(checked);
  }
  
  return (
    <div style={{ marginTop: '10px' }}>
      <div>
        <Button
          content='Choose File'
          icon='file'
          onClick={() => fileInputRef.current.click()}
        />
        <input
          ref={fileInputRef}
          type='file'
          accept='.csv,.xls'
          hidden
          onChange={fileChange}
        />
      </div>

      {dataMapping.length > 0 &&
        <>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>File Column Key</Table.HeaderCell>
                <Table.HeaderCell width={4}>Table Column Key</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Dropdown
                    onChange={(e, data) => onImportKeysChange(e, data, 'file_key')}
                    selectOnBlur={false}
                    value={importKeys.file_key}
                    search
                    fluid
                    placeholder='File Column Key'
                    selection
                    options={[...new Set(fileColumnNames)].map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }))} />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    onChange={(e, data) => onImportKeysChange(e, data, 'table_key')}
                    selectOnBlur={false}
                    value={importKeys.table_key}
                    search
                    fluid
                    placeholder='Table Column Key'
                    selection
                    options={[
                      { key: 0, value: 'workOrderNumber', text: 'workOrderNumber' },
                      { key: 1, value: 'locationId', text: 'locationId' },
                      { key: 2, value: 'oldMeterNumber', text: 'oldMeterNumber' }
                    ]} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Table celled onMouseLeave={onMouseLeave}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}></Table.HeaderCell>
                <Table.HeaderCell width={4}>File Column</Table.HeaderCell>
                <Table.HeaderCell width={4}>Table Column</Table.HeaderCell>
                <Table.HeaderCell width={2}>Updatable</Table.HeaderCell>
                <Table.HeaderCell width={1}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {dataMapping.map((dataMappingItem, index) => (
                <Table.Row key={index} className={dataMappingItem.tableColumnName === 'workOrderNumber' ? 'workorder-row-background' : ''}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell onMouseOver={(e) => onMouseOver(e, index)}>
                    {showEdit === index || dataMappingItem.tableColumnName === 'workOrderNumber' ?
                      <>
                        {dataMappingItem.tableColumnName === 'workOrderNumber' ? (
                          <Dropdown
                            onChange={(e, data) => onWorkOrderFileColumnChange(e, data, index)}
                            selectOnBlur={false}
                            value={dataMapping[index].fileColumnName || ''}
                            search
                            fluid
                            placeholder='File Column'
                            selection
                            options={[...new Set(fileColumnNames)].map((name, index) => Object.assign({}, { key: name + index, value: name, text: name }))} />
                        ) : (
                          <Input
                            type='text'
                            value={dataMappingItem.fileColumnName}
                            onChange={(e, data) => onFileColumnNameEdit(e, data, index)}
                          />
                        )}
                      </>
                      : dataMappingItem.fileColumnName}
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      onChange={(e, data) => onTableColumnNameChange(e, data, index)}
                      selectOnBlur={false}
                      value={dataMappingItem.tableColumnName || ''}
                      search
                      disabled={dataMappingItem.tableColumnName === 'workOrderNumber'}
                      fluid
                      placeholder='Table Column'
                      selection
                      options={getTableColumns(index)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      disabled={!dataMappingItem.tableColumnName}
                      checked={dataMappingItem.updatable}
                      onChange={(e, data) => onUpdatableChange(e, data, index)}
                      toggle
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div style={{ textAlign: 'center' }}>
                      <Icon onClick={(event) => removeColumnMapping(event, index)} name='remove' className='remove-icon' />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          {((projectImportConfig.tables.WorkOrder.concat && projectImportConfig.tables.WorkOrder.concat.length > 0) ||
            (projectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].concat && projectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].concat.length > 0)) &&
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Table Column</Table.HeaderCell>
                  <Table.HeaderCell width={4}>File Columns</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Text</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {[...(projectImportConfig.tables.WorkOrder.concat || []), ...(projectImportConfig.tables[getWorkOrderTypeTable(workOrderType)].concat || [])].map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {item.tableColumnName}
                    </Table.Cell>
                    <Table.Cell>
                      {item.fileColumnNames.join(', ')}
                    </Table.Cell>
                    <Table.Cell>
                      {item.value}
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{ textAlign: 'center' }}>
                        <Icon onClick={(event) => onRemoveColumnsJoin(event, item.tableColumnName)} name='remove' className='remove-icon' />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>}

          <Button color='blue' onClick={() => setShowNewFileColumnModal(true)}>New File Column</Button>
          <Button color='blue' onClick={() => setShowJoinColumnsModal(true)}>Join Columns</Button>
          <Button color='blue' onClick={() => setShowLookupsModal(true)}>{lookups ? 'Edit' : 'Add'} Lookups</Button>
          {showLookupsModal && <LookupsModal
            configLookups={lookups}
            fileColumnNames={fileColumnNames}
            tableColumnNames={tableColumnNames}
            modalRef={modalRef}
            showModal={showLookupsModal}
            closeModal={onCloseLookupsModal}
            onSubmit={onSaveLookups} />}
          {showNewFileColumnModal && <NewFileColumnModal
            modalRef={modalRef}
            showModal={showNewFileColumnModal}
            closeModal={onCloseNewFileColumnModal}
            onSubmit={onNewFileColumn} />}
          {showJoinColumnsModal && <JoinColumnsModal
            fileColumnNamesCopy={fileColumnNames}
            tableColumnNames={tableColumnNames}
            modalRef={modalRef}
            showModal={showJoinColumnsModal}
            closeModal={onCloseJoinColumnsModal}
            onSubmit={onJoinColumns} />}

          <Segment.Group>
            <Segment>
            <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Trim whitespace'
                  onChange={onTrimWhitespace}
                  checked={trimWhitespace}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Update In-Review'
                  onChange={onUpdateInReviewChange}
                  checked={updateInReview}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Update Escalated'
                  onChange={onUpdateEscalatedChange}
                  checked={updateEscalated}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Utility is Reusing Old Meters'
                  onChange={onReuseChange}
                  checked={reuse}
                  toggle
                />
              </div>
              {reuse && <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Skip Reusing If 3 Strike'
                  onChange={onReuseIgnoreRTU}
                  checked={reuseIgnoreRTU}
                  toggle
                />
              </div>}
              <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Auto Import'
                  onChange={onAutoImportChange}
                  checked={autoImport}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Skip Exchanged By Utility'
                  onChange={onSkipExchangedByUtilityChange}
                  checked={skipExchangedByUtility}
                  toggle
                />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Checkbox
                  label='Skip New Inventory Check'
                  onChange={onSkipNewInventoryCheckChange}
                  checked={skipNewInventoryCheck}
                  toggle
                />
              </div>
              <div>
                <Checkbox
                  label='Exchanged Limit'
                  onChange={onShowExchangedLimitChange}
                  checked={showExchangedLimit}
                  toggle
                />
                {showExchangedLimit && <Input className='exchanged-limit-input' type='number' value={exchangedLimit} onChange={onExchangedLimitChange} />}
              </div>
            </Segment>
          </Segment.Group>
        </>}

      {error && <ErrorMessage message={error.message} headerStyle={{}} />}
      {successNotification && <div style={{ marginTop: 10 }}>
        <SuccessMessage header message='Your data has been saved' />
      </div>}
      {errorNotification && <div style={{ marginTop: 10 }}>
        <ErrorMessage header message='Failed to save the data' />
      </div>}

      {tableColumnNames.length > 0 && <div style={{ marginTop: '10px', paddingBottom: '10px', textAlign: 'right' }}>
        <Button loading={loading} onClick={onSave} positive content='Save' />
      </div>}
    </div>
  )
}

export default ImportStep;