import React, { Component } from 'react';
import TopMenu from './components/top-menu';
import AppContext from '../../core/context/app.context';
import WorkOrdersListView from './components/grid/list-view';
import ErrorMessage from '../../shared/components/messages/error';
import * as workOrderService from '../../core/services/workOrder.service';
import { useTimeZone } from '../../helpers/date';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import { isEnabled } from '../../core/services/auth.service';
import ROLES from '../../constants/roles';
import MapBox from './components/mapbox';
import * as mapActions from '../../core/redux/actions/map.actions';
import { connect } from 'react-redux';
import { getImagePreSignedUrl } from '../../core/services/photo.service';
import { orderPhotosByType } from '../../helpers/workOrderPhoto';

class WorkOrdersRoute extends Component {

  static contextType = AppContext;
  _isMounted = false;

  state = {
    workOrders: [],
    loading: true,
    error: false,
    currentOffset: 0,
    pageLimit: 0,
    resultCount: 0,
    filterTypes: [],
  };

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  UNSAFE_componentWillMount() {
    this._isMounted = true;
    let pageLimit = localStorage.getItem('ALL_WORKORKDERS_PAGE_LIMIT_SIZE');
    if (!pageLimit) {
      localStorage.setItem('ALL_WORKORKDERS_PAGE_LIMIT_SIZE', '50');
      pageLimit = '50';
    }
    this.setState({ pageLimit: JSON.parse(pageLimit) });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changePageLimit = async (newLimit) => {
    localStorage.setItem('ALL_WORKORKDERS_PAGE_LIMIT_SIZE', newLimit);
    this.setState({ pageLimit: newLimit });
  }

  setLoading = (loading) => {
    this.setState({ loading });
  }

  getFilterTypes = async () => {
    const sortFilters = ( input ) => {
      const alphaNumericSort = (a, b) => {
          const aValue = a.replace(/[a-zA-z]*: (.*)/, '$1');
          const bValue = b.replace(/[a-zA-z]*: (.*)/, '$1');
          const isANumeric = !isNaN(aValue);
          const isBNumeric = !isNaN(bValue);
          if (isANumeric && isBNumeric) {
              if(Number(aValue) > Number(bValue)) return 1;
              if(Number(aValue) < Number(bValue)) return -1;
              if(Number(aValue) === Number(bValue)) return 0;
          } else if (isANumeric && !isBNumeric){
              return -1;
          } else if (!isANumeric && isBNumeric){
              return 1;
          } else {
              return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
          }
      }
      const keys = Object.keys(input);
      for (const key of keys) {
          if (key === 'workOrderCustomFilterItems') continue;
          if (key === 'workOrderStatusFilterItems') continue;
          input[key].sort(alphaNumericSort);
      }
    }
    try {
      const { currentProject, workOrdersPageMapViewActive } = this.context;
      const filterTypes = await workOrderService.getFilterTypes(
        currentProject.id
      );
      sortFilters(filterTypes);
      let filters = [];
      Object.keys(filterTypes).forEach(filterType => {
        filterTypes[filterType].forEach(filterName => {
          if (workOrdersPageMapViewActive) {
            if (!filterName.startsWith('Custom: Invalid Coordinates')) {
              filters.push({ id: filters.length, filterName });
            }
          } else {
            filters.push({ id: filters.length, filterName });
          }
        });
      });
      this.setState({ filterTypes: filters });
    } catch (error) {
      console.log(error);
    }
  };

  getWorkOrders = async (offset = 0, search, filter, multipleValuesSearch) => {
    try {
      this.setState({ loading: true });
      const { currentProject } = this.context;
      if (currentProject) {
        let workOrders = [];
        if (currentProject) {
          let { pageLimit } = this.state;
          const queryData = {
            projectId: currentProject.id,
            workOrderType: currentProject.projectWorkOrderType,
            offset,
            limit: pageLimit,
            search,
            filter,
            multipleValuesSearch
          };
          const data = await workOrderService.getWorkOrders(queryData);
          if (data && data.workOrders && data.workOrders.length > 0) {
            workOrders = useTimeZone(data.workOrders, ['workOrderLastVisitDate', 'appointmentStartDate'], currentProject.projectTimeZone);
            this.setState({ currentOffset: offset, workOrders, resultCount: data.resultCount, loading: false });
          } else {
            this.setState({ workOrders: [], resultCount: data.resultCount, loading: false });
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: true, loading: false });
    }
  }

  showMapView = () => {
    const { setWorkOrdersPageMapViewActive, setWorkOrdersPageListViewActive } = this.context;
    setWorkOrdersPageMapViewActive(true);
    setWorkOrdersPageListViewActive(false);
  }

  showListView = () => {
    const { setWorkOrdersPageMapViewActive, setWorkOrdersPageListViewActive } = this.context;
    setWorkOrdersPageMapViewActive(false);
    setWorkOrdersPageListViewActive(true);
  }

  renderTopMenu = () => {
    const { currentProject, workOrdersPageMapViewActive, workOrdersPageListViewActive } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - All Work Orders` : 'All Work Orders'}`,
        iconName: 'flag'
      },
      tabs: [
        {
          active: workOrdersPageListViewActive,
          onClick: this.showListView,
          iconName: 'list',
          title: 'List View',
        },
        {
          active: workOrdersPageMapViewActive,
          onClick: this.showMapView,
          iconName: 'pin',
          title: 'Map View'
        }
      ]
    };
    return <TopMenu
      config={topMenuConfig}
      style={{ zIndex: 2 }}
      mapViewActive={workOrdersPageMapViewActive}
      refreshMap={this.refreshMap} />;
  }

  refreshMap = () => {
    const { currentProject } = this.context;
    this.props.createGeoJson({
      projectId: currentProject.id,
      workOrderType: currentProject.projectWorkOrderType,
      refresh: true,
      search: true,
      usePreviousLocation: true
    });
  }

  filterWorkOrders = workOrders => {
    return workOrders.map(workOrder => Object.assign({ selected: false }, workOrder));
  }

  getWorkOrderDetails = async (workOrderId) => {
    const { currentProject } = this.context;
    let workOrderDetails = await workOrderService.getWorkOrderDetails(workOrderId);
    workOrderDetails = await this.formatWorkOrderDetailsPhotos(workOrderDetails);
    workOrderDetails = this.filterWorkOrderCommentsByUserName(workOrderDetails);
    workOrderDetails = useTimeZone(workOrderDetails, ['workOrderLastVisitDate', 'workOrderDeletedDate', 'workOrderAuditDate'], currentProject.projectTimeZone);
    if (workOrderDetails.workOrderHistorys && workOrderDetails.workOrderHistorys.items && workOrderDetails.workOrderHistorys.items.length > 0) {
      workOrderDetails.workOrderHistorys.items = useTimeZone(workOrderDetails.workOrderHistorys.items, ['historyDate'], currentProject.projectTimeZone);
    }
    return workOrderDetails;
  }

  filterWorkOrderCommentsByUserName = (workOrder) => {
    const { currentProject, user } = this.context;
    if (workOrder.workOrderComments && workOrder.workOrderComments.items && workOrder.workOrderComments.items.length > 0 && workOrder.userName) {
      workOrder.workOrderComments.items = useTimeZone(workOrder.workOrderComments.items, ['contentDate'], currentProject.projectTimeZone);
      // show all comments if user is SystemAdmin/ClientAdmin/AccountAdmin/Admin
      if (!isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.ClientAdmin, ROLES.AccountAdmin, ROLES.Admin])) {
        workOrder.workOrderComments.items = workOrder.workOrderComments.items.filter(comment => comment.user.userName === workOrder.userName);
      }
    }
    return workOrder;
  }

  formatWorkOrderDetailsPhotos = async (workOrder) => {
    let workOrderCopy = Object.assign({}, workOrder);
    if (workOrderCopy.photos.items && workOrderCopy.photos.items.length > 0) {
      workOrderCopy = orderPhotosByType(workOrderCopy);
      const imagesPaths = workOrderCopy.photos.items.map(({ file }) => `${file.bucket}/${file.key}`);
      const urls = await getImagePreSignedUrl(JSON.stringify(imagesPaths));
      const imagesUrls = urls.map((url, index) => {
        return { original: url, thumbnail: url, photoName: workOrder.photos.items[index].file.key };
      });
      workOrderCopy.photos = imagesUrls;
    } else {
      workOrderCopy.photos = [];
    }
    return workOrderCopy;
  };

  renderWorkOrdersRouteContent = () => {
    const {
      loading,
      workOrders,
      currentOffset,
      pageLimit,
      resultCount,
      filterTypes,
      mapStatus,
    } = this.state;
    const { workOrdersPageMapViewActive } = this.context;
    const { location } = this.props;
    const { state } = location;
    return (
      <React.Fragment>
        {workOrdersPageMapViewActive ? (
          <MapBox
            filterTypes={filterTypes}
            getFilterTypes={this.getFilterTypes}
            mapStatus={mapStatus}
            getWorkOrderDetails={this.getWorkOrderDetails} />
        ) : <WorkOrdersListView
            workOrderId={state && state.workOrderId ? state.workOrderId : null}
            getWorkOrderDetails={this.getWorkOrderDetails}
            getWorkOrders={this.getWorkOrders}
            workOrders={this.filterWorkOrders(workOrders)}
            currentOffset={currentOffset}
            pageLimit={pageLimit}
            total={resultCount}
            loading={loading}
            changePageLimit={this.changePageLimit}
            setLoading={this.setLoading}
            filterTypes={filterTypes}
            getFilterTypes={this.getFilterTypes} />}
      </React.Fragment>
    );
  }

  render() {
    const { error } = this.state;
    return (
      <ProjectRouteWrapper>
        {this.renderTopMenu()}
        {error ? <ErrorMessage message='Error. Unable to fetch work orders' /> : this.renderWorkOrdersRouteContent()}
      </ProjectRouteWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createGeoJson: (options) => dispatch(mapActions.createGeoJson(options)),
  }
}

export default connect(null, mapDispatchToProps)(WorkOrdersRoute);