import gql from 'graphql-tag';

export const CREATE_VERSION_MUTATION = gql`
  mutation CreateVersion($versionName: String!) {
    createVersion(input: {versionName: $versionName, versionType: "Web"}) {
      id
      versionName
      versionType
      versionDescription
    }
  }
`;