import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../core/context/app.context';
import TopMenu from '../../../shared/components/top-menu';
import ProjectRouteWrapper from '../../../hoc/projectRouteWrapper';
import AuthComponent from '../../../shared/components/auth';
import ROLES from '../../../constants/roles';
import routeMaps from '../../../core/route-maps';
import * as QuickSightService from '../../../core/services/quicksight.service';
import CustomLoader from '../../../shared/components/loader';
import { UserListBox } from '../../administration/update-project/components/reportingStep';
import { Message, MessageHeader } from 'semantic-ui-react';

const PeopleEmailReportingRoute = () => {
  const context = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const init = async () => {
      const { currentProject } = context;
      if (currentProject) {
        await Promise.all([fetchQuickSightGroupUsersByProject(currentProject.id), fetchQuickSightGroupMembersByProject(currentProject.id)]);
        setLoading(false);
      }
    }
    init();
  }, []);

  const fetchQuickSightGroupUsersByProject = async (projectId) => {
    const users = await QuickSightService.listGroupUsers(projectId);
    setUsers(users);
  }

  const fetchQuickSightGroupMembersByProject = async (projectId) => {
    const members = await QuickSightService.listGroupMembers(projectId);
    setMembers(members);
  }

  const onSave = async (userIds) => {
    const { currentProject } = context;
    setSaving(true);
    await QuickSightService.manageUserGroup(currentProject.id, userIds);
    await Promise.all([fetchQuickSightGroupUsersByProject(currentProject.id), fetchQuickSightGroupMembersByProject(currentProject.id)]);
    setSaving(false);
  }

  const renderTopMenu = () => {
    const { currentProject } = context;
    const topMenuConfig = {
      header: {
        title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Email Reporting` : 'Email Reporting'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  const renderRouteContent = () => {
    const showWarningMessage = users.length === 0 && members.length === 0;
    return (
      <>
        {loading && <CustomLoader loading={loading} />}
        {!loading && showWarningMessage && (
          <Message warning>
            <MessageHeader>Email reporting is not enabled for this project.</MessageHeader>
            <p>For help, please contact our support team.</p>
          </Message>
        )}
        {!loading && !showWarningMessage && <UserListBox loading={saving} users={users} members={members} onSave={onSave} />}
      </>
    );
  }

  return (
    <AuthComponent routeRoles={[ROLES.SystemAdmin, ROLES.AccountAdmin]} redirectTo={routeMaps.dashboard}>
      <ProjectRouteWrapper>
        {renderTopMenu()}
        {renderRouteContent()}
      </ProjectRouteWrapper>
    </AuthComponent>
  );
}

export default PeopleEmailReportingRoute;