import React, { useContext } from 'react';
import { Layer } from 'react-mapbox-gl';
import { MAP_LAYOUT_IDS } from '../../../../constants/mapLayout';
import BLACKOUT_ICON from '../../../../assets/icons/pins/wo-assigned-black-out.png';
import ASSIGNED_ICON from '../../../../assets/icons/pins/wo-assigned-person.png';
import ASSIGNED_CNC_ICON from '../../../../assets/icons/pins/wo-assigned-cnc.png';
import THREE_STRIKE_ICON from '../../../../assets/icons/pins/wo-3-strike.png';
import AD_HOC_ICON from '../../../../assets/icons/pins/wo-ad-hoc-in-review.png';
import OPEN_ICON from '../../../../assets/icons/pins/wo-open.png';
import CHECKED_ICON from '../../../../assets/icons/pins/wo-checked.png';
import SAVED_ICON from '../../../../assets/icons/pins/wo-saved-in-review.png';
import APPOINTMENT_ICON from '../../../../assets/icons/pins/wo-appointment.png';
import SITE_TEST_ICON from '../../../../assets/icons/pins/wo-sitetest.png';
import WORKORDER_PERSON_POSITION_ICON from '../../../../assets/icons/pins/woPersonPosition.png';
import UTILITY_REVIEW_ICON from '../../../../assets/icons/pins/wo-utility-review.png';
import { useDispatch } from 'react-redux';
import { setPopup, setLayoutIconPopup } from '../../../../core/redux/actions/map.actions';
import PopupRenderer from './popup';
import LayoutIconPopupRenderer from './layoutIconPopup';
import { connect } from 'react-redux';
import COLLECTOR_ICON from '../../../../assets/icons/pins/collector.png';
import GENERIC_ICON from '../../../../assets/icons/pins/generic.png';
import ROUTER_ICON from '../../../../assets/icons/pins/router.png';
import SUBSTATION_ICON from '../../../../assets/icons/pins/substation.png';
import WAREHOUSE_ICON from '../../../../assets/icons/pins/warehouse.png';
import MAP_SOURCES from '../../constants/mapSource';
import MAP_LAYOUT_DEFAULT_COLORS from '../../constants/mapLayoutDefaultColors';
import AppContext from '../../../../core/context/app.context';

const ClusterLayers = ({ properties }) => {
  const { clusterId, unclusteredPointId, clusterTextId, sourceId } = properties;
  return (
    <>
      <Layer
        id={clusterId}
        sourceId={sourceId}
        filter={['has', 'point_count']}
        maxZoom={9}
        type='circle'
        paint={{
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#577590',
            50,
            '#43AA8B',
            250,
            '#90BE6D',
            750,
            '#F3722C',
            1500,
            '#F8961E',
            2500,
            '#F9C74F'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            50,
            15,
            250,
            20,
            750,
            25,
            1500,
            30,
            2500,
            35
          ]
        }} />
      <Layer
        id={unclusteredPointId}
        sourceId={sourceId}
        filter={['!', ['has', 'point_count']]}
        type='circle'
        maxZoom={9}
        paint={{
          'circle-color': MAP_LAYOUT_DEFAULT_COLORS,
          'circle-radius': 5
        }} />
      <Layer
        id={clusterTextId}
        sourceId={sourceId}
        filter={['has', 'point_count']}
        maxZoom={9}
        type='symbol'
        layout={{
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }} />
    </>
  )
}

let point;

const workOrderPersonPositionIcon = new Image();
workOrderPersonPositionIcon.src = WORKORDER_PERSON_POSITION_ICON;

const utilityReviewIcon = new Image();
utilityReviewIcon.src = UTILITY_REVIEW_ICON;

const openIcon = new Image();
openIcon.src = OPEN_ICON;

const blackoutIcon = new Image();
blackoutIcon.src = BLACKOUT_ICON;

const assignedIcon = new Image();
assignedIcon.src = ASSIGNED_ICON;

const appointmentIcon = new Image();
appointmentIcon.src = APPOINTMENT_ICON;

const siteTestIcon = new Image();
siteTestIcon.src = SITE_TEST_ICON;

const assignedCNCIcon = new Image();
assignedCNCIcon.src = ASSIGNED_CNC_ICON;

const threeStrikeIcon = new Image();
threeStrikeIcon.src = THREE_STRIKE_ICON;

const adhocIcon = new Image();
adhocIcon.src = AD_HOC_ICON;

const savedIcon = new Image();
savedIcon.src = SAVED_ICON;

const checkedIcon = new Image();
checkedIcon.src = CHECKED_ICON;

const collectorIcon = new Image();
collectorIcon.src = COLLECTOR_ICON;

const genericIcon = new Image();
genericIcon.src = GENERIC_ICON;

const routerIcon = new Image();
routerIcon.src = ROUTER_ICON;

const substationIcon = new Image();
substationIcon.src = SUBSTATION_ICON;

const warehouseIcon = new Image();
warehouseIcon.src = WAREHOUSE_ICON;

const images = [
  ['OPEN_ICON', openIcon],
  ['BLACKOUT_ICON', blackoutIcon],
  ['ASSIGNED_ICON', assignedIcon],
  ['APPOINTMENT_ICON', appointmentIcon],
  ['SITE_TEST_ICON', siteTestIcon],
  ['ASSIGNED_CNC_ICON', assignedCNCIcon],
  ['THREE_STRIKE_ICON', threeStrikeIcon],
  ['AD_HOC_ICON', adhocIcon],
  ['SAVED_ICON', savedIcon],
  ['CHECKED_ICON', checkedIcon],
  ['UTILITY_REVIEW_ICON', utilityReviewIcon],
];

const overlayImages = [
  ['COLLECTOR_ICON', collectorIcon],
  ['GENERIC_ICON', genericIcon],
  ['ROUTER_ICON', routerIcon],
  ['SUBSTATION_ICON', substationIcon],
  ['WAREHOUSE_ICON', warehouseIcon],
];

const MapLayers = ({ map, onGetWorkOrderDetails, overlayGeoJsonFileUrl, customDataUrl }) => {
  const context = useContext(AppContext);
  const dispatch = useDispatch();

  const onShowPopup = async (event, map) => {
    map.getCanvas().style.cursor = 'pointer';
    const { features, lngLat } = event;
    const properties = features[0].properties;
    dispatch(setPopup({ lngLat, properties }));
  }

  const onHidePopup = () => {
    map.getCanvas().style.cursor = '';
    dispatch(setPopup(null));
  }

  const onShowLayoutIconPopup = async (event, map) => {
    const { features, lngLat } = event;
    const properties = features[0].properties;
    dispatch(setLayoutIconPopup({ lngLat, properties }));
  }

  const onHideLayoutIconPopup = () => {
    dispatch(setLayoutIconPopup(null));
  }

  const onMouseUp = (event) => {
    const currentPoint = event.point;
    if (point && currentPoint && currentPoint.x === point.x && currentPoint.y === point.y) {
      map.getCanvas().style.cursor = 'wait';
      const { id } = event.features[0].properties;
      onGetWorkOrderDetails(id);
    }
  }

  const onTouchUp = (event) => {
    const currentPoint = event.point;
    if (point && currentPoint && currentPoint.x === point.x && currentPoint.y === point.y) {
      const { id } = event.features[0].properties;
      onGetWorkOrderDetails(id);
    }
  }

  const onTouchStart = (event) => {
    point = event.point;
  }

  const onMouseDown = (event) => {
    point = event.point;
  }

  const renderLayers = () => {
    const { projectConfiguration } = context.currentProject;
    const customDataGeoJson = projectConfiguration && projectConfiguration.additionalFeatures && projectConfiguration.additionalFeatures.customDataGeoJson;
    return (
      <React.Fragment>
        <PopupRenderer />
        <LayoutIconPopupRenderer />
        {customDataUrl && <Layer
          onTouchStart={onTouchStart}
          onMouseDown={onMouseDown}
          onTouchEnd={(event) => onTouchUp(event, map)}
          onMouseUp={(event) => onMouseUp(event, map)}
          minZoom={9}
          id={MAP_LAYOUT_IDS.Custom}
          sourceId={MAP_SOURCES.CUSTOM}
          onMouseLeave={onHidePopup}
          onMouseEnter={(event) => onShowPopup(event, map)}
          type='circle'
          paint={{
            'circle-radius': {
              'base': 1.85,
              'stops': [
                [12, 5],
                [22, 180]
              ]
            },
            'circle-stroke-color': '#ffffff',
            'circle-stroke-width': 1,
            'circle-stroke-opacity': .5,
            'circle-color': customDataGeoJson && customDataGeoJson.layoutColor ? customDataGeoJson.layoutColor : '#616161'
          }} />}
        {customDataUrl && <ClusterLayers properties={{
          clusterId: MAP_LAYOUT_IDS.CustomCluster,
          unclusteredPointId: MAP_LAYOUT_IDS.CustomUnclusteredPoint,
          clusterTextId: MAP_LAYOUT_IDS.CustomClusterText,
          sourceId: MAP_SOURCES.CUSTOM
        }} />}
        <ClusterLayers properties={{
          clusterId: MAP_LAYOUT_IDS.PrimaryCluster,
          unclusteredPointId: MAP_LAYOUT_IDS.PrimaryUnclusteredPoint,
          clusterTextId: MAP_LAYOUT_IDS.PrimaryClusterText,
          sourceId: MAP_SOURCES.MAP_SOURCE_ID
        }} />
        <Layer
          onTouchStart={onTouchStart}
          onMouseDown={onMouseDown}
          onTouchEnd={(event) => onTouchUp(event, map)}
          minZoom={9}
          maxZoom={15.5}
          onMouseLeave={onHidePopup}
          onMouseEnter={(event) => onShowPopup(event, map)}
          onMouseUp={(event) => onMouseUp(event, map)}
          id={MAP_LAYOUT_IDS.Primary}
          sourceId={MAP_SOURCES.MAP_SOURCE_ID}
          type='circle'
          paint={{
            'circle-radius': {
              'base': 1.85,
              'stops': [
                [12, 5],
                [22, 180]
              ]
            },
            'circle-stroke-color': '#ffffff',
            'circle-stroke-width': 1,
            'circle-stroke-opacity': .5,
            'circle-color': MAP_LAYOUT_DEFAULT_COLORS
          }} />
        {overlayGeoJsonFileUrl && <Layer
          id={MAP_LAYOUT_IDS.Overlay}
          type='symbol'
          sourceId={MAP_SOURCES.OVERLAY_SOURCE_ID}
          onMouseLeave={onHideLayoutIconPopup}
          onMouseEnter={(event) => onShowLayoutIconPopup(event, map)}
          layout={{
            'icon-image': [
              'match',
              ['get', 'overlayType'],
              'Collector',
              'COLLECTOR_ICON',
              'Substation',
              'SUBSTATION_ICON',
              'Warehouse',
              'WAREHOUSE_ICON',
              'Router',
              'ROUTER_ICON',
              'GENERIC_ICON'
            ],
            'icon-allow-overlap': true
          }}
          images={overlayImages}
          filter={['match', ['geometry-type'], ['Point'], true, false]}
        />}
        {overlayGeoJsonFileUrl && <Layer
          id={MAP_LAYOUT_IDS.MultiPolygonOverlay}
          type='fill'
          sourceId={MAP_SOURCES.OVERLAY_SOURCE_ID}
          paint={{
            'fill-color': ['get','color'],
            'fill-opacity': 0.4
          }}
          filter={['match', ['geometry-type'], ['Polygon', 'MultiPolygon'], true, false]}
        />}
        {overlayGeoJsonFileUrl && <Layer
          id={MAP_LAYOUT_IDS.MultiPolygonOverlayLabel}
          sourceId={MAP_SOURCES.OVERLAY_SOURCE_ID}
          filter={['match', ['geometry-type'], ['Polygon', 'MultiPolygon'], true, false]}
          type='symbol'
          layout={{
            'text-field': ['get', 'Name'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 20
          }} 
        />}
        <Layer
          onTouchStart={onTouchStart}
          onMouseDown={onMouseDown}
          onTouchEnd={(event) => onTouchUp(event, map)}
          minZoom={15.5}
          onMouseLeave={onHidePopup}
          onMouseEnter={(event) => onShowPopup(event, map)}
          onMouseUp={(event) => onMouseUp(event, map)}
          id={MAP_LAYOUT_IDS.Secondary}
          type='symbol'
          sourceId={MAP_SOURCES.MAP_SOURCE_ID}
          layout={{
            'icon-image': [
              'match',
              ['get', 'icon'],
              'CHECKED_ICON', 'CHECKED_ICON',
              'OPEN_ICON', 'OPEN_ICON',
              'ASSIGNED_CNC_ICON', 'ASSIGNED_CNC_ICON',
              'UTILITY_REVIEW_ICON', 'UTILITY_REVIEW_ICON',
              'THREE_STRIKE_ICON', 'THREE_STRIKE_ICON',
              'WORKORDER_PERSON_POSITION_ICON', 'WORKORDER_PERSON_POSITION_ICON',
              'BLACKOUT_ICON', 'BLACKOUT_ICON',
              'APPOINTMENT_ICON', 'APPOINTMENT_ICON',
              'SITE_TEST_ICON', 'SITE_TEST_ICON',
              'ASSIGNED_ICON', 'ASSIGNED_ICON',
              'AD_HOC_ICON', 'AD_HOC_ICON',
              'SAVED_ICON', 'SAVED_ICON',
              'CHECKED_ICON'
            ],
            'icon-allow-overlap': true
          }}
          images={images}
        />
        <Layer
          id={MAP_LAYOUT_IDS.Tertiary}
          onTouchStart={onTouchStart}
          onMouseDown={onMouseDown}
          onTouchEnd={(event) => onTouchUp(event, map)}
          onMouseLeave={onHidePopup}
          onMouseEnter={(event) => onShowPopup(event, map)}
          onMouseUp={(event) => onMouseUp(event, map)}
          type='symbol'
          sourceId={MAP_SOURCES.MAP_SOURCE_ID}
          filter={['case', ['==', ['get', 'tlvd'], null], false, true]} // technicianWorkOrderLastVisitDate
          layout={{
            'icon-image': [
              'case',
              ['all', ['has', 'tlvd'], ['!=', ['get', 'tlvd'], '']],
              'WORKORDER_PERSON_POSITION_ICON',
              'OPEN_ICON',
            ],
            'icon-allow-overlap': true
          }}
          images={['WORKORDER_PERSON_POSITION_ICON', workOrderPersonPositionIcon]}
        />
      </React.Fragment>
    )
  }

  return renderLayers();
}

const mapStateToProps = (state) => {
  return {
    overlayGeoJsonFileUrl: state.map.overlayGeoJsonFileUrl,
    layoutType: state.map.layoutType,
    customDataUrl: state.map.customDataUrl,
  }
}

export default connect(mapStateToProps, null)(MapLayers);