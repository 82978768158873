import React, { Component } from 'react';
import { Button, Dropdown, Icon, Image, Message, Input } from 'semantic-ui-react';
import AppContext from '../../../core/context/app.context';
import TopMenu from '../../../shared/components/top-menu';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import BACKGROUND_IMAGE from '../../../assets/ReportBackground.png';
import ErrorMessage from '../../../shared/components/messages/error';
import { getToken } from '../../../core/services/auth.service';
import RouteWrapper from '../../../hoc/routeWrapper';
import routeMaps from '../../../core/route-maps';
import config from '../../../config';
import { validateEmail } from '../../../helpers/validators';
import CustomMessage from '../../../shared/components/messages/message';

class PayrollReportRoute extends Component {

  static contextType = AppContext;

  state = {
    fromDate: '',
    toDate: '',
    error: '',
    exporting: false,
    projectId: undefined,
    email: '',
    showEmailSuccessMessage: false
  }

  componentDidMount = () => {
    const { history } = this.props;
    const { currentTenant, user } = this.context;
    if (!currentTenant) {
      history.push(routeMaps.administrationProjects);
    }
    if (user) {
      this.setState({ email: user.email ? user.email : '' });
    }
  }

  renderTopMenu = () => {
    const { currentTenant } = this.context;
    const topMenuConfig = {
      header: {
        title: `${currentTenant && currentTenant.tenantName ? `${currentTenant.tenantName} - Payroll Report` : 'Payroll Report'}`,
        iconName: 'flag'
      },
      tabs: []
    };
    return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
  }

  onDateChange = (event) => {
    const { end, start } = event.target.value;
    this.setState({ fromDate: start, toDate: end });
  }

  // current format is yyyy-MM-dd
  formatDate(date) {
    if (date) {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = date.getDate();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  downloadReport = async (tenantId, fromDate, toDate, projectId, email) => {
    try {
      const token = await getToken();
      let response = await fetch(`${config.invokeAdminExportPayrollReportApi}/${tenantId}/${fromDate}/${toDate}/${email}${projectId ? `/${projectId}` : ''}`, {
        method: 'get',
        headers: new Headers({
          'Authorization': token
        })
      });
      if (!response.ok) {
        throw new Error('No data found');
      }
    } catch (error) {
      throw error;
    }
  }

  onProjectChange = (event, { value }) => {
    if (value === 'all') {
      this.setState({ projectId: null });
    } else {
      this.setState({ projectId: value });
    }
  }

  export = async () => {
    this.setState({ error: '', showEmailSuccessMessage: false });
    try {
      let { fromDate, toDate, projectId, email } = this.state;
      const { currentTenant } = this.context;
      if (!email) {
        this.setState({ error: 'Email is required' });
        return;
      } else if (!validateEmail(email)) {
        this.setState({ error: 'Email is not valid' });
        return;
      }
      if (fromDate && toDate) {
        this.setState({ exporting: true });
        fromDate = this.formatDate(fromDate);
        toDate = this.formatDate(toDate);
        await this.downloadReport(currentTenant.id, fromDate, toDate, projectId, email);
        this.setState({ exporting: false, showEmailSuccessMessage: true });
      } else {
        this.setState({ error: 'From Date/To Date are required fields' });
      }
    } catch (error) {
      this.setState({ error: error.message, exporting: false });
    }
  }

  onEmailChange = (event, { value }) => {
    this.setState({ email: value });
  }

  renderPayrollReportRouteContent = () => {
    const { error, exporting, email, showEmailSuccessMessage } = this.state;
    const { tenantProjects } = this.context;
    return (
      <React.Fragment>
        <Message color='grey' content='The Payroll Report shows detail information for technician activities by date range and meter size/form.' />
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <DateRangePicker format='yyyy-MM-dd' onChange={this.onDateChange} />
          <Dropdown
            clearable
            placeholder='Project'
            style={{ marginLeft: '10px' }}
            selection
            options={[{ key: 'all', value: 'all', text: 'All Projects' }, ...tenantProjects.filter(({ isActive }) => isActive).map(({ id: value, projectName: text }, key) => Object.assign({}, { key, value, text }))]}
            onChange={this.onProjectChange} />
          <Input value={email} onChange={this.onEmailChange} style={{ marginLeft: '10px' }} placeholder='Email' type='email' required />
          <Button loading={exporting} style={{ marginLeft: '10px' }} onClick={this.export}><Icon name='download' />Export</Button>
        </div>
        {showEmailSuccessMessage && <div style={{ marginTop: '10px' }}>
          <CustomMessage message='You have requested a longer running report. When the information is ready you will recieve an email with a link to download the report.' />
        </div>}
        {error && <div style={{ marginTop: '10px' }}>
          <ErrorMessage message={error} />
        </div>}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
          <Image src={BACKGROUND_IMAGE} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <RouteWrapper>
        {this.renderTopMenu()}
        {this.renderPayrollReportRouteContent()}
      </RouteWrapper>
    );
  }
}

export default PayrollReportRoute;