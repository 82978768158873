import React, { useState } from 'react';
import { Button, Dropdown, Input, Modal } from 'semantic-ui-react';
import { EXPORT_ARGUMENT_VALUE_TYPE, EXPORT_BEFORE_UPLOAD_OPERATION_TYPE, EXPORT_BEFORE_UPLOAD_OPERATION_TYPE_LABEL } from '../constants';

const argumentTypeSchema = {
  type: EXPORT_ARGUMENT_VALUE_TYPE.json,
  value: ''
}

const beforeUploadSchema = {
  name: '',
}

const ReportBeforeUploadModal = ({ showModal, closeModal, onSaveBeforeUpload }) => {
  const [beforeUpload, setBeforeUpload] = useState({ ...beforeUploadSchema });
  const [argument, setArgument] = useState({ ...argumentTypeSchema });

  const onOperationChange = (event, { value }) => {
    setBeforeUpload({ ...beforeUpload, name: value });
  }

  const onArgumentValueChange = (event, { value }) => {
    setArgument({ ...argument, value });
  }

  const getBeforeUploadOperationOptions = () => {
    return [
      { key: 0, value: EXPORT_BEFORE_UPLOAD_OPERATION_TYPE.unshiftRow, text: EXPORT_BEFORE_UPLOAD_OPERATION_TYPE_LABEL[EXPORT_BEFORE_UPLOAD_OPERATION_TYPE.unshiftRow] },
      { key: 1, value: EXPORT_BEFORE_UPLOAD_OPERATION_TYPE.pushRow, text: EXPORT_BEFORE_UPLOAD_OPERATION_TYPE_LABEL[EXPORT_BEFORE_UPLOAD_OPERATION_TYPE.pushRow] },
    ]
  }

  const onSubmit = () => {
    const { name } = beforeUpload;
    if (argument.type === EXPORT_ARGUMENT_VALUE_TYPE.json) {
      argument.value = JSON.parse(argument.value);
    }
    onSaveBeforeUpload({ name, args: [argument.value] });
  }

  return (
    <Modal dimmer='blurring' size='tiny' open={showModal}>
      <Modal.Header>New "Before Upload"</Modal.Header>
      <Modal.Content>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <span style={{ paddingRight: '10px' }}>Operation:</span>
            <Dropdown
              placeholder='Operation'
              className='export-condition-small-dropdown'
              selection
              onChange={onOperationChange}
              value={beforeUpload.operation}
              options={getBeforeUploadOperationOptions()} />
          </div>
          <div>
            <span style={{ paddingRight: '10px' }}>Value Type:</span>
            <Dropdown
              placeholder='Value Type'
              className='export-operation-value-type-small-dropdown'
              selection
              value={argument.type}
              options={[
                { key: EXPORT_ARGUMENT_VALUE_TYPE.json, value: EXPORT_ARGUMENT_VALUE_TYPE.json, text: EXPORT_ARGUMENT_VALUE_TYPE.json.toUpperCase() }
              ]} />
            <span style={{ paddingRight: '10px' }}>Value:</span>
            <Input type={EXPORT_ARGUMENT_VALUE_TYPE.string} placeholder='Value' className='export-operation-value-input' value={argument.value} onChange={onArgumentValueChange} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} negative>Cancel</Button>
        <Button onClick={onSubmit} positive icon='checkmark' labelPosition='right' content='Save' />
      </Modal.Actions>
    </Modal>
  )
}

export default ReportBeforeUploadModal;