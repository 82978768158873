import React, { useEffect } from 'react';
import routeMaps from '../../../../core/route-maps';
import * as authService from '../../../../core/services/auth.service';
import * as userService from '../../../../core/services/user.service';
import ROLES from '../../../../constants/roles';
import jwt_decode from 'jwt-decode';

const SSOCodeHandler = ({ error, loading, setUser, history }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const stateNonce = urlParams.get("state");
    const codeVerifier = sessionStorage.getItem(`codeVerifier-${stateNonce}`);
    const username = sessionStorage.getItem(`username`);

    const SSOAuthentication = async (code, codeVerifier,) => {
        try {
            await authService.getTokenDetailsFromCognito(code, codeVerifier);
            const token = await authService.getToken();
            const user = await userService.getUserByUsername(username);
            if (user) {
                if (!user.userRoles) {
                    //console.log("User exists but doesn't have roles assigned:"+user.userName+":"+userGroups);
                    authService.logout();
                    history.push(routeMaps.login);
                } else {
                    setUser(user);
                    if (user.userRoles.indexOf(ROLES.AccountAdmin) > -1 || user.userRoles.indexOf(ROLES.SystemAdmin) > -1) {
                        history.push(routeMaps.administrationProjects);
                    } else if (user.userRoles.indexOf('Technician') > -1 && user.userRoles.length === 1) {
                        //throw error = { message: 'You currently have mobile access only. Please log into the Peak Workflow Mobile App.' };
                        authService.logout();
                        history.push(routeMaps.login);
                    } else {
                        setUser(user);
                        history.push(routeMaps.dashboard);
                    }
                }
            } else {
                // user authenticated but doesn't exist in database
                const myIdToken = jwt_decode(token);
                const userName = myIdToken['username'] ? myIdToken['username'] : username;
                console.log("User authenticated but doesn't exist in database:" + userName);
                //Assume PreToken Generation Trigger has been configured to add user to database
                console.log("userName:", userName);
                const user = await userService.getUserByUsername(userName);
                console.log("user:", user);
                setUser(user);
                history.push(routeMaps.dashboard);
                authService.removeAccessToken();
                authService.removeRefreshToken();
            }
        } catch (error) {
            console.log(error);
            authService.logout();
            history.push(routeMaps.login);
        }
    }

    useEffect(() => {
        if ((code !== null) && (codeVerifier !== null)) {
            try {
                SSOAuthentication(code, codeVerifier);
                sessionStorage.removeItem(`codeVerifier-${stateNonce}`);
                sessionStorage.removeItem(`username`);
            } catch (error) {
                console.log(error.message);
            }
        }
    }, [codeVerifier]);

    return (
        <div className='login-form error'>{error}</div>
    );
};

export default SSOCodeHandler;