import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Modal, Input } from 'semantic-ui-react';
import WORKORDER_TYPES from '../../../../../../constants/workOrderTypes';
import * as qualificationService from '../../../../../../core/services/qualification.service';
import CustomErrorMessage from '../../../../../../shared/components/messages/error';

const QualificationModal = ({
  showModal,
  closeModal,
  onSave,
  selectedQualification,
  currentTenant
}) => {
  const [qualificationType, setQualificationType] = useState(() => {
    if (selectedQualification) {
      const definition = JSON.parse(selectedQualification.qualificationDefinition);
      return definition.hasOwnProperty('meterFormFactor') ? WORKORDER_TYPES.MeterDeploy : WORKORDER_TYPES.WaterDeploy;
    }
    return null;
  });
  const [qualificationName, setQualificationName] = useState(() => selectedQualification && selectedQualification.qualificationName ? selectedQualification.qualificationName : '');
  const [qualificationDescription, setQualificationDescription] = useState(() => selectedQualification && selectedQualification.qualificationDescription ? selectedQualification.qualificationDescription : '');
  const [qualificationDefinitionOptions, setQualificationDefinitionOptions] = useState([]);
  const [qualificationDefinitionValues, setQualificationDefinitionValues] = useState([]);
  const [loadingQualificationDefinitionOptions, setLoadingQualificationDefinitionOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (qualificationType) {
        setLoadingQualificationDefinitionOptions(true);
        const values = await qualificationService.listQualificationDefinitionValuesByTenant(currentTenant.id, qualificationType);
        if (values && values.length > 0) {
          const qualificationDefinitionOptions = values.map(value => {
            return { key: value, value, text: value };
          });
          setQualificationDefinitionOptions(qualificationDefinitionOptions);
          setLoadingQualificationDefinitionOptions(false);
        }
      }
    }
    init();
  }, [qualificationType]);

  useEffect(() => {
    if (selectedQualification && selectedQualification.qualificationDefinition) {
      const qualificationDefinition = JSON.parse(selectedQualification.qualificationDefinition);
      setQualificationDefinitionValues(qualificationDefinition[Object.keys(qualificationDefinition)[0]]);
    }
  }, [])

  const onQualificationTypeChange = (event, { value }) => {
    setQualificationType(value);
    setQualificationDefinitionValues([]);
  }

  const onQualificationNameChange = (event, { value }) => {
    setQualificationName(value);
  }

  const onQualificationDescriptionChange = (event, { value }) => {
    setQualificationDescription(value);
  }

  const onQualificationDefinitionValuesChange = (event, { value }) => {
    setQualificationDefinitionValues(value);
  }

  const isDisabled = () => {
    return !qualificationType || !qualificationName || !qualificationDescription || qualificationDefinitionValues.length === 0;
  }

  const handleSave = async () => {
    try {
      setError(null);
      setLoading(true);
      const qualification = {
        qualificationName,
        qualificationDescription,
      };
      if (qualificationType === WORKORDER_TYPES.MeterDeploy) {
        qualification.qualificationDefinition = JSON.stringify({
          'meterFormFactor': qualificationDefinitionValues
        });
      } else {
        qualification.qualificationDefinition = JSON.stringify({
          'meterSize': qualificationDefinitionValues
        });
      }
      if (selectedQualification) {
        qualification.qualificationId = selectedQualification.id;
        await qualificationService.updateQualification(qualification);
      } else {
        qualification.tenantId = currentTenant.id;
        await qualificationService.createQualification(qualification);
      }
      onSave();
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const handleDelete = async () => {
    setDeleting(true);
    await qualificationService.deleteQualification(selectedQualification.id);
    onSave();
  }

  return (
    <Modal size='small' onClose={closeModal} open={showModal}>
      <Modal.Header>{`${selectedQualification ? 'Edit' : 'Create'}`} Qualification</Modal.Header>
      <Modal.Content>
        <div>
          Type: <Dropdown
            style={{ marginLeft: '10px' }}
            placeholder='Type'
            selection
            value={qualificationType}
            options={[
              { key: 0, value: WORKORDER_TYPES.MeterDeploy, text: 'Electric' },
              { key: 1, value: WORKORDER_TYPES.WaterDeploy, text: 'Water' },
              { key: 2, value: null, text: 'Gas' }
            ]}
            onChange={onQualificationTypeChange} />
        </div>
        <div>
          Name: <Input type='text' value={qualificationName} style={{ marginLeft: '10px', marginTop: '10px' }} onChange={onQualificationNameChange} />
        </div>
        <div>
          Description: <Input type='text' value={qualificationDescription} style={{ marginLeft: '10px', marginTop: '10px' }} onChange={onQualificationDescriptionChange} />
        </div>
        <div>
          Values: <Dropdown
            style={{ marginLeft: '10px', marginTop: '10px' }}
            multiple
            placeholder='Values'
            selection
            search
            disabled={loadingQualificationDefinitionOptions}
            loading={loadingQualificationDefinitionOptions}
            value={qualificationDefinitionValues}
            options={qualificationDefinitionOptions}
            onChange={onQualificationDefinitionValuesChange} />
        </div>
        {error && <CustomErrorMessage message={error.message || 'Unexpected error'} />}
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {selectedQualification && <Button loading={deleting} disabled={loading || deleting} onClick={handleDelete} negative>Delete</Button>}
          </div>
          <div>
            <Button disabled={loading || deleting} onClick={closeModal}>Cancel</Button>
            <Button loading={loading} disabled={isDisabled() || deleting} onClick={handleSave} positive icon='checkmark' labelPosition='right' content='Save' />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  )
}
export default QualificationModal;